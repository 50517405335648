//TAddOnAck
import Handler from "../Handler";

class TourneyAddOnAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyAddOnAckHandler(receivedData);
  }
}

export default TourneyAddOnAckHandler;

// TAddOnAck#;
// private String status;
// private String playerName;
// private double Amount;
// private String addOnMessage;
// private int statusCode;
