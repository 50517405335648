import React, { useEffect, Fragment } from "react";
import goldTrophyIcon from "../../../assets/lobby/GoldTrophyIcon.svg";
import chip from "../../../assets/lobby/Chip.svg";
import tourneyHorizontalSeperator from "../../../assets/lobby/TourneyHorizontalSeperator.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";

import {
  TourneyEntriesContainer,
  TotalPrizeAndNoOfWinnersWrapper,
  TotalPrizeWrapper,
  TotalPrizeText,
  PrizeAmountText,
  TrophyIconWrapper,
  PlayersWrapper,
  PlayersText,
  PlayersCountText,
  TourneyEntriesTableContainer,
  TableWrapper,
  TableHeaderWrapper,
  TableHeaderItemText,
  TableRow,
  TableItemText,
  PlayerEntryWrapper,
  PlayerNameText,
  ChipIcon,
  ChipsCount,
  ChipsWrapper,
  HorizontalSeperator,
  TableMessageWrapper,
  MessageText,
  TicketIcon,
  AddIconText,
  TicketsCountText,
  EntriesText,
  HeaderContentScrollIconWrapper,
  EntriesTextWrapper,
} from "./tourneyEntriesStyles";
import { useDispatch } from "react-redux";
import { requestTourneyEntriesDynamicData } from "../../redux/slices/lobbySlice";
import { TOURNEY_REGISTRATION_STARTED } from "../../data/Constants";

import { ScrollDownArrow } from "../../../Common/components/ScrollDownArrow/ScrollDownArrow";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

function TourneyEntries(props) {
  const {
    tourneyEntriesData,
    prizePool,
    tourneyId,
    displayWinners,
    tourneyStatus,
    breakEven,
    dynamicPoolPrize,
    prizePoolTickets,
    tourneyIdentifier,
  } = props.tourneyDetails;
  console.log("Yashwanth logging props.playerName:", props.userName);
  const dispatch = useDispatch();

  const decimalPlaces = tourneyIdentifier === "completedTourneys" ? 0 : 2;

  useEffect(() => {
    dispatch(requestTourneyEntriesDynamicData({ tourneyId: tourneyId }));
  }, []);
  return (
    <TourneyEntriesContainer>
      <TotalPrizeAndNoOfWinnersWrapper>
        <TotalPrizeWrapper>
          <TotalPrizeText>Total Prize</TotalPrizeText>
          <PrizeAmountText>
            {breakEven
              ? dynamicPoolPrize > 0
                ? `₹${parseDecimalValuesOfNumber(
                    dynamicPoolPrize,
                    2,
                    checkToDisplayValueInK(dynamicPoolPrize),
                    checkToDisplayValueInL(dynamicPoolPrize)
                  )}`
                : ""
              : prizePool > 0
              ? `₹${parseDecimalValuesOfNumber(
                  prizePool,
                  2,
                  checkToDisplayValueInK(prizePool),
                  checkToDisplayValueInL(prizePool)
                )}`
              : ""}
            {prizePoolTickets > 0 && (
              <>
                {((breakEven && dynamicPoolPrize > 0) || prizePool > 0) && (
                  <AddIconText>+</AddIconText>
                )}
                <TicketsCountText>{prizePoolTickets}</TicketsCountText>
                <TicketIcon src={goldTicket} />
              </>
            )}
          </PrizeAmountText>
        </TotalPrizeWrapper>
        <TrophyIconWrapper src={goldTrophyIcon} />
        <PlayersWrapper>
          <PlayersText>No. of Winners</PlayersText>
          <PlayersCountText>{displayWinners}</PlayersCountText>
        </PlayersWrapper>
      </TotalPrizeAndNoOfWinnersWrapper>
      <TourneyEntriesTableContainer>
        <HeaderContentScrollIconWrapper>
          {/* header section (non-scrollable) */}
          <TableHeaderWrapper>
            <TableHeaderItemText>Players</TableHeaderItemText>
            <TableHeaderItemText>Position</TableHeaderItemText>
            <TableHeaderItemText>Prize</TableHeaderItemText>
          </TableHeaderWrapper>
          {/* content (under headers) section (scrollable) */}
          <TableWrapper>
            {/* <TableHeaderWrapper>
            <TableHeaderItemText>Players</TableHeaderItemText>
            <TableHeaderItemText>Position</TableHeaderItemText>
            <TableHeaderItemText>Prize</TableHeaderItemText>
          </TableHeaderWrapper> */}
            {tourneyEntriesData &&
              tourneyEntriesData?.map((eachEntry, index) => (
                <Fragment key={eachEntry.playerName}>
                  <TableRow
                    isOwnPlayerName={eachEntry.playerName === props.userName}
                  >
                    <PlayerEntryWrapper>
                      <PlayerNameText>{eachEntry.playerName}</PlayerNameText>
                      <ChipsWrapper>
                        <ChipIcon src={chip} />
                        <ChipsCount>
                          {/* Doing below change as part of IM-2142 ticket. Got direct requirement from Yashwanth directly on 29/5/2024 */}
                          {parseDecimalValuesOfNumber(
                            eachEntry.playerChips,
                            decimalPlaces,
                            checkToDisplayValueInK(eachEntry.playerChips),
                            checkToDisplayValueInL(eachEntry.playerChips)
                          )}
                        </ChipsCount>
                      </ChipsWrapper>
                    </PlayerEntryWrapper>
                    <TableItemText>{eachEntry.rank}</TableItemText>
                    <TableItemText
                    // addRightPadding={
                    //   (eachEntry.winningAmount === 0 &&
                    //   !eachEntry?.ticketAssigned &&
                    //   eachEntry.winningBonusAmount <= 0)
                    // }
                    >
                      {eachEntry.winningAmount > 0 ? (
                        <>{`₹${parseDecimalValuesOfNumber(
                          eachEntry.winningAmount,
                          2,
                          checkToDisplayValueInK(eachEntry.winningAmount),
                          checkToDisplayValueInL(eachEntry.winningAmount)
                        )}`}</>
                      ) : (
                        !eachEntry?.ticketAssigned &&
                        eachEntry.winningBonusAmount <= 0 && <>{"-"}</>
                      )}
                      {eachEntry?.ticketAssigned && (
                        <>
                          {eachEntry.winningAmount > 0 && (
                            <AddIconText>+</AddIconText>
                          )}
                          <TicketIcon src={goldTicket} />
                        </>
                      )}
                      {eachEntry.winningBonusAmount > 0 && (
                        <>
                          {(eachEntry.winningAmount > 0 ||
                            eachEntry?.ticketAssigned) && (
                            <AddIconText>+</AddIconText>
                          )}
                          {`₹${parseDecimalValuesOfNumber(
                            eachEntry.winningBonusAmount,
                            2,
                            checkToDisplayValueInK(
                              eachEntry.winningBonusAmount
                            ),
                            checkToDisplayValueInL(eachEntry.winningBonusAmount)
                          )} (Bonus)`}
                        </>
                      )}
                    </TableItemText>
                  </TableRow>
                  {/* {index < tourneyEntriesData.length && (
                  <HorizontalSeperator src={tourneyHorizontalSeperator} />
                )} */}
                  {eachEntry.playerName !== props.userName &&
                    index < tourneyEntriesData.length && (
                      <HorizontalSeperator src={tourneyHorizontalSeperator} />
                    )}
                  {/* {tourneyEntriesData.length > 6 && (
                  <ScrollDownArrow bottom={15} right={16} />
                )} */}
                </Fragment>
              ))}
            {/* <>
            {tourneyEntriesData.length >= 50 && (
              <TableRow>
                <EntriesText>{`Showing ${tourneyEntriesData.length} entries`}</EntriesText>
              </TableRow>
            )}
            {tourneyEntriesData.length > 6 && (
              <ScrollDownArrow bottom={15} right={16} />
            )}
          </> */}
            {/* {tourneyEntriesData.length >= 50 && (
            <TableRow>
              <EntriesText>{`Showing ${tourneyEntriesData.length} entries`}</EntriesText>
            </TableRow>
          )} */}

            {/* if there are no entries available */}
            {tourneyEntriesData && tourneyEntriesData.length === 0 && (
              <TableMessageWrapper>
                <MessageText>
                  {tourneyStatus === TOURNEY_REGISTRATION_STARTED
                    ? "Ranks information will be available once tournament begins."
                    : "Ranks information not available"}
                </MessageText>
              </TableMessageWrapper>
            )}
          </TableWrapper>
          <EntriesTextWrapper>
            {tourneyEntriesData.length > 0 &&
              tourneyEntriesData.length < 50 && (
                // <TableRow>
                //   <EntriesText>{`Showing ${tourneyEntriesData.length} entries`}</EntriesText>
                // </TableRow>
                <EntriesText>{`Total entries: ${tourneyEntriesData.length}`}</EntriesText>
              )}
            {tourneyEntriesData.length >= 50 && (
              // <TableRow>
              //   <EntriesText>{`Showing ${tourneyEntriesData.length} entries`}</EntriesText>
              // </TableRow>
              <EntriesText>{`Showing ${tourneyEntriesData.length} entries`}</EntriesText>
            )}
            {tourneyEntriesData.length > 6 && (
              <ScrollDownArrow bottom={15} right={16} />
            )}
          </EntriesTextWrapper>
        </HeaderContentScrollIconWrapper>
      </TourneyEntriesTableContainer>
    </TourneyEntriesContainer>
  );
}

export default TourneyEntries;
