let windowWidth = Math.min(window.innerWidth, window.innerHeight);
let windowHeight = Math.max(window.innerWidth, window.innerHeight);
//892 412
let cardWidth = 81;
let cardHeight = 107;
let baseWidth = 1000;

if (windowWidth <= 720) {
  cardWidth = 110;
  cardHeight = 155;
}

if (windowWidth > 720 && windowWidth < 800) {
  cardWidth = 100;
  cardHeight = 141;
}

if (windowWidth >= 800) {
  cardWidth = 95;
  cardHeight = 134;
}
if (windowWidth > 1000) {
  cardWidth = 110;
  cardHeight = 145;
}

let ratio = windowWidth / baseWidth;

cardWidth = parseInt(cardWidth * ratio);
cardHeight = parseInt(cardHeight * ratio);

const getCardWidthAndHeight = () => {
  let sizeObject = {
    cardWidth: cardWidth,
    cardHeight: cardHeight,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
  };
  return sizeObject;
};


export {getCardWidthAndHeight};