import {
  EVENT_ON_CLIENT_CONNECT,
  EVENT_ON_CLIENT_DISCONNECT,
  EVENT_ON_CLIENT_READ_TIMED_OUT,
  EVENT_ON_CLIENT_CONNECT_TIME_OUT,
  EVENT_ON_CLIENT_RE_CONNECT,
} from "./Configuration";

export class ClientListener extends Object {
  constructor(store) {
    super();
    this.store = store;
  }

  onClientConnect() {}

  onClientDisconnect() {}

  onClientReconnect() {}

  onReadTimedout() {}

  onConnectTimeout() {}

  onError(event) {}

  setCommunicationChannel(value) {
    this.communicationChannel = value;
  }

  getCommunicationChannel() {
    return this.communicationChannel;
  }

  startHeartbeat() {
    this.communicationChannel.startHeartbeat();
  }

  resetHeartbeat() {
    this.communicationChannel.resetHeartbeat();
  }

  setHeartbeatGapCount() {}

  doCleanUp() {}

  validateListeners() {
    let toBeImplemented = [
      EVENT_ON_CLIENT_CONNECT,
      EVENT_ON_CLIENT_DISCONNECT,
      EVENT_ON_CLIENT_READ_TIMED_OUT,
      EVENT_ON_CLIENT_CONNECT_TIME_OUT,
      EVENT_ON_CLIENT_RE_CONNECT,
    ];
    for (let key in toBeImplemented) {
      console.log("Key: ", key);
      let toBeImplementedFunctionName = toBeImplemented[key];
      function checkFunctionImplementation(toBeImplementedFunctionName) {
        for (let functionKey in ClientListener.prototype) {
          if (typeof ClientListener.prototype[functionKey] === "function") {
            if (
              functionKey !== "constructor" &&
              functionKey !== "validateListeners"
            ) {
              if (toBeImplementedFunctionName === functionKey) {
                return true;
              }
            }
          }
        }
        return false;
      }
      let isFunctionImplemented = checkFunctionImplementation(
        toBeImplementedFunctionName
      );
      if (!isFunctionImplemented) {
        throw new Error(
          "Found no implementation for " + toBeImplementedFunctionName
        );
      } else {
        console.log("Going to else case client listener");
      }
    }
    return true;
  }
}

export default ClientListener;
