import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import { motion } from 'framer-motion';

export const HandTypeWrapper = styled.div`
  display: inline;
  width: 100%;
  max-width: 250px;
  position: absolute;
  border-top: 1px solid rgba(234, 191, 105, 0.09);
  border-bottom: 1px solid rgba(234, 191, 105, 0.09);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 31.25%,
    rgba(0, 0, 0, 0.4) 65.1%,
    rgba(0, 0, 0, 0) 100%
  );
  top: ${(props) => (props.isLandscape ? "58%" : props.top + "px")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  text-align: center;
`;

export const PossibleHandTypeWrapper = styled.div`
  min-width: ${(props) => (props.isLandscape ? 30 : props.minWidth)}%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 100)}%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 31.25%,
    rgba(0, 0, 0, 0.65) 65.1%,
    rgba(0, 0, 0, 0) 100%
  );
  top: ${(props) => (props.top ? props.top + "px" : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom + "%" : "unset")};
  left: ${(props) => (props.left ? props.left + "%" : "unset")};
  right: ${(props) => (props.right ? props.right + "%" : "unset")};
  transform: translate(-100%, -50%);
  z-index: 4;
  text-align: center;

  ${(props) =>
    props.type !== "playerPossibleHand" &&
    `@media (min-width: 768px) {
    top: 52.5%;
    height: 45px;
  }`}
`;

export const HandType = styled.span`
  display: block;
  font-family: Conto;
  font-size: ${(props) => (props.textSize ? props.textSize : 14)}px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
  color: ${() => colors.primaryGold};
`;

export const CursiveHandType = styled(motion.div)`
  display: block;
  font-family: Cursive;
  font-size: ${(props) => (props.textSize ? props.textSize : 22)}px;
  font-weight: bold;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
  color: ${() => colors.primaryGold};
  margin-top: 6px;
`;
