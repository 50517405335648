import { cloneDeep, isEqual } from "lodash";
import {
  deleteGameTable,
  // proceedWithCheckGame,
  // proceedWithMatchMaking,
  // proceedWithTimeOutBasedConnection,
} from "../redux/slices/gameTableSlice";
import { getGameSlice, getLobbySlice, getPlayerObject } from "./ReduxUtils";
import {
  CHECK_GAME_API,
  CHECK_GAME_API_READY_TO_REQUEST,
  GAME_SOCKET_ERROR,
  GAME_SOCKET_TIMEOUT,
  INITIATE_LACK_GAME_CONNECTION,
  INITIATE_TOURNEY_GAME_CONNECTION,
  INITIATE_TOURNEY_GAME_RECONNECTION,
  MATCH_MAKING_API,
  MATCH_MAKING_API_READY_TO_REQUEST,
  SEQUENTIAL_HOLD,
  SEQUENTIAL_RESET,
  SEQUENTIAL_START,
  TOURNEY_GAME_CONNECTION_STATE,
} from "../data/Constants";
import GameConnectionsN from "../io/GameConnectionsN";

class TableObserver {
  constructor(tempTableId, gameDefinitionId, dispatchFunction) {
    this.tempTableId = tempTableId;
    this.gameDefinitionId = gameDefinitionId;
    this.tableData = undefined;
    this.dispatchFunction = dispatchFunction;
    this.sequentialStatus = SEQUENTIAL_HOLD;
    this.gameConnectionStatus = undefined;
    this.forceDelay = false;
    this.lobbySliceData = undefined;
  }

  observe = (tableData, lobbySliceData) => {
    this.tableDataPrevious =
      typeof this.tableDataCurrent !== "undefined"
        ? cloneDeep(this.tableDataCurrent)
        : "";
    this.tableDataCurrent = tableData;

    if (!isEqual(this.tableDataCurrent, this.tableDataPrevious)) {
      console.log("this.tableDataPrevious: ", this.tableDataPrevious);

      if (
        this.tableDataPrevious === "" ||
        tableData.gameConnectionStatus !==
          this.tableDataPrevious.gameConnectionStatus
      ) {
        this.triggerGameConnectionCheck(
          this.tableDataCurrent.gameConnectionStatus,
          lobbySliceData
        );
      } else {
        console.log("ELSE ELSE ELSE ELSE YASHWANTH");
      }
    } else {
      console.log("Both old and new table datas are same");
      console.log("Previous: ", this.tableDataPrevious);
      console.log("Current: ", this.tableDataCurrent);
    }
  };

  // triggerGameConnectionCheck = (gameConnectionStatus, lobbySliceData) => {
  //     console.log("Game Connection Status: ", gameConnectionStatus, this.tableDataCurrent.tempTableId);
  //     if (gameConnectionStatus === MATCH_MAKING_API_READY_TO_REQUEST) {
  //         console.log("Sending match making request");
  //         let playerData = lobbySliceData.player;

  //         // let dataObject = {
  //         //     gdid: this.tableDataCurrent.gameDefinition.gameDefId,
  //         //     playerName: playerData.userName,
  //         //     _id: playerData.userId,
  //         //     ip: playerData.IP,
  //         //     latitude: playerData.latitude,
  //         //     longitude: playerData.longitude,
  //         //     channel: lobbySliceData.channel,
  //         //     deviceId: playerData.deviceId,
  //         // }

  //         // let gameConnection = GameConnectionsN.getInstance().getGameConnection(this.tableDataCurrent.tempTableId);
  //         // gameConnection.listener.initiateMatchMakingProcess({ matchMakingURL: this.tableDataCurrent.apiMatchMakingURL, apiPayload: dataObject });

  //         this.dispatchFunction(proceedWithMatchMaking({
  //             tempTableId: this.tableDataCurrent.tempTableId,
  //             dataObject: {
  //                 gdid: this.tableDataCurrent.gameDefinition.gameDefId,
  //                 playerName: playerData.userName,
  //                 _id: playerData.userId,
  //                 ip: playerData.IP,
  //                 latitude: playerData.latitude,
  //                 longitude: playerData.longitude,
  //                 channel: lobbySliceData.channel,
  //                 deviceId: playerData.deviceId,
  //             }
  //         }))
  //     }
  //     else if(gameConnectionStatus === SEQUENTIAL_START){
  //         console.log("Inside SEQUENTIAL START");
  //         let playerData = lobbySliceData.player;
  //         let dataObject = {};

  //         if (this.tableDataCurrent.gameAPIState === MATCH_MAKING_API) {
  //             dataObject = {
  //                 gdid: this.tableDataCurrent.gameDefinition.gameDefId,
  //                 playerName: playerData.userName,
  //                 _id: playerData.userId,
  //                 ip: playerData.IP,
  //                 latitude: playerData.latitude,
  //                 longitude: playerData.longitude,
  //                 channel: lobbySliceData.channel,
  //                 deviceId: playerData.deviceId,
  //             }
  //         } else {
  //             dataObject = {
  //                 gameDefId: this.tableDataCurrent.gameDefinition.gameDefId,
  //                 tableID: this.tableDataCurrent.tableId,
  //                 gameId: this.tableDataCurrent.gameId,
  //                 playerName: playerData.userName,
  //                 deviceId: playerData.deviceId,
  //                 authToken: lobbySliceData.accessToken,
  //             };
  //         }

  //         // let gameConnection = GameConnectionsN.getInstance().getGameConnection(this.tableDataCurrent.tempTableId);
  //         // gameConnection.listener.initiateTimeOutBasedConnection(this.tableDataCurrent.gameAPIState, { checkGameURL: this.tableDataCurrent.apiCheckGameURL, apiPayload: dataObject });

  //         console.log("Sending data object to timeout is: ", dataObject);
  //         this.dispatchFunction(
  //             proceedWithTimeOutBasedConnection({
  //                 tempTableId: this.tableDataCurrent.tempTableId,
  //                 apiPayload: dataObject
  //             })
  //         )

  //         // if(this.tableDataCurrent.gameAPIState === MATCH_MAKING_API){

  //         // }else{
  //         //     console.log("Dispatching check game")
  //         //     this.dispatchFunction(proceedWithCheckGame({
  //         //         tempTableId: this.tableDataCurrent.tempTableId,
  //         //         dataObject: dataObject
  //         //     }))
  //         // }
  //     }
  // }

  triggerGameConnectionCheck = () => {
    // console.log("Game Connection Status: ", gameConnectionStatus, this.tableDataCurrent.tempTableId);
    console.log(
      "In triggerGameConnectionCheck:",
      this.gameConnectionStatus,
      this.tempTableId,
      this.getSequentialStatus()
    );
    if (
      this.getSequentialStatus() === SEQUENTIAL_HOLD ||
      this.getSequentialStatus() === SEQUENTIAL_RESET
    ) {
      console.log("Returning as", this.getSequentialStatus());
      return;
    }
    console.log("Proceeding with trigger connection check:", this.tempTableId);
    if (this.gameConnectionStatus === MATCH_MAKING_API_READY_TO_REQUEST) {
      let requestDate = new Date();
      let log = `${requestDate.getDate()}/${requestDate.getMonth()}/${requestDate.getFullYear()}, ${requestDate.getHours()}:${requestDate.getMinutes()}:${requestDate.getSeconds()}:${requestDate.getMilliseconds()}`;
      console.log(
        "Sending match making request: ",
        this.tempTableId,
        log,
        this.getForceDelay()
      );
      // let playerData = this.lobbySliceData.player;
      let playerData = getPlayerObject();
      console.log("Fetched playerdata: ", playerData);

      let dataObject = {
        gdid: this.gameDefinitionId,
        playerName: playerData.userName,
        _id: playerData.userId,
        ip: playerData.IP,
        latitude: playerData.latitude,
        longitude: playerData.longitude,
        // channel: this.lobbySliceData.channel,
        channel: getLobbySlice().channel,
        deviceId: playerData.deviceId,
        a23Token: getLobbySlice().a23Token,
        authToken: getLobbySlice().accessToken,
      };
      console.log("Post building data Object at MM: ", dataObject);

      let gameConnection = GameConnectionsN.getInstance().getGameConnection(
        this.tempTableId
      );
      if (this.getForceDelay()) {
        gameConnection.listener.initiateTimeOutBasedConnection(
          MATCH_MAKING_API,
          {
            apiURL: process.env.REACT_APP_MATCH_MAKING_API_URL,
            apiPayload: dataObject,
          }
        );
      } else {
        gameConnection.listener.initiateMatchMakingProcess({
          apiURL: process.env.REACT_APP_MATCH_MAKING_API_URL,
          apiPayload: dataObject,
        });
      }

      // this.dispatchFunction(proceedWithMatchMaking({
      //     tempTableId: this.tableDataCurrent.tempTableId,
      //     dataObject: {
      //         gdid: this.tableDataCurrent.gameDefinition.gameDefId,
      //         playerName: playerData.userName,
      //         _id: playerData.userId,
      //         ip: playerData.IP,
      //         latitude: playerData.latitude,
      //         longitude: playerData.longitude,
      //         channel: lobbySliceData.channel,
      //         deviceId: playerData.deviceId,
      //     }
      // }))
    } else if (this.gameConnectionStatus === CHECK_GAME_API_READY_TO_REQUEST) {
      console.log("Inside CHECK GAME START");
      // let playerData = this.lobbySliceData.player;
      let playerData = getPlayerObject();
      let tableData = getGameSlice().games[this.tempTableId];
      console.log("fetched table data: ", tableData);

      let dataObject = {
        gameDefId: this.gameDefinitionId,
        tableID: tableData.tableId,
        gameId: tableData.gameId,
        playerName: playerData.userName,
        deviceId: playerData.deviceId,
        // authToken: this.lobbySliceData.accessToken,
        authToken: getLobbySlice().accessToken,
      };

      let gameConnection = GameConnectionsN.getInstance().getGameConnection(
        this.tempTableId
      );
      gameConnection.listener.initiateTimeOutBasedConnection(CHECK_GAME_API, {
        apiURL: process.env.REACT_APP_CHECK_GAME_API_URL,
        apiPayload: dataObject,
      });

      console.log("Sending data object to timeout is: ", dataObject);
    } else if (
      this.gameConnectionStatus === INITIATE_LACK_GAME_CONNECTION ||
      this.gameConnectionStatus === INITIATE_TOURNEY_GAME_CONNECTION
    ) {
      console.log(
        "Inside direct socket game connection:",
        this.gameConnectionStatus
      );

      let gameConnection = GameConnectionsN.getInstance().getGameConnection(
        this.tempTableId
      );
      gameConnection.listener.triggerDirectSocketConnection();
    } else if (
      this.gameConnectionStatus === GAME_SOCKET_TIMEOUT ||
      this.gameConnectionStatus === GAME_SOCKET_ERROR ||
      this.gameConnectionStatus === INITIATE_TOURNEY_GAME_RECONNECTION
    ) {
      let gameConnection = GameConnectionsN.getInstance().getGameConnection(
        this.tempTableId
      );
      console.log(
        "Inside TIMEOUT SOCKET DIRECT CONNECTION STATUS:",
        this.gameConnectionStatus
      );
      // let gameConnection = GameConnectionsN.getInstance().getGameConnection(this.tempTableId);
      console.log("Game connection: ", gameConnection);
      console.log("Listener: ", gameConnection.listener);
      gameConnection.listener.initiateTimeOutBasedDirectConnection();
    }
    console.log("EXITED THE TRIGGER FLOW");
  };

  // triggerConnectionProtocol = () => {
  //     console.log("Method logged");
  //     let lobbySlice = getLobbySlice();
  //     let tableData = getGameSlice().games[this.tempTableId];

  //     let payload = {
  //         a23Token: lobbySlice.a23Token,
  //         authToken: lobbySlice.accessToken,
  //         gameId: tableData.gameId,
  //         playerName: lobbySlice.player.userName,
  //         playerBalance: lobbySlice.balance.totalBalance,
  //         deviceId: lobbySlice.player.deviceId,
  //         // deviceName: mobileModel, //not required as these are already added in lobby connection protocol
  //         // deviceOSVersion: osVersion,
  //         // deviceVendor: mobileVendor,
  //         // webViewVersion: engineVersion,
  //         channel: lobbySlice.channel,
  //         profilePic: lobbySlice.player.avatar,
  //         aceLevel: lobbySlice.player.level,
  //         userId: lobbySlice.player.userId,
  //         gameDefId: this.gameDefinitionId,
  //         state: lobbySlice.player.state,
  //         IP: lobbySlice.player.IP,
  //         tableId: tableData.tableId,
  //         // matchMakingCount: tableData.apiMatchMakingTimeLogsArray,
  //         // checkInCount: tableData.apiCheckGameTimeLogsArray,
  //       };

  //       console.log("Sending PAYLOAD: ", payload);

  //       let gameConnection = GameConnectionsN.getInstance().getGameConnection(this.tempTableId);
  //       gameConnection.listener.requestGameConnection(tableData.gameAPIState, payload);
  // }

  // triggerConnectionProtocol = () => {
  //     console.log("Method logged");
  //     let lobbySlice = getLobbySlice();
  //     let tableData = getGameSlice().games[this.tempTableId];
  //     let payload = {};
  //     let gameConnection = GameConnectionsN.getInstance().getGameConnection(this.tempTableId);

  //     if (tableData.isLackGame && !tableData.isConnectionAckReceivedOnce) {
  //         payload = {
  //             playerName: lobbySlice.player.userName,
  //             gameId: tableData.gameId,
  //             tableId: tableData.tableId,
  //         }
  //         console.log("Sending PAYLOAD relogin game connection: ", payload);
  //         gameConnection.listener.requestReloginGameConnection(payload);

  //     } else {
  //         if (tableData.gameAPIState === MATCH_MAKING_API) { //for con#
  //             payload = {
  //                 a23Token: lobbySlice.a23Token,
  //                 authToken: lobbySlice.accessToken,
  //                 gameId: tableData.gameId,
  //                 playerName: lobbySlice.player.userName,
  //                 playerBalance: lobbySlice.balance.totalBalance,
  //                 deviceId: lobbySlice.player.deviceId,
  //                 // deviceName: mobileModel, //not required as these are already added in lobby connection protocol
  //                 // deviceOSVersion: osVersion,
  //                 // deviceVendor: mobileVendor,
  //                 // webViewVersion: engineVersion,
  //                 channel: lobbySlice.channel,
  //                 profilePic: lobbySlice.player.avatar,
  //                 aceLevel: lobbySlice.player.level,
  //                 userId: lobbySlice.player.userId,
  //                 gameDefId: this.gameDefinitionId,
  //                 state: lobbySlice.player.state,
  //                 IP: lobbySlice.player.IP,
  //                 tableId: tableData.tableId,
  //                 // matchMakingCount: tableData.apiMatchMakingTimeLogsArray,
  //                 // checkInCount: tableData.apiCheckGameTimeLogsArray,
  //             }
  //             console.log("Sending PAYLOAD Game Normal connection: ", payload);
  //             gameConnection.listener.requestGameConnection(payload);

  //         } else { //for Rcon#
  //             payload = {
  //                 gameId: tableData.gameId,
  //                 tableId: tableData.tableId,
  //                 gdid: tableData.gameDefinition.gameDefId,
  //                 playerName: lobbySlice.player.userName,
  //                 deviceId: lobbySlice.player.deviceId,
  //                 userId: lobbySlice.player.userId,
  //             }
  //             console.log("Sending PAYLOAD Game Reconnection: ", payload);
  //             gameConnection.listener.requestGameReConnection(payload);
  //         }
  //     }
  // }

  triggerConnectionProtocol = () => {
    console.log("Method logged");
    let lobbySlice = getLobbySlice();
    let tableData = getGameSlice().games[this.tempTableId];
    let tableSettings = getGameSlice().tableSettings;
    let payload = {};
    let gameConnection = GameConnectionsN.getInstance().getGameConnection(
      this.tempTableId
    );

    console.log("Table Data GAME API STATE: ", tableData.gameAPIState);
    if (tableData.isLackGame && !tableData.isConnectionAckReceivedOnce) {
      //|| tableData.isTourneyGame
      console.log("If case");
      payload = {
        channel: lobbySlice.channel,
        a23Token: lobbySlice.a23Token,
        authToken: lobbySlice.accessToken,
        playerName: lobbySlice.player.userName,
        aceLevel: lobbySlice.player.level,
        playerA23Status: lobbySlice.player.playerStatus,
        state: lobbySlice.player.state,
        IP: lobbySlice.player.IP,
        lat: lobbySlice.player.latitude,
        lng: lobbySlice.player.longitude,
        gameId: tableData.gameId,
        tableId: tableData.tableId,
        clientVersion: process.env.REACT_APP_CLIENT_VERSION,
        gdid: tableData?.gameDefinition.gameDefId,
        tourneyId: tableData.isTourneyGame
          ? tableData?.gameDefinition?.tourneyId
          : null,
        isWatchPlayer: tableData.watchPlayer,
      };
      console.log("Sending PAYLOAD relogin game connection: ", payload);
      gameConnection.listener.requestReloginGameConnection(payload);
    } else {
      console.log("ELSE CASE");
      if (
        tableData.gameAPIState === MATCH_MAKING_API ||
        tableData.gameAPIState === TOURNEY_GAME_CONNECTION_STATE
      ) {
        //for con#
        payload = {
          a23Token: lobbySlice.a23Token,
          authToken: lobbySlice.accessToken,
          clientVersion: process.env.REACT_APP_CLIENT_VERSION,
          playerName: lobbySlice.player.userName,
          playerBalance: lobbySlice.balance.totalBalance,
          deviceId: lobbySlice.player.deviceId,
          // deviceName: mobileModel, //not required as these are already added in lobby connection protocol
          // deviceOSVersion: osVersion,
          // deviceVendor: mobileVendor,
          // webViewVersion: engineVersion,
          channel: lobbySlice.channel,
          profilePic: lobbySlice.player.avatar,
          aceLevel: lobbySlice.player.level,
          playerA23Status: lobbySlice.player.playerStatus,
          userId: lobbySlice.player.userId,
          gameDefId: this.gameDefinitionId,
          state: lobbySlice.player.state,
          IP: lobbySlice.player.IP,
          lat: lobbySlice.player.latitude,
          lng: lobbySlice.player.longitude,
          tableId: tableData.tableId,
          gameId: tableData.gameId,
          tourneyId: tableData.isTourneyGame
            ? tableData?.gameDefinition?.tourneyId
            : null,
          isWatchPlayer: tableData.watchPlayer,
          autoRebuy: tableSettings.autoRebuy,
          autoMuck: tableSettings.autoMuck,
          topUpEnabled: tableSettings.autoTopupConfig.enabled,
          topUpAmount: tableSettings.autoTopupConfig.topUpAmount,
          fallsBelowAmount: tableSettings.autoTopupConfig.fallsBelowAmount,
          // matchMakingCount: tableData.apiMatchMakingTimeLogsArray,
          // checkInCount: tableData.apiCheckGameTimeLogsArray,
        };
        console.log("Sending PAYLOAD Game Normal connection: ", payload);

        gameConnection.listener.requestGameConnection(payload);
      } else {
        //for Rcon#
        payload = {
          channel: lobbySlice.channel,
          gameId: tableData.gameId,
          tableId: tableData.tableId,
          gdid: tableData.gameDefinition.gameDefId,
          playerName: lobbySlice.player.userName,
          aceLevel: lobbySlice.player.level,
          playerA23Status: lobbySlice.player.playerStatus,
          clientVersion: process.env.REACT_APP_CLIENT_VERSION,
          deviceId: lobbySlice.player.deviceId,
          userId: lobbySlice.player.userId,
          state: lobbySlice.player.state,
          IP: lobbySlice.player.IP,
          lat: lobbySlice.player.latitude,
          lng: lobbySlice.player.longitude, //check with Pranith
          tourneyId: tableData.isTourneyGame
            ? tableData?.gameDefinition?.tourneyId
            : null,
          isWatchPlayer: tableData.watchPlayer,
        };
        console.log("Sending PAYLOAD Game Reconnection: ", payload);
        gameConnection.listener.requestGameReConnection(payload);
      }
    }
  };

  cleanUp = (allowDeleteGameTable) => {
    console.log(
      "Clean up called in table observer with temp table id",
      this.tempTableId,
      allowDeleteGameTable
    );
    //nothing to be cleaned up as of now, adding this method in case anything get's added in future

    if (allowDeleteGameTable) {
      this.dispatchFunction(deleteGameTable({ tempTableId: this.tempTableId }));
    }
  };

  setGameConnectionStatus(value) {
    // console.log("Value: ", value);
    // console.log("Existing: ", this.getGameConnectionStatus());
    // let triggerCall = !isEqual(value, this.getGameConnectionStatus()) ? true : false;
    // this.gameConnectionStatus = value;

    // console.log("Trigger call: ", triggerCall);
    // if(triggerCall){
    //     this.triggerGameConnectionCheck();
    // }
    this.gameConnectionStatus = value;
  }

  getGameConnectionStatus() {
    return this.gameConnectionStatus;
  }

  setSequentialStatus(value) {
    console.log("Value:", value, this.getSequentialStatus());
    let triggerCall = !isEqual(value, this.getSequentialStatus())
      ? true
      : false;
    this.sequentialStatus = value;

    console.log("Trigger call:", triggerCall);
    if (triggerCall) {
      this.triggerGameConnectionCheck();
    }
  }

  // setSequentialStatus(value){
  //     this.sequentialStatus = value;
  // }

  getSequentialStatus() {
    return this.sequentialStatus;
  }

  setForceDelay(value) {
    this.forceDelay = value;
  }

  getForceDelay() {
    return this.forceDelay;
  }
}

export default TableObserver;
