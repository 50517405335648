import Handler from "../Handler";

class AutoCheckActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoCheckActionAck(receivedData);
  }
}

export default AutoCheckActionHandler;
