import Handler from "../Handler";

class TourneyInTheMoneyHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyInTheMoneyData(receivedData);
  }
}
export default TourneyInTheMoneyHandler;

//inthemoney#{tourneyId: "qtz9x"}
