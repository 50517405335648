import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaroErrorBoundary } from "@grafana/faro-react";

import "./index.css";
import { getIsFaroEnabled, sendFaroLog } from "./Common/utils/FaroUtil";
import PokerLobby from "./Poker/routes/PokerLobby/PokerLobby";
import { messageToNativeClient } from "./Common/utils/platformCommunicationUtil";
import {
  addLobbyToastMessage,
  clearDeeplink,
  handleDeeplink,
  joinGameFromLeaderBoard,
  leaderBoardGamesList,
  openRakebackPage,
  sendLobbyDebugLog,
  setGameJoinCheckAck,
  setIsWebView,
  setPlayerConsentDetails,
  setPlayerDetails,
  setRakebackGameDefinitions,
  updateApplicationVisibility,
  updateHomePageData,
  updateNotificationCount,
  updatePlayerBalance,
  updatePlayerRakebackLevel,
  updateRemindMeStatusToServer,
  updateResourceLoadFailure,
} from "./Poker/redux/slices/lobbySlice";
import {
  resizingTable,
  setResizingToComplete,
  updateGameApplicationVisibility,
} from "./Poker/redux/slices/gameTableSlice";
import {
  checkIfFoldDeviceInTabletMode,
  checkIfUnsupportedDevice,
  getISTDateTimeFormat,
  preloadImage,
} from "./Common/utils/GameUtils";
import TableUtils from "./Poker/utils/TableUtils";
import LobbyConnection from "./Poker/io/LobbyConnection";
import { isIOS } from "react-device-detect";
import {
  CASH_LOBBY,
  DELETED_PLAYER,
  DELETED_PLAYER_LOGOUT,
  FORCE_LOGOUT,
  FUN_CHANNELS,
  GAME_TABLE,
  HOME_LOBBY,
  LOGOUT_WARNING,
  MISSIONS_PAGE,
  MYTICKET,
  POKER_ASSET_LOAD_FAIL,
  POKER_LEADERBOARD,
  RAKEBACK_LEVEL_UPGRADE,
  RAKEBACK_PAGE,
  REDIRECTION_FAIL,
  TOAST_INFO,
  TOURNEY_LOBBY,
  TOURNEY_REGISTRATION,
  TO_BE_DELETED_PLAYER,
  UNSUPPORTED_DEVICE,
  UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE,
} from "./Poker/data/Constants";
import CleverTapUtil from "./Common/utils/CleverTapUtil";
import {
  IP_Client_Poker_launch,
  Poker_Redirection_Fail,
} from "./Poker/data/ClevertapConstants";
import { openModal } from "./Poker/redux/slices/modalSlice";
import * as gameAssets from "./Poker/routes/Game/gameAssets.js";
import { getGameSlice } from "./Poker/utils/ReduxUtils";
import { getDeviceAndConnectionDetails } from "./Poker/utils/BrowserUtils.js";

function App() {
  const dispatch = useDispatch();
  let userAgent = window.navigator.userAgent.toLocaleLowerCase();
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const showGameTable = useSelector((state) => state.gameTable.showGameTable);
  const accessToken = useSelector((state) => state.lobby.player.accessToken);
  const hideRakeBack = useSelector((state) => state.lobby.hideRakeBack);
  // const receivedTokenResponse = useSelector(
  //   (state) => state.lobby.receivedTokenResponse
  // );
  const receivedBalanceResponse = useSelector(
    (state) => state.lobby.receivedBalanceResponse
  );
  const channel = useSelector((store) => store.lobby.channel);
  const player = useSelector((store) => store.lobby.player);
  const userBalance = useSelector((state) => state.lobby.balance);
  // const activeGames = useSelector((state) => state.gameTable.games);
  const [appLoaded, setAppLoaded] = useState(false);
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const resizeRelatedTimeout = useRef(null);
  const playerDetailsTimeout = useRef(null);
  const playerBalanceTimeout = useRef(null);

  const loadImages = async () => {
    // console.log("preloading images", new Date(Date.now()));
    const imagesPromiseList = [];
    if ("serviceWorker" in navigator) {
      let serviceWorkerStatus = await navigator.serviceWorker.getRegistration();
      if (serviceWorkerStatus?.active?.state === "activated") {
        setResourcesLoaded(true);
        return;
      }
    }
    for (const img of Object.values(gameAssets)) {
      imagesPromiseList.push(preloadImage(img));
    }
    try {
      await Promise.all(imagesPromiseList);
      setResourcesLoaded(true);
    } catch (err) {
      sendFaroLog({
        type: POKER_ASSET_LOAD_FAIL,
        username: player?.userName,
        channel: channel,
        timestamp: getISTDateTimeFormat(),
        error: err,
      });
      setResourcesLoaded("FAIL");
    }
  };

  useEffect(() => {
    if (appLoaded && resourcesLoaded) {
      messageToNativeClient({ type: "appLoaded" });
    } else {
      if (appLoaded && resourcesLoaded === "FAIL") {
        messageToNativeClient({ type: "appLoaded" });
        dispatch(updateResourceLoadFailure());
      }
    }
  }, [appLoaded, resourcesLoaded]);

  // useEffect(() => {
  // loadImages();
  // }, []);

  useEffect(() => {
    const orientationHandler = () => {
      if (document.visibilityState === "visible") {
        // console.log('Poker app is visible.')
        dispatch(updateApplicationVisibility({ status: true }));
        dispatch(updateGameApplicationVisibility({ status: true }));
      } else {
        // console.log('Poker app is hidden. ')
        dispatch(updateApplicationVisibility({ status: false }));
        dispatch(updateGameApplicationVisibility({ status: false }));
      }
    };

    document.addEventListener("visibilitychange", orientationHandler);

    return () => {
      clearTimeout(resizeRelatedTimeout.current);
      clearTimeout(playerDetailsTimeout.current);
      clearTimeout(playerBalanceTimeout.current);
      document.removeEventListener("visibilitychange", orientationHandler);
    };
  }, [gameOrientation]);

  useEffect(() => {
    resizeFunction();

    if (showGameTable) {
      if (gameOrientation === "PORTRAIT") {
        messageToNativeClient({
          type: "orientation",
          viwe: "portrait",
          currentScreen: "table",
        });
      } else if (gameOrientation === "LANDSCAPE") {
        messageToNativeClient({
          type: "orientation",
          viwe: "landscape",
          currentScreen: "table",
        });
      }
    }

    window.addEventListener("resize", resizeTimeout);
    // window.addEventListener("online", requestPlatformPendingCallbacks);
    return () => {
      window.removeEventListener("resize", resizeTimeout);
      // window.removeEventListener("online", () => {});
    };
  }, [gameOrientation, accessToken]);

  // useEffect(() => {
  //   window.addEventListener("online", requestPlatformPendingCallbacks);

  //   return () => {
  //     window.removeEventListener("online", () => {});
  //   };
  // }, [receivedTokenResponse]);

  useEffect(() => {
    if (appLoaded && receivedBalanceResponse) {
      let timeStamp = getISTDateTimeFormat();
      CleverTapUtil.getInstance().logEvent(IP_Client_Poker_launch, {
        Username: player.userName,
        channel: channel,
        Launch_TImeStamp: timeStamp,
        "Wallet Balance": userBalance?.totalBalance,
      });
    }
  }, [appLoaded, receivedBalanceResponse]);

  const resizeTimeout = () => {
    if (userAgent.indexOf("android") === -1) {
      return (resizeRelatedTimeout.current = setTimeout(resizeFunction, 100));
    } else {
      return resizeFunction();
    }
  };

  // const requestPlatformPendingCallbacks = () => {
  //   if (!receivedTokenResponse) {
  //     messageToNativeClient({ type: "token" });
  //   } else if (receivedTokenResponse && !receivedBalanceResponse) {
  //     messageToNativeClient({
  //       type: "getBalance",
  //       token: accessToken,
  //     });
  //   }
  // };

  const resizeFunction = () => {
    // dispatch(resizingTable());
    try {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      doc.style.setProperty("--app-width", `${window.innerWidth}px`);
    } catch (e) {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `100vh`);
      doc.style.setProperty("--app-width", `100vw`);
    }
    // dispatch(setResizingToComplete());
  };

  useEffect(() => {
    //in message to native client, "currentScreen" is added in type: "orientation"  as "lobby"/"table" in order to set the notch and
    //bottom soft navigation area background color according to the application (iOS).
    if (!showGameTable) {
      messageToNativeClient({
        type: "orientation",
        viwe: "portrait",
        currentScreen: "lobby",
      });
    } else {
      if (gameOrientation === "LANDSCAPE") {
        messageToNativeClient({
          type: "orientation",
          viwe: "landscape",
          currentScreen: "table",
        });
      } else {
        messageToNativeClient({
          type: "orientation",
          viwe: "portrait",
          currentScreen: "table",
        });
      }
    }
  }, [showGameTable]);

  useEffect(() => {
    //mount useEffect
    // disable of logs and version check are added here
    if (process.env.REACT_APP_CHECK_VERSION === "true") {
      checkVersion();
    }
    // if (process.env.REACT_APP_DISABLE_CONSOLE_STATEMENTS === "true") {
    // disableLogs();
    // }
    if (getIsFaroEnabled()) {
      if (
        isIOS &&
        Number(getDeviceAndConnectionDetails()?.deviceOSVersion) >= 14
      ) {
        initialiseFaro();
      } else if (!isIOS) {
        initialiseFaro();
      }
    }
    window.addEventListener("dataToClient", dataFromPlatform);
    // if (isIOS) {
    //   window.addEventListener("offline", (event) => {
    //     console.log("YASHWANTH LOG - OFFLINE FIRED:", event);
    //     LobbyConnection.getInstance().toggleEventBasedNetworkStatus();
    //     TableUtils.getInstance().toggleEventBasedNetworkStatus();
    //   })
    //   window.addEventListener("online", (event) => {
    //     console.log("YASHWANTH LOG - ONLINE FIRED:", event);
    //   })
    // }

    messageToNativeClient({ type: "hideHeader" });
    messageToNativeClient({ type: "token" });

    return function cleanup() {
      console.log("clean up invoked");
      window.removeEventListener("dataToClient", dataFromPlatform);

      // window.addEventListener("offline", (event) => {
      //   console.log("YASHWANTH LOG - OFFLINE FIRED:", event);
      // })
      // window.addEventListener("online", (event) => {
      //   console.log("YASHWANTH LOG - ONLINE FIRED:", event);
      // })
    };
  }, []);

  const initialiseFaro = () => {
    try {
      const FaroUtil = require("./Common/utils/FaroUtil.js").default;
      var faro = new FaroUtil();
      faro.initialize();
    } catch (e) {
      sendLobbyDebugLog({
        type: "FARO_FAILED_TO_INITIALISE",
        error: JSON.stringify(e),
      });
    }
  };

  const dataFromPlatform = (event) => {
    let date = new Date();
    console.log(
      `Message from Platform ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} >>> ${
        event.data
      }`
    );

    let data = event.data;
    try {
      data = JSON.parse(event.data);
    } catch (error) {
      console.log("Error at JSON parse dataFromPlatform:", error);
      data = undefined;
    }
    // let data = JSON.parse(event.data);

    if (data) {
      dispatch(setIsWebView({ isWebView: true }));
    }
    switch (data && data.type && data.type.toLowerCase()) {
      case "token":
        console.log("Token data is ", data);
        // Checking if device is unsupported
        if (checkIfFoldDeviceInTabletMode(data)) {
          dispatch(
            openModal({ type: UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE })
          );
          return;
        } else if (checkIfUnsupportedDevice(data)) {
          dispatch(openModal({ type: UNSUPPORTED_DEVICE }));
          return;
        } else {
          loadImages();
        }
        if (data.screenName && data.screenName !== "") {
          dispatch(setPlayerDetails(data));
          let payload = {
            Site: {
              Name: data.screenName,
              Identity: data.screenName,
            },
          };
          CleverTapUtil.getInstance().loginUser(payload);
          messageToNativeClient({
            type: "getBalance",
            token: data.body,
          });
          // messageToNativeClient({ type: "appLoaded" });
          setAppLoaded(true);
        } else {
          dispatch(openModal({ type: FORCE_LOGOUT }));
        }
        if (
          data?.playerStatus === DELETED_PLAYER ||
          data?.playerStatus === TO_BE_DELETED_PLAYER
        ) {
          dispatch(openModal({ type: DELETED_PLAYER_LOGOUT }));
        }
        if (window && window.faro) {
          //handle this if screenname is empty or missing
          const { screenName, userId } = data;
          window.faro.api.setUser({
            id: userId,
            username: screenName,
            attributes: { userDetails: JSON.stringify(data) },
          });
        }

        break;
      case "balance":
        dispatch(updatePlayerBalance(data));
        if (
          data.playerStatus === DELETED_PLAYER ||
          data.playerStatus === TO_BE_DELETED_PLAYER
        ) {
          dispatch(openModal({ type: DELETED_PLAYER_LOGOUT }));
        }
        break;
      case "logout":
        let gamesLength = Object.keys(getGameSlice().games);
        console.log("gamesLength:", gamesLength);
        if (gamesLength?.length > 0) {
          // console.log("SPRUHA LOG >>> CANT LOG OUT. HE HAS GAMES!");
          messageToNativeClient({ type: "openSideDrawer", isOpened: false });
          dispatch(openModal({ type: LOGOUT_WARNING }));
        } else {
          messageToNativeClient({ type: "openLogout" });
        }
      case "orientation":
        if (data.viwe === "portrait" || data.viwe === "landscape") {
          dispatch(resizingTable());
        }
        if (data.view === "portraitAck" || data.view === "landscapeAck") {
          dispatch(setResizingToComplete());
        }
        break;
      case "remindmestatus":
        dispatch(updateRemindMeStatusToServer({ ...data }));
        break;
      case "playnowdata":
        console.log("LB playnow data ", data);
        dispatch(joinGameFromLeaderBoard(data));
        break;
      case "lbdata":
        dispatch(leaderBoardGamesList(data));
        break;
      case "plpclick":
        let location = data.deepLink?.Location?.toLowerCase().trim();
        let restrictedFeatures = [
          POKER_LEADERBOARD.toLowerCase(),
          RAKEBACK_PAGE.toLowerCase(),
          MISSIONS_PAGE.toLowerCase(),
          TOURNEY_LOBBY.toLowerCase(),
          TOURNEY_REGISTRATION.toLowerCase(),
          MYTICKET.toLowerCase(),
          GAME_TABLE.toLowerCase(),
        ];
        if (!FUN_CHANNELS.includes(channel)) {
          if (location === POKER_LEADERBOARD.toLowerCase()) {
            let dataObject = {
              Location: data.deepLink?.Location?.trim(),
            };
            if (data.deepLink?.LBID) {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: {
                  ...dataObject,
                  Tab: data.deepLink.Tab.trim(),
                  SubTab: data.deepLink.SubTab,
                  LBID: data.deepLink.LBID.trim(),
                  Game: data.deepLink.Game.trim(),
                },
              });
            } else if (data.deepLink?.SubTab) {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: {
                  ...dataObject,
                  SubTab: data.deepLink.SubTab.trim(),
                  Tab: data.deepLink.Tab.trim(),
                  Game: data.deepLink.Game.trim(),
                },
              });
            } else {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: data.deepLink,
              });
            }
          } else if (location === RAKEBACK_PAGE.toLowerCase() && !hideRakeBack) {
            dispatch(openRakebackPage());
            // messageToNativeClient({ type: "openRakeBack" });
          } else if (location === MISSIONS_PAGE.toLowerCase()) {
            messageToNativeClient({ type: "openMissions" });
          } else if (
            location === CASH_LOBBY.toLowerCase() ||
            location === TOURNEY_LOBBY.toLowerCase() ||
            location === GAME_TABLE.toLowerCase() ||
            location === TOURNEY_REGISTRATION.toLowerCase() ||
            location === HOME_LOBBY.toLowerCase() ||
            location === MYTICKET.toLowerCase()
          ) {
            dispatch(handleDeeplink(data));
          } else {
            if (location && location !== "") {
              console.log(
                "plpclick for unhandled location=",
                location,
                data.deepLink
              );
              dispatch(
                addLobbyToastMessage({
                  type: TOAST_INFO,
                  message: REDIRECTION_FAIL,
                })
              );
              let timeStamp = getISTDateTimeFormat();
              CleverTapUtil.getInstance().logEvent(Poker_Redirection_Fail, {
                Username: player.userName,
                Channel: channel,
                Timestamp: timeStamp,
                KVPairs: data.deepLink,
              });
              sendFaroLog({
                type: Poker_Redirection_Fail,
                Username: player.userName,
                Channel: channel,
                Timestamp: timeStamp,
                KVPairs: data.deepLink,
              });
              dispatch(clearDeeplink());
            }
          }
        }
        break;
      case "pokerrblogin":
        dispatch(setRakebackGameDefinitions(data));
        break;

      case "pokerrbchange":
        dispatch(updatePlayerRakebackLevel(data));
        data?.serverData?.type === "upgrade" &&
          dispatch(openModal({ type: RAKEBACK_LEVEL_UPGRADE }));
        break;

      case "internet":
        LobbyConnection.getInstance().toggleEventBasedNetworkStatus();
        TableUtils.getInstance().toggleEventBasedNetworkStatus();
        break;

      case "useconsent":
        dispatch(setPlayerConsentDetails(data));
        break;

      case "gamejoincheckack":
        dispatch(setGameJoinCheckAck(data));
        break;
      case "homepage":
        dispatch(updateHomePageData({ homeData: data }));
        break;
      case "getNotifications":
        dispatch(updateNotificationCount(data));
        break;
      case "logoutsuccess":
        // IOS specific
        console.log("removing lobby connection");
        LobbyConnection.getInstance().removeLobbyConnection();
        let allGames = getGameSlice().games;
        for (let eachGameTempTableId in allGames) {
          TableUtils.getInstance().removeTableObserver(eachGameTempTableId);
        }
        break;

      default:
        return null;
    }
  };

  const checkVersion = () => {
    let clientVersion = process.env.REACT_APP_CLIENT_VERSION;

    try {
      if (window.XMLHttpRequest) {
        let locationURL = new URL(window.location.href);
        console.log("Location URL: ", locationURL);

        let xmlRequest = new XMLHttpRequest();
        xmlRequest.onreadystatechange = (Event) => {
          if (Event.target.readyState === 4) {
            let xmlDocument = xmlRequest.responseXML;
            console.log("XML_DOCUMENT_RESPONSE", xmlDocument);
            if (xmlDocument) {
              let versionElement = xmlDocument.getElementsByTagName("version");
              let version = versionElement[0].textContent;
              console.log(
                "CLIENT_VERSION_NUMBER --> ",
                version,
                "CLIENT_VERSION --> ",
                clientVersion
              );
              if (version !== clientVersion) {
                let redirectionURL = new URL(
                  process.env.REACT_APP_CLIENT_ORIGIN +
                    process.env.REACT_APP_CLIENT_URL_PATH_NAME +
                    "index.html"
                );

                let locationURLSearchParams = new URLSearchParams(
                  locationURL.search
                );
                locationURLSearchParams.set("t", Math.random()); //replaces the existing timestamp (if any)

                redirectionURL.search = locationURLSearchParams.toString(); //updates with the remaining (if any) search params
                redirectionURL.hash = locationURL.hash; //hash (if already exists) in URL to be added

                window.location.href = redirectionURL.href;
              }
            }
          }
        };

        xmlRequest.open(
          "GET",
          process.env.REACT_APP_CLIENT_ORIGIN +
            process.env.REACT_APP_CLIENT_URL_PATH_NAME +
            "version.xml?t=" +
            Math.random(),
          true
        );

        xmlRequest.responseType = "document";
        xmlRequest.send();
      }
    } catch (error) {
      console.log("Error at check version:", error);
    }
  };

  return (
    <div
      className='App'
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      {getIsFaroEnabled() ? (
        <FaroErrorBoundary>
          <PokerLobby accessToken={accessToken} />
        </FaroErrorBoundary>
      ) : (
        <PokerLobby accessToken={accessToken} />
      )}
    </div>
  );
}

export default App;
