import styled from "styled-components";
import colors from "../../styleGuide/Colors";

export const CollapseCardContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 35px;
  margin-bottom: 2.5%;
  overflow: hidden;
  animation: ${(props) =>
    props.isCardExpanded
      ? "open-collapse-card-height 0s forwards"
      : props.playInitialAnimation
      ? "close-collapse-card-height 0.5s forwards"
      : null};

  @keyframes open-collapse-card-height {
    0% {
      max-height: 35px;
    }

    100% {
      max-height: 1000px;
    }
  }

  @keyframes close-collapse-card-height {
    0% {
      max-height: 1000px;
    }

    100% {
      max-height: 35px;
    }
  }
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0% 2%;
  background: linear-gradient(to right, #044859, #075063);
  box-shadow: 0px 0px 5px black;
  z-index: 2;
`;

export const CollapseCardTitle = styled.span`
  color: ${colors.primaryGold};
  font-size: 12px;
  text-shadow: 0px 1px 1px black;
`;

export const CollapseCardHeaderCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`

export const OpenCloseButton = styled.button`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenCloseButtonIcon = styled.img`
  width: 12px;
  height: 7px;
  animation: ${(props) =>
    props.isCardExpanded
      ? "turn-up-arrow 0.5s forwards"
      : props.playInitialAnimation ?  "turn-down-arrow 0.5s forwards" : null};

  @keyframes turn-up-arrow {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes turn-down-arrow {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export const CardDetails = styled.div`
  /* border-top: 0.5px solid ${colors.navy2}; */
  /* min-height: 100px; */
  position: relative;
  /* background: ${colors.primaryBlue}; */
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transform: translateY(-100%);
  animation: ${(props) =>
    props.isCardExpanded
      ? "open-collapse-card 0.5s forwards"
      : props.playInitialAnimation
      ? "close-collapse-card 0.5s forwards"
      : null};
  z-index: 1;

  @keyframes open-collapse-card {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  @keyframes close-collapse-card {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-100%);
    }
  }
`;
