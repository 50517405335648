import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TIMER_TYPE_BUY_IN } from "../../../Poker/data/Constants";
import colors from "../../styleGuide/Colors";
import {
  cancelAnimation,
  linear,
  playAnimation,
} from "../Animations/Animation";
import {
  CircularDisc,
  CircularProgressInner,
  CircularProgressOuter,
  Container,
  TimerValue,
  ValueContainer,
} from "./TimerStyles";

export const BuyInTimer = (props) => {
  const { type, timer } = props;
  const circularProgressInnerRef = useRef();
  const circularProgressOuterRef = useRef();
  const circularDiscRef = useRef();
  const [totalTime, setTotalTime] = useState(timer);
  const [initialTime, setInitialTime] = useState(timer);
  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );

  let frame, interval;

  const intervalRef = useRef(null);

  useEffect(() => {
    // Starting the progress animation for timer.
    if (applicationVisibility) {
      timerAnimation(0);
    }
  }, []);

  useEffect(() => {
    // Counting down the timer.
    if (timer) {
      intervalRef.current = setInterval(() => {
        setTotalTime((prev) => (prev !== 0 ? prev - 1 : prev));
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef?.current);
      cancelAnimation(frame);
    };
  }, []);

  useEffect(() => {
    setTotalTime(timer);
    cancelAnimation(frame);
    let offset = (initialTime - totalTime) / initialTime;
    if (applicationVisibility) {
      timerAnimation(offset);
    } else {
      cancelAnimation(frame);
      clearInterval(interval);
    }
  }, [timer, applicationVisibility]);

  useEffect(() => {
    // This is to auto close the buy in modal when it touches 0.
    if (type === TIMER_TYPE_BUY_IN) {
      if (totalTime === 0) {
        props?.buyInCloseFunction();
        return;
      }
    }
  }, [totalTime]);

  const timerAnimation = (offset, timer = totalTime) => {
    frame = playAnimation({
      duration: timer * 1000,
      timing: (timeFraction) => linear(timeFraction),
      draw: (progress) => timerProgress(progress, offset),
    });
  };

  const timerProgress = (progress, offset) => {
    try {
      if (
        circularProgressOuterRef.current !== null &&
        circularProgressInnerRef.current !== null
      ) {
        let color =
          progress * 100 < 50
            ? colors.timerGreen
            : progress * 100 > 50 && progress * 100 < 80
            ? colors.timerOrange
            : colors.timerRed;

        if (type === TIMER_TYPE_BUY_IN) {
          circularProgressOuterRef.current.style.background = `conic-gradient(
        rgba(0,0,0,0)  ${(progress + offset) * 100 * 3.6}deg, ${color}  ${
            (progress + offset) * 10 * 3.6
          }deg)`;
          circularDiscRef.current.style.background = " rgba(0,0,0,0)";
          circularProgressInnerRef.current.style.background =
            colors.primaryBlue;
        }

        if (progress === 1) {
          cancelAnimation(frame);
          circularProgressInnerRef.current.style.background = "transparent";
          circularProgressOuterRef.current.style.background = "transparent";
          circularDiscRef.current.style.background = "transparent";
          circularProgressInnerRef.current.style.transition = "all 0.2s ease";
          circularProgressOuterRef.current.style.transition = "all 0.2s ease";
          circularDiscRef.current.style.transition = "all 0.2s ease";
        }
      }
    } catch (err) {
      console.log("ERROR IN TIMER PROGRESS:", err);
    }
  };

  return timer > 0 && totalTime > 0 ? (
    <Container totalTime={totalTime} type={type} id="timer">
      <CircularProgressOuter type={type} ref={circularProgressOuterRef}>
        <CircularDisc type={type} ref={circularDiscRef}>
          <CircularProgressInner type={type} ref={circularProgressInnerRef}>
            <ValueContainer totalTime={totalTime} type={type}>
              <TimerValue>{totalTime}</TimerValue>
            </ValueContainer>
          </CircularProgressInner>
        </CircularDisc>
      </CircularProgressOuter>
    </Container>
  ) : null;
};
