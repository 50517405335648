import Handler from "../Handler";

class TourneySlabChangeHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneySlabChange(receivedData);
  }
}
export default TourneySlabChangeHandler;
//SlabChange#{"event":"slab-change","tourneyId":"VKkaS","winnersPayoutSlab":{"1":40.0,"2":20.0,"3":10.0,"4":10.0},"numberOfWinners":4}
