import Handler from "../Handler";

class RebuyFailureHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRebuyFailure(receivedData);
  }
}

export default RebuyFailureHandler;

// RebuyInF#{“status”:””FAILURE,”playerName":"angel459889","Amount":900.0,”statusCode”:503,”buyInMessage”:"reBuy in failed!"}
