import Handler from "../Handler"

class LobbyConnectionAckHandler extends Handler {
  execute(data){
    // {"authToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ6amVtODd4cnpoMDMyY24iLCJzY3JlZW5OYW1lIjoia2FtbGVzaCIsIm1vYmlsZSI6Iis5MTk3ODQ1NjQzMTgiLCJzdGF0dXMiOnRydWUsImRldmljZV9pZCI6IjU0ZjFjMmZlNGZiODYzOTkiLCJjaGFubmVsIjoiQTIzQVBTIiwicGxheWVyU3RhdHVzIjoibnVsbCIsImlhdCI6MTY3MTY4OTgyNCwiZXhwIjoxNjcxNzc2MjI0fQ.pFaX_TFGx_CEae-ewBerl57OZna5JlN8873NaoPdN88","a23Token":"jnwNsLduDmrJuz7E9EaWYPxbAYs3rJoktrDcXlJNUaplmfG41flmpos+tHV+TN67MQmnubvHdL1nloEKXHOLscnDqIOU20aazuNBbK/lIqfirmdSm4NEb1ookGU4zgJ1+82XMyOhk2wSLpL8MbpO48wce2PWCPtyw2iJC8tGJn5M9T21l6nn3aLwzRHwJC66qMzWuB5Dq6Z4OldADxf4Kq/uCiFilbXzhqg86bSq3nDCXRAteb0BG4dNiaRlKOQtiuEFpYpPhq7C50yaDkTmGprbk12kEJX+yC9kPCaUpgwOB3dTmQhpzCVSufUggd89","playerName":"kamlesh","deviceId":"54f1c2fe4fb86399","channel":"A23APS","profilePic":"https://images.a23games.in/avatars/poker_avatar.png","userId":"zjem87xrzh032cn"}
    data = JSON.parse(data);
    console.log(data)
    this.service.lobbyConAck(data);
  }
}


export default LobbyConnectionAckHandler