import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import spinner from "./spinner.json";
// import loader from "./poker-loader.json";

export const Loader = ({size, margin,}) => {
  const lottieAnimationRef = useRef(null);
  const loaderTimeout = useRef(null);

  useEffect(() => {
    loaderTimeout.current = setTimeout(() => {
      lottieAnimationRef?.current?.play();
    }, 100);

    return () => clearTimeout(loaderTimeout.current);
  }, []);

  return (
      <Lottie
        style={{
          width: size ? size + 'px' : "100%",
          height: size ? size + 'px' : "100%",
          margin: margin ? margin + 'px 0px' : 0,
        }}
        animationData={spinner}
        ref={lottieAnimationRef.current}
        autoPlay={true}
        loop={true}
      />
  );
};
