import React, { useEffect, useRef, useState } from "react";
import { CircularProgressContainer, PercentageValue } from "./TableStatsStyle";

const CircularProgress = ({ percentageValue, innerColor, outerColor, percentageDisplay }) => {
  const [percentage, setPercentage] = useState(0);
  const [transitionTime, setTransitionTime] = useState(1000);
  const intervalRef = useRef(null)
  // let interval;

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setPercentage((prev) => (prev !== percentageValue && prev !== percentageDisplay ? prev + 1 : prev));
    }, transitionTime / Math.floor(percentageValue));

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (percentage === percentageValue) {
      clearInterval(intervalRef.current);
      setPercentage(percentageDisplay);
    }
  }, [percentage]);

  return (
    <CircularProgressContainer
      innerColor={innerColor}
      outerColor={outerColor}
      percentage={percentage}
    >
      <PercentageValue>{percentage}%</PercentageValue>
    </CircularProgressContainer>
  );
};

export default CircularProgress;
