import {
  EXIT_TABLE_ACK_FAILURE,
  EXIT_TABLE_ACK_SUCCESS,
} from "../../data/Constants";
import Handler from "../Handler";

class CloseAckHandler extends Handler {
  execute(message) {
    //closea#{playerName:”abc”,tableId:”fvd”,status:100}
    let receivedData = JSON.parse(message);
    // let ackStatus =
    //   receivedData.status === 100
    //     ? EXIT_TABLE_ACK_SUCCESS
    //     : receivedData.status === false
    //       ? EXIT_TABLE_ACK_FAILURE
    //       : EXIT_TABLE_ACK_SUCCESS;

    this.service.closeTableAck();
  }
}

export default CloseAckHandler;
