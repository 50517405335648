import Handler from "../Handler";

class NextLevelUpHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processNextLevelUp(receivedData);
  }
}

export default NextLevelUpHandler;
// NextLevel#{"minutesLeft":0,"smallBlind":120.0,"bigBlind":240.0,"ante":50.0}
