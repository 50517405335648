import Handler from "../Handler";

class AutoUserActionsHandler extends Handler {
  execute(message) {
    /**AutoUserActions#{"position":3,"callAny":true,"call":false,"callAmount":0.0,"checkFold":true,"check":true,"fold":false}*/

    let receivedData = JSON.parse(message);
    this.service.processAutoUserActions(receivedData);
  }
}

export default AutoUserActionsHandler;

// AutoUserActions#{
//   "position": 3,
//   "callAny": true,
//   "call": false,
//   "callAmount": 0,
//   "checkFold": true,
//   "check": true,
//   "fold": false
// }
