export function setToLocalStorage(identifier, value) {
  try {
    localStorage.setItem(identifier, value);
  } catch (e) {
    console.log("Error at setting local storage variable");
  }
}

export function getFromLocalStorage(identifier) {
  try {
    const value = JSON.parse(localStorage.getItem(identifier));
    return value;
  } catch (e) {
    console.log("Error at getting local storage variable");
    return null;
  }
}
