import Handler from "../Handler";

class TourneyRebuyCancleHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoTourneyRebuyCancle(receivedData);
  }
}
export default TourneyRebuyCancleHandler;
// TRebuyCancel#{String tourneyId;
// private String playerName}
