class GameConnections {
  static gameConnectionsInstance;

  constructor() {
    if (!GameConnections.gameConnectionsInstance) {
      this.gameConnections = [];
      GameConnections.gameConnectionsInstance = this;
    }
    return GameConnections.gameConnectionsInstance;
  }

  addGameConnection(connectionObj) {
    this.gameConnections.push(connectionObj);
  }

  getGameConnection(tempTableId) {
    for (let index = 0; index < this.gameConnections.length; index++) {
      let connection = this.gameConnections[index];
      if (connection.tempTableId === tempTableId) {
        return connection;
      }
    }
  }

  removeGameConnection(tempTableId) {
    let gameConnectionRemovalStatusObject = {
      isConnectionObjFound: false,
      isRemoved: false,
    };

    for (let index = this.gameConnections.length - 1; index >= 0; index--) {
      let connection = this.gameConnections[index];
      console.log("\nConnection temp: ", connection.tempTableId);
      console.log("received temp: ", tempTableId);
      if (connection.tempTableId === tempTableId) {
        gameConnectionRemovalStatusObject.isConnectionObjFound = true;
        console.log("Temp table id matched for remove connection");
        try {
          let messageHandler = connection.messageHandler;
          if (messageHandler) {
            messageHandler.doCleanUp(true);

            this.gameConnections.splice(index, 1);
            gameConnectionRemovalStatusObject.isRemoved = true;
            break;
          }
        } catch (e) {
          console.log(
            "Error at removeGameConnection with tempTableId " +
              tempTableId +
              ":",
            e
          );
        } finally {
          console.log(
            "Game socket removal status -- " +
              gameConnectionRemovalStatusObject.isConnectionObjFound
              ? "connection object found -- " +
                gameConnectionRemovalStatusObject.isRemoved
                ? +"removed"
                : "not removed"
              : " connection object not found"
          );
          break;
          //   return gameConnectionRemovalStatusObject;
        }
      }
      //   return gameConnectionRemovalStatusObject;
    }
  }

  static getInstance() {
    if (!GameConnections.gameConnectionsInstance) {
      return new GameConnections();
    } else {
      return GameConnections.gameConnectionsInstance;
    }
  }
}

export default GameConnections;
