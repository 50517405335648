class Handler {
  constructor(service, gameDefinition, player) {
    this.service = service;
    this.gameDefinition = gameDefinition;
    this.player = player;
  }
  execute(data) {
    return null;
  }
}

export default Handler;
