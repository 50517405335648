import Handler from "../Handler";

class CloseBroadcastHandler extends Handler {
  execute(message) {
    //CloseB#{"playerName":"testuser","tableId":"THR170823-2202673xhj43","status":100}
    let receivedData = JSON.parse(message);
    this.service.processLeaveBroadcast(receivedData);
  }
}

export default CloseBroadcastHandler;
