import Handler from "./../Handler";

class ChipsReloadAckHandler extends Handler {
  execute(data) {
    let parsedData = JSON.parse(data);
    this.service.processPlayerChipsReload(parsedData);
  }
}

export default ChipsReloadAckHandler;
