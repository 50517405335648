import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const RBWrapper = styled.div`
  width: 80%;
  max-width:400px;
  height: fit-content;
  max-height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  border-radius: 8px;
`;

export const RBModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  color:white;
  position: relative;
`;

export const RBHeaderTitleText = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #06404F;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: #032931;
  font-size: 16px;
  font-weight: bold;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  border-radius: 8px 8px 0 0;
  position: relative;
  box-sizing: border-box;
  .close-icon {
    margin-top: -6px;
  }
`;

export const RBBodyContainer = styled.div`
  background: #032931;
  padding: 1rem;
  display: grid;
  gap: 0.5rem;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  .rewards-button{
    font-weight: 700;
    font-size: 14px;
    width: 45%;
    height: 34px;
    justify-self: center;
  }
`;

export const RBLevelImage = styled.img`
  width: 130px;
  // height: 120px;
`

export const RBImageText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 25%;
`
export const RBDetails = styled.div`
  display:flex;
  flex-direction: column;
  justif-content: center;
  align-items: center;
  font-size: 14px;
  margin:2.5% 0;
`

export const RBImagePercent = styled.span`
  font-size: 18px;
  font-weight: bold;
`

export const RBText = styled.span`
  font-size: 10px;
`

export const CloseButton = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
  width: 14px;
`;
