import Handler from "../Handler";

class FoldActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processFoldActionAck(receivedData);
  }
}

export default FoldActionHandler;
