import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10WhiteContoMediumText,
  Typo13PebbleGrayContoMediumText,
} from "../../../Common/styleGuide/Typos";
import { LANDSCAPE } from "../../data/Constants";

export const GameDetailsWrapper = styled.div`
  min-width: ${(props) => (props.orientation === LANDSCAPE ? 150 : 100)}px;
  position: absolute;
  left: ${(props) => (props.orientation === "LANDSCAPE" ? "94%" : "50%")};
  /* top: ${(props) => (props.top ? props.top + "px" : "69%")}; */
  top: ${(props) => (props.orientation === "LANDSCAPE" ? "5%" : "65%")};
  z-index: 3;
  text-align: ${(props) =>
    props.orientation === "LANDSCAPE" ? "right" : "center"};
  /* display: ${(props) => (props.top && props.left ? "block" : "none")}; */
  transform: ${(props) =>
    props.orientation === "LANDSCAPE"
      ? "translate(-75%, 0%)"
      : "translateX(-50%)"};
  pointer-events: none;
`;

export const Details = styled.span`
  display: block;
  letter-spacing: 0.25px;
  font-size: 6px;
  font-weight: bold;
  color: rgba(213, 213, 213, 0.25);
`;

export const HappyHoursGlass = styled.img`
  width: 8px;
  margin-left: 4px;
  opacity: 0.4;
`

export const TourneyNameWrapper = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  background: rgba(234, 191, 105, 0.15);
  margin-bottom: 4px;
`;

export const TourneyNameText = styled(Typo10WhiteContoMediumText)`
  line-height: normal;
`;
