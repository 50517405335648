import Handler from "../Handler";

class ManageUserActionHandler extends Handler {
  execute(message) {

    let receivedData = JSON.parse(message);
    this.service.processUserActionStatus(receivedData);
  }
}

export default ManageUserActionHandler;