import Handler from "../Handler";

class CallActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processCallActionAck(receivedData);
  }
}

export default CallActionHandler;
