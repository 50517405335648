import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const ConsolidatedMenuContainer = styled.div`
  width: 35%;
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  max-height: var(--app-height);
  position: absolute;
  left: 0;
  overflow: scroll;
  border-top-right-radius: 8px;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "left-right 0.25s forwards"
      : "right-left 0.25s forwards"};
  /* background: linear-gradient(to bottom, #064656, #053642, #053642, #053642); */
  background: ${colors.primaryBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConsolidatedMenuHeader = styled.div`
  height: 50px;
  min-height: 50px;
  padding: 0% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.primaryGold};
  text-shadow: 0px 2px 2px black;
  background: ${colors.deepBlue};
  font-size: 14px;
  font-weight: bold;
`;

export const GameControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5% 0%;
  border-bottom: 1px solid #02222b;
  border-top: 2px solid #0d4d5e;
`;

export const GameControl = styled.div`
  display: ${(props) =>
    props.name === "Sit Out" ||
    props.name === "Sit In" ||
    props.name === "Leave Seat"
      ? props.mySeat !== null && props.mySeat !== undefined
        ? "flex"
        : "none"
      : "flex"};
  flex-direction: row;
  /* justify-content: ${(props) =>
    props.name === "Sit Out" ? "space-between" : null}; */
  align-items: center;
  padding: 4% 10%;
  position: relative;
  opacity: ${(props) =>
    (props.name === "Sit Out" ||
      props.name === "Sit In" ||
      props.name === "Leave Seat" ||
      props.name === "Exit Table") &&
    props.restricted
      ? //   ||
        // ((props.name === "Landscape Mode" ||
        //   props.name === "Portrait Mode" ||
        //   props.name === "Go to Lobby") &&
        //   props.disableModeChange)
        0.5
      : 1};
`;

export const GameControlName = styled.span`
  margin-left: 5%;
  color: white;
  font-size: 12px;
`;

export const GameToggleButton = styled.div`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
`;

export const GameControlIcon = styled.img`
  height: 18px;
  min-width: 25px;
`;

export const ArrowOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #02222b;
  border-top: 2px solid #0d4d5e;
  padding: 5% 4%;
`;

export const ArrowOptionLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ArrowOptionIcon = styled.img`
  width: 20px;
  height: auto;
`;

export const ArrowOptionName = styled.span`
  margin-left: 10%;
  color: white;
  font-size: 12px;
`;

export const ArrowOptionRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`;

export const ArrowIcon = styled.img`
  width: 10px;
  position: relative;
  right: 10%;
  height: auto;
`;

export const ConsolidatedMenuBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  height: 10%;
  min-height: 100px;
  padding-bottom: 5%;
`;

export const BottomRowOne = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40%;
`;

export const GameInfoButton = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  background-color: #0d4656;
  width: 48%;
  margin-right: 0.5%;
  height: 100%;
`;

export const HandRankingButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  background-color: #0d4656;
  width: ${(props) => (props.isTourneyGame ? "97" : "48")}%;
  margin-left: ${(props) => (props.isTourneyGame ? "0" : "0.5")}%;
  height: 100%;
  justify-content: ${(props) => (props.isTourneyGame ? "center" : "unset")};
`;

export const BottomRowTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  background-color: #0d4656;
  width: 97%;
  margin: 1% auto;
`;

export const WalletInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
`;

export const WalletText = styled.span`
  margin-left: 5px;
  color: ${colors.primaryGold};
  font-weight: bold;
`;

export const AddCashButton = styled.div`
  /* width: 50px; */
  /* height: 25px; */
  background: ${colors.primaryGreen};
  height: 60%;
  width: 45%;
  max-height: 40px;
  max-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
`;
