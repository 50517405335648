import Handler from "../Handler";

class CloseFailureHandler extends Handler {
  execute(message) {
    // closef#
    this.service.processCloseFailure();
  }
}

export default CloseFailureHandler;
