import Handler from "../Handler";

class StartGameTimerHandler extends Handler {
  execute(message) {
    /**Stime#{"startGameTime":"0","tableId":"THR070823-1335398zhf8a","gameId":"070823-1335398m1hop"}*/

    let receivedData = JSON.parse(message);
    this.service.processStartGameTimer(receivedData);
  }
}

export default StartGameTimerHandler;
