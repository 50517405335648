import Handler from "../Handler";

class TourneyAddOnWithBreakHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyAddOnWithBreak(receivedData);
  }
}

export default TourneyAddOnWithBreakHandler;

// TourneyAddOn#{
//   "tableId": "TOTNSE300324-241154727159o0nvm",
//   "message": "AddOn Break",
//   "endTime": 1711830889657,
//   "addOnDetailsToClient": {
//     "tourneyId": "5AnSE",
//     "addOnEntryFee": 20,
//     "chipsToBeGiven": 2000
//   }
// }
