import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const GameTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: #042C36; */
  background: linear-gradient(180deg, #084e60 2.27%, #023643 102.27%);
  /* background: linear-gradient(180deg, #084e60 2.27%, #023643 102.27%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); */

  border-radius: 6px;
  /* padding: 12px; */
  /* height: 95px; */
  box-sizing: border-box;
  border: 1px solid #2c515e;
  transform: ${(props) => (props.squeezeCard ? "scale(0.9)" : "scale(1)")};
  transition: 0.25s ease;
  animation: fade-in 0.5s forwards;
  box-shadow: 0px 2px 1px 0px #00000066;
  min-height: 91px;
  margin-bottom: 12px;
`;
