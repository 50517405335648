import Handler from "../Handler";

class TourneyRankingLeaderboardHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyRankingLeaderboardData(receivedData);
  }
}
export default TourneyRankingLeaderboardHandler;

// {
//     "tourneyId": "N36YV",
//     "tourneyName": "Test",
//     "buyIn": 0,
//     "rankDataList": [
//         {
//             "playerName": "emashutosh",
//             "playerChips": 1000,
//             "isBustedOut": false,
//             "bustedOutTimeStamp": 0,
//             "rank": 0,
//             "winner": false,
//             "winningAmount": 0,
//             "winningDistributed": false
//         },
//         {
//             "playerName": "gg11",
//             "playerChips": 1000,
//             "isBustedOut": false,
//             "bustedOutTimeStamp": 0,
//             "rank": 0,
//             "winner": false,
//             "winningAmount": 0,
//             "winningDistributed": false
//         }
//     ],
//     "currentLevel": 1,
//     "maxLevel": 10,
//     "nextLevel": 2,
//     "nextBlindIn": 120,
//     "prizePool": 1000,
//     "totalNumberOfWinners": 8,
//     "maxRegistrationAllowed": 6,
//     "currRegistration": 2,
//     "highestChipStack": 1000,
//     "lowestChipStack": 1000,
//     "averageChipStack": 1000,
//     "lateReg": true,
//     "lateRegEndTimeInMin": 20,
//     "addonSettings": {
//         "chipsToBeGranted": 2000,
//         "addonTimeInterval": 10,
//         "addonBuyin": 10,
//         "addonEntryFee": 10
//     },
//     "rebuySettings": {
//         "chipsBalanceForRebuyTrigger": 5000,
//         "chipsToBeGranted": 1000,
//         "doubleRebuy": true,
//         "noOfRebuys": 5,
//         "rebuyBuyin": 10,
//         "rebuyEntryFee": 10,
//         "rebuyLevel": 2
//     },
//     "tableDetailsList": {
//         "listOfTableDetails": []
//     },
//     "winnersPayoutInNumber": {
//         "1": 250,
//         "2": 250,
//         "3": 100,
//         "4": 100,
//         "5": 100,
//         "6": 66.67,
//         "7": 66.67,
//         "8": 66.67
//     },
//     "tableDetailsForClientList": [
//         {
//             "domainName": "ws://15.206.204.28:8008/websocket",
//             "nodeIp": "15.206.204.28",
//             "tableID": "TOT6YV250324-023124620428f0aby",
//             "numberOfPlayers": 2,
//             "highestChips": 1000,
//             "lowestChips": 1000
//         }
//     ]
// }
