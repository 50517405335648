import React, { useState } from "react";
import {
  ActiveGamesStickyContainer,
  ActiveGamesText,
  ActiveTablesArrow,
  ActiveTablesIcon,
  NumberOfTablesContainer,
  NumberOfTablesValue,
  RippleCircle,
  ShimmerFat,
  ShimmerLightingContainer,
  ShimmerThin,
} from "./styledComponents";
import activeTablesIcon from "../../../assets/lobby/activeTables.png";
import { useSelector } from "react-redux";

const ActiveGamesStickyButton = ({ toggleGameTable, tileScrollStatus }) => {
  const games = useSelector((state) => state.gameTable.games);
  const isPlayerTurnOnAnyTable = useSelector(
    (state) => state.gameTable.isPlayerTurnOnAnyTable
  );

  const handleGoToActiveGames = () => {
    toggleGameTable();
  };

  return Object.keys(games).length >= 1 ? (
    <ActiveGamesStickyContainer
      tileScrollStatus={tileScrollStatus}
      onTouchStart={() => handleGoToActiveGames()}
    >
      <ShimmerLightingContainer>
        <ShimmerThin></ShimmerThin>
        <ShimmerFat></ShimmerFat>
      </ShimmerLightingContainer>
      <ActiveTablesIcon src={activeTablesIcon}></ActiveTablesIcon>
      <ActiveGamesText>Active</ActiveGamesText>
      <NumberOfTablesContainer>
        {isPlayerTurnOnAnyTable ? <RippleCircle></RippleCircle> : null}
        <NumberOfTablesValue>{Object.keys(games).length}</NumberOfTablesValue>
      </NumberOfTablesContainer>
      {/* <ActiveTablesIcon src={activeTablesIcon}></ActiveTablesIcon> */}
      {/* <ActiveTablesArrow src={arrowIcon}></ActiveTablesArrow> */}
    </ActiveGamesStickyContainer>
  ) : null;
};

export default ActiveGamesStickyButton;
