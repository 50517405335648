import Handler from "../Handler";

class RabbitHuntingHandler extends Handler {
  execute(message) {
    // rabbith --- ["d1","c1","c11"]
    let receivedData = JSON.parse(message);
    this.service.processRabbitHunting(receivedData);
  }
}

export default RabbitHuntingHandler;
