import Handler from "../Handler";

class JoinBroadcastHandler extends Handler {
  execute(message) {
    //JoinB#{“position”: 3,“playerName”: ”IPMANplayer”,“atTableAmount”:123,“acelevel”:”acelevel”,“profilePic”:”profilePic” }
    let receivedData = JSON.parse(message);
    this.service.processGameJoinBroadcastMessage(receivedData);
  }
}

export default JoinBroadcastHandler;
