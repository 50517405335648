/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { sendFaroLog } from "../../Common/utils/FaroUtil";
import {
  CALL_A,
  CALL_B,
  CHIP_ANIMATION_EXECUTION_TIME,
  DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL,
  DIRECT_SHOWDOWN_REVEAL_COMMUNITY_CARDS_EXECUTION_TIME,
  REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME,
  GAME_STAGE_FLOP,
  GAME_STAGE_SHOWDOWN,
  POT_WINNER,
  POT_WINNER_EXECUTION_TIME,
  PRIZE_POT,
  PRIZE_POT_EXECUTION_TIME,
  RAISE_A,
  RAISE_B,
  RESULT,
  RESULT_ANIMATION_EXECUTION_TIME,
  REVEAL_CARDS,
  REVEAL_FLOP_CARDS_EXECUTION_TIME,
  TEXAS_START_GAME_EXECUTION_TIME,
  START_GAME_PROTOCOL,
  REFRESH_PROTOCOL,
  REFRESH_EXECUTION_TIME,
  RABBIT_HUNTING_PROTOCOL,
  RABBIT_HUNTING_PROTOCOL_EXECUTION_TIME,
  GAME_STAGE_TURN,
  GAME_STAGE_RIVER,
  TOURNEY_BREAK_BROADCAST,
  TOURNEY_BREAK_BROADCAST_EXECUTION_TIME_IN_MS,
  TOURNEY_ADDON_BREAK,
  NEXT_GAME_CANCEL,
  NEXT_GAME_CANCEL_DELAY,
} from "../data/Constants";

import Handler from "./../handlers/Handler";
import { isIOS } from "react-device-detect";
import TableUtils from "../utils/TableUtils";
import { getMLTLogFormat } from "../utils/ProtocolUtils";
import { getPlayerObject } from "../utils/ReduxUtils";
// import { updateAppVisibility } from "redux/reducers/LobbyReducer";
class BaseClient {
  constructor(configuration, clientListener, listenerType) {
    if (typeof configuration === "undefined") {
      throw new Error("No configuration found");
    }

    if (typeof clientListener === "undefined") {
      throw new Error("No listener found");
    }

    this.configuration = configuration;
    this.clientListener = clientListener;
    this.listenerType = listenerType;
    this.eventListeners = [];
    this.isConnected = false;
    this.isConnecting = false;
    //  this.inReconnectionMode = false;
    this.inReconnectionMode = this.configuration.getReconnectionMode();
    this.heartBeatHandle = undefined;
    this.isHeartbeatReceived = false;
    this.queueArray = [];
    this.delayTimeOut = undefined;
    this.isExecutionInProgress = false;
    this.clientHidden = false;
    this.gameLogStyle =
      "background: #125C2E;color: #FFFDEA; font-weight: bold; padding: 0.25rem; line-height: 1.25rem;";
    this.lobbyLogStyle =
      "background:#032028; color: #FFFDEA; font-weight: bold; padding: 0.25rem; line-height: 1.25rem;";
    this.gameExecutionStyle =
      "background: black; color: #42BE40; font-weight: bold; padding: 0.25rem; line-height: 1.25rem;";
    this.lobbyExecutionStyle =
      "background: black; color: #FFFDEA; font-weight: bold; padding: 0.25rem; line-height: 1.25rem;";
    this.maintenancePopup = false;
    this.gameStageArray = [];

    window.document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    // window.document.addEventListener(
    //   "webkitvisibilitychange",
    //   this.handleVisibilityChange
    // );

    //below listener for "offline" is commented - testing the flow @yashwanth
    // if (isIOS) {
    //   window.addEventListener("offline", this.handleOfflineEvent);
    // }
  }

  handleVisibilityChange = () => {
    this.clientHidden = window.document.hidden;

    console.log(
      "this.clientHidden: ",
      this.clientHidden,
      `${this.clientHidden ? "APP IN BACKGROUND" : "APP IN FOREGROUND"}`
    );
    // store.dispatch(updateAppVisibility({ isAppHidden: document.hidden }));

    if (this.clientHidden === true) {
      //document is hidden
      //this.sleep();
      if (this.listenerType === "gameListener") {
        console.log(
          "Invoking successive execution at hidden on:",
          this.listenerType
        );
        this.startSuccessiveExecution();
      }
    } else {
      //this.wakeUp();
      if (this.isDisconnected) {
        if (this.isConnected === true) {
          this.isConnected = false;
          this.isConnecting = false;
          this.inReconnectionMode = false;
          this.disconnectClient();
        }
      }
    }
  };

  // handleOfflineEvent = () => {
  //   this.clientListener.onReadTimedout();
  //   this.disconnectClient(null);
  // };

  start() {
    var flag = this.configuration.validateConfiguration();
    //    if(flag === true){
    //       flag = this.clientListener.validateListeners();
    //    }
    if (flag) {
      this.createConnection();
    }
  }

  createConnection() {
    try {
      let date = new Date();
      console.log(
        `Creating ${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        } Socket connection at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} to ` +
          `address ${this.configuration.getServerAddress()} - reconnection mode ${
            this.inReconnectionMode
          }`
      );

      this.isConnecting = true;
      var openProtocol = this.configuration.getServerAddress();
      openProtocol =
        this.configuration.getServerPort() !== ""
          ? openProtocol + ":" + this.configuration.getServerPort()
          : openProtocol;

      let wssString = `${this.configuration.connectionType}://`;

      this.webSocket = new WebSocket(wssString + openProtocol + "/websocket");
      this.webSocket.onopen = this.onConnect.bind(this);
      this.webSocket.onclose = this.onClose.bind(this);
      this.webSocket.onerror = this.onError.bind(this);
      this.webSocket.onmessage = this.onMessage.bind(this);
      //        this.webSocket.onopen = (function(event){
      //            console.log("On open function of evebsocket", event);
      //        });
      //        this.webSocket.addEventListener("open",onConnect.bind(this));
      //        this.webSocket.addEventListener("message",onMessage.bind(this));
      //        this.webSocket.addEventListener("close",onClose.bind(this));
      //        this.webSocket.addEventListener("error",onError.bind(this));

      if (
        this.connectionTimeoutHandle === undefined &&
        this.inReconnectionMode === false
      ) {
        this.connectionTimeoutHandle = setTimeout(
          this.onConnectionTimeout.bind(this),
          this.configuration.getConnectionTimeout() * 1000
        );
      } else if (this.inReconnectionMode) {
        if (this.reconnectionTimeoutHandle === undefined) {
          this.reconnectionTimeoutHandle = setTimeout(
            this.reconnectionTimeout.bind(this),
            this.configuration.getReconnectionTimeout() * 1000
          );
        }
      } else {
        let date = new Date();
        console.log(
          `${
            this.listenerType === "gameListener" ? "Game" : "Lobby"
          } Socket entered into unhandled condition at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} to` +
            `address ${this.configuration.getServerAddress()} - please check`
        );
      }
    } catch (error) {
      console.log(
        `${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        } Socket Error while attempting connection to address ${this.configuration.getServerAddress()}`,
        error
      );
    }
  }

  onConnect(event) {
    var date = new Date();
    console.log(
      `${
        this.listenerType === "gameListener" ? "Game" : "Lobby"
      } Socket connected at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} to ` +
        `address ${this.configuration.getServerAddress()}`
    );

    this.isConnected = true;
    try {
      //below if check of mismatch of the target is a precautionary measure, carrying it as part of standard, never really encountered this @yashwanth
      if (this.webSocket !== event.target) {
        console.warn(
          `${
            this.listenerType === "gameListener" ? "Game" : "Lobby"
          } Socket address and connect event target mismatched`,
          `websocket`,
          this.webSocket,
          `event target`,
          event.target
        );
        this.doCleanUp();
        this.createConnection();
        return;
      }

      var clientListener = this.clientListener;
      this.isDisconnected = false;

      if (this.inReconnectionMode === true) {
        clientListener.onClientReconnect();
        this.inReconnectionMode = false;
      } else {
        clientListener.onClientConnect();
      }
    } catch (error) {
      //have to show some message on the screen @yashwanth
      console.error(
        `${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        } Socket connect error`,
        error
      );
    } finally {
      //**** clearing time out and nullifying
      clearTimeout(this.connectionTimeoutHandle);
      this.connectionTimeoutHandle = undefined;
      //
      //***clearing reconnection interval in case of reconnection
      clearInterval(this.reconnectionTimeoutHandle);
      this.reconnectionTimeoutHandle = undefined;
      //
      this.isConnecting = false;
    }
  }

  // sendHeartBeat() {
  //   // heartBeatGapCount will be incremented by 1 for every invocation of sendHeartBeat();
  //   // heartBeatGapCount will be reset to 0 once a heartbeat response is received from server

  //   let heartBeatConfiguredMessage = this.configuration.getHeartBeatMessage();

  //   let heartBeatConfigurationParameters = heartBeatConfiguredMessage.split(
  //     this.configuration.getProtocolDelimiter()
  //   )[1];

  //   heartBeatConfigurationParameters =
  //     heartBeatConfigurationParameters !== ""
  //       ? JSON.parse(heartBeatConfigurationParameters)
  //       : heartBeatConfigurationParameters;

  //   let heartBeatGapDetailsObject = {
  //     gapCount: this.heartBeatGapCount,
  //     status: this.isHeartbeatReceived === true ? "T" : "F",
  //   };

  //   let heartBeatPayload = {
  //     ...heartBeatConfigurationParameters,
  //     ...heartBeatGapDetailsObject,
  //   };
  //   heartBeatPayload = JSON.stringify(heartBeatPayload);

  //   //temporary till sorted out with server
  //   heartBeatGapDetailsObject =
  //     this.listenerType === "gameListener"
  //       ? JSON.stringify(heartBeatConfigurationParameters)
  //       : JSON.stringify(heartBeatGapDetailsObject);

  //   let message =
  //     heartBeatConfiguredMessage.split(
  //       this.configuration.getProtocolDelimiter()
  //     )[0] +
  //     this.configuration.getProtocolDelimiter() +
  //     heartBeatPayload;

  //   this.sendMessage(message);

  //   this.isHeartbeatReceived = false;
  //   this.heartBeatGapCount++;
  //   this.clientListener.setHeartbeatGapCount(this.heartBeatGapCount);
  //   // this.heartBeatGapCount++;

  //   var readTimeoutCount =
  //     this.heartBeatGapCount * this.configuration.getHeartBeatInterval();
  //   if (this.configuration.getServerPort() === 7792) {
  //     // console.log("Sending heart beat here  7792 readTimeOut " + readTimeoutCount + " heartbeat handle " + this.heartBeatHandle);
  //   }
  //   console.log("readTimeoutCount: ", readTimeoutCount, this.listenerType);
  //   if (readTimeoutCount === 30) {
  //     var date = new Date();
  //     console.log(
  //       `${
  //         this.listenerType === "gameListener" ? "Game" : "Lobby"
  //       } Socket heartbeat timed out at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `
  //     );
  //     this.clientListener.onReadTimedout();
  //     this.disconnectClient(null);
  //   }
  // }

  sendHeartBeat() {
    // heartBeatGapCount will be incremented by 1 for every invocation of sendHeartBeat();
    // heartBeatGapCount will be reset to 0 once a heartbeat response is received from server
    let heartBeatConfiguredMessage = this.configuration.getHeartBeatMessage();

    let heartBeatConfigurationParameters = "";
    if (this.listenerType === "gameListener") {
      heartBeatConfigurationParameters = {
        playerName: getPlayerObject().userName,
        tableId: this.clientListener.tableId,
      };
    }

    let heartBeatGapDetailsObject = {
      gapCount: this.heartBeatGapCount,
      status: this.isHeartbeatReceived === true ? "T" : "F",
    };

    let heartBeatPayload = {
      ...heartBeatConfigurationParameters,
      ...heartBeatGapDetailsObject,
    };
    heartBeatPayload = JSON.stringify(heartBeatPayload);

    let message =
      heartBeatConfiguredMessage +
      this.configuration.getProtocolDelimiter() +
      heartBeatPayload;

    this.sendMessage(message);

    this.isHeartbeatReceived = false;
    this.heartBeatGapCount++;
    this.clientListener.setHeartbeatGapCount(this.heartBeatGapCount);
    // this.heartBeatGapCount++;

    var readTimeoutCount =
      this.heartBeatGapCount * this.configuration.getHeartBeatInterval();
    if (this.configuration.getServerPort() === 7792) {
      // console.log("Sending heart beat here  7792 readTimeOut " + readTimeoutCount + " heartbeat handle " + this.heartBeatHandle);
    }
    console.log("readTimeoutCount: ", readTimeoutCount, this.listenerType);
    if (readTimeoutCount === 30) {
      var date = new Date();
      console.log(
        `${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        } Socket heartbeat timed out at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `
      );
      this.clientListener.onReadTimedout();
      this.disconnectClient(null);
    }
  }

  // onConnectionTimeout() {
  //   //**** clearing time out and nullifying
  //   clearTimeout(this.connectionTimeoutHandle);
  //   this.connectionTimeoutHandle = undefined;

  //   if (!this.isConnected) {
  //     this.doCleanUp()
  //     this.clientListener.onConnectTimeout();
  //     this.createConnection();
  //   }
  // }

  onConnectionTimeout() {
    //**** clearing time out and nullifying
    console.log(
      this.listenerType === "gameListener"
        ? "game server"
        : "lobby server " + "connection timeout"
    );
    clearTimeout(this.connectionTimeoutHandle);
    this.connectionTimeoutHandle = undefined;

    if (!this.isConnected) {
      // if (this.listenerType === "gameListener") {
      //   this.clientListener.onConnectTimeout();
      // } else {
      //   this.doCleanUp();
      //   this.clientListener.onConnectTimeout();
      //   this.createConnection();
      // }

      this.doCleanUp();
      this.clientListener.onConnectTimeout();

      if (this.listenerType !== "gameListener") {
        //game socket reconnection flow is different when compared to lobby socket - refer to 'onConnectTimeout' in GameListenerN script for more understanding
        this.createConnection();
      }
    }
  }

  cleanUpQueueArray = () => {
    this.queueArray = [];
    this.isExecutionInProgress = false;
    clearTimeout(this.delayTimeOut);
  };

  doCleanUp = (isFinalRemoval, isMaintenanceMode) => {
    console.log("isFinalRemoval: ", isFinalRemoval, this.listenerType);
    //"isFinalRemoval" is true when game connection is removed completely, should be added for lobby connection too @yashwanth
    try {
      if (isFinalRemoval) {
        window.document.removeEventListener(
          "visibilitychange",
          this.handleVisibilityChange
        );
        console.log("this.queueArray in doCleanUp--->", this.queueArray);
        if (!isMaintenanceMode) {
          this.cleanUpQueueArray();
        }

        // if (isIOS) {
        //   window.removeEventListener("offline", this.handleOfflineEvent);
        // }
      }

      if (this.heartBeatHandle !== undefined) {
        console.log("Came inside heartbeat check >>> ", this.heartBeatHandle);
        clearInterval(this.heartBeatHandle);
        this.heartBeatHandle = undefined;
      }

      if (this.connectionTimeoutHandle !== undefined) {
        clearTimeout(this.connectionTimeoutHandle);
        this.connectionTimeoutHandle = undefined;
      }

      this.clientListener.doCleanUp();

      console.log("this.webSocket", this.webSocket, this.listenerType);
      if (this.webSocket) {
        console.log(
          "Cleaning the web socket object completely",
          this.listenerType
        );
        //Below must be done before cleanup.
        this.webSocket.onopen = function () {};
        this.webSocket.onclose = function () {};
        this.webSocket.onmessage = function () {};
        this.webSocket.onerror = function () {};

        //WebSocket readyState
        //0 ---- Trying to connect
        //1 ---- Open
        //2 ---- Closing
        //3 ---- Closed
        //If we attempt to close the connection while the
        //readyState of the socket is 0 and exception is thrown saying
        //attempted to close the socket before connecting
        console.log("this.webSocket.readyState:", this.webSocket.readyState);
        if (this.webSocket.readyState !== 0) {
          console.log("CLOSING WEB SOCKET");
          this.webSocket.close();
        }
        //Try making websocket undefined
        this.webSocket = null;
        this.webSocket = undefined;
      }
      this.isConnecting = false;
      this.isConnected = false;
    } catch (e) {
      console.log("Error while cleaning socket ", e, this.listenerType);
    }
  };

  // onMessage(event) {
  //   //RegExp implementation required for \n
  //   //Force protocol delimiter to be implemented in configuration
  //   var protocol = event.data;

  //   //We are receiving "new line \n" in data, removal is done below
  //   //
  //   //Line breaks in text are generally represented in three ways as either \r\n or as \n or \r.
  //   //The first type of line break (\r\n) is usually created on a windows computer,
  //   //the second (\n) on Linux and
  //   //the third kind of line break (\r) on an Apple computer.
  //   //protocol = protocol.replace(/\r?\n|\r/g, ""); //working but not sure whether this removes all 3 types of line breaks
  //   protocol = protocol.replace(/(\r\n|\n|\r)/gm, ""); //removes all 3 types of line breaks

  //   var message = this.constructMessage(protocol);

  //   //    var protocol = data;
  //   //    var details = protocol.split(this.configuration.getProtocolDelimiter());
  //   //    var command = details[0];
  //   //    command = command.toLowerCase();
  //   //var message = details[1];

  //   //    var heartBeatMessage = this.configuration.getHeartBeatMessage();
  //   //    if(heartBeatMessage.indexOf("#") >= 0){
  //   //        heartBeatMessage = heartBeatMessage.substring(0,heartBeatMessage.length-1);
  //   //    }
  //   //Expose startHeartBeat, resetHeartBeat method to client
  //   //    if(command.indexOf(heartBeatMessage) === 0){
  //   //        if(this.heartBeatHandle === undefined){
  //   //            this.heartBeatGapCount = 0;
  //   //            this.heartBeatHandle = setInterval(sendHeartBeat.bind(this),this.configuration.getHeartBeatInterval()*1000);
  //   //        }else{
  //   //            this.heartBeatGapCount--;
  //   //        }
  //   //    }

  //   var heartBeatMessage = this.configuration.getHeartBeatMessage();
  //   if (heartBeatMessage.indexOf("#") >= 0) {
  //     heartBeatMessage = heartBeatMessage.substring(0, heartBeatMessage.length - 1);
  //     heartBeatMessage = heartBeatMessage.toLowerCase();
  //   }

  //   if (message.command.indexOf(heartBeatMessage) === 0) {
  //     this.heartBeatGapCount = 0;
  //     this.isHeartbeatReceived = true;

  //     //heart beat on both lobby and game is started only after heart beat protocol is received from server
  //     if (!this.isHeartBeatStarted) {
  //       this.isHeartBeatStarted = true;
  //       this.clientListener.getCommunicationChannel().startHeartbeat();
  //     }
  //   }
  //   //var data = details[1];
  //   //    var handler = this.configuration.getHandlersList()[command];
  //   var handler = this.configuration.getHandlersList()[message.command];
  //   var date = new Date();

  //   if ((typeof handler) !== "undefined" && ((handler instanceof Handler))) {
  //     console.log(`Message From server ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${message.command} --- ${message.data}`);

  //     /* handler.execute(message.data); */
  //     try {
  //       // handler.execute(message.data);
  //       // handler.execute(message.data, message.command);
  //       this.addToQueue(message);
  //     } catch (e) {
  //       this.sendMessage(`DB#HBC Error at command ${message.command} data is ${message.data} Error is >>> ${e.stack}`);
  //       // console.log(`
  //       // Error at command ${message.command} data is ${message.data} Error is  >>> ${e.stack}
  //       // `);
  //     }
  //   } else {
  //     console.warn(`Handler not found for command ${message.command} --- ${message.data} ---- ` +
  //       `On server ---- ${this.configuration.getServerAddress()} -- on Port --- ${this.configuration.getServerPort()} at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`);
  //   }
  // }

  onMessage(event) {
    //RegExp implementation required for \n
    //Force protocol delimiter to be implemented in configuration
    var protocol = event.data;

    //We are receiving "new line \n" in data, removal is done below
    //
    //Line breaks in text are generally represented in three ways as either \r\n or as \n or \r.
    //The first type of line break (\r\n) is usually created on a windows computer,
    //the second (\n) on Linux and
    //the third kind of line break (\r) on an Apple computer.
    //protocol = protocol.replace(/\r?\n|\r/g, ""); //working but not sure whether this removes all 3 types of line breaks
    protocol = protocol.replace(/(\r\n|\n|\r)/gm, ""); //removes all 3 types of line breaks

    var message = this.constructMessage(protocol);

    //    var protocol = data;
    //    var details = protocol.split(this.configuration.getProtocolDelimiter());
    //    var command = details[0];
    //    command = command.toLowerCase();
    //var message = details[1];

    //    var heartBeatMessage = this.configuration.getHeartBeatMessage();
    //    if(heartBeatMessage.indexOf("#") >= 0){
    //        heartBeatMessage = heartBeatMessage.substring(0,heartBeatMessage.length-1);
    //    }
    //Expose startHeartBeat, resetHeartBeat method to client
    //    if(command.indexOf(heartBeatMessage) === 0){
    //        if(this.heartBeatHandle === undefined){
    //            this.heartBeatGapCount = 0;
    //            this.heartBeatHandle = setInterval(sendHeartBeat.bind(this),this.configuration.getHeartBeatInterval()*1000);
    //        }else{
    //            this.heartBeatGapCount--;
    //        }
    //    }

    // var heartBeatMessage = this.configuration.getHeartBeatMessage();
    // if (heartBeatMessage.indexOf("#") >= 0) {
    //   heartBeatMessage = heartBeatMessage.substring(0, heartBeatMessage.length - 1);
    //   heartBeatMessage = heartBeatMessage.toLowerCase();
    // }

    // if (message.command.indexOf(heartBeatMessage) === 0) { //condition for heartbeat protocol
    //   this.heartBeatGapCount = 0;
    //   this.isHeartbeatReceived = true;

    //   //heart beat on both lobby and game is started only after heart beat protocol is received from server
    //   if (!this.isHeartBeatStarted) {
    //     this.isHeartBeatStarted = true;
    //     this.clientListener.getCommunicationChannel().startHeartbeat();
    //   }
    // }
    // var handler = this.configuration.getHandlersList()[message.command];
    var date = new Date();
    let gameIdentifierDebugData =
      this.listenerType === "gameListener"
        ? " >>> temp table id " +
          this.clientListener.tempTableId +
          " >>> game id " +
          this.clientListener.gameId +
          " - "
        : "";
    // if(this.listenerType === "gameListener"){
    console.log(
      `%cMessage FROM ${
        this.listenerType === "gameListener" ? "GAME" : "LOBBY"
      } server ${gameIdentifierDebugData} ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${
        message.command
      } --- ${message.data}`,
      this.listenerType === "gameListener"
        ? this.gameLogStyle
        : this.lobbyLogStyle
    );

    let faroLogPayload = {
      message: `Message FROM ${
        this.listenerType === "gameListener" ? "GAME" : "LOBBY"
      } SERVER ${gameIdentifierDebugData} ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${
        message.command
      } --- ${message.data}`,
    };
    if (this.listenerType === "gameListener") {
      faroLogPayload["gameId"] = this.clientListener.gameId;
      faroLogPayload["tableId"] = this.clientListener.tableId;
    }
    sendFaroLog(faroLogPayload);

    // }

    // this.addToQueue(message);
    if (this.listenerType === "gameListener") {
      this.addToQueue(message);
    } else {
      var heartBeatMessage = this.configuration.getHeartBeatMessage();
      if (heartBeatMessage.toLowerCase() === message.command.toLowerCase()) {
        this.heartBeatGapCount = 0;
        this.isHeartbeatReceived = true;

        if (!this.isHeartBeatStarted) {
          this.isHeartBeatStarted = true;
          this.clientListener.getCommunicationChannel().startHeartbeat();
        }
      }
      this.invokeHandler(message);
    }

    // if ((typeof handler) !== "undefined" && ((handler instanceof Handler))) {
    //   // console.log(`Message From server ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${message.command} --- ${message.data}`);

    //   /* handler.execute(message.data); */
    //   try {
    //     // handler.execute(message.data);
    //     // handler.execute(message.data, message.command);
    //     this.addToQueue(message);
    //   } catch (e) {
    //     this.sendMessage(`DB#HBC Error at command ${message.command} data is ${message.data} Error is >>> ${e.stack}`);
    //     // console.log(`
    //     // Error at command ${message.command} data is ${message.data} Error is  >>> ${e.stack}
    //     // `);
    //   }
    // } else {
    //   console.warn(`Handler not found for command ${message.command} --- ${message.data} ---- ` +
    //     `On server ---- ${this.configuration.getServerAddress()} -- on Port --- ${this.configuration.getServerPort()} at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`);
    // }
  }

  constructMessage(protocol) {
    //this is used to maintain multiple hash symbols in the received protocol data
    var details = protocol.split(this.configuration.getProtocolDelimiter());
    var message = {};
    var command, data;

    for (var index = 0; index < details.length; index++) {
      if (index === 0) {
        command = details[index];
      } else {
        if (data) {
          // console.debug("More than one Hash found in Protocol Data");
          data = data.concat("#" + details[index]);
        } else {
          data = details[index];
        }
      }
    }

    message.command = command.toLowerCase();
    message.data = data;

    return message;
  }

  addToQueue(message) {
    let protocolName = message.command;
    let dataObject = {};

    let data = message.data ? JSON.parse(message.data) : null;
    let gameStage = protocolName === REVEAL_CARDS ? data?.gameStage : null;

    let numberOfSidePots =
      protocolName === POT_WINNER
        ? this.getNumberOfSidePotsForPotWinner(data)
        : null;

    dataObject.command = protocolName;
    dataObject.data = message.data;
    if (protocolName === "start") {
      console.log(
        "PROTOCOL_DELAY",
        this.getTimeDelay(protocolName, gameStage, numberOfSidePots, data)
      );
    }
    dataObject.timeDelay =
      this.listenerType === "gameListener"
        ? !this.clientHidden &&
          this.getTimeDelay(protocolName, gameStage, numberOfSidePots, data)
        : DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

    var heartBeatMessage = this.configuration.getHeartBeatMessage();
    if (heartBeatMessage.toLowerCase() === protocolName.toLowerCase()) {
      this.heartBeatGapCount = 0;
      this.isHeartbeatReceived = true;

      if (!this.isHeartBeatStarted) {
        this.isHeartBeatStarted = true;
        this.clientListener.getCommunicationChannel().startHeartbeat();
      }

      this.invokeHandler(dataObject);
    }
    else {
      if (protocolName === "tourneyplayerrank") {
        //no need to add this protocol into the queue & no dependencies for executing this
        this.invokeHandler(dataObject);
      } else {
        if (protocolName === "closea") {
          console.log("this.queueArray ------> ", this.queueArray);
          this.cleanUpQueueArray();
        } else if (
          protocolName.toLowerCase() === "ngc" &&
          data?.status === 800
        ) {
          TableUtils.getInstance().removeTableObserver(
            this.clientListener.tempTableId,
            false,
            true
          );
          this.maintenancePopup = true;

          let maintenanceObject = {};
          maintenanceObject.command = "maintenance";
          maintenanceObject.data = "";
          maintenanceObject.timeDelay = 0;

          this.queueArray.push(maintenanceObject);
        } else if (protocolName.toLowerCase() === "result") {
          // This is added to reveal all other players cards immediately instead of waiting for the delays.
          let revealCardsObject = {};
          revealCardsObject.command = "revealotherscards";
          revealCardsObject.data = dataObject.data;
          let timeout = setTimeout(() => {
            this.invokeHandler(revealCardsObject);
            clearTimeout(timeout);
          }, 100)
          // this.queueArray.push(dataObject);
          // return
        }

        if (this.clientHidden) {
          if (this.queueArray.length > 0) {
            this.startSuccessiveExecution();
          }
          this.invokeHandler(dataObject);
        } else {
          this.queueArray.push(dataObject);

          if (!this.isExecutionInProgress) {
            this.invokeQueue();
          }
        }
      }
    }

    // this.queueArray.push(dataObject);
    // if (!this.isHeartBeatStarted) {
    //   this.isHeartBeatStarted = true;
    //   console.log(`Starting ${this.listenerType === "gameListener" ? "game" : "lobby"} heart beat`);
    //   this.clientListener.getCommunicationChannel().startHeartbeat();
    // }

    // if (!this.isExecutionInProgress) {
    //   this.invokeQueue();
    // }
  }

  // this returns the number of sidepots from potwinner , as the animation is to be played one after the another, so total time to execute will be multiplied by total number of sidepots
  getNumberOfSidePotsForPotWinner = (data) => {
    let potWinnersList = data.potWinners.potWinnersList;
    let sidePots = 0;
    potWinnersList.forEach((pot) => {
      sidePots += pot.winnerNames.length;
    });
    return sidePots;
  };

  getTimeDelay = (protocolName, gameStage, numberOfSidePots, data) => {
    if (protocolName === START_GAME_PROTOCOL) {
      this.gameStageArray = [];
      return TEXAS_START_GAME_EXECUTION_TIME;
    } else if (protocolName === PRIZE_POT) {
      return PRIZE_POT_EXECUTION_TIME;
    } else if (protocolName === REFRESH_PROTOCOL) {
      return REFRESH_EXECUTION_TIME;
    } else if (protocolName === NEXT_GAME_CANCEL) {
      // console.log('Returning delay for NGC# which is ', NEXT_GAME_CANCEL_DELAY);
      return NEXT_GAME_CANCEL_DELAY;
    } else if (protocolName === REVEAL_CARDS) {
      this.gameStageArray.push(gameStage);
      let revealCardsData = data;
      // console.log("Reveal Cards Data is ", revealCardsData);
      let numberOfCards = revealCardsData?.revealCard?.length;
      if (numberOfCards) {
        return numberOfCards * REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME;
      } else {
        if (gameStage === GAME_STAGE_FLOP) {
          return REVEAL_FLOP_CARDS_EXECUTION_TIME;
        } else if (gameStage === GAME_STAGE_SHOWDOWN) {
          this.gameStageArray.pop(); // removed showdown

          let gameStageBeforeShowDown =
            this.gameStageArray.length > 0
              ? this.gameStageArray[this.gameStageArray.length - 1]
              : undefined;

          if (gameStageBeforeShowDown === GAME_STAGE_FLOP) {
            return REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME * 2;
          } else if (
            gameStageBeforeShowDown === GAME_STAGE_TURN ||
            gameStageBeforeShowDown === GAME_STAGE_RIVER
          ) {
            return REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME;
          } else {
            return DIRECT_SHOWDOWN_REVEAL_COMMUNITY_CARDS_EXECUTION_TIME;
          }

          // return DIRECT_SHOWDOWN_REVEAL_COMMUNITY_CARDS_EXECUTION_TIME;
        } else return REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME;
      }
    } else if (protocolName === POT_WINNER) {
      return POT_WINNER_EXECUTION_TIME * numberOfSidePots;
    } else if (
      protocolName === CALL_A ||
      protocolName === CALL_B ||
      protocolName === RAISE_A ||
      protocolName === RAISE_B
    ) {
      return CHIP_ANIMATION_EXECUTION_TIME;
    } else if (protocolName === RESULT) {
      return RESULT_ANIMATION_EXECUTION_TIME;
    } else if (protocolName === RABBIT_HUNTING_PROTOCOL) {
      return RABBIT_HUNTING_PROTOCOL_EXECUTION_TIME;
    } else if (
      protocolName === TOURNEY_BREAK_BROADCAST ||
      protocolName === TOURNEY_ADDON_BREAK
    ) {
      return TOURNEY_BREAK_BROADCAST_EXECUTION_TIME_IN_MS;
    }

    return DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
  };

  // invokeQueue() {
  //   clearTimeout(this.delayTimeOut);
  //   if (this.queueArray.length > 0) {
  //     this.isExecutionInProgress = true;
  //     let currentProtocol = this.queueArray[0];
  //     this.invokeHandler(currentProtocol);

  //     this.delayTimeOut = setTimeout(() => {
  //       this.queueArray.splice(0, 1);
  //       this.invokeQueue();
  //     }, currentProtocol.timeDelay);
  //   } else {
  //     this.isExecutionInProgress = false;
  //   }
  // }

  // addToQueue = (message) => {
  //   let protocolName = message.command;

  //   let dataObject = {};
  //   dataObject.command = protocolName;
  //   dataObject.data = message.data;
  //   dataObject.timeDelay =
  //     this.listenerType === "gameListener"
  //       ? protocolName === CUT_SEAT_PROTOCOL
  //         ? CUT_SEAT_EXECUTION_TIME
  //         : protocolName === START_GAME_PROTOCOL
  //         ? START_GAME_EXECUTION_TIME
  //         : protocolName === DISCARD_RESPONSE_PROTOCOL
  //         ? DISCARD_EXECUTION_TIME
  //         : protocolName === HAND_WINNER_PROTOCOL
  //         ? HAND_WINNER_EXECUTION_TIME
  //         : DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL
  //       : DEFAULT_EXECUTION_TIME_FOR_Lobby_PROTOCOL;

  //   //////////
  //   var heartBeatMessage = this.configuration.getHeartBeatMessage();
  //   if (heartBeatMessage.indexOf("#") >= 0) {
  //     heartBeatMessage = heartBeatMessage.substring(
  //       0,
  //       heartBeatMessage.length - 1
  //     );
  //     heartBeatMessage = heartBeatMessage.toLowerCase();
  //   }

  //   if (protocolName.indexOf(heartBeatMessage) === 0) {
  //     //condition for heartbeat protocol
  //     this.heartBeatGapCount = 0;
  //     this.isHeartbeatReceived = true;

  //     //heart beat on both lobby and game is started only after heart beat protocol is received from server
  //     if (!this.isHeartBeatStarted) {
  //       this.isHeartBeatStarted = true;
  //       console.log(
  //         `Starting ${
  //           this.listenerType === "gameListener" ? "game" : "lobby"
  //         } heart beat`
  //       );
  //       this.clientListener.getCommunicationChannel().startHeartbeat();
  //     }

  //     this.invokeHandler(dataObject);
  //   } else {
  //     if (this.clientHidden) {
  //       if (this.queueArray.length > 0) {
  //         console.log(
  //           "Queue array length is more than zero: ",
  //           this.queueArray
  //         );
  //         // clearTimeout(this.delayTimeOut);
  //         this.startSuccessiveExecution();
  //       }
  //       this.invokeHandler(dataObject);
  //     } else {
  //       this.queueArray.push(dataObject);

  //       if (dataObject.command === "disc") {
  //         let endPreviousDiscardObject = {};
  //         endPreviousDiscardObject.command = "endPreviousDiscard";
  //         endPreviousDiscardObject.data = "";
  //         endPreviousDiscardObject.timeDelay = 50;
  //         this.queueArray.push(endPreviousDiscardObject);
  //       }
  //       if (!this.isExecutionInProgress) {
  //         this.invokeQueue();
  //       }
  //     }
  //   }
  // };

  invokeQueue = () => {
    clearTimeout(this.delayTimeOut);
    // this.isNonQueueExecutionInProgress = false;

    if (this.queueArray.length > 0) {
      this.isExecutionInProgress = true;
      let currentProtocol = this.queueArray[0];
      this.invokeHandler(currentProtocol);
      this.queueArray.splice(0, 1);

      if (currentProtocol?.command === "potwinner" && this.maintenancePopup) {
        this.delayTimeOut = setTimeout(() => {
          this.invokeQueue();
        }, 4000);
        return;
      }

      this.delayTimeOut = setTimeout(() => {
        this.invokeQueue();
      }, currentProtocol.timeDelay);
    } else {
      this.isExecutionInProgress = false;
      this.maintenancePopup = false;
    }
  };

  // startSuccessiveExecution() {
  //   clearTimeout(this.delayTimeOut);
  //   this.isExecutionInProgress = false;

  //   for (let index = 0; index < this.queueArray.length - 1; index++) {
  //     let currentProtocol = this.queueArray[index];
  //     console.log("Invoking NON Queue protocol: ", currentProtocol);
  //     this.invokeHandler(currentProtocol);
  //   }

  //   this.isNonQueueExecutionInProgress = false;
  // }

  startSuccessiveExecution = () => {
    this.isExecutionInProgress = false;
    clearTimeout(this.delayTimeOut);

    for (let index = 0; index < this.queueArray.length; index++) {
      let currentProtocol = this.queueArray[index];
      this.invokeHandler(currentProtocol);
    }
    this.queueArray.splice(0, this.queueArray.length);

    // this.isNonQueueExecutionInProgress = false;
  };

  invokeHandler = (message) => {
    let date = new Date();
    let gameIdentifierDebugData =
      this.listenerType === "gameListener"
        ? " >>> temp table id " +
          this.clientListener.tempTableId +
          " >>> game id " +
          this.clientListener.gameId +
          " - "
        : "";

    var handler = this.configuration.getHandlersList()[message.command];
    if (typeof handler !== "undefined" && handler instanceof Handler) {
      console.log(
        `%cExecuting ${
          this.listenerType === "gameListener" ? "GAME" : "LOBBY"
        } protocol ${gameIdentifierDebugData} ${
          message.command
        } at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${
          message.command
        } --- refer protocol for data`, //--- ${message.data}
        this.listenerType === "gameListener"
          ? this.gameExecutionStyle
          : this.lobbyExecutionStyle
      );

      try {
        if (message.data.length === 0) {
          console.warn(message.command + " protocol data is empty");
        }
        handler.execute(message.data);
      } catch (e) {
        console.log(
          `Error at ${
            this.listenerType === "gameListener" ? "game" : "lobby"
          } protocol ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${
            message.command
          } --- ${message.data} Error is >>> ${e.stack}`
        );

        let protocolErrorLogObject = {
          type: "error at protocol execution",
          location: `${
            this.listenerType === "gameListener" ? "game" : "lobby"
          }`,
          timeStamp: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`,
          protocolCommand: message.command,
          protocolData:
            this.listenerType !== "gameListener"
              ? message?.data
                ? { trimmedData: `${message?.data.substring(0, 300)}` }
                : "NA"
              : message?.data
              ? message?.data
              : "NA",
          // protocolData: message.data ? JSON.parse(message.data) : "", //not attaching as huge data is choking up the socket and resulting in disconnection
          error: JSON.stringify(e.stack),
        };
        this.sendMessage("DB#" + JSON.stringify(protocolErrorLogObject));
      }
    } else {
      console.warn(
        `Handler not found ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} <<< ${
          message.command
        } --- ${message.data}`
      );
    }
  };

  onError(event) {
    var date = new Date();
    console.log(
      `${
        this.listenerType === "gameListener" ? "Game" : "Lobby"
      } Socket connection error at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `,
      event
    );
    sendFaroLog(
      getMLTLogFormat({
        type: "socketLog",
        stage: "onError",
        logLocation: `${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        }`,
        tempTableId: this.clientListener.tempTableId,
      })
    );
    this.clientListener.onError(event);
  }

  onClose(event) {
    let date = new Date();
    console.log(
      `${
        this.listenerType === "gameListener" ? "Game" : "Lobby"
      } Socket disconnected at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `,
      event
    );
    console.log("document.hidden: ", document.hidden, this.isConnected);
    console.log("Window document hidden:", window.document.hidden);
    console.log("Web kit hidden: ", window.document.webkitHidden);

    sendFaroLog(
      getMLTLogFormat({
        type: "socketLog",
        stage: "onClose",
        logLocation: `${
          this.listenerType === "gameListener" ? "Game" : "Lobby"
        }`,
        tempTableId: this.clientListener.tempTableId,
      })
    );

    if (!document.hidden) {
      console.log("ENTERED INTO IF CHECK AT ON CLOSE");
      //have to see at later point (after release of IP Man phase 1) how this check is specifically helpful @yashwanth
      if (this.isConnected === true) {
        let date = new Date();
        console.log(
          `${
            this.listenerType === "gameListener" ? "Game" : "Lobby"
          } OnClose - Socket invoking disconnect client method at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `,
          event
        );

        sendFaroLog(
          getMLTLogFormat({
            type: "socketLog",
            stage: "onClose",
            logLocation: `${
              this.listenerType === "gameListener" ? "Game" : "Lobby"
            }`,
            tempTableId: this.clientListener.tempTableId,
            comments:
              "client not hidden current status connected proceeding with disconnection",
          })
        );

        this.isConnected = false;
        this.isConnecting = false;
        this.inReconnectionMode = false;

        this.disconnectClient(event);
      } else {
        let date = new Date();
        console.log(
          `${
            this.listenerType === "gameListener" ? "Game" : "Lobby"
          } OnClose - Socket not invoking disconnect client method at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `,
          event
        );

        sendFaroLog(
          getMLTLogFormat({
            type: "socketLog",
            stage: "onClose",
            logLocation: `${
              this.listenerType === "gameListener" ? "Game" : "Lobby"
            }`,
            tempTableId: this.clientListener.tempTableId,
            comments:
              "client not hidden current status not connected not proceeding with disconnection",
          })
        );
      }
    } else {
      console.log("ENTERED INTO ELSE CHECK AT ON CLOSE");
      this.isDisconnected = true;

      sendFaroLog(
        getMLTLogFormat({
          type: "socketLog",
          stage: "onClose",
          logLocation: `${
            this.listenerType === "gameListener" ? "Game" : "Lobby"
          }`,
          tempTableId: this.clientListener.tempTableId,
          comments: `client hidden current status ${this.isConnected} marking as disconnected`,
        })
      );
    }
  }

  disconnectClient = (event) => {
    console.log("Disconnect client method invoked in", this.listenerType);
    this.doCleanUp();
    this.resetHeartbeat();

    var clientListener = this.clientListener;

    if (this.listenerType === "gameListener") {
      if (event !== null && event !== undefined) {
        clientListener.onClientDisconnect(event.wasClean);
      } else {
        clientListener.onClientDisconnect();
      }
    } else {
      var attemptReconnection;
      if (event !== null && event !== undefined) {
        attemptReconnection = clientListener.onClientDisconnect(event.wasClean);
      } else {
        attemptReconnection = clientListener.onClientDisconnect();
      }
      if (attemptReconnection === undefined) {
        console.warn(
          "Client does not attempt reconnection unless specified to do so. Return true from onClientDisconnect to start reconnection"
        );
      }
      if (attemptReconnection !== null && attemptReconnection !== undefined) {
        if (this.reconnectionTimeoutHandle === undefined) {
          this.inReconnectionMode = true;
          this.createConnection();
          //       this.doReconnection();
          //       this.reconnectionTimeoutHandle = setInterval(doReconnection.bind(this),this.configuration.getReconnectionAttemptInterval()*1000);
        }
      }
    }
  };

  // disconnectClientOnBackground() {
  //   this.doCleanUp();
  //   this.resetHeartbeat();
  // }

  // reconnectClientOnForeground() {
  //   this.disconnectClient();
  // }

  reconnectionTimeout() {
    clearTimeout(this.reconnectionTimeoutHandle);
    this.reconnectionTimeoutHandle = undefined;
    this.doCleanUp();
    this.createConnection();
    // console.log("Attempting reconnection on server " + this.configuration.getServerAddress() + " with port " + this.configuration.getServerPort());
  }

  sendMessage(messageToBeSent) {
    var date = new Date();
    // console.log("this.webSocket.readyState >>> ",this.webSocket)
    // console.log(`Message to be sent >>> ${messageToBeSent}`);

    if (this.webSocket) {
      let gameIdentifierDebugData =
        this.listenerType === "gameListener"
          ? " >>> temp table id " +
            this.clientListener.tempTableId +
            " >>> game id " +
            this.clientListener.gameId +
            " - "
          : "";
      if (this.webSocket.readyState === 1) {
        this.webSocket.send(messageToBeSent + "\n");
        // if(this.listenerType === "gameListener"){

        let faroLogPayload = {
          message:
            `Message TO ${
              this.listenerType === "gameListener" ? "GAME" : "LOBBY"
            } server ${gameIdentifierDebugData} ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ` +
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} >>>  ${messageToBeSent}`,
        };
        if (this.listenerType === "gameListener") {
          faroLogPayload["gameId"] = this.clientListener.gameId;
          faroLogPayload["tableId"] = this.clientListener.tableId;
        }

        sendFaroLog(faroLogPayload);

        console.log(
          `%cMessage TO ${
            this.listenerType === "gameListener" ? "GAME" : "LOBBY"
          } server ${gameIdentifierDebugData} ${this.configuration.getServerAddress()}:${this.configuration.getServerPort()}  ` +
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} >>>  ${messageToBeSent}`,
          this.listenerType === "gameListener"
            ? this.gameLogStyle
            : this.lobbyLogStyle
        );
        // }
      } else {
        console.warn(
          `${
            this.listenerType === "gameListener" ? "game" : "lobby"
          } websocket cannot send data ready state ${
            this.webSocket.readyState
          } and the message trying to send is >>>> ` +
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} ${messageToBeSent}`
        );
      }
    } else {
      console.warn("Websocket is not available " + this.webSocket);
    }
  }

  startHeartbeat() {
    // console.log("Came to start Heart beat " , this.heartBeatHandle);
    if (this.heartBeatHandle === undefined) {
      this.heartBeatGapCount = 0;
      this.heartBeatHandle = setInterval(
        this.sendHeartBeat.bind(this),
        this.configuration.getHeartBeatInterval() * 1000
      );
      // console.log("startHeartbeat <<<>>>  <><><><><><><><><><><><><><><><><><><> >................", this.heartBeatHandle)
    }
  }

  resetHeartbeat() {
    console.log(
      "Came to reset Heart beat >>><><><><><><><><> .......  ",
      this.heartBeatHandle
    );
    if (this.heartBeatHandle !== undefined) {
      clearInterval(this.heartBeatHandle);
      this.heartBeatHandle = undefined;
    }
    this.heartBeatGapCount = 0;
    this.isHeartBeatStarted = false;
  }

  setSessionId(value) {
    this.sessionId = value;
  }

  getSessionId() {
    return this.sessionId;
  }

  sleep() {}

  wakeUp() {}
}

export default BaseClient;
