import { createSlice, current } from "@reduxjs/toolkit";
// import { cloneDeep } from "lodash";

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    visible: false,
    type: null,
    config: null,
    animating: false,
  },
  reducers: {
    openSlider: (state, action) => {
      // let newState = cloneDeep(current(state));
      state.visible = true;
      let data = action.payload;
      state.type = data.type;
      if (data.config) {
        state.config = data.config;
      } else {
        state.config = undefined;
      }
      state.animating = true;
      // return newState;
    },
    closeSlider: (state, action) => {
        // let newState = cloneDeep(current(state))
        state.visible = false;
        state.type = null;
        state.config = null;
        state.animating = false;
        // return newState;
    },
    updateSliderAnimatingFlag: (state, action) => {
      let { value } = action.payload;
      // let newState = cloneDeep(current(state));
      state.animating = value;
      // return newState;
    }
  },
});

export const { openSlider, closeSlider, updateSliderAnimatingFlag } = sliderSlice.actions;
export default sliderSlice.reducer;
