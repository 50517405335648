import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CursiveHandType, HandType } from "./winnerHandTypeStyles";
import { AnimatePresence, motion } from "framer-motion";
import colors from "../../../Common/styleGuide/Colors";

const WinnerHandType = (props) => {
  const { tempTableId } = props;

  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isWinnerDeclared
  );

  const playerData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );

  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );

  const doCommunityCardsExist = useSelector(
    (state) => state.gameTable.games[tempTableId]?.doCommunityCardsExist
  );

  const multiPotAmounts = useSelector(
    (state) => state.gameTable.games[tempTableId]?.multiPotAmounts
  );

  const [handType, setHandType] = useState(null);
  const [splitPot, setSplitPot] = useState(false);
  const [multiPots, setMultiPots] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getPlayerHandType();
    } else {
      setHandType(null);
      setSplitPot(false);
      setMultiPots(false);
      clearTimeout(intervalRef?.current);
    }
    return () => {
      clearTimeout(intervalRef?.current);
    };
  }, [isWinnerDeclared, winningPotData]);

  const getPlayerHandType = () => {
    let playerName = winningPotData?.winnerNames[0];
    let playerInformation = playerData.find(
      (player, index) => player.userName === playerName
    );
    if (
      playerInformation &&
      playerInformation.isWinner &&
      playerInformation.handType
    ) {
      setHandType(playerInformation?.handType);
    }
    setSplitPot(winningPotData?.winnerNames?.length > 1);
    if (multiPotAmounts?.length > 1) {
      setMultiPots(true);
    } else {
      setMultiPots(false);
    }
  };

  const handTypeAtShowDown = () => {
    return (
      <AnimatePresence key={"handTypeAtShowdown-" + tempTableId}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isWinnerDeclared && handType && doCommunityCardsExist && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
              style={{
                width: "100%",
                zIndex: 4,
                textAlign: "center",
                borderImage: `linear-gradient(to right, rgba(0,0,0,0), ${colors.primaryGold}, rgba(0,0,0,0)) 30`,
                borderWidth: "1px",
                borderStyle: "solid",
                background:
                  "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.6), rgba(0,0,0,0))",
              }}
            >
              <HandType textSize={18}>{handType}</HandType>
            </motion.div>
          )}
          <AnimatePresence>
            {multiPots ? (
              <CursiveHandType
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 0,
                }}
              >
                {winningPotData?.potIndex === 0 ? 'Main Pot' : 'Side Pot'}
              </CursiveHandType>
            ) : null}
          </AnimatePresence>
        </div>
      </AnimatePresence>
    );
  };

  return <>{handTypeAtShowDown()}</>;
};

export default memo(WinnerHandType);
