import {
  LogLevel,
  ReactIntegration,
  getWebInstrumentations,
  initializeFaro,
} from "@grafana/faro-react";
import { isIOS } from "react-device-detect";
import { getDeviceAndConnectionDetails } from "../../Poker/utils/BrowserUtils";

class FaroUtil {
  static faroInstance;
  constructor(props) {
    // if (!FaroUtil.faroInstance) {
    //   FaroUtil.faroInstance = this;
    // }
    // return FaroUtil.faroInstance;
  }

  initialize() {
    let faroUrl = process.env.REACT_APP_FARO_BASE_URL;
    if (!faroUrl) {
      throw new Error("Please provide faro url");
    }
    if (window && !window.faro) {
      const instrumentsList = [
        // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted

        ...getWebInstrumentations({
          // Disabled console logs to faro.
          captureConsole: false,
          captureConsoleDisabledLevels: [],
        }),
        new ReactIntegration(),
      ];
      if (
        (isIOS &&
          Number(getDeviceAndConnectionDetails()?.deviceOSVersion) >= 14) ||
        !isIOS
      ) {
        const { TracingInstrumentation } = require("@grafana/faro-web-tracing");
        instrumentsList.push(new TracingInstrumentation());
      }

      const faro = initializeFaro({
        url: faroUrl,
        app: {
          name: "REACT_IP_MAN",
          version: process.env.REACT_APP_CLIENT_VERSION,
          environment: process.env.REACT_APP_ENVIRONMENT,
          // channel: channel,
        },
        instrumentations: instrumentsList,
      });
      console.log("faro is initialised");
    }
  }
}

export default FaroUtil;

export const getIsFaroEnabled = () => {
  return process.env.REACT_APP_ENABLE_FARO === "true";
};

export function sendFaroLog(payload) {
  if (getIsFaroEnabled() && window && window.faro) {
    try {
      let message = payload ? JSON.stringify(payload) : "";
      // console.log("SEND_FARO_LOG", message);

      window.faro.api.pushLog([message]);
    } catch (e) {
      console.log(
        "ERROR --> FAILED TO STRINGIFY MESSAGE AT SEND FARO LOG",
        payload
      );
    }
  }
}
