import Handler from "../Handler";

class SitInBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processSitInBroadcast(receivedData);
  }
}

export default SitInBroadcastHandler;
