import Handler from "../Handler";

class TourneyPlayerRankHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPlayerRankData(receivedData);
  }
}
export default TourneyPlayerRankHandler;

//TourneyPlayerRank#{"tourneyId":"hhvyf","playerName":"decode","playerRank":3,"registeredPlayerCount":3,"activePlayerCount":3}
