import Handler from "../Handler";

class TourneyRebuyInHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyGameRebuyIn(receivedData);
  }
}
export default TourneyRebuyInHandler;

// TRebuyIn#{
//     "playerName": "gg05",
//     "totalBalance": 1000,
//     "reBuyAmount": 20,
//     "chipsToBeGranted": 750,
//     "doubleReBuyAmount": 40,
//     "doubleChipsToBeGranted": 1500,
//     "doubleRebuy": true,
//     "endTime": 10
// }

// In Double rebuy false case:
// TRebuyIn#{
//     "playerName": "gg05",
//     "totalBalance": 1000,
//     "reBuyAmount": 20,
//     "chipsToBeGranted": 750,
//     "doubleRebuy": false,
//     "endTime": 10
// }
