import Handler from "./../Handler";
class UpdatePlayerCountHandler extends Handler {
  constructor(service) {
    super(service);
  }
  execute(data) {
    /**
     * {
        "playerCountMap": [
          {
            "gameDefId": 605,
            "playerCount": 45
          },
          {
            "gameDefId": 502,
            "playerCount": 55
          }
        ]
      }
     */
    let receivedData = JSON.parse(data);
    if (receivedData) {
      this.service.processUpdatePlayerCount(receivedData);
    }
  }
}

export default UpdatePlayerCountHandler;
