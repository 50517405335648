//ranksdata#{"tourneyId":"lGzQO","rankDataList":[{"playerName":"testuser","playerChips":100.0,"isBustedOut":false,"bustedOutTimeStamp":0,"rank":0,"winner":false},{"playerName":"guru","playerChips":100.0,"isBustedOut":false,"bustedOutTimeStamp":0,"rank":0,"winner":false}]}

import Handler from "../Handler";

class TourneyTableDataHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyTableData(receivedData);
  }
}
export default TourneyTableDataHandler;
