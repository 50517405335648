import axios from "axios";
import {
  leaderBoardIcon,
  calendar,
  rank,
  score,
  closeIcon,
} from "../../../../Poker/routes/Game/gameAssets";
import { ButtonImage } from "../../../../Poker/routes/Game/gameTableStyles";
import {
  Details,
  LbDetails,
  LbIcons,
  LbTitle,
  LeaderboardButton,
  LeaderboardCard,
  LeaderboardHeading,
  LeaderboardOverlay,
  PrizeDetails,
  RankDetails,
  RankScoreInfo,
  StartDate,
} from "./LeaderboardStyle";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import {
  FLAVOUR_TYPES,
  GAME_TYPES,
  MONTHS,
  RSA_FAILURE_STATUS_ERROR_CODES,
  TOURNEY_MAP,
} from "../../../../Poker/data/Constants";
import { Loader } from "../../../../Poker/components/Loader/Loader";
import { encryptAPIPayload } from "../../../utils/EncryptionUtil";
import { sendLobbyDebugLog } from "../../../../Poker/redux/slices/lobbySlice";
export const Leaderboard = (props) => {
  const playerData = useSelector((state) => state.lobby.player);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lbData, setLbData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const applicableLeaderboards = useSelector(
    (store) => store.lobby.applicableLeaderboards
  );
  const currentTimeStamp = useSelector((store) => store.lobby.currentTimeStamp);
  const accessToken = useSelector((state) => state.lobby.accessToken);
  const a23Token = useSelector((state) => state.lobby.a23Token);

  let { flavourType, gameType, gameDefId } = props.gameDefinition;
  let tourneyId, tournamentType;
  if (props.isTourneyGame) {
    tournamentType = props.gameDefinition.tournamentType;
    tourneyId = props.gameDefinition.tourneyId;
  }
  const clickHandler = async () => {
    if (showOverlay) return;
    setIsLoaded(false);
    setShowOverlay(true);
    let url = process.env.REACT_APP_LIVE_COMPLETED_LEADERBOARDS;
    console.log(
      "lb details api url: ",
      url,
      `${
        (props.isTourneyGame
          ? FLAVOUR_TYPES["tourney"]
          : FLAVOUR_TYPES[flavourType]) +
        "_" +
        GAME_TYPES[props.gameDefinition.gameType] +
        "_" +
        (props.isTourneyGame
          ? TOURNEY_MAP[props.gameDefinition.tournamentType]
          : gameDefId)
      }`
    );

    let dataObj = {
      gameType: "poker",
      lbDefId: `${
        (props.isTourneyGame
          ? FLAVOUR_TYPES["tourney"]
          : FLAVOUR_TYPES[flavourType]) +
        "_" +
        GAME_TYPES[props.gameDefinition.gameType] +
        "_" +
        (props.isTourneyGame
          ? TOURNEY_MAP[props.gameDefinition.tournamentType]
          : gameDefId)
      }`,
    };
    let response;
    try {
      let headerVar = {
        headers: {
          Authorization: accessToken,
          a23token: a23Token,
        },
      };
      response = await axios.post(url, dataObj, headerVar);
      console.log("lb details response", response);
      if (response.status === 200) {
        setLbData(response.data.data);
      } else {
        console.log("lb details api response unexpected status", response);
        if (
          response?.errorCode &&
          RSA_FAILURE_STATUS_ERROR_CODES.includes(response?.errorCode)
        ) {
          sendLobbyDebugLog({
            type: `ENCRYPTION_FAILED_WITH_CODE - ${response?.errorCode}`,
            responsePayload: response,
          });
        }
      }
      setIsLoaded(true);
    } catch (err) {
      console.log("Unsuccesful API request for lb details", err);
    }
  };

  function ordinal_suffix_of(i) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const closeOverlay = () => {
    setShowOverlay(false);
    setLbData([]);
  };

  const isLeaderboardApplicable = () => {
    if (props.isTourneyGame) {
      for (const element of applicableLeaderboards.tourney) {
        let current = element;
        if (current.tourneySeries) {
          if (
            current.tourneyIds.includes(tourneyId) &&
            props.gameDefinition.tourneyStartTime >= current.startTime &&
            props.gameDefinition.tourneyStartTime <= current.endTime
          ) {
            return true;
          }
        } else {
          for (const element of current.gameDefIds) {
            let tourneyType = Number(element.split("_")[2]);
            tourneyType = Object.keys(TOURNEY_MAP).find(
              (key) => TOURNEY_MAP[key] === tourneyType
            );

            if (
              tournamentType === tourneyType &&
              props.gameDefinition.tourneyStartTime * 1000 >=
                current.startTime &&
              props.gameDefinition.tourneyStartTime <= current.endTime
            ) {
              return true;
            }
          }
        }
      }
    } else {
      if (flavourType === "real") {
        for (let i = 0; i < applicableLeaderboards.real.length; i++) {
          let current = applicableLeaderboards.real[i];
          for (let j = 0; j < current.gameDefIds.length; j++) {
            let currentDefId = Number(current.gameDefIds[j].split("_")[2]);
            if (
              currentDefId === props.gameDefinition.gameDefId &&
              currentTimeStamp >= current.startTime &&
              currentTimeStamp <= current.endTime
            ) {
              return true;
            }
          }
        }
      } else if (flavourType === "play") {
        for (let i = 0; i < applicableLeaderboards.play.length; i++) {
          let current = applicableLeaderboards.play[i];
          for (let j = 0; j < current.gameDefIds.length; j++) {
            let currentDefId = Number(current.gameDefIds[j].split("_")[2]);
            if (
              currentDefId === props.gameDefinition.gameDefId &&
              currentTimeStamp >= current.startTime &&
              currentTimeStamp <= current.endTime
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  return (
    isLeaderboardApplicable() && (
      <>
        <LeaderboardButton
          onClick={() => clickHandler()}
          orientation={props.orientation}
          isTourney={props.isTourneyGame}
          id='live-completed-leaderboard'
        >
          <ButtonImage src={leaderBoardIcon}></ButtonImage>
        </LeaderboardButton>
        {showOverlay && (
          <LeaderboardOverlay
            isTourney={props.isTourneyGame}
            id='leaderboard-overlay'
            orientation={props.orientation}
          >
            {isLoaded ? (
              <>
                <LeaderboardHeading>
                  {" "}
                  Leaderboards
                  <LbIcons
                    src={closeIcon}
                    className='icon-close'
                    onClick={closeOverlay}
                  />
                </LeaderboardHeading>
                <Swiper
                  pagination={{
                    clickable: false,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                  }}
                  nested={true}
                  touchMoveStopPropagation={true}
                  modules={[Pagination]}
                >
                  {lbData?.map((leaderboard) => {
                    let lbStartTime = new Date(leaderboard.lbStartTime);
                    let lbEndTime = new Date(leaderboard.lbEndTime);
                    console.log("lbStartTime=" + lbStartTime);
                    return (
                      <SwiperSlide key={"lb" + 1}>
                        <LeaderboardCard>
                          <PrizeDetails>
                            Prizes worth{" "}
                            <LbDetails>Rs.{leaderboard.prizeWorth}</LbDetails>
                          </PrizeDetails>
                          <Details>
                            <LbTitle>{leaderboard.lbName}</LbTitle>
                            <StartDate>
                              <LbIcons
                                src={calendar}
                                className='icon-calendar'
                              />
                              {ordinal_suffix_of(lbStartTime.getDate()) +
                                " " +
                                MONTHS[lbStartTime.getMonth()] +
                                ", " +
                                lbStartTime.getHours() +
                                ":" +
                                (lbStartTime.getMinutes() < 10
                                  ? "0" + lbStartTime.getMinutes()
                                  : lbStartTime.getMinutes()) +
                                " to " +
                                ordinal_suffix_of(lbEndTime.getDate()) +
                                " " +
                                MONTHS[lbEndTime.getMonth()] +
                                ", " +
                                lbEndTime.getHours() +
                                ":" +
                                (lbEndTime.getMinutes() < 10
                                  ? "0" + lbEndTime.getMinutes()
                                  : lbEndTime.getMinutes())}
                              {/* 20th Jan, 10:00 to 31st Jan, 17:00 */}
                            </StartDate>
                            <RankScoreInfo>
                              <RankDetails>
                                <LbIcons src={rank} className='icon-rank' />
                                Rank
                                <span style={{ marginLeft: "5px" }}>
                                  {" "}
                                  {leaderboard.playerRank}{" "}
                                </span>
                              </RankDetails>
                              <span style={{ color: "#BABABA" }}>|</span>
                              <LbDetails>
                                <LbIcons src={score} className='icon-score' />
                                Points
                                <span style={{ marginLeft: "5px" }}>
                                  {leaderboard.playerScore}
                                </span>
                              </LbDetails>
                            </RankScoreInfo>
                          </Details>
                        </LeaderboardCard>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            ) : (
              <Loader size={20} marginHorizontal={5} />
            )}
          </LeaderboardOverlay>
        )}
      </>
    )
  );
};
