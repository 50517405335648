import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BannerContainer,
  BannerWrapper,
  TournamentBreakTimeWrapper,
  BannerMessageText,
  TimerWrapper,
  MinutesAndSecondsWrapper,
  TimeText,
  TimeTextWrapper,
  MinutesAndSecondChildrenWrapper,
  TimeLabelText,
  TimeNumbersParentWrapper,
  TourneyAddOnButton,
  PrimaryCTAButtonText,
  BlindsDetailsWrapper,
  ChipIconWrapper,
  SmallBlindAndBigBlindDetailsText,
} from "./tourneyGameBannersStyles";
import * as constants from "../../data/Constants";
import {
  closeTourneyBanner,
  handleOpenTourneyAddOnPopup,
} from "../../redux/slices/gameTableSlice";
import { sendLobbyDebugLog } from "../../redux/slices/lobbySlice";

import Button from "../../../Common/components/Button/Button";
import colors from "../../../Common/styleGuide/Colors";
import { Typo12GrayContoBoldText } from "../../../Common/styleGuide/Typos";
import ChipIcon from "../../../assets/gameTable/chips.png";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

function TourneyGameBanners(props) {
  const { tempTableId } = props;

  const dispatch = useDispatch();
  const currentActiveGame = useSelector(
    (state) => state.gameTable.games[tempTableId]
  );
  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);

  const tourneyBlindsUpDetails = currentActiveGame?.tourneyBlindsUpDetails;

  const activeTourneyBannerType = currentActiveGame.activeTourneyBannerType;

  const [breakTimeInSeconds, setBreakTimeInSeconds] = useState(-1);
  const [timerId, setTimerId] = useState(null);
  const closeTourneyBannerTimeout = useRef(null);

  useEffect(() => {
    if (
      activeTourneyBannerType &&
      activeTourneyBannerType === constants.TOURNEY_BANNER_BREAK_TIME
    ) {
      if (closeTourneyBannerTimeout?.current) {
        clearTimeout(closeTourneyBannerTimeout.current);
      }
      if (timerId) {
        clearExistingTimer(timerId);
        setTimerId(null);
      }
      const difference =
        new Date(currentActiveGame.tourneyGameBreakBroadcast.endTime) -
        currentLobbyTime;

      let breakTime = Math.floor(difference / 1000);
      setBreakTimeInSeconds(breakTime);
      startBreakTimer();
    } else if (
      activeTourneyBannerType &&
      activeTourneyBannerType !==
        constants.TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES &&
      activeTourneyBannerType !==
        constants.TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES &&
      activeTourneyBannerType !== constants.TOURNEY_BANNER_WAITING_FOR_REBUYS &&
      activeTourneyBannerType !== constants.TOURNEY_BANNER_MERGE_WAIT_MESSAGE
    ) {
      closeTourneyBannerTimeout.current = setTimeout(() => {
        dispatch(closeTourneyBanner({ tempTableId: tempTableId }));
      }, 3000);
    }

    return () => {
      clearTimeout(closeTourneyBannerTimeout.current);
      if (timerId) {
        clearExistingTimer(timerId);
        setTimerId(null);
      }
    };
  }, [activeTourneyBannerType]);

  useEffect(() => {
    if (
      activeTourneyBannerType &&
      activeTourneyBannerType === constants.TOURNEY_BANNER_BREAK_TIME &&
      breakTimeInSeconds < 1
    ) {
      if (timerId) {
        clearExistingTimer(timerId);
        dispatch(closeTourneyBanner({ tempTableId: tempTableId }));
      }
    }
  }, [breakTimeInSeconds]);

  const startBreakTimer = () => {
    let intervalTimerId = setInterval(() => {
      setBreakTimeInSeconds((prev) => prev - 1);
    }, 1000);
    setTimerId(intervalTimerId);
  };

  const handleOpenTourneyAddOn = () => {
    dispatch(handleOpenTourneyAddOnPopup({ tempTableId: tempTableId }));
  };

  const clearExistingTimer = (timerIntervalId) => {
    try {
      clearInterval(timerIntervalId);
    } catch (e) {
      sendLobbyDebugLog({
        type: "ERROR_AT_CLEAR_INTERVAL_IN_TOURNEY_BANNERS",
        error: JSON.stringify(e),
      });
    }
  };

  const renderTourneyBreakTimer = (breakTime) => {
    let minutes;
    let seconds;

    if (breakTime < 60) {
      minutes = "00";
      if (breakTime >= 0) {
        seconds = breakTime > 9 ? breakTime : `0${breakTime}`;
      } else {
        seconds = "00";
      }
    } else {
      let minutesValue = Math.floor((breakTime % 3600) / 60);
      minutes = minutesValue > 9 ? minutesValue : `0${minutesValue}`;
      let secondsValue = breakTime % 60;
      if (breakTime >= 0) {
        seconds = secondsValue > 9 ? secondsValue : `0${secondsValue}`;
      } else {
        seconds = "00";
      }
    }
    return (
      <MinutesAndSecondsWrapper key={`${minutes}-${seconds}`}>
        <TimeNumbersParentWrapper>
          <MinutesAndSecondChildrenWrapper>
            {minutes
              .toString()
              .split("")
              .map((eachMinuteNumber) => (
                <TimeTextWrapper>
                  <TimeText>{eachMinuteNumber}</TimeText>
                </TimeTextWrapper>
              ))}
          </MinutesAndSecondChildrenWrapper>
          <TimeLabelText>Minutes</TimeLabelText>
        </TimeNumbersParentWrapper>
        <TimeText className={"time-divider"}>:</TimeText>
        <TimeNumbersParentWrapper>
          <MinutesAndSecondChildrenWrapper>
            {seconds
              .toString()
              .split("")
              .map((eachSecondNumber) => (
                <TimeTextWrapper>
                  <TimeText>{eachSecondNumber}</TimeText>
                </TimeTextWrapper>
              ))}
          </MinutesAndSecondChildrenWrapper>
          <TimeLabelText>Seconds</TimeLabelText>
        </TimeNumbersParentWrapper>
      </MinutesAndSecondsWrapper>
    );
  };

  const renderTourneyBannerByType = () => {
    const {
      TOURNEY_BANNER_BLINDS_UP,
      TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES,
      TOURNEY_BANNER_BREAK_TIME,
      TOURNEY_BANNER_FINAL_TABLE_BUBBLE,
      TOURNEY_BANNER_MOVED_TO_ANOTHER_TABLE,
      TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE,
      TOURNEY_BANNER_IN_WINNING_POSITIONS,
      TOURNEY_WAIT_UNTIL_HAND_COMPLETES_ON_OTHER_TABLE,
      TOURNEY_BANNER_WAITING_FOR_REBUYS,
      TOURNEY_BANNER_NODE_SWITCH,
      TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES,
    } = constants;
    console.log("TOURNEY BANNER OPEN --> ", activeTourneyBannerType);
    switch (activeTourneyBannerType) {
      case TOURNEY_BANNER_BLINDS_UP:
        return (
          <BannerWrapper>
            <BannerMessageText>Blinds Are Up!</BannerMessageText>
            <Typo12GrayContoBoldText>
              Level {tourneyBlindsUpDetails.level}
            </Typo12GrayContoBoldText>
            <BlindsDetailsWrapper>
              <ChipIconWrapper src={ChipIcon} />
              <SmallBlindAndBigBlindDetailsText>{`${parseDecimalValuesOfNumber(
                tourneyBlindsUpDetails.sb,
                2,
                checkToDisplayValueInK(tourneyBlindsUpDetails.sb),
                checkToDisplayValueInL(tourneyBlindsUpDetails.sb)
              )} / ${parseDecimalValuesOfNumber(
                tourneyBlindsUpDetails.bb,
                2,
                checkToDisplayValueInK(tourneyBlindsUpDetails.bb),
                checkToDisplayValueInL(tourneyBlindsUpDetails.bb)
              )} (${parseDecimalValuesOfNumber(
                tourneyBlindsUpDetails.ante,
                2,
                checkToDisplayValueInK(tourneyBlindsUpDetails.ante),
                checkToDisplayValueInL(tourneyBlindsUpDetails.ante)
              )})`}</SmallBlindAndBigBlindDetailsText>
            </BlindsDetailsWrapper>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES:
        return (
          <BannerWrapper>
            <BannerMessageText>
              Break time - Waiting for other tables to finish
            </BannerMessageText>
          </BannerWrapper>
        );

      case TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES:
        return (
          <BannerWrapper>
            <BannerMessageText>
              Add-on Break time - Waiting for other tables to finish
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_BREAK_TIME:
        return (
          <BannerWrapper>
            <TournamentBreakTimeWrapper>
              <BannerMessageText>Tournament is on break </BannerMessageText>
              <TimerWrapper>
                {breakTimeInSeconds <= 0 ? (
                  <BannerMessageText>Tourney will begin now</BannerMessageText>
                ) : (
                  renderTourneyBreakTimer(breakTimeInSeconds)
                )}
              </TimerWrapper>
            </TournamentBreakTimeWrapper>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_FINAL_TABLE_BUBBLE:
        return (
          <BannerWrapper>
            <BannerMessageText>Final table bubble</BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_MOVED_TO_ANOTHER_TABLE:
        return (
          <BannerWrapper>
            <BannerMessageText>
              You are moved to another table!
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE:
        return (
          <BannerWrapper>
            <BannerMessageText>
              {/* You are merged with another table! */}
              You are being merged with another table
            </BannerMessageText>
          </BannerWrapper>
        );
      case constants.TOURNEY_BANNER_MERGE_WAIT_MESSAGE:
        return (
          <BannerWrapper>
            <BannerMessageText>
              You will be moved to a new table soon. Please wait!
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_NODE_SWITCH:
        sendFaroLog({
          type: "TOURNEY_GAME_NODE_SWITCH",
          currentActiveGame: currentActiveGame,
        });
        return (
          <BannerWrapper>
            <BannerMessageText>
              {/*  You are being merged with another table  */}
              You are being merged with another table
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_IN_WINNING_POSITIONS:
        return (
          <BannerWrapper>
            <BannerMessageText>
              Awesome! You are now in cash winning positions
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_WAIT_UNTIL_HAND_COMPLETES_ON_OTHER_TABLE:
        return (
          <BannerWrapper>
            <BannerMessageText>
              Please wait until the hand completes in other table!
            </BannerMessageText>
          </BannerWrapper>
        );
      case TOURNEY_BANNER_WAITING_FOR_REBUYS:
        return (
          <BannerWrapper>
            <BannerMessageText>
              Waiting for re-buys to complete
            </BannerMessageText>
          </BannerWrapper>
        );
    }
  };
  return (
    activeTourneyBannerType && (
      <BannerContainer key={activeTourneyBannerType}>
        {renderTourneyBannerByType()}
        {currentActiveGame.tourneyAddOnDetails && (
          <TourneyAddOnButton>
            <Button
              clickHandler={handleOpenTourneyAddOn}
              bgColor={colors.secondaryGreen}
              showIcon={false}
            >
              <PrimaryCTAButtonText>Add On</PrimaryCTAButtonText>
            </Button>
          </TourneyAddOnButton>
        )}
      </BannerContainer>
    )
  );
}

export default TourneyGameBanners;
