import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const GameInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  min-height: ${(props) =>
    props.orientation === "PORTRAIT" ? "400px" : "250px"};
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  /* background-color: rgba(8, 13, 26, 1); */
  background-color: rgb(4,54,66);
`;

export const GameInfoHeader = styled.div`
  background: rgba(4, 42, 52, 1);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  font-weight: 700;
  font-size: 16px;
`;

export const GameInfo = styled.div`
  margin: 0.5rem 0rem 1rem 0rem;
  width: 100%;
`;

export const GameInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  height: 50px;
  /* border-image: linear-gradient(to right, #042e39, #49808E, #042e39) 30;
  border-width: 1px;
  border-style: ${(props) => props.lastItem ? 'none' : 'none none solid none'}; */
  border-bottom: ${(props) => props.lastItem ? '0px solid transparent' : '1px solid transparent'}; 
  background-image: ${(props) => props.lastItem ? 'transparent' : 'linear-gradient(to right, #042e39, #49808E, #042e39)'};
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 1px;
`;

export const GameInfoRowTitle = styled.div`
  color: rgba(186, 186, 186, 1);
  font-size: 14px;
  font-weight: 500;
`;

export const GameInfoRowValue = styled.div`
  color: ${colors.primaryGold};
  font-size: 14px;
  font-weight: 500;
`;
