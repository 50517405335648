import Handler from "../Handler";

class RebuyInAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRebuyInSuccess(receivedData);
  }
}

export default RebuyInAckHandler;

// RebuyInA#{"status":"SUCCESS","playerName":"testuser","Amount":600.0}
