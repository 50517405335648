import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const ModalText = styled.div`
    color: white;
    font-size: 14px;
    text-align: center;
    margin-bottom:20px;
`;

export const OKButton = styled.button`
    background: ${colors.primaryGreen};
    /* width: 35%; */
    color: white;
    border: 0;
    margin: 0rem 0rem 0.5rem 0rem;
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-radius: 5px;
    font-family: 'Conto';
    justify-self: center;
`