import Handler from "../Handler";

class ReconnectionAckHandler extends Handler {
    execute(message) {
        /**
         * rcona#{"gameId":"181023-59382519q2id","playerName":"super136497","tableId":"THR181023-5938251qf8gk","latestGameId":"181023-59382519q2id"}
         * we need to update the existing game id in client with the data received in key 'latestGameId'
         * 
         * This is helpful in case reconnection happens after the game id user was in ends
        */

        // let messageObject = JSON.parse(message);
        // console.log("After parse calling service ack game:", messageObject);
        // this.service.reconnectionAck(messageObject);
        this.service.reconnectionAck({});
    }
}
export default ReconnectionAckHandler;