import Handler from "./../Handler";

class GetFunChipsBalanceAckHandler extends Handler {
  execute(data) {
    let parsedData = JSON.parse(data);
    this.service.processPlayerChipsLatestBalance(parsedData);
  }
}

export default GetFunChipsBalanceAckHandler;
