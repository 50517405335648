class LobbyConnection {
  static lobbyConnectionInstance;

  constructor() {
    if (!LobbyConnection.lobbyConnectionInstance) {
      this.lobbyConnection = undefined;
      LobbyConnection.lobbyConnectionInstance = this;
    }
    return LobbyConnection.lobbyConnectionInstance;
  }

  addLobbyConnection(connectionObj) {
    if (!this.lobbyConnection) {
      console.log("Adding lobby connection instance ", connectionObj);
      this.lobbyConnection = connectionObj;
    }
    // else {
    //     throw new Error("Lobby Connection is not empty, unable to add the connection object ", connectionObj);
    // }TODO: this causing app crash, need to discuss and check for the fix.
  }

  getLobbyConnection() {
    return this.lobbyConnection;
  }

  writeMessage(message) {
    if (this.lobbyConnection) {
      this.lobbyConnection.sendMessage(message);
    } else {
      console.log(`Lobby connection not available unable to write ${message}`);
    }
  }

  toggleEventBasedNetworkStatus = (isOffline = true) => {
    console.log("YASHWANTH LOG - LOBBY TOGGLE");

    if (this.lobbyConnection) {
      if (!this.lobbyConnection.clientListener.isConnectionEstablishedOnce) {
        console.log("YASHWANTH LOG - FIRING MANUAL ON ERROR");
        this.lobbyConnection.onError();
      } else {
        console.log("YASHWANTH LOG - FIRING MANUAL ON CLOSE");
        this.lobbyConnection.onClose();
      }
    } else {
      console.log("Yashwanth log - LOBBY COMMUNICATION CHANNEL NOT FOUND");
    }
  }

  removeLobbyConnection() {
    console.log("In removeLobbyConnection");
    try {
      if (this.lobbyConnection) {
        // let messageHandler = this.lobbyConnection.messageHandler;
        // messageHandler.doCleanUp();
        this.lobbyConnection.doCleanUp(true);
      }
    } catch (e) {
      console.log("Error at removeLobbyConnection: ", e);
    } finally {
      this.lobbyConnection = undefined;
    }
  }

  static getInstance() {
    if (!LobbyConnection.lobbyConnectionInstance) {
      return new LobbyConnection();
    } else {
      return LobbyConnection.lobbyConnectionInstance;
    }
  }
}

export default LobbyConnection;
