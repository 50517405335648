import React, { useState } from "react";
import { GameTileContainer } from "./styledComponents";


function BaseGameTileCard(props) {
  const [squeezeCard, setSqueezeCard] = useState(false);
  return (
    <GameTileContainer
      squeezeCard={squeezeCard}
      id={"cash-tile-" + props.gameDefId}
      onTouchStart={() => setSqueezeCard(true)}
      onTouchMove={() => setSqueezeCard(false)}
      onTouchEnd={() => setSqueezeCard(false)}
      onMouseLeave={() => setSqueezeCard(false)}
    >
      {props.children}
    </GameTileContainer>
  );
}

export default BaseGameTileCard;
