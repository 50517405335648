import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const Container = styled.div`
  width: 40%;
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  max-height: var(--app-height);
  /* background-color: rgb(27, 27, 27); */
  background: #042c36;
  position: absolute;
  right: 0;
  overflow: scroll;
  /* border-top-right-radius: 8px; */
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "table-stats-animation 0.25s forwards"
      : "table-stats-close-animation 0.25s forwards"};

  @keyframes table-stats-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes table-stats-close-animation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${colors.primaryGold};
  background: linear-gradient(to right, #044859, #075063);
  min-height: 45px;
`;

export const HeaderText = styled.span`
  color: #eabf69;
  font-size: 14px;
  font-weight: 550;
  font-family: "Conto";
  text-shadow: 0px 2px 2px black;
`;

export const CloseButton = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
  width: 15px;
`;

export const TablePlayersDetailsContainer = styled.div`
  width: 100%;
  background: #032028;
  min-height: 35%;
  padding: 0% 0%;
`;

export const TablePlayersData = styled.div`
  width: 92%;
  margin: auto;
  border-radius: 5px;
  /* border: 1px solid gray; */
  padding: 0% 2%;
  /* background-color: red; */
`;

export const TablePlayersDataHeaderText = styled.div`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  width: 100%;
  font-family: "Conto";
  color: #91cfde;
  font-weight: bold;
  font-size: 12px;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #042c36;
  padding: 2.5% 0%;
  min-height: 25px;
`;

export const TablePlayerDataText = styled.div`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  width: 100%;
  color: ${(props) =>
    props.isNegative
      ? colors.secondaryRed
      : props.isWinningsText
      ? "#08D421"
      : colors.pureWhite};
  font-weight: bold;
  font-family: "Conto";
  font-size: 12px;
`;

export const CircularProgressContainer = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) =>
    `radial-gradient(closest-side, ${props.innerColor} 70%, transparent 0% 100%), conic-gradient(${props.outerColor} ${props.percentage}%, white 0)`};
  animation: progress 2s 1 forwards;
`;

export const PercentageValue = styled.span`
  font-family: "Conto";
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

export const MySessionStatsContainer = styled.div`
  width: 100%;
  padding: 5% 0%;
  display: flex;
  flex-direction: column;
`;

export const SessionGraphRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  justify-content: space-around;
`;

export const SessionGraphRowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

export const SessionGraphRowRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const SessionGraphData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto;
`;

export const SessionGraphDataColoredBox = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 2.5px;
  background-color: ${(props) => (props.color ? props.color : "white")};
`;

export const SessionGraphDataText = styled.span`
  color: white;
  font-family: "Conto";
  font-size: 10px;
  margin-left: 10px;
`;

export const MySessionBottomData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MySessionBottomDataHeader = styled.span`
  font-family: "Conto";
  color: gray;
  font-size: 10px;
`;

export const MySessionBottomDataValue = styled.span`
  font-family: "Conto";
  font-weight: bold;
  color: white;
  margin-bottom: 6px;
  font-size: 14px;
`;
