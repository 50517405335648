import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        value: false,
        type: undefined,
    },
    reducers: {
        showAlert: (state, action) => {
            state.value = true
            state.type = action.payload
        },

        hideAlert: (state) => {
            state.value = false
            state.type = undefined
        }
    }
})

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;