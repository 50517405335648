import Handler from "../Handler";

class AwsALBCookieAckHandler extends Handler {
  execute(message) {
    /** awsalbcookieack#{"hostAdd":"172.31.22.137"} 
     * 
     * currently not consuming the protocol values
    */
    // this.service.updateGameCurrentAPIStatus();
    this.service.handleAwsAlbCookieAck();
  }
}

export default AwsALBCookieAckHandler;
