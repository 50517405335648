//TourneyCancleHandler.js

import Handler from "../Handler";

class TourneyCancleHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyCancle(receivedData);
  }
}
export default TourneyCancleHandler;

// tourneycancel#{"tourneyId":"fV8so"}
