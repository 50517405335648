import Handler from "../Handler";

class GameActiveStatusHandler extends Handler {
  execute(message) {
    let parsedData = JSON.parse(message);
    this.service.processGameActiveStatus(parsedData);
  }
}

export default GameActiveStatusHandler;
//  {"gameDefIds":[741],"status":"Inactive"}
//  {"gameDefIds":[531],"status":"Active"}
