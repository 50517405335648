import React from "react";
import {
  GlobalModal,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  RelativeWrapper,
} from "../../Modals/modalStyle.js";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos.js";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil.js";
import { ModalText, OKButton } from "../ForceLogout/ForceLogoutStyles";

const UnsupportedDevice = (props) => {
  return (
    <GlobalModal>
      <ModalContainer>
        <RelativeWrapper>
          <ModalWrapper>
            <ModalHeader>
              <Typo16WhiteContoBoldText>
                Unsupported Device
              </Typo16WhiteContoBoldText>
            </ModalHeader>
            <ModalBody>
              <ModalText bold={true}>
                You seem to be using an unsupported platform. <br></br> We
                deeply regret the inconvenience, we are working on it.
              </ModalText>
              {/* <div style={{color:'white', textAlign:'center',marginBottom:'20px'}}>Sorry for inconvenience, please try again later</div> */}
              <OKButton
                onClick={() => {
                  messageToNativeClient({
                    type: "closeClient",
                  });
                }}
              >
                Ok
              </OKButton>
            </ModalBody>
          </ModalWrapper>
        </RelativeWrapper>
      </ModalContainer>
    </GlobalModal>
  );
};

export default UnsupportedDevice;
