import Handler from "../Handler";

class LeaveSeatFailureHandler extends Handler {
  execute(message) {
    /** leavef--   */
    let receivedData = JSON.parse(message);
    this.service.processLeaveSeatFailure(receivedData);
  }
}

export default LeaveSeatFailureHandler;
