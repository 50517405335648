import styled from "styled-components";
import { Typo16WhiteContoBoldText } from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const FullscreenWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  overflow: scroll;
  position: absolute;
  top: 0px;
  z-index: 120;
`;

export const WindowHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  padding: 12px;
  background: ${colors.primaryBlue};
  box-sizing: border-box;
  border-radius: 0px;
  justify-content: space-between;
  align-items: center;
`;

export const BackArrowIconWrapper = styled.img`
  width: 12px;
  height: 14px;
`;

export const WindowChildrenWrapper = styled.object`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--app-height) - 50px);
  // background: #080d1a;
  background: #ffffff;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderTitleText = styled(Typo16WhiteContoBoldText)`
  line-height: 22px;
  margin: 0 auto;
`;
