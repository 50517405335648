import Handler from "../Handler";

class HeartBeatGameHandler extends Handler {
  // B - bid timer, S - start game timer, T - table timer (timer on player profile)
  // {"type":"B","time":7,"maxTime":10,"gameId":"06102022-3136535f8iay",playerName:"player1"}
  // {"type":"S","time":5,"maxTime":5,"gameId":"02122022-1033587if7dv"}
  // {"playerName":"1","type":"T","time":3,"maxTime":10,"gameId":"02122022-1033587if7dv"}
  execute(message) {
    if (message && message.length > 0) {
      let receivedData = JSON.parse(message);
      console.log("Heartbeat game handler data", receivedData);
      this.service.heartBeat(receivedData);
    } else {
      // console.log("Message received at HeartBeatGameHandler is of length 0");
    }
  }
}
export default HeartBeatGameHandler;
