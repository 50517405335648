import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import { motion } from "framer-motion";
import {
  Typo10BlackContoBoldText,
  Typo10GrayContoMediumText,
  Typo10PrimaryGoldContoMediumText,
  Typo10WhiteContoBoldText,
  Typo10WhiteContoMediumText,
  Typo12GreenContoMediumText,
  Typo14PrimaryGoldContoMediumText,
} from "../../../Common/styleGuide/Typos";

// import sparkle from "../../../assets/gameTable/starShine.png";
import {
  DISCONNECTION_TIMER,
  LANDSCAPE,
  OMAHA,
  TEXAS_HOLDEM,
} from "../../data/Constants";

export const Container = styled.div`
  position: absolute;
  color: ${colors.white};
  top: ${(props) => (props.top ? props.top + "px" : "unset")};
  left: ${(props) => (props.left ? props.left + "px" : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom + "px" : "unset")};
  right: ${(props) => (props.right ? props.right + "px" : "unset")};
  z-index: 0;
  width: ${(props) =>
    props.orientation === "PORTRAIT"
      ? props.isSeated
        ? props.size + "px"
        : "40px"
      : props.isSeated
      ? props.size + "px"
      : "44px"};
  /* aspect-ratio: 1/1; */
  height: ${(props) =>
    props.orientation === "PORTRAIT"
      ? props.isSeated
        ? props.size + "px"
        : "40px"
      : props.isSeated
      ? props.size + "px"
      : "44px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%)
    ${(props) =>
      props.mySeat === props.position && props.orientation === "PORTRAIT"
        ? props.isOmaha5
          ? "scale(1.05)"
          : "scale(1.2)"
        : "scale(1)"};
`;

export const ProfileImagesContainer = styled.div`
  position: relative;
  display: flex;
  /* width: 100%; */
  /* height: 100%; */
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${colors.primaryBlue};
  z-index: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat !== props.position
      ? 2
      : null};
`;

export const PlayerComponentContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 50%;
  animation: ${(props) =>
    props.isPlayerTurn ? "playerTurnHighlight 1s infinite" : null};
  transition: 0.4s ease-out;

  @keyframes playerTurnHighlight {
    0% {
      box-shadow: 0px 0px 2px 2px ${colors.primaryGold};
    }

    50% {
      box-shadow: 0px 0px 5px 4px ${colors.primaryGold};
    }

    100% {
      box-shadow: 0px 0px 2px 2px ${colors.primaryGold};
    }
  }

  @keyframes player-profile-fade-in {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const SitHereComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.tundora};
  border-radius: 50%;
  /* border: 0.5px solid ${colors.primaryGold}; */
  color: ${colors.primaryGold};
`;

export const SitHerePlusIcon = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: bold;
`;

export const SitHereText = styled.div`
  font-size: ${(props) => (props.orientation === "LANDSCAPE" ? "8px" : "8px")};
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-weight: bold;
`;

export const SeatComponentContainer = styled.div`
  width: ${(props) => (props.type === "empty" ? "35px" : "100%")};
  height: ${(props) => (props.type === "empty" ? "35px" : "100%")};
  border-radius: 50%;
  border: 1px solid ${colors.navy2};
  background: ${(props) =>
    props.type === "reserved"
      ? "radial-gradient(50% 50% at 50% 50%, rgba(3, 33, 70, 1) 0%, rgba(8, 13, 26, 1) 100%)"
      : "radial-gradient(50% 50% at 50% 50%, rgba(3, 33, 70, 0.3) 0%, rgba(8, 13, 26, 0.65) 100%)"};
`;

export const SeatStatusText = styled.div`
  font-size: ${(props) => (props.type === "empty" ? "6px" : "6px")};
  color: ${colors.pebbleGray};
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

export const ProfileImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* opacity: ${(props) => (props.isActive ? 1 : 0.8)}; */
  filter: ${(props) => (props.isActive ? null : "brightness(0.5)")};
  background-color: #f4f3f7;
`;

export const ProfileDetails = styled.div`
  width: 3.7rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat !== props.position
      ? "50%"
      : "100%"};
  left: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat !== props.position
      ? props.isRightPlayer
        ? "0%"
        : "100%"
      : "50%"};
  transform: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat !== props.position
      ? !props.isRightPlayer
        ? "translate(-10%, -30%)"
        : "translate(-90%, -30%)"
      : "translate(-50%, -10%)"};
  border-radius: 4px;
  border: ${(props) =>
    props.colorCode
      ? `2px solid #${props.colorCode}`
      : `1px solid ${colors.primaryBlue}`};
  z-index: 1;
`;

export const DetailsBlock = styled.div`
  bottom: 0;
  width: 100%;
  height: 15px;
  display: flex;
  margin: ${(props) =>
    props.orientation === "LANDSCAPE" ? "0px 15px" : "0px 5px"};
  justify-content: center;
  align-items: center;
  /* background: ${colors.deepBlue}; */
  background: black;
  position: relative;
  /* border-top: ${(props) =>
    props.softEdges === "bottom" ? `1px solid ${colors.primaryBlue}` : null};
  border-bottom: ${(props) =>
    props.colorCode ? `4px solid #${props.colorCode}` : null}; */
  /* opacity: ${(props) => (props.isActive ? 1 : 0.6)}; */
  filter: ${(props) => (props.isActive ? null : "brightness(0.5)")};
`;

export const PlayerTableDataContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  /* position: relative; */
`;

export const AddAmount = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 105%;
  bottom: 0%;
`;

export const DetailsText = styled.span`
  color: ${(props) => (props.gold ? colors.primaryGold : colors.white)};
  font-size: 10px;
  /* max-width: 60px; */
  max-width: 80px;
  overflow: hidden;
  /* font-weight: bold; */
  white-space: nowrap;
`;

export const InfinityText = styled.div`
  animation: infinite-text-animation 5s linear infinite;
  /* color: white; */
  @keyframes infinite-text-animation {
    from {
      transform: translateX(100%);
      /* color: gold; */
    }
    to {
      transform: translateX(-200%);
      /* color: red; */
    }
  }
`;

export const ProfileInfoWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: -37px;
  left: -2px;
  border: 1px solid #383838;
  border-radius: 2px;
  background: #171717;
  text-align: center;
  overflow: hidden;
`;

export const UsernameTextBox = styled.div`
  // border-radius: 2px 2px 0px 0px;
  display: flex;
  padding: 2% 8%;
  justify-content: center;
`;

export const UsernameText = styled(Typo10GrayContoMediumText)`
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserChips = styled(Typo10GrayContoMediumText)`
  color: ${colors.primaryGold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserInfoDivider = styled.div`
  height: 1px;
  content: " ";
  background: #383838;
`;

export const SitHereTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    position: relative;
    bottom: 5px;
  }
`;

export const SitHereAddText = styled(Typo14PrimaryGoldContoMediumText)`
  font-size: 25px;
  @media (min-width: 768px) {
    font-size: 35px;
  }
`;

export const RippleWrapper = styled.div`
  width: 92%;
  height: 92%;
  border-radius: 100%;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
`;

export const DealerImg = styled(motion.img)`
  width: 15px;
  position: absolute;
  left: ${(props) =>
    props.orientation === "LANDSCAPE"
      ? props.playerDirection === "right"
        ? "0"
        : "100"
      : props.left
      ? props.left
      : null}%;
  right: ${(props) =>
    props.orientation === "LANDSCAPE"
      ? props.playerDirection === "right"
        ? "100"
        : "0"
      : props.right
      ? props.right
      : null}%;
  bottom: ${(props) =>
    props.orientation === "LANDSCAPE"
      ? "100"
      : props.bottom
      ? props.bottom
      : null}%;
  top: ${(props) =>
    props.orientation === "LANDSCAPE" ? "0" : props.top ? props.top : null}%;
  display: ${(props) => (props.isShow ? "none" : "block")};
  /* box-shadow: 4px 4px 6px rgba(0, 0, 0, 1); */
  border-radius: 50%;

  // filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 1));

  transform: ${(props) =>
    props.orientation === "LANDSCAPE" ? "translate(-50%, -50%)" : null};
`;

export const AllInIcon = styled(DealerImg)`
  width: 38%;
  max-width: 30px;
  transform: scale(0);
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 1));
`;

export const EmptySeatWrapper = styled.div`
  width: calc(var(--app-width) * 0.1);
  height: calc(var(--app-width) * 0.1);
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.pebbleGray};
  font-size: 70%;
`;

export const EmptySeatText = styled.span`
  color: ${colors.pebbleGray};
  font-size: 80%;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LottieWrapper = styled.div`
  position: absolute;
  width: 140%;
  height: 140%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

export const WinnerLabel = styled.div`
  position: absolute;
  top: -2px;
  width: 110%;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 253, 234);
  font-size: 10px;
  text-transform: uppercase;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    #c62828;
  border-radius: 2px;
  font-weight: bold;
  letter-spacing: 1px;
  z-index: 10;

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

// Winner glowing animation
export const StarShine = styled.div`
  position: absolute;
  top: -6px;
  left: -9px;
  z-index: 4;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const AllInLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 40%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  font-size: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(
      270deg,
      rgba(205, 127, 50, 0.4) 0%,
      #cd7f32 50%,
      rgba(205, 127, 50, 0.4) 100%
    );
`;

export const SitOutLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 40%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  font-size: ${(props) => (props.type === "wait-for-bb" ? "6px" : "10px")};
  background: ${colors.primaryDeepBlue};
  font-weight: bold;
  white-space: nowrap;
`;

export const MuckLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 40%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  font-size: 6px;
  background: ${colors.black80};
  font-weight: bold;
  white-space: nowrap;
`;

export const UserActionLabel = styled(Typo10WhiteContoMediumText)`
  border-radius: 3px;
  border-top: 1px solid ${(props) => props.borderColor};
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  background: ${(props) =>
    props.actionBgColor ? props.actionBgColor : colors.transparent};
  position: absolute;
  width: ${(props) => (props.allIn ? "90%" : "40px")};
  bottom: ${(props) => (props.orientation === "LANDSCAPE" ? "0px" : "2px")};
  z-index: 10;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.allIn ? "bold" : null)};
  left: 50%;
  transform: ${(props) =>
    props.orientation === "LANDSCAPE" && props.position !== props.mySeat
      ? "translate(-50%, 100%) scale(0.95)"
      : "translate(-50%) scale(0.95)"};
  text-transform: capitalize;
`;

export const WinningAmount = styled(Typo12GreenContoMediumText)`
  position: absolute;
  top: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat === props.position
      ? "54%"
      : "102%"};
  left: ${(props) =>
    props.orientation === "LANDSCAPE" && props.mySeat === props.position
      ? "100%"
      : "unset"};
  z-index: 4;
  background: #00000085;
  /* background-color: red; */
  min-width: 100%;
  text-align: center;
  /* border-radius: 2px; */
  font-size: 14px;
  white-space: nowrap;
`;

export const PureRelativeWrapper = styled.div`
  position: ${(props) => (props.isOppositePlayer ? "absolute" : "relative")};
  width: 100%;
  height: 100%;
`;

export const BlindIconContainer = styled.div`
  position: absolute;
  left: ${(props) =>
    props.isOppositePlayer
      ? "50%"
      : props.position === "left"
      ? "105%"
      : props.position === "right"
      ? "unset"
      : props.position === "top"
      ? "50%"
      : "20%"};
  bottom: ${(props) =>
    props.isOppositePlayer
      ? "-35%"
      : props.position === "left" || props.position === "right"
      ? "-100%"
      : props.position === "top"
      ? props.lastAction
        ? "-35px"
        : "-22.5px"
      : "105%"};
  transform: ${(props) =>
    props.position === "top" || props.isOppositePlayer
      ? "translateX(-50%)"
      : null};
  right: ${(props) => (props.position === "right" ? "105%" : "unset")};
  display: ${(props) =>
    props.display && props.position !== "bottom" ? "flex" : "none"};
  flex-direction: ${(props) => (props.position === "right" ? "row" : "row")};
  align-items: center;
  background-color: ${(props) =>
    props.isPlainIcon ? "transparent" : " rgba(0, 0, 0, 0.75)"};
  border: ${(props) => (props.isPlainIcon ? "transparent" : `1px solid black`)};
  border-radius: 10px;
  opacity: 1;
  /* padding: 0.5px 1px; */
  height: 16px;
  animation: actionFadeIn 0.5s forwards;

  @keyframes actionFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MyBlindIconContainer = styled(motion.div)`
  position: absolute;
  bottom: ${(props) => (props.mySeat ? "110%" : "130%")};
  left: 50%;
  /* transform: translateX(-50%); */
  display: ${(props) => (props.display ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.isPlainIcon ? "transparent" : "  rgba(0, 0, 0, 0.75)"};
  border: ${(props) => (props.isPlainIcon ? "transparent" : `1px solid black`)};
  border-radius: 10px;
  opacity: 1;
  height: 16px;
  /* width: auto; */
  /* padding: 0.5px 1px; */
  /* animation: actionFadeIn 0.5s forwards; */

  /* @keyframes actionFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
`;

export const PlayerTableIcon = styled.img`
  /* width: ${(props) =>
    props.isBlind ? "auto" : props.isLandscape ? "12px" : "14px"}; */
  /* height: ${(props) => (props.isBlind ? "14px" : "auto")}; */
  height: 100%;
  width: auto;
`;

export const PlayerTableValue = styled.div`
  font-family: "Conto";
  font-size: 11px;
  margin: 0px 5px;
  white-space: nowrap;
  /* font-weight: bold; */
`;

export const PlayerHandRankWrapper = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  bottom: ${(props) => (props.orientation === LANDSCAPE ? 0 : 5)}px;
  border-top: 1px solid rgba(234, 191, 105, 0.09);
  border-bottom: 1px solid rgba(234, 191, 105, 0.09);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 31.25%,
    rgba(0, 0, 0, 0.4) 65.1%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: center;
  // align-items: center;
  // text-align: center;
`;

export const PlayerHandRankText = styled.span`
  font-family: Conto;
  font-size: 56%;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
  background: linear-gradient(
    114deg,
    #eabf69 0%,
    #fffdea 5.21%,
    #eabf69 10.6%,
    #fffdea 15.28%,
    #eabf69 19.57%,
    #fffdea 23.98%,
    #eabf69 28.65%,
    #fffdea 33.44%,
    #eabf69 38.37%,
    #fffdea 43.06%,
    #eabf69 47.77%,
    #fffdea 52.57%,
    #eabf69 57.33%,
    #fffdea 62.12%,
    #eabf69 66.73%,
    #fffdea 71.46%,
    #eabf69 76.17%,
    #fffdea 80.92%,
    #eabf69 85.6%,
    #fffdea 90.03%,
    #eabf69 94.27%,
    #fffdea 98.42%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const OtherPlayerCardsContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: fit-content;
  bottom: 10%;
  right: ${(props) => (props.isLeftPlayer ? 0 : props.isTexas ? -35 : -28)}px;
  z-index: 3;

  opacity: ${(props) => (props.isFolded ? 0.5 : 1)};
  transition: ${(props) => (props.isFolded ? 1 : 0.5)};
  animation: ${(props) =>
    props.isFolded ? "" : " showOtherPlayerCards 0.15s backwards"};

  @keyframes showOtherPlayerCards {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes hideOtherPlayerCards {
    0% {
      top: -20%;
      left: ${(props) =>
        props.isLeftPlayer ? `0%` : props.isTexas ? `100%` : `85%`};
      transform: scale(1)
        ${(props) =>
          props.isLeftPlayer
            ? "rotate(-20deg)"
            : "rotate(20deg) translate(-50%, 50%)"};
    }
    50% {
      top: -40%;
      left: 100%;
      transform: scale(1)
        ${(props) =>
          props.isLeftPlayer
            ? "rotate(-20deg)"
            : "rotate(20deg) translate(-50%, 50%)"};
    }
    100% {
      top: 50%;
      left: 50%;
      opacity: 0.1;
      transform: scale(0.25) translate(-50%, -50%)
        ${(props) =>
          props.isLeftPlayer
            ? "rotate(-20deg)"
            : "rotate(20deg) translate(-50%, 50%)"};
    }
  }
`;

export const OtherPlayerCard = styled.img`
  width: ${(props) => (props.gameType === TEXAS_HOLDEM ? 23 : 21)}%;
  height: ${(props) =>
    props.gameType === TEXAS_HOLDEM
      ? 1.2
      : 1}rem; // added it to adjust cards in iphone6

  transition: transform 1s ease;
  transform: ${(props) =>
    props.gameType === TEXAS_HOLDEM
      ? props.index === 0
        ? `rotate(-10deg) translateY(${props.index}px)`
        : `rotate(${10 * props.index}deg) translateY(${props.index}px)`
      : props.gameType === OMAHA
      ? `translateY(${
          props.index === 0 || props.index === 3 ? 2.5 : 1
        }px)  rotate(${
          props.index === 0
            ? -24
            : props.index === 1
            ? -10
            : props.index === 2
            ? 10
            : 20
        }deg) `
      : `translateY(${
          props.index === 0 || props.index === 3
            ? 2
            : props.index === 1 || props.index == 2
            ? 1
            : 4
        }px)  rotate(${
          props.index === 0
            ? -28
            : props.index === 1
            ? -20
            : props.index === 2
            ? 0
            : props.index === 3
            ? 20
            : 24
        }deg) `};

  margin-left: ${(props) => (props.index > 0 ? -8.6 : 0)}px;
`;

export const PlayerViewContainer = styled.div`
  position: relative;
  animation: fade-in forwards 0.5s;
  z-index: 2;
`;

export const TourneyChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ChipImg = styled.img`
  width: 10px;
  margin-right: 2px;
`;

export const ChipsStackIconWrapper = styled.img`
  margin-right: 4px;
  height: 10px;
`;

export const TimerTypeLabel = styled.div`
  width: ${(props) => (props.type === DISCONNECTION_TIMER ? "100" : "95")}%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 2px;
  border-radius: 2px;
  border: 1px solid rgb(255 255 255 / 48%);
  background-color: rgb(0 0 0 / 65%);
  text-align: center;
  left: ${(props) => (props.type === DISCONNECTION_TIMER ? -2 : 0)}px;
  z-index: 5;
  padding: 1%;
  transform: ${(props) => (props.isLoggedInPlayer ? "scale(0.8)" : "scale(1)")};
`;

export const PlayerNotesIconWrapper = styled.img`
  width: 16.48px;
  height: 20px;

  position: absolute;
  right: -10px;
`;

export const PlayerSitHereIconWrapper = styled.img`
  height: 100%;
  width: 100%;
`;

export const ExtraTimeLeftWrapper = styled.div`
  width: 18px;
  position: absolute;
  bottom: -50%;
  left: -50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: show-clock 0.1s ease;
  @keyframes show-clock {
    from {
      transform: scale(0.3);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const ExtraTimeLeftIcon = styled.img`
  width: 100%;
`;

export const ExtraTimeLeftText = styled(Typo10BlackContoBoldText)`
  position: absolute;
  margin-top: 0.5vh;
  color: ${colors.black};
  font-weight: bold;
`;
