import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10WhiteContoRegularText,
  Typo10WhiteTwoBoldText,
  Typo12GrayContoRegularText,
  Typo12PrimaryGoldContoRegularText,
  Typo12WhiteContoMediumText,
  Typo12WhiteTwoContoBoldText,
  Typo14LightYellowContoBoldText,
  Typo14PrimaryGoldContoBoldText,
  Typo14PrimaryGoldContoMediumText,
  Typo14WhiteContoMediumText,
  Typo16PrimaryGoldBoldText,
  Typo16WhiteContoBoldText,
  Typo16WhiteContoMediumText,
  Typo8WhiteTwoRegularText,
} from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: var(--app-height);
  background-color: ${colors.black60};
  position: absolute;
  z-index: 8;
  top: 0px;
  left: 0px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: var(--app-height);
  background: ${colors.deepBlue};
  width: 90%;
  max-width: 500px;
`;

export const RankingLeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  width: 90%;
  max-width: 500px;
  height: 100%;
  max-height: var(--app-height);
  background: ${colors.deepBlue};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "play-left-to-right 0.25s forwards"
      : "play-right-to-left 0.25s forwards"};

  @keyframes play-left-to-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes play-right-to-left {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const RankingLeaderboardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #075063;
  box-sizing: border-box;
  padding: 12px;
`;

export const TourneyRankingLeaderBoardScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 46px);
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 46px;
  width: 40px;
`;

export const HeaderTitleText = styled(Typo16WhiteContoBoldText)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
`;

export const RankingLeaderboardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 12px;
  box-sizing: border-box;
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  justify-content: center;
  box-sizing: border-box;
`;

export const CurrentLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: auto;
  align-items: center;
`;

export const NextLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: auto;
  align-items: center;
`;

export const LevelHeadingLabel = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const LevelText = styled(Typo14WhiteContoMediumText)`
  line-height: 24px;
  text-align: center;
`;

export const TourneyPaidRankAndEntrantsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  padding-bottom: 12px;
  margin-top: 12px;
  box-sizing: border-box;
  align-items: flex-start;
`;

export const AddIconText = styled(Typo16WhiteContoMediumText)`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`;

export const PaidItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
`;

export const Labeltext = styled(Typo12GrayContoRegularText)``;

export const LabelValueText = styled(Typo14WhiteContoMediumText)`
  display: ${(props) => (props.isPlayerName ? "inline-block" : "flex")};
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.isCurrentBlind
      ? colors.primaryGold
      : props.isBustedOutPlayer
      ? "#fffdea75"
      : colors.white};
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 4px 12px 4px 12px;
  box-sizing: border-box;
  height: 33px;
  min-height: 33px;
  span:first-child {
    justify-content: flex-start;
    border-bottom: ${(props) =>
      props.isActive ? `1px solid ${colors.leafyGreen}` : "none"};
  }
  /* span:last-child {
    justify-content: flex-end;
  } */
  span {
    color: ${(props) => (props.isActive ? colors.offWhite : colors.gray)};
  }
`;
export const TableIdText = styled(Typo14WhiteContoMediumText)`
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SlicedTourneyIDText = styled(Typo14WhiteContoMediumText)`
  text-transform: uppercase;
  border-bottom: none !important;
`;

export const TableValueText = styled(Typo14WhiteContoMediumText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EntriesItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
`;

export const YourRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
`;

export const HorizontalSeperatorLine = styled.span`
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${colors.sideMenuSeperator};
  margin-top: ${(props) => (props.hideMargin ? "0px" : "1px")};
  margin-bottom: ${(props) => (props.hideMargin ? "0px" : "1px")};
`;

export const AddonsAndRebuysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  .value {
    margin-left: 10px;
  }
`;

export const AddOnsWrapper = styled.div`
  display: flex;
  justify-content: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 0px 12px;

  span {
    line-height: 24px;
  }
`;

export const RebuyWrapper = styled.div`
  display: flex;
  justify-content: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px 12px 0px 12px;
  .span {
    line-height: 24px;
  }
`;

export const TotalAddOnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AddOnFeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TotalRebuysWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RebuyFeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RebuyDescriptionText = styled(Typo10WhiteContoRegularText)`
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
`;

export const BlindsupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: auto;
  align-items: center;
  justify-content: center;
  background: #022129;
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  box-sizing: border-box;
`;

export const BlindsUpTimeText = styled(Typo14PrimaryGoldContoBoldText)`
  line-height: 24px;
`;

export const GoldUpArrowIcon = styled.img`
  height: 9px;
  width: 10px;
  margin-left: 2px;
`;

export const TourneyStatsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;

  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  padding: 12px 16px 10px 16px;
`;

export const StatsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  span {
    line-height: 24px;
  }
`;

export const LateRegistrationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const LateRegistrationText = styled(Typo12WhiteContoMediumText)`
  margin-left: 8px;
`;

export const DotIcon = styled.img`
  height: 10px;
  width: 10px;
`;

export const TabbarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  /* border-bottom: 1px solid ${colors.primaryGold}; */
  .tab-item-container {
    height: 52px;
    min-height: 52px;
  }
  .tab-item {
    width: 100%;
    height: 52px;
  }
`;

export const TourneySelectedTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  padding: 16px 12px;
  overflow-y: auto;
  position: relative;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 295px;
  border-radius: 4px;
  border: 1px solid #336070;

  max-height: 295px;
  overflow-y: scroll;
`;

export const RankingTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #336070;

  max-height: 295px;
  overflow-y: scroll;
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 10px 12px;
  box-sizing: border-box;
  border: 1px solid #336070;

  // margin-bottom: 8px;
  height: 42px;
  min-height: 42px;
`;

export const TableHeaderItemWrapper = styled.div`
  width: 25%;
  text-align: ${(props) =>
    props.isPlayerName || props.isTableName || props.isLevelField
      ? "left"
      : "center"};
  span {
    line-height: 24px;
  }
`;

export const TableRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12px 0px 12px;
  box-sizing: border-box;
  height: 33px;
  min-height: 33px;
`;

export const PrizeTableRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12px 0px 12px;
  box-sizing: border-box;
  height: 33px;
  min-height: 33px;
`;

export const RankingTableRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px 8px 12px;
  box-sizing: border-box;
  height: 33px;
  min-height: 33px;
  background-color: ${(props) =>
    props.isOwnPlayerName ? " #336070" : "inherit"};
`;

export const TableRowItemWrapper = styled.div`
  display: flex;
  width: 25%;
  display: flex;
  justify-content: ${(props) =>
    props.isPlayerName || props.isLevelField ? "flex-start" : "center"};
  align-items: center;
  /* 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const PrizeItemPositionWrapper = styled.div`
  display: flex;
  width: 25%;
  display: flex;
  justify-content: ${(props) =>
    props.isPlayerName || props.isLevelField ? "flex-start" : "center"};
  align-items: center;
  /* 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
export const PrizeItemAmountWrapper = styled.div`
  display: flex;
  width: 75%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  /* 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
`;

export const TextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
`;

export const PrizeAmountText = styled(Typo14PrimaryGoldContoMediumText)`
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

export const TicketIcon = styled.img`
  height: 13px;
  width: 13px;
`;

export const TicketsCountText = styled(Typo14PrimaryGoldContoMediumText)`
  margin-right: 2px;
`;

export const EntriesText = styled(Typo12GrayContoRegularText)`
  line-height: 12px;
  display: flex;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
`;

export const DynamicPayoutStructureText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  display: flex;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  margin-top: 8px;
  /* height: 100%; */
`;

export const TourneyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  border: 1px solid #336070;
  border-radius: 8px;
`;

export const PrizePoolContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #336070;
  justify-content: center;
  align-items: center;
`;

export const GoldTrophyIconWrapper = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 12px;
`;

export const PrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrizeWorthText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  margin-bottom: 2px;
`;

export const TourneyLevelsAndBlindsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  border: 1px solid #336070;
  border-radius: 8px;
  margin-top: 16px;
`;

export const TourneyBlindsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #336070;
`;

export const BlindGreySingleChipIcon = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const TourneySectionTitleText = styled(Typo12WhiteTwoContoBoldText)`
  line-height: 16.26px;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  border: 1px solid #336070;
  border-radius: 8px;
  margin-top: 16px;
`;

export const ChipsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #336070;
`;

export const GreyChipsIcon = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const TabbarAndTabSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* justify-content: flex-start;
  align-items: center; */
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  /* border: 1px solid #336070;
  border-radius: 8px; */
  /* margin-top: 16px; */
  .tab-item {
    background: linear-gradient(
      180deg,
      rgba(5, 58, 71, 0.8) 0%,
      rgba(4, 47, 58, 0.8) 100%
    );
  }
`;

export const ActivePlayersCountDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const PlayerIconWrapper = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const ActivePlayerCountText = styled(Typo12PrimaryGoldContoRegularText)`
  line-height: 13.55px;
`;

export const RemainingText = styled(Typo12PrimaryGoldContoRegularText)`
  line-height: 13.55px;
  margin-left: 4px;
`;

export const VerticalSeperator = styled.img`
  height: 40px;
`;
