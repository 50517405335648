import Handler from "../Handler";

class PrizePotHandler extends Handler {
  execute(message) {
    // PrizePot#
    let receivedData = JSON.parse(message);
    this.service.processPrizePot(receivedData);
  }
}

export default PrizePotHandler;

// PrizePot#
// {
//   "totalPot": 45,
//   "multiPotsPojo": [
//       {
//           "potAmount": 30,
//           "participatingPlayerNames": [
//               "lord25697",
//               "guru"
//           ]
//       },
//       {
//           "potAmount": 15,
//           "participatingPlayerNames": [
//               "guru"
//           ]
//       }
//   ],
//   "gameStage": "Pre_FLOP",
//   "userInvestment": {
//       "guru": 30,
//       "lord25697": 15
//   }
// }
