import Handler from "../Handler";

class JoinAckHandler extends Handler {
  execute(message) {
    /** JoinA#{position: 0, tableId: "THD020823-2859544rvhtj", status: "SUCCESS", gameId: "020823-2859544xwnr7",atTableAmount:124} */
    let receivedData = JSON.parse(message);
    if (receivedData.status === "SUCCESS") {
      this.service.processGameJoinRequest(receivedData);
    }
  }
}

export default JoinAckHandler;
