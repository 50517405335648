import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  MultiPotContainer,
  PotAmountChip,
  PotAmountChipsContainer,
  PotAmountText,
  PotContainer,
} from "./FramerPotStyles";
import redChip from "../../../assets/gameTable/chips.png";
import blackChip from "../../../assets/gameTable/chip-black.png";
import blueChip from "../../../assets/gameTable/chip-blue.png";
import greenChip from "../../../assets/gameTable/chip-green.png";
import yellowChip from "../../../assets/gameTable/chip-yellow.png";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import FramerChipComponent from "./FramerChipComponent";

const FramerPotAmount = ({ tempTableId }) => {
  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId].gameId
  );
  const multiPotAmounts = useSelector(
    (state) => state.gameTable.games[tempTableId]?.multiPotAmounts
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable.tableSettings.BBDisplay
  );
  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.bigBlind
  );
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  let allChipImages = [redChip, blueChip, yellowChip, greenChip, blackChip];
  const [numberOfChips, setNumberOfChips] = useState(1);
  const [hiddenPots, setHiddenPots] = useState([]);

  const updateHiddenPots = (shouldHidePot, index) => {
    if (shouldHidePot) {
      // This code is written so that even if the user goes to the lobby during winner declaration adn comes back, the previous pots will not show up on the table.
      let alreadyHiddenPots = [];
      for (let i = 0; i < index; i++) {
        if (i < index) {
          alreadyHiddenPots.push(i);
        }
      }
      let timeout;
      timeout = setTimeout(() => {
        setHiddenPots([index, ...hiddenPots, ...alreadyHiddenPots]);
        clearTimeout(timeout);
      }, 1000);
    }
  };

  useEffect(() => {
    if (winningPotData) {
      setNumberOfChips(10 * winningPotData?.winnerNames?.length);
    } else {
      setNumberOfChips(1);
    }
  }, [winningPotData]);

  useEffect(() => {
    setHiddenPots([]);
  }, [gameId, multiPotAmounts]);

  useEffect(() => {
    if (winningPotData) {
      updateHiddenPots(true, winningPotData?.potIndex);
    }
  }, [winningPotData]);
  console.log("MULTI_POTS_CHECK_CRASH ", multiPotAmounts);
  return (
    <MultiPotContainer>
      {multiPotAmounts?.length > 0
        ? multiPotAmounts.map((pot, index) => {
            return (
              <PotContainer
                key={"multiPotIndex-" + index}
                initial={{
                  scale: 1,
                  opacity: 0,
                }}
                animate={{
                  scale: hiddenPots.includes(index) ? 0 : 1,
                  opacity: hiddenPots.includes(index)
                    ? 0
                    : winningPotData && index !== winningPotData?.potIndex
                    ? 0.25
                    : 1,
                }}
              >
                <PotAmountChipsContainer>
                  {!winningPotData ? (
                    <PotAmountChip src={allChipImages[index]} />
                  ) : winningPotData ? (
                    winningPotData?.winnerNames.map((winner, winnerIndex) =>
                      Array.from({ length: numberOfChips }).map(
                        (item, chipIndex) => {
                          return (
                            <FramerChipComponent
                              key={
                                "winner-" +
                                winnerIndex +
                                "-potIndex-" +
                                index +
                                "-chipIndex-" +
                                chipIndex
                              }
                              index={index}
                              chipIndex={chipIndex}
                              winnerIndex={winnerIndex}
                              winningPotData={winningPotData}
                              winner={winner}
                              shouldAnimate={
                                winningPotData &&
                                index === winningPotData?.potIndex &&
                                winningPotData?.playAnimation
                              }
                            />
                          );
                        }
                      )
                    )
                  ) : null}
                </PotAmountChipsContainer>
                <PotAmountText
                  exit={{
                    opacity: 0,
                    scale: 0,
                    transition: {
                      delay: 1,
                    },
                  }}
                >
                  {BBDisplay
                    ? parseDecimalValuesOfNumber(
                        pot?.potAmount / bigBlindValue,
                        1
                      ) + " BB"
                    : parseDecimalValuesOfNumber(
                        pot?.potAmount,
                        2,
                        checkToDisplayValueInK(pot?.potAmount),
                        checkToDisplayValueInL(pot?.potAmount)
                      )}
                </PotAmountText>
              </PotContainer>
            );
          })
        : null}
    </MultiPotContainer>
  );
};

export default FramerPotAmount;
