import { cloneDeep, isEqual } from "lodash";

import store from "../redux/store";
import TableObserver from "./TableObserver";
import {
  CHECK_GAME_API,
  CHECK_GAME_API_READY_TO_REQUEST,
  GAME_SOCKET_REMOVED,
  MATCH_MAKING_API_READY_TO_REQUEST,
  SEQUENTIAL_FINISH,
  SEQUENTIAL_HOLD,
  SEQUENTIAL_RESET,
  SEQUENTIAL_START,
} from "../data/Constants";
import GameConnectionsN from "../io/GameConnectionsN";
import {
  deleteGameTable,
  updateGameConnectionStatusN,
} from "../redux/slices/gameTableSlice";
import { getGameSlice } from "./ReduxUtils";

class TableUtils {
  static tableUtilInstance;

  constructor() {
    if (!TableUtils.tableUtilInstance) {
      this.watchTables = [];
      this.removedTempTableids = [];
      this.observerRecreatedTempTableids = [];
      this.sequentialArray = [];
      this.dispatchFunction = undefined;

      this.init();
      TableUtils.tableUtilInstance = this;
    }
    return TableUtils.tableUtilInstance;
  }

  init = () => {
    console.log("Init method triggered for table util");
    // this.store = store;
    // this.store.subscribe(this.onStoreUpdate);
  };

  getTableObserver = (tempTableId) => {
    console.log("In get observer call: ", tempTableId);
    console.log(
      "this.watchTables.length : ",
      this.watchTables[0],
      this.watchTables.length
    );
    for (let index = 0; index < this.watchTables.length; index++) {
      let observer = this.watchTables[index];

      if (observer.tempTableId === tempTableId) {
        console.log("Returning observer");
        console.log(observer);
        return observer;
      }
    }
  };

  containsTableUpdateObserver = (tempTableId) => {
    let alreadyCreated = false;

    for (let index = 0; index < this.watchTables.length; index++) {
      let tableObserver = this.watchTables[index];
      if (tableObserver.tempTableId === tempTableId) {
        alreadyCreated = true;
        break;
      }
    }

    if (!alreadyCreated) {
      //this would be triggered only if not found in 'watchTables'
      for (let index = 0; index < this.removedTempTableids.length; index++) {
        let removedTempTableid = this.removedTempTableids[index];
        if (removedTempTableid === tempTableId) {
          alreadyCreated = true;
          break;
        }
      }
    }

    return alreadyCreated;
  };

  addTableObserver = (tempTableId, gameDefinitionId, dispatch) => {
    console.log("in addTableObserver this.watchTables: ", this.watchTables);
    let addedTableObserver = false;
    this.dispatchFunction = dispatch;

    if (!this.containsTableUpdateObserver(tempTableId)) {
      let tableObserver = new TableObserver(
        tempTableId,
        gameDefinitionId,
        dispatch
      );
      this.watchTables.push(tableObserver);

      addedTableObserver = true;
      console.log("Watch Table to add: ", tempTableId, tableObserver);
    } else {
      console.log("Watch Table already exists: ", tempTableId);
    }

    return addedTableObserver;
  };

  removeTableObserver = (
    tempTableId,
    allowDeleteGameTable = true,
    isMaintenanceMode = false
  ) => {
    let isTableObserverFound = false;

    for (let index = this.watchTables.length - 1; index >= 0; index--) {
      let observer = this.watchTables[index];

      if (observer.tempTableId === tempTableId) {
        isTableObserverFound = true;
        console.log("Cleaning up table observer:", observer.tempTableId);
        observer.cleanUp(allowDeleteGameTable);

        this.watchTables.splice(index, 1);
        observer = undefined;
        break;
      }
    }
    if (!isTableObserverFound) {
      console.warn("TABLE OBSERVER NOT FOUND at DELETION:", tempTableId);
    }
    GameConnectionsN.getInstance().removeGameConnection(
      tempTableId,
      isMaintenanceMode
    );
    this.passGameToSequential(tempTableId, "remove");

    if (this.removedTempTableids.indexOf(tempTableId) === -1) {
      this.removedTempTableids.push(tempTableId);
    }

    //below check is to update the connection status of game in redux when only the connection is removed/closed in one call and the
    //(continuation...) game table removal call will be given after user does some action like CTA on any modal alerts.
    if (isTableObserverFound && !allowDeleteGameTable) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: tempTableId,
          status: GAME_SOCKET_REMOVED,
        })
      );
    }

    //below check is to clear out the tables for which only the connection is removed/closed in one call and the
    //(continuation...) game table removal call is given after user does some action like CTA on any modal alerts.
    if (!isTableObserverFound && allowDeleteGameTable) {
      this.dispatchFunction(deleteGameTable({ tempTableId: tempTableId }));
    }
  };

  overrideRemovedTableObserver = (tempTableId) => {
    let indexOfTempTableidInRemoved =
      this.removedTempTableids.indexOf(tempTableId);
    console.log(
      "indexOfTempTableidInRemoved: ",
      indexOfTempTableidInRemoved,
      tempTableId
    );
    if (indexOfTempTableidInRemoved >= 0) {
      this.removedTempTableids.splice(indexOfTempTableidInRemoved, 1);
    } else {
      console.log("NOT NOT NOT FOUND");
    }

    //adds if not, leaves if already exists
    let indexOfTempTableidInRecreated =
      this.observerRecreatedTempTableids.indexOf(tempTableId);
    if (indexOfTempTableidInRecreated === -1) {
      this.observerRecreatedTempTableids.push(tempTableId);
    }
  };

  toggleEventBasedNetworkStatus = (isOffline = true) => {
    // console.log("YASHWANTH LOG - this.watchTables: ", this.watchTables);
    // console.log("YASHWANTH LOG - this.watchTables LENGTH: ", this.watchTables.length);
    for (let index = 0; index < this.watchTables.length; index++) {
      let tableObserver = this.watchTables[index];
      // console.log("YASHWANTH LOG - Table observer:", tableObserver.tempTableId + "  status:", tableObserver.getGameConnectionStatus());

      let gameConnection = GameConnectionsN.getInstance().getGameConnection(
        tableObserver.tempTableId
      );
      let gameListener = gameConnection.listener;
      if (gameListener) {
        // console.log("YASHWANTH LOG - Communication channel:", gameListener.getCommunicationChannel())

        if (gameListener.getCommunicationChannel()) {
          if (!gameListener.isConnectionEstablishedOnce) {
            // console.log("Yashwanth log - Invoking MANUAL ON ERROR temp table id:", tableObserver.tempTableId);
            gameListener.getCommunicationChannel().onError();
          } else {
            // console.log("Yashwanth log - Invoking MANUAL ON CLOSE temp table id:", tableObserver.tempTableId);
            gameListener.getCommunicationChannel().onClose();
          }
        } else {
          // console.log("Yashwanth log - COMMUNICATION CHANNEL NOT FOUND temp table id:", tableObserver.tempTableId);
        }
      }
    }
  };

  updateGameConnectionStatus = (
    tempTableId,
    status,
    forceDelay = false,
    atTableCreation = false
  ) => {
    // 'forceDelay' is set to true if 'atTableCreation' is true and 'sequentialArray' is in progress.
    // This is done inorder to initiate the API request with a timeout in case there is more than one game window.
    // In case, this is not done, API requests of two game windows will be sent at same time.
    if (atTableCreation && this.sequentialArray.length >= 1) {
      forceDelay = true;
    }

    if (atTableCreation) {
      console.log("FORCE FORCE FORCE SETTING DELAY: ", forceDelay);
    }

    for (let index = 0; index < this.watchTables.length; index++) {
      let observer = this.watchTables[index];

      if (observer.tempTableId === tempTableId) {
        observer.setGameConnectionStatus(status);
        observer.setForceDelay(forceDelay);
        break;
      }
    }
  };

  // initiateNodeMismatchFlow = (tempTableId) => {
  //   this.dispatchFunction(
  //     updateGameConnectionStatusN({
  //       tempTableId: tempTableId,
  //       status: CHECK_GAME_API_READY_TO_REQUEST,
  //     })
  //   );

  //   let gameConnection =
  //     GameConnectionsN.getInstance().getGameConnection(tempTableId);
  //   gameConnection.listener.cleanUpGameListener(false);

  //   this.updateGameConnectionStatus(
  //     tempTableId,
  //     CHECK_GAME_API_READY_TO_REQUEST
  //   );
  //   this.passGameToSequential(tempTableId, "add");
  // };

  initiateNodeMismatchFlow = (tempTableId) => {
    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: tempTableId,
        status:
          getGameSlice().games[tempTableId].gameAPIState === CHECK_GAME_API
            ? CHECK_GAME_API_READY_TO_REQUEST
            : MATCH_MAKING_API_READY_TO_REQUEST,
      })
    );

    let gameConnection =
      GameConnectionsN.getInstance().getGameConnection(tempTableId);
    gameConnection.listener.cleanUpGameListener(false);

    this.updateGameConnectionStatus(
      tempTableId,
      getGameSlice().games[tempTableId].gameAPIState === CHECK_GAME_API
        ? CHECK_GAME_API_READY_TO_REQUEST
        : MATCH_MAKING_API_READY_TO_REQUEST
    );
    this.passGameToSequential(tempTableId, "add");
  };

  passGameToSequential = (tempTableId, type, atTableCreation) => {
    let indexOfTempTableId = this.sequentialArray.indexOf(tempTableId);
    let previousFirstIdInSequential = this.sequentialArray[0];

    //'proceedWithLoop' is added during 'atTableCreation' so as not to trigger the loop when 'sequentialArray' process is already going on. Triggering again may create issue(s).
    let proceedWithLoop = atTableCreation
      ? this.sequentialArray.length >= 1
        ? false
        : true
      : true;

    if (indexOfTempTableId >= 0) {
      this.sequentialArray.splice(indexOfTempTableId, 1);
      console.log(
        "Removed from clonedSequentialArray: : ",
        this.sequentialArray,
        proceedWithLoop
      );
    }

    if (type === "add") {
      console.log("Adding temp table id to Sequential: ", tempTableId);
      this.sequentialArray.push(tempTableId);
    }

    if (this.sequentialArray.length === 0) {
      let currentTableObserver;
      for (let index = 0; index < this.watchTables.length; index++) {
        let observer = this.watchTables[index];
        if (observer.tempTableId === tempTableId) {
          currentTableObserver = observer;
          break;
        }
      }
      // if(lobbySliceData){
      //     currentTableObserver.lobbySliceData = lobbySliceData;
      // }
      if (currentTableObserver) {
        console.log("Setting sequential status FINISH");
        currentTableObserver.setSequentialStatus(SEQUENTIAL_FINISH);
      }
      // currentTableObserver.setForceDelay(forceDelay);
      // currentTableObserver.setSequentialStatus(SEQUENTIAL_FINISH);
    } else {
      if (proceedWithLoop) {
        console.log("Sequential Array in Table Util: ", this.sequentialArray);
        for (let index = 0; index < this.sequentialArray.length; index++) {
          let sequentialId = this.sequentialArray[index];

          for (
            let indexOne = 0;
            indexOne < this.watchTables.length;
            indexOne++
          ) {
            let observer = this.watchTables[indexOne];
            let breakTheLoop = false;

            if (observer.tempTableId === sequentialId) {
              // if(lobbySliceData){
              //     observer.lobbySliceData = (index === 0) ? lobbySliceData : observer.lobbySliceData;
              // }
              console.log("SETTING TO FUNCTIOn: ", index);
              console.log(
                "ID's: ",
                observer.tempTableId,
                tempTableId,
                sequentialId
              );

              if (index === 0) {
                if (previousFirstIdInSequential === observer.tempTableId) {
                  // observer.setForceDelay(true);
                  observer.setSequentialStatus(SEQUENTIAL_RESET);
                }
                observer.setSequentialStatus(SEQUENTIAL_START);
              } else {
                observer.setSequentialStatus(SEQUENTIAL_HOLD);
              }

              // observer.setSequentialStatus((index === 0) ? SEQUENTIAL_START : SEQUENTIAL_HOLD);
              breakTheLoop = true;
            }

            // observer.setForceDelay((observer.tempTableId === tempTableId) ? forceDelay : observer.getForceDelay());

            if (breakTheLoop) {
              break;
            }
          }
        }
      } else {
        console.log("Not looping at sequential");
      }
    }
  };

  // passGameToSequential = (tempTableId, type, connectionStatus, lobbySliceData, forceDelay) => {
  //     let indexOfTempTableId = this.sequentialArray.indexOf(tempTableId);

  //     if (indexOfTempTableId >= 0) {
  //         this.sequentialArray.splice(indexOfTempTableId, 1);
  //         console.log("Removed from clonedSequentialArray: : ", this.sequentialArray);
  //     }

  //     if (type === "add") {
  //         console.log("Adding temp table id to Sequential: ", tempTableId);
  //         this.sequentialArray.push(tempTableId);
  //     }

  //     if (this.sequentialArray.length === 0) {
  //         let currentTableObserver;
  //         for (let index = 0; index < this.watchTables.length; index++) {
  //             let observer = this.watchTables[index];
  //             if (observer.tempTableId === tempTableId) {
  //                 currentTableObserver = observer;
  //                 break;
  //             }
  //         }
  //         if(lobbySliceData){
  //             currentTableObserver.lobbySliceData = lobbySliceData;
  //         }
  //         currentTableObserver.setSequentialStatus(SEQUENTIAL_FINISH);
  //         currentTableObserver.setForceDelay(forceDelay);
  //         currentTableObserver.setGameConnectionStatus(connectionStatus);
  //         // currentTableObserver.setSequentialStatus(SEQUENTIAL_FINISH);

  //       }else{
  //         console.log("Sequential Array in Table Util: ", this.sequentialArray);
  //         for(let index=0; index<this.sequentialArray.length; index++){
  //             let sequentialId = this.sequentialArray[index];

  //             for(let indexOne = 0; indexOne<this.watchTables.length; indexOne++){
  //                 let observer = this.watchTables[indexOne];
  //                 let breakTheLoop = false;

  //                 if (observer.tempTableId === sequentialId) {
  //                     if(lobbySliceData){
  //                         observer.lobbySliceData = (index === 0) ? lobbySliceData : observer.lobbySliceData;
  //                     }
  //                     console.log("SETTING TO FUNCTIOn: ", index);
  //                     console.log("ID's: ", observer.tempTableId, tempTableId, sequentialId);
  //                     observer.setSequentialStatus((index === 0) ? SEQUENTIAL_START : SEQUENTIAL_HOLD);
  //                     breakTheLoop = true;
  //                 }

  //                 observer.setForceDelay((observer.tempTableId === tempTableId) ? forceDelay : observer.getForceDelay());
  //                 observer.setGameConnectionStatus((observer.tempTableId === tempTableId) ? connectionStatus : observer.getGameConnectionStatus());

  //                 if(breakTheLoop){
  //                     break;
  //                 }
  //             }
  //         }
  //       }
  // }

  // onStoreUpdate = () => {
  //   let state = this.store.getState();
  //   let lobbySliceData = state["lobby"];
  //   let gameSlice = state["gameTable"];

  //   // let playerLobbyState = lobbySlice.player;
  //   // let channel = lobbySlice.channel;

  //   // let tables = gameSlice.games;
  //   let tables = cloneDeep(gameSlice.games);

  //   console.log("Starting loop");
  //   for (let index = 0; index < this.watchTables.length; index++) {
  //     let tableObserver = this.watchTables[index];
  //     let watchTableId = tableObserver.tempTableId;

  //     let tableData = tables[watchTableId];

  //     if (tableData) {
  //       console.log("Passing table data: ", watchTableId, tableData);
  //       tableObserver.observe(tableData, lobbySliceData);
  //     }
  //     console.log("Loop end: ", index);
  //   }
  // };

  static getInstance() {
    if (!TableUtils.tableUtilInstance) {
      return new TableUtils();
    } else {
      return TableUtils.tableUtilInstance;
    }
  }
}

export default TableUtils;
