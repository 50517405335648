import Handler from "../Handler";

class ReserveFailureHandler extends Handler {
  execute(message) {
    /** reserved --- {"playerName":"pradeep","position":1,"gender":"M"} */

    let receivedData = JSON.parse(message);
    this.service.processReserveFailure(receivedData);
  }
}

export default ReserveFailureHandler;
