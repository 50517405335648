import Handler from "../Handler";

class TourneyPrizePoolUpdateHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPrizePoolUpdateHandler(receivedData);
  }
}
export default TourneyPrizePoolUpdateHandler;

//prizepoolupdate#{"event":"update-prize","tourneyId":"ss23L","updatedPrizePool":1000.0}
