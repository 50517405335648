import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const TableThemeWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: ${(props) => (props.orientation === "LANDSCAPE" ? "40%" : "40%")};
  min-height: ${(props) =>
    props.orientation === "PORTRAIT" ? "400px" : "250px"};
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  display: flex;
  flex-direction: column;
  /* background-color: rgba(8, 13, 26, 1); */
  background-color: #052c36;
  overflow: scroll;
`;

export const ThemeHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primaryGold};
  text-shadow: 0px 2px 2px black;
  background: ${colors.deepBlue};
  font-size: 14px;
  font-weight: bold;
  height: 80px;
  background: ${colors.primaryBlue};
`;

export const HeaderText = styled.span`
  color: ${colors.primaryGold};
  text-shadow: 0px 2px 2px black;
  background: ${colors.deepBlue};
  font-size: 14px;
  font-weight: bold;
`;

export const ThemeTypes = styled.div`
  /* background: linear-gradient(to bottom, white, #0F0F0F); */
  background-color: ${colors.primaryBlue};
  height: 50px;
  display: flex;
  justify-content: space-evenly;
`;

export const CardThemeTypes = styled.div`
  background: ${colors.primaryBlue};
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
`;

export const ThemeType = styled.div`
  /* transition: 0.5s ease; */
  color: white;
  background: ${(props) => (props.active ? "#075063" : colors.primaryBlue)};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 40px;
`;

export const CardType = styled.div`
  /* transition: 0.5s ease; */
  color: white;
  background: ${(props) => (props.active ? "#052C36" : colors.deepBlue)};
  box-shadow: ${(props) =>
    props.active ? "0px 0px 2px 0px black inset" : null};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 40px;
`;

export const Theme = styled.div`
  width: 200px;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: ${(props) =>
    props.activeCardType === "Card Size" ? "center" : "center"};
  padding: 0% 5%;
  border-bottom: 6px inset
    ${(props) => (props.active ? "#075063" : "transparent")};
  /* background: ${(props) => (props.type === "Card" ? "" : "#032128")}; */
  &:disabled {
    pointer-events: none;
  }
  @media (min-width: 768px) {
    min-width: ${(props) =>
      props.type === "Table"
        ? "100px"
        : props.type === "Background"
        ? "350px"
        : "300px"};
  }
`;

export const ThemeOptions = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const ActiveTriangle = styled.div`
  width: 0;
  height: 0;
  border-bottom: 7px solid #eabf69;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
`;
