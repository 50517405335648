import React from 'react'
import { PokerHandName, PokerHandNumber, PokerHandWrapper, Card, CardShowcase, DullCard, HandInfoWrapper } from './HandRankingStyle'
// import { CardImages } from '../../../../assets/cards'
import { cardThemes } from '../../../../assets/cards'
import { useSelector } from "react-redux";


const PokerHand = ({ handStrength, handName, orientation }) => {
    // const card = CardImages
    const cardDeckTheme = useSelector(
        (state) => state.gameTable.themeData.cardDeck
      );

    const returnCardsImage = (handName) => {
        let imageComponent;
        switch (handName) {
            case 'Royal Flush':
                return imageComponent = (
                    <CardShowcase>
                        <Card src={cardThemes[cardDeckTheme].h10}/>
                        <Card src={cardThemes[cardDeckTheme].h11}/>
                        <Card src={cardThemes[cardDeckTheme].h12}/>
                        <Card src={cardThemes[cardDeckTheme].h13}/>
                        <Card src={cardThemes[cardDeckTheme].h1}/>
                    </CardShowcase>
                )
            case 'Straight Flush':
                return imageComponent = (
                    <CardShowcase>
                        <Card src={cardThemes[cardDeckTheme].s5}/>
                        <Card src={cardThemes[cardDeckTheme].s6}/>
                        <Card src={cardThemes[cardDeckTheme].s7}/>
                        <Card src={cardThemes[cardDeckTheme].s8}/>
                        <Card src={cardThemes[cardDeckTheme].s9}/>
                    </CardShowcase>
                )
            case 'Four Of A Kind':
                return imageComponent = (
                    <CardShowcase>
                        <DullCard src={cardThemes[cardDeckTheme].d2}/>
                        <Card src={cardThemes[cardDeckTheme].h11}/>
                        <Card src={cardThemes[cardDeckTheme].c11}/>
                        <Card src={cardThemes[cardDeckTheme].d11}/>
                        <Card src={cardThemes[cardDeckTheme].s11}/>
                    </CardShowcase>
                )
            case 'Full House':
                return imageComponent = (
                    <CardShowcase>
                        <Card src={cardThemes[cardDeckTheme].c5}/>
                        <Card src={cardThemes[cardDeckTheme].s5}/>
                        <Card src={cardThemes[cardDeckTheme].d13}/>
                        <Card src={cardThemes[cardDeckTheme].s13}/>
                        <Card src={cardThemes[cardDeckTheme].h13}/>
                    </CardShowcase>
                )
            case 'Flush':
                return imageComponent = (
                    <CardShowcase>
                        <Card src={cardThemes[cardDeckTheme].c3}/>
                        <Card src={cardThemes[cardDeckTheme].c5}/>
                        <Card src={cardThemes[cardDeckTheme].c7}/>
                        <Card src={cardThemes[cardDeckTheme].c9}/>
                        <Card src={cardThemes[cardDeckTheme].c13}/>
                    </CardShowcase>
                )
            case 'Straight':
                return imageComponent = (
                    <CardShowcase>
                        <Card src={cardThemes[cardDeckTheme].d5}/>
                        <Card src={cardThemes[cardDeckTheme].s6}/>
                        <Card src={cardThemes[cardDeckTheme].h7}/>
                        <Card src={cardThemes[cardDeckTheme].c8}/>
                        <Card src={cardThemes[cardDeckTheme].d9}/>
                    </CardShowcase>
                )
            case 'Three Of A Kind':
                return imageComponent = (
                    <CardShowcase>
                        <DullCard src={cardThemes[cardDeckTheme].s4}/>
                        <DullCard src={cardThemes[cardDeckTheme].h7}/>
                        <Card src={cardThemes[cardDeckTheme].c13}/>
                        <Card src={cardThemes[cardDeckTheme].d13}/>
                        <Card src={cardThemes[cardDeckTheme].s13}/>
                    </CardShowcase>
                )
            case 'Two Pairs':
                return imageComponent = (
                    <CardShowcase>
                        <DullCard src={cardThemes[cardDeckTheme].d5}/>
                        <Card src={cardThemes[cardDeckTheme].c7}/>
                        <Card src={cardThemes[cardDeckTheme].d7}/>
                        <Card src={cardThemes[cardDeckTheme].s13}/>
                        <Card src={cardThemes[cardDeckTheme].h13}/>
                    </CardShowcase>
                )
            case 'One Pair':
                return imageComponent = (
                    <CardShowcase>
                        <DullCard src={cardThemes[cardDeckTheme].s6}/>
                        <DullCard src={cardThemes[cardDeckTheme].h2}/>
                        <DullCard src={cardThemes[cardDeckTheme].c7}/>
                        <Card src={cardThemes[cardDeckTheme].d12}/>
                        <Card src={cardThemes[cardDeckTheme].s12}/>
                    </CardShowcase>
                )
            case 'High Card':
                return imageComponent = (
                    <CardShowcase>
                        <DullCard src={cardThemes[cardDeckTheme].d3}/>
                        <DullCard src={cardThemes[cardDeckTheme].c7}/>
                        <DullCard src={cardThemes[cardDeckTheme].d8}/>
                        <DullCard src={cardThemes[cardDeckTheme].s11}/>
                        <Card src={cardThemes[cardDeckTheme].h1}/>
                    </CardShowcase>
                )
            default:
                return;
        }
    }

    return (
        <PokerHandWrapper>
            <HandInfoWrapper>
                <PokerHandNumber>
                    {handStrength}
                </PokerHandNumber>
                <PokerHandName orientation={orientation}>
                    {handName}
                </PokerHandName>
            </HandInfoWrapper>
            {returnCardsImage(handName)}
        </PokerHandWrapper>
    )
}

export default PokerHand