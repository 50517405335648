import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const InputRange = styled.input`
  border-radius: 8px;
  border: 1px solid #075063;
  background: ${colors.rangeSliderBackground};
  background-blend-mode: normal, screen;
  border-radius: 8px;
  height: 6px;
  width: 100%;
  outline: none;
  transition: background 0.2ms ease-in;
  -webkit-appearance: none;
  position: relative;
  margin: 5px 0px;

  ${(props) =>
    props.usageType === "table-settings"
      ? `
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: 0px;
    height: 20px;
    width: 20px;
    background: linear-gradient(174.64deg, #e7e7e7 4.29%, #49646b 96.52%),
      linear-gradient(0deg, #ffffff, #ffffff);
    border-top: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 2px 5px black;
  }

  ::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background: linear-gradient(174.64deg, #e7e7e7 4.29%, #49646b 96.52%),
      linear-gradient(0deg, #ffffff, #ffffff);
    height: 25px;
    width: 25px;
    border-top: 1px solid white;
  }

  :focus {
    outline: none;
  }
  `
      : `&::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    border-radius: 50%;
    border-radius: 16px;
    background: linear-gradient(84deg, #054454 3.64%, #075063 95.45%);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.68);
  }`}
`;
