import Handler from "../Handler";

class CallActionBroadcastHandler extends Handler {
  //CallB# {position(int) , playerName(String) , potAmount(Double) , atTableAmount(Double)}
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processCallActionBroadcast(receivedData);
  }
}

export default CallActionBroadcastHandler;
