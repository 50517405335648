import Handler from "../Handler";

class AutoRebuyAcknowledmentHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoRebuySuccessAck(receivedData);
  }
}

export default AutoRebuyAcknowledmentHandler;
// AutoRebuyInA#{"status":"SUCCESS","playerName":"angel459889","Amount":900.0} 1695626627.2673745
