import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo12PebbleGrayContoRegularText,
  Typo12WhiteContoMediumText,
  Typo14LeafyGreenContoBoldText,
  Typo14OffWhiteContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo14PureWhiteContoBoldText,
  Typo14SecondaryRedContoMediumText,
  Typo14WhiteContoBoldText,
  Typo14WhiteContoMediumText,
  Typo16OffWhiteContoBoldText,
} from "../../../Common/styleGuide/Typos";

export const TourneyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  width: 100%;
  background: ${colors.tourneyInfoBackground};
`;

export const TourneyHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 12px;
  box-sizing: border-box;
`;

export const TourneyInfoTitleText = styled(Typo16OffWhiteContoBoldText)``;

export const TourneyIdText = styled(Typo12PebbleGrayContoRegularText)``;

export const BackLeftArrowWrapper = styled.img`
  width: 12px;
  height: 16px;
`;

export const TourneyInfoBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--app-height) - 99px);
`;

export const TourneyInfoFooterCTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top: 1px solid ${colors.secondaryBlue};
  height: 59px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  .secondary-cta {
    margin-right: 12px;
    border: ${(props) =>
      props.reminded
        ? `2px solid ${colors.vampireGrey}`
        : `2px solid ${colors.leafyGreen}`};

    img {
      height: 14px;
      width: 14px;
      margin-right: 8px;
    }
  }
  button {
    width: 100%;
    height: 35px;
    max-width: 160px;
  }
`;

export const TabbarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  min-height: 52px;
  /* border-bottom: 1px solid ${colors.primaryGold}; */
  .tab-item-container {
    height: 52px;
    min-height: 52px;
  }
  .tab-item {
    width: 100%;
    height: 52px;
    min-height: 52px;
  }
`;

export const LoadingViewContainer = styled.div`
  width: 95%;
  margin: auto;
  height: 70%;
`;

export const PrimaryCTAButtonText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
`;

export const SecondaryCTAButtonText = styled(Typo14LeafyGreenContoBoldText)`
  line-height: normal;
  color: ${(props) =>
    props.reminded ? colors.vampireGrey : colors.leafyGreen};
`;

export const RegisterationsStartsInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35px;
`;

export const RegistrationStartsInText = styled(Typo14WhiteContoMediumText)`
  line-height: normal;
`;

export const TourneyInfoAnnouncementFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top: 1px solid ${colors.secondaryBlue};
  height: 59px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;

  button {
    width: 100%;
    height: 35px;
    max-width: 50%;
    :nth-child(2) {
      margin-right: 12px;
      border: ${(props) =>
        props.reminded
          ? `2px solid ${colors.vampireGrey}`
          : `2px solid ${colors.leafyGreen}`};
      img {
        height: 14px;
        width: 14px;
        margin-right: 8px;
      }
    }
  }
`;

export const TimeText = styled(Typo14SecondaryRedContoMediumText)`
  line-height: 24px;
`;

export const TicketIcon = styled.img`
  margin-left: 4px;
`;

export const NoTicketButtonWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  span {
    color: ${colors.gray};
  }
`;

export const MaxRegFullText = styled(Typo14PureWhiteContoBoldText)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`;

export const DirectAddCashModalDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const DescriptionText = styled(Typo14OffWhiteContoMediumText)``;

export const MinimumAddCashWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const AddCashAmountText = styled(Typo14PrimaryGoldContoMediumText)`
  line-height: normal;
  margin-left: 6px;
`;

export const StatusTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 58px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StatusTypeIconWrapper = styled.img`
  margin-right: 4px;
`;
