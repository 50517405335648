import Handler from "../Handler";

class TourneyTableSwitchHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyTableSwitch(receivedData);
  }
}

export default TourneyTableSwitchHandler;

// TableSwitch#{"tableId":"TOTPUG010424-010268110963y3qul"}
