import Handler from "../Handler";

class ReserveAckHandler extends Handler {
  execute(message) {
    /** reserved --- {"playerName":"pradeep","position":1,"gender":"M"} */

    let receivedData = JSON.parse(message);
    this.service.processReserveAck(receivedData);
  }
}

export default ReserveAckHandler;
