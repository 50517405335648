import Handler from "../Handler";

class CheckActionBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processCheckActionBroadcast(receivedData);
  }
}

export default CheckActionBroadcastHandler;
