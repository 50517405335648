const deckPaths = ["Default", "Colored", "Gold", "Multicolor", "Decktab"];

const suits = ["club", "spade", "heart", "diamond"];

const cardThemes = {};

deckPaths.forEach((deckPath, deckIndex) => {
  cardThemes[`${deckPath}`] = {};

  suits.forEach((suit) => {
    cardThemes[`${deckPath}`][`${suit[0]}2`] = require(`./${deckPath}/${suit.toLowerCase()}_2.png`);
    cardThemes[`${deckPath}`][`${suit[0]}3`] = require(`./${deckPath}/${suit.toLowerCase()}_3.png`);
    cardThemes[`${deckPath}`][`${suit[0]}4`] = require(`./${deckPath}/${suit.toLowerCase()}_4.png`);
    cardThemes[`${deckPath}`][`${suit[0]}5`] = require(`./${deckPath}/${suit.toLowerCase()}_5.png`);
    cardThemes[`${deckPath}`][`${suit[0]}6`] = require(`./${deckPath}/${suit.toLowerCase()}_6.png`);
    cardThemes[`${deckPath}`][`${suit[0]}7`] = require(`./${deckPath}/${suit.toLowerCase()}_7.png`);
    cardThemes[`${deckPath}`][`${suit[0]}8`] = require(`./${deckPath}/${suit.toLowerCase()}_8.png`);
    cardThemes[`${deckPath}`][`${suit[0]}9`] = require(`./${deckPath}/${suit.toLowerCase()}_9.png`);
    cardThemes[`${deckPath}`][`${suit[0]}10`] = require(`./${deckPath}/${suit.toLowerCase()}_10.png`);
    cardThemes[`${deckPath}`][`${suit[0]}11`] = require(`./${deckPath}/${suit.toLowerCase()}_11.png`);
    cardThemes[`${deckPath}`][`${suit[0]}12`] = require(`./${deckPath}/${suit.toLowerCase()}_12.png`);
    cardThemes[`${deckPath}`][`${suit[0]}13`] = require(`./${deckPath}/${suit.toLowerCase()}_13.png`);
    cardThemes[`${deckPath}`][`${suit[0]}1`] = require(`./${deckPath}/${suit.toLowerCase()}_14.png`);
  });
});

export { cardThemes };
