import { createSlice } from "@reduxjs/toolkit";

const buttonSlice = createSlice({
    name: 'buttons',
    initialState: {
        value: true
    },
    reducers: {
        activateButtons: (state) => {
            state.value = true
        },

        deactivateButtons: (state) => {
            state.value = false
        }
    }
})

export const { activateButtons, deactivateButtons } = buttonSlice.actions;
export default buttonSlice.reducer