export const playAnimation = ({ duration, timing, draw }) => {
  try {
    let start = performance.now();
    let frame;
    frame = requestAnimationFrame(function animation(time) {
      // timeFraction goes from 0 to 1
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;
      // calculate the current animation state
      let progress = timing(timeFraction);

      draw(progress); // draw it

      if (timeFraction < 1) {
        requestAnimationFrame(animation);
      }
    });
    return frame;
  } catch (e) {
    console.log("Error in animation ", e);
  }
};

export const cancelAnimation = (frame) => {
  window.cancelAnimationFrame(frame);
};

export const linear = (timeFraction) => {
  return timeFraction;
};