import Handler from "../Handler";

class TourneyStatusChangeHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyStatusChange(receivedData);
  }
}
export default TourneyStatusChangeHandler;

// tsc#{"tourneyId": "abcd", "status": "REGISTRATION_STARTED"}