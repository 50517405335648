import Handler from "../Handler";

class RaiseActionHandler extends Handler {
  execute(message) {
    //RaiseA#{"status":"SUCCESS","tableId":"THR170823-44440662l895","gameId":"170823-44440662trw6","amount":125.0,"allIn":false}
    let receivedData = JSON.parse(message);
    this.service.processRaiseActionAk(receivedData);
  }
}

export default RaiseActionHandler;
