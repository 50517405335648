import { cloneDeep } from "lodash";
import axios from "axios";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

import LobbyConnection from "../../io/LobbyConnection";
import Configuration from "../../io/Configuration";
import BaseClient from "../../io/BaseClient";
import LobbyListener from "../../io/LobbyListener";

import LobbyService from "../services/LobbyService";
import LobbyDataHandler from "../../handlers/lobby/LobbyDataHandler";
import LobbyConnectionAckHandler from "../../handlers/lobby/LobbyConnectionAckHandler";
import HeartBeatLobbyHandler from "../../handlers/lobby/HeartBeatLobbyHandler";
import LackHandler from "../../handlers/lobby/LackHandler";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../Common/utils/StorageUtils";
import {
  IS_HAPTICS_EBABLED,
  IS_HAPTICS_SUPPORT,
  IS_SOUND_ENABLED,
} from "../../data/Constants";
import LobbyReconnectionAckHandler from "../../handlers/lobby/LobbyReconnectionAckHandler";
import * as Constants from "../../data/Constants";
import GameDefinitionUpdateHandler from "../../handlers/lobby/GameDefinitionUpdateHandler";
import UpdatePlayerCountHandler from "../../handlers/lobby/UpdatePlayerCountHandler";
import TourneyLobbydataHandler from "../../handlers/lobby/TourneyLobbydataHandler";
import TourneyInfoAckHandler from "../../handlers/lobby/TourneyInfoAckHandler";
import TourneyRegisteredCountHandler from "../../handlers/lobby/TourneyRegisteredCountHandler";
import TourneyStatusChangeHandler from "../../handlers/lobby/TourneyStatusChangeHandler";
import TourneyPlayerStatusHandler from "../../handlers/lobby/TourneyPlayerStatusHandler";
import TourneyStartHandler from "../../handlers/lobby/TourneyStartHandler";
import DynamicTourneyLobbyDataHandler from "../../handlers/lobby/DynamicTourneyLobbyDataHandler";
import TourneyPrizeHandler from "../../handlers/lobby/TourneyPrizeHandler";
import TourneyCompleteHandler from "../../handlers/lobby/TourneyCompleteHandler";
import TourneyRanksDataHandler from "../../handlers/lobby/TourneyRanksDataHandler";
import TourneyCancleHandler from "../../handlers/lobby/TourneyCancleHandler";
import TourneyTableDataHandler from "../../handlers/lobby/TourneyTableDataHandler";
import GameActiveStatusHandler from "../../handlers/lobby/GameActiveStatusHandler";
import PlayerConfigHandler from "../../handlers/lobby/PlayerConfigHandler";
import TourneySlabChangeHandler from "../../handlers/lobby/TourneySlabChangeHandler";
import ChipsReloadAckHandler from "../../handlers/lobby/ChipsReloadAckHandler";
import TourneyPrizePoolUpdateHandler from "../../handlers/lobby/TourneyPrizePoolUpdateHandler";
import GetFunChipsBalanceAckHandler from "../../handlers/lobby/GetFunChipsBalanceAckHandler";
import TourneyLateRegEndedHandler from "../../handlers/lobby/TourneyLateRegEndedHandler";
import TourneyTicketsHandler from "../../handlers/lobby/TourneyTicketsHandler";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import ActiveGamesHandler from "../../handlers/lobby/ActiveGamesHandler";
import TourneyPlayerAutoRegisteredHandler from "../../handlers/lobby/TourneyPlayerAutoRegisteredHandler";
import TourneyNewChildTourneyHandler from "../../handlers/lobby/TourneyNewChildTourneyHandler";
import TourneyRemoveHandler from "../../handlers/lobby/TourneyRemoveHandler";
import TourneyDynamicCountsUpdateHandler from "../../handlers/lobby/TourneyDynamicCountsUpdateHandler";
import { Poker_Redirection_Fail } from "../../data/ClevertapConstants";
import {
  checkIfFoldDeviceInMobileMode,
  getISTDateTimeFormat,
} from "../../../Common/utils/GameUtils";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import UpdateDepositorTourneyHandler from "../../handlers/lobby/UpdateDepositorTourneyHandler";
import TourneyInTheMoneyHandler from "../../handlers/lobby/TourneyInTheMoneyHandler";
import TourneyInBreakHandler from "../../handlers/lobby/TourneyInBreakHandler";
import TourneyDepositorTicketReceiveHandler from "../../handlers/lobby/TourneyDepositorTicketReceiveHandler";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import SpecialBetHandler from "../../handlers/lobby/SpecialBetHandler";
import SpecialBetGameActiveStatusHandler from "../../handlers/lobby/SpecialBetGameActiveStatusHandler";

export const fetchRequestTourneyRegistration = createAsyncThunk(
  "/lobbySlice/tourneyRegistration",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_REGISTER_INITIATE",
        requestPayload: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails); //NOTE: need to change this.
    }
  }
);

export const fetchRequestTourneyDeRegistration = createAsyncThunk(
  "/lobbySlice/tourneyDeRegistration",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_DE_REGISTER_INITIATE",
        requestPayload: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails); //NOTE: need to change this.
    }
  }
);

export const fetchTourneyReEntry = createAsyncThunk(
  "/lobbySlice/tourneyReEntry",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_RE_ENTRY_INTIATE",
        requestDetails: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails);
    }
  }
);

export const getTourneyRegisterFailureMessageByCode = (messageCode) => {
  const {
    REGISTER_SUCCESS,
    REGISTER_ACK_NULL,
    A23_TOKEN_DECRYPTION_FAILED_IN_REGISTER,
    TOURNEY_CHECK_FAILED,
    ERROR_IN_REGISTER_CONTROLLER,
    LOW_BALANCE,
    DE_REGISTER_SUCCESS,
    DE_REGISTER_ACK_NULL,
    PLAYER_NOT_FOUND_IN_TOURNEY,
    A23_TOKEN_DECRYPTION_FAILED_IN_DE_REGISTER,
    ERROR_IN_DE_REGISTER_CONTROLLER,
    TOURNEY_STARTED,
    TOAST_SUCCESS,
    TOAST_INFO,
    TOAST_ERROR,
    TOAST_WARNING,
    TOURNEY_REGISTRATIONS_FULL,
    TOURNEY_RE_ENTRY_SUCCESS,
    TOURNEY_RE_ENTRY_IN_THE_MONEY,
    TOURNEY_IN_THE_BUBBLE,
    TOURNEY_RE_ENTRY_COUNT_EXCEEDED,
    TOURNEY_RE_ENTRY_LEVEL_EXCEEDED,
    TOURNEY_RE_ENTRY_LOW_BALANCE,
    TOURNEY_RE_ENTRY_PLAYER_NOT_REGISTERED,
    TOURNEY_RE_ENTRY_PLAYER_NOT_BUSTED_OUT,
    TOURNEY_RE_ENTRY_TOURNEY_NOT_FOUND,
    TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY,
    TOURNEY_DIRECT_ENTRIES_FULL,
    TOURNEY_TICKET_ENTRIES_FULL,
  } = Constants;
  switch (messageCode) {
    case REGISTER_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney Registration Successful",
      };
    case TOURNEY_RE_ENTRY_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney Re-entry Successful",
      };
    case REGISTER_ACK_NULL:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };
    case TOURNEY_REGISTRATIONS_FULL:
      return {
        type: TOAST_ERROR,
        message: "Tourney Registrations are Full",
      };

    case TOURNEY_CHECK_FAILED:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case A23_TOKEN_DECRYPTION_FAILED_IN_REGISTER:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case ERROR_IN_REGISTER_CONTROLLER:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case LOW_BALANCE:
      return {
        type: TOAST_ERROR,
        message: "Failed to register due to insufficient funds",
      };
    case DE_REGISTER_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney has been deregistered",
      };
    case DE_REGISTER_ACK_NULL:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };

    case PLAYER_NOT_FOUND_IN_TOURNEY:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };

    case A23_TOKEN_DECRYPTION_FAILED_IN_DE_REGISTER:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };
    case ERROR_IN_DE_REGISTER_CONTROLLER:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };
    case TOURNEY_STARTED:
      return {
        type: TOAST_ERROR,
        message: "Tourney started, Failed to deregister",
      };
    case TOURNEY_RE_ENTRY_IN_THE_MONEY:
      return {
        type: TOAST_ERROR,
        message: "Tourney is in the bubble stage. Re-entry has been closed.",
      };
    case TOURNEY_IN_THE_BUBBLE:
      return {
        type: TOAST_ERROR,
        message:
          "Uh oh! We can't register you for this tourney right now. Tourney is currently in the bubble stage.",
      };
    case TOURNEY_DIRECT_ENTRIES_FULL:
      return {
        type: TOAST_ERROR,
        message: "Direct entries are full. Register through add cash",
      };
    case TOURNEY_TICKET_ENTRIES_FULL:
      return {
        type: TOAST_ERROR,
        message: "Unable to register. Tourney is full",
      };
    case TOURNEY_RE_ENTRY_COUNT_EXCEEDED:
    case TOURNEY_RE_ENTRY_LEVEL_EXCEEDED:
    case TOURNEY_RE_ENTRY_LOW_BALANCE:
    case TOURNEY_RE_ENTRY_PLAYER_NOT_REGISTERED:
    case TOURNEY_RE_ENTRY_PLAYER_NOT_BUSTED_OUT:
    case TOURNEY_RE_ENTRY_TOURNEY_NOT_FOUND:
      return {
        type: TOAST_ERROR,
        message: "Unable to process your Re-entry request",
      };
    // case TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY:
    //   return {
    //     type: TOAST_ERROR,
    //     message:
    //       "We are unable to register you to the tournament due to our fair play policy. Apologies for the inconvenience caused.",
    //   };
  }
};

const initialState = {
  player: {
    userName: "",
    subscriptionType: "",
    state: "",
    level: "",
    isAllowLocation: "",
    avatar: "",
    IP: "",
    deviceId: "",
    latitude: "",
    longitude: "",
    userId: "",
    deepLink: {
      isAllowLocation: "true",
    },
    playerStatus: null,
  },
  balance: {
    totalBalance: "0.0",
    bonus: "0.0",
    depositBalance: "0.0",
    redeemableBalance: "0.0",
    playChips: 0,
  },
  currentTab: null,
  a23Token: "",
  accessToken: "",
  channel: "",
  isHapticsSupport: false,
  isWebView: true,
  resourceLoadFailure: false,
  showLobbyLoading: true,
  showTourneyLobbyLoading: true,
  masterGameDefinitions: [],
  masterGameDefinitionsCount: 0,
  activeGamesExist: true,
  realStakeGameVariants: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
  },
  playStateGameVariants: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
  },
  hideRakeBack : false,
  showGameTable: false,
  showTourneyInfoPage: false,
  showTourneyInfoLoading: false,
  lackGames: {
    playerId: "",
    activeGames: [],
  },
  lackTourneys: [],
  isLackGamesShown: false,
  isLackGamesReceived: false,
  onlinePlayerCount: 0,
  currentLobbyTime: undefined,
  tourneys: {
    masterTourneysData: [],
    upcomingTourneys: [],
    completedTourneys: [],
    myTourneys: [],
  },
  dynamicTourneysStartData: [],
  tourneyTickets: [],
  tourneyTicketsClaimInProgressDetails: {},
  tourneyRegisterAPIURL: process.env.REACT_APP_TOURNEY_REGISTER_API_URL,
  tourneyDeRegisterAPIURL: process.env.REACT_APP_TOURNEY_DE_REGISTER_API_URL,
  tourneyReEntryAPIURL: process.env.REACT_APP_TOURNEY_RE_ENTRY_API_URL,
  tourneyTabs: [],
  selectedTourneyInfoDetails: null,
  showTourneyRegisterConfirmationPopup: false,
  showTourneyAddCashTicketRegistrationPopup: false,
  showTourneyReEntryConfirmationPopup: false,
  showTourneyRegisterOrDeregisterRequestLoading: false,
  showTourneyReEntryRequestLoading: false,
  showTourneyInsufficientFundsModal: false,
  showTourneyFairPlayPolicyRestrictionPopup: false,
  showLateRegisterSuccessPopup: false,
  toastMessages: [],
  receivedTokenResponse: false,
  receivedBalanceResponse: false,
  receivedLobbyData: false,
  receivedTourneyData: false,
  applicationVisibility: true,
  isLackTourneyGamesDisplayed: false,
  isCashGamesToggled: false,
  //leaderboard
  isPlayerJoiningFromLB: false,
  leaderBoardGameDefinition: undefined,
  applicableLeaderboards: {
    real: [],
    play: [],
    tourney: [],
    private: [],
    mega: [],
  },
  isNewLeaderboard: false,
  isLeaderboardWinner: false,
  isLeaderboardEnabled: false,
  //rakeback
  rakebackDefinitions: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
    //handle texas six plus when it is implemented
  },
  rakeBackCurrentLevelName: undefined,
  rakeBackCurrentLevel: undefined,
  rakeBackPercent: undefined,
  rakebackChangeType: undefined,
  showRakebackToast: undefined,
  deeplinkData: undefined,
  notificationsCount: 0,
  homeData: {
    rakeback: null,
    leaderboard: null,
    missions: null,
    gridTilesData: [],
    horizontalBanners: [],
    CTPromotionalBanners: [],
    bottomVerticalBanners: [],
    dataUpdated: false,
    recommendedGames: [],
    topLoadedBanners: [],
    bottomLoadedBanners: [],
    // loadingStates: {
    //   topBanners: true,
    //   promotionalBanners: true,
    //   gridTiles: true,
    //   recommendedGames: true,
    //   progressBars: true
    // }
  },
  showAllTicketsSection: false,
};

// function updateGameDefiniton(gameDefinitionsList, gameDefinition) {
//   let updatedGameDefinitions;

//   if (gameDefinition.gameUpdateType === Constants.GAME_DEFINITION_NEW) {
//     updatedGameDefinitions = [...gameDefinitionsList, gameDefinition];
//     console.log("Updated game definitions after additions:", updatedGameDefinitions);

//   } else {
//     let oldGameDefinitons = [...gameDefinitionsList];
//     updatedGameDefinitions = oldGameDefinitons.map((eachDefinition) => {
//       if (eachDefinition.gameDefId === gameDefinition.gameDefId) {
//         return gameDefinition;
//       }
//       return eachDefinition;
//     });
//   }
//   return [...updatedGameDefinitions];
// }

function returnGameDefinitionMap(gameDefinitionList) {
  let object = {};
  for (let i = 0; i < gameDefinitionList.length; i++) {
    let gameDefinition = gameDefinitionList[i];
    //  in order to sink master definitions with funGamesData
    if (gameDefinition.flavourType === Constants.LB_PLAY) {
      gameDefinition.gameType = gameDefinition.gameVariant;
    }
    // console.log('Game Definition is ', gameDefinition);
    let gameDefId = gameDefinition.gameDefId;
    // map.set(gameDefinition.gameDefId, gameDefinition);
    object[gameDefId] = gameDefinition;
  }
  // console.log("Master Game Definitions are ", object);
  return object;
}

function returnArrayOfGameDefinitionIDs(gameDefinitionList) {
  let array = [];
  for (let i = 0; i < gameDefinitionList.length; i++) {
    let gameDefinition = gameDefinitionList[i];
    if (gameDefinition.status === Constants.GAME_STATUS_ACTIVE) {
      array.push(gameDefinition.gameDefId);
    }
  }
  return array;
}

function updateGameDefiniton(gameDefinitionsList, gameDefinition) {
  let newGamesList = gameDefinitionsList.filter(
    (gameDef) => gameDef.gameDefId !== gameDefinition.gameDefId
  );
  newGamesList.push(gameDefinition);
  newGamesList.sort((a, b) => a.smallBlind - b.smallBlind);
  return newGamesList;
}

function getGameDefinition(masterGameDefinitionsList, gameDefId) {
  let gameDefinition = masterGameDefinitionsList.find(
    (eachGameDefinition) => eachGameDefinition.gameDefId === gameDefId
  );
  return gameDefinition;
}

function createLobbyConnection(state, dispatchFunction) {
  let clientType = "mipman"; //TODO: need to validate this.
  let returnArray = [];
  let lobbyService = new LobbyService(dispatchFunction);

  let serverAddress = process.env.REACT_APP_LOBBY_SERVER;
  let serverPort = process.env.REACT_APP_LOBBY_PORT;
  let redirectionURL = "";

  returnArray.push(serverAddress);
  returnArray.push(redirectionURL);

  let handlersList = {};
  handlersList["ld"] = new LobbyDataHandler(lobbyService);
  handlersList["cona"] = new LobbyConnectionAckHandler(lobbyService);
  if (!Constants.FUN_CHANNELS.includes(state.channel)) {
    handlersList["lack"] = new LackHandler(lobbyService);
    handlersList["activegames"] = new ActiveGamesHandler(lobbyService);
    handlersList["tourneyinfoa"] = new TourneyInfoAckHandler(lobbyService);
    handlersList["trc"] = new TourneyRegisteredCountHandler(lobbyService);
    handlersList["tsc"] = new TourneyStatusChangeHandler(lobbyService);
    handlersList["tps"] = new TourneyPlayerStatusHandler(lobbyService);
    handlersList["tld"] = new TourneyLobbydataHandler(lobbyService);
    handlersList["tourneystart"] = new TourneyStartHandler(lobbyService);
    handlersList["dtld"] = new DynamicTourneyLobbyDataHandler(lobbyService);
    handlersList["tourneyprize"] = new TourneyPrizeHandler(lobbyService);
    handlersList["tourneycomplete"] = new TourneyCompleteHandler(lobbyService);
    handlersList["ranksdata"] = new TourneyRanksDataHandler(lobbyService);
    handlersList["tourneycancel"] = new TourneyCancleHandler(lobbyService);
    handlersList["tourneylateregended"] = new TourneyLateRegEndedHandler(
      lobbyService
    );
    handlersList["tickets"] = new TourneyTicketsHandler(lobbyService);
    handlersList["newchildtourney"] = new TourneyNewChildTourneyHandler(
      lobbyService
    );
    handlersList["tourneyremove"] = new TourneyRemoveHandler(lobbyService);
    handlersList["tcd"] = new TourneyDynamicCountsUpdateHandler(lobbyService);
    handlersList["playerautoregistered"] =
      new TourneyPlayerAutoRegisteredHandler(lobbyService);
    handlersList["slabchange"] = new TourneySlabChangeHandler(lobbyService);
    handlersList["prizepoolupdate"] = new TourneyPrizePoolUpdateHandler(
      lobbyService
    );
    handlersList["tabledata"] = new TourneyTableDataHandler(lobbyService);
    handlersList["updatedepositortourney"] = new UpdateDepositorTourneyHandler(
      lobbyService
    );
    handlersList["depositorpopup"] = new TourneyDepositorTicketReceiveHandler(
      lobbyService
    );
  }

  handlersList["gu"] = new GameDefinitionUpdateHandler(lobbyService);
  handlersList["upc"] = new UpdatePlayerCountHandler(lobbyService);
  handlersList["g"] = new HeartBeatLobbyHandler(lobbyService);
  // handlersList["upc"] = new UpdatePlayerCountHandler(lobbyService)
  // handlersList["lack"] = new ReLoginHandler(lobbyService)
  handlersList["rcona"] = new LobbyReconnectionAckHandler(lobbyService);
  // handlersList["activegames"] = new ActiveGamesHandler(lobbyService);
  // handlersList["conf"] = new ConnectionFailHandler(lobbyService);
  // handlersList["opengame"] = new openGameDefinitionsHandler(lobbyService);
  // handlersList["closegame"] = new closeGameDefinitionsHandler(lobbyService);
  handlersList["gameactivestatus"] = new GameActiveStatusHandler(lobbyService);
  handlersList["playerconfig"] = new PlayerConfigHandler(lobbyService);
  handlersList["chipsreloada"] = new ChipsReloadAckHandler(lobbyService);
  handlersList["getplayerchipsa"] = new GetFunChipsBalanceAckHandler(
    lobbyService
  );
  handlersList["inthemoney"] = new TourneyInTheMoneyHandler(lobbyService);
  handlersList["breakstart"] = new TourneyInBreakHandler(lobbyService);

  handlersList['specialbet'] = new SpecialBetHandler(lobbyService);
  handlersList['sbgu'] = new SpecialBetGameActiveStatusHandler(lobbyService);

  let configuration = new Configuration();
  configuration.setHandlersList(handlersList);

  configuration.setConnectionType(process.env.REACT_APP_CONNECTION_TYPE);
  configuration.setServerAddress(serverAddress);
  configuration.setServerPort(serverPort);

  // configuration.setHeartBeatMessage("g#");
  configuration.setHeartBeatMessage("g");
  configuration.setHeartBeatInterval(3);
  configuration.setConnectionTimeout(10);
  configuration.setReadTimeout(15);
  configuration.setReconnectionTimeout(10);
  configuration.setProtocolDelimiter("#");
  configuration.setReconnectionMode(false);

  let clientListener = new LobbyListener(state, dispatchFunction, clientType);
  let baseClient = new BaseClient(
    configuration,
    clientListener,
    "lobbyListener"
  );
  clientListener.setCommunicationChannel(baseClient);
  baseClient.start();

  lobbyService.messageHandler = clientListener;

  returnArray.push(baseClient);
  // returnArray.push(googleClientId);

  //CleverTap changes
  return returnArray;
}

const LobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    updateApplicationVisibility: (state, action) => {
      let { status } = action.payload;
      state.applicationVisibility = status;
    },
    initializeLobbyConnection: (state, action) => {
      let payload = action.payload;

      let baseClient = createLobbyConnection(
        current(state),
        payload.dispatchFunction
      );
      state.serverConnectIP = baseClient[0];
      state.redirectionURL = baseClient[1];
      // newState.googleClientId = baseClient[3]; //not in use anymore

      let lobbyConnection = LobbyConnection.getInstance();
      lobbyConnection.addLobbyConnection(baseClient[2]);
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    setPlayerDetails: (state, action) => {
      const {
        body,
        screenName,
        userSubcriptionType,
        channel,
        userID,
        a23Token,
        userLevel,
        deepLink,
        avatar,
        isAllowLocation,
        isHapticsSupport,
        deviceType,
        deviceModel,
        deviceOS,
        deviceId,
        ip,
        lat,
        lng,
        playerStatus,
        referCode,
        state: userState,
      } = action.payload;
      /** description of value received in key "userSubcriptionType" --
       * 1. "pseudo" for newly signed up user who received bonus but not yet added cash,
       * 2. "premium" for user who did add cash atleast once,
       * 3. "regular" for player who neither received bonus nor did an add cash */
      state.player = {
        userName: screenName,
        subscriptionType: userSubcriptionType,
        state: userState,
        level: userLevel,
        isAllowLocation: isAllowLocation === "true",
        avatar: avatar,
        IP: ip,
        deviceId: deviceId,
        deviceType: deviceType,
        deviceModel: deviceModel,
        deviceOS: deviceOS,
        latitude: lat,
        longitude: lng,
        userId: userID,
        deepLink: deepLink,
        // playerStatus: playerStatus ? playerStatus : null,
        playerStatus:
          playerStatus && playerStatus !== "null"
            ? playerStatus
            : Constants.PLAYER_STATUS_NOT_AVAILABLE,
        referCode: referCode ? referCode : null,
      };
      state.isFoldDeviceInMobileMode = checkIfFoldDeviceInMobileMode({
        deviceType: deviceType,
        deviceModel: deviceModel,
      });
      state.isHapticsSupport = isHapticsSupport;
      state.channel = channel;
      state.a23Token = a23Token;
      state.accessToken = body;
      state.player.channel = state.channel;
      setToLocalStorage(IS_HAPTICS_SUPPORT, isHapticsSupport);
      if (getFromLocalStorage(IS_SOUND_ENABLED) === null) {
        setToLocalStorage(IS_SOUND_ENABLED, true);
      }
      if (getFromLocalStorage(IS_HAPTICS_EBABLED) === null) {
        setToLocalStorage(IS_HAPTICS_EBABLED, true);
      }
      state.receivedTokenResponse = true;

      //commented below line as parsing of only an empty string is throwing an error 'unexpected EOF' - iOS data problem from native client
      // let isAllowedState = JSON.parse(isAllowLocation);
      let isAllowedState = isAllowLocation === "true"; //to convert string to a boolean - if value is empty/undefined it returns false
      state.isCashGamesToggled = isAllowedState;
      state.isCashGamesToggled = true; //this is only temporary value
      // return newState;
    },
    setChannel: (state, action) => {
      state.channel = action.payload.channel;
    },
    setIsWebView: (state, action) => {
      state.isWebView = action.payload.isWebView;
    },

    updatePlayerBalance: (state, action) => {
      state.balance.bonus = action.payload.extraCash;
      state.balance.totalBalance = action.payload.body;
      state.balance.depositBalance = action.payload.depositBalance;
      state.balance.redeemableBalance = action.payload.redeemableBalance;
      // state.player.playerStatus = action.payload?.playerStatus
      //   ? action.payload.playerStatus
      //   : null;
      state.player.level = action.payload.userLevel;
      //stored by the playerStatus value by converting to lower case as we are receiving in camel case
      state.player.playerStatus =
        action.payload.playerStatus && action.payload.playerStatus !== "null"
          ? action.payload.playerStatus.toLowerCase()
          : Constants.PLAYER_STATUS_NOT_AVAILABLE;
      state.receivedBalanceResponse = true;
      if (
        state.receivedBalanceResponse &&
        state.receivedTokenResponse &&
        state.receivedLobbyData
      ) {
        state.showLobbyLoading = false;
        state.showTourneyLobbyLoading = false;
      }
    },

    updatePlayerPlayChipsBalance: (state, action) => {
      const { playChips } = action.payload;
      state.balance.playChips = playChips;
    },

    handlePlayChipsBalanceReload: (state, action) => {
      const { status, reloadedPlayChips } = action.payload;
      if (status === "success") {
        state.balance.playChips = reloadedPlayChips;
        state.toastMessages.push({
          type: Constants.TOAST_SUCCESS,
          message: "Chips successfully reloaded.",
        });
      } else if (status === "failure") {
        state.toastMessages.push({
          type: Constants.TOAST_ERROR,
          message: "Failed to reload, Please try again.",
        });
      }
    },
    processFunChipsBalance: (state, action) => {
      const { playChips } = action.payload;
      state.balance.playChips = playChips;
    },

    handleDeeplink: (state, action) => {
      let deeplinkData = action.payload.deepLink;
      state.deeplinkData = deeplinkData;
    },

    clearDeeplink: (state, action) => {
      state.deeplinkData = undefined;
    },

    joinGameFromLeaderBoard: (state, action) => {
      let lbData = action.payload.deepLinkData;
      state.isPlayerJoiningFromLB = true;
      let lbDef = lbData.lbGameDefId.split("_");
      let lbType = Number(lbDef[0]);
      let isTourney = lbType === 2 || lbType === 11;
      let lbGameDefId = Number(lbDef[2]);
      if (isTourney) {
        if (lbType === 2) {
          let tourneyDefinitions = state.tourneys.masterTourneysData;
          let leaderBoardGameDefinition = tourneyDefinitions.filter(
            (tourney) => tourney.tourneyId === lbData.tourneyId
          );
          if (leaderBoardGameDefinition.length === 1)
            state.leaderBoardGameDefinition = leaderBoardGameDefinition[0];
          else {
            CleverTapUtil.getInstance().logEvent(Poker_Redirection_Fail, {
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              KVPairs: action.payload,
            });
            sendFaroLog({
              type: Poker_Redirection_Fail,
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              message: `Tourney not found in master list ${lbData.tourneyId}`,
            });
          }
        }
      } else {
        // let gameType;
        // if (lbGameFormat === 1) {
        //   gameType = "texasHoldem";
        // } else if (lbGameFormat === 2) {
        //   gameType = "omaha";
        // } else if (lbGameFormat === 17) {
        //   gameType = "omaha5";
        // }
        //handle data for lbType = 7
        // let gameDefinitions =
        //   lbType === 1
        //     ? state.realStakeGameVariants
        //     : lbType === 4
        //     ? state.playStateGameVariants
        //     : lbType === 7
        //     ? []
        //     : [];
        // let leaderBoardGameDefinition = gameDefinitions[gameType].filter(
        //   (gameDefinition) => gameDefinition.gameDefId === lbGameDefId
        // );
        if (lbType === 1 || lbType === 4) {
          let leaderboardGameDefinition =
            state.masterGameDefinitions?.[lbGameDefId];
          if (leaderboardGameDefinition)
            state.leaderBoardGameDefinition = leaderboardGameDefinition;
          else {
            console.error(
              "no game with lb def id",
              lbData.lbGameDefId,
              "gameId",
              lbData.lbGameDefId.split("_")[2]
            );
            CleverTapUtil.getInstance().logEvent(Poker_Redirection_Fail, {
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              KVPairs: action.payload,
            });
            sendFaroLog({
              type: Poker_Redirection_Fail,
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              message: `leaderboardGameDefinition is undefined meaning gameid not in master game definitions ${lbGameDefId}`,
            });
            // state.isPlayerJoiningFromLB = false;
          }
        }
      }
      // console.log("lb game definition", state.leaderBoardGameDefinition);
    },

    setIsPlayerJoiningFromLB: (state, action) => {
      state.isPlayerJoiningFromLB = action.payload.flag;
      state.leaderBoardGameDefinition = undefined;
    },

    leaderBoardGamesList: (state, action) => {
      let lbData = action.payload.serverData;
      console.log("lb list", lbData);
      let applicableLeaderboards = {
        real: [],
        play: [],
        tourney: [],
        private: [],
        mega: [],
      };

      for (const element of lbData.pokerleaderboards) {
        let currentLeaderboard = element;
        let currentLeaderboardFlavour =
          currentLeaderboard.gameDefIds.length > 0
            ? Number(currentLeaderboard.gameDefIds[0].split("_")[0])
            : undefined;
        if (currentLeaderboardFlavour) {
          if (currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["real"]) {
            applicableLeaderboards.real.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["play"]
          ) {
            applicableLeaderboards.play.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["tourney"]
          ) {
            applicableLeaderboards.tourney.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["private"]
          ) {
            applicableLeaderboards.private.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["mega"]
          ) {
            applicableLeaderboards.mega.push(currentLeaderboard);
          }
        }
      }
      state.isNewLeaderboard = lbData.isNewLb === "true";
      state.isLeaderboardEnabled = lbData.isEnabled === "true";
      state.applicableLeaderboards = applicableLeaderboards;
      // console.log("POKER LBS", state);
    },

    setRakebackGameDefinitions: (state, action) => {
      let rakeBackData = action.payload.serverData;
      let gameDefIds = rakeBackData.gameDefIds;
      let isRBblocked = rakeBackData?.isRBBlocked === true;
      let rakebackDefinitions = {
        texasHoldem: [],
        omaha: [],
        omaha5: [],
        //handle texas six plus when it is implemented
      };
      for (const current of gameDefIds) {
        let gamedefArray = current.split("_");
        //Fix for IM-2580 below notations we need to refer to 1st index instead of 0 index
        if (Number(gamedefArray[1]) === 1) {
          rakebackDefinitions.texasHoldem.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[1]) === 2) {
          rakebackDefinitions.omaha.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[1]) === 17) {
          rakebackDefinitions.omaha5.push(Number(gamedefArray[2]));
        }
        // else if(Number(gamedefArray[0] === 18)){
        //   rakebackDefinitions.omaha5.push(gamedefArray[2]);
        // }
        else {
          console.log("INCORRECT VARIANT - NOT HANDLED ", gamedefArray[0]);
        }
      }
      state.rakebackDefinitions = rakebackDefinitions;
      state.rakeBackPercent = Number(rakeBackData.rakeBackPercent);
      state.rakeBackCurrentLevel = Number(rakeBackData.currentLevel);
      state.rakeBackCurrentLevelName = rakeBackData.currentLevelName;
      state.hideRakeBack = isRBblocked;
      console.log("POKER rakeback updated bets", state.rakebackDefinitions);
    },

    openRakebackPage : (state, action) => {
      if(!state.hideRakeBack){
        messageToNativeClient({ type: "openRakeBack" });
      }
      else{
        const toastMessage = {
          type: Constants.TOAST_INFO,
          message: Constants.REDIRECTION_FAIL,
        };
        state.toastMessages.filter(
          (toast) => toast.message === toastMessage.message
        ).length === 0 && state.toastMessages.push(toastMessage);
      }
    },

    removeRakebackToast: (state, action) => {
      state.showRakebackToast = false;
    },

    updatePlayerRakebackLevel: (state, action) => {
      let rakeBackData = action.payload.serverData;
      let gameDefIds = rakeBackData.gameDefIds;
      let rakebackDefinitions = {
        texasHoldem: [],
        omaha: [],
        omaha5: [],
        //handle texas six plus when it is implemented
      };
      for (const current of gameDefIds) {
        let gamedefArray = current.split("_");
        if (Number(gamedefArray[0]) === 1) {
          rakebackDefinitions.texasHoldem.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[0]) === 2) {
          rakebackDefinitions.omaha.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[0]) === 17) {
          rakebackDefinitions.omaha5.push(Number(gamedefArray[2]));
        }
        // else if(Number(gamedefArray[0] === 18)){
        //   rakebackDefinitions.omaha5.push(gamedefArray[2]);
        // }
        else {
          console.log("INCORRECT VARIANT - NOT HANDLED ", gamedefArray[0]);
        }
      }
      state.rakebackDefinitions = rakebackDefinitions;
      state.rakeBackPercent = Number(rakeBackData.rakeBackPercent);
      state.rakeBackCurrentLevel = Number(rakeBackData.currentLevel);
      state.rakeBackCurrentLevelName = rakeBackData.currentLevelName;
      state.rakebackChangeType = rakeBackData.type;
      state.showRakebackToast = rakeBackData.type === "claim";
      // console.log(
      //   "POKER rakeback updated bets",
      //   state.rakebackDefinitions
      // );
    },

    updateGameDefinitionsMasterdata: (state, action) => {
      // Master Game Definition Logic
      // state.masterGameDefinitions = action.payload;
      const { key, data } = action.payload;

      let gameDefMap = returnGameDefinitionMap(data);

      if (key === Constants.LOBBY_DATA) {
        state.masterGameDefinitions = gameDefMap;
      } else if (key === Constants.SPECIAL_BETS_DATA) {
        // existing game definitions + special game definitions
        let newData = {
          ...current(state.masterGameDefinitions),
          ...gameDefMap,
        };
        state.masterGameDefinitions = newData;
        state.specialbets = returnArrayOfGameDefinitionIDs(data);
      }
      // state.masterGameDefinitions = returnGameDefinitionMap(action.payload);
      //calculating the length again just to avoid any rare case of duplicate game definitions (need to fine tune this) @yashwanth
      state.masterGameDefinitionsCount = Object.keys(
        state.masterGameDefinitions
      ).length;

      // let data = action.payload;
      state.receivedLobbyData = true;

      // Real Games Logic
      let realStakeGames = Object.values(state.masterGameDefinitions)
        .filter(
          (eachGameDefinition) =>
            eachGameDefinition.flavourType.toLowerCase() === Constants.LB_REAL
        )
        .sort((a, b) => a.smallBlind - b.smallBlind);
      let texasRealHoldemGames = realStakeGames.filter(
        (eachGameDefinition) =>
          eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      );
      let omahaRealGames = realStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      );
      let omaha5RealGames = realStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      );
      let realGamesData = {
        texasHoldem: texasRealHoldemGames,
        omaha: omahaRealGames,
        omaha5: omaha5RealGames,
      };

      state.realStakeGameVariants = realGamesData;
      state.realGames = returnArrayOfGameDefinitionIDs(realStakeGames);
      state.realTexasHoldem =
        returnArrayOfGameDefinitionIDs(texasRealHoldemGames);
      state.realOmaha = returnArrayOfGameDefinitionIDs(omahaRealGames);
      state.realOmaha5 = returnArrayOfGameDefinitionIDs(omaha5RealGames);

      // Fun Games Logic
      let funGameDefinitions = [...data]
        .filter(
          (eachGameDefinition) =>
            eachGameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
        )
        .sort((a, b) => a.smallBlind - b.smallBlind);
      let playStakeGames = funGameDefinitions.map((eachFunGame) => {
        let updatedGameDetails = { ...eachFunGame };
        updatedGameDetails.originalGameType = eachFunGame.gameType;
        updatedGameDetails.gameType = eachFunGame.gameVariant;
        return updatedGameDetails;
      });
      let texasHoldemGames = playStakeGames.filter(
        (eachGameDefinition) =>
          eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      );
      let omahaGames = playStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      );
      let omaha5Games = playStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      );
      let funGamesData = {
        texasHoldem: texasHoldemGames,
        omaha: omahaGames,
        omaha5: omaha5Games,
      };
      state.playStateGameVariants = funGamesData;

      state.funGames = returnArrayOfGameDefinitionIDs(playStakeGames);
      state.funTexasHoldem = returnArrayOfGameDefinitionIDs(texasHoldemGames);
      state.funOmaha = returnArrayOfGameDefinitionIDs(omahaGames);
      state.funOmaha5 = returnArrayOfGameDefinitionIDs(omaha5Games);

      // We are not sending lack request upon the reconnection scenario. as it causing duplicate table scenario.
      // NOTE: moving this lack request after receiving tld# data
      // if (!state.isLackGamesReceived) {
      //   let lackProtocol =
      //     "lack#" +
      //     JSON.stringify({
      //       playerName: state.player.userName,
      //     });
      //   // LobbyConnection.getInstance()
      //   //   .lobbyConnection.clientListener.getCommunicationChannel()
      //   //   .sendMessage(lackProtocol);
      //   LobbyConnection.getInstance().writeMessage(lackProtocol);
      // }

      state.showLobbyLoading = false;
    },

    updateGameActiveStatus: (state, action) => {
      const { gameDefIds, status } = action.payload;

      let activeGamesExist = true; //false;
      let numberOfInactiveGames = 0;
      let masterGameDefinitionsCount = state.masterGameDefinitionsCount;

      for (let i = 0; i < gameDefIds.length; i++) {
        let gameDefId = gameDefIds[i];
        if (state.masterGameDefinitions.hasOwnProperty(gameDefId)) {
          state.masterGameDefinitions[gameDefId].status = status;

          if (status.toLowerCase() === "inactive") {
            numberOfInactiveGames = numberOfInactiveGames + 1;
          }
        }
        // if (status?.toLowerCase() === "active") {
        //   activeGamesExist = true;
        // }
      }

      if (masterGameDefinitionsCount === numberOfInactiveGames) {
        activeGamesExist = false;
      }
      state.activeGamesExist = activeGamesExist;

      // let updatedGameDefinions = [...state.masterGameDefinitions].map(
      //   (eachGameDefinition) => {
      //     if (gameDefIds.includes(eachGameDefinition.gameDefId)) {
      //       eachGameDefinition.status = status;
      //       return eachGameDefinition;
      //     } else {
      //       return eachGameDefinition;
      //     }
      //   }
      // );

      // state.masterGameDefinitions = [...updatedGameDefinions];

      // let realStakeGames = updatedGameDefinions.filter(
      //   (eachGameDefinition) =>
      //     eachGameDefinition.flavourType.toLowerCase() === Constants.LB_REAL
      // );
      // let texasHoldemGames = realStakeGames.filter(
      //   (eachGameDefinition) =>
      //     eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      // );
      // let omahaGames = realStakeGames.filter(
      //   (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      // );
      // let omaha5Games = realStakeGames.filter(
      //   (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      // );

      // state.realStakeGameVariants = {
      //   texasHoldem: texasHoldemGames,
      //   omaha: omahaGames,
      //   omaha5: omaha5Games,
      // };

      // let playStakeGames = updatedGameDefinions.filter(
      //   (eachGameDefinition) =>
      //     eachGameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
      // );
      // let playTexasHoldemGames = playStakeGames.filter(
      //   (eachGameDefinition) =>
      //     eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      // );
      // let playOmahaGames = playStakeGames.filter(
      //   (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      // );
      // let playOmaha5Games = playStakeGames.filter(
      //   (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      // );

      // state.playStateGameVariants = {
      //   texasHoldem: playTexasHoldemGames,
      //   omaha: playOmahaGames,
      //   omaha5: playOmaha5Games,
      // };

      // console.log(
      //   "UPDATED GAME ACTIVE STATUS ",
      //   state.masterGameDefinitions
      // );
    },

    sendLobbyDebugLog: (state, action) => {
      const { message } = action.payload;

      const logPayload = {
        playerName: state?.player?.userName,
        logMessage: message,
      };
      let debugProtocol = `DB#${JSON.stringify(logPayload)}`;
      // LobbyConnection.getInstance()
      //   .lobbyConnection.clientListener.getCommunicationChannel()
      //   .sendMessage(debugProtocol);
      LobbyConnection.getInstance().writeMessage(debugProtocol);
    },

    lobbyPlayData: (state, action) => {
      state.playStateGameVariants = action.payload;
    },

    lobbyRealData: (state, action) => {
      state.realStakeGameVariants = action.payload;
    },

    lobbyUpdatedGameDefinition: (state, action) => {
      const { gameDefinition } = action.payload;

      if (state.masterGameDefinitions[gameDefinition?.gameDefId]) {
        state.masterGameDefinitions[gameDefinition?.gameDefId] = gameDefinition;
        return;
      }

      if (gameDefinition.flavourType.toLowerCase() === Constants.LB_REAL) {
        state.realGames = [...state.realGames, gameDefinition.gameDefId].sort(
          (a, b) => a.smallBlind - b.smallBlind
        );
        if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
          state.realTexasHoldem = [
            ...state.realTexasHoldem,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        } else if (gameDefinition.gameType === Constants.OMAHA) {
          state.realOmaha = [...state.realOmaha, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        } else if (gameDefinition.gameType === Constants.OMAHA5) {
          state.realOmaha5 = [
            ...state.realOmaha5,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        }
      } else if (
        gameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
      ) {
        state.funGames = [...state.funGames, gameDefinition.gameDefId].sort(
          (a, b) => a.smallBlind - b.smallBlind
        );
        if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
          state.funTexasHoldem = [
            ...state.funTexasHoldem,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        } else if (gameDefinition.gameType === Constants.OMAHA) {
          state.funOmaha = [...state.funOmaha, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        } else if (gameDefinition.gameType === Constants.OMAHA5) {
          state.funOmaha5 = [...state.funOmaha5, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        }
      }
      state.masterGameDefinitions[gameDefinition.gameDefId] = gameDefinition;
      //calculating the length again just to avoid any rare case of duplicate game definitions (need to fine tune this) @yashwanth
      state.masterGameDefinitionsCount = Object.keys(
        state.masterGameDefinitions
      ).length;
    },

    upadatePlayersCount: (state, action) => {
      const { playerCountMap } = action.payload;
      // console.log("Player Count Map is ", playerCountMap);
      // const { realStakeGameVariants, playStateGameVariants } = state;
      for (let i = 0; i < playerCountMap.length; i++) {
        let playerCountItem = playerCountMap[i];
        if (
          state.masterGameDefinitions.hasOwnProperty(playerCountItem.gameDefId)
        ) {
          state.masterGameDefinitions[playerCountItem.gameDefId].onlineCount =
            playerCountItem.playerCount;
        }
      }
      // let totalGameDefinitions = [
      //   ...realStakeGameVariants.texasHoldem,
      //   ...realStakeGameVariants.omaha,
      //   ...realStakeGameVariants.omaha5,
      //   ...playStateGameVariants.texasHoldem,
      //   ...playStateGameVariants.omaha,
      //   ...playStateGameVariants.omaha5,
      // ];
      // playerCountMap.map((eachPlayerCount) => {
      //   let gameDefinition = getGameDefinition(
      //     totalGameDefinitions,
      //     eachPlayerCount.gameDefId
      //   );
      //   if (gameDefinition) {
      //     gameDefinition.onlineCount = eachPlayerCount.playerCount;

      //     if (gameDefinition.flavourType.toLowerCase() === Constants.LB_REAL) {
      //       if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
      //         state.realStakeGameVariants.texasHoldem =
      //           updateGameDefiniton(
      //             state.realStakeGameVariants.texasHoldem,
      //             gameDefinition
      //           );
      //       } else if (gameDefinition.gameType === Constants.OMAHA) {
      //         state.realStakeGameVariants.omaha = updateGameDefiniton(
      //           state.realStakeGameVariants.omaha,
      //           gameDefinition
      //         );
      //       } else if (gameDefinition.gameType === Constants.OMAHA5) {
      //         state.realStakeGameVariants.omaha5 = updateGameDefiniton(
      //           state.realStakeGameVariants.omaha5,
      //           gameDefinition
      //         );
      //       }
      //     } else if (
      //       gameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
      //     ) {
      //       if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
      //         state.playStateGameVariants.texasHoldem =
      //           updateGameDefiniton(
      //             state.playStateGameVariants.texasHoldem,
      //             gameDefinition
      //           );
      //       } else if (gameDefinition.gameType === Constants.OMAHA) {
      //         state.playStateGameVariants.omaha = updateGameDefiniton(
      //           state.playStateGameVariants.omaha,
      //           gameDefinition
      //         );
      //       } else if (gameDefinition.gameType === Constants.OMAHA5) {
      //         state.playStateGameVariants.omaha5 = updateGameDefiniton(
      //           state.playStateGameVariants.omaha5,
      //           gameDefinition
      //         );
      //       }
      //     }
      //   }
      //   return eachPlayerCount;
      // });
    },

    handleTourneysLobbyData: (state, action) => {
      const { upcomingTourneys, completedTourneys, tourneyTiles } =
        action.payload;
      state.tourneys.upcomingTourneys = [...upcomingTourneys];
      state.tourneys.completedTourneys = [...completedTourneys];
      state.receivedTourneyData = true;
      let updatedUpcomingTourneysData = [...upcomingTourneys].map(
        (eachTourney) => {
          eachTourney["tourneyIdentifier"] = "upcomingTourneys";
          eachTourney["tourneyPlayerStatus"] = "NOT_REGISTERED";
          eachTourney["isAlreadyRegisteredTourney"] = false;
          return eachTourney;
        }
      );
      let updatedCompletedTourneys = [...completedTourneys].map(
        (eachTourney) => {
          eachTourney["tourneyIdentifier"] = "completedTourneys";
          eachTourney["tourneyPlayerStatus"] = "COMPLETED";

          return eachTourney;
        }
      );

      state.tourneys.masterTourneysData = [
        ...updatedUpcomingTourneysData,
        ...updatedCompletedTourneys,
      ];
      state.tourneyTabs = [...tourneyTiles];
      if (
        state.receivedBalanceResponse &&
        state.receivedTokenResponse &&
        state.receivedLobbyData
      ) {
        // state.showLobbyLoading = false;
        state.showTourneyLobbyLoading = false;
      }

      if (!state.isLackGamesReceived) {
        let lackProtocol =
          "lack#" +
          JSON.stringify({
            playerName: state.player.userName,
          });
        // LobbyConnection.getInstance()
        //   .lobbyConnection.clientListener.getCommunicationChannel()
        //   .sendMessage(lackProtocol);
        LobbyConnection.getInstance().writeMessage(lackProtocol);
      }
    },

    handleDynamicTourneyLobbyData: (state, action) => {
      const { tournaments } = action.payload;
      let updatedUpcomingTourneysData = [...tournaments].map((eachTourney) => {
        eachTourney["tourneyIdentifier"] = "upcomingTourneys";
        eachTourney["tourneyPlayerStatus"] = "NOT_REGISTERED";
        eachTourney["isAlreadyRegisteredTourney"] = false;
        return eachTourney;
      });
      state.tourneys.upcomingTourneys.push(updatedUpcomingTourneysData[0]);
      state.tourneys.masterTourneysData.push(updatedUpcomingTourneysData[0]);
      state.receivedTourneyData = true;
    },

    handleTourneyRegisteredCountChange: (state, action) => {
      const { tourneyRegistrationCountMap } = action.payload;

      Object.keys(tourneyRegistrationCountMap).map((eachTourneyId) => {
        let tourneyDetails = state.tourneys.masterTourneysData.find(
          (eachTourney) => eachTourney.tourneyId === eachTourneyId
        );

        if (tourneyDetails) {
          if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
            state.tourneys.upcomingTourneys = [
              ...state.tourneys.upcomingTourneys,
            ].map((eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                eachTourney.registeredPlayerCount =
                  tourneyRegistrationCountMap[eachTourney.tourneyId];
                return eachTourney;
              }
              return eachTourney;
            });
          } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
            state.tourneys.completedTourneys = [
              ...state.tourneys.completedTourneys,
            ].map((eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                eachTourney.registeredPlayerCount =
                  tourneyRegistrationCountMap[eachTourney.tourneyId];
                return eachTourney;
              }
              return eachTourney;
            });
          } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
            state.tourneys.myTourneys = [...state.tourneys.myTourneys].map(
              (eachTourney) => {
                if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                  eachTourney.registeredPlayerCount =
                    tourneyRegistrationCountMap[eachTourney.tourneyId];
                  return eachTourney;
                }
                return eachTourney;
              }
            );
          }

          state.tourneys.masterTourneysData.map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              tourneyDetails.registeredPlayerCount =
                tourneyRegistrationCountMap[eachTourney.tourneyId];
              return tourneyDetails;
            }
            return eachTourney;
          });

          if (
            state.selectedTourneyInfoDetails &&
            state.selectedTourneyInfoDetails.tourneyId ===
              tourneyDetails.tourneyId
          ) {
            state.selectedTourneyInfoDetails.registeredPlayerCount =
              tourneyRegistrationCountMap[tourneyDetails.tourneyId];
          }
        }
      });
    },

    handleTourneyStatusChange: (state, action) => {
      const { tourneyId, status, tourneyStartTime } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );

      if (tourneyDetails) {
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          state.tourneys.upcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              eachTourney.tourneyStatus = status;
              eachTourney.tourneyStartTime = tourneyStartTime;
              return eachTourney;
            }
            return eachTourney;
          });
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          state.tourneys.completedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              eachTourney.tourneyStatus = status;
              eachTourney.tourneyStartTime = tourneyStartTime;
              return eachTourney;
            }
            return eachTourney;
          });
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          state.tourneys.myTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                eachTourney.tourneyStatus = status;
                eachTourney.tourneyStartTime = tourneyStartTime;
                return eachTourney;
              }
              return eachTourney;
            }
          );
        }
        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            tourneyDetails.tourneyStatus = status;
            tourneyDetails.tourneyStartTime = tourneyStartTime;
            return tourneyDetails;
          }
          return eachTourney;
        });
        if (
          state?.selectedTourneyInfoDetails &&
          tourneyDetails.tourneyId ===
            state?.selectedTourneyInfoDetails?.tourneyId
        ) {
          state.selectedTourneyInfoDetails.tourneyStatus = status;
          state.selectedTourneyInfoDetails.tourneyStartTime = tourneyStartTime;
        }
      }
    },

    handleTourneyComplete: (state, action) => {
      const { tourneyId } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedTourneys = [...state.tourneys.upcomingTourneys].filter(
            (eachTourney) => eachTourney.tourneyId !== tourneyDetails.tourneyId
          );
          state.tourneys.upcomingTourneys = [...updatedTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedTourneys = [...state.tourneys.myTourneys].filter(
            (eachTourney) => eachTourney.tourneyId !== tourneyDetails.tourneyId
          );
          state.tourneys.myTourneys = [...updatedTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedTourneys = [...state.tourneys.completedTourneys].filter(
            (eachTourney) => eachTourney.tourneyId !== tourneyDetails.tourneyId
          );
          state.tourneys.completedTourneys = [...updatedTourneys];
        }
        tourneyDetails.tourneyStatus = Constants.TOURNEY_STATUS_COMPLETED;
        tourneyDetails.tourneyIdentifier = "completedTourneys";
        tourneyDetails.isAlreadyRegisteredTourney = false;

        state.tourneys.completedTourneys.push(tourneyDetails);

        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          }
          return eachTourney;
        });
      }
    },

    handleTourneyPlayerAutoRegister: (state, action) => {
      const { tourneyId, registerAck } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.registeredPlayerCount = registerAck.tourneyPlayerCount;
        tourneyDetails.tourneyIdentifier = "myTourneys";
        tourneyDetails.isAlreadyRegisteredTourney = true;

        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          }
          return eachTourney;
        });
        let updatedTourneysMasterData = [...state.tourneys.masterTourneysData];
        const myTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "myTourneys"
        );
        const upcomingTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "upcomingTourneys"
        );
        const completedTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "completedTourneys"
        );
        state.tourneys.completedTourneys = completedTourneys;
        state.tourneys.upcomingTourneys = upcomingTourneys;
        state.tourneys.myTourneys = myTourneys;

        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId === tourneyId
        ) {
          state.selectedTourneyInfoDetails.registeredPlayerCount =
            registerAck.tourneyPlayerCount;
          state.selectedTourneyInfoDetails.tourneyIdentifier = "myTourneys";
          state.selectedTourneyInfoDetails.isAlreadyRegisteredTourney = true;
        }
      }
    },

    handleTourneyDynamicCountsUpdate: (state, action) => {
      const { tourneyId, reEntryCount, reBuyCount, addOnCount } =
        action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.totalReEntryCount = reEntryCount;
        tourneyDetails.totalRebuyCount = reBuyCount;
        tourneyDetails.totalAddOnCount = addOnCount;

        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          }
          return eachTourney;
        });
        let updatedTourneysMasterData = [...state.tourneys.masterTourneysData];
        const myTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "myTourneys"
        );
        const upcomingTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "upcomingTourneys"
        );
        const completedTourneys = updatedTourneysMasterData.filter(
          (eachTourney) => eachTourney.tourneyIdentifier === "completedTourneys"
        );
        state.tourneys.completedTourneys = completedTourneys;
        state.tourneys.upcomingTourneys = upcomingTourneys;
        state.tourneys.myTourneys = myTourneys;

        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId === tourneyId
        ) {
          state.selectedTourneyInfoDetails.totalReEntryCount = reEntryCount;
          state.selectedTourneyInfoDetails.totalRebuyCount = reBuyCount;
          state.selectedTourneyInfoDetails.totalAddOnCount = addOnCount;
        }
      }
    },

    handleTourneyRemove: (state, action) => {
      const { tourneyId } = action.payload;

      let filteredMasterTourneys = state.tourneys.masterTourneysData.filter(
        (eachTourney) => eachTourney.tourneyId !== tourneyId
      );

      const myTourneys = filteredMasterTourneys.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "myTourneys"
      );
      const upcomingTourneys = filteredMasterTourneys.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "upcomingTourneys"
      );
      const completedTourneys = filteredMasterTourneys.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "completedTourneys"
      );
      state.tourneys.completedTourneys = completedTourneys;
      state.tourneys.upcomingTourneys = upcomingTourneys;
      state.tourneys.myTourneys = myTourneys;
      state.tourneys.masterTourneysData = [...filteredMasterTourneys];
    },

    handleNewChildTourneyData: (state, action) => {
      const { tourneyId, childTourneys } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.childTourneys = [...childTourneys];

        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          }
          return eachTourney;
        });
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId === tourneyId
        ) {
          state.selectedTourneyInfoDetails.childTourneys = [...childTourneys];
        }
      }
    },

    requestTourneyInfoData: (state, action) => {
      const { tourneyDetails } = action.payload;
      const requestPayload = {
        tourneyId: tourneyDetails.tourneyId,
        playerName: state.player.userName,
      };
      const tourneyInfoProtocol =
        "TourneyInfo#" + JSON.stringify({ ...requestPayload });
      // LobbyConnection.getInstance()
      //   .lobbyConnection.clientListener.getCommunicationChannel()
      //   .sendMessage(tourneyInfoProtocol);
      LobbyConnection.getInstance().writeMessage(tourneyInfoProtocol);

      state.showTourneyInfoPage = true;
      state.showTourneyInfoLoading = true;
      state.selectedTourneyInfoDetails = {
        ...tourneyDetails,
        hideRegistrationCTAButtons: false,
        tourneyEntriesData: [],
      };
      state.toastMessages = [];
    },

    updateTourneyRegistrationCTAButtonsHide: (state, action) => {
      const { isHidden } = action.payload;
      if (state.selectedTourneyInfoDetails) {
        state.selectedTourneyInfoDetails = {
          ...state.selectedTourneyInfoDetails,
          hideRegistrationCTAButtons: isHidden,
        };
      }
    },

    requestTourneyEntriesDynamicData: (state, action) => {
      const { tourneyId } = action.payload;
      const entriesDetailsPayload = {
        tourneyId: tourneyId,
        playerName: state.player.userName,
      };
      const entriesProtocol =
        "ranksData#" + JSON.stringify({ ...entriesDetailsPayload });
      // LobbyConnection.getInstance()
      //   .lobbyConnection.clientListener.getCommunicationChannel()
      //   .sendMessage(entriesProtocol);
      LobbyConnection.getInstance().writeMessage(entriesProtocol);
    },

    requestUpdatedTourneyPlayerStatus: (state, action) => {
      const tpsPayload = {
        playerName: state.player.userName,
        tourneyId: action?.payload?.tourneyId ? action.payload.tourneyId : null,
      };
      const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
      LobbyConnection.getInstance().writeMessage(tpsProtocol);
    },

    requestFunChipsReload: (state, action) => {
      const requestPayload = {
        playerName: state.player.userName,
      };
      const reloadPayload =
        "ChipsReload#" + JSON.stringify({ ...requestPayload });

      LobbyConnection.getInstance().writeMessage(reloadPayload);
    },

    requestCurrentFunChipsBalance: (state, action) => {
      const requestPayload = {
        playerName: state.player.userName,
      };
      const fetchFunChipsBalance =
        "getPlayerChips#" + JSON.stringify({ ...requestPayload });
      LobbyConnection.getInstance().writeMessage(fetchFunChipsBalance);
    },

    requestTourneyTablesDynamicData: (state, action) => {
      const { tourneyId } = action.payload;
      const tablesDetailsPayload = {
        tourneyId: tourneyId,
        playerName: state.player.userName,
      };
      const tablesProtocol =
        "tabledata#" + JSON.stringify({ ...tablesDetailsPayload });
      LobbyConnection.getInstance().writeMessage(tablesProtocol);
    },

    handleTourneyRanksData: (state, action) => {
      let { rankDataList } = action.payload;

      if (state.selectedTourneyInfoDetails) {
        // limiting the records only for 50 (if current user rank is within 50 else 51 (50+1))
        // state.selectedTourneyInfoDetails.tourneyEntriesData =
        //   rankDataList;
        let index = rankDataList.findIndex(
          (item) => item["playerName"] === state.player.userName
        );
        console.log("Yashwanth logging index:", index);
        if (index > 0) {
          //to rearrange ranks order only if the player name is greater than index 0
          let currentUserRankDetails = rankDataList[index];
          console.log(
            "Yashwanth logging currentUserRankDetails:",
            currentUserRankDetails
          );
          // let updatedRanksDataList = rankDataList.filter(
          //   (eachRankDetail) => eachRankDetail.playerName !== playerName
          // );
          rankDataList.splice(index, 1);
          let updatedRanksDataList = rankDataList;
          console.log(
            "Yashwanth logging updatedRanksDataList:",
            updatedRanksDataList
          );
          rankDataList = [currentUserRankDetails, ...updatedRanksDataList];
          console.log("Yashwanth logging FINAL rankDataList:", rankDataList);
        }
        state.selectedTourneyInfoDetails.tourneyEntriesData = rankDataList;
      }
    },
    handleTourneyTablesData: (state, action) => {
      const { tourneyTableDetailsList, activeTableCount } = action.payload;
      let updatedTableData = tourneyTableDetailsList.map((eachTable) => {
        return {
          domainName: eachTable?.domainName,
          nodeIp: eachTable?.nodeIp,
          tableId: eachTable?.tableID,
          players: eachTable?.numberOfPlayers,
          largest: eachTable?.highestChips,
          smallest: eachTable?.lowestChips,
        };
      });
      if (state?.selectedTourneyInfoDetails) {
        if (
          state.selectedTourneyInfoDetails?.tourneyIdentifier ===
          "completedTourneys"
        ) {
          state.selectedTourneyInfoDetails.tourneyTablesStatusList = [];
        } else {
          state.selectedTourneyInfoDetails.tourneyTablesStatusList = [
            ...updatedTableData,
          ];
        }
        state.selectedTourneyInfoDetails["activeTableCount"] = activeTableCount;
      }
    },

    updateRemindMeStatusToServer: (state, action) => {
      const { tourneyId, RemindMeEnable } = action.payload;
      const isReminded = RemindMeEnable === "true";

      if (tourneyId && isReminded) {
        const requestBody = {
          tourneyId: tourneyId,
          playerName: state.player.userName,
          reminder: isReminded,
        };
        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyId) {
            eachTourney.reminder = isReminded;
            return eachTourney;
          }
          return eachTourney;
        });
        state.selectedTourneyInfoDetails.reminder = isReminded;
        const remindMeProtocol = "rm#" + JSON.stringify({ ...requestBody });
        // LobbyConnection.getInstance()
        //   .lobbyConnection.clientListener.getCommunicationChannel()
        //   .sendMessage(remindMeProtocol);
        LobbyConnection.getInstance().writeMessage(remindMeProtocol);
      }
    },

    updateTourneyInfoData: (state, action) => {
      state.selectedTourneyInfoDetails = {
        ...state.selectedTourneyInfoDetails,
        ...action.payload,
      };
      state.showTourneyInfoLoading = false;
    },

    closeTourneyInfoPage: (state, action) => {
      state.showTourneyInfoPage = false;
      state.selectedTourneyInfoDetails = null;
      state.showLateRegisterSuccessPopup = false;
    },

    closeTourneyInfoPageOnlyIfLoading: (state, action) => {
      if (state.showTourneyInfoLoading) {
        state.showTourneyInfoPage = false;
        state.selectedTourneyInfoDetails = null;
        state.showLateRegisterSuccessPopup = false;
      }
    },

    updateCashAndFunGamesToggle: (state, action) => {
      const { isCashGameToggled } = action.payload;
      state.isCashGamesToggled = isCashGameToggled;
    },

    updateNotificationCount: (state, action) => {
      const { notificationsCount } = action.payload;
      state.notificationsCount = notificationsCount ? notificationsCount : 0;
    },

    updateHomePageData: (state, action) => {
      let { homeData } = action.payload;
      let { horizantalBanners, promotionalbanners, serverData } = homeData;
      if (serverData) {
        let { rakeback, missions, leaderboard } = serverData;
        if ("currentRBPoints" in rakeback) {
          state.homeData.rakeback = rakeback;
        } else {
          state.homeData.rakeback = null;
        }
        if (missions.missionDescription) {
          state.homeData.missions = missions;
        } else {
          state.homeData.missions = null;
        }
        if (leaderboard.lbName) {
          state.homeData.leaderboard = leaderboard;
        } else {
          state.homeData.leaderboard = null;
        }
      }
      state.homeData.horizontalBanners = horizantalBanners;
      state.homeData.CTPromotionalBanners = promotionalbanners;
      state.homeData.dataUpdated = true;
    },

    resetHomePageData: (state, action) => {
      state.homeData = {
        rakeback: null,
        leaderboard: null,
        missions: null,
        horizontalBanners: [],
        CTPromotionalBanners: [],
        dataUpdated: false,
        recommendedGames: [],
      };
    },

    updateResourceLoadFailure: (state, action) => {
      state.resourceLoadFailure = true;
    },

    checkIfResourcesFailed: (state, action) => {},

    updateHomePageRecommendedData: (state, action) => {
      let { lbGameDefIds } = action.payload;
      let gameDefinitions = [];
      for (let lbGameId of lbGameDefIds) {
        let gameDefId = lbGameId.split("_")[2];
        if (gameDefId) {
          let gameDefinition = state.masterGameDefinitions[gameDefId];
          if (gameDefinition?.status.toLowerCase() === "active") {
            gameDefinitions.push(gameDefinition);
          }
        }
      }
      state.homeData.recommendedGames = gameDefinitions;
    },

    updateGridTiles: (state, action) => {
      state.homeData.gridTilesData = action.payload.gridTiles;
    },

    updateTopBanners: (state, action) => {
      state.homeData.topLoadedBanners = action.payload.topBanners;
    },

    updateBottomBanners: (state, action) => {
      state.homeData.bottomLoadedBanners = action.payload.bottomBanners;
    },

    updateShowLobbyLoading: (state, action) => {
      state.showLobbyLoading = action.payload.showLobbyLoading;
    },

    setShowGameTable: (state, action) => {
      state.showGameTable = action.payload.showGameTable;
    },

    updateShowTourneyInfoPage: (state, action) => {
      state.showTourneyInfoPage = action.payload.showTourneyInfoPage;
    },

    updateIsLackTourneyGamesDisplayed: (state, action) => {
      state.isLackTourneyGamesDisplayed =
        action.payload.isLackTourneysDisplayed;
    },

    updateLackGamesDetails: (state, action) => {
      const { playerId, activeGames } = action.payload;
      state.isLackGamesReceived = true;
      state.lackGames = {
        playerId: playerId,
        activeGames: activeGames,
      };
    },

    updateLackReceivedStatus: (state, action) => {
      state.isLackGamesReceived = true;
    },

    updateLackGamesShowStatus: (state, action) => {
      state.isLackGamesShown = true;
    },

    handleRemoveDisplayedCashLackGame: (state, action) => {
      const { gameId } = action.payload;
      // let updatedLackGames = [...currentState.lackGames.activeGames].filter(
      //   (eachLackGame) => eachLackGame.gameId !== gameId
      // );
      state.lackGames.activeGames = state.lackGames.activeGames.filter(
        (eachLackGame) => eachLackGame.gameId !== gameId
      );
      // return currentState;
    },

    updateLobbyHeartBeatDetails: (state, action) => {
      const { onlinePlayerCount, currentTime } = action.payload;
      state.onlinePlayerCount = onlinePlayerCount;
      state.currentTimeStamp = currentTime;
      state.currentLobbyTime = new Date(currentTime);
    },

    updateShowTourneyRegisterConfirmationPopup: (state, action) => {
      state.showTourneyRegisterConfirmationPopup = action.payload.isOpen;
    },

    updateShowAllTicketsSection: (state, action) => {
      state.showAllTicketsSection = action.payload.isOpen;
    },

    updateLobbyCurrentTab: (state, action) => {
      state.currentTab = action.payload.selectedTab;
    },

    updateShowTourneyAddCashTicketRegistrationPopup: (state, action) => {
      state.showTourneyAddCashTicketRegistrationPopup = action.payload.isOpen;
    },
    updateShowTourneyReEntryConfirmationPopup: (state, action) => {
      state.showTourneyReEntryConfirmationPopup = action.payload.isOpen;
    },

    updateShowTourneyRegisterOrDeregisterRequestLoading: (state, action) => {
      state.showTourneyRegisterOrDeregisterRequestLoading =
        action.payload.isLoading;
    },

    updateTourneyTicketsClaimInProgress: (state, action) => {
      const { tourneyId, isLoading } = action.payload;
      state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = isLoading;
    },

    updateShowTourneyReEntryRequestLoading: (state, action) => {
      state.showTourneyReEntryRequestLoading = action.payload.isLoading;
    },

    updateShowTourneyInsufficientFundsModal: (state, action) => {
      state.showTourneyInsufficientFundsModal = action.payload.isOpen;
    },

    updateShowTourneyFairPlayPolicyRestruction: (state, action) => {
      state.showTourneyFairPlayPolicyRestrictionPopup = action.payload.isOpen;
    },

    updateShowLateRegisterSuccessPopup: (state, action) => {
      state.showLateRegisterSuccessPopup = action.payload.isOpen;
    },
    updateTourneyPlayerStatusChange: (state, action) => {
      const { playerStatusObjList } = action.payload;

      let updatedTourneysMasterData = [...state.tourneys.masterTourneysData];

      playerStatusObjList.forEach((eachTourneyStatus) => {
        let updatedTourneysData = [...state.tourneys.masterTourneysData].map(
          (eachTourney) => {
            if (
              !Object.keys(eachTourneyStatus).includes("tourneyPlayerStatus")
            ) {
              let prevTourneyStatus = { ...eachTourneyStatus };
              eachTourneyStatus["tourneyPlayerStatus"] =
                Constants.TOURNEY_PLAYER_STATUS_MISSED;

              const logPayload = {
                type: "TPS DATA ISSUE tourneyPlayerStatus KEY MISSED.",
                playerName: state?.player?.userName,
                receivedData: prevTourneyStatus,
              };

              let debugProtocol = `DB#${JSON.stringify(logPayload)}`;
              LobbyConnection.getInstance().writeMessage(debugProtocol);
            }

            const {
              tourneyId,
              tourneyPlayerStatus,
              reminder,
              tourneyStatus,
              activePlayerForTourney,
              bustedOut,
            } = eachTourneyStatus;
            if (tourneyId === eachTourney.tourneyId) {
              eachTourney.tourneyStatus = tourneyStatus;
              if (!eachTourney?.bustedOut) {
                eachTourney["bustedOut"] = bustedOut;
              }
              console.log(
                "UDPATED BUSTED OUT PLAYER DETAILS IN TPS",
                eachTourney
              );

              eachTourney["activePlayerForTourney"] = activePlayerForTourney;
              if (tourneyPlayerStatus === Constants.TOURNEY_PLAYER_STATUS_WON) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_WON;
                eachTourney.tourneyIdentifier = "completedTourneys";
                eachTourney.isAlreadyRegisteredTourney = false;
                eachTourney.reminder = reminder;
              } else if (
                tourneyPlayerStatus === Constants.TOURNEY_STATUS_COMPLETED
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_COMPLETED;
                eachTourney.tourneyIdentifier = "completedTourneys";
                eachTourney.isAlreadyRegisteredTourney = false;
              } else if (
                tourneyPlayerStatus ===
                Constants.TOURNEY_PLAYER_STATUS_CANCELLED
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.tourneyIdentifier = "completedTourneys";
                eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_CANCELLED;
                eachTourney.isAlreadyRegisteredTourney = false;
                eachTourney.reminder = reminder;
              } else if (
                eachTourney.tourneyStatus !== tourneyPlayerStatus &&
                tourneyPlayerStatus ===
                  Constants.TOURNEY_PLAYER_STATUS_MISSED &&
                eachTourney.tourneyStatus !== Constants.TOURNEY_STATUS_LIVE
                //to be confirmed with Ganesh - @yashwanth
                // eachTourney.tourneyStatus !== Constants.TOURNEY_STATUS_LIVE &&
                // eachTourney.tourneyStatus !== Constants.TOURNEY_REGISTRATION_STARTED
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.tourneyIdentifier = "completedTourneys";
                eachTourney.tourneyStatus =
                  Constants.TOURNEY_PLAYER_STATUS_MISSED;
                eachTourney.isAlreadyRegisteredTourney = false;
                eachTourney.reminder = reminder;
              } else if (
                eachTourney.tourneyStatus !== tourneyPlayerStatus &&
                tourneyPlayerStatus ===
                  Constants.TOURNEY_PLAYER_STATUS_MISSED &&
                eachTourney.tourneyStatus === Constants.TOURNEY_STATUS_LIVE
                //to be confirmed with Ganesh - @yashwanth
                // (eachTourney.tourneyStatus === Constants.TOURNEY_STATUS_LIVE ||
                //   eachTourney.tourneyStatus === Constants.TOURNEY_REGISTRATION_STARTED )
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.tourneyIdentifier = "upcomingTourneys";
                eachTourney.tourneyStatus =
                  Constants.TOURNEY_PLAYER_STATUS_MISSED;
                eachTourney.isAlreadyRegisteredTourney = false;
                eachTourney.reminder = reminder;
              } else if (
                tourneyPlayerStatus ===
                Constants.TOURNEY_PLAYER_STATUS_REGISTERED
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.reminder = reminder;
                eachTourney.tourneyIdentifier = "myTourneys";
                eachTourney.isAlreadyRegisteredTourney = true;
              } else if (
                tourneyPlayerStatus === Constants.TOURNEY_PLAYER_STATUS_RE_ENTRY
              ) {
                eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
                eachTourney.reminder = reminder;
                eachTourney.isAlreadyRegisteredTourney = false;
                eachTourney.lateRegistrationAllowed = false;
              } else if (tourneyPlayerStatus.length === 0) {
                eachTourney.tourneyPlayerStatus = "NOT_REGISTERED";
                eachTourney.reminder = reminder;
                eachTourney.tourneyIdentifier = "upcomingTourneys";
                eachTourney.isAlreadyRegisteredTourney = false;
              }
              if (
                state?.selectedTourneyInfoDetails &&
                state?.selectedTourneyInfoDetails.tourneyId ===
                  eachTourney?.tourneyId
              ) {
                state.selectedTourneyInfoDetails.tourneyPlayerStatus =
                  eachTourney.tourneyPlayerStatus;
                state.selectedTourneyInfoDetails.reminder =
                  eachTourney.reminder;
                state.selectedTourneyInfoDetails.isAlreadyRegisteredTourney =
                  eachTourney.isAlreadyRegisteredTourney;
                state.selectedTourneyInfoDetails.lateRegistrationAllowed =
                  eachTourney.lateRegistrationAllowed;
                state.selectedTourneyInfoDetails.activePlayerForTourney =
                  activePlayerForTourney;
              }
              return eachTourney;
            }
            return eachTourney;
          }
        );
        updatedTourneysMasterData = updatedTourneysData;
      });
      state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      const myTourneys = updatedTourneysMasterData.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "myTourneys"
      );
      const upcomingTourneys = updatedTourneysMasterData.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "upcomingTourneys"
      );
      const completedTourneys = updatedTourneysMasterData.filter(
        (eachTourney) => eachTourney.tourneyIdentifier === "completedTourneys"
      );
      state.tourneys.completedTourneys = completedTourneys;
      state.tourneys.upcomingTourneys = upcomingTourneys;
      state.tourneys.myTourneys = myTourneys;
    },

    updateTourneyPlayerBustedOut: (state, action) => {
      const { tourneyId, bustedOut } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        if (tourneyDetails && !tourneyDetails?.bustedOut) {
          tourneyDetails["bustedOut"] = bustedOut;
        }
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          if (!state.selectedTourneyInfoDetails?.bustedOut) {
            state.selectedTourneyInfoDetails["bustedOut"] = bustedOut;
          }
        }
        console.log("UDPATED BUSTED OUT PLAYER DETAILS", tourneyDetails);
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    updateTourneySlabChange: (state, action) => {
      const { tourneyId, winnersPayoutWithBonus, numberOfWinners } =
        action.payload;
      // let updatedTourneysMasterData = [
      //   ...state.tourneys.masterTourneysData,
      // ].map((eachTourney));
      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.displayWinners = numberOfWinners;
        tourneyDetails.winnersPayoutWithBonus = winnersPayoutWithBonus;
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.displayWinners = numberOfWinners;
          state.selectedTourneyInfoDetails.winnersPayoutWithBonus =
            winnersPayoutWithBonus;
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },
    updateTourneyPrizePoolUpdate: (state, action) => {
      const { tourneyId, updatedPrizePool } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.breakEven = false;
        tourneyDetails.prizePool = updatedPrizePool;
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.breakEven = false;
          state.selectedTourneyInfoDetails.prizePool = updatedPrizePool;
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    updateTourneyLateRegistrationEnded: (state, action) => {
      const { tourneyId, updatedPrizePool } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.lateRegistrationAllowed = false;
        tourneyDetails.lateRegEnded = true;

        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.lateRegistrationAllowed = false;
          state.selectedTourneyInfoDetails.lateRegEnded = true;
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    updateDepositorTourneyDetails: (state, action) => {
      const { tourneyId, addCashTicketRegistration } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.addCashTicketRegistration = addCashTicketRegistration;
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.addCashTicketRegistration =
            addCashTicketRegistration;
          if (
            !tourneyDetails.isAlreadyRegisteredTourney &&
            state.showTourneyRegisterConfirmationPopup &&
            !state.showTourneyRegisterOrDeregisterRequestLoading
          ) {
            state.showTourneyRegisterConfirmationPopup =
              !addCashTicketRegistration;
            state.showTourneyAddCashTicketRegistrationPopup =
              addCashTicketRegistration;
          }
          // if (
          //   !tourneyDetails.isAlreadyRegisteredTourney &&
          //   (clonedState.showTourneyRegisterConfirmationPopup ||
          //     clonedState.showTourneyAddCashTicketRegistrationPopup)
          // ) {
          //   clonedState.showTourneyRegisterConfirmationPopup =
          //     !addCashTicketRegistration;
          //   clonedState.showTourneyAddCashTicketRegistrationPopup =
          //     addCashTicketRegistration;
          // }
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    updateTourneyInTheMoney: (state, action) => {
      const { tourneyId } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.lateRegistrationAllowed = false;
        tourneyDetails.lateRegEnded = true;
        tourneyDetails.inTheMoney = true;
        tourneyDetails.hideRegistrationCTAButtons = true;
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.lateRegistrationAllowed = false;
          state.selectedTourneyInfoDetails.lateRegEnded = true;
          state.selectedTourneyInfoDetails.inTheMoney = true;
          state.selectedTourneyInfoDetails.hideRegistrationCTAButtons = true;
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    updateTourneyInBreak: (state, action) => {
      const { tourneyId, breakStartTime, breakEndTime, breakInProgress } =
        action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        tourneyDetails.breakStartTime = breakStartTime;
        tourneyDetails.breakEndTime = breakEndTime;
        tourneyDetails.breakInProgress = breakInProgress;

        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedUpcomingTourneys = [
            ...state.tourneys.upcomingTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.upcomingTourneys = [...updatedUpcomingTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedMyTourneys = [...state.tourneys.myTourneys].map(
            (eachTourney) => {
              if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
                return tourneyDetails;
              } else {
                return eachTourney;
              }
            }
          );
          state.tourneys.myTourneys = [...updatedMyTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "completedTourneys") {
          let updatedCompletedTourneys = [
            ...state.tourneys.completedTourneys,
          ].map((eachTourney) => {
            if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
              return tourneyDetails;
            } else {
              return eachTourney;
            }
          });
          state.tourneys.completedTourneys = [...updatedCompletedTourneys];
        }
        if (
          state.selectedTourneyInfoDetails &&
          state.selectedTourneyInfoDetails.tourneyId ===
            tourneyDetails.tourneyId
        ) {
          state.selectedTourneyInfoDetails.breakStartTime = breakStartTime;
          state.selectedTourneyInfoDetails.breakEndTime = breakEndTime;
          state.selectedTourneyInfoDetails.breakInProgress = breakInProgress;
        }
        let updatedTourneysMasterData = [
          ...state.tourneys.masterTourneysData,
        ].map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          } else {
            return eachTourney;
          }
        });
        state.tourneys.masterTourneysData = [...updatedTourneysMasterData];
      }
    },

    handleTourneyStart: (state, action) => {
      //NOTE: For fresh tourney start , we are appending it to same lackTourneys list and stating the game using that to avoid multiple changes in different places.
      //Even for the original Lack tourneys protocol we will update the same list to maintain consistant flow.

      action.payload.tourneys.forEach((eachLackTourney) => {
        state.lackTourneys.push({
          ...eachLackTourney,
          isTourneyLackGame: action.payload.isTourneyLackGame,
        });
      });
      state.isLackTourneyGamesDisplayed = false;
      // console.log("TOURNEY START --> ", currentState.lackTourneys);
      // return currentState;
    },

    handleRemoveDisplayedTourneyLackGame: (state, action) => {
      const { lackTourneyId } = action.payload;

      // let updatedLackTourneys = [...state.lackTourneys].filter(
      //   (eachLackTourney) => eachLackTourney.tourneyId !== lackTourneyId
      // );
      state.lackTourneys = state.lackTourneys.filter(
        (eachLackTourney) => eachLackTourney.tourneyId !== lackTourneyId
      );
      // return currentState;
    },

    addLobbyToastMessage: (state, action) => {
      const toastMessage = action.payload;
      state.toastMessages.filter(
        (toast) => toast.message === toastMessage.message
      ).length === 0 && state.toastMessages.push(toastMessage);
    },

    removeLobbyToastMessage: (state, action) => {
      const { message } = action.payload;

      let index = state.toastMessages.indexOf(message);
      state.toastMessages.splice(index, 1);
      // return currentState;
    },
    handleTourneyPrize: (state, action) => {
      const { tourneyId, playerName, playerWinning } = action.payload;
      //TODO: removing toast for now, need to show when no game tables are not open using this tourneyId, need to add this logic and display the toast.
      // state.toastMessages.push({
      //   type: Constants.TOAST_SUCCESS,
      //   message: `Congratulations! You won ${playerWinning} for playing tourney: ${tourneyId}`,
      // });
    },
    handleTourneyCancle: (state, action) => {
      const { tourneyId } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.find(
        (eachTourney) => eachTourney.tourneyId === tourneyId
      );
      if (tourneyDetails) {
        if (tourneyDetails.tourneyIdentifier === "upcomingTourneys") {
          let updatedTourneys = [...state.tourneys.upcomingTourneys].filter(
            (eachTourney) => eachTourney.tourneyId !== tourneyDetails.tourneyId
          );
          state.tourneys.upcomingTourneys = [...updatedTourneys];
        } else if (tourneyDetails.tourneyIdentifier === "myTourneys") {
          let updatedTourneys = [...state.tourneys.myTourneys].filter(
            (eachTourney) => eachTourney.tourneyId !== tourneyDetails.tourneyId
          );
          state.tourneys.myTourneys = [...updatedTourneys];
        }
        tourneyDetails.tourneyStatus = Constants.TOURNEY_STATUS_CANCELLED;
        tourneyDetails.isAlreadyRegisteredTourney = false;
        tourneyDetails.tourneyIdentifier = "completedTourneys";
        state.tourneys.completedTourneys.push(tourneyDetails);

        state.tourneys.masterTourneysData.map((eachTourney) => {
          if (eachTourney.tourneyId === tourneyDetails.tourneyId) {
            return tourneyDetails;
          }
          return eachTourney;
        });
      }
    },
    showTourneyMaxTablesLimitReached: (state, action) => {
      state.toastMessages.push({
        type: Constants.TOAST_INFO,
        message: action.payload.message,
      });
    },
    handleDynamicTourneysStartData: (state, action) => {
      const { tourneyDetails } = action.payload;
      state.dynamicTourneysStartData.push(tourneyDetails);
      state.showLateRegisterSuccessPopup = false;
    },
    handleClearDynamicTourneyStartData: (state, action) => {
      state.dynamicTourneysStartData = [];
    },

    setPlayerConsentDetails: (state, action) => {
      const { userConsentFlag } = action.payload;
      if (userConsentFlag == "true") {
        state.player.userConsentFlag = true;
        state.player.gameJoinCheckAck = true;
      } else {
        state.player.userConsentFlag = false;
        state.player.gameJoinCheckAck = false;
      }
    },

    setGameJoinCheckAck: (state, action) => {
      const { gameJoinCheckAck } = action.payload;
      if (gameJoinCheckAck == "true") {
        state.player.gameJoinCheckAck = true;
        state.player.userConsentFlag = true;
      } else {
        state.player.gameJoinCheckAck = false;
        state.player.userConsentFlag = false;
      }
    },

    handleTourneyTicketsData: (state, action) => {
      const { tickets } = action.payload;

      state.tourneyTickets = [...tickets];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestTourneyRegistration.fulfilled, (state, action) => {
        sendFaroLog({
          requestType: "TOURNEY_REGISTER_FULFILLED",
          responseData: action.payload.data,
        });
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
          lowBalanceData,
        } = action.payload.data;
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.find(
            (eachTourney) => eachTourney.tourneyId === tourneyId
          );

          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = Constants.TOURNEY_REGISTERED;
            // tourneyDetails.reminder = reminder
            tourneyDetails.tourneyIdentifier = "myTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;
            tourneyDetails.isAlreadyRegisteredTourney = true;

            state.tourneys.upcomingTourneys = [
              ...state.tourneys.upcomingTourneys,
            ].filter((eachTourney) => eachTourney.tourneyId !== tourneyId);
            state.tourneys.myTourneys.push(tourneyDetails);
            if (state?.selectedTourneyInfoDetails) {
              state.selectedTourneyInfoDetails.tourneyPlayerStatus =
                Constants.TOURNEY_REGISTERED;
              state.selectedTourneyInfoDetails.tourneyIdentifier = "myTourneys";
              state.selectedTourneyInfoDetails.registeredPlayerCount =
                tourneyPlayerCount;
              state.selectedTourneyInfoDetails.isAlreadyRegisteredTourney = true;
            }
            if (
              tourneyDetails?.tourneyStatus === Constants.TOURNEY_STATUS_LIVE &&
              tourneyDetails?.lateRegistrationAllowed
            ) {
              state.showLateRegisterSuccessPopup = true;
            }
          }
        } else if (status === 800) {
          let tourneyDetails = state.tourneys.masterTourneysData.find(
            (eachTourney) => eachTourney.tourneyId === tourneyId
          );
          if (tourneyDetails) {
            state.selectedTourneyInfoDetails = {
              ...state?.selectedTourneyInfoDetails,
              lowBalanceDetails: {
                amountToAdd: lowBalanceData?.amount_to_add,
                totalBalance: lowBalanceData?.total_balance,
                depositAndWinnings: lowBalanceData?.deposit_and_winning,
                usableBonus: lowBalanceData?.usable_bonus,
                buyInAmount: lowBalanceData?.buyInAmount,
                isTourneyGame: true,
              },
            };
            if (lowBalanceData) {
              state.showTourneyInsufficientFundsModal = true;
            }
          }
          if (
            messageCode ===
            Constants.TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY
          ) {
            state.showTourneyFairPlayPolicyRestrictionPopup = true;
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = false;
        state.showTourneyRegisterConfirmationPopup = false;

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchRequestTourneyRegistration.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_REGISTER_REJECTED",
          responseData: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails = {};
      })
      .addCase(fetchRequestTourneyDeRegistration.fulfilled, (state, action) => {
        sendFaroLog({
          requestType: "TOURNEY_DE_REGISTER_FULFILLED",
          responseData: action.payload.data,
        });
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
        } = action.payload.data;
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.find(
            (eachTourney) => eachTourney.tourneyId === tourneyId
          );

          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = "Deregistered";
            tourneyDetails.isAlreadyRegisteredTourney = false;
            // tourneyDetails.reminder = false;
            tourneyDetails.tourneyIdentifier = "upcomingTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;
            if (state?.selectedTourneyInfoDetails) {
              state.selectedTourneyInfoDetails.tourneyPlayerStatus =
                "Deregistered";
              state.selectedTourneyInfoDetails.isAlreadyRegisteredTourney = false;
              state.selectedTourneyInfoDetails.tourneyIdentifier =
                "upcomingTourneys";
              state.selectedTourneyInfoDetails.registeredPlayerCount =
                tourneyPlayerCount;
            }

            state.tourneys.myTourneys = [...state.tourneys.myTourneys].filter(
              (eachTourney) =>
                eachTourney.tourneyId !== tourneyDetails.tourneyId
            );
            state.tourneys.upcomingTourneys.push(tourneyDetails);
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterConfirmationPopup = false;
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = false;
        // state.showTourneyInfoPage = false

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchRequestTourneyDeRegistration.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_DE_REGISTER_REJECTED",
          responseData: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails = {};
      })
      .addCase(fetchTourneyReEntry.fulfilled, (state, action) => {
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
          lowBalanceDetails,
        } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_RE_ENTRY_FULFILLED",
          responseDetails: action.payload.data,
        });
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.find(
            (eachTourney) => eachTourney.tourneyId === tourneyId
          );
          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = Constants.TOURNEY_REGISTERED;
            // tourneyDetails.reminder = reminder
            tourneyDetails.tourneyIdentifier = "myTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;
            tourneyDetails.isAlreadyRegisteredTourney = true;
            if (tourneyDetails?.bustedOut) {
              tourneyDetails["bustedOut"] = false;
            }
            state.tourneys.upcomingTourneys = [
              ...state.tourneys.upcomingTourneys,
            ].filter((eachTourney) => eachTourney.tourneyId !== tourneyId);

            let checkTourneyExist = [...state.tourneys.myTourneys].find(
              (tourney) => tourney.tourneyId === tourneyId
            );
            if (checkTourneyExist) {
              let updatedMyTourneys = [...state.tourneys.myTourneys].map(
                (tourney) => {
                  if (tourney.tourneyId === tourneyId) {
                    return tourneyDetails;
                  }
                  return tourney;
                }
              );
              state.tourneys.myTourneys = [...updatedMyTourneys];
            } else {
              state.tourneys.myTourneys.push(tourneyDetails);
            }
            if (state?.selectedTourneyInfoDetails) {
              state.selectedTourneyInfoDetails.tourneyPlayerStatus =
                Constants.TOURNEY_REGISTERED;

              state.selectedTourneyInfoDetails.tourneyIdentifier = "myTourneys";
              state.selectedTourneyInfoDetails.registeredPlayerCount =
                tourneyPlayerCount;
              state.selectedTourneyInfoDetails.isAlreadyRegisteredTourney = true;
              if (state.selectedTourneyInfoDetails?.bustedOut) {
                state.selectedTourneyInfoDetails["bustedOut"] = false;
              }
            }
          }
        } else if (status === 800) {
          let tourneyDetails = state.tourneys.masterTourneysData.find(
            (eachTourney) => eachTourney.tourneyId === tourneyId
          );
          if (tourneyDetails) {
            state.selectedTourneyInfoDetails = {
              ...state?.selectedTourneyInfoDetails,
              lowBalanceDetails: lowBalanceDetails,
            };
            if (lowBalanceDetails) {
              state.showTourneyInsufficientFundsModal = true;
            }
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyReEntryConfirmationPopup = false;
        state.showTourneyReEntryRequestLoading = false;

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchTourneyReEntry.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_RE_ENTRY_REJECTED",
          responseDetails: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyReEntryRequestLoading = false;
      });
  },
});

export const {
  initializeLobbyConnection,
  lobbyPlayData,
  lobbyRealData,
  setAccessToken,
  setPlayerDetails,
  setChannel,
  setIsWebView,
  updatePlayerBalance,
  updateShowLobbyLoading,
  lobbyConAck,
  setShowGameTable,
  updateLackGamesDetails,
  updateGameDefinitionsMasterdata,
  updateLackGamesShowStatus,
  sendLobbyDebugLog,
  updateLobbyHeartBeatDetails,
  lobbyUpdatedGameDefinition,
  upadatePlayersCount,
  handleTourneysLobbyData,
  requestTourneyInfoData,
  updateShowTourneyInfoPage,
  updateTourneyInfoData,
  closeTourneyInfoPage,
  handleTourneyRegisteredCountChange,
  handleTourneyStatusChange,
  updateShowTourneyRegisterConfirmationPopup,
  addLobbyToastMessage,
  removeLobbyToastMessage,
  updateTourneyPlayerStatusChange,
  updateShowTourneyRegisterOrDeregisterRequestLoading,
  handleTourneyStart,
  updateApplicationVisibility,
  updateShowTourneyInsufficientFundsModal,
  handleDynamicTourneyLobbyData,
  updateIsLackTourneyGamesDisplayed,
  handleTourneyPrize,
  updateRemindMeStatusToServer,
  handleTourneyComplete,
  handleRemoveDisplayedTourneyLackGame,
  requestTourneyEntriesDynamicData,
  handleTourneyRanksData,
  handleRemoveDisplayedCashLackGame,
  handleTourneyCancle,
  joinGameFromLeaderBoard,
  setIsPlayerJoiningFromLB,
  leaderBoardGamesList,
  requestTourneyTablesDynamicData,
  handleTourneyTablesData,
  updateGameActiveStatus,
  updateTourneySlabChange,
  updateCashAndFunGamesToggle,
  updatePlayerPlayChipsBalance,
  updateLackReceivedStatus,
  handlePlayChipsBalanceReload,
  requestFunChipsReload,
  setRakebackGameDefinitions,
  updateTourneyPrizePoolUpdate,
  requestCurrentFunChipsBalance,
  processFunChipsBalance,
  updateShowTourneyReEntryRequestLoading,
  updatePlayerRakebackLevel,
  updateTourneyLateRegistrationEnded,
  requestUpdatedTourneyPlayerStatus,
  showTourneyMaxTablesLimitReached,
  handleDynamicTourneysStartData,
  handleClearDynamicTourneyStartData,
  handleTourneyTicketsData,
  handleDeeplink,
  clearDeeplink,
  updateShowTourneyReEntryConfirmationPopup,
  setPlayerConsentDetails,
  setGameJoinCheckAck,
  updateTourneyTicketsClaimInProgress,
  updateHomePageData,
  handleTourneyPlayerAutoRegister,
  handleNewChildTourneyData,
  handleTourneyRemove,
  resetHomePageData,
  updateGridTiles,
  updateHomePageRecommendedData,
  handleTourneyDynamicCountsUpdate,
  removeRakebackToast,
  updateNotificationCount,
  updateBottomBanners,
  updateTopBanners,
  updateShowTourneyFairPlayPolicyRestruction,
  updateShowLateRegisterSuccessPopup,
  updateResourceLoadFailure,
  updateTourneyRegistrationCTAButtonsHide,
  closeTourneyInfoPageOnlyIfLoading,
  updateDepositorTourneyDetails,
  updateShowTourneyAddCashTicketRegistrationPopup,
  updateTourneyInTheMoney,
  updateTourneyInBreak,
  updateShowAllTicketsSection,
  updateLobbyCurrentTab,
  updateTourneyPlayerBustedOut,
  openRakebackPage,
} = LobbySlice.actions;

export default LobbySlice.reducer;
