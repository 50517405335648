import React, { useState } from "react";

import A23PokerLogo from "../../../assets/lobby/A23Logo.png";
import notificationIconSource from "../../../assets/lobby/NotificationIcon.svg";
import goldPlayerIcon from "../../../assets/lobby/GoldPlayerIcon.svg";
import goldWalletIcon from "../../../assets/lobby/GoldWalletIcon.svg";
import greyLockIcon from "../../../assets/lobby/greyLockIcon.svg";
import funChipsIcon from "../../../assets/lobby/FunChipIcon.svg";
import reloadChipsIcon from "../../../assets/lobby/Reload.svg";
import addIcon from "../../../assets/lobby/AddIcon.svg";
import * as Constants from "../../data/Constants.js";

import {
  HeaderWrapper,
  A23LogoWrapper,
  NotificationAndLiveCountContainer,
  NotificationIcon,
  LivePlayerCountWrapper,
  PlayerIcon,
  LiveCountText,
  LogoAndLiveCountContainer,
  AddCashAndDynamicHeaderItemContainer,
  WalletAndAddCashCTAWrapper,
  CashAndPracticeGamesWrapper,
  CashAndPracticeGameToggleWrapper,
  CashAndPracticeItemText,
  WalletWrapper,
  WalletIcon,
  WalletAmountText,
  DynamicHeaderTitleText,
  FunChipsContainer,
  FunChipsBalanceText,
  ChipIcon,
  ReloadIcon,
  BackToPlatformButton,
  RedDotIcon,
  HomeTitleText,
} from "./headerStyles";
import Button from "../../../Common/components/Button/Button";
import colors from "../../../Common/styleGuide/Colors";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import { footerTabConstants } from "../../routes/PokerLobby/LobbyConstants";
import GameAudio from "../../audio/GameAudio";
import { useDispatch, useSelector } from "react-redux";
import {
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { openModal } from "../../redux/slices/modalSlice.js";
import { requestFunChipsReload } from "../../redux/slices/lobbySlice.js";
import { FooterTabConstants } from "../Footer/constants.js";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_CashFunToggle_Click } from "../../data/ClevertapConstants";
import { getGameSlice } from "../../utils/ReduxUtils";
import { HeaderIcon, HomePageTitle } from "../../routes/PokerLobby/HomeStyle";

function Header(props) {
  const {
    userBalance,
    isCashGamesToggled,
    handleCashAndPracticeGameToggle,
    onHeaderA23LogoClick,
    currentTab,
    channel,
  } = props;
  const dispatch = useDispatch();
  const onlinePlayerCount = useSelector(
    (state) => state.lobby.onlinePlayerCount
  );
  const notificationsCount = useSelector(
    (state) => state.lobby.notificationsCount
  );
  const playerData = useSelector((state) => state.lobby.player);
  const isPseudoLockedpLayer =
    playerData.playerStatus === Constants.PSEUDO_LOCKED_PLAYER;
  const activeGames = useSelector((state) =>
    Object.keys(state.gameTable.games)
  );
  const isPlaySoundEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.sound
  );
  const isHapticsEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.haptics
  );

  const handleAddCash = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "AddCash_Click",
      eventProperties: {
        gameType: "Poker",
        channel: channel,
        clickLocation: "CashLobby",
        currentCashBalance: userBalance.totalBalance,
        timeStamp: timeStamp,
        userName: playerData.userName,
      },
    });
    if (!playerData?.isAllowLocation) {
      return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    }
    messageToNativeClient({ type: "openaddcash" });
  };

  const handleWallet = () => {
    messageToNativeClient({ type: "openMyWallet", isOpened: true });
  };

  const handleToggleChange = () => {
    GameAudio.getInstance().playAudio(
      !isPlaySoundEnabled,
      Constants.BUTTON_SOUND,
      true,
      false
    );
    let timeStamp = getISTDateTimeFormat();

    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_CashFunToggle_Click",
      eventProperties: {
        userName: playerData.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        timeStamp: timeStamp,
        clickLocation: !isCashGamesToggled ? "Cash" : "Practice",
      },
    });
    CleverTapUtil.getInstance().logEvent(IP_Client_CashFunToggle_Click, {
      Username: playerData.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      Timestamp: timeStamp,
      "Click location": isCashGamesToggled ? "Cash" : "Fun",
    });
    try {
      if (isHapticsEnabled) {
        GameAudio.getInstance().playVibration(Constants.VIBRATE_LIGHT);
      }
    } catch (e) {
      console.log("Error at play haptic on cash and fun toggle");
    }
    handleCashAndPracticeGameToggle(!isCashGamesToggled);
  };

  const handleFunChipsReload = () => {
    if (userBalance.playChips < Constants.MIN_RELOAD_CHIPS_LIMIT) {
      dispatch(requestFunChipsReload());
    } else {
      dispatch(
        openModal({
          type: Constants.RESTRICT_FUN_CHIPS_RELOAD,
        })
      );
    }
  };

  const handleLogoClick = () => {};

  const renderDynamicHeaderItem = () => {
    const { home, games, tourneys, leaderboard, rules } = footerTabConstants;
    switch (currentTab) {
      case footerTabConstants.games:
        return Constants.FUN_CHANNELS.includes(channel) ? null : (
          <CashAndPracticeGamesWrapper id='cash-fun-toggle'>
            <CashAndPracticeGameToggleWrapper
              onClick={handleToggleChange}
              isActive={isCashGamesToggled}
            >
              <CashAndPracticeItemText>Cash</CashAndPracticeItemText>
            </CashAndPracticeGameToggleWrapper>
            <CashAndPracticeGameToggleWrapper
              onClick={handleToggleChange}
              isActive={!isCashGamesToggled}
            >
              <CashAndPracticeItemText>Practice</CashAndPracticeItemText>
            </CashAndPracticeGameToggleWrapper>
          </CashAndPracticeGamesWrapper>
        );
      case tourneys:
        return <DynamicHeaderTitleText>TOURNAMENTS</DynamicHeaderTitleText>;
      case leaderboard:
        return <DynamicHeaderTitleText>LEADERBOARD</DynamicHeaderTitleText>;
      case rules:
        return <DynamicHeaderTitleText>LEADERBOARD</DynamicHeaderTitleText>;
      case home:
        return <DynamicHeaderTitleText>HOME</DynamicHeaderTitleText>;
      // (
      //   // <div
      //   //   style={{
      //   //     display: "flex",
      //   //     flexDirection: "row",
      //   //     justifyContent: 'flex-start',
      //   //     alignItems: "center",
      //   //     gap: "6px",
      //   //   }}
      //   // >
      //     {/* <img src={homeIcon} style={{ height: "16px", position: 'relative', bottom: '2px' }}></img> */}
      //   // </div>
      // );
      default:
        return <div></div>;
    }
  };

  const handleLogout = () => {
    let gamesLength = Object.keys(getGameSlice().games);
    if (gamesLength?.length > 0) {
      dispatch(openModal({ type: Constants.LOGOUT_WARNING }));
    } else {
      messageToNativeClient({ type: "closeClient" });
    }
  };

  const handleNotificationIconClick = () => {
    messageToNativeClient({ type: "openNotifications" });
  };

  return (
    <HeaderWrapper id='header-wrapper'>
      <LogoAndLiveCountContainer>
        <A23LogoWrapper onClick={handleLogoClick} src={A23PokerLogo} />
        <NotificationAndLiveCountContainer>
          <>
            {notificationsCount && notificationsCount > 0 ? (
              <RedDotIcon />
            ) : null}
            <NotificationIcon
              src={notificationIconSource}
              onClick={handleNotificationIconClick}
            />
          </>

          {onlinePlayerCount > 0 && (
            <LivePlayerCountWrapper>
              <PlayerIcon src={goldPlayerIcon} />
              <LiveCountText>{onlinePlayerCount}</LiveCountText>
            </LivePlayerCountWrapper>
          )}
        </NotificationAndLiveCountContainer>
      </LogoAndLiveCountContainer>
      <AddCashAndDynamicHeaderItemContainer>
        {renderDynamicHeaderItem()}
        {(isCashGamesToggled || currentTab === FooterTabConstants.tourneys) &&
        !Constants.FUN_CHANNELS.includes(channel) ? (
          <WalletAndAddCashCTAWrapper>
            <WalletWrapper
              isPseudoLockedpLayer={isPseudoLockedpLayer}
              onClick={handleWallet}
            >
              <WalletIcon
                src={isPseudoLockedpLayer ? greyLockIcon : goldWalletIcon}
              />
              <WalletAmountText
                id='wallet-amount'
                isPseudoLockedpLayer={isPseudoLockedpLayer}
              >{`₹${parseDecimalValuesOfNumber(
                userBalance.totalBalance,
                2
              )}`}</WalletAmountText>
            </WalletWrapper>
            <Button
              id='add-cash-header'
              clickHandler={handleAddCash}
              buttonText={"Cash"}
              bgColor={colors.ctaPrimaryColor1}
              showIcon={true}
              iconUrl={addIcon}
            />
          </WalletAndAddCashCTAWrapper>
        ) : (
          <FunChipsContainer>
            <ChipIcon src={funChipsIcon} />
            <FunChipsBalanceText>{userBalance.playChips}</FunChipsBalanceText>
            <ReloadIcon src={reloadChipsIcon} onClick={handleFunChipsReload} />
          </FunChipsContainer>
        )}
      </AddCashAndDynamicHeaderItemContainer>
    </HeaderWrapper>
  );
}

export default Header;
