import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const MaintenanceModalWrapper = styled.div`
    width: 90%;
    max-width: 450px;
    height: fit-content;
    padding: 1rem;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
`

export const MaintenanceWrapper = styled.div`
    padding: 16px 12px;
    color: white;
    background: ${(props) => props.betLevel ? 'transparent' : colors.deepBlue};
    /* background: linear-gradient(171.2deg, #075063 0%, #222222 100%),
linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); */
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-radius: 8px;
    border: 1px solid ${(props) => props.betLevel ? 'transparent' : colors.primaryBlue};
`

export const MaintenanceMainText = styled.div`
    color: ${colors.primaryGold};
    text-shadow: 0px 2px 2px black;
    font-size: 16px;
    font-weight: bold;
    width: 90%;
    text-align: center;
    margin: 14px 0px;
`

export const MaintenanceSubText = styled.div`
    color: white;
    font-size: 12px;
    width: 75%;
    text-align: center;
`