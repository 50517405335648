import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ACCOUNT_ISSUE_GAME_JOIN,
  CONNECTION_REQUESTS_LIMIT_REACHED,
  DATA_LOST_ISSUE_GAME_JOIN,
  FOLD_CONFIRM,
  GAME_SOCKET_CONNECTED,
  INSUFFICIENT_BALANCE,
  INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
  INSUFFICIENT_FUN_CHIPS_BALANCE,
  LEAVE_SEAT,
  LEAVE_TABLE,
  LOGOUT_WARNING,
  MAX_FUN_GAMES_LIMIT,
  MAX_TABLE_LIMIT,
  MIN_RELOAD_CHIPS_LIMIT,
  RELOGIN_FAILURE,
  RESTRICTED_ACCESS,
  RESTRICTED_LOCATION_GAME_EXIT,
  RESTRICT_FUN_CHIPS_RELOAD,
  TOURNEY_ADDON,
  TOURNEY_FAIRPLAY_POLICY_RESTRICTION,
  TOURNEY_LATE_REGISTRATION_SUCCESS,
  TOURNEY_WATCH_GAME_COMPLETED,
  WALLET_BALANCE_ISSUE_GAME_JOIN,
  TOURNEY_CANCEL_WITH_REFUND,
  TOURNEY_CANCEL_WITH_HOLD,
  TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH,
  JOIN_FAILURE_ACCOUNT_DELETED,
} from "../../../../Poker/data/Constants";
import GoldArrowIcon from "../../../../assets/lobby/GoldArrowIcon.svg";
import GoldAddIcon from "../../../../assets/lobby/GoldAddIcon.svg";
import GoldTicketLarge from "../../../../assets/lobby/GoldTicketLarge.png";

import Reload from "../../../../assets/gameTable/Reload.svg";
import WhiteChipIcon from "../../../../assets/gameTable/WhiteChipIcon.svg";

import {
  closeFailedGameTable,
  closeLocalGameModal,
  closeTourneyAddOnPopup,
  requestTourneyAddOnTopup,
} from "../../../../Poker/redux/slices/gameTableSlice";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil";
import ConfirmationPopupUI from "../ConfirmationPopupUI/ConfirmationPopupUI";
import {
  InsufficientFundsText,
  BalanceDetailsWrapper,
  BalanceAmountText,
  BalanceDetailsContainer,
  InsufficientFundsBucketDeductionDetailsWrapper,
  TotalBalanceWrapper,
  TotalBalanceText,
  EachDeductionDetailWrapper,
  DeductionLableText,
  BonusCappingText,
  BalanceAmountToAdd,
  ArrowIconWrapper,
  AddIconWrapper,
  CustomButtonWrapper,
  ChipIcon,
  ReloadIcon,
  ButtonText,
  TourneyAddOnDetailsWrapper,
  TourneyDetailsText,
  TourneyDescriptionText,
  TourneyAddOnDetailsContainer,
  TourneyDetailsLabelTextWrapper,
  TourneyDetailsValueWrapper,
  EachAddOnDetailsWrapper,
  ColumnText,
  ChipsToBeGivenText,
  TicketDetailsWrapper,
  TicketIcon,
  TicketDescriptionText,
  TourneyNameText,
  tourneyTicketReceivedPopupCustomStyles,
} from "./confirmStyles";
import TableUtils from "../../../../Poker/utils/TableUtils";
import GameConnectionsN from "../../../../Poker/io/GameConnectionsN";
import {
  sendCheckProtocol,
  sendFoldProtocol,
  sendLeaveSeatProtocol,
} from "../../../../Poker/utils/ProtocolUtils";
import { parseDecimalValuesOfNumber } from "../../../utils/GameUtils";
import {
  closeTourneyInfoPage,
  requestFunChipsReload,
  updateLobbyCurrentTab,
  updateShowAllTicketsSection,
} from "../../../../Poker/redux/slices/lobbySlice";
import { closeModal } from "../../../../Poker/redux/slices/modalSlice";
import { FooterTabConstants } from "../../../../Poker/components/Footer/constants";

function Confirm(props) {
  const { type, handleModalClose, tempTableId, modalConfig } = props;
  const dispatch = useDispatch();
  const playerDetails = useSelector((state) => state.lobby.player);
  const channel = useSelector((state) => state.lobby.channel);
  const playerName = useSelector((state) => state.lobby.player.userName);
  const totalBalance = useSelector((state) => state.lobby.balance.totalBalance);
  const gameData = useSelector((state) => state.gameTable.games[tempTableId]);
  const playerCards = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerCards
  );
  const playerData = useSelector((state) =>
    state.gameTable.games[tempTableId]?.playerData.find(
      (item) => item.position === state.gameTable?.mySeat
    )
  );

  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameConnectionStatus
  );
  // const playerTurn = useSelector((state) => state.gameTable.games[tempTableId]?.playerTurn);
  const showDefaultActions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showDefaultActions
  );

  const tourneyAddOnDetails = useSelector(
    (state) => state.gameTable.games[tempTableId]?.tourneyAddOnDetails
  );

  const isTourneyAddOnRequestInProgress = useSelector(
    (state) =>
      state.gameTable.games[tempTableId]?.isTourneyAddOnRequestInProgress
  );

  const userBalance = useSelector((state) => state.lobby.balance);

  const [isBucketDetailsExpanded, setIsBucketDetailsExpanded] = useState(false);

  const handleCloseModal = () => {
    dispatch(
      closeLocalGameModal({
        tempTableId: tempTableId,
      })
    );
  };

  useEffect(() => {
    // This is to automatically close the fold confirm modal if he does not perform any action and turn changes.
    if (type === FOLD_CONFIRM) {
      if (showDefaultActions === false) {
        dispatch(
          closeLocalGameModal({
            tempTableId: tempTableId,
          })
        );
      }
    }
  }, [showDefaultActions]);

  const primaryCTAAction = () => {
    console.log("Type in primary cta action: ", type);
    switch (type) {
      case RESTRICTED_ACCESS:
        handleAddCash();
        handleModalClose();
        break;
      case LEAVE_TABLE:
        console.log("gameConnectionStatus: ", gameConnectionStatus);
        // if (gameConnectionStatus !== GAME_CONNECTION_PROTOCOL_SUCCESS) {
        //   console.log("dispatching close failed game table");
        //   dispatch(closeFailedGameTable({ tempTableId: tempTableId }));
        // } else {
        //   if (Object.keys(games).length === 1) {
        //     dispatch(hideGameTable());
        //   }
        //   console.log("dispatching exit game table");
        //   dispatch(
        //     exitGameTable({ tempTableId: tempTableId, playerName: playerName })
        //   );
        // }
        console.log("Before table observer: ", tempTableId);
        console.log("Fetching instance: ", TableUtils.getInstance());
        console.log(
          "Fetching observer: ",
          TableUtils.getInstance().getTableObserver(tempTableId)
        );
        let tableObserver =
          TableUtils.getInstance().getTableObserver(tempTableId);
        console.log("tableObserver:", tableObserver);
        console.log(
          "Table observer game connection status: ",
          tableObserver.getGameConnectionStatus()
        );
        if (tableObserver.getGameConnectionStatus() !== GAME_SOCKET_CONNECTED) {
          console.log("Dispatching close failed game table");
          // dispatch(closeFailedGameTable({ tempTableId: tempTableId }));
          // GameConnectionsN.getInstance().removeGameConnection(tempTableId);
          TableUtils.getInstance().removeTableObserver(tempTableId); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method
        } else {
          // if (Object.keys(games).length === 1) {
          //   dispatch(hideGameTable());
          // }
          console.log("dispatching exit game table");
          let gameConnection =
            GameConnectionsN.getInstance().getGameConnection(tempTableId);
          if (gameConnection) {
            gameConnection.listener.close();
          } else {
            //Note: As game connection is not available we are not restricting user from eixt table, we manually deleting the table and making him exit.
            TableUtils.getInstance()?.removeTableObserver(tempTableId);
          }
        }
        // if (gameConnectionStatus !== GAME_CONNECTION_PROTOCOL_SUCCESS) {
        //   dispatch(closeFailedGameTable({ tempTableId: tempTableId }));
        // } else {
        //   if (Object.keys(games).length === 1) {
        //     dispatch(hideGameTable());
        //   }
        //   console.log("dispatching exit game table");
        //   dispatch(
        //     exitGameTable({ tempTableId: tempTableId, playerName: playerName })
        //   );
        // }
        break;
      case "join-failure":
        dispatch(
          closeFailedGameTable({
            tempTableId: tempTableId,
          })
        );
        break;
      case LEAVE_SEAT:
        // dispatch(
        //   leaveTableSeat({ tempTableId: tempTableId, playerName: playerName })
        // );
        sendLeaveSeatProtocol({
          tempTableId: tempTableId,
          tableId: gameData.tableId,
          gameId: gameData.gameId,
          playerName: playerName,
        });
        break;
      case FOLD_CONFIRM:
        // dispatch(
        //   toggleCheckAction({ tempTableId: tempTableId, playerName: playerName })
        // );
        sendCheckProtocol({
          tempTableId: tempTableId,
          playerName: playerName,
        });
        break;
      case RELOGIN_FAILURE:
        TableUtils.getInstance().removeTableObserver(tempTableId); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method
        // dispatch(
        //   handleReloginFailure({
        //     tempTableId: props.tempTableId,
        //   })
        // );
        break;
      case LOGOUT_WARNING:
        dispatch(closeModal());
        break;
      case CONNECTION_REQUESTS_LIMIT_REACHED:
      case ACCOUNT_ISSUE_GAME_JOIN:
      case WALLET_BALANCE_ISSUE_GAME_JOIN:
      case DATA_LOST_ISSUE_GAME_JOIN:
        messageToNativeClient({
          type: "openContactUs",
        });
        TableUtils.getInstance().removeTableObserver(tempTableId);
        break;
      case TOURNEY_WATCH_GAME_COMPLETED:
      case RESTRICTED_LOCATION_GAME_EXIT:
        return TableUtils.getInstance().removeTableObserver(tempTableId);
      case TOURNEY_CANCEL_WITH_HOLD:
      case TOURNEY_CANCEL_WITH_REFUND:
        return TableUtils.getInstance().removeTableObserver(tempTableId);
      case TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH:
        handleModalClose();
        dispatch(
          updateLobbyCurrentTab({ selectedTab: FooterTabConstants.tourneys })
        );
        dispatch(closeTourneyInfoPage());
        dispatch(updateShowAllTicketsSection({ isOpen: true }));
        return;
      case JOIN_FAILURE_ACCOUNT_DELETED:
        handleModalClose();
        return;
      default:
        handleModalClose();
    }
    handleCloseModal();
    return;
  };

  const handleTourneyAddOnCTA = () => {
    const { userName, userId } = playerDetails;
    dispatch(
      requestTourneyAddOnTopup({
        playerName: userName,
        userId: userId,
        channel: channel,
      })
    );
  };

  const secondaryCTAAction = () => {
    console.log("Secondary Modal CTA ACTION!! ");
    switch (type) {
      case RESTRICTED_ACCESS:
        console.log("INSIDE RESTRICTED ACCESS MODAL!");
        handleModalClose();
        break;
      case FOLD_CONFIRM:
        // dispatch(
        //   toggleFoldAction({ tempTableId: tempTableId, playerName: playerName })
        // );
        //hitting protocol directly @yashwanth
        sendFoldProtocol({
          tempTableId: tempTableId,
          playerName: playerName,
        });
        break;
      case INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT:
        // dispatch(
        //   leaveTableSeat({ tempTableId: tempTableId, playerName: playerName })
        // );
        sendLeaveSeatProtocol({
          tempTableId: tempTableId,
          tableId: gameData.tableId,
          gameId: gameData.gameId,
          playerName: playerName,
        });
        dispatch(
          closeLocalGameModal({
            tempTableId: tempTableId,
          })
        );
        break;
      case CONNECTION_REQUESTS_LIMIT_REACHED:
      case ACCOUNT_ISSUE_GAME_JOIN:
      case WALLET_BALANCE_ISSUE_GAME_JOIN:
      case DATA_LOST_ISSUE_GAME_JOIN:
        return TableUtils.getInstance().removeTableObserver(tempTableId);
    }
    handleCloseModal();
  };

  const handleAddCash = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_CashFunToggle_Click",
      eventProperties: {
        userName: playerDetails.userName,
        channel: channel,
        currentCashBalance: totalBalance,
        timeStamp: timeStamp,
        clickLocation: "InsufficientBalance",
      },
    });
    messageToNativeClient({
      type: "openaddcash",
    });
    dispatch(
      closeLocalGameModal({
        tempTableId: tempTableId,
      })
    );
  };

  const handleBucketDetailsExpansion = (event) => {
    setIsBucketDetailsExpanded(!isBucketDetailsExpanded);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFunChipsReload = () => {
    dispatch(requestFunChipsReload());
  };

  const handleTourneyAddonCancle = () => {
    dispatch(handleModalClose());
    dispatch(
      closeTourneyAddOnPopup({
        tempTableId: tempTableId,
      })
    );
  };

  const getConfirmByType = (confirmType) => {
    switch (confirmType) {
      case LEAVE_TABLE:
        return (
          <ConfirmationPopupUI
            headerTitle={"Exit Table"}
            title={"Are you sure you want to exit the table?"}
            description={
              !playerData?.isFolded &&
              playerCards?.length > 0 &&
              !gameData.isWinnerDeclared &&
              "Exiting now will fold your current hand."
            }
            secondaryCTAText={"Cancel"}
            primaryCTAText={"Yes"}
            onSecondaryCTAClick={secondaryCTAAction}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case LEAVE_SEAT:
        return (
          <ConfirmationPopupUI
            headerTitle={"Leave Seat"}
            title={"Are you sure you want to leave seat?"}
            secondaryCTAText={"No"}
            primaryCTAText={"Yes"}
            onSecondaryCTAClick={secondaryCTAAction}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case FOLD_CONFIRM:
        return (
          <ConfirmationPopupUI
            headerTitle={"Fold Confirm"}
            title={
              <>
                You have an option to check.
                <br /> Are you sure you want to fold?
              </>
            }
            secondaryCTAText={"Fold"}
            primaryCTAText={"Check"}
            onSecondaryCTAClick={secondaryCTAAction}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case INSUFFICIENT_BALANCE:
        return (
          <ConfirmationPopupUI
            headerTitle={
              modalConfig?.blindsText
                ? `Insufficient Balance ${modalConfig.blindsText}`
                : "Insufficient Balance"
            }
            title={
              <InsufficientFundsText>
                Your balance is too low to join this table. Add Cash to continue
                playing!
                <BalanceDetailsContainer>
                  {modalConfig?.amountToAdd ||
                  gameData?.gameDefinition?.minBuyInAmount ? (
                    <>
                      <BalanceDetailsWrapper>
                        {props.isTourneyGame
                          ? `To join this tourney add`
                          : `To play on this table add`}
                      </BalanceDetailsWrapper>
                      <BalanceAmountText onClick={handleBucketDetailsExpansion}>
                        ₹
                        {modalConfig?.amountToAdd
                          ? parseDecimalValuesOfNumber(modalConfig?.amountToAdd)
                          : gameData?.gameDefinition?.minBuyInAmount
                          ? parseDecimalValuesOfNumber(
                              Math.abs(
                                gameData?.gameDefinition?.minBuyInAmount -
                                  totalBalance
                              ),
                              2
                            )
                          : null}
                        {modalConfig && modalConfig?.amountToAdd && (
                          <ArrowIconWrapper
                            isOpen={isBucketDetailsExpanded}
                            src={GoldArrowIcon}
                          />
                        )}
                      </BalanceAmountText>
                    </>
                  ) : null}
                </BalanceDetailsContainer>
                {modalConfig && modalConfig?.amountToAdd && (
                  <InsufficientFundsBucketDeductionDetailsWrapper
                    isOpen={isBucketDetailsExpanded}
                  >
                    <TotalBalanceWrapper>
                      <TotalBalanceText>{`Total Balance : ₹${modalConfig?.totalBalance}`}</TotalBalanceText>
                    </TotalBalanceWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText>
                        {modalConfig?.isTourneyGame
                          ? `Entry fee`
                          : `Buy-in amount`}
                      </DeductionLableText>
                      <DeductionLableText>{`₹${modalConfig?.buyInAmount} `}</DeductionLableText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText>
                        Deposit + Winnings
                      </DeductionLableText>
                      <DeductionLableText>
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig?.depositAndWinnings,
                          2
                        )}`}
                      </DeductionLableText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <BonusCappingText>Usable Bonus Cash</BonusCappingText>
                      <BonusCappingText>
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig?.usableBonus,
                          2
                        )}`}
                      </BonusCappingText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText></DeductionLableText>
                      <BalanceAmountToAdd>
                        <AddIconWrapper src={GoldAddIcon} />{" "}
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig.amountToAdd,
                          2
                        )}`}
                      </BalanceAmountToAdd>
                    </EachDeductionDetailWrapper>
                  </InsufficientFundsBucketDeductionDetailsWrapper>
                )}
              </InsufficientFundsText>
            }
            primaryCTAText={"Add Cash"}
            onPrimaryCTAClick={handleAddCash}
            handleCloseModal={handleModalClose}
          />
        );
      case RELOGIN_FAILURE:
        return (
          <ConfirmationPopupUI
            // headerTitle={"Oops!"}
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                {/* This table no longer exists. <br></br>
                Any amount debited will be credited back to your A23 Wallet. */}
                This table no longer exists
              </InsufficientFundsText>
            }
            // primaryCTAText={"Go to lobby"}
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case RESTRICTED_ACCESS:
        return (
          <ConfirmationPopupUI
            // headerTitle={"Oops!"}
            headerTitle={"Premium Access"}
            title={
              <InsufficientFundsText>
                This feature is available only for premium players. Add cash now
                for a premium gaming experience on A23.
              </InsufficientFundsText>
            }
            // primaryCTAText={"Go to lobby"}
            primaryCTAText={"Add Cash"}
            secondaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            onSecondaryCTAClick={secondaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case LOGOUT_WARNING:
        return (
          <ConfirmationPopupUI
            // headerTitle={"Oops!"}
            headerTitle={"Exit"}
            title={
              <InsufficientFundsText>
                {/* This table no longer exists. <br></br>
                Any amount debited will be credited back to your A23 Wallet. */}
                Please exit active game windows before you exit from the current
                screen
              </InsufficientFundsText>
            }
            // primaryCTAText={"Go to lobby"}
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case CONNECTION_REQUESTS_LIMIT_REACHED:
      case ACCOUNT_ISSUE_GAME_JOIN:
      case WALLET_BALANCE_ISSUE_GAME_JOIN:
      case DATA_LOST_ISSUE_GAME_JOIN:
        return (
          <ConfirmationPopupUI
            // headerTitle={"Oops!"}
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                {confirmType === CONNECTION_REQUESTS_LIMIT_REACHED
                  ? "Failed to Process request. Contact Support Team"
                  : confirmType === ACCOUNT_ISSUE_GAME_JOIN
                  ? "Looks like there's an issue with your account. Contact Support Team"
                  : confirmType === WALLET_BALANCE_ISSUE_GAME_JOIN
                  ? "Looks like there's an issue with your wallet balance. Contact Support Team"
                  : confirmType ===
                    "Looks like the data got lost in the filing cabinet. We'll find it, contact Support Team"}
              </InsufficientFundsText>
            }
            // primaryCTAText={"Go to lobby"}
            primaryCTAText={"Report issue"}
            secondaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            onSecondaryCTAClick={secondaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case RESTRICTED_LOCATION_GAME_EXIT:
        return (
          <ConfirmationPopupUI
            // headerTitle={"Oops!"}
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                Oops! <br></br>
                It seems like you're trying to play poker from a blocked state.
                We're sorry for the inconvenience, but due to legal
                restrictions, access to real-money poker games is currently
                unavailable in your location.
              </InsufficientFundsText>
            }
            // primaryCTAText={"Go to lobby"}
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={() => null}
          />
        );
      case INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT:
        return (
          <ConfirmationPopupUI
            headerTitle={
              modalConfig?.blindsText
                ? `Insufficient Balance ${modalConfig.blindsText}`
                : "Insufficient Balance"
            }
            title={
              <InsufficientFundsText>
                Your balance is not enough to play on this table. Please reload
                to enjoy games!
                <BalanceDetailsContainer>
                  {modalConfig?.amountToAdd ||
                  gameData?.gameDefinition?.minBuyInAmount ? (
                    <>
                      <BalanceDetailsWrapper>
                        {props.isTourneyGame
                          ? `To join this tourney add`
                          : `To play on this table add`}
                      </BalanceDetailsWrapper>
                      <BalanceAmountText onClick={handleBucketDetailsExpansion}>
                        ₹
                        {modalConfig?.amountToAdd
                          ? parseDecimalValuesOfNumber(modalConfig?.amountToAdd)
                          : gameData?.gameDefinition?.minBuyInAmount
                          ? parseDecimalValuesOfNumber(
                              Math.abs(
                                gameData?.gameDefinition?.minBuyInAmount -
                                  totalBalance
                              ),
                              2
                            )
                          : null}
                        {modalConfig && modalConfig?.amountToAdd && (
                          <ArrowIconWrapper
                            isOpen={isBucketDetailsExpanded}
                            src={GoldArrowIcon}
                          />
                        )}
                      </BalanceAmountText>
                    </>
                  ) : null}
                </BalanceDetailsContainer>
                {modalConfig && modalConfig?.amountToAdd && (
                  <InsufficientFundsBucketDeductionDetailsWrapper
                    isOpen={isBucketDetailsExpanded}
                  >
                    <TotalBalanceWrapper>
                      <TotalBalanceText>{`Total Balance : ₹${modalConfig?.totalBalance}`}</TotalBalanceText>
                    </TotalBalanceWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText>
                        {modalConfig?.isTourneyGame
                          ? `Entry fee`
                          : `Buy-in amount`}
                      </DeductionLableText>
                      <DeductionLableText>{`₹${modalConfig?.buyInAmount} `}</DeductionLableText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText>
                        Deposit + Winnings
                      </DeductionLableText>
                      <DeductionLableText>
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig?.depositAndWinnings,
                          2
                        )}`}
                      </DeductionLableText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <BonusCappingText>Usable Bonus Cash</BonusCappingText>
                      <BonusCappingText>
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig?.usableBonus,
                          2
                        )}`}
                      </BonusCappingText>
                    </EachDeductionDetailWrapper>
                    <EachDeductionDetailWrapper>
                      <DeductionLableText></DeductionLableText>
                      <BalanceAmountToAdd>
                        <AddIconWrapper src={GoldAddIcon} />{" "}
                        {`₹${parseDecimalValuesOfNumber(
                          modalConfig.amountToAdd,
                          2
                        )}`}
                      </BalanceAmountToAdd>
                    </EachDeductionDetailWrapper>
                  </InsufficientFundsBucketDeductionDetailsWrapper>
                )}
              </InsufficientFundsText>
            }
            secondaryCTAText={"Leave Seat"}
            primaryCTAText={"Add Cash"}
            onSecondaryCTAClick={secondaryCTAAction}
            onPrimaryCTAClick={handleAddCash}
            handleCloseModal={handleModalClose}
          />
        );
      case INSUFFICIENT_FUN_CHIPS_BALANCE:
        return (
          <ConfirmationPopupUI
            headerTitle={"No Chips left"}
            title={
              <InsufficientFundsText>
                Please reload chips to continue playing
              </InsufficientFundsText>
            }
            primaryCTAText={"5000"}
            onPrimaryCTAClick={handleFunChipsReload}
            handleCloseModal={handleModalClose}
            customPrimaryButton={() => (
              <CustomButtonWrapper>
                <ChipIcon src={WhiteChipIcon} />
                <ButtonText>5000</ButtonText>
                <ReloadIcon src={Reload} />
              </CustomButtonWrapper>
            )}
          />
        );
      case RESTRICT_FUN_CHIPS_RELOAD:
        return (
          <ConfirmationPopupUI
            headerTitle={"Request Failed"}
            title={
              <InsufficientFundsText>
                {`Sorry! You can add fun chips when your balance falls below ${MIN_RELOAD_CHIPS_LIMIT}.`}
              </InsufficientFundsText>
            }
            handleCloseModal={handleModalClose}
            primaryCTAText={"OK"}
            onPrimaryCTAClick={handleModalClose}
          />
        );
      case TOURNEY_FAIRPLAY_POLICY_RESTRICTION:
        return (
          <ConfirmationPopupUI
            headerTitle={"Registration Failed"}
            title={
              <InsufficientFundsText>
                {`We are unable to register you to the tournament due to our fair play policy. Apologies for the inconvenience caused.`}
              </InsufficientFundsText>
            }
            handleCloseModal={handleModalClose}
            primaryCTAText={"OK"}
            onPrimaryCTAClick={handleModalClose}
          />
        );
      case TOURNEY_LATE_REGISTRATION_SUCCESS:
        return (
          <ConfirmationPopupUI
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                {`Registered Successfully!! Seat will be allotted soon.`}
              </InsufficientFundsText>
            }
            handleCloseModal={handleModalClose}
            primaryCTAText={"OK"}
            onPrimaryCTAClick={handleModalClose}
          />
        );
      case MAX_TABLE_LIMIT:
        return (
          <ConfirmationPopupUI
            headerTitle={"Tables limit"}
            title={
              <InsufficientFundsText>
                You have reached the maximum number of tables.
                <BalanceDetailsContainer>
                  <BalanceAmountText>
                    Leave atleast one table to join a new one.
                  </BalanceAmountText>
                </BalanceDetailsContainer>
              </InsufficientFundsText>
            }
            primaryCTAText={"Yes"}
            onPrimaryCTAClick={handleModalClose}
            handleCloseModal={handleModalClose}
          />
        );
      case MAX_FUN_GAMES_LIMIT:
        return (
          <ConfirmationPopupUI
            headerTitle={"Tables limit"}
            title={
              <InsufficientFundsText>
                You can only play one fun game at a time.
                <BalanceDetailsContainer>
                  <BalanceAmountText>
                    Leave atleast one of them to join a new one.
                  </BalanceAmountText>
                </BalanceDetailsContainer>
              </InsufficientFundsText>
            }
            primaryCTAText={"Yes"}
            onPrimaryCTAClick={handleModalClose}
            handleCloseModal={handleModalClose}
          />
        );
      case TOURNEY_ADDON:
        if (tourneyAddOnDetails) {
          const { addOnEntryFee, chipsToBeGiven } = tourneyAddOnDetails;
          return (
            <ConfirmationPopupUI
              headerTitle={"Add-On"}
              title={
                <TourneyAddOnDetailsWrapper>
                  <TourneyDetailsText>
                    Tournament is on break
                  </TourneyDetailsText>
                  <TourneyDescriptionText>{`Would you like to add-on to your balance ?`}</TourneyDescriptionText>
                  <TourneyAddOnDetailsContainer>
                    <EachAddOnDetailsWrapper>
                      <TourneyDetailsLabelTextWrapper>
                        Total Balance
                      </TourneyDetailsLabelTextWrapper>
                      <ColumnText>:</ColumnText>
                      <TourneyDetailsValueWrapper>{`₹${totalBalance}`}</TourneyDetailsValueWrapper>
                    </EachAddOnDetailsWrapper>
                    <EachAddOnDetailsWrapper>
                      <TourneyDetailsLabelTextWrapper>
                        Add-On amount
                      </TourneyDetailsLabelTextWrapper>
                      <ColumnText>:</ColumnText>
                      <TourneyDetailsValueWrapper>
                        {`₹${addOnEntryFee}`}
                        <ChipsToBeGivenText>{`(${chipsToBeGiven})`}</ChipsToBeGivenText>
                      </TourneyDetailsValueWrapper>
                    </EachAddOnDetailsWrapper>
                  </TourneyAddOnDetailsContainer>
                </TourneyAddOnDetailsWrapper>
              }
              secondaryCTAText={"Cancel"}
              primaryCTAText={
                totalBalance >= addOnEntryFee ? "Yes" : "Add Cash"
              }
              onSecondaryCTAClick={handleTourneyAddonCancle}
              onPrimaryCTAClick={
                totalBalance >= addOnEntryFee
                  ? handleTourneyAddOnCTA
                  : handleAddCash
              }
              handleCloseModal={handleTourneyAddonCancle}
              isLoading={isTourneyAddOnRequestInProgress}
            />
          );
        } else if (tourneyAddOnDetails === null) {
          handleCloseModal();
          return;
        }
      case TOURNEY_WATCH_GAME_COMPLETED:
        return (
          <ConfirmationPopupUI
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                This table has ended
              </InsufficientFundsText>
            }
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case TOURNEY_CANCEL_WITH_REFUND:
        return (
          <ConfirmationPopupUI
            headerTitle={"Heads Up! Tournament Cancelled"}
            title={
              "There's a hiccup with the tournament, but no worries—refunds are being processed!"
            }
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            // handleCloseModal={handleModalClose}
          />
        );
      case TOURNEY_CANCEL_WITH_HOLD:
        return (
          <ConfirmationPopupUI
            headerTitle={"Heads Up! Tournament Cancelled"}
            title={
              "There's a hiccup with the tournament. But no worries, you will be updated very soon about the makeup tournament details!"
            }
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            // handleCloseModal={handleModalClose}
          />
        );
      case TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH:
        return (
          <ConfirmationPopupUI
            headerTitle={"Congratulations"}
            headerCustomStyles={tourneyTicketReceivedPopupCustomStyles}
            title={
              <TicketDetailsWrapper>
                <TicketIcon src={GoldTicketLarge} />
                <TicketDescriptionText>
                  You have received a ticket to
                </TicketDescriptionText>
                {modalConfig && (
                  <TourneyNameText>{modalConfig?.tourneyName}</TourneyNameText>
                )}
              </TicketDetailsWrapper>
            }
            primaryCTAText={"Go to My Tickets"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
      case JOIN_FAILURE_ACCOUNT_DELETED:
        return (
          <ConfirmationPopupUI
            headerTitle={"Info"}
            title={
              <InsufficientFundsText>
                Your account has been blocked due to unfair play. For more
                information, please contact support.
              </InsufficientFundsText>
            }
            primaryCTAText={"Close"}
            onPrimaryCTAClick={primaryCTAAction}
            handleCloseModal={handleModalClose}
          />
        );
    }
  };

  return getConfirmByType(type);
}

export default Confirm;
