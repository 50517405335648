import { engineVersion, isAndroid, isIOS, mobileModel, osName, osVersion } from "react-device-detect";

export function getDeviceAndConnectionDetails() {
    let dataObject = {};

    if ("connection" in navigator) {
        dataObject.connectionType = navigator.connection.type;
        dataObject.connectionEffectiveType = navigator.connection.effectiveType;
    }
    if ("serviceWorker" in navigator) {
        if (navigator.serviceWorker.controller) {
            // dataObject.serviceWorkerScriptURL = navigator.serviceWorker.controller.scriptURL; //commented as it could be sensitive in terms of security
            dataObject.serviceWorkerScriptState = navigator.serviceWorker.controller.state;
        }
    }
    dataObject.deviceName = mobileModel;
    dataObject.deviceOSname = osName;
    dataObject.deviceOSVersion = osVersion;
    dataObject.webViewVersion= engineVersion;
    return dataObject;
}