import Handler from "../Handler";

class TopupFailureHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTopupFailure(receivedData);
  }
}

export default TopupFailureHandler;

// TopUpF#
