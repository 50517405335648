import Handler from "../Handler";

class PreSelectedAutoAction extends Handler {
  execute(message) {
    //PreSelectedAutoAction#{"preSelectedAction": "CHECK"}
    let receivedData = JSON.parse(message);
    this.service.processPreSelectedAutoAction({
      preSelectedAction: `${receivedData.preSelectedAction}`,
    });
  }
}

export default PreSelectedAutoAction;
