import React from "react";
import { ButtonIcon, ButtonWrapper, Button } from "./gameInfoButtonStyle";

export const GameInfoButton = (props) => {
  return (
    <ButtonWrapper
      onClick={() => props.clickHandler()}
      top={props.top}
      left={props.left}
      right={props.right}
      bottom={props.bottom}
      width={props.width}
      position={props.position}
      id={props.id}
    >
      <Button backgroundColor={props.backgroundColor} disabled={props.isDisabled}>
        <ButtonIcon src={props.iconSrc}></ButtonIcon>
      </Button>
    </ButtonWrapper>
  );
};
