import Handler from "../Handler";

class GameDefinitionUpdateHandler extends Handler {
  constructor(service) {
    super(service);
  }
  execute(data) {
    /**
     * gu#{
     *     "orderId":0,"gameDefId":31,"gameMode":"real","poolId":"31","gameType":"TEXAS_HOLDEM","tableName":"finalTest",
     *     "flavourType":"real","smallBlind":38.0,"bigBlind":76.0,"isWatchPlayerEnable":false,"tableTime":10,"nextGameTimer":0,
     *     "minBuyInAmount":380.0,"maxbuyInAmount":760.0,"isRITEnabled":false,"maxPlayers":6,"maxJoinAndWatchPlayers":0,
     *     "maxWatchPlayersCount":0,"maxJoinWaitListCount":0,"isWaitListEnabled":false,"isSplitEnabled":false,
     *     "minPlayersToStartGame":2,"isEnabled":true,"status":"Active","maxTables":0,"onlineCount":0,
     *     "channelApplicable":["IPAPS","IPIPS","PKAPS","PKIPS","PIPS","PAPS"],"isStraddleEnabled":false,"isSNG":false,
     *     "sgtExtraTime":0,"sgtETCount":0,"rake":2.0,"headsUpRake":3.0,"sitOutTimeInMins":5,"reserveTime":5,
     *     "rakeCapForStartPlayersLETo3":4.0,"rakeCapForStartPlayersGT3":5.0,"limitType":"NL","buyInTimer":0,"rebuyInTimer":0,
     *     "timeBank":0,"isRankSuggestionEnabled":false,"anonymous":false,"leaderboard":false,"turbo":false,"prizePotLimit":"NL",
     *     "gameUpdateType":"new","bonusPercentage":100
     *    }
     */
    let receivedData = JSON.parse(data);
    this.service.processGameDefinitionUpdateData(receivedData);
  }
}

export default GameDefinitionUpdateHandler;
