import Handler from "../Handler";

class TourneyAutoPlayHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyAutoPlay(receivedData);
  }
}

export default TourneyAutoPlayHandler;
