//blindsUpHandler.js
import Handler from "../Handler";

class BlindsUpHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processBlindsUpHandler(receivedData);
  }
}

export default BlindsUpHandler;
// BlindsUp#{"bb":2.0,"ante":2.0,"level":2,"sb":2.0,"tourneyId":"XtmBR"}
