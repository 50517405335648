import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { OMAHA5, TEXAS_HOLDEM } from "../../../../Poker/data/Constants";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
} from "../modalStyle";
import {
  InfoDetail,
  InfoDivider,
  InfoTitle,
  InfoWrapper,
} from "./informationStyles";

export const Information = (props) => {
  const gameDefinition = useSelector(
    (state) => state.gameTable.games[props.tempTableId].gameDefinition
  );

  const createInfoDetails = () => {
    return (
      <>
        <InfoWrapper>
          <InfoTitle>Type</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>
            {gameDefinition.gameType === TEXAS_HOLDEM
              ? "Texas Hold'em"
              : gameDefinition.gameType === OMAHA5
              ? "Omaha 5"
              : "Omaha"}
          </InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Limit</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>
            {gameDefinition.limitType === "NL"
              ? "No Limit"
              : gameDefinition.limitType === "FL"
              ? "Fixed Limit"
              : gameDefinition.limitType === "PL"
              ? "Pot Limit"
              : null}
          </InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Stakes</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>
            {gameDefinition.smallBlind} / {gameDefinition.bigBlind}
          </InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Turn Time</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.tableTime}s</InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Time Bank</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.timeBank}s</InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Rake</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.rake}%</InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Rake {`(HU)`}</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.headsUpRake}%</InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Cap {`(<=3)`}</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.rakeCapForStartPlayersLETo3}</InfoDetail>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>Cap {`(>3)`}</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>{gameDefinition.rakeCapForStartPlayersGT3}</InfoDetail>
        </InfoWrapper>
        {/* <InfoWrapper>
        <InfoTitle>Anti Banking</InfoTitle>
        <InfoDivider>:</InfoDivider>
        <InfoDetail>1m</InfoDetail>
      </InfoWrapper> */}
      </>
    );
  };

  return (
    <ModalWrapper>
      <ModalHeader>
        <Typo16WhiteContoBoldText>Information</Typo16WhiteContoBoldText>
        <CloseButton tempTableId={props.tempTableId} top={10}></CloseButton>
      </ModalHeader>
      <ModalBody>{createInfoDetails()}</ModalBody>
    </ModalWrapper>
  );
};
