import styled from "styled-components";
import colors from "../../styleGuide/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  /* If layout issues occur, check the bottom line */
  min-height: 100vh;
  overflow: hidden;
  animation: animate-poker-lobby 0.1s forwards;

  @keyframes animate-poker-lobby {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const HeaderWrapper = styled.div`
  /* display: ${(props) => (props.isWebView ? "none" : "flex")}; */
  /* visibility: hidden; */
  flex-direction: row;
  width: 100%;
  height: 96px;
  background-color: #032028;

  @media (min-width: 768px) {
    // height: 0px;
  }
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: ${(props) =>
    props.isWebView
      ? `var(--app-height)`
      : `calc(var(--app-height) - 156px)`}; */
  height: 100%;
  overflow: hidden;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  min-height: 70px;
  justify-content: space-around;
  align-items: center;
  /* box-shadow: 3px -3px 8px 0px rgba(0, 0, 0, 0.25); */
  // background: ${colors.secondaryDeepBlue};
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(180deg, #336070 0%, rgba(7, 80, 99, 0.6) 100%),
    linear-gradient(0deg, #000000, #000000); */
  background: rgb(51, 96, 112, 0.4);
  box-shadow: 3px -3px 8px 0px #00000040;

  // border-top: 1px solid ${colors.secondaryDeepBlue};
`;

export const MoreSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  border: 1px solid yellow;
`;
