import React from "react";
import {
  InfoIconContainer,
  TakeSeatContainer,
  TakeSeatText,
} from "./AlertStyle";
import infoIcon from "../../../assets/gameTable/infoIcon.svg";

const TakeSeatAlert = ({ orientation }) => {
  return (
    <TakeSeatContainer orientation={orientation}>
      <InfoIconContainer src={infoIcon}></InfoIconContainer>
      <TakeSeatText>Select a seat to Start Playing</TakeSeatText>
    </TakeSeatContainer>
  );
};

export default TakeSeatAlert;
