import styled from "styled-components";
import { TOURNEY_RANKING_LEADERBOARD } from "../../../Poker/data/Constants";

export const GlobalSlider = styled.div`
  width: var(--app-width);
  height: var(--app-height);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  height: var(--app-height);
`;

export const DarkScreen = styled.div`
  background-color: ${(props) =>
    props.sliderType === "Hand History" ||
    props.sliderType === "Table Stats" ||
    props.sliderType === "Consolidated Menu" ||
    props.sliderType === TOURNEY_RANKING_LEADERBOARD
      ? "#0000008f"
      : null};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
