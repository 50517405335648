import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import goldGloryIcon from "../../../assets/lobby/goldGloryIcon.png";
import tourneyVerticalSeperator from "../../../assets/lobby/TourneyVerticalSeperator.svg";
import tourneyHorizontalSeperator from "../../../assets/lobby/TourneyHorizontalSeperator.svg";
import CloseIcon from "../../../assets/lobby/CloseIcon.svg";
import Subtract from "../../../assets/gameTable/Subtract.svg";
import maskPlayerIcon from "../../../assets/lobby/MaskPlayerIcon.png";

import GrayAccordianIcon from "../../../assets/lobby/GrayAccordianIcon.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import breakCoffeeIcon from "../../../assets/lobby/BreakCoffeeIcon.png";
import RightLeafyGreenArrow from "../../../assets/lobby/RightLeafyGreenArrow.png";
import grayInfoIcon from "../../../assets/lobby/GrayInfoIcon.png";

import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { ScrollDownArrow } from "../../../Common/components/ScrollDownArrow/ScrollDownArrow";

import {
  SIT_AND_GO_TOURNEYS,
  TICKETS,
  TICKET_BASED,
  TIMER_TOURNEYS,
  TOURNEY_ANNOUNCED,
  TOURNEY_PLAYER_STATUS_CANCELLED,
  TOURNEY_PLAYER_STATUS_COMPLETED,
  TOURNEY_PLAYER_STATUS_MISSED,
  TOURNEY_REGISTERED,
  TOURNEY_REGISTRATION_CLOSE_BEFORE_START_IN_SECONDS,
  TOURNEY_REGISTRATION_ENDED,
  TOURNEY_REGISTRATION_STARTED,
  TOURNEY_STATUS_CANCELLED,
  TOURNEY_STATUS_COMPLETED,
  TOURNEY_STATUS_LIVE,
  TOURNEY_STATUS_MISSED,
  TOURNEY_STATUS_WON,
} from "../../data/Constants";
import {
  updateTourneyInBreak,
  updateTourneyRegistrationCTAButtonsHide,
} from "../../redux/slices/lobbySlice";

import TourneyTileCard from "../TourneyTileCard/TourneyTileCard";

import {
  TourneyDetailsContainer,
  TourneyDetailsWrapper,
  TourneyStartsInAndPrizeAmountWrapper,
  PrizeAmountContainer,
  TrophyIconWrapper,
  PrizeAmountWrapper,
  PrizeWorthText,
  PrizeAmountText,
  PrizeBreakupText,
  TourneyDetailTable,
  TourneyBuyInAndWinnersWrapper,
  BuyInWrapper,
  BuyInText,
  BuyInAmountText,
  VerticalSeperator,
  PlayersWrapper,
  PlayersText,
  PlayersCountText,
  WinnersWrapper,
  WinnersText,
  WinnersCountText,
  HorizontalSeperator,
  TotalAddonsAndRebuyWrapper,
  TableItemText,
  AddOnsAndRebuyItemWrapper,
  AddOnAndRebuyAmountDetailWrapper,
  RebuyAmountText,
  UnlimitedRebuysText,
  ViewBlindStructureText,
  LateRegistrationWrapper,
  GoldOpenIcon,
  LateRegistrationText,
  TourneyStatsContainer,
  TourneyStatsText,
  TourneyStatItemWrapper,
  TourneyStatItemName,
  TourneyStatItemValue,
  ModalContainer,
  PrizeBreakUpModalContainer,
  PrizeBreakupHeader,
  PrizeBreakupHeaderText,
  PrizeBreakupBodyWrapper,
  CloseIconWrapper,
  TableWrapper,
  TableHeaderWrapper,
  HeaderItemText,
  TableRow,
  TableRowItemText,
  BlindStructureContainer,
  BlindStructureHeader,
  BlindStructureText,
  BlindStructureBodyWrapper,
  TourneyRulesContainer,
  RulesListOrderedWrapper,
  RulesListItemWrapper,
  DescriptionLabelText,
  AccordianIcon,
  MaxRunTimeText,
  SitAndGoMessageText,
  ChildTourneysText,
  ChildTourneysContainer,
  ViewMoreOrLessWrapper,
  ExpansionPanelText,
  ExpansionAccordianIcon,
  TicketIcon,
  AddIconText,
  ParentTourneysText,
  ParentTourneysContainer,
  BlindStructureHeaderItemText,
  BlindStructureTableRow,
  BlindStructureTableHeaderWrapper,
  BlindStructureTableRowItemText,
  TicketsCountText,
  RebuyAmountValue,
  DynamicPayoutStructureText,
  TourneyDetailsTableHeader,
  TourneyDetailsTableBodyWrapper,
  TourneyDetailsText,
  BlindsRightArrow,
  TourneyStatsTableBodyWrapper,
  TourneyStatsTableHeader,
  TourneyTimersContainer,
  TourneyTimersWrapper,
  CurrentTimerTitle,
  RunningTimeWrapper,
  TimePeriodAndPeriodNameWrapper,
  TimePeriodWrapper,
  PeriodNameText,
  PeriodValueText,
  LateRegistrationTimerText,
  GreenActiveIcon,
  ActivePlayersCountDetails,
  ActivePlayerCountText,
  RemainingText,
  ReEntriesWrapper,
  ReEntriesText,
  ReEntryCountText,
  TourneyOnBreakContainer,
  CoffeeIconWrapper,
  TourneyBreakMessageText,
  BreakTimeText,
  PlayerIconWrapper,
  DepositorTourneyEntriesWrapper,
  DirectEntriesWrapper,
  GrayInfoIconWrapper,
  TicketEntriesWrapper,
  DirectEntriesAndTicketEntriesCountText,
  TooltipText,
  TooltipArrow,
  TooltipWrapper,
} from "./tourneyDetailsStyles";

function TourneyDetails(props) {
  const {
    buyIn,
    tourneyRake,
    registeredPlayerCount,
    maxPlayers,
    displayWinners,
    totalAddOns,
    addOnAmount,
    rebuyFee,
    startingStack,
    playersPerTable,
    prizePool,
    currentLevel,
    nextLevel,
    nextBreak,
    highestStack,
    blindStructure,
    tourneyStartTime,
    tourneyStatus,
    lateRegistrationAllowed,
    maxRebuyCount,
    tourneyRules,
    winnersPayoutWithBonus,
    breakEven,
    dynamicPoolPrize,
    tournamentType,
    lateRegistrationDuration,
    rebuyLevel,
    childTourneys,
    prizePoolTickets,
    lateRegEnded,
    reEntry,
    parentTourney,
    levelDuration,
    totalAddOnCount,
    totalRebuyCount,
    totalReEntryCount,
    isAlreadyRegisteredTourney,
    tourneyId,
    prizePoolType,
    payoutType,
    hideRegistrationCTAButtons,
    tourneyIdentifier,
    activePlayerForTourney,
    breakInProgress,
    breakEndTime,
    averageChips,
    lateRegEndTime,
    depositTourneyFlag,
    allowedFreeRegistrations,
    allowedTicketRegistrations,
  } = props.tourneyDetails;
  const dispatch = useDispatch();

  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const [openPrizeBreakupModal, setOpenPrizeBreakupModal] = useState(false);
  const [openBlindStructure, setOpenBlindStructure] = useState(false);
  const [remainingStartTime, setRemainingStartTime] = useState(-1);

  const [timerId, setTimerId] = useState(null);
  const [isRulesExpansionPanelOpen, setIsRulesExpansionPanelOpen] =
    useState(false);
  const [isChildTourneysExpansionOpened, setIsChildTourneysExpansionOpened] =
    useState(false);
  const [showDirectEntriesTooltip, setShowDirectEntriesTooltip] =
    useState(false);

  const [showTicketEntriesTooltip, setShowTicketEntriesTooltip] =
    useState(false);
  const [tooltipTimeout, setToolTipTimeout] = useState(null);

  const upcomingTourneys = useSelector(
    (state) => state.lobby.tourneys.upcomingTourneys
  );
  const completedTourneys = useSelector(
    (state) => state.lobby.tourneys.completedTourneys
  );
  const myTourneys = useSelector((state) => state.lobby.tourneys.myTourneys);
  const [lateRegEndTimestamp, setLateRegEndTimestamp] = useState(0);
  const [breakTimeInSeconds, setBreakTimeInSeconds] = useState(breakEndTime);

  const lateRegEndTimerIntervalRef = useRef(null);
  const breakTimeIntervalRef = useRef(null);

  useEffect(() => {
    handleLateRegisterTimer();
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      handleTourneyStatusLiveTimer();
    } else {
      handleTourneyRemainingTimeToStartTimer();
    }

    if (getChildTourneysDetails().length < 2) {
      setIsChildTourneysExpansionOpened(true);
    }

    return () => {
      clearInterval(timerId);
      if (lateRegEndTimerIntervalRef?.current) {
        clearInterval(lateRegEndTimerIntervalRef.current);
        lateRegEndTimerIntervalRef.current = null;
      }
      if (breakTimeIntervalRef?.current) {
        clearInterval(breakTimeIntervalRef.current);
        breakTimeIntervalRef.current = null;
      }
      clearRunningTooltipTimer();
    };
  }, []);

  useEffect(() => {
    handleTourneyBreakTimer();
  }, [breakInProgress]);

  useEffect(() => {
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      getRunningTimeInSeconds();
    } else {
      if (
        remainingStartTime > 0 &&
        remainingStartTime <
          TOURNEY_REGISTRATION_CLOSE_BEFORE_START_IN_SECONDS &&
        !hideRegistrationCTAButtons
      ) {
        dispatch(updateTourneyRegistrationCTAButtonsHide({ isHidden: true }));
      }
      getRemainingTimeInSeconds();
    }
  }, [remainingStartTime]);

  useEffect(() => {
    if (
      tourneyStatus === TOURNEY_STATUS_LIVE &&
      !lateRegEnded &&
      lateRegEndTime > 0
    ) {
      runLateRegisterEndsInTimer();
    }

    // Cleanup the interval on unmount or when dependencies change
    return () => {
      if (lateRegEndTimerIntervalRef.current) {
        clearInterval(lateRegEndTimerIntervalRef.current);
      }
    };
  }, [tourneyStatus, lateRegEnded, lateRegEndTime]);

  useEffect(() => {
    if (
      breakInProgress &&
      breakTimeInSeconds > 0 &&
      !breakTimeIntervalRef?.current
    ) {
      runBreakEndsInTimer();
    }
    if (breakTimeInSeconds <= 0) {
      if (breakTimeIntervalRef.current) {
        clearInterval(breakTimeIntervalRef.current);
        breakTimeIntervalRef.current = null;
      }
      dispatch(
        updateTourneyInBreak({
          breakInProgress: false,
          breakStartTime: 0,
          breakEndTime: 0,
        })
      );
    }
  }, [breakTimeInSeconds]);

  useEffect(() => {
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      if (timerId) {
        clearInterval(timerId);
        setTimerId(null);
      }
      handleTourneyStatusLiveTimer();
      if (
        tourneyStatus === TOURNEY_STATUS_LIVE &&
        !lateRegEnded &&
        lateRegEndTime > 0
      ) {
        handleLateRegisterTimer();
        runLateRegisterEndsInTimer();
      }
    } else if (!timerId) {
      handleTourneyRemainingTimeToStartTimer();
    }
  }, [tourneyStatus]);

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const masterTourneysDefinitions = [
      ...myTourneys,
      ...upcomingTourneys,
      ...completedTourneys,
    ];

    let tourney = masterTourneysDefinitions.find(
      (tourney) => tourney.tourneyId === tourneyId
    );
    return tourney ? tourney : false;
  };

  const handleTourneyStatusLiveTimer = () => {
    const difference = currentLobbyTime - new Date(tourneyStartTime * 1000);
    setRemainingStartTime(Math.floor(difference / 1000));
  };

  const handleTourneyRemainingTimeToStartTimer = () => {
    const difference = new Date(tourneyStartTime * 1000) - currentLobbyTime;
    setRemainingStartTime(Math.floor(difference / 1000));
  };

  const handleTourneyBreakTimer = () => {
    const difference = new Date(breakEndTime * 1000) - currentLobbyTime;
    setBreakTimeInSeconds(Math.floor(difference / 1000));
  };

  const handleLateRegisterTimer = () => {
    if (lateRegEndTime > 0 && tourneyStatus === TOURNEY_STATUS_LIVE) {
      const difference = new Date(lateRegEndTime * 1000) - currentLobbyTime;
      setLateRegEndTimestamp(Math.floor(difference / 1000));
    } else {
      const difference =
        new Date(lateRegEndTime * 1000) - new Date(tourneyStartTime * 1000);
      setLateRegEndTimestamp(Math.floor(difference / 1000));
    }
  };

  const getRemainingTimeInSeconds = () => {
    if (!timerId) {
      let intervalTimerId = setInterval(() => {
        setRemainingStartTime((prev) => prev - 1);
      }, 1000);
      setTimerId(intervalTimerId);
    }
  };

  const getRunningTimeInSeconds = () => {
    if (!timerId) {
      let intervalTimerId = setInterval(() => {
        setRemainingStartTime((prev) => prev + 1);
      }, 1000);
      setTimerId(intervalTimerId);
    }
  };

  const handleToolTipTimer = () => {
    let tooltipTimer = setTimeout(() => {
      setShowDirectEntriesTooltip(false);
      setShowTicketEntriesTooltip(false);
      clearRunningTooltipTimer();
    }, 3000);
    setToolTipTimeout(tooltipTimer);
  };

  const clearRunningTooltipTimer = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
      setToolTipTimeout(null);
    }
  };

  const runLateRegisterEndsInTimer = () => {
    // Clear any existing interval
    if (lateRegEndTimerIntervalRef.current) {
      clearInterval(lateRegEndTimerIntervalRef.current);
    }

    // Start a new interval
    lateRegEndTimerIntervalRef.current = setInterval(() => {
      setLateRegEndTimestamp((prev) => {
        if (prev <= 1) {
          clearInterval(lateRegEndTimerIntervalRef.current);
          lateRegEndTimerIntervalRef.current = null;
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const runBreakEndsInTimer = () => {
    if (!breakTimeIntervalRef?.current) {
      breakTimeIntervalRef.current = setInterval(() => {
        setBreakTimeInSeconds((prev) => prev - 1);
      }, 1000);
    }
  };
  const convertTimeToMinutesAndSeconds = (timeInSeconds, showUnits = false) => {
    if (timeInSeconds > 0) {
      if (timeInSeconds < 60) {
        //seconds
        return showUnits ? `${timeInSeconds} sec` : timeInSeconds;
      } else if (timeInSeconds >= 60) {
        //Minutes
        let mins = Math.floor(timeInSeconds / 60);
        return showUnits ? `${mins} min` : mins;
      }
    } else {
      return 0;
    }
  };

  const getRunningTimerDetails = (runningTime) => {
    const timePeriods = [];
    if (runningTime >= 0) {
      if (runningTime === 0) {
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            { timePeriod: "00", periodName: "Min" },
            { timePeriod: "00", periodName: "Sec" },
          ]
        );
      } else if (runningTime < 60) {
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            { timePeriod: "00", periodName: "Min" },
            {
              timePeriod: `${
                runningTime > 9 ? runningTime : `0${runningTime}`
              }`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime >= 60 && runningTime < 3600) {
        let mins = Math.floor((runningTime % 3600) / 60);
        let secs = runningTime % 60;
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
            {
              timePeriod: `${secs > 9 ? secs : `0${secs}`}`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime >= 3600 && runningTime < 86400) {
        let hrs = Math.floor(runningTime / 3600);
        let mins = Math.floor((runningTime % 3600) / 60);
        let secs = runningTime % 60;
        timePeriods.push(
          ...[
            {
              timePeriod: `${hrs > 9 ? hrs : `0${hrs}`}`,
              periodName: "Hrs",
            },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
            {
              timePeriod: `${secs > 9 ? secs : `0${secs}`}`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime > 86400) {
        let days = Math.floor(runningTime / 86400);
        let hrs = Math.floor((runningTime % 86400) / 3600);
        let mins = Math.floor(((runningTime % 86400) % 3600) / 60);
        timePeriods.push(
          ...[
            {
              timePeriod: `${days > 9 ? days : `0${days}`}`,
              periodName: "Days",
            },
            {
              timePeriod: `${hrs > 9 ? hrs : `0${hrs}`}`,
              periodName: "Hrs",
            },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
          ]
        );
      }
    } else if (runningTime <= 0) {
      timePeriods.push(
        ...[
          { timePeriod: "00", periodName: "Hrs" },
          { timePeriod: "00", periodName: "Min" },
          { timePeriod: "00", periodName: "Sec" },
        ]
      );
    }

    return timePeriods.map((eachTimePeriod) => (
      <TimePeriodAndPeriodNameWrapper key={eachTimePeriod.periodName}>
        <TimePeriodWrapper>
          <PeriodValueText>{eachTimePeriod.timePeriod}</PeriodValueText>
        </TimePeriodWrapper>
        <PeriodNameText>{eachTimePeriod.periodName}</PeriodNameText>
      </TimePeriodAndPeriodNameWrapper>
    ));
  };

  const handleOpenPrizeBreakupModal = () => {
    setOpenPrizeBreakupModal(true);
  };

  const handleClosePrizeBreakup = () => {
    setOpenPrizeBreakupModal(false);
  };

  const handleOpenBlindStructure = () => {
    setOpenBlindStructure(true);
  };

  const handleCloseBlindStructure = () => {
    setOpenBlindStructure(false);
  };

  const handleRulesAccordian = () => {
    setIsRulesExpansionPanelOpen(!isRulesExpansionPanelOpen);
  };

  const getChildTourneysDetails = () => {
    let childTourneysList = [];
    childTourneys.forEach((eachTourneyId) => {
      const childTourneyDetails =
        getTourneyDefinitionByTourneyId(eachTourneyId);
      if (childTourneyDetails) {
        childTourneysList.push({ ...childTourneyDetails });
      }
    });
    const sortedTourneysList = [...childTourneysList].sort(
      (currentTourney, nextTourney) => {
        // Check if either or both tourneys are CANCELLED
        const isCurrentCancelled =
          currentTourney.tourneyStatus === TOURNEY_STATUS_CANCELLED;
        const isNextCancelled =
          nextTourney.tourneyStatus === TOURNEY_STATUS_CANCELLED;

        // If only one is CANCELLED, move it to the end
        if (isCurrentCancelled && !isNextCancelled) return 1;
        if (!isCurrentCancelled && isNextCancelled) return -1;
        // Otherwise, sort by tourneyStartTime (descending)
        return nextTourney.tourneyStartTime - currentTourney.tourneyStartTime;
      }
    );
    return sortedTourneysList;
  };

  const renderTourneyRules = () => {
    let tourneyRulesList = tourneyRules.split("\n");
    if (tourneyRulesList.length > 0) {
      let rules = tourneyRulesList.map((eachRule, index) => (
        <RulesListItemWrapper key={`eachRule-${index}`}>
          {eachRule}
        </RulesListItemWrapper>
      ));

      return (
        <>
          <TourneyRulesContainer
            isOpen={isRulesExpansionPanelOpen}
            rulesLength={tourneyRulesList.length}
          >
            <DescriptionLabelText>Tourney Rules:</DescriptionLabelText>
            <RulesListOrderedWrapper>{rules}</RulesListOrderedWrapper>
          </TourneyRulesContainer>
          {/* Hiding the accordian for the shorter content which height is less thanb 92px , here 28px is consider as each item average height */}
          {tourneyRulesList.length * 28 > 92 && (
            <AccordianIcon
              onClick={handleRulesAccordian}
              isOpen={isRulesExpansionPanelOpen}
              src={Subtract}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const handleDirectEntriesInfoIconClick = () => {
    if (tooltipTimeout !== null) {
      clearRunningTooltipTimer();
      setShowTicketEntriesTooltip(false);
    }
    setShowDirectEntriesTooltip(true);
    handleToolTipTimer();
  };

  const handleTicketEntriesInfoIconClick = () => {
    if (tooltipTimeout !== null) {
      clearRunningTooltipTimer();
      setShowDirectEntriesTooltip(false);
    }
    setShowTicketEntriesTooltip(true);
    handleToolTipTimer();
  };

  const getTimerTextBasedOnTourneyStage = (currentTourneyStatus) => {
    switch (currentTourneyStatus) {
      case TOURNEY_STATUS_LIVE:
        if (remainingStartTime >= 0) {
          if (!lateRegEnded && lateRegEndTime > 0) {
            return "Late registration ends in ";
          }
          return "Running since";
        } else {
          return "About to start";
        }
      case TOURNEY_ANNOUNCED:
      case TOURNEY_REGISTRATION_STARTED:
      case TOURNEY_REGISTRATION_ENDED:
        if (remainingStartTime >= 0) {
          return "Starts In";
        } else {
          return "About to start";
        }

      default:
        return "";
    }
  };

  const renderTourneyTimer = () => {
    if (
      tourneyIdentifier === "completedTourneys" ||
      tourneyStatus === TOURNEY_STATUS_MISSED
    ) {
      return;
    } else if (tourneyStatus !== TOURNEY_STATUS_MISSED) {
      if (
        tourneyStatus !== TOURNEY_STATUS_LIVE &&
        tournamentType === SIT_AND_GO_TOURNEYS
      ) {
        return;
      }
      return (
        <TourneyTimersContainer>
          <TourneyTimersWrapper>
            <CurrentTimerTitle>
              {getTimerTextBasedOnTourneyStage(tourneyStatus)}
            </CurrentTimerTitle>
            <RunningTimeWrapper>
              {tourneyStatus !== TOURNEY_STATUS_CANCELLED &&
                tourneyStatus !== TOURNEY_STATUS_COMPLETED &&
                getRunningTimerDetails(
                  tourneyStatus === TOURNEY_STATUS_LIVE &&
                    !lateRegEnded &&
                    lateRegEndTime > 0
                    ? lateRegEndTimestamp
                    : remainingStartTime
                )}
            </RunningTimeWrapper>
            {lateRegistrationAllowed && !lateRegEnded && (
              <LateRegistrationWrapper>
                <>
                  <GoldOpenIcon />
                  {tourneyStatus === TOURNEY_STATUS_LIVE &&
                  !lateRegEnded &&
                  lateRegEndTime > 0 ? (
                    <LateRegistrationText>
                      {`Started since `}
                      <LateRegistrationTimerText>{`${convertTimeToMinutesAndSeconds(
                        remainingStartTime,
                        true
                      )}`}</LateRegistrationTimerText>
                    </LateRegistrationText>
                  ) : (
                    <LateRegistrationText>
                      {`Late Registration available for `}
                      <LateRegistrationTimerText>{`${convertTimeToMinutesAndSeconds(
                        lateRegEndTimestamp,
                        true
                      )}`}</LateRegistrationTimerText>
                    </LateRegistrationText>
                  )}
                </>
              </LateRegistrationWrapper>
            )}
            {lateRegEnded && (
              <LateRegistrationWrapper>
                <>
                  {" "}
                  <GoldOpenIcon />
                  <LateRegistrationText>
                    {`Late registration ended`}
                  </LateRegistrationText>
                </>
              </LateRegistrationWrapper>
            )}
          </TourneyTimersWrapper>
        </TourneyTimersContainer>
      );
    }
  };

  return (
    <TourneyDetailsContainer id={`tourney-details-page-${tourneyId}`}>
      <TourneyDetailsWrapper>
        <TourneyStartsInAndPrizeAmountWrapper>
          <PrizeAmountContainer>
            <TrophyIconWrapper src={goldGloryIcon} />
            <PrizeAmountWrapper>
              <PrizeWorthText>Prizes Worth</PrizeWorthText>
              <PrizeAmountText>
                {/* if dynamicPoolPrize or prizePool is 0, we should not display it in the details */}
                {breakEven
                  ? dynamicPoolPrize > 0
                    ? `₹${parseDecimalValuesOfNumber(
                        dynamicPoolPrize,
                        2,
                        checkToDisplayValueInK(dynamicPoolPrize),
                        checkToDisplayValueInL(dynamicPoolPrize)
                      )}`
                    : ""
                  : prizePool > 0
                  ? `₹${parseDecimalValuesOfNumber(
                      prizePool,
                      2,
                      checkToDisplayValueInK(prizePool),
                      checkToDisplayValueInL(prizePool)
                    )}`
                  : ""}
                {prizePoolTickets > 0 && (
                  <>
                    {((breakEven && dynamicPoolPrize > 0) || prizePool > 0) && (
                      <AddIconText>+</AddIconText>
                    )}
                    <TicketsCountText>{prizePoolTickets}</TicketsCountText>
                    <TicketIcon src={goldTicket} />
                  </>
                )}
              </PrizeAmountText>
            </PrizeAmountWrapper>
          </PrizeAmountContainer>
          <PrizeBreakupText onClick={handleOpenPrizeBreakupModal}>
            Prize Breakup
          </PrizeBreakupText>
        </TourneyStartsInAndPrizeAmountWrapper>
        {tournamentType === TIMER_TOURNEYS && (
          <MaxRunTimeText>{`Maximum run time : ${props.tourneyDetails?.totalTimeForTimerTourney} minutes`}</MaxRunTimeText>
        )}
        {tournamentType === SIT_AND_GO_TOURNEYS &&
          tourneyStatus === TOURNEY_REGISTRATION_STARTED && (
            <SitAndGoMessageText>
              Tourney starts when all players join
            </SitAndGoMessageText>
          )}
        <TourneyDetailTable>
          <TourneyDetailsTableHeader>
            <TourneyDetailsText>Tourney Details</TourneyDetailsText>

            <ViewBlindStructureText onClick={handleOpenBlindStructure}>
              Blind Structure
              <BlindsRightArrow src={RightLeafyGreenArrow} />
            </ViewBlindStructureText>
          </TourneyDetailsTableHeader>
          <TourneyDetailsTableBodyWrapper>
            <TourneyBuyInAndWinnersWrapper>
              <BuyInWrapper>
                <BuyInText>Buy-In</BuyInText>
                <BuyInAmountText>
                  {tournamentType === TICKET_BASED
                    ? TICKETS
                    : buyIn + tourneyRake}
                </BuyInAmountText>
              </BuyInWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <PlayersWrapper>
                <PlayersText>Entries</PlayersText>
                <PlayersCountText>{`${parseDecimalValuesOfNumber(
                  registeredPlayerCount,
                  2,
                  checkToDisplayValueInK(registeredPlayerCount),
                  checkToDisplayValueInL(registeredPlayerCount)
                )}/${parseDecimalValuesOfNumber(
                  maxPlayers,
                  2,
                  checkToDisplayValueInK(maxPlayers),
                  checkToDisplayValueInL(maxPlayers)
                )}`}</PlayersCountText>
                {(tourneyStatus === TOURNEY_STATUS_LIVE ||
                  tourneyStatus === TOURNEY_STATUS_MISSED) &&
                tourneyIdentifier !== "completedTourneys" &&
                activePlayerForTourney > 0 ? (
                  <ActivePlayersCountDetails>
                    <PlayerIconWrapper src={maskPlayerIcon} />
                    <ActivePlayerCountText>
                      {`${parseDecimalValuesOfNumber(
                        activePlayerForTourney,
                        2,
                        checkToDisplayValueInK(activePlayerForTourney),
                        checkToDisplayValueInL(activePlayerForTourney)
                      )}`}
                    </ActivePlayerCountText>
                    <RemainingText>Active</RemainingText>
                  </ActivePlayersCountDetails>
                ) : null}
              </PlayersWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <ReEntriesWrapper>
                <ReEntriesText>Re-entries</ReEntriesText>
                <ReEntryCountText>
                  {totalReEntryCount > 0 ? totalReEntryCount : "-"}
                </ReEntryCountText>
              </ReEntriesWrapper>

              <VerticalSeperator src={tourneyVerticalSeperator} />
              <WinnersWrapper>
                <WinnersText>Winners</WinnersText>
                <WinnersCountText>
                  {parseDecimalValuesOfNumber(
                    displayWinners,
                    2,
                    checkToDisplayValueInK(displayWinners),
                    checkToDisplayValueInL(displayWinners)
                  )}
                </WinnersCountText>
              </WinnersWrapper>
            </TourneyBuyInAndWinnersWrapper>
            {(totalAddOns > 0 ||
              maxRebuyCount > 0 ||
              reEntry ||
              depositTourneyFlag) && (
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            )}
            <TotalAddonsAndRebuyWrapper>
              {depositTourneyFlag && (
                <DepositorTourneyEntriesWrapper>
                  <DirectEntriesWrapper>
                    <TableItemText>Direct Entries</TableItemText>
                    {showDirectEntriesTooltip && (
                      <TooltipWrapper>
                        <TooltipText>
                          No of direct registrations allowed
                        </TooltipText>
                        <TooltipArrow></TooltipArrow>
                      </TooltipWrapper>
                    )}
                    <GrayInfoIconWrapper
                      src={grayInfoIcon}
                      onClick={handleDirectEntriesInfoIconClick}
                    />
                    <DirectEntriesAndTicketEntriesCountText>
                      {allowedFreeRegistrations}
                    </DirectEntriesAndTicketEntriesCountText>
                  </DirectEntriesWrapper>
                  <TicketEntriesWrapper>
                    <TableItemText>Ticket Entries</TableItemText>
                    {showTicketEntriesTooltip && (
                      <TooltipWrapper tooltipPosition={"left"}>
                        <TooltipText>
                          No of tickets issues through add cash
                        </TooltipText>
                        <TooltipArrow tooltipPosition={"left"}></TooltipArrow>
                      </TooltipWrapper>
                    )}
                    <GrayInfoIconWrapper
                      src={grayInfoIcon}
                      onClick={handleTicketEntriesInfoIconClick}
                    />
                    <DirectEntriesAndTicketEntriesCountText>
                      {allowedTicketRegistrations}
                    </DirectEntriesAndTicketEntriesCountText>
                  </TicketEntriesWrapper>
                </DepositorTourneyEntriesWrapper>
              )}
              {totalAddOns > 0 && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>
                    {`Total Add-ons`}
                    <RebuyAmountValue>{totalAddOnCount} </RebuyAmountValue>
                  </TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Add-on fee </TableItemText>
                    <RebuyAmountText>{`₹${addOnAmount}`}</RebuyAmountText>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
              {maxRebuyCount > 0 && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>
                    {`Total Re-buys`}
                    <RebuyAmountValue>{`${totalRebuyCount}`}</RebuyAmountValue>
                  </TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Re-buy fees</TableItemText>
                    <RebuyAmountValue>{`₹${rebuyFee}`}</RebuyAmountValue>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
              {reEntry && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>{`Total Re-Entries  ${totalReEntryCount}`}</TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Re-entry fees</TableItemText>
                    <RebuyAmountText>{`₹${reEntry.reEntrySettings.reentryEntryFee}`}</RebuyAmountText>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
            </TotalAddonsAndRebuyWrapper>
            {maxRebuyCount > 0 && (
              <UnlimitedRebuysText>
                {`${maxRebuyCount} re-buys allowed till Level ${rebuyLevel}`}
              </UnlimitedRebuysText>
            )}
            {reEntry && (
              <UnlimitedRebuysText>
                {`${reEntry.reEntrySettings.noOfReentry} re-entries allowed till Level ${reEntry.reEntrySettings.reentryLevel}`}
              </UnlimitedRebuysText>
            )}
          </TourneyDetailsTableBodyWrapper>
        </TourneyDetailTable>
        {renderTourneyTimer()}

        {renderTourneyRules()}

        {parentTourney &&
          parentTourney.length > 0 &&
          getTourneyDefinitionByTourneyId(parentTourney) && (
            <ParentTourneysContainer isExpansionOpened={false}>
              <ParentTourneysText>Parent Tourney</ParentTourneysText>
              <TourneyTileCard
                tourneyDetails={getTourneyDefinitionByTourneyId(parentTourney)}
                hideStatusSection={false}
              />
            </ParentTourneysContainer>
          )}

        {childTourneys && getChildTourneysDetails().length > 0 && (
          <ChildTourneysContainer
            isExpansionOpened={isChildTourneysExpansionOpened}
          >
            <ChildTourneysText>Child Tourneys</ChildTourneysText>
            {getChildTourneysDetails().map((childTourneyDetails) => {
              return (
                <TourneyTileCard
                  tourneyDetails={childTourneyDetails}
                  hideStatusSection={true}
                />
              );
            })}
          </ChildTourneysContainer>
        )}
        {getChildTourneysDetails().length > 2 && (
          <ViewMoreOrLessWrapper
            onClick={() => {
              setIsChildTourneysExpansionOpened(
                !isChildTourneysExpansionOpened
              );
            }}
          >
            <ExpansionPanelText>View more</ExpansionPanelText>
            <ExpansionAccordianIcon
              isOpened={isChildTourneysExpansionOpened}
              src={GrayAccordianIcon}
            />
          </ViewMoreOrLessWrapper>
        )}
        <TourneyStatsContainer>
          <TourneyStatsTableHeader>
            <TourneyStatsText>Tourney Stats</TourneyStatsText>
          </TourneyStatsTableHeader>
          <TourneyStatsTableBodyWrapper>
            <TourneyStatItemWrapper>
              <TourneyStatItemName>Starting Stack</TourneyStatItemName>
              <TourneyStatItemValue>
                {parseDecimalValuesOfNumber(
                  startingStack,
                  2,
                  checkToDisplayValueInK(startingStack),
                  checkToDisplayValueInL(startingStack)
                )}
              </TourneyStatItemValue>
            </TourneyStatItemWrapper>
            <HorizontalSeperator src={tourneyHorizontalSeperator} />
            <TourneyStatItemWrapper>
              <TourneyStatItemName>Players Per Table</TourneyStatItemName>
              <TourneyStatItemValue>{`Max ${playersPerTable} Players`}</TourneyStatItemValue>
            </TourneyStatItemWrapper>
            {tourneyStatus === TOURNEY_STATUS_LIVE && (
              <>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Highest Stack</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {parseDecimalValuesOfNumber(
                      highestStack,
                      2,
                      checkToDisplayValueInK(highestStack),
                      checkToDisplayValueInL(highestStack)
                    )}
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Average Stack</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {parseDecimalValuesOfNumber(
                      averageChips,
                      2,
                      checkToDisplayValueInK(averageChips),
                      checkToDisplayValueInL(averageChips)
                    )}
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Current Level</TourneyStatItemName>
                  <TourneyStatItemValue>{currentLevel}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Next Level</TourneyStatItemName>
                  <TourneyStatItemValue>{nextLevel}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Next Break</TourneyStatItemName>
                  <TourneyStatItemValue>{nextBreak}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
              </>
            )}
          </TourneyStatsTableBodyWrapper>
        </TourneyStatsContainer>

        {breakInProgress && breakTimeInSeconds > 0 && (
          <TourneyOnBreakContainer>
            <CoffeeIconWrapper src={breakCoffeeIcon} />
            <TourneyBreakMessageText>
              Tournament is in currently on break for
            </TourneyBreakMessageText>
            <BreakTimeText>{`${convertTimeToMinutesAndSeconds(
              breakTimeInSeconds,
              true
            )}`}</BreakTimeText>
          </TourneyOnBreakContainer>
        )}
      </TourneyDetailsWrapper>
      {openPrizeBreakupModal && (
        <ModalContainer onClick={handleClosePrizeBreakup}>
          <PrizeBreakUpModalContainer
            isOpen={openPrizeBreakupModal}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <PrizeBreakupHeader>
              <PrizeBreakupHeaderText>Prize Breakup</PrizeBreakupHeaderText>
              <CloseIconWrapper
                src={CloseIcon}
                onClick={handleClosePrizeBreakup}
              />
            </PrizeBreakupHeader>
            <PrizeBreakupBodyWrapper>
              <TableWrapper>
                <TableHeaderWrapper>
                  <HeaderItemText>Position</HeaderItemText>
                  <HeaderItemText>Prizes</HeaderItemText>
                </TableHeaderWrapper>
                {winnersPayoutWithBonus &&
                  Object.keys(winnersPayoutWithBonus).map(
                    (eachPayout, index) => {
                      return (
                        <>
                          <TableRow>
                            <TableRowItemText>{`${
                              Object.keys(winnersPayoutWithBonus)[index]
                            }`}</TableRowItemText>
                            <TableRowItemText>
                              {winnersPayoutWithBonus[eachPayout]?.amount > 0 &&
                                `₹${parseDecimalValuesOfNumber(
                                  winnersPayoutWithBonus[eachPayout]?.amount,
                                  2,
                                  checkToDisplayValueInK(
                                    winnersPayoutWithBonus[eachPayout]?.amount
                                  ),
                                  checkToDisplayValueInL(
                                    winnersPayoutWithBonus[eachPayout]?.amount
                                  )
                                )}`}
                              {winnersPayoutWithBonus[`${eachPayout}`]?.bonus >
                                0 && (
                                <>
                                  {winnersPayoutWithBonus[eachPayout]?.amount >
                                    0 && <AddIconText>+</AddIconText>}
                                  {`₹${parseDecimalValuesOfNumber(
                                    winnersPayoutWithBonus[`${eachPayout}`]
                                      ?.bonus,
                                    2,
                                    checkToDisplayValueInK(
                                      winnersPayoutWithBonus[`${eachPayout}`]
                                        ?.bonus
                                    ),
                                    checkToDisplayValueInL(
                                      winnersPayoutWithBonus[`${eachPayout}`]
                                        ?.bonus
                                    )
                                  )}
                                  (Bonus)`}
                                </>
                              )}
                              {winnersPayoutWithBonus[`${eachPayout}`]
                                ?.ticket && (
                                <>
                                  {(winnersPayoutWithBonus[`${eachPayout}`]
                                    ?.amount > 0 ||
                                    winnersPayoutWithBonus[`${eachPayout}`]
                                      ?.bonus > 0) && (
                                    <AddIconText>+</AddIconText>
                                  )}
                                  <TicketIcon src={goldTicket} />
                                </>
                              )}
                            </TableRowItemText>
                          </TableRow>

                          <HorizontalSeperator
                            src={tourneyHorizontalSeperator}
                          />
                        </>
                      );
                    }
                  )}
              </TableWrapper>
              {prizePoolType === "Dynamic" && (
                <DynamicPayoutStructureText>{`Note: The prize breakdown may change based on the number of entries.`}</DynamicPayoutStructureText>
              )}
            </PrizeBreakupBodyWrapper>
          </PrizeBreakUpModalContainer>
        </ModalContainer>
      )}
      {openBlindStructure && (
        <ModalContainer onClick={handleCloseBlindStructure}>
          <BlindStructureContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <BlindStructureHeader>
              <BlindStructureText>Blind Structure</BlindStructureText>
              <CloseIconWrapper
                src={CloseIcon}
                onClick={handleCloseBlindStructure}
              />
            </BlindStructureHeader>
            <BlindStructureBodyWrapper>
              <TableWrapper>
                <BlindStructureTableHeaderWrapper>
                  <BlindStructureHeaderItemText textPosition={"flex-start"}>
                    Level
                  </BlindStructureHeaderItemText>
                  <BlindStructureHeaderItemText>
                    Blind
                  </BlindStructureHeaderItemText>
                  <BlindStructureHeaderItemText>
                    Ante
                  </BlindStructureHeaderItemText>
                  <BlindStructureHeaderItemText>
                    Minutes
                  </BlindStructureHeaderItemText>
                </BlindStructureTableHeaderWrapper>
                {blindStructure.map((eachLevel, index) => (
                  <>
                    <BlindStructureTableRow>
                      <BlindStructureTableRowItemText
                        textPosition={"flex-start"}
                      >
                        {eachLevel.level}
                      </BlindStructureTableRowItemText>
                      <BlindStructureTableRowItemText>{`${parseDecimalValuesOfNumber(
                        eachLevel.sb,
                        2,
                        checkToDisplayValueInK(eachLevel.sb),
                        checkToDisplayValueInL(eachLevel.sb)
                      )}/${parseDecimalValuesOfNumber(
                        eachLevel.bb,
                        2,
                        checkToDisplayValueInK(eachLevel.bb),
                        checkToDisplayValueInL(eachLevel.bb)
                      )}`}</BlindStructureTableRowItemText>
                      <BlindStructureTableRowItemText>
                        {parseDecimalValuesOfNumber(
                          eachLevel.ante,
                          2,
                          checkToDisplayValueInK(eachLevel.ante),
                          checkToDisplayValueInL(eachLevel.ante)
                        )}
                      </BlindStructureTableRowItemText>
                      <BlindStructureTableRowItemText>
                        {levelDuration > 0 ? levelDuration / 60 : levelDuration}
                      </BlindStructureTableRowItemText>
                    </BlindStructureTableRow>
                    {blindStructure.length !== index + 1 && (
                      <HorizontalSeperator src={tourneyHorizontalSeperator} />
                    )}
                    {blindStructure.length > 6 && (
                      <ScrollDownArrow bottom={16} right={20} />
                    )}
                  </>
                ))}
              </TableWrapper>
            </BlindStructureBodyWrapper>
          </BlindStructureContainer>
        </ModalContainer>
      )}
    </TourneyDetailsContainer>
  );
}

export default TourneyDetails;
