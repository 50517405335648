import Handler from "../Handler";

class AutoRebuyFailureHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoRebuyFailure(receivedData);
  }
}

export default AutoRebuyFailureHandler;

// AutoRebuyInF#{“status”:”FAILURE,”playerName":"angel459889","Amount”:0.0,”statusCode”:502,”buyInMessage”: “Insufficient funds,please add cash!”}
