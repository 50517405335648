import Handler from "../Handler";

class PlayerStatsSave extends Handler {
  execute(data) {
    let receivedData = JSON.parse(data);
    this.service.processSavePlayerStats(receivedData);
  }
}

export default PlayerStatsSave;
