//TourneyCompleteHandler.js

import Handler from "../Handler";

class TourneyInTheMoneyHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPlayerInTheMoneyDetails(receivedData);
  }
}
export default TourneyInTheMoneyHandler;

//TourneyInTheMoney#{"tourneyId":"QOlUk","playerLeft":2}
