import AllInAudioSource from "../../assets/sounds/allin.mp3";
import BetAudioSource from "../../assets/sounds/bet.mp3";
import ButtonAudioSource from "../../assets/sounds/btn.mp3";
import CallAudioSource from "../../assets/sounds/call.mp3";
import ChatAudioSource from "../../assets/sounds/chat.mp3";
import CheckAudioSource from "../../assets/sounds/check.mp3";
import DealAudioSource from "../../assets/sounds/deal.mp3";
import ExtraTimeAudioSource from "../../assets/sounds/ExtraTimeSound.mp3";
import FoldAudioSource from "../../assets/sounds/fold.mp3";
import PlayRegisterAudioSource from "../../assets/sounds/playregister.mp3";
import RaiseAudioSource from "../../assets/sounds/Raise.mp3";
import RaiseBarAudioSource from "../../assets/sounds/raiseBar.mp3";
import TournamentBustAudioSource from "../../assets/sounds/TournamentBust.mp3";
import TurnAudioSource from "../../assets/sounds/turn.mp3";
import WinnerAudioSource from "../../assets/sounds/win.mp3";
import ApplauseAudioSource from "../../assets/sounds/applause.mp3";

import {
  ALL_IN_SOUND,
  BET_SOUND,
  BUTTON_SOUND,
  CALL_SOUND,
  CHAT_SOUND,
  CHECK_SOUND,
  DEAL_SOUND,
  EXTRA_TIMER_SOUND,
  FOLD_SOUND,
  PLAY_REGISTER_SOUND,
  RAISE_SOUND,
  RAISE_BAR_SOUND,
  TOURNAMENT_BUST_SOUND,
  TURN_SOUND,
  WINNER_SOUND,
  VIBRATE_LIGHT,
  VIBRATE_HEAVY,
  VIBRATE_MEDIUM,
  IS_HAPTICS_EBABLED,
  IS_HAPTICS_SUPPORT,
  APPLAUSE_SOUND,
} from "../data/Constants";
import { messageToNativeClient } from "../../Common/utils/platformCommunicationUtil";
import { getFromLocalStorage } from "../../Common/utils/StorageUtils";

class GameAudio {
  static GameAudioInstance;

  constructor() {
    if (!GameAudio.GameAudioInstance) {
      this.init();
      GameAudio.GameAudioInstance = this;
    } else {
      return GameAudio.GameAudioInstance;
    }
  }

  init = () => {
    if (this.soundAudio) {
      this.soundAudio = null;
    }
    this.soundAudio = new Audio();
  };

  stopSound = () => {
    this.soundAudio.currentTime = 0;
    this.soundAudio.pause();
    this.soundAudio.loop = false;
  };

  playAudio = (isMuted, audioType, isVolumeEnable, loopValue) => {
    // try {
    //   this.soundAudio.removeEventListener("ended", () => {});
    // } catch (e) {
    //   const logMessage = {
    //     type: "Error",
    //     message: "Failed to remove ended event listner in game audio",
    //   };
    //   console.log("Error at GameAudio: ", logMessage);
    //   sendLobbyDebugProtocol(logMessage);
    // }
    if (isMuted || isVolumeEnable) {
      this.soundAudio.muted = isMuted;

      if (this.soundAudio) {
        if (this.soundAudio && this.soundAudio.currentTime > 0) {
          this.soundAudio.loop = false;
          this.soundAudio.pause();
          this.soundAudio.currentTime = 0;
        }
        if (audioType === ALL_IN_SOUND) {
          this.soundAudio.src = AllInAudioSource;
        } else if (audioType === BET_SOUND) {
          this.soundAudio.src = BetAudioSource;
        } else if (audioType === BUTTON_SOUND) {
          this.soundAudio.src = ButtonAudioSource;
        } else if (audioType === CALL_SOUND) {
          this.soundAudio.src = CallAudioSource;
        } else if (audioType === CHAT_SOUND) {
          this.soundAudio.src = ChatAudioSource;
        } else if (audioType === CHECK_SOUND) {
          this.soundAudio.src = CheckAudioSource;
        } else if (audioType === DEAL_SOUND) {
          this.soundAudio.src = DealAudioSource;
        } else if (audioType === EXTRA_TIMER_SOUND) {
          this.soundAudio.src = ExtraTimeAudioSource;
        } else if (audioType === FOLD_SOUND) {
          this.soundAudio.src = FoldAudioSource;
        } else if (audioType === PLAY_REGISTER_SOUND) {
          this.soundAudio.src = PlayRegisterAudioSource;
        } else if (audioType === RAISE_SOUND) {
          this.soundAudio.src = RaiseAudioSource;
        } else if (audioType === RAISE_BAR_SOUND) {
          this.soundAudio.src = RaiseBarAudioSource;
        } else if (audioType === TOURNAMENT_BUST_SOUND) {
          this.soundAudio.src = TournamentBustAudioSource;
        } else if (audioType === TURN_SOUND) {
          this.soundAudio.src = TurnAudioSource;
        } else if (audioType === WINNER_SOUND) {
          this.soundAudio.src = WinnerAudioSource;
        } else if (audioType === APPLAUSE_SOUND) {
          this.soundAudio.src = ApplauseAudioSource;
        }
        let playPromise = this.soundAudio.play(); //this.audio.currentTime = 0;

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
              // this.soundAudio.play();
            })
            .catch((error) => {
              // this.soundAudio.pause();
              console.log(
                "Error at Audio play of type " + audioType + ": ",
                error
              );
              // Auto-play was prevented
              // Show paused UI.
            });
        }
        // this.soundAudio.addEventListener("ended", () => {
        //   if (this.soundAudio && this.soundAudio.currentTime > 0) {
        //     this.soundAudio.pause();
        //     this.soundAudio.currentTime = 0;
        //   }
        //   this.soundAudio.removeEventListener("ended", () => {});
        // });
        this.soundAudio.loop = loopValue;
      }
    }
    // if (isVibrateEnable) {
    //NOTE: need to uncomment this when we start integration of haptic feedbacks
    // if (isIOS) {
    // messageToNativeClient({
    //   type: "vibrate",
    //   token: accessToken,
    // });
    //   } else {
    //     this.playVibration(vibrationDuration);
    //   }
    // }
  };

  playVibration(vibrationType) {
    if (getFromLocalStorage(IS_HAPTICS_SUPPORT)) {
      try {
        messageToNativeClient({ type: "haptic", strength: vibrationType }); // NOTE: Added this for testing haptic for ios to test , strength levels - low, medium, high
      } catch (e) {
        console.log("Error in navigator vibrate: ", e);
      }
    } else {
      this.playVibrationForBrowser(vibrationType);
    }
  }

  playVibrationForBrowser(vibrationType) {
    let vibrationDuration = 0;
    if (vibrationType === VIBRATE_LIGHT) {
      vibrationDuration = 100;
    } else if (vibrationType === VIBRATE_MEDIUM) {
      vibrationDuration = 300;
    } else if (vibrationType === VIBRATE_HEAVY) {
      vibrationDuration = 500;
    }
    try {
      const canVibrate = window.navigator.vibrate;
      if (canVibrate) window.navigator.vibrate(vibrationDuration);
    } catch (e) {
      console.log("Error in navigator vibrate: ", e);
    }
  }

  stopVibration() {
    try {
      const canVibrate = window.navigator.vibrate;
      if (canVibrate) window.navigator.vibrate(0);
    } catch (e) {
      console.log("Error in stop navigator vibrate: ", e);
    }
  }

  static getInstance() {
    if (!GameAudio.GameAudioInstance) {
      return new GameAudio();
    } else {
      return GameAudio.GameAudioInstance;
    }
  }
}

export default GameAudio;
