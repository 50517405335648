import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo12WhiteContoMediumText,
  Typo12GrayContoRegularText,
  Typo14OffWhiteContoMediumText,
  Typo10SilverChaliceContoRegularText,
  Typo14PureWhiteContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const TourneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100% - 52px);
  overflow: scroll;
`;

export const TourneyTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: auto; */
  max-height: 76vh;

  border-radius: 4px;
  border: 1px solid ${colors.secondaryBlue};
  overflow-y: scroll;
  background: linear-gradient(180deg, #053a47 0%, #042f3a 100%);
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 12px 10px 12px;
  border-bottom: 1px solid ${colors.secondaryBlue};
  box-sizing: border-box;
  height: 45px;
  min-height: 45px;
`;

export const TableHeaderItemText = styled(Typo12GrayContoRegularText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 25%;
  line-height: 24px;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 4px 12px 4px 12px;
  box-sizing: border-box;
  height: 33px;
  min-height: 33px;
  span:first-child {
    justify-content: flex-start;
    border-bottom: ${(props) =>
      props.isActive ? `1px solid ${colors.leafyGreen}` : "none"};
  }
  span:last-child {
    justify-content: center;
  }
  span {
    color: ${(props) => (props.isActive ? colors.offWhite : colors.gray)};
  }
`;

export const TableItemText = styled(Typo14OffWhiteContoMediumText)`
  display: ${(props) => (props.isTableNameElement ? "inline-block" : "flex")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableIDText = styled(Typo14OffWhiteContoMediumText)`
  text-transform: uppercase;
  border-bottom: none !important;
`;

export const HorizontalSeperator = styled.img`
  width: 100%;

  margin-top: 4px;
  margin-bottom: 4px;
`;

export const TableMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
`;

export const MessageText = styled(Typo12WhiteContoMediumText)``;

export const TourneyTablesAndActivePlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const TourneyTablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 42px;
  justify-content: space-between;
  align-items: center;
`;

export const TableIconWrapper = styled.img`
  width: 45px;
  height: 24px;
`;

export const RemainingPlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 42px;
  justify-content: space-between;
  align-items: center;
`;

export const RunningTablesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
`;

export const LabelText = styled(Typo10SilverChaliceContoRegularText)`
  line-height: 12.1px;
`;

export const ValueText = styled(Typo14PureWhiteContoMediumText)`
  line-height: 16.94px;
  margin-left: 4px;
`;

export const RemainingPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 24px;
  justify-content: center;
  align-items: center;
`;

export const PlayerIconWrapper = styled.img`
  height: 14px;
  width: 14px;
`;
