import rakeback_level1 from "../../../../assets/lobby/rakeback_level1.png";
import rakeback_level2 from "../../../../assets/lobby/rakeback_level2.png";
import rakeback_level3 from "../../../../assets/lobby/rakeback_level3.png";
import rakeback_level4 from "../../../../assets/lobby/rakeback_level4.png";
import rakeback_level5 from "../../../../assets/lobby/rakeback_level5.png";
import rakeback_level6 from "../../../../assets/lobby/rakeback_level6.png";
import {
  RBHeaderTitleText,
  HeaderWrapper,
  RBBodyContainer,
  RBModalBody,
  CloseButton,
  RBWrapper,
  RBLevelImage,
  RBDetails,
  RBImageText,
  RBImagePercent,
  RBText,
} from "./RakebackLevelUpgradeStyle";
import Button from "../../Button/Button";
import colors from "../../../styleGuide/Colors";
import { useSelector } from "react-redux";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil";
import close from "../../../../assets/common/close.svg";

export const RakebackLevelUpgrade = ({ handleModalClose }) => {
  const rakeBackCurrentLevel = useSelector(
    (state) => state.lobby.rakeBackCurrentLevel
  );
  const rakeBackPercent = useSelector((state) => state.lobby.rakeBackPercent);
  const rakebackChangeType = useSelector(
    (state) => state.lobby.rakebackChangeType
  );
  const onRewardsButtonClick = () => {
    handleModalClose();
    messageToNativeClient({ type: "openRakeBack" });
  };

  const handleClose = () => {
    handleModalClose();
  };

  const getRakebackLevelImage = (level) => {
    switch (level) {
      case 1:
        return rakeback_level1;
      case 2:
        return rakeback_level2;
      case 3:
        return rakeback_level3;
      case 4:
        return rakeback_level4;
      case 5:
        return rakeback_level5;
      case 6:
        return rakeback_level6;
      default:
        return rakeback_level1;
    }
  };

  return (
    rakebackChangeType === "upgrade" && (
      <RBWrapper>
        <HeaderWrapper>
          <RBHeaderTitleText>Level Upgraded</RBHeaderTitleText>
          <CloseButton src={close} onClick={handleClose} />
        </HeaderWrapper>
        <RBBodyContainer>
          <RBModalBody>
            <RBLevelImage
              src={getRakebackLevelImage(rakeBackCurrentLevel)}
              alt='rakback-level'
              id='rakeback-level'
            ></RBLevelImage>
            <RBImageText>
              <RBImagePercent>{rakeBackPercent} %</RBImagePercent>
              <RBText>Rakeback</RBText>
            </RBImageText>
            <span style={{ fontWeight: "bold" }}>Congratulations!</span>
            <RBDetails>
              <span>Lvl.{rakeBackCurrentLevel} unlocked.</span>
              <span>Enjoy {rakeBackPercent}% rakeback</span>
            </RBDetails>
          </RBModalBody>
          <Button
            className='rewards-button'
            bgColor={colors.secondaryGreen}
            buttonText='Go to Rewards'
            clickHandler={onRewardsButtonClick}
          ></Button>
        </RBBodyContainer>
      </RBWrapper>
    )
  );
};
