//ranksdata#{"tourneyId":"lGzQO","rankDataList":[{"playerName":"testuser","playerChips":100.0,"isBustedOut":false,"bustedOutTimeStamp":0,"rank":0,"winner":false},{"playerName":"guru","playerChips":100.0,"isBustedOut":false,"bustedOutTimeStamp":0,"rank":0,"winner":false}]}

import Handler from "../Handler";

class TourneyRanksDataHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    // receivedData = {
    //   "tourneyId": "4qb68",
    //   "rankDataList": [
    //     {
    //       "playerName": "flash932554",
    //       "playerChips": 464000.01,
    //       "gameStartChips": 0,
    //       "isBustedOut": false,
    //       "bustedOutTimeStamp": 0,
    //       "rank": 1,
    //       "winner": true,
    //       "winningAmount": 2600,
    //       "winningBonusAmount": 3900,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "gold749787",
    //       "playerChips": 0,
    //       "gameStartChips": 48579.96,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222145280,
    //       "rank": 2,
    //       "winner": true,
    //       "winningAmount": 900,
    //       "winningBonusAmount": 1350,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "vector494786",
    //       "playerChips": 0,
    //       "gameStartChips": 25500,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222121277,
    //       "rank": 3,
    //       "winner": true,
    //       "winningAmount": 656,
    //       "winningBonusAmount": 984,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "gambit282001",
    //       "playerChips": 0,
    //       "gameStartChips": 12940.03,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222121277,
    //       "rank": 4,
    //       "winner": true,
    //       "winningAmount": 560,
    //       "winningBonusAmount": 840,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "sage855599",
    //       "playerChips": 0,
    //       "gameStartChips": 8600,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222112776,
    //       "rank": 5,
    //       "winner": true,
    //       "winningAmount": 440,
    //       "winningBonusAmount": 660,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "blaze443256",
    //       "playerChips": 0,
    //       "gameStartChips": 8430,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222112776,
    //       "rank": 6,
    //       "winner": true,
    //       "winningAmount": 320,
    //       "winningBonusAmount": 480,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "turbo88301",
    //       "playerChips": 0,
    //       "gameStartChips": 11289.98,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222092273,
    //       "rank": 7,
    //       "winner": true,
    //       "winningAmount": 260,
    //       "winningBonusAmount": 390,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "drago725815",
    //       "playerChips": 0,
    //       "gameStartChips": 7420,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222071271,
    //       "rank": 8,
    //       "winner": true,
    //       "winningAmount": 160,
    //       "winningBonusAmount": 240,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "champ936952",
    //       "playerChips": 0,
    //       "gameStartChips": 68580,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222059769,
    //       "rank": 9,
    //       "winner": true,
    //       "winningAmount": 150,
    //       "winningBonusAmount": 225,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "chief426927",
    //       "playerChips": 0,
    //       "gameStartChips": 29240,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222059769,
    //       "rank": 10,
    //       "winner": true,
    //       "winningAmount": 140,
    //       "winningBonusAmount": 210,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "blaze756023",
    //       "playerChips": 0,
    //       "gameStartChips": 770.01,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222033266,
    //       "rank": 11,
    //       "winner": true,
    //       "winningAmount": 136,
    //       "winningBonusAmount": 204,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "whiz351003",
    //       "playerChips": 0,
    //       "gameStartChips": 44900,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222030265,
    //       "rank": 12,
    //       "winner": true,
    //       "winningAmount": 136,
    //       "winningBonusAmount": 204,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "sage50311",
    //       "playerChips": 0,
    //       "gameStartChips": 5060,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222030265,
    //       "rank": 13,
    //       "winner": true,
    //       "winningAmount": 130,
    //       "winningBonusAmount": 195,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "aces238697",
    //       "playerChips": 0,
    //       "gameStartChips": 3860,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222028265,
    //       "rank": 14,
    //       "winner": true,
    //       "winningAmount": 130,
    //       "winningBonusAmount": 195,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "falcon984136",
    //       "playerChips": 0,
    //       "gameStartChips": 2250,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222028265,
    //       "rank": 15,
    //       "winner": true,
    //       "winningAmount": 126,
    //       "winningBonusAmount": 189,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "hawk961112",
    //       "playerChips": 0,
    //       "gameStartChips": 4060,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222027265,
    //       "rank": 16,
    //       "winner": true,
    //       "winningAmount": 120,
    //       "winningBonusAmount": 180,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "zircon874985",
    //       "playerChips": 0,
    //       "gameStartChips": 1740,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222019264,
    //       "rank": 17,
    //       "winner": true,
    //       "winningAmount": 116,
    //       "winningBonusAmount": 174,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "fury31092",
    //       "playerChips": 0,
    //       "gameStartChips": 960,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222019264,
    //       "rank": 18,
    //       "winner": true,
    //       "winningAmount": 116,
    //       "winningBonusAmount": 174,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "aces54704",
    //       "playerChips": 0,
    //       "gameStartChips": 5609.99,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222008263,
    //       "rank": 19,
    //       "winner": true,
    //       "winningAmount": 116,
    //       "winningBonusAmount": 174,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "grid659196",
    //       "playerChips": 0,
    //       "gameStartChips": 1920,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222006762,
    //       "rank": 20,
    //       "winner": true,
    //       "winningAmount": 116,
    //       "winningBonusAmount": 174,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "blade736001",
    //       "playerChips": 0,
    //       "gameStartChips": 34180,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717222006262,
    //       "rank": 21,
    //       "winner": true,
    //       "winningAmount": 104,
    //       "winningBonusAmount": 156,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "wizard974838",
    //       "playerChips": 0,
    //       "gameStartChips": 20600,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221997761,
    //       "rank": 22,
    //       "winner": true,
    //       "winningAmount": 104,
    //       "winningBonusAmount": 156,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "flash161293",
    //       "playerChips": 0,
    //       "gameStartChips": 14120,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221994761,
    //       "rank": 23,
    //       "winner": true,
    //       "winningAmount": 104,
    //       "winningBonusAmount": 156,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "flash610562",
    //       "playerChips": 0,
    //       "gameStartChips": 4240,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221993761,
    //       "rank": 24,
    //       "winner": true,
    //       "winningAmount": 104,
    //       "winningBonusAmount": 156,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "blitz13717",
    //       "playerChips": 0,
    //       "gameStartChips": 6940,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221983759,
    //       "rank": 25,
    //       "winner": true,
    //       "winningAmount": 104,
    //       "winningBonusAmount": 156,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "nitro668021",
    //       "playerChips": 0,
    //       "gameStartChips": 1680,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221983759,
    //       "rank": 26,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "nova532105",
    //       "playerChips": 0,
    //       "gameStartChips": 2440,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221979759,
    //       "rank": 27,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "grid961061",
    //       "playerChips": 0,
    //       "gameStartChips": 1080,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221973758,
    //       "rank": 28,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "ruby396246",
    //       "playerChips": 0,
    //       "gameStartChips": 700,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221973758,
    //       "rank": 29,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "pro121091",
    //       "playerChips": 0,
    //       "gameStartChips": 31080,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221971258,
    //       "rank": 30,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "drago53703",
    //       "playerChips": 0,
    //       "gameStartChips": 360,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221970258,
    //       "rank": 31,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "brave412095",
    //       "playerChips": 0,
    //       "gameStartChips": 5520,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221960756,
    //       "rank": 32,
    //       "winner": true,
    //       "winningAmount": 100,
    //       "winningBonusAmount": 150,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "icon267647",
    //       "playerChips": 0,
    //       "gameStartChips": 480,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221960756,
    //       "rank": 33,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "ruby457592",
    //       "playerChips": 0,
    //       "gameStartChips": 4575.01,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221955756,
    //       "rank": 34,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "legend560049",
    //       "playerChips": 0,
    //       "gameStartChips": 3680,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221955756,
    //       "rank": 35,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "bolt428739",
    //       "playerChips": 0,
    //       "gameStartChips": 3680,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221954255,
    //       "rank": 36,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "champ797914",
    //       "playerChips": 0,
    //       "gameStartChips": 925,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221954255,
    //       "rank": 37,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "royal870039",
    //       "playerChips": 0,
    //       "gameStartChips": 6160,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221952255,
    //       "rank": 38,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "elite301205",
    //       "playerChips": 0,
    //       "gameStartChips": 12175,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221951755,
    //       "rank": 39,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "sage756284",
    //       "playerChips": 0,
    //       "gameStartChips": 3500,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221943754,
    //       "rank": 40,
    //       "winner": true,
    //       "winningAmount": 86,
    //       "winningBonusAmount": 129,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "whiz585917",
    //       "playerChips": 0,
    //       "gameStartChips": 3040,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221942754,
    //       "rank": 41,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "dev04",
    //       "playerChips": 0,
    //       "gameStartChips": 1840,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221942754,
    //       "rank": 42,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "beast952126",
    //       "playerChips": 0,
    //       "gameStartChips": 545,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221942754,
    //       "rank": 43,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "aura60408",
    //       "playerChips": 0,
    //       "gameStartChips": 540,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221931753,
    //       "rank": 44,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "soul946786",
    //       "playerChips": 0,
    //       "gameStartChips": 14520,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221930752,
    //       "rank": 45,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "eagle475479",
    //       "playerChips": 0,
    //       "gameStartChips": 11200,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221930752,
    //       "rank": 46,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "genius951827",
    //       "playerChips": 0,
    //       "gameStartChips": 0.02,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221927752,
    //       "rank": 47,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "noble848205",
    //       "playerChips": 0,
    //       "gameStartChips": 2400,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221914750,
    //       "rank": 48,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "nuke648969",
    //       "playerChips": 0,
    //       "gameStartChips": 9525,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221895248,
    //       "rank": 49,
    //       "winner": true,
    //       "winningAmount": 80,
    //       "winningBonusAmount": 120,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     },
    //     {
    //       "playerName": "jade938986",
    //       "playerChips": 0,
    //       "gameStartChips": 160,
    //       "isBustedOut": true,
    //       "bustedOutTimeStamp": 1717221806736,
    //       "rank": 129,
    //       "winner": true,
    //       "winningAmount": 42,
    //       "winningBonusAmount": 63,
    //       "winningDistributed": true,
    //       "isCredited": true,
    //       "ticketAssigned": false
    //     }
    //   ]
    // }
    this.service.processTourneyRanksData(receivedData);
  }
}
export default TourneyRanksDataHandler;
