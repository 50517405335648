import Handler from "../Handler";

class FoldActionBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processFoldActionBroadcast(receivedData);
  }
}

export default FoldActionBroadcastHandler;
