import Handler from "../Handler";

class CheckActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processCheckActionAck(receivedData);
  }
}

export default CheckActionHandler;
