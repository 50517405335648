import styled from "styled-components";
import { LANDSCAPE } from "../../../../Poker/data/Constants";
import colors from "../../../styleGuide/Colors";

export const PlayerStatsModalContainer = styled.div`
  background-color: ${colors.deepBlue};
  max-width: 450px;
  max-height: ${(props) => (props.orientation === LANDSCAPE ? "100%" : "80%")};
  width: 90%;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;
  /* animation: springIn 0.4s forwards; */
  /* transform: ${(props) =>
    props.orientation === LANDSCAPE ? "scale(0.7)" : "translateY(-25%)"}; */
  position: absolute;
  top: 25%;
  transform: translateY(-25%);
`;

export const PlayerStatsModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${colors.primaryBlue};
  color: white;
  padding: 0.5rem;
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const PlayerStatsBody = styled.div`
  padding: 14px;
  background-color: ${colors.deepBlue};
`;

export const StatsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: inherit;
  margin-bottom: 10px;
  background: ${colors.primaryBlue};
  border-radius: 8px;
`;

export const Player = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
`;

export const PlayerAvatarBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 30px; */
  position: relative;
`;

export const PlayerAvatar = styled.img`
  height: 100%;
  max-width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid ${(props) => props.color};
  z-index: 2;
`;

export const KYCStatusBox = styled.div`
  position: absolute;
  bottom: -5px;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  z-index: 3;
  background-color: ${(props) => (props.verified ? "green" : null)};
  background: ${(props) => (props.verified ? null : colors.redBtn)};
`;

export const KYCText = styled.span`
  color: white;
  font-size: 8px;
  white-space: nowrap;
`;

export const PlayerInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

export const PlayerName = styled.span`
  color: white;
  font-size: 14px;
`;

export const PlayerMoney = styled.div`
  color: ${colors.primaryGold};
  font-weight: bold;
  font-size: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const MainStats = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  /* border: 1px solid white; */
  border-top: 1px solid ${colors.primaryBlue};
  border-bottom: 1px solid ${colors.primaryBlue};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const OtherStats = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
  text-align: right;
  /* background-color: red; */
`;

export const StatText = styled.p`
  color: white;
  font-size: 8px;
`;

export const ShowdownBarContainer = styled.div`
  height: 6px;
  width: 100%;
  max-width: 120px;
  border-radius: 25rem;
  background-color: ${colors.deepBlue};
  position: relative;
  /* margin-top: 1rem; */
`;

export const WonShowdownInnerProgressBar = styled.div`
  /* width: ${(props) => props.width + "%"}; */
  height: 100%;
  border-radius: 25rem;
  background-color: gold;
  position: absolute;
  animation: showdownWonAnimation 2s forwards ease;

  @keyframes showdownWonAnimation {
    0% {
      width: 0;
    }
    100% {
      width: ${(props) => props.width + "%"};
    }
  }
`;

export const WentToShowdownInnerProgressBar = styled.div`
  /* width: ${(props) => props.width + "%"}; */
  height: 100%;
  border-radius: 25rem;
  background-color: green;
  position: absolute;
  animation: wentToShowdownAnimation 1s forwards ease;

  @keyframes wentToShowdownAnimation {
    0% {
      width: 0;
    }
    100% {
      width: ${(props) => props.width + "%"};
    }
  }
`;

export const Statistic = styled.div`
  display: flex;
  /* min-height: 50px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.primaryBlue};
  border-radius: 8px;
  border: 1px solid ${colors.primaryBlue};
  padding: 5px;
`;

export const StatisticValue = styled.span`
  color: white;
  font-size: 14px;
  margin-bottom: 2px;
`;

export const StatisticName = styled.span`
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const ColorSection = styled.div`
  background-color: ${colors.primaryBlue};
  width: inherit;
  border-radius: 8px;
  padding: 10px;
  border-bottom: 1px solid ${colors.primaryBlue};
  margin-bottom: 10px;
`;

export const EnableControls = styled.div`
  display: flex;
  align-items: center;
`;

export const EnableTitle = styled.span`
  color: white;
  font-size: 14px;
`;

export const Toggle = styled.img`
  height: 20px;
  width: auto;
  margin-left: 15px;
`;

export const ColorOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  opacity: ${(props) => (props.colorsEnabled ? 1 : 0.5)};
`;

export const Color = styled.div`
  background-color: ${(props) => (props.active ? "transparent" : props.hex)};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.active ? "1px solid" : "none")};
  border-color: ${(props) => (props.active ? props.hex : null)};
`;

export const SelectedColor = styled.div`
  background-color: ${(props) => props.hex};
  width: 75%;
  height: 75%;
  border-radius: 50%;
  z-index: 4;
  box-shadow: ${(props) => "0px 0px 10px " + props.hex};
`;

export const NotesSection = styled.div`
  width: inherit;
  /* border-radius: 8px; */
  overflow: hidden;
  display: flex;
  margin-bottom: 1rem;
`;

export const Notes = styled.textarea`
  flex: 1;
  color: white;
  background-color: ${colors.primaryBlue};
  border: none;
  border-radius: 8px;
  padding: 5px;
  min-height: 70px;
  max-height: 120px;
  font-family: "Conto";
  font-size: 14px;
  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: white;
  }
`;

export const PlayerStatsActionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const PlayerStatsSaveButton = styled.button`
  border: none;
  background: ${colors.greenBtn};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 1rem;
  font-family: "Conto";
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
`;

export const PlayerStatsCancelButton = styled.button`
  border: 1px solid white;
  background: transparent;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 1rem;
  font-family: "Conto";
  min-height: 30px;
`;
