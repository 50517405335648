import styled from "styled-components";
import { Typo13PrimaryBlueContoMediumText } from "../../../Common/styleGuide/Typos";

export const ToolTipContainer = styled.div`
  width: 80%;
  position: absolute;
  bottom: 31%;
  transform: translate(0%, 120%);
  transition: all 0.2s ease-in-out;
`;

export const ToolTipArrow = styled.div`
  width: 18px;
  height: 10px;
  background: rgb(230, 198, 131);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: absolute;
  top: -7px;
  // arrow position can be adjusted by changing the left and transform
  left: 60%;
  transform: translate(50%, 0px);
`;

export const ToolTipWrapper = styled.div`
  border-radius: 4px;
  background: #e6c683;
  padding: 3% 4%;
  line-height: 18px;
`;

export const ToolTipText = styled(Typo13PrimaryBlueContoMediumText)`
  letter-spacing: 0.5px;
`;
