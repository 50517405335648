import styled from "styled-components";
import {
  Typo10WhiteContoRegularText,
  Typo6WhiteContoRegularText,
  Typo8PureWhiteContoRegularText,
} from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const TourneyCommonButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const NextRoundTimerContainer = styled.div`
color: ${(props) => props.timerColorFlag ? colors.secondaryRed : colors.white};
`

export const NextUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 74px;
  height: 40px;
  min-height: 40px;
  padding: 6px;
  box-sizing: border-box;

  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    #2a2a2b 14.61%,
    rgba(28, 28, 29, 0) 91.67%
  );
  position: absolute;
  top: 80px;
  left: 2%;
  z-index: 2px;
`;

export const NextupLabelWrapper = styled.span`
  position: relative;
  left: 7px;
  /* top: -3.5px; */
  top: 2px;
  border-radius: 2px;
  background: #2b3b4b;
  padding: 1px 3px;
  box-sizing: border-box;
  z-index: 2px;
`;

export const NextUpText = styled(Typo6WhiteContoRegularText)`
  /* line-height: normal; */
  background: #2b3b4b;
  padding: 1px 3px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 8px;
`;

export const ChipIconWrapper = styled.img`
  width: 8px;
  height: 8px;
`;

export const BlindsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 14px;
  min-height: 14px;
  width: 100%;
`;

export const SmallBlindAndBigBlindDetailsText = styled(
  Typo10WhiteContoRegularText
)`
  line-height: normal;
  margin-left: 6px;
`;

export const NextUpTimerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 14px;
  min-height: 14px;
`;

export const TimerIconWrapper = styled.img`
  width: 10px;
  height: 10px;
`;

export const NextUpTimerText = styled(Typo10WhiteContoRegularText)`
  line-height: normal;
  margin-left: 6px;
`;

export const TourneyLeaderboard = styled.img`
  position: absolute;
  top: 128px;
  left: 2%;
  z-index: 3;
  height: 30px;
  width: 30px;
`;

export const TourneyCurrentLevelWrapper = styled.div`
  display: flex;
  width: auto;
  min-width: 75px;
  max-width: 100px;
  padding: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  /* gap: 6px; */
  border-radius: 4px;
  background: linear-gradient(
    270deg,
    #2a2a2b 27.81%,
    rgba(28, 28, 29, 0) 109.84%
  );
  top: 80px;
  right: 2%;
  position: absolute;
  z-index: 2;
`;

export const ChevronIconWrapper = styled.img`
  width: 12px;
  height: 12px;
`;

export const CurrentLevelText = styled(Typo10WhiteContoRegularText)`
  line-height: normal;
  margin-left: 6px;
`;

export const TourneyRankingLeaderboardWrapper = styled.div`
  position: absolute;
  top: 112px;
  right: 2%;
  z-index: 2;
`;

export const TrophyIconWrapper = styled.img`
  height: 30px;
  width: 30px;
`;
