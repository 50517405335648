import Handler from "../Handler";

class RemoveFromWaitListAckHandler extends Handler {
  execute(message) {
    // UnWaitA#
    // {playerName:”abc”,tableId:”fvd”,status:100,totWaitListCount:integer}

    let receivedData = JSON.parse(message);
    this.service.processRemoveFromWaitListAck(receivedData);
  }
}

export default RemoveFromWaitListAckHandler;