import axios from "axios";
import AwsALBCookieAckHandler from "../handlers/game/AwsAlbCookieHandler";
import ConnectAckHandler from "../handlers/game/ConnectAckHandler";
import ConnectFailHandler from "../handlers/game/ConnectFailHandler";
import DReserveAckHandler from "../handlers/game/DReserveAckHandler";
import HeartBeatGameHandler from "../handlers/game/HeartBeatGameHandler";
import RSeatsBroadcastHandler from "../handlers/game/RSeatsBroadcastHandler";
import ReconnectionAckHandler from "../handlers/game/ReconnectionAckHandler";
import ReserveAckHandler from "../handlers/game/ReserveAckHandler";
import ReserveBroadcastHandler from "../handlers/game/ReserveBrodcastHandler";
import SeatsHandler from "../handlers/game/SeatsHandler";
import GameTableService from "../redux/services/GameTableService";
import BaseClient from "./BaseClient";
import ClientListener from "./ClientListener";
import Configuration from "./Configuration";
import {
  closeInternetDisconnectionGameModal,
  handleReportIssueSuccess,
  // createToastMessageTemp,
  openLocalGameModal,
  // passGameToSequential,
  processCheckGameResponse,
  processMatchMakingResponse,
  updateAmountToAdd,
  updateDisableOrientationChange,
  updateGameConnectionStatusN,
  updatePlayerDetails,
} from "../redux/slices/gameTableSlice";
import {
  ACCOUNT_ISSUE_GAME_JOIN,
  AXIOS_NETWORK_ERROR_MESSAGE,
  CHECK_GAME_ACCOUNT_ISSUE,
  CHECK_GAME_API_READY_TO_REQUEST,
  CHECK_GAME_API_REQUEST_INITIATED,
  CHECK_GAME_API_RESPONSE_SUCCESS,
  CHECK_GAME_API_RESPONSE_UNEXPECTED,
  CONNECTION_REQUESTS_LIMIT_REACHED,
  DISCONNECTION,
  GAME_SOCKET_CONNECTED,
  GAME_SOCKET_DISCONNECTED,
  GAME_SOCKET_ERROR,
  GAME_SOCKET_TIMEOUT,
  INITIATE_TOURNEY_GAME_RECONNECTION,
  MATCH_MAKING_ACCOUNT_ISSUE,
  MATCH_MAKING_API,
  MATCH_MAKING_API_INSUFFICIENT_BALANCE,
  MATCH_MAKING_API_READY_TO_REQUEST,
  MATCH_MAKING_API_REQUEST_INITIATED,
  MATCH_MAKING_API_REQUEST_REJECTED,
  MATCH_MAKING_API_RESPONSE_SUCCESS,
  MATCH_MAKING_BET_DISABLED,
  NETWORK_ICON_AVERAGE,
  NETWORK_ICON_DISCONNECTED,
  NETWORK_ICON_GOOD,
  NETWORK_ICON_WEAK,
  RELOGIN_FAILURE,
} from "../data/Constants";
import TableUtils from "../utils/TableUtils";
import DReserveBroadcastHandler from "../handlers/game/DReserveBroadcastHandler";
import JoinAckHandler from "../handlers/game/JoinAckHandler";
import JoinBroadcastHandler from "../handlers/game/JoinBroadcastHandler";
import JoinFailureHandler from "../handlers/game/JoinFailureHandler";
import CloseAckHandler from "../handlers/game/CloseAckHandler";
import LeaveSeatAckHandler from "../handlers/game/LeaveSeatAckHandler";
import LeaveSeatFailureHandler from "../handlers/game/LeaveSeatFailureHandler";
import TableTimerHandler from "../handlers/game/TableTimerHandler";
import TurnHandler from "../handlers/game/TurnHandler";
import StartGameHandler from "../handlers/game/StartGameHandler";
import AutoUserActionsHandler from "../handlers/game/AutoUserActionsHandler";
import StartGameTimerHandler from "../handlers/game/StartGameTimerHandler";
// import ProfileMaskHandler from "../handlers/game/ProfileMaskHandler";
import RevealCardsHandler from "../handlers/game/RevealCardsHandler";
import PrizePotHandler from "../handlers/game/PrizePotHandler";
import ExtraTimeHandler from "../handlers/game/ExtraTimeHandler";
import AutoFoldActionHandler from "../handlers/game/AutoFoldActionHandler";
import AutoCheckActionHandler from "../handlers/game/AutoCheckActionHandler";
import AutoCheckFoldActionHandler from "../handlers/game/AutoCheckFoldActionHandler";
import AutoCallActionHandler from "../handlers/game/AutoCallActionHandler";
import AutoCallAnyActionHandler from "../handlers/game/AutoCallAnyActionHandler";
import CloseBroadcastHandler from "../handlers/game/CloseBroadcastHandler";
import LeaveBroadcastHandler from "../handlers/game/LeaveBroadcastHandler";
import CallActionHandler from "../handlers/game/CallActionHandler";
import CallActionBroadcastHandler from "../handlers/game/CallActionBroadcastHandler";
import CheckActionHandler from "../handlers/game/CheckActionHandler";
import CheckActionBroadcastHandler from "../handlers/game/CheckActionBroadcastHandler";
import FoldActionHandler from "../handlers/game/FoldAction";
import FoldActionBroadcastHandler from "../handlers/game/FoldActionBroadcastHandler";
import RaiseActionHandler from "../handlers/game/RaiseActionHandler";
import RaiseActionBroadcastHandler from "../handlers/game/RaiseActionBroadcastHandler";
import PotWinnerHandler from "../handlers/game/PotWinnerHandler";
import SitInHandler from "../handlers/game/SitInHandler";
import SitOutHandler from "../handlers/game/SitOutHandler";
import SitOutBroadcastHandler from "../handlers/game/SitOutBroadcastHandler";
import SitOutCancelHandler from "../handlers/game/SitOutCancelHandler";
import SitInBroadcastHandler from "../handlers/game/SitInBroadcastHandler";
import SitInFailureHandler from "../handlers/game/SitInFailureHandler";
import SitOutFailureHandler from "../handlers/game/SitOutFailureHandler";
import WaitForBBHandler from "../handlers/game/WaitForBBHandler";
import RankDetailsHandler from "../handlers/game/RankDetailsHandler";
import HandHistoryHandler from "../handlers/game/HandHistoryHandler";
import TableStatisticsHandler from "../handlers/game/TableStatisticsHandler";
import ResultHandler from "../handlers/game/ResultHandler";
import CloseFailureHandler from "../handlers/game/CloseFailureHandler";
import AutoRebuyAcknowledmentHandler from "../handlers/game/AutoRebuyAcknowledmentHandler";
import AutoRebuyBroadcastHandler from "../handlers/game/AutoRebuyBroadcastHandler";
import AutoRebuyFailureHandler from "../handlers/game/AutoRebuyFailureHandler";
import RebuyInHandler from "../handlers/game/RebuyInHandler";
import DRebuyInHandler from "../handlers/game/DRebuyHandler";
import RebuyFailureHandler from "../handlers/game/RebuyFailureHandler";
import RebuyInAckHandler from "../handlers/game/RebuyInAckHandler";
import RebuyInBroadcastHandler from "../handlers/game/RebuyInBroadcastHandler";
import NodeMismatchHandler from "../handlers/game/NodeMismatchHandler";
import TopupAckHandler from "../handlers/game/TopupAckHandler";
import TopupBroadcastHandler from "../handlers/game/TopupBroadcastHandler";
import TopupFailureHandler from "../handlers/game/TopupFailureHandler";
import AntiBankingHandler from "../handlers/game/AntiBankingHandler";
import RefreshHandler from "../handlers/game/RefreshHandler";
import ReloginConnectionAckHandler from "../handlers/game/ReloginConnectionAckHandler";
import ReloginFailureHandler from "../handlers/game/ReloginFailureHandler";
import PreSelectedAutoAction from "../handlers/game/PreSelectedAutoActionHandler";
import RabbitHuntingHandler from "../handlers/game/RabbitHuntingHandler";
import NextGameTimerHandler from "../handlers/game/NextGameTimerHandler";
import {
  getGameSlice,
  getLobbySlice,
  getPlayerObject,
} from "../utils/ReduxUtils";
import { sendLobbyDebugProtocol } from "../utils/ProtocolUtils";
import { getDeviceAndConnectionDetails } from "../utils/BrowserUtils";
import TourneyPrizeHandler from "../handlers/lobby/TourneyPrizeHandler";
import StartGameCancel from "../handlers/game/StartGameCancelHandler";
import NextGameCancel from "../handlers/game/NextGameCancelHandler";
import BlindsUpHandler from "../handlers/game/BlindsUpHandler";
import BreakBroadcastHandler from "../handlers/game/BreakBroadcastHandler";
import NextLevelUpHandler from "../handlers/game/NextLevelUpHandler";
import DisconnectionTimeHandler from "../handlers/game/DisconnectionTimeHandler";
import TourneyEliminateHandler from "../handlers/game/TourneyEliminateHandler";
import ManageUserActionHandler from "../handlers/game/ManageUserActionHandler";
import TourneyWatchPlayerChannelExistHandler from "../handlers/game/TourneyWatchPlayerChannelExistHandler";
import TourneyGameCompleteHandler from "../handlers/game/TourneyGameCompleteHandler";
import GameDefinitionUpdateHandler from "../handlers/lobby/GameDefinitionUpdateHandler";
import PlayerStatsOnDemand from "../handlers/game/PlayerStatsOnDemand";
import PlayerStatsOnDemandFailure from "../handlers/game/PlayerStatsOnDemandFailureHandler";
import PlayerStatsSave from "../handlers/game/PlayerStatsSaveHandler";
import PlayerStatsOnPlayerJoin from "../handlers/game/PlayerStatsOnPlayerJoin";
import TourneyRebuyInHandler from "../handlers/game/TourneyRebuyInHandler";
import TourneyRebuyInAckHandler from "../handlers/game/TourneyRebuyInAckHandler";
import TourneyRebuyCancleHandler from "../handlers/game/TourneyRebuyCancleHandler";
import TourneyRebuyBroadcastHandler from "../handlers/game/TourneyRebuyBroadcastHandler";
import TourneyRebuyFailAckHandler from "../handlers/game/TourneyRebuyFailAckHandler";
import ReserveFailureHandler from "../handlers/game/ReserveFailureHandler";
import TourneyAddOnAckHandler from "../handlers/game/TourneyAddOnAckHandler";
import MaintenanceHandler from "../handlers/game/MaintenanceHandler";
import TourneyAddOnFailAckHandler from "../handlers/game/TourneyAddOnFailAckHandler";
import TourneyRankingLeaderboardHandler from "../handlers/game/TourneyRankingLeaderboardHandler";
import TourneyPlayerRankHandler from "../handlers/game/TourneyPlayerRankHandler";
import LogoutHandler from "../handlers/game/LogoutHandler";
import TourneyAddOnWithBreakHandler from "../handlers/game/TourneyAddOnWithBreakHandler";
import TourneyTableSwitchHandler from "../handlers/game/TourneyTableSwitchHandler";
import TourneyBreakEndHandler from "../handlers/game/TourneyBreakEndHandler";
import TourneyStartNodeSwitchHandler from "../handlers/game/TourneyStartNodeSwitchHandler";
import TourneyRebuyEndedHandler from "../handlers/game/TourneyRebuyEndedHandler";
import WaitListFailureHandler from "../handlers/game/WaitListFaliureHandler";
import WaitListAckHandler from "../handlers/game/WaitListAckHandler";
import WaitListBroadcastHandler from "../handlers/game/WaitListBroadcastHandler";
import RemoveFromWaitListAckHandler from "../handlers/game/RemoveFromWaitListAckHandler";
import RemoveFromWaitListBroadcastHandler from "../handlers/game/RemoveFromWaitListBroadcastHandler";
import PlayerConfigAckHandler from "../handlers/game/PlayerConfigAckHandler";
import TourneyAddOnWatchPlayerHandler from "../handlers/game/TourneyAddOnWatchPlayerHandler";
import TourneyInTheMoneyHandler from "../handlers/game/TourneyInTheMoneyHandler";
import TourneyWaitingForMergingHandler from "../handlers/game/TourneyWaitingForMergingHandler";
import { sendFaroLog } from "../../Common/utils/FaroUtil";
import TableEndedHandler from "../handlers/game/TableEndedHandler";
import TopupCancelHandler from "../handlers/game/TopupCancelHandler";
import TourneyCancelHandler from "../handlers/game/TourneyCancelHandler";
import RevealOtherPlayerCardsHandler from "../handlers/game/RevealOthersCardsHandler";
import TourneyAutoPlayHandler from "../handlers/game/TourneyAutoPlayHandler";
import ExtraTimeLeftHandler from "../handlers/game/ExtraTimeLeftHandler";

class GameListenerN extends ClientListener {
  constructor(dispatchFunction) {
    super();

    this.dispatchFunction = dispatchFunction;

    this.tempTableId = undefined;
    this.timeoutId = undefined;
    this.awsAlbCookie = undefined;
    this.gameId = undefined;
    this.tableId = undefined;
    this.gameService = undefined;
    this.handlers = undefined;
    this.configuration = undefined;
    this.isTourneyGame = undefined;

    this.apiErrorCount = 0;
    this.apiTimeLogsObject = {};
    this.connectionTimeLogsObject = {};
    this.sendApiLog = false;
    this.finalCleanUpInvoked = false;
    this.isConnectionEstablishedOnce = false;
  }

  async initiateMatchMakingProcess(paramsObject) {
    console.log("Initiate match making process:", this.tempTableId);

    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    if (this.finalCleanUpInvoked) {
      //need to write a log to the lobby in case this happens
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      throw new Error(
        "Match making - clean up already invoked for this listener with temp table id: ",
        this.tempTableId + " not proceeding further"
      );
      return;
    }

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: MATCH_MAKING_API_REQUEST_INITIATED,
      })
    );

    TableUtils.getInstance().updateGameConnectionStatus(
      this.tempTableId,
      MATCH_MAKING_API_REQUEST_INITIATED
    );

    try {
      const { apiURL, apiPayload } = paramsObject;

      let requestDate = new Date();
      let requestTimeLog = `${requestDate.getDate()}/${requestDate.getMonth()}/${requestDate.getFullYear()}, ${requestDate.getHours()}:${requestDate.getMinutes()}:${requestDate.getSeconds()}:${requestDate.getMilliseconds()}`;

      let counterKey = `${Object.keys(this.apiTimeLogsObject).length + 1}`;
      console.log("Counter Key at MM:", counterKey, typeof counterKey);

      let logObject = {
        type: "matchmaking",
        request: requestTimeLog,
        gameDefId: apiPayload.gdid,
      };

      this.apiTimeLogsObject[counterKey] = logObject;
      console.log("API LOGS");
      console.log(this.apiTimeLogsObject);

      let response = await axios.post(apiURL, apiPayload);
      console.log("Logging response");
      console.log(response);

      this.handleMatchMakingResponse(response);
    } catch (error) {
      console.log("Error at async await match making: ", error);
      //dispatch match making failure to redux

      // this.dispatchFunction(
      //     passGameToSequential({
      //         tempTableId: this.tempTableId,
      //         type: "add",
      //         scenario: "error"
      //         // connectionStatus: GAME_SOCKET_DISCONNECTED
      //     })
      // )

      let resetAPILogOnNetworkError =
        error.message === AXIOS_NETWORK_ERROR_MESSAGE ? true : false;

      if (!resetAPILogOnNetworkError) {
        let responseDate = new Date();
        let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

        let counterKey = Object.keys(this.apiTimeLogsObject).length;
        this.apiTimeLogsObject[counterKey] = {
          ...this.apiTimeLogsObject[counterKey],
          response: responseTimeLog,
          status: error.message,
        };
      }

      let countTheRequest = resetAPILogOnNetworkError ? false : true;
      //today work VERIFIED --> pass the parameter value of 'counting the log' as not to count only in case of network error but not for other errors
      this.handleMatchMakingFailure(countTheRequest);
    }
  }

  async submitReportIssueDetails(paramsObject) {
    try {
      const { apiURL, apiPayload, accessToken, a23Token, tempTableId } =
        paramsObject;
      let headerVar = {
        headers: {
          Authorization: accessToken,
          a23token: a23Token,
        },
      };
      let response = await axios.post(apiURL, apiPayload, headerVar);
      console.log("REPORT ISSUE RESPONSE", response);
      this.dispatchFunction(
        handleReportIssueSuccess({ ...response, tempTableId: tempTableId })
      );
    } catch (error) {
      console.log(error);
    }
  }

  handleMatchMakingResponse(response) {
    /** sample match making response
            {
            "tableIdGameIdEntity": {
                "tableId": "THR090823-3654052vr49n",
                "gameId": "090823-3654052c4sem"
            },
            "status": 200,
            "amountToAdd": 0.0,
            "errorMessage": null,
            "awsAlbCookie": "oe0lnyrNh8MOvuDY//wDu5D/dhsGN1L3Zqd8ouUM3rr81lCpwdWBlHwvO0sqlAizuEj7afisLBdfX5KGbgdqXAsHqaOOhEuOrd4hEobkA+uAfl3fq8vCXjL30bjc",
            "gameEndpoint": "wss://pokerws.inffdev.com/websocket"
            }
        */
    console.log("Response received for match making");

    const {
      gameEndpoint,
      tableIdGameIdEntity,
      status,
      amountToAdd,
      awsAlbCookie,
      errorMessage,
    } = response.data;
    const { tableId, gameId } = tableIdGameIdEntity;

    let dataObject = {};
    let responseDate = new Date();
    let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

    let counterKey = Object.keys(this.apiTimeLogsObject).length;
    this.apiTimeLogsObject[counterKey] = {
      ...this.apiTimeLogsObject[counterKey],
      response: responseTimeLog,
      status: status.toString(),
      gameId: gameId,
      tableId: tableId,
    };

    this.setSendApiLog(true);

    if (this.finalCleanUpInvoked) {
      //this 'if check' is helpful in case user closes the game window before the API response (successful or rejected) is received
      //today work VERIFIED --> write time logs to lobby in case returning here
      console.log("Clean up invoked - print at match making response");
      this.apiTimeLogsObject[counterKey] = {
        ...this.apiTimeLogsObject[counterKey],
        note: "game window closed",
      };

      // this.setSendApiLog(true);

      //no need to really set and get value but only for consistency purpose doing this.
      //If everybody is clear after KT, this can be removed and send the log uncoditionally @yashwanth
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");
      return;
    }

    // 201 - low balance
    // 202 - account error
    // 203 - server error
    // 800 - disabled bet
    // This is to handle match making insufficient balance error
    if (status === 201 || (status === 202 && amountToAdd > 0)) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: MATCH_MAKING_API_INSUFFICIENT_BALANCE,
        })
      );
      this.dispatchFunction(
        updateAmountToAdd({
          tempTableId: this.tempTableId,
          amountToAdd: amountToAdd,
        })
      );

      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      return;
    }

    // This is to handle match making bet deleted error
    if (status === 800 || status === 202) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: MATCH_MAKING_BET_DISABLED,
        })
      );

      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      return;
    }

    if (status === 204) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: MATCH_MAKING_ACCOUNT_ISSUE,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: ACCOUNT_ISSUE_GAME_JOIN,
          tempTableId: this.tempTableId,
        })
      );

      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      return;
    }

    if (status === 200) {
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }

      this.setAwsAlbCookie(awsAlbCookie);
      this.setGameId(gameId);
      this.setTableId(tableId);

      const { hostname, port, protocol } = new URL(gameEndpoint);

      dataObject.tableId = tableId;
      dataObject.gameId = gameId;
      dataObject.hostname = hostname;
      dataObject.port = port;
      dataObject.connectionType = protocol.replace(":", ""); //ws or wss
      dataObject.awsalbcookie = this.getAwsAlbCookie();
      dataObject.amountToAdd = amountToAdd;
      dataObject.errorMessage = errorMessage;
      dataObject.gameEndpoint = gameEndpoint;

      // if (document) {
      //   console.log(
      //     "setting AWS ALB cookie to the window document object"
      //   );
      //   console.log(`AWSALB=${awsAlbCookie};domain=${hostname};path=/`);

      //   //window.document.cookie = `AWSALBCORS=${awsAlbCookie};domain=${hostname};path=/`;
      //   window.document.cookie = `AWSALB=${awsAlbCookie};domain=${hostname};path=/`;
      // }
      console.log(
        "setting AWS ALB cookie to the window document object game id: ",
        gameId,
        awsAlbCookie
      );
      console.log(`AWSALB=${awsAlbCookie};domain=${hostname};path=/`);

      //window.document.cookie = `AWSALBCORS=${awsAlbCookie};domain=${hostname};path=/`; //no longer using AWSALBCORS @yashwanth
      // window.document.cookie = `AWSALB=${awsAlbCookie};domain=${hostname};path=/`;
      window.document.cookie = `AWSALB=${awsAlbCookie};domain=${process.env.REACT_APP_COOKIE_HOST_NAME};path=/`;

      //dispatch match making success response to redux
      this.dispatchFunction(
        processMatchMakingResponse({
          ...dataObject,
          connectionStatus: MATCH_MAKING_API_RESPONSE_SUCCESS,
          tempTableId: this.tempTableId,
        })
      );

      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: MATCH_MAKING_API_RESPONSE_SUCCESS,
        })
      );

      console.log("MM response.data: ", response.data);
      // this.dispatchFunction(
      //   createToastMessageTemp({
      //     tempTableId: this.tempTableId,
      //     cookieValue: this.getAwsAlbCookie(),
      //     communicationStage: "match making response",
      //     apiResponse: response.data
      //   })
      // )

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        MATCH_MAKING_API_RESPONSE_SUCCESS
      );

      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;

      console.log("SETTING TIMEOUT FOR SOCKET INITIATION");
      this.timeoutId = setTimeout(
        this.initiateGameSocket.bind(this),
        300,
        dataObject
      );
    } else {
      //as of now only status 203 enters here, rest all are handled in above "if conditions"
      console.log("STATUS NOT 200 at MM FULLFILLED: ", this.tempTableId);
      this.handleMatchMakingFailure(true);
    }
  }

  // handleMatchMakingFailure(countTheRequest, resetAPILogOnNetworkError) {
  //     if (countTheRequest) {
  //         this.setAPIErrorCount(this.getAPIErrorCount() + 1);
  //     }
  //     console.log("API error count incremented:", this.tempTableId);

  //     if (resetAPILogOnNetworkError) {
  //         //today work --> clear only last (meaning recent) one log from the object but not all
  //         this.clearTimeLogs();
  //     }

  //     this.setSendApiLog(true);

  //     if (this.getAPIErrorCount() >= 3) {
  //         console.log("Dispatch an action to the redux to show an alert about match making issue");
  //         sendLobbyDebugProtocol({ "gameapilog": this.apiTimeLogsObject });

  //         this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: CONNECTION_REQUESTS_LIMIT_REACHED }));

  //         TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, CONNECTION_REQUESTS_LIMIT_REACHED);
  //         TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

  //     } else {
  //         this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: MATCH_MAKING_API_READY_TO_REQUEST }));

  //         TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, MATCH_MAKING_API_READY_TO_REQUEST, true);
  //         TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
  //     }

  //     //to be discussed with Product member Maruthi - contact Spruha or yashwanth
  //     // this.dispatchFunction(
  //     //     openLocalGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
  //     // );
  // }

  handleMatchMakingFailure(countTheRequest) {
    if (countTheRequest) {
      this.setAPIErrorCount(this.getAPIErrorCount() + 1);
      console.log("API error count incremented:", this.tempTableId);
    } else {
      console.log(
        "BEFORE API time logs last thing removed:",
        this.apiTimeLogsObject
      );
      //deletes the last noted 'request' log, doing this as we are not considering the network error
      let counterKey = Object.keys(this.apiTimeLogsObject).length;
      delete this.apiTimeLogsObject[counterKey];

      console.log(
        "AFTER API time logs last thing removed:",
        this.apiTimeLogsObject
      );
    }

    if (this.getAPIErrorCount() >= 3) {
      console.log(
        "Dispatch an action to the redux to show an alert about match making issue"
      );
      this.setSendApiLog(true);

      //no need to really set and get value but only for consistency purpose doing this.
      //If everybody is clear after KT, this can be removed and send the log uncoditionally @yashwanth
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }

      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CONNECTION_REQUESTS_LIMIT_REACHED,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: CONNECTION_REQUESTS_LIMIT_REACHED,
          tempTableId: this.tempTableId,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        CONNECTION_REQUESTS_LIMIT_REACHED
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");
    } else {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: MATCH_MAKING_API_READY_TO_REQUEST,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        MATCH_MAKING_API_READY_TO_REQUEST,
        true
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
    }

    //to be discussed with Product member Maruthi - contact Spruha or yashwanth
    // this.dispatchFunction(
    //     openLocalGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
    // );
  }

  async initiateCheckGameProcess(paramsObject) {
    console.log("Initiate check game process:", this.tempTableId);

    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    if (this.finalCleanUpInvoked) {
      //need to write a log to the lobby in case this happens
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      // throw new Error(
      //   "Check game - clean up already invoked for this listener with temp table id: ",
      //   this.tempTableId + " not proceeding further"
      // );
      console.log(
        "Check game - clean up already invoked for this listener with temp table id: ",
        this.tempTableId + " not proceeding further"
      );
      return;
    }

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: CHECK_GAME_API_REQUEST_INITIATED,
      })
    );

    TableUtils.getInstance().updateGameConnectionStatus(
      this.tempTableId,
      CHECK_GAME_API_REQUEST_INITIATED
    );

    try {
      console.log("check gameparamsObject: ", paramsObject);
      const { apiURL, apiPayload } = paramsObject;

      let requestDate = new Date();
      let requestTimeLog = `${requestDate.getDate()}/${requestDate.getMonth()}/${requestDate.getFullYear()}, ${requestDate.getHours()}:${requestDate.getMinutes()}:${requestDate.getSeconds()}:${requestDate.getMilliseconds()}`;

      let counterKey = `${Object.keys(this.apiTimeLogsObject).length + 1}`;
      console.log("Counter Key at CG:", counterKey, typeof counterKey);

      let logObject = {
        type: "checkgame",
        request: requestTimeLog,
        gameDefId: apiPayload.gameDefId,
      };

      console.log("Before adding: ", this.apiTimeLogsObject);

      this.apiTimeLogsObject[counterKey] = logObject;
      console.log("API LOGS");
      console.log(this.apiTimeLogsObject);
      console.log(this.apiTimeLogsObject["1"]);

      let response = await axios.post(apiURL, apiPayload);
      console.log("Logging check game response:", this.tempTableId);
      console.log(response);

      this.handleCheckGameResponse(response);
    } catch (error) {
      console.log("Error at async await check game: ", error, this.tempTableId);

      let resetAPILogOnNetworkError =
        error.message === AXIOS_NETWORK_ERROR_MESSAGE ? true : false;

      if (!resetAPILogOnNetworkError) {
        let responseDate = new Date();
        let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

        let counterKey = Object.keys(this.apiTimeLogsObject).length;
        this.apiTimeLogsObject[counterKey] = {
          ...this.apiTimeLogsObject[counterKey],
          response: responseTimeLog,
          status: error.message,
        };
      }
      //today work VERIFIED --> pass the parameter value of 'counting the log' as not to count only in case of network error but not for other errors
      let countTheRequest = resetAPILogOnNetworkError ? false : true;
      this.handleCheckGameFailure(countTheRequest);
    }
  }

  handleCheckGameResponse(response) {
    /**
         * {
    "awsalbcookie": null,
    "nodeIP": "wss://pokerws.inffdev.com/websocket",
    "gameId": "101223-5127547or10r",
    "status": 200,
    "tableID": "THR101223-51275478976a"
}
         */

    console.log("At handle check game service: ", response);

    const { nodeIP, gameId, status, tableID, awsalbcookie } = response.data;

    let dataObject = {};
    let responseDate = new Date();
    let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

    let counterKey = Object.keys(this.apiTimeLogsObject).length;
    this.apiTimeLogsObject[counterKey] = {
      ...this.apiTimeLogsObject[counterKey],
      response: responseTimeLog,
      status: status.toString(),
      gameId: gameId,
      tableId: tableID,
    };

    this.setSendApiLog(true);

    if (this.finalCleanUpInvoked) {
      //this 'if check' is helpful in case user closes the game window before the API response (successful or rejected) is received
      //today work VERIFIED --> write time logs to lobby in case returning here
      console.log("Clean up invoked - print at check game response");
      this.apiTimeLogsObject[counterKey] = {
        ...this.apiTimeLogsObject[counterKey],
        note: "game window closed",
      };

      // this.setSendApiLog(true);

      //no need to really set and get value but only for consistency purpose doing this.
      //If everybody is clear after KT, this can be removed and send the log uncoditionally @yashwanth
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");
      return;
    }

    // This is to handle check game rejection and stop attempting sequential reconnection
    if (status === 800) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CHECK_GAME_API_RESPONSE_UNEXPECTED,
        })
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      this.dispatchFunction(
        openLocalGameModal({
          type: RELOGIN_FAILURE,
          tempTableId: this.tempTableId,
        })
      );

      return;
    }

    if (status === 204) {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CHECK_GAME_ACCOUNT_ISSUE,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: ACCOUNT_ISSUE_GAME_JOIN,
          tempTableId: this.tempTableId,
        })
      );

      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

      return;
    }

    if (status === 200) {
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }

      this.setAwsAlbCookie(awsalbcookie);
      this.setGameId(gameId);
      this.setTableId(tableID);

      const { hostname, port, protocol } = new URL(nodeIP);

      dataObject.hostname = hostname;
      dataObject.port = port;
      dataObject.connectionType = protocol.replace(":", ""); //ws or wss
      dataObject.gameId = gameId;
      dataObject.tableId = tableID;
      dataObject.awsalbcookie = awsalbcookie;
      dataObject.gameEndpoint = nodeIP;

      console.log("Setting AWS ALB cookie at Check game: ", awsalbcookie);
      // window.document.cookie = `AWSALB=${awsalbcookie};domain=${hostname};path=/`;
      window.document.cookie = `AWSALB=${awsalbcookie};domain=${process.env.REACT_APP_COOKIE_HOST_NAME};path=/`;

      //dispatch check game success response to redux
      this.dispatchFunction(
        processCheckGameResponse({
          ...dataObject,
          connectionStatus: CHECK_GAME_API_RESPONSE_SUCCESS,
          tempTableId: this.tempTableId,
        })
      );

      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CHECK_GAME_API_RESPONSE_SUCCESS,
        })
      );

      console.log("CG response.data: ", response.data);

      // this.dispatchFunction(
      //   createToastMessageTemp({
      //     tempTableId: this.tempTableId,
      //     cookieValue: awsalbcookie,
      //     communicationStage: "check game response",
      //     apiResponse: response.data
      //   })
      // )

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        CHECK_GAME_API_RESPONSE_SUCCESS
      );

      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;

      this.timeoutId = setTimeout(
        this.initiateGameSocket.bind(this),
        300,
        dataObject
      );
    } else {
      console.log("STATUS NOT 200 at CG FULLFILLED");
      this.handleCheckGameFailure(true);
    }
  }

  // handleCheckGameFailure(countTheRequest, resetAPILogOnNetworkError) {
  //     if (countTheRequest) {
  //         this.setAPIErrorCount(this.getAPIErrorCount() + 1);
  //     }

  //     if (resetAPILogOnNetworkError) {
  //         //today work --> clear only last (meaning recent) one log from the object but not all
  //         this.clearTimeLogs();
  //     }

  //     this.setSendApiLog(true);

  //     if (this.getAPIErrorCount() >= 3) {
  //         console.log("Dispatch an action to the redux to show an alert about check game issue");
  //         sendLobbyDebugProtocol({ "gameapilog": this.apiTimeLogsObject });

  //         this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: CONNECTION_REQUESTS_LIMIT_REACHED }));

  //         TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, CONNECTION_REQUESTS_LIMIT_REACHED);
  //         TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

  //     } else {
  //         console.log("Setting check game API ready to request");
  //         this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: CHECK_GAME_API_READY_TO_REQUEST }));

  //         TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, CHECK_GAME_API_READY_TO_REQUEST);
  //         TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
  //     }
  // }

  handleCheckGameFailure(countTheRequest, resetAPILogOnNetworkError) {
    if (countTheRequest) {
      console.log("Check game error count (non network issue) is ignored");
      this.setAPIErrorCount(this.getAPIErrorCount() + 1);
      console.log("API error count incremented:", this.tempTableId);
      this.setSendApiLog(true);
    } else {
      console.log(
        "BEFORE API time logs last thing removed:",
        this.apiTimeLogsObject
      );
      //deletes the last noted 'request' log, doing this as we are not considering the network error
      let counterKey = Object.keys(this.apiTimeLogsObject).length;
      delete this.apiTimeLogsObject[counterKey];

      console.log(
        "AFTER API time logs last thing removed:",
        this.apiTimeLogsObject
      );
    }

    if (this.getAPIErrorCount() >= 3) {
      console.log(
        "Dispatch an action to the redux to show an alert about check game issue"
      );
      this.setSendApiLog(true);

      //no need to really set and get value but only for consistency purpose doing this.
      //If everybody is clear after KT, this can be removed and send the log uncoditionally @yashwanth
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }

      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CONNECTION_REQUESTS_LIMIT_REACHED,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: CONNECTION_REQUESTS_LIMIT_REACHED,
          tempTableId: this.tempTableId,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        CONNECTION_REQUESTS_LIMIT_REACHED
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");
    } else {
      console.log("Setting check game API ready to request");
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CHECK_GAME_API_READY_TO_REQUEST,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        CHECK_GAME_API_READY_TO_REQUEST
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
    }
  }

  initiateTimeOutBasedConnection(gameAPIState, paramsObject) {
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
    console.log("Params object at timeout method: ", paramsObject);

    if (gameAPIState === MATCH_MAKING_API) {
      this.timeoutId = setTimeout(
        this.initiateMatchMakingProcess.bind(this),
        5000,
        paramsObject
      );
    } else {
      console.log("At timebound connection check game");
      this.timeoutId = setTimeout(
        this.initiateCheckGameProcess.bind(this),
        2000,
        paramsObject
      ); //5000 - testing with less timeout @yashwanth
    }
  }

  initiateTimeOutBasedDirectConnection() {
    console.log(
      "Setting timeout based direct socket connection: ",
      this.timeoutId
    );
    this.timeoutId = setTimeout(
      this.triggerDirectSocketConnection.bind(this),
      5000
    );
  }

  // triggerDirectSocketConnection() {
  //     console.log("Clearing timeout in direct socket connection: ", this.timeoutId);
  //     clearTimeout(this.timeoutId);
  //     this.timeoutId = undefined;

  //     let tableData = getGameSlice().games[this.tempTableId];
  //     console.log("Table data at direct socket connection:", tableData);

  //     this.setAwsAlbCookie(tableData.awsalbcookie);
  //     this.setGameId(tableData.gameId);
  //     this.setTableId(tableData.tableId);

  //     if (this.getAwsAlbCookie() === "") {
  //         console.warn("AWS ALB COOKIE EMPTY AT LACK GAME CONNECTION TABLE DATA:", tableData);
  //         return;
  //     }

  //     const { hostname, port, protocol } = new URL(tableData.gameEndpoint);

  //     let dataObject = {
  //         hostname: hostname,
  //         port: port,
  //         connectionType: protocol.replace(":", ""), //ws or wss
  //         // tableId: tableData.tableID
  //         tableId: tableData.tableId
  //     }

  //     console.log("setting AWS ALB cookie to the lack game window document object");
  //     console.log(`AWSALB=${this.getAwsAlbCookie()};domain=${hostname};path=/`, this.awsAlbCookie);

  //     //window.document.cookie = `AWSALBCORS=${awsAlbCookie};domain=${hostname};path=/`;
  //     window.document.cookie = `AWSALB=${this.getAwsAlbCookie()};domain=${hostname};path=/`;

  //     clearTimeout(this.timeoutId);
  //     this.timeoutId = undefined;
  //     console.log("Setting timeout for initiate socket connection");
  //     this.timeoutId = setTimeout(this.initiateGameSocket.bind(this), 300, dataObject);
  // }

  triggerDirectSocketConnection() {
    console.log(
      "Clearing timeout in direct socket connection: ",
      this.timeoutId,
      this.tempTableId
    );

    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    let tableData = getGameSlice().games[this.tempTableId];
    console.log(
      "Table data at direct socket connection:",
      tableData,
      "Temp table id -->",
      this.tempTableId
    );
    if (!tableData) {
      sendLobbyDebugProtocol({
        errorType:
          "No game table available at trigger direct socket connection",
        data: this,
      });
    }

    // this.dispatchFunction(
    //   createToastMessageTemp({
    //     tempTableId: this.tempTableId,
    //     cookieValue: tableData.awsalbcookie,
    //     communicationStage: "direct socket"
    //   })
    // )

    this.setAwsAlbCookie(tableData.awsalbcookie);
    this.setGameId(tableData.gameId);
    this.setTableId(tableData.tableId);

    const { hostname, port, protocol } = new URL(tableData.gameEndpoint);

    let dataObject = {
      hostname: hostname,
      port: port,
      connectionType: protocol.replace(":", ""), //ws or wss
      // tableId: tableData.tableID
      tableId: tableData.tableId,
    };

    if (this.getIsTourneyGame()) {
      console.log("INITIATING TOURNEY SOCKET CONNECTION:", this.tempTableId);
      this.initiateGameSocket(dataObject);
    } else {
      if (this.getAwsAlbCookie() === "") {
        console.warn(
          "AWS ALB COOKIE EMPTY AT LACK GAME CONNECTION TABLE DATA:",
          tableData
        );

        sendFaroLog({
          type: "AWS ALB cookie empty",
          situation: "at lack game connection table data",
          // tableData: tableData //commented as this might exceed the fixed frame size allowed in standard web socket
        });

        sendLobbyDebugProtocol({
          message: "AWS ALB cookie empty at lack game connection table data",
        });
        return;
      }

      console.log(
        "setting AWS ALB cookie to the lack game window document object"
      );
      console.log(
        `AWSALB=${this.getAwsAlbCookie()};domain=${hostname};path=/`,
        this.awsAlbCookie
      );

      //window.document.cookie = `AWSALBCORS=${awsAlbCookie};domain=${hostname};path=/`;
      // window.document.cookie = `AWSALB=${this.getAwsAlbCookie()};domain=${hostname};path=/`;
      window.document.cookie = `AWSALB=${this.getAwsAlbCookie()};domain=${
        process.env.REACT_APP_COOKIE_HOST_NAME
      };path=/`;

      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;

      console.log("Setting timeout for initiate socket connection");
      this.timeoutId = setTimeout(
        this.initiateGameSocket.bind(this),
        300,
        dataObject
      );
    }
  }

  initiateGameSocket(responseObject) {
    console.log("Clearing timeout initiate game socket");
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    let requestDate = new Date();
    let requestTimeLog = `${requestDate.getDate()}/${requestDate.getMonth()}/${requestDate.getFullYear()}, ${requestDate.getHours()}:${requestDate.getMinutes()}:${requestDate.getSeconds()}:${requestDate.getMilliseconds()}`;

    let counterKey = `${Object.keys(this.connectionTimeLogsObject).length + 1}`;
    console.log(
      "Counter Key at initiate game socket:",
      counterKey,
      typeof counterKey,
      responseObject
    );

    let logObject = {
      type: "socketConnection",
      request: requestTimeLog,
      tableId: responseObject.tableId,
    };

    this.connectionTimeLogsObject[counterKey] = logObject;
    console.log("API LOGS");
    console.log(this.connectionTimeLogsObject);

    if (!this.gameService) {
      this.gameService = new GameTableService(
        this.dispatchFunction,
        this.tempTableId
      );
    }
    if (!this.handlers) {
      this.handlers = this.createHandlers(this.gameService);
    }

    // let heartBeatMessageObj = {
    //   playerName: getPlayerObject().userName,
    //   tableId: responseObject.tableId,
    // };

    if (!this.configuration) {
      this.configuration = new Configuration();
      this.configuration.setHandlersList(this.handlers);
      this.configuration.setConnectionType(responseObject.connectionType);
      // this.configuration.setServerAddress(responseObject.hostname);
      // this.configuration.setServerPort(responseObject.port);
      // this.configuration.setHeartBeatMessage(
      //   `g#${JSON.stringify(heartBeatMessageObj)}`
      // );
      this.configuration.setHeartBeatInterval(3);
      this.configuration.setConnectionTimeout(10);
      this.configuration.setReadTimeout(15);
      this.configuration.setReconnectionTimeout(10);
      this.configuration.setProtocolDelimiter("#");
      // this.configuration.setReconnectionMode(
      //     tableData.gameAPIState === MATCH_MAKING_API ? false : true
      //   );
      this.configuration.setReconnectionMode(false);
    }

    //host name & port are kept outside just in case there is a change in these values during re-connection
    //heart beat message is kept outside to sync up the table id received in re-connection
    this.configuration.setServerAddress(responseObject.hostname);

    // if (Object.keys(this.connectionTimeLogsObject).length === 40) {
    //   this.configuration.setServerAddress(responseObject.hostname);
    // } else {
    //   this.configuration.setServerAddress("pokergameabcd.qaa23rmg.com")
    // }
    // this.configuration.setServerAddress("pokergameabcd.qaa23rmg.com")
    this.configuration.setServerPort(responseObject.port);
    // this.configuration.setHeartBeatMessage(
    //   `g#${JSON.stringify(heartBeatMessageObj)}`
    // );
    this.configuration.setHeartBeatMessage("g");

    if (this.getCommunicationChannel()) {
      //the below clean up in this 'if condition' is already done in method 'cleanUpGameListener()' but in case anyone misses to call that method, this one helps
      //we clean up the existing socket with parameter 'isFinalRemoval' as true before assigning a new instance of base client (during disconnection)
      this.getCommunicationChannel().doCleanUp(true);
      this.setCommunicationChannel(null);
    }

    // this.counterDUMMY = !this.counterDUMMY ? 1 : this.counterDUMMY + 1;
    // if(this.counterDUMMY >= 1 && this.counterDUMMY < 6){
    //     this.setValue = true; //temp variable for intentional failure (test case), to be removed later @yashwanth
    //     console.log("this.counterDUMMY : ", this.counterDUMMY, this.setValue);
    // }

    let baseClient = new BaseClient(this.configuration, this, "gameListener");
    baseClient.start();

    this.setCommunicationChannel(baseClient);
  }

  createHandlers(gameService) {
    let handlers = {};
    handlers["awsalbcookieack"] = new AwsALBCookieAckHandler(gameService);
    handlers["cona"] = new ConnectAckHandler(gameService);
    handlers["conf"] = new ConnectFailHandler(gameService);
    handlers["rcona"] = new ReconnectionAckHandler(gameService);
    handlers["g"] = new HeartBeatGameHandler(gameService);
    handlers["seats"] = new SeatsHandler(gameService);
    handlers["reservea"] = new ReserveAckHandler(gameService);
    handlers["reservef"] = new ReserveFailureHandler(gameService);
    handlers["reserveb"] = new ReserveBroadcastHandler(gameService);
    handlers["rseatsb"] = new RSeatsBroadcastHandler(gameService);
    handlers["dreservea"] = new DReserveAckHandler(gameService);
    handlers["dreserveb"] = new DReserveBroadcastHandler(gameService);
    handlers["joina"] = new JoinAckHandler(gameService);
    handlers["joinb"] = new JoinBroadcastHandler(gameService);
    handlers["joinf"] = new JoinFailureHandler(gameService);
    handlers["closea"] = new CloseAckHandler(gameService);
    handlers["tsclose"] = new CloseAckHandler(gameService);
    handlers["leavea"] = new LeaveSeatAckHandler(gameService);
    handlers["leavef"] = new LeaveSeatFailureHandler(gameService);
    handlers["ttm"] = new TableTimerHandler(gameService);
    handlers["disconnectiontime"] = new DisconnectionTimeHandler(gameService);
    handlers["turn"] = new TurnHandler(gameService);
    handlers["useractionstatus"] = new ManageUserActionHandler(gameService);
    handlers["start"] = new StartGameHandler(gameService);
    handlers["autouseractions"] = new AutoUserActionsHandler(gameService);
    handlers["stime"] = new StartGameTimerHandler(gameService);
    handlers["revealcard"] = new RevealCardsHandler(gameService);
    handlers["prizepot"] = new PrizePotHandler(gameService);
    handlers["extratime"] = new ExtraTimeHandler(gameService);
    handlers["autofolda"] = new AutoFoldActionHandler(gameService);
    handlers["autochecka"] = new AutoCheckActionHandler(gameService);
    handlers["autocheckfold"] = new AutoCheckFoldActionHandler(gameService);
    handlers["autocalla"] = new AutoCallActionHandler(gameService);
    handlers["autocallanya"] = new AutoCallAnyActionHandler(gameService);
    handlers["closeb"] = new CloseBroadcastHandler(gameService);
    handlers["leaveb"] = new LeaveBroadcastHandler(gameService);
    handlers["calla"] = new CallActionHandler(gameService);
    handlers["callb"] = new CallActionBroadcastHandler(gameService);
    handlers["checka"] = new CheckActionHandler(gameService);
    handlers["checkb"] = new CheckActionBroadcastHandler(gameService);
    handlers["folda"] = new FoldActionHandler(gameService);
    handlers["foldb"] = new FoldActionBroadcastHandler(gameService);
    handlers["raisea"] = new RaiseActionHandler(gameService);
    handlers["raiseb"] = new RaiseActionBroadcastHandler(gameService);
    handlers["potwinner"] = new PotWinnerHandler(gameService);
    handlers["sitina"] = new SitInHandler(gameService);
    handlers["sitouta"] = new SitOutHandler(gameService);
    handlers["sitoutb"] = new SitOutBroadcastHandler(gameService);
    handlers["sitoutcancela"] = new SitOutCancelHandler(gameService);
    handlers["sitinb"] = new SitInBroadcastHandler(gameService);
    handlers["sitinf"] = new SitInFailureHandler(gameService);
    handlers["sitoutf"] = new SitOutFailureHandler(gameService);
    handlers["wfbba"] = new WaitForBBHandler(gameService);
    handlers["rankdetails"] = new RankDetailsHandler(gameService);
    handlers["handhistorya"] = new HandHistoryHandler(gameService);
    handlers["tablestats"] = new TableStatisticsHandler(gameService);
    handlers["result"] = new ResultHandler(gameService);
    handlers["revealotherscards"] = new RevealOtherPlayerCardsHandler(
      gameService
    );
    handlers["closef"] = new CloseFailureHandler(gameService);
    handlers["ngc"] = new NextGameCancel(gameService);
    handlers["sgc"] = new StartGameCancel(gameService);
    handlers["ngt"] = new NextGameTimerHandler(gameService);
    handlers["autorebuyina"] = new AutoRebuyAcknowledmentHandler(gameService);
    handlers["autorebuyinb"] = new AutoRebuyBroadcastHandler(gameService);
    handlers["autorebuyinf"] = new AutoRebuyFailureHandler(gameService);
    handlers["rebuyin"] = new RebuyInHandler(gameService);
    handlers["drebuyin"] = new DRebuyInHandler(gameService);
    handlers["rebuyinf"] = new RebuyFailureHandler(gameService);
    handlers["rebuyina"] = new RebuyInAckHandler(gameService);
    handlers["rebuyinb"] = new RebuyInBroadcastHandler(gameService);
    handlers["nodemismatch"] = new NodeMismatchHandler(gameService);
    handlers["topupa"] = new TopupAckHandler(gameService);
    handlers["topupb"] = new TopupBroadcastHandler(gameService);
    handlers["topupf"] = new TopupFailureHandler(gameService);
    handlers["topupc"] = new TopupCancelHandler(gameService);
    handlers["ab"] = new AntiBankingHandler(gameService);
    handlers["refresh"] = new RefreshHandler(gameService);
    // handlers["logout"] = new LogoutHandler(gameService);
    handlers["rlcona"] = new ReloginConnectionAckHandler(gameService);
    handlers["rf"] = new ReloginFailureHandler(gameService);
    handlers["preselectedautoaction"] = new PreSelectedAutoAction(gameService);
    handlers["rabbith"] = new RabbitHuntingHandler(gameService);
    handlers["tourneyprize"] = new TourneyPrizeHandler(gameService);
    handlers["blindsup"] = new BlindsUpHandler(gameService);
    handlers["breakbroadcast"] = new BreakBroadcastHandler(gameService);
    handlers["nextlevel"] = new NextLevelUpHandler(gameService);
    handlers["tourneyeliminate"] = new TourneyEliminateHandler(gameService);
    handlers["watchchannelexists"] = new TourneyWatchPlayerChannelExistHandler(
      gameService
    );
    handlers["tourneycompleted"] = new TourneyGameCompleteHandler(gameService);
    // handlers["gu"] = new GameDefinitionUpdateHandler(gameService);
    // Player Stats Handlers
    handlers["psa"] = new PlayerStatsOnDemand(gameService);
    handlers["psf"] = new PlayerStatsOnDemandFailure(gameService);
    handlers["nacc"] = new PlayerStatsOnPlayerJoin(gameService);
    handlers["pstatsa"] = new PlayerStatsSave(gameService);
    handlers["pstatsf"] = new PlayerStatsSave(gameService);
    handlers["tourneyaddon"] = new TourneyAddOnWithBreakHandler(gameService);
    handlers["trebuyin"] = new TourneyRebuyInHandler(gameService);
    handlers["trebuyack"] = new TourneyRebuyInAckHandler(gameService);
    handlers["trebuycancel"] = new TourneyRebuyCancleHandler(gameService);
    handlers["trebuybroadcast"] = new TourneyRebuyBroadcastHandler(gameService);
    handlers["trebuyfailack"] = new TourneyRebuyFailAckHandler(gameService);
    handlers["taddonack"] = new TourneyAddOnAckHandler(gameService);
    handlers["taddonfailack"] = new TourneyAddOnFailAckHandler(gameService);
    handlers["taddonwatchplayer"] = new TourneyAddOnWatchPlayerHandler(
      gameService
    );
    // Handler for Maintenance
    handlers["maintenance"] = new MaintenanceHandler(gameService);

    handlers["trankingleaderboard"] = new TourneyRankingLeaderboardHandler(
      gameService
    );
    handlers["tourneyplayerrank"] = new TourneyPlayerRankHandler(gameService);
    handlers["tableswitch"] = new TourneyTableSwitchHandler(gameService);
    handlers["tourneybreakend"] = new TourneyBreakEndHandler(gameService);
    handlers["tourneyrebuyended"] = new TourneyRebuyEndedHandler(gameService);
    handlers["tourneystartnodeswitch"] = new TourneyStartNodeSwitchHandler(
      gameService
    );
    handlers["tourneyinthemoney"] = new TourneyInTheMoneyHandler(gameService);
    handlers["waitingformerging"] = new TourneyWaitingForMergingHandler(
      gameService
    );
    handlers["playerconfiga"] = new PlayerConfigAckHandler(gameService);
    handlers["tourneyautoplaya"] = new TourneyAutoPlayHandler(gameService);

    // waitlist handlers
    handlers["waitf"] = new WaitListFailureHandler(gameService);
    handlers["waita"] = new WaitListAckHandler(gameService);
    handlers["waitb"] = new WaitListBroadcastHandler(gameService);
    handlers["unwaita"] = new RemoveFromWaitListAckHandler(gameService);
    handlers["unwaitb"] = new RemoveFromWaitListBroadcastHandler(gameService);
    handlers["tableended"] = new TableEndedHandler(gameService);

    handlers["tourneycancel"] = new TourneyCancelHandler(gameService);
    handlers["extratimeleft"] = new ExtraTimeLeftHandler(gameService);
    return handlers;
  }
  // onClientConnect() {
  //     let responseDate = new Date();
  //     let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

  //     let counterKey = Object.keys(this.connectionTimeLogsObject).length;
  //     this.connectionTimeLogsObject[counterKey] = {
  //         ...this.connectionTimeLogsObject[counterKey],
  //         "status": "success",
  //         "response": responseTimeLog,
  //     }

  //     if (this.getSendApiLog()) {
  //         sendLobbyDebugProtocol({ "gameconnectionlog": this.connectionTimeLogsObject });
  //     }

  //     this.setAPIErrorCount(0);

  //     //remove the disconnection modal (if exists), created during failure of match making request
  //     // let tableData = getGameSlice().games[this.tempTableId];
  //     // if (!tableData.isConnectionAckReceivedOnce) {
  //     //     this.dispatchFunction(
  //     //         closeInternetDisconnectionGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
  //     //     );
  //     // }

  //     this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: GAME_SOCKET_CONNECTED }));

  //     TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, GAME_SOCKET_CONNECTED);
  //     TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

  //     this.requestAwsCookie();
  // }

  onClientConnect() {
    console.log("On Client Connect:", this.tempTableId);
    this.isConnectionEstablishedOnce = true;

    let responseDate = new Date();
    let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

    let counterKey = Object.keys(this.connectionTimeLogsObject).length;
    this.connectionTimeLogsObject[counterKey] = {
      ...this.connectionTimeLogsObject[counterKey],
      status: "success",
      response: responseTimeLog,
    };

    if (this.getSendApiLog()) {
      sendLobbyDebugProtocol({
        gameconnectionlog: this.connectionTimeLogsObject,
      });
    }

    this.setAPIErrorCount(0);

    //remove the disconnection modal (if exists), created during failure of match making request
    // let tableData = getGameSlice().games[this.tempTableId];
    // if (!tableData.isConnectionAckReceivedOnce) {
    //     this.dispatchFunction(
    //         closeInternetDisconnectionGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
    //     );
    // }

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_SOCKET_CONNECTED,
      })
    );

    TableUtils.getInstance().updateGameConnectionStatus(
      this.tempTableId,
      GAME_SOCKET_CONNECTED
    );
    TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");

    console.log(
      "At on client connect - is tourney game:",
      this.getIsTourneyGame()
    );
    if (!this.getIsTourneyGame()) {
      this.requestAwsCookie();
    } else {
      console.log("Triggeting connection protocol directly for tourney");
      this.dispatchFunction(
        updatePlayerDetails({ lobbyPlayerData: getPlayerObject() })
      );
      TableUtils.getInstance()
        .getTableObserver(this.tempTableId)
        .triggerConnectionProtocol();
    }
  }

  // onClientDisconnect() {
  //     console.log("On client")
  //     this.setHeartbeatGapCount(10);

  //     this.dispatchFunction(updateGameConnectionStatusN({ tempTableId: this.tempTableId, status: CHECK_GAME_API_READY_TO_REQUEST }));

  //     TableUtils.getInstance().updateGameConnectionStatus(this.tempTableId, CHECK_GAME_API_READY_TO_REQUEST);
  //     TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");

  //     super.onClientDisconnect();

  //     this.dispatchFunction(
  //         openLocalGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
  //     );
  // }

  onClientDisconnect() {
    console.log("On Client Disonnect:", this.tempTableId);
    this.setHeartbeatGapCount(10);

    let connectionStatus = this.getIsTourneyGame()
      ? INITIATE_TOURNEY_GAME_RECONNECTION
      : CHECK_GAME_API_READY_TO_REQUEST;

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: connectionStatus,
      })
    );

    TableUtils.getInstance().updateGameConnectionStatus(
      this.tempTableId,
      connectionStatus
    );
    TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");

    super.onClientDisconnect();

    this.dispatchFunction(
      openLocalGameModal({ tempTableId: this.tempTableId, type: DISCONNECTION })
    );
    this.dispatchFunction(
      updateDisableOrientationChange({
        tempTableId: this.tempTableId,
        isDisabled: false,
      })
    );
  }

  onConnectTimeout() {
    super.onConnectTimeout();
    console.warn("In timeout method in Game ListenerN:", this.tempTableId);

    this.cleanUpGameListener(false);

    let tableData = getGameSlice().games[this.tempTableId];
    if (tableData.gameAPIState === MATCH_MAKING_API) {
      //this does not apply for the check game scenario & tourney game
      this.setAPIErrorCount(this.getAPIErrorCount() + 1);
    }

    this.setSendApiLog(true);

    let responseDate = new Date();
    let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

    let counterKey = Object.keys(this.connectionTimeLogsObject).length;
    this.connectionTimeLogsObject[counterKey] = {
      ...this.connectionTimeLogsObject[counterKey],
      status: "timeout",
      response: responseTimeLog,
    };

    if (this.getAPIErrorCount() >= 3) {
      console.log(
        "Dispatch an action to the redux to show an alert about socket timeout issue"
      );
      this.setSendApiLog(true);

      //no need to really set and get value but only for consistency purpose doing this.
      //If everybody is clear after KT, this can be removed and send the log uncoditionally @yashwanth
      if (this.getSendApiLog()) {
        sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
      }

      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: CONNECTION_REQUESTS_LIMIT_REACHED,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: CONNECTION_REQUESTS_LIMIT_REACHED,
          tempTableId: this.tempTableId,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        CONNECTION_REQUESTS_LIMIT_REACHED
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "remove");
    } else {
      this.dispatchFunction(
        updateGameConnectionStatusN({
          tempTableId: this.tempTableId,
          status: GAME_SOCKET_TIMEOUT,
        })
      );

      TableUtils.getInstance().updateGameConnectionStatus(
        this.tempTableId,
        GAME_SOCKET_TIMEOUT
      );
      TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
    }
  }

  onError(event) {
    console.log("OnError:", this.tempTableId);
    super.onError();

    if (this.isConnectionEstablishedOnce) {
      console.log(
        "RETURNING - Game socket was established once, io proceeds with disconnection flow"
      );
      return;
    } else {
      //not triggering any specific disconnection or trying to establish connection modal/alerts as there is a lottie play during
      //initial connection with message 'Hold your cards..Your match is just a shuffle away'
    }

    this.cleanUpGameListener(false);

    let responseDate = new Date();
    let responseTimeLog = `${responseDate.getDate()}/${responseDate.getMonth()}/${responseDate.getFullYear()}, ${responseDate.getHours()}:${responseDate.getMinutes()}:${responseDate.getSeconds()}:${responseDate.getMilliseconds()}`;

    let counterKey = Object.keys(this.connectionTimeLogsObject).length;
    this.connectionTimeLogsObject[counterKey] = {
      ...this.connectionTimeLogsObject[counterKey],
      status: "error",
      response: responseTimeLog,
    };

    console.log("Updated error event logs:", this.apiTimeLogsObject);

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_SOCKET_ERROR,
      })
    );

    TableUtils.getInstance().updateGameConnectionStatus(
      this.tempTableId,
      GAME_SOCKET_ERROR
    );
    TableUtils.getInstance().passGameToSequential(this.tempTableId, "add");
  }

  setHeartbeatGapCount(value) {
    console.log("In Game listener N:", value);
    let networkIconType = NETWORK_ICON_DISCONNECTED;

    value = value * 3; //values received as 1,2,3,4,5,6 (we multiply them with 3, so as we can understand them as number of seconds)
    if (value < 6) {
      networkIconType = NETWORK_ICON_GOOD;
    } else if (value >= 6 && value < 9) {
      networkIconType = NETWORK_ICON_AVERAGE;
    } else if (value < 30) {
      networkIconType = NETWORK_ICON_WEAK;
    }

    console.log("networkIconType in game: ", networkIconType);
    super.setHeartbeatGapCount(value);
  }

  clearTimeLogs() {
    this.apiTimeLogsObject = {};
    this.connectionTimeLogsObject = {};
  }

  requestAwsCookie() {
    // this.dispatchFunction(
    //   createToastMessageTemp({
    //     tempTableId: this.tempTableId,
    //     cookieValue: this.getAwsAlbCookie(),
    //     communicationStage: "AWSALB protocol"
    //   })
    // )

    let payload = {
      gameId: this.getGameId(),
      tableId: this.getTableId(),
      cookie: this.getAwsAlbCookie(),
      playerName: getPlayerObject().userName,
    };
    console.log("While sending AWS ALB COOKIE:", this.getAwsAlbCookie());
    let awsCookieProtocol = "awsAlbCookie#" + JSON.stringify(payload);

    this.getCommunicationChannel().sendMessage(awsCookieProtocol);
  }

  requestGameConnection(payload) {
    if (this.getSendApiLog()) {
      let logsDataObject = {
        gameapilog: this.apiTimeLogsObject,
        gameconnectionlog: this.connectionTimeLogsObject,
      };

      payload = {
        ...payload,
        ...logsDataObject,
        ...getDeviceAndConnectionDetails(),
      };
    }

    console.log("Payload at requesting game connection: ", payload);

    let gameConnectProtocol = "Con#" + JSON.stringify(payload);
    this.getCommunicationChannel().sendMessage(gameConnectProtocol);

    if (this.getSendApiLog()) {
      sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
    }
    this.clearTimeLogs();
  }

  requestGameReConnection(payload) {
    if (this.getSendApiLog()) {
      let logsDataObject = {
        gameapilog: this.apiTimeLogsObject,
        gameconnectionlog: this.connectionTimeLogsObject,
      };

      payload = {
        ...payload,
        ...logsDataObject,
        ...getDeviceAndConnectionDetails(),
      };
    }

    let gameReconnectProtocol = "RCon#" + JSON.stringify(payload);
    this.getCommunicationChannel().sendMessage(gameReconnectProtocol);
    if (this.getSendApiLog()) {
      sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
    }
    this.clearTimeLogs();
  }

  requestReloginGameConnection(payload) {
    console.log("this.getSendApiLog(): ", this.getSendApiLog());
    if (this.getSendApiLog()) {
      // NOTE: "gameapilog" value will be an empty object in this case because of a direct socket connection
      let logsDataObject = {
        gameapilog: this.apiTimeLogsObject,
        gameconnectionlog: this.connectionTimeLogsObject,
      };

      payload = {
        ...payload,
        ...logsDataObject,
        ...getDeviceAndConnectionDetails(),
      };
    } else {
      //else case is written because (please refer to comment in the if check) -- will be fine tuned after phase 1 if required
      payload = {
        ...payload,
        ...getDeviceAndConnectionDetails(),
      };
    }

    let gameReloginConnectProtocol = "RLCon#" + JSON.stringify(payload);
    this.getCommunicationChannel().sendMessage(gameReloginConnectProtocol);
    if (this.getSendApiLog()) {
      sendLobbyDebugProtocol({ gameapilog: this.apiTimeLogsObject });
    }
    this.clearTimeLogs();
  }

  close = () => {
    let tableData = getGameSlice().games[this.tempTableId];
    let payload = {
      playerName: getLobbySlice().player.userName,
      tableId: tableData.tableId,
      gameDefId: tableData.gameDefinition.gameDefId,
      isWatchPlayer: tableData.watchPlayer,
    };

    let closeProtocol = "close#" + JSON.stringify(payload);
    this.getCommunicationChannel().sendMessage(closeProtocol);
  };

  requestReserveSeat(requestPayload) {
    console.log("Sending reserve from N");
    let reserveProtocol = "Reserve#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(reserveProtocol);
  }

  requestRefreshData(requestPayload) {
    let refreshProtocol = "refresh#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(refreshProtocol);
  }

  requestPlayerStats(requestPayload) {
    console.log("Sending player stats request");
    let playerStatsProtocol = "PS#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(playerStatsProtocol);
  }

  requestSavePlayerStats(requestPayload) {
    let savePlayerStatsProtocol = "PStats#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(savePlayerStatsProtocol);
  }

  requestRemoveReserveSeat(requestPayload) {
    let removeReserveProtocol = "DReserve#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(removeReserveProtocol);
  }

  requestGameJoin(requestPayload) {
    let joinProtocol = "Join#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(joinProtocol);
  }

  processHandHistoryRequest(requestPayload) {
    let handHistoryProtocol = "HandHistory#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(handHistoryProtocol);
  }

  processTableSettingsSaveRequest(requestPayload) {
    let tableSettingsSaveProtocol =
      "PlayerConfig#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(tableSettingsSaveProtocol);
  }
  processTourneyRebuyRequest(requestPayload) {
    let rebuyProtocol = "TourneyRebuy#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(rebuyProtocol);
  }

  processTourneyAddOnRequest(requestPayload) {
    let addOnProtocol = "TourneyAddOn#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(addOnProtocol);
  }

  requestTourneyAddonCancle(requestPayload) {
    let addOnCancleProtocol =
      "TourneyAddOnCancel#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(addOnCancleProtocol);
  }

  processTourneyRebuyCancle(requestPayload) {
    let rebuyCancleProtocol =
      "TourneyRebuyCancel#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(rebuyCancleProtocol);
  }

  requestTourneyRankingLeaderboard(requestPayload) {
    let trlProtocol =
      "TourneyRankLeaderboard#" + JSON.stringify(requestPayload);
    this.getCommunicationChannel().sendMessage(trlProtocol);
  }
  // cleanUpGameListener = () => {
  //     console.log("In game listener clean up");
  //     // this.cancelPendingAPIRequests();
  //     this.finalCleanUpInvoked = true;

  //     clearTimeout(this.timeoutId);
  //     this.timeoutId = undefined;

  //     if (this.getCommunicationChannel()) {
  //         console.log("invoing clean up of base client")
  //         this.getCommunicationChannel().doCleanUp(true);
  //         this.setCommunicationChannel(null);
  //     }

  //     this.gameService = null;
  //     this.handlers = null;
  //     this.configuration = null;
  // }

  cleanUpGameListener = (isFinal, isMaintenanceMode) => {
    console.log("In game listener clean up: ", isFinal);
    if (isFinal !== true && isFinal !== false) {
      throw new Error(
        "Parameter isFinal should either be true or false inorder to proceed with clean up of game listener with temp table id",
        this.tempTableId
      );
    }
    // this.cancelPendingAPIRequests();
    this.finalCleanUpInvoked = isFinal;

    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;

    //invoking clean up of base client from GameListenerN
    if (this.getCommunicationChannel()) {
      console.log("invoking clean up of base client from GameListenerN");
      this.getCommunicationChannel().doCleanUp(true, isMaintenanceMode);
      this.setCommunicationChannel(null);
    }

    if (isFinal) {
      this.gameService = null;
      this.handlers = null;
      this.configuration = null;
    }
  };

  setAPIErrorCount(value) {
    this.apiErrorCount = value;
  }

  getAPIErrorCount() {
    return this.apiErrorCount;
  }

  setTempTableId(value) {
    this.tempTableId = value;
  }

  getTempTableId() {
    return this.tempTableId;
  }

  setAwsAlbCookie(value) {
    this.awsAlbCookie = value;
    console.log("Setting cookie value:", value);
  }

  getAwsAlbCookie() {
    return this.awsAlbCookie;
  }

  setGameId(value) {
    this.gameId = value;
  }

  getGameId() {
    return this.gameId;
  }

  setTableId(value) {
    this.tableId = value;
  }

  getTableId() {
    return this.tableId;
  }

  setSendApiLog(value) {
    this.sendApiLog = value;
  }

  getSendApiLog() {
    return this.sendApiLog;
  }

  setIsTourneyGame(value) {
    this.isTourneyGame = value;
  }

  getIsTourneyGame() {
    return this.isTourneyGame;
  }
}

export default GameListenerN;
