//TAddOnAck
import Handler from "../Handler";

class TourneyAddOnFailAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyAddOnFailureAck(receivedData);
  }
}

export default TourneyAddOnFailAckHandler;

// TAddOnFailAck#{"status":"FAILED","playerName":"lachi3","Amount":0.0,"addOnMessage":"ReBuyIn failed!","statusCode":400}
