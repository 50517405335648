import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import tourneyHorizontalSeperator from "../../../assets/lobby/TourneyHorizontalSeperator.svg";
import smallTableIcon from "../../../assets/lobby/SmallTableIcon.png";
import maskPlayerIcon from "../../../assets/lobby/MaskPlayerIcon.png";

import {
  TourneyContainer,
  TourneyTableContainer,
  TableWrapper,
  TableHeaderWrapper,
  TableHeaderItemText,
  TableRow,
  TableItemText,
  HorizontalSeperator,
  TableMessageWrapper,
  MessageText,
  TableIDText,
  TourneyTablesAndActivePlayersWrapper,
  TourneyTablesWrapper,
  RemainingPlayersContainer,
  RunningTablesWrapper,
  LabelText,
  TableIconWrapper,
  ValueText,
  PlayerIconWrapper,
  RemainingPlayersWrapper,
} from "./tourneyTablesStyles";
import {
  closeTourneyInfoPage,
  handleTourneyStart,
  requestTourneyTablesDynamicData,
} from "../../redux/slices/lobbySlice";
import {
  GAME_TABLE_WATCH_CHANNEL_EXIST,
  MAX_TABLE_LIMIT,
  TOURNEY_REGISTRATION_STARTED,
} from "../../data/Constants";
import { openModal } from "../../redux/slices/modalSlice";
import { ScrollDownArrow } from "../../../Common/components/ScrollDownArrow/ScrollDownArrow";
import {
  checkExistingTourneyWatchTable,
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import {
  openLocalGameModal,
  showGameTable,
  updateActiveGame,
} from "../../redux/slices/gameTableSlice";

function TourneyTables(props) {
  const {
    tourneyTablesStatusList,
    tourneyId,
    tourneyStatus,
    tourneyIdentifier,
    activePlayerForTourney,
    activeTableCount,
  } = props.tourneyDetails;
  const playerName = useSelector((state) => state.lobby.player.userName);
  const activeGames = useSelector((state) => state.gameTable.games);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestTourneyTablesDynamicData({ tourneyId: tourneyId }));
  }, []);

  const handleTableNameClick = (event, tableDetails) => {
    event.preventDefault();
    event.stopPropagation();

    if (Object.keys(activeGames).length === 4) {
      dispatch(
        openModal({
          type: MAX_TABLE_LIMIT,
        })
      );
    } else {
      let tableToStartDetails = {
        tourneyId: tourneyId,
        playerName: playerName,
        tableId: tableDetails.tableId,
        gameId: "",
        domainName: tableDetails.domainName,
        nodeIp: tableDetails.nodeIp,
        watchPlayer: true,
      };

      //returns an object
      let watchTableCheck = checkExistingTourneyWatchTable(
        tourneyId,
        tableDetails.tableId
      );
      console.log("watchTableCheck at tourney tables: ", watchTableCheck);

      if (watchTableCheck.requestedAlreadyOpenedTempTableId) {
        console.log("IF WATCH TOURNEY TABLES");
        dispatch(
          updateActiveGame(watchTableCheck.requestedAlreadyOpenedTempTableId)
        );
        dispatch(showGameTable());
      } else if (
        watchTableCheck.openedWatchTableTempTableId &&
        !watchTableCheck.openedWatchTableConnectionRemoved
      ) {
        console.log("ELSE IF WATCH TOURNEY TABLES:", tableToStartDetails);
        dispatch(updateActiveGame(watchTableCheck.openedWatchTableTempTableId));
        dispatch(showGameTable());

        dispatch(
          openLocalGameModal({
            type: GAME_TABLE_WATCH_CHANNEL_EXIST,
            tempTableId: watchTableCheck.openedWatchTableTempTableId,
            config: {
              stage: "watchChannelExistCheck",
              tableToStartDetails: tableToStartDetails,
            },
          })
        );
      } else {
        console.log("ELSE FINAL WATCH TOURNEY TABLES:", tableToStartDetails);
        // let tableToStartDetails = {
        //   tourneyId: tourneyId,
        //   playerName: playerName,
        //   tableId: tableDetails.tableId,
        //   gameId: "",
        //   domainName: tableDetails.domainName,
        //   nodeIp: tableDetails.nodeIp,
        //   watchPlayer: true
        // };

        dispatch(closeTourneyInfoPage());
        dispatch(
          handleTourneyStart({
            tourneys: [tableToStartDetails],
            isTourneyLackGame: false,
          })
        );
      }

      // if(tourneyWatchTableCheck.isAnotherWatchTableFound){

      // }else if(tourneyWatchTableCheck.isSameWatchTableFound){

      // }
    }
  };

  return (
    <TourneyContainer>
      {tourneyTablesStatusList &&
        tourneyTablesStatusList?.length > 0 &&
        activeTableCount > 0 && (
          <TourneyTablesAndActivePlayersWrapper>
            <TourneyTablesWrapper>
              <RunningTablesWrapper>
                <TableIconWrapper src={smallTableIcon} />
                <ValueText>{activeTableCount}</ValueText>
              </RunningTablesWrapper>
              <LabelText>Active tables</LabelText>
            </TourneyTablesWrapper>
            <RemainingPlayersContainer>
              <RemainingPlayersWrapper>
                <PlayerIconWrapper src={maskPlayerIcon} />
                <ValueText>
                  {`${parseDecimalValuesOfNumber(
                    activePlayerForTourney,
                    2,
                    checkToDisplayValueInK(activePlayerForTourney),
                    checkToDisplayValueInL(activePlayerForTourney)
                  )}`}
                </ValueText>
              </RemainingPlayersWrapper>
              <LabelText>Active Players</LabelText>
            </RemainingPlayersContainer>
          </TourneyTablesAndActivePlayersWrapper>
        )}
      <TourneyTableContainer>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableHeaderItemText>Table</TableHeaderItemText>
            <TableHeaderItemText>Players</TableHeaderItemText>
            <TableHeaderItemText>Largest</TableHeaderItemText>
            <TableHeaderItemText>Smallest</TableHeaderItemText>
          </TableHeaderWrapper>
          {tourneyTablesStatusList &&
            tourneyTablesStatusList?.map((eachTableStatus, index) => (
              <Fragment key={eachTableStatus.tableId}>
                <TableRow isActive={tourneyIdentifier !== "completedTourneys"}>
                  <TableItemText
                    isTableNameElement={true}
                    onClick={(event) => {
                      handleTableNameClick(event, eachTableStatus);
                    }}
                  >
                    {`Table #`}
                    <TableIDText>
                      {`${eachTableStatus.tableId?.slice(-2)}`}
                    </TableIDText>
                  </TableItemText>
                  <TableItemText>{eachTableStatus.players}</TableItemText>
                  <TableItemText>
                    {parseDecimalValuesOfNumber(
                      eachTableStatus.largest,
                      2,
                      checkToDisplayValueInK(eachTableStatus.largest),
                      checkToDisplayValueInL(eachTableStatus.largest)
                    )}
                  </TableItemText>
                  <TableItemText>
                    {parseDecimalValuesOfNumber(
                      eachTableStatus.smallest,
                      2,
                      checkToDisplayValueInK(eachTableStatus.smallest),
                      checkToDisplayValueInL(eachTableStatus.smallest)
                    )}
                  </TableItemText>
                </TableRow>
                {index < tourneyTablesStatusList.length - 1 && (
                  <HorizontalSeperator src={tourneyHorizontalSeperator} />
                )}

                {tourneyTablesStatusList.length > 12 && (
                  <ScrollDownArrow bottom={80} right={16} />
                )}
              </Fragment>
            ))}
          {tourneyTablesStatusList && tourneyTablesStatusList.length === 0 && (
            <TableMessageWrapper>
              <MessageText>
                {tourneyIdentifier !== "completedTourneys"
                  ? tourneyStatus === TOURNEY_REGISTRATION_STARTED
                    ? " Table information will be available once tournament begins."
                    : "Tables information not available."
                  : "Tourney has concluded"}
              </MessageText>
            </TableMessageWrapper>
          )}
        </TableWrapper>
      </TourneyTableContainer>
    </TourneyContainer>
  );
}

export default TourneyTables;
