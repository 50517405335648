import Handler from "../Handler";

class TableTimerHandler extends Handler {
  execute(message) {
    /**TTM#{"playerName":"lachi3","position":4,"time":17,"maxTime":17,"pokerGameStatus":{"tableId":"THR070823-1335398zhf8a","boStatus":99,"seatedPlayerCount":2,"emptySeatCount":6,"allottedPlayerCount":2,"watchPlayerCount":1,"boCreationTime":1691399615398,"gameId":"070823-1335398m1hop","allottedPlayers":[{"playerId":"hammer241661","ip":"40.80.160.200","deviceId":"20.000.1000.0000","latitude":"24.0213","longitude":"78.2340"},{"playerId":"lachi3","ip":"40.80.160.200","deviceId":"20.000.1000.0000","latitude":"24.0213","longitude":"78.2340"}]}}*/

    let receivedData = JSON.parse(message);
    this.service.processTableTimer(receivedData);
  }
}

export default TableTimerHandler;

//TTM# {
//     "playerName": "lachi3",
//     "position": 4,
//     "time": 17,
//     "maxTime": 17,
//     "pokerGameStatus": {
//       "tableId": "THR070823-1335398zhf8a",
//       "boStatus": 99,
//       "seatedPlayerCount": 2,
//       "emptySeatCount": 6,
//       "allottedPlayerCount": 2,
//       "watchPlayerCount": 1,
//       "boCreationTime": 1691399615398,
//       "gameId": "070823-1335398m1hop",
//       "allottedPlayers": [
//         {
//           "playerId": "hammer241661",
//           "ip": "40.80.160.200",
//           "deviceId": "20.000.1000.0000",
//           "latitude": "24.0213",
//           "longitude": "78.2340"
//         },
//         {
//           "playerId": "lachi3",
//           "ip": "40.80.160.200",
//           "deviceId": "20.000.1000.0000",
//           "latitude": "24.0213",
//           "longitude": "78.2340"
//         }
//       ]
//     }
//   }
