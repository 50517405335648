import Handler from "../Handler";

class DReserveBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRemoveReserveSeatBroadcast(receivedData);
  }
}

export default DReserveBroadcastHandler;
