import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acknowledgePlayerMovedToCenter,
  setToastMessage,
  updatePlayerPositionsOnTable,
} from "../../redux/slices/gameTableSlice";
import {
  linear,
  playAnimation,
} from "../../../Common/components/Animations/Animation";
import { activateButtons } from "../../redux/slices/buttonSlice";
import PlayerComponent from "./PlayerComponent";
import { LB_REAL, TOAST_WARNING } from "../../data/Constants";
import { PlayerViewContainer } from "./playerStyles";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_Join } from "../../data/ClevertapConstants";
import { getISTDateTimeFormat } from "../../../Common/utils/GameUtils";

const TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE = 26; // THE INITIAL INDEXS OF 6 POSITIONS 0,2,3,1,4,5 SO IM USING SAME AS CONSTANT

const PlayerView = memo((props) => {
  const dispatch = useDispatch();
  // 50 + 3 for 1.5px border on both sides of player component.
  const playerComponentWidth =
    0.1 * window.innerWidth > 55 ? 0.1 * window.innerWidth : 55;
  const tempTableId = props.activeGameTable;
  const orientation = props.orientation;
  const renderCount = useRef(0);
  const boundingClientRect = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameTableDimensions
  );
  const playerPositions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerPositions
  );
  const mySeat = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerSeat
  );
  const playerMovedToCenter = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerMovedToCenter
  );
  const isLackGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isLackGame
  );
  const totalPlayers = useSelector(
    (state) => state.gameTable.games[tempTableId]?.totalPlayersConfig
  );
  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isTourneyGame
  );

  const gameDefinition = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition
  );

  const atTableAmount = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === mySeat
      )?.atTableAmount
  );

  const loggedInPlayerDetails = useSelector((store) => store.lobby.player);
  const userBalance = useSelector((state) => state.lobby.balance);

  let players, frame;

  useEffect(() => {
    if (isLackGame && !playerMovedToCenter) {
      dispatch(
        acknowledgePlayerMovedToCenter({
          playerMovedToCenter: true,
          tempTableId,
        })
      );
    }
  }, []);

  useEffect(() => {
    handlePlayerPositions();
  }, [orientation]);

  useEffect(() => {
    if (mySeat !== undefined) {
      if (renderCount.current >= 1 && !isTourneyGame) {
        calculateCountAndDirection(playerPositions, totalPlayers, true);
      } else {
        handlePlayerPositions();
      }
    }
    renderCount.current += 1;
    // Added tourney check below as a fix for IM-3150
    if (totalPlayers === 2 && !mySeat && isTourneyGame) {
      handlePlayerPositions();
    }

    if (mySeat !== undefined && mySeat !== null) {
      let timeStamp = getISTDateTimeFormat();
      CleverTapUtil.getInstance().logEvent(IP_Client_Join, {
        Username: loggedInPlayerDetails.userName,
        Channel: loggedInPlayerDetails.channel,
        "Wallet balance": userBalance.totalBalance,
        "Cash / Fun": gameDefinition.flavourType === LB_REAL ? "Cash" : "Fun",
        "Entry fee": gameDefinition?.minBuyInAmount,
        "No. of players": gameDefinition?.maxPlayers,
        GameID: gameDefinition?.gameDefId,
        "Game Format": gameDefinition?.gameType,
        Blinds: `${gameDefinition?.smallBlind}/${gameDefinition?.bigBlind}`,
        "Buy-in": atTableAmount,
        "Join timestamp": timeStamp,
      });
    }
    return () => {
      cancelAnimationFrame(frame);
    };
  }, [mySeat]);

  const handlePlayerPositions = () => {
    players = getPlayerPositions(totalPlayers);
    if (isLackGame || isTourneyGame) {
      const countAndDirectionData = calculateCountAndDirection(
        players,
        totalPlayers,
        false
      );
      let updatedPlayerPositions = players;
      for (let i = 0; i < countAndDirectionData?.count; i++) {
        updatedPlayerPositions = updatePlayerPositionsAfterRotateAnimation(
          updatedPlayerPositions,
          countAndDirectionData.direction
        );
      }

      let arrangeUpdatedPlayerPositions = players.map((eachPlayer, index) => {
        eachPlayer.position = updatedPlayerPositions[index].position;
        return eachPlayer;
      });
      dispatch(
        updatePlayerPositionsOnTable({
          tempTableId: tempTableId,
          positions: arrangeUpdatedPlayerPositions,
        })
      );
      return;
    }
    if (playerMovedToCenter || mySeat) {
      // This is to get the positions post rotation and apply them since player has moved to center already.
      players.map((player, index) => {
        player.position = playerPositions[index]?.position;
      });
    }
    dispatch(
      updatePlayerPositionsOnTable({
        tempTableId: tempTableId,
        positions: players,
      })
    );
  };

  const getPlayerPositions = (playersCount) => {
    let playerPositionsCoords;
    let leftOffset = (window.innerWidth - boundingClientRect.width) / 2;
    // Logic is:
    // if 2 player table, there is topPosition and bottomPosition
    // if 6 player table, there is topPosition, bottomPosition, and 2 on left (left1, left2) and right(right1, right2)
    // if 9 player table, there is top1, top2 on the top, 3 on the left (left1, left2, left3) and 3 on the right(right1, right2, right3) and one in the bottom
    // Bottom Position shared by both 2 player, 6 player and 9 player table
    let BottomPosition =
      orientation === "PORTRAIT"
        ? {
            scale: 1.1,
            left: leftOffset + boundingClientRect.width / 2,
            top: boundingClientRect.bottom - playerComponentWidth / 2,
            position: 0,
          }
        : {
            scale: 1,
            left: leftOffset + boundingClientRect.width / 2,
            // playerComponentWidth / 2,
            top: boundingClientRect.bottom - boundingClientRect.height * 0.18,
            position: 0,
          };
    // START OF 6 PLAYER ARRANGEMENT
    // 6 Player Positions in ORDER
    // 6 Player Lefts
    let SixPlayerLeft1 =
      orientation === "PORTRAIT"
        ? {
            // scale: 0.95,
            left: leftOffset + playerComponentWidth / 2,
            top: boundingClientRect.top + boundingClientRect.height * 0.625,
            position:
              playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 2
                : 1,
          }
        : {
            scale: 1,
            left: leftOffset,
            top: boundingClientRect.top + boundingClientRect.height * 0.5,
            position:
              playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 2
                : 1,
          };
    let SixPlayerLeft2 =
      orientation === "PORTRAIT"
        ? {
            // scale: 0.85,
            left: leftOffset + playerComponentWidth,
            top: boundingClientRect.top + boundingClientRect.height * 0.225,
            position:
              playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 3
                : 2,
          }
        : {
            scale: 1,
            left: leftOffset + boundingClientRect.width * 0.1,
            top: boundingClientRect.top + boundingClientRect.height * 0.1,
            position:
              playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 3
                : 2,
          };
    // Top Player
    let TopPosition =
      orientation === "PORTRAIT"
        ? {
            // scale: 0.8,
            left: leftOffset + boundingClientRect.width / 2,
            top: boundingClientRect.top,
            position:
              playersCount === 9
                ? 4
                : playersCount === 6
                ? 3
                : playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 1
                : 1,
          }
        : {
            scale: 1,
            left: leftOffset + boundingClientRect.width / 2,
            top: boundingClientRect.top - boundingClientRect.height * 0.05,
            position:
              playersCount === 9
                ? 4
                : playersCount === 6
                ? 3
                : playersCount === TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
                ? 1
                : 1,
          };
    // 6 Player Rights
    let SixPlayerRight1 =
      orientation === "PORTRAIT"
        ? {
            // scale: 0.85,
            left: leftOffset + boundingClientRect.width - playerComponentWidth,
            top: boundingClientRect.top + boundingClientRect.height * 0.225,
            position: 4,
          }
        : {
            scale: 1,
            left: leftOffset + boundingClientRect.width * 0.9,
            top: boundingClientRect.top + boundingClientRect.height * 0.1,
            position: 4,
          };
    let SixPlayerRight2 =
      orientation === "PORTRAIT"
        ? {
            // scale: 0.95,
            left:
              leftOffset + boundingClientRect.width - playerComponentWidth / 2,
            top: boundingClientRect.top + boundingClientRect.height * 0.625,
            position: 5,
          }
        : {
            scale: 1,
            left: leftOffset + boundingClientRect.width,
            top: boundingClientRect.top + boundingClientRect.height * 0.5,
            position: 5,
          };
    // END OF 6 PLAYER ARRANGEMENT

    // END OF 9 PLAYER ARRANGEMENT
    // eslint-disable-next-line default-case
    switch (playersCount) {
      case 2:
        playerPositionsCoords = [
          // Bottom
          BottomPosition,
          // Top
          TopPosition,
        ];
        break;
      case TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE:
        playerPositionsCoords = [
          BottomPosition, // INDEX 0
          SixPlayerLeft1, // INDEX 1
          SixPlayerLeft2, // INDEX 2
          TopPosition, // INDEX 3
          SixPlayerRight1, // INDEX 4
          SixPlayerRight2, // INDEX 5
        ];
        break;
      case 5:
        dispatch(
          setToastMessage({
            tempTableId: tempTableId,
            type: TOAST_WARNING,
            message: `You are on ${totalPlayers} players table.\nCurrently only 2, 6 and 9 players table is supported`,
          })
        );

        playerPositionsCoords = [
          // Bottom
          BottomPosition,
          // Left Side
          SixPlayerLeft1,
          SixPlayerLeft2,

          // Top
          // TopPosition,
          // @Sneha: this fix is Temproary, since there is no player config for 5 player positions(bug: IM-377), and topPosition have default position as 1 and SixPlayerLeft1 pposition is also 1 so instead of making change there, commenting it here , now 5 player table will have 5 position, top position will be missing

          // Right Side
          SixPlayerRight1,
          SixPlayerRight2,
        ];
        break;
      case 6:
        playerPositionsCoords = [
          // Bottom
          BottomPosition,
          // Left Side
          SixPlayerLeft1,
          SixPlayerLeft2,
          // Top
          TopPosition,
          // Right Side
          SixPlayerRight1,
          SixPlayerRight2,
        ];
        break;

      case 9:
        // START OF 9 PLAYER ARRANGEMENT
        // 9 Player Positions in ORDER
        // 9 Player Lefts
        let NinePlayerLeftOne =
          orientation === "PORTRAIT"
            ? {
                scale: 1,
                left: leftOffset,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.75 -
                  playerComponentWidth / 2,
                position: 1,
              }
            : {
                scale: 1,
                left:
                  leftOffset +
                  0.2 * boundingClientRect.width -
                  playerComponentWidth / 2,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height -
                  playerComponentWidth / 1.5,
                position: 1,
              };
        let NinePlayerLeftTwo =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.9,
                left: leftOffset,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.5 -
                  playerComponentWidth,
                position: 2,
              }
            : {
                scale: 1,
                left: leftOffset - playerComponentWidth / 2,
                top: boundingClientRect.top + boundingClientRect.height * 0.5,
                position: 2,
              };
        let NinePlayerLeftThree =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.8,
                left: leftOffset + 0.03 * boundingClientRect.width,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.25 -
                  playerComponentWidth,
                position: 3,
              }
            : {
                scale: 1,
                left: leftOffset,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.1 -
                  playerComponentWidth / 2,
                position: 3,
              };
        // Top Two
        let NinePlayerTopOne =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.8,
                left:
                  leftOffset +
                  boundingClientRect.width / 3 -
                  playerComponentWidth / 2,
                top: boundingClientRect.top - playerComponentWidth / 2,
              }
            : {
                scale: 1,
                left: leftOffset + boundingClientRect.width * 0.28,
                top: boundingClientRect.top - playerComponentWidth / 2,
              };
        let NinePlayerTopTwo =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.8,
                left:
                  leftOffset +
                  2 * (boundingClientRect.width / 3) -
                  playerComponentWidth / 2,
                top: boundingClientRect.top - playerComponentWidth / 2,
              }
            : {
                scale: 1,
                left:
                  leftOffset +
                  boundingClientRect.width * 0.72 -
                  playerComponentWidth,
                top: boundingClientRect.top - playerComponentWidth / 2,
              };
        // 9 Player Rights
        let NinePlayerRightOne =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.8,
                left:
                  leftOffset + boundingClientRect.width - playerComponentWidth,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.25 -
                  playerComponentWidth,
                position: 3,
              }
            : {
                scale: 1,
                left:
                  leftOffset + boundingClientRect.width - playerComponentWidth,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.1 -
                  playerComponentWidth / 2,
                position: 3,
              };
        let NinePlayerRightTwo =
          orientation === "PORTRAIT"
            ? {
                // scale: 0.9,
                left:
                  leftOffset + boundingClientRect.width - playerComponentWidth,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.5 -
                  playerComponentWidth,
                position: 2,
              }
            : {
                scale: 1,
                left:
                  leftOffset +
                  boundingClientRect.width -
                  playerComponentWidth / 2,
                top: boundingClientRect.top + boundingClientRect.height * 0.5,
                position: 2,
              };
        let NinePlayerRightThree =
          orientation === "PORTRAIT"
            ? {
                scale: 1,
                left:
                  leftOffset + boundingClientRect.width - playerComponentWidth,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height * 0.75 -
                  playerComponentWidth / 2,
                position: 1,
              }
            : {
                scale: 1,
                left:
                  leftOffset +
                  0.8 * boundingClientRect.width -
                  playerComponentWidth / 2,
                top:
                  boundingClientRect.top +
                  boundingClientRect.height -
                  playerComponentWidth / 1.5,
                position: 1,
              };
        playerPositionsCoords = [
          // Bottom
          BottomPosition,
          // Left Side
          NinePlayerLeftOne,
          NinePlayerLeftTwo,
          NinePlayerLeftThree,
          // Top
          NinePlayerTopOne,
          NinePlayerTopTwo,
          // Right Side
          NinePlayerRightOne,
          NinePlayerRightTwo,
          NinePlayerRightThree,
        ];
        break;

      // @Sneha: Temproary: Currently below player table is not supported yet
      case 3:
      case 4:
      case 7:
      case 8:
        dispatch(
          setToastMessage({
            tempTableId: tempTableId,
            type: TOAST_WARNING,
            message: `You are on ${totalPlayers} players table.\nCurrently only 2, 6 and 9 players table is supported`,
          })
        );

        playerPositionsCoords = [
          // Bottom
          BottomPosition,
          // Left Side
          SixPlayerLeft1,
          SixPlayerLeft2,
          // Top
          TopPosition,
          // Right Side
          SixPlayerRight1,
          SixPlayerRight2,
        ];
        break;
    }
    // if (!playerMovedToCenter || mySeat === undefined) {
    //   dispatch(updatePlayerPositionsOnTable({ tempTableId: tempTableId, positions: players }));
    // }

    return playerPositionsCoords;
  };

  const getCurveTranslateValue = (player, progress, duration, isClockwise) => {
    let xSinAngleValue =
      40 * Math.sin(0.5 * 2 * Math.PI * ((duration * progress) / duration));
    if (isClockwise) {
      switch (player) {
        case 0:
          return `translateX(calc(-50% + -${xSinAngleValue}px)) translateY(-50%)`;
        case 5:
          return `translateX(calc(-50% + ${xSinAngleValue}px)) translateY(-50%)`;
        case 2:
          return `translateY(calc(-50% + -${xSinAngleValue}px)) translateX(-50%)`;

        case 3:
          return `translateY(-50%) translateX(calc(-50% + ${xSinAngleValue}px))`;

        default:
          return "translate(-50%, -50%)";
      }
    } else {
      switch (player) {
        case 4:
          return `translateY(-50%) translateX(calc(-50% + -${xSinAngleValue}px))`;
        case 5:
          return `translateY(-50%) translateX(calc(-50% + ${xSinAngleValue}px))`;
        case 2:
          return `translateY(calc(-50% + -${xSinAngleValue}px)) translateX(-50%)`;
        case 1:
          return `translateY(-50%) translateX(calc(-50% + ${xSinAngleValue}px))`;
        default:
          return "translate(-50%, -50%)";
      }
    }
  };

  const checkIndexForRotateAnimationCurve = (index, direction) => {
    let originalPlayerPositions =
      totalPlayers === 2
        ? getPlayerPositions(TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE)
        : playerPositions;
    let sixPlayersClockWiseEdgeIndexes = [0, 2, 5, 3];
    let sixPlayersCounterClockWiseEdgeIndexes = [4, 5, 2, 1];

    let ninePlayersClockWiseEdgeIndexes = [0, 3, 4, 5, 8];
    let ninePlayersCounterClockWiseEdgeIndexes = [0, 1, 6, 5, 4];

    if (direction === "clockwise") {
      return originalPlayerPositions.length === 6
        ? sixPlayersClockWiseEdgeIndexes.includes(index)
        : originalPlayerPositions.length === 9
        ? ninePlayersClockWiseEdgeIndexes.includes(index)
        : false;
    } else {
      return originalPlayerPositions.length === 6
        ? sixPlayersCounterClockWiseEdgeIndexes.includes(index)
        : originalPlayerPositions.length === 9
        ? ninePlayersCounterClockWiseEdgeIndexes.includes(index)
        : false;
    }
  };

  const rotatePlayerPositionsOnTable = async (
    count,
    direction,
    currentPlayerPositions,
    animationDuration
  ) => {
    if (count === 0) {
      dispatch(activateButtons());
      let finalPlayerPositionsAfterAnimation = [...currentPlayerPositions];

      if (totalPlayers === 2) {
        let final2PlayerPositions = [];
        currentPlayerPositions.forEach((eachPosition, index) => {
          if (index === 0) {
            final2PlayerPositions.push(eachPosition);
          } else if (index === 3) {
            final2PlayerPositions.push(eachPosition);
          }
        });

        finalPlayerPositionsAfterAnimation = final2PlayerPositions;
      }
      dispatch(
        updatePlayerPositionsOnTable({
          tempTableId: tempTableId,
          positions: finalPlayerPositionsAfterAnimation,
        })
      );
      dispatch(
        acknowledgePlayerMovedToCenter({
          playerMovedToCenter: true,
          tempTableId,
        })
      );
      return;
    }

    await new Promise((resolve, reject) => {
      let isClockwise = direction === "clockwise";
      let playerElements = [];
      let originalPlayerPositions = isClockwise
        ? currentPlayerPositions
        : [...currentPlayerPositions].reverse();
      let updatedPlayerPositions = [...originalPlayerPositions];
      let animationCompletedStatusForEachPlayer = [];
      for (let player = 0; player < originalPlayerPositions.length; player++) {
        playerElements.push(
          document.getElementById(
            `player-${originalPlayerPositions[player].position}-${tempTableId}`
          )
        );
      }

      for (let index = 0; index < originalPlayerPositions.length; ++index) {
        let endPosition =
          index < updatedPlayerPositions.length - 1
            ? updatedPlayerPositions[index + 1]
            : updatedPlayerPositions[updatedPlayerPositions.length - 1 - index];
        frame = playAnimation({
          duration: animationDuration,
          timing: (timeFraction) => linear(timeFraction),
          draw: (progress) => {
            if (playerElements[index]) {
              let scalingValue =
                originalPlayerPositions[index].scale -
                (originalPlayerPositions[index].scale - endPosition.scale) *
                  progress;

              if (checkIndexForRotateAnimationCurve(index, direction)) {
                playerElements[
                  index
                ].style.transform = `${getCurveTranslateValue(
                  index,
                  progress,
                  animationDuration,
                  isClockwise
                )}`;
              } else {
                // playerElements[index].style.transform = `translateX(-50%)`;
                playerElements[index].style.transform = `translate(-50%, -50%)`;
              }
              playerElements[index].style.scale = scalingValue;
              playerElements[index].style.top =
                originalPlayerPositions[index].top -
                (originalPlayerPositions[index].top - endPosition.top) *
                  progress +
                "px";
              playerElements[index].style.left =
                originalPlayerPositions[index].left -
                (originalPlayerPositions[index].left - endPosition.left) *
                  progress +
                "px";
              playerElements[index].style.scale = scalingValue;
            }
            if (progress === 1) {
              animationCompletedStatusForEachPlayer.push(1);
              if (
                animationCompletedStatusForEachPlayer.length ===
                originalPlayerPositions.length
              ) {
                resolve();
              }
            }
          },
        });
      }
    });
    //Recursive function with combination of async promises.
    rotatePlayerPositionsOnTable(
      count - 1,
      direction,
      updatePlayerPositionsAfterRotateAnimation(
        currentPlayerPositions,
        direction
      ),
      animationDuration
    );
  };

  const updatePlayerPositionsAfterRotateAnimation = (
    currentPlayerPositions,
    diretcion
  ) => {
    let updatedPlayerPositions = [...currentPlayerPositions];
    let originalPlayerPositions =
      totalPlayers === 2
        ? getPlayerPositions(TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE)
        : playerPositions.length > 0
        ? playerPositions
        : getPlayerPositions(totalPlayers);

    if (diretcion === "clockwise") {
      updatedPlayerPositions.unshift(
        updatedPlayerPositions[updatedPlayerPositions.length - 1]
      );
      updatedPlayerPositions.pop();
    } else {
      updatedPlayerPositions.push(updatedPlayerPositions[0]);
      updatedPlayerPositions.shift();
    }
    let final = updatedPlayerPositions.map((eachPlayer, index) => {
      return {
        top: originalPlayerPositions[index].top,
        left: originalPlayerPositions[index].left,
        position: eachPlayer.position,
        scale: originalPlayerPositions[index].scale,
      };
    });
    return final;
  };

  const initiateRotatePlayerPositionAnimation = (
    count,
    direction,
    playerPositions
  ) => {
    let animationCount = totalPlayers === 2 ? count : count;
    // let animationDuration = 800 / animationCount;
    let animationDuration = 600;
    if (playerPositions.length === 2) {
      let sixPlayerPositions = getPlayerPositions(
        TWO_PLAYER_TABLE_WITH_SIX_POSITIONS_REFERENCE
      );
      rotatePlayerPositionsOnTable(
        animationCount,
        direction,
        sixPlayerPositions,
        animationDuration
      );
    } else {
      rotatePlayerPositionsOnTable(
        count,
        direction,
        playerPositions,
        animationDuration
      );
    }
  };

  const calculateCountAndDirection = (
    finalPlayerPositions,
    totalPlayers,
    playRotateAnimation = isTourneyGame ? false : isLackGame ? false : true
  ) => {
    let count = 0;
    let direction = "clockwise";
    if (!finalPlayerPositions) {
      switch (totalPlayers) {
        case 2:
          count = 2;
          break;
        case 6:
          if (mySeat < 3) {
            count = mySeat;
            direction = "anticlockwise";
          } else {
            count = 6 - mySeat;
          }
          break;
        case 9:
          if (mySeat < 4) {
            count = mySeat;
            direction = "anticlockwise";
          } else {
            count = 9 - mySeat;
          }
          break;
      }
    } else {
      let playerItem = finalPlayerPositions.find(
        (player) => player.position === mySeat
      );
      let selectedIndex = finalPlayerPositions.indexOf(playerItem);
      if (selectedIndex === 0) {
        // setting playerMoved to center to true when he sits at index 0 and there is no rotation animation
        dispatch(
          acknowledgePlayerMovedToCenter({
            playerMovedToCenter: true,
            tempTableId,
          })
        );
        dispatch(activateButtons());
        return;
      } else if (selectedIndex < Math.floor(totalPlayers / 2)) {
        direction = "anticlockwise";
        count = selectedIndex;
      } else {
        count = totalPlayers === 2 ? 3 : 6 - selectedIndex;
      }
    }
    // dispatch(deactivateButtons());
    if (playRotateAnimation) {
      initiateRotatePlayerPositionAnimation(count, direction, playerPositions);
      return;
    }

    return {
      count: count,
      direction: direction,
    };
  };

  return playerPositions ? (
    <PlayerViewContainer>
      {playerPositions.map((player, i) => {
        return (
          <PlayerComponent
            top={player.top}
            className={"PlayerClass: " + player.position}
            left={player.left}
            bottom={player.bottom}
            right={player.right}
            scale={player.scale}
            position={player.position}
            tempTableId={tempTableId}
            key={
              i +
              "tableNumber: " +
              tempTableId +
              "playerPosition: " +
              player.position
            }
            id={player.position + "-" + tempTableId}
            gameTableLoaded={props.gameTableLoaded}
          />
        );
      })}
    </PlayerViewContainer>
  ) : null;
});

export default memo(PlayerView);
