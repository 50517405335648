import Handler from "../Handler";

class TourneyDepositorTicketReceiveHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyDepositorTicketRequest(receivedData);
  }
}
export default TourneyDepositorTicketReceiveHandler;

// depositorPopUp# {
// playerName: "abcd",
// tourneyId: "xyz",
// tourneyName:"tourney name"
// }
