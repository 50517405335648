import styled from "styled-components";

export const Button = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)}px;
  right: ${(props) => (props.right ? props.right : 0)}px;
  width: 40px;
  height: 30px;

  &:focus {
    outline: 0;
    border: 0;
  }
`;

export const CloseButtonWrapper = styled.button`
  outline: 0;
  border: 0;
  background: transparent;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  &:focus {
    outline: 0;
    border: 0;
  }
`

export const CloseIcon = styled.img`
  width: 15px;
  height: 15px;
`;
