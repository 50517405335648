import Handler from "../Handler";

class RankDetailsHandler extends Handler {
  execute(message) {
    // Rankdetails#
    let receivedData = JSON.parse(message);
    this.service.processRankDetails(receivedData);
  }
}

export default RankDetailsHandler;

// Rankdetails#
// {
//     "userCards": [],
//     "potAmount": 0,
//     "smallBlindAmount": 0,
//     "bigBlindAmount": 0,
//     "rankDetails": {
//         "longDescription": "Two Pairs, Jacks and Tens (plus Ace)",
//         "winningHand": "s11,d11,h10,c10,h1",
//         "rank": "2",
//         "description": "Two Pairs, Jacks and Tens",
//         "rankCards": "s11,d11,h10,c10",
//         "handType": "Two pairs",
//         "points": "2865838"
//     }
// }
