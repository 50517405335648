import styled from "styled-components";
import { LANDSCAPE } from "../../../Poker/data/Constants";
import colors from "../../styleGuide/Colors";

const windowHeight = window.innerHeight + "px";

export const GlobalModal = styled.div`
  width: var(--app-width);
  height: var(--app-height);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--app-height);
`;

export const DarkScreen = styled.div`
  background-color: rgba(0,0,0,0.65);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ModalContainer = styled.div`
  background-color: #0000008f;
  width: 100%;
  /* height: 100vh; */
  position: relative;
`;

export const ModalWrapper = styled.div`
  width: ${(props) => (props.orientation === "LANDSCAPE" ? "80%" : "75%")};
  max-width: ${(props) =>
    props.orientation === "LANDSCAPE" ? "600px" : "600px"};
  height: fit-content;
  max-height: 80%;
  overflow: scroll;
  position: relative;
  animation: springIn 0.28s forwards;
  border-radius: 8px;
`;

export const BuyInWrapper = styled.div`
  width: 80%;
  max-width: ${(props) =>
    props.orientation === LANDSCAPE ? "350px" : "400px"};
  height: fit-content;
  max-height: ${(props) =>
    props.orientation === LANDSCAPE ? "100%" : "80%"};
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  animation: ${(props) => props.orientation === LANDSCAPE ? null : 'springIn 0.4s forwards' };
  border-radius: 8px;
  border: 1px solid ${colors.secondaryBlue};
  transform: ${(props) => props.orientation === LANDSCAPE ? 'scale(0.9)' : null};
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 0.7rem 0rem;
  display: flex;
  justify-content: center;
  background: ${colors.popupBgColor};
  border-radius: 8px 8px 0 0;
`;

export const ModalBody = styled.div`
  background: ${colors.popupBgColor};
  padding: 1rem;
  display: grid;
  grid-template-columns: ${(props) =>
    props.orientation === "LANDSCAPE" ? "1fr 1fr" : "1fr"};
  grid-column-gap: ${(props) =>
    props.orientation === "LANDSCAPE" ? "2rem" : "0px"};
  grid-row-gap: ${(props) =>
    props.orientation === "LANDSCAPE" ? "0.5rem" : "10px"};
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`;
