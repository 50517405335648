//TourneyCompleteHandler.js

import Handler from "../Handler";

class TourneyCompleteHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyComplete(receivedData);
  }
}
export default TourneyCompleteHandler;

// tourneycomplete#{"tourneyId":"fV8so"}
