import Handler from "../Handler";

class ActiveGamesHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processLackGames(receivedData);
  }
}
export default ActiveGamesHandler;

// activeGames activegames#{
//     "realActiveGames": {
//         "playerId": "blaze117777",
//         "activeGames": []
//     },
//     "tourneyActiveGames": {
//         "tourneyActiveGameList": []
//     },
//     "realWaitingGames": null
// }
