import React, { useEffect, useState } from "react";
import MiniGameTableSVG from "../../../assets/gameTable/miniGameTable.svg";
import {
  Card,
  CardsContainer,
  DimCard,
  MiniGameInfoContainer,
  MiniGameTableContainer,
  MiniGameTableImage,
  MiniGameTableTimerContainer,
  MiniGameTableTimerValue,
  TableName,
  TableStakes,
  TotalPotAmountContainer,
  TotalPotChipIcon,
  TotalPotValue,
  WinnerLabel,
} from "./MiniGameTableStyle";
// import { CardImages as Cards } from "../../../assets/cards/index";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveGame } from "../../redux/slices/gameTableSlice";
import chipIcon from "../../../assets/gameTable/chips.png";
import { cardThemes } from "../../../assets/cards";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { AnimatePresence } from "framer-motion";

const MiniGameTable = ({
  tableNumber,
  activeGame,
  isLobby,
  close,
  lobbyCallback,
}) => {
  const dispatch = useDispatch();
  const buttonStatus = useSelector((state) => state.button.value);
  const playerTurn = useSelector(
    (state) => state.gameTable.games[tableNumber].playerTurn
  );
  const mySeat = useSelector(
    (state) => state.gameTable.games[tableNumber].playerSeat
  );
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const playerData = useSelector((state) =>
    state.gameTable.games[tableNumber].playerData.find(
      (player) => player.position === mySeat
    )
  );
  // const currentUserData = useSelector((state) =>
  //   state.gameTable.games[tableNumber].playerData.find(
  //     (player) => player.position === mySeat
  //   )
  // );
  const playerCards = useSelector(
    (state) => state.gameTable.games[tableNumber].playerCards
  );
  const smallBlindValue = useSelector(
    (state) => state.gameTable.games[tableNumber]?.gameDefinition.smallBlind
  );
  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tableNumber]?.gameDefinition.bigBlind
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable.tableSettings.BBDisplay
  );
  const tableType = useSelector(
    (state) => state.gameTable.games[tableNumber]?.gameDefinition.gameType
  );
  const isActiveGameTable = isLobby ? true : tableNumber === activeGame;

  const playerTurnTimer = useSelector(
    (state) =>
      state.gameTable.games[tableNumber].playerData.find(
        (player) => player.position === mySeat
      )?.remainingTime
  );
  const shouldPlayCardAnimation = useSelector(
    (state) => state.gameTable.games[tableNumber]?.shouldPlayCardAnimation
  );
  const totalPotAmount = useSelector(
    (state) => state.gameTable.games[tableNumber]?.totalPotAmt
  );

  const gameType = useSelector(
    (state) => state.gameTable.games[tableNumber].gameDefinition.gameType
  );

  const changeActiveTable = () => {
    console.log("Change in active table ", activeGame, tableNumber);
    if (buttonStatus && activeGame !== tableNumber) {
      // close();
      dispatch(updateActiveGame(tableNumber));
    } else {
      console.log("Else case of change");
    }
    return;
  };

  const openGameTableFromLobby = () => {
    lobbyCallback({
      type: "MINI_GAME_TABLE_CALL_BACK",
      tempTableId: tableNumber,
    });
  };

  const returnPlayerTurnOnTable = () => {
    if (mySeat !== undefined) {
      if (mySeat === playerTurn) {
        return true;
      }
    }
    return false;
  };

  const returnTableType = () => {
    if (tableType === "TEXAS_HOLDEM") {
      return "Texas Hold'em";
    }

    if (tableType === "OMAHA") {
      return "Omaha";
    }

    if (tableType === "OMAHA5") {
      return "Omaha 5";
    }

    if (tableType === "Tourney") {
      return "Tourney";
    }
  };

  const returnBlindValues = () => {
    return smallBlindValue && bigBlindValue
      ? `${parseDecimalValuesOfNumber(
          smallBlindValue,
          2,
          checkToDisplayValueInK(smallBlindValue),
          checkToDisplayValueInL(smallBlindValue)
        )} / ${parseDecimalValuesOfNumber(
          bigBlindValue,
          2,
          checkToDisplayValueInK(bigBlindValue),
          checkToDisplayValueInL(bigBlindValue)
        )}`
      : null;
  };

  return (
    <MiniGameTableContainer
      orientation={gameOrientation}
      isPlayerTurn={returnPlayerTurnOnTable()}
      isActiveGameTable={isActiveGameTable}
      playerTimer={playerTurnTimer}
      onTouchStart={() =>
        isLobby ? openGameTableFromLobby() : changeActiveTable()
      }
      id={`mini-gametable-container-${tableNumber}`}
    >
      <MiniGameTableImage src={MiniGameTableSVG} />
      {playerCards && playerCards.length !== 0 ? (
        <CardsContainer
          isActiveGameTable={isActiveGameTable}
          gameType={gameType}
        >
          {shouldPlayCardAnimation
            ? playerCards.map((card) => {
                return (
                  <Card
                    initial={{
                      y: 35,
                    }}
                    animate={{
                      y: 0,
                    }}
                    key={tableNumber + "currentUserData-" + card}
                    src={cardThemes.Decktab[card]}
                    gameType={gameType}
                  />
                );
              })
            : playerCards.map((card) => {
                return (
                  <Card
                    initial={{
                      y: 0,
                    }}
                    animate={{
                      y: 35,
                      transition: {
                        delay: 2.5,
                      },
                    }}
                    key={tableNumber + "currentUserData-" + card}
                    src={cardThemes.Decktab[card]}
                    gameType={gameType}
                  />
                );
              })}
        </CardsContainer>
      ) : (
        <MiniGameInfoContainer
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          isActiveGameTable={isActiveGameTable}
        >
          <TableName
            orientation={gameOrientation}
            isActiveGameTable={isActiveGameTable}
          >
            {returnTableType()}
          </TableName>
          <TableStakes
            orientation={gameOrientation}
            isActiveGameTable={isActiveGameTable}
            // smallFont={
            //   checkToDisplayValueInK(smallBlindValue) ||
            //   checkToDisplayValueInL(smallBlindValue) ||
            //   checkToDisplayValueInK(bigBlindValue) ||
            //   checkToDisplayValueInL(bigBlindValue)
            // }
          >
            {returnBlindValues()}
          </TableStakes>
        </MiniGameInfoContainer>
      )}
      {playerTurnTimer && playerTurnTimer > 0 && !isActiveGameTable ? (
        <MiniGameTableTimerContainer playerTimer={playerTurnTimer}>
          <MiniGameTableTimerValue>{playerTurnTimer}</MiniGameTableTimerValue>
        </MiniGameTableTimerContainer>
      ) : null}
      <AnimatePresence
        key={"AnimatePresence-MiniGameTablePotAmount-" + tableNumber}
      >
        {totalPotAmount && !playerData?.isWinner ? (
          <TotalPotAmountContainer
            key={'TotalPotAmountContainer-' + tableNumber}
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
            }}
            exit={{
              scale: 0,
            }}
          >
            <TotalPotChipIcon src={chipIcon} />
            <TotalPotValue>
              {BBDisplay
                ? parseDecimalValuesOfNumber(
                    totalPotAmount / bigBlindValue,
                    1
                  ) + " BB"
                : parseDecimalValuesOfNumber(
                    totalPotAmount,
                    2,
                    checkToDisplayValueInK(totalPotAmount),
                    checkToDisplayValueInL(totalPotAmount)
                  )}
            </TotalPotValue>
          </TotalPotAmountContainer>
        ) : null}
      </AnimatePresence>
      {playerData?.isWinner ? (
        <WinnerLabel initial={{ scale: 0 }} animate={{ scale: 1 }}>
          WINNER
        </WinnerLabel>
      ) : null}
    </MiniGameTableContainer>
  );
};

export default MiniGameTable;
