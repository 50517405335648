import Handler from "./../Handler";

class SpecialBetHandler extends Handler {
  // specialbet#
  constructor(service) {
    super(service);
  }
  execute(data) {
    data = JSON.parse(data).gamePools;
    this.service.porcessSpecialBets(data);
  }
}

export default SpecialBetHandler;