import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import {
  Typo12PebbleGrayContoMediumText,
  Typo14OffWhiteContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo14WhiteContoRegularText,
} from "../../../styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  background-color: ${colors.black60};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  min-height: 140px;
  height: auto;
  border-radius: 10px;
  background: ${colors.confirmPopupBackground};
  animation: springIn 0.4s forwards;
`;

export const ConfirmHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.deepTeal60};
`;

export const HeaderTitleText = styled(Typo14PrimaryGoldContoMediumText)`
  line-height: normal;
  ${(props) => (props.headerCustomStyles ? props.headerCustomStyles : "")};
`;

export const ConfirmBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`;

export const ConfirmText = styled(Typo14OffWhiteContoMediumText)`
  line-height: normal;
`;

export const DescriptionText = styled(Typo12PebbleGrayContoMediumText)`
  line-height: normal;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

export const NoteText = styled(Typo12PebbleGrayContoMediumText)`
  line-height: normal;
  margin-right: 8px;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 18px;
`;

export const SecondaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.wedgeWood};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmSecondaryCTAbackground};
  border-radius: 5px;
  &:disabled {
    opacity: 0.6;
  }
`;

export const PrimaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.dragonGreen};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmPrimaryCTAbackground};
  border-radius: 5px;
`;

export const ButtonText = styled(Typo14WhiteContoRegularText)`
  line-height: normal;
`;
