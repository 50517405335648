import React from "react";
import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  IconWrapper,
  MessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  CloseIcon,
  SecondaryCTAButton,
} from "./gameTableWatchChannelExistModalStyles";
import { useDispatch, useSelector } from "react-redux";

import GreatIcon from "../../../assets/gameTable/GreatIcon.svg";
import closeIcon from "../../../assets/common/close.svg";

import {
  closeLocalGameModal,
  exitGameTable,
  hideGameTable,
  openLocalGameModal,
  resetTableData,
} from "../../redux/slices/gameTableSlice";
import {
  closeTourneyInfoPage,
  handleTourneyStart,
} from "../../redux/slices/lobbySlice";
import TableUtils from "../../utils/TableUtils";
import { LEAVE_TABLE } from "../../data/Constants";
import { getGameSlice } from "../../utils/ReduxUtils";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import { getMLTLogFormat } from "../../utils/ProtocolUtils";
import GameConnectionsN from "../../io/GameConnectionsN";

function GameTableWatchChannelExistModal(props) {
  const dispatch = useDispatch();
  const playerName = useSelector((state) => state.lobby.player.userName);

  const handleModalClose = () => {
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  };

  // const handlePrimaryCTAClick = () => {
  //   dispatch(
  //     exitGameTable({ tempTableId: props.tempTableId, playerName: playerName })
  //   );
  //   TableUtils.getInstance().removeTableObserver(props.tempTableId, false);
  //   TableUtils.getInstance().overrideRemovedTableObserver(props.tempTableId);
  //   dispatch(
  //     resetTableData({ tempTableId: props.tempTableId, isTourneyGame: true })
  //   );
  //   handleModalClose();
  // };

  // const handlePrimaryCTAClick = () => {
  //   let allGames = getGameSlice().games;
  //   let isAnotherWatchTableFound = false;
  //   console.log("props.tourneyId: ", props.tourneyId);

  //   //should be sending close# protocol on already opened another tourney watch table
  //   console.log("allGames: ", allGames);//eachGameTempTableId
  //   for (let eachGameTempTableId in allGames) {
  //     let eachGameTableObject = allGames[eachGameTempTableId];
  //     console.log("eachGameTableObject:", eachGameTableObject);
  //     console.log("eachGameTempTableId: ", eachGameTempTableId, props.tempTableId);

  //     //have to confirm with team whether to use "eachGameTableObject.watchPlayer" or "!eachGameTableObject.playerSeat - <value undefined>"
  //     //above comment can be ignored
  //     if (eachGameTableObject.isTourneyGame && eachGameTableObject.gameDefinition.tourneyId === props.tourneyId &&
  //       eachGameTempTableId !== props.tempTableId) {
  //       console.log("Entered into if check");
  //       isAnotherWatchTableFound = true;
  //       dispatch(
  //         exitGameTable({ tempTableId: eachGameTempTableId, playerName: playerName })
  //       );
  //       //we are not waiting for any acknowledgement (closea#) from server
  //       TableUtils.getInstance().removeTableObserver(eachGameTempTableId);
  //       break;
  //     }
  //   }
  //   // dispatch(
  //   //   exitGameTable({ tempTableId: props.tempTableId, playerName: playerName })
  //   // );
  //   // TableUtils.getInstance().removeTableObserver(props.tempTableId, false);
  //   if (isAnotherWatchTableFound) {
  //     TableUtils.getInstance().overrideRemovedTableObserver(props.tempTableId);
  //     dispatch(
  //       resetTableData({ tempTableId: props.tempTableId, isTourneyGame: true })
  //     );
  //   } else {
  //     console.warn("Same tourney id watch table not found, closing this game window");
  //     TableUtils.getInstance().removeTableObserver(props.tempTableId);
  //   }

  //   handleModalClose();
  // };

  const handlePrimaryCTAClick = () => {
    console.log("ALERT modal config: ", props.modalConfig);
    if (props.modalConfig) {
      if (props.modalConfig.stage === "watchChannelExistProtocol") {
        handleModalClose();
        console.log(
          "watchChannelExistProtocol table data:",
          getGameSlice().games[props.tempTableId]
        );

        TableUtils.getInstance().overrideRemovedTableObserver(
          props.tempTableId
        );
        dispatch(
          resetTableData({
            tempTableId: props.tempTableId,
            isTourneyGame: true,
          })
        );
      } else if (props.modalConfig.stage === "watchChannelExistCheck") {
        //we are not waiting for any acknowledgement (closea#) from server
        let gameConnection = GameConnectionsN.getInstance().getGameConnection(
          props.tempTableId
        );
        if (gameConnection) {
          gameConnection.listener.close();
        }
        handleModalClose();
        TableUtils.getInstance().removeTableObserver(props.tempTableId);

        dispatch(
          handleTourneyStart({
            tourneys: [props.modalConfig.tableToStartDetails],
            isTourneyLackGame: false,
          })
        );
      } else {
        //just in case, ideally this case should not come
        handleModalClose();
        TableUtils.getInstance().removeTableObserver(props.tempTableId);
      }
    }
  };

  const handleSecondaryCTAClick = () => {
    handleModalClose();
    dispatch(closeTourneyInfoPage());

    if (
      props.modalConfig &&
      props.modalConfig.stage === "watchChannelExistProtocol"
    ) {
      TableUtils.getInstance().removeTableObserver(props.tempTableId);
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Confirm</HeaderTitleText>
          {/* <CloseIcon src={closeIcon} onClick={handleModalClose} /> */}
        </ModalHeader>

        <ModalBody>
          <IconWrapper src={GreatIcon} />
          <MessageText>
            A game was already being watched, are you sure you want to replace
            it?
          </MessageText>
          <CTAContainer>
            <SecondaryCTAButton onClick={handleSecondaryCTAClick}>
              <ButtonText>Cancel</ButtonText>
            </SecondaryCTAButton>
            <PrimaryCTAButton onClick={handlePrimaryCTAClick}>
              <ButtonText>Yes, I'm sure</ButtonText>
            </PrimaryCTAButton>
          </CTAContainer>
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default GameTableWatchChannelExistModal;
