import Handler from "../Handler";

class TourneyEliminateHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyEliminate(receivedData);
  }
}

export default TourneyEliminateHandler;
// tourneyEliminate#{"playerName":testuser}
