import Handler from "../Handler";

class RaiseActionBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRaiseActionBroadcast(receivedData);
  }
}

export default RaiseActionBroadcastHandler;
