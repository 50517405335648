import Handler from "../Handler";

class TourneyWatchPlayerChannelExistHandler extends Handler {
  execute(message) {
    this.service.processTourneyWatchPlayerChannelExist();
  }
}

export default TourneyWatchPlayerChannelExistHandler;

//WatchChannelExists#
