import styled from "styled-components";

export const GameTablesMainContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    animation: animate-game-tables-in 0.25s forwards;

    @keyframes animate-game-tables-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`
