import styled from "styled-components";
import { DISCONNECTION_TIMER, TIMER_TYPE_BUY_IN } from "../../../Poker/data/Constants";

export const Container = styled.div`
  position: ${(props) =>
    props.type === TIMER_TYPE_BUY_IN ? "relative" : "absolute"};
  // top: 1px;
  // left: 1px;
  width: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "98%" : "100%")};
  height: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "98%" : "100%")};
  top: 0px;
  left: 0px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* animation: ${(props) => props.totalTime === 1 ? 'fade-out 1s forwards' : null}; */
`;

export const CircularProgressOuter = styled.div`
  position: relative;
  // height: 51px;
  // width: 51px;
  width: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "38px" : "100%")};
  height: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "38px" : "100%")};
  /* height: calc(var(--app-width) * 0.1 + 3px) ; */
  /* width: calc(var(--app-width) * 0.1 + 3px); */
  /* min-height: 48px; */
  /* min-width: 48px; */
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

export const CircularDisc = styled.div`
  position: relative;
  width: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "30px" : "100%")};
  height: ${(props) => (props.type === TIMER_TYPE_BUY_IN ? "30px" : "100%")};
  // background-color: black;
  // height: 47px;
  // width: 47px;
  /* height: calc(var(--app-width) * 0.1 + 6px); */
  /* width: calc(var(--app-width) * 0.1 + 6px); */
  border-radius: 50%;
  display: grid;
  place-items: center;

  @media (min-width: 768px) {
    /* height: calc(var(--app-width) * 0.1 + 3px); */
    /* width: calc(var(--app-width) * 0.1 + 3px); */
  }
`;

export const CircularProgressInner = styled(CircularDisc)``;

export const ValueContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* animation: ${(props) => props.totalTime === 1 ? 'fade-out 1s forwards' : null}; */
`;

export const TimerValue = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  display: block;
`;

export const TimerType = styled.span`
  width: 100%;
  font-size: ${(props) => props.timerType !== DISCONNECTION_TIMER ? '8px' : '6px'};
  font-weight: 600;
  padding: ${(props) => props.timerType !== DISCONNECTION_TIMER ? '0 4px' : '0px'};
  color: #fff;
  position: relative;
  bottom: 3px;
`;
