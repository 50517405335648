import styled from "styled-components";
import colors from "../../styleGuide/Colors";
import { Typo14WhiteContoMediumText } from "../../styleGuide/Typos";

export const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--app-width);
  height: var(--app-height);
  z-index: 3;
  pointer-events: none;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const TakeSeatContainer = styled.div`
  position: absolute;
  bottom: 1%;
  width: 75%;
  max-width: ${(props) =>
    props.orientation === "LANDSCAPE" ? "400px" : "200px"};
  left: 50%;
  transform: translateX(-50%);
  background-color: #fffdea;
  border-radius: 5px;
  border: 2px solid #eabf69;
  /* display: ${(props) => props.orientation === "LANDSCAPE" ? "none" : "flex"}; */
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: ${(props) =>
    props.orientation === "LANDSCAPE" ? "2px 5px" : "5px 10px"};
  color: ${colors.deepBlue};
  font-weight: bold;
  z-index: 1;
`;

export const TakeSeatText = styled.span``;

export const InfoIconContainer = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 10px;
`;

export const DisconnectionAlertIconWrapper = styled.div`
  width: 80%;
  display: grid;
  padding: 5% 10%;
`;

export const DisconnectionIcon = styled.img`
  width: 20%;
  height: 100%;
  margin: 0 auto;
`;

export const DisconnectionMessage = styled(Typo14WhiteContoMediumText)`
  margin: 12px auto auto auto;
`;
