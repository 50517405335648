import React from "react";
import { DisconnectionLottie } from "../../../../Poker/components/NetworkLottie/DisconnectionLottie";
import { RELOGIN_FAILURE } from "../../../../Poker/data/Constants";
import { CloseGameButton } from "../../../../Poker/routes/Game/gameTableStyles";
import TableUtils from "../../../../Poker/utils/TableUtils";
import { ModalText, ModalWrapper } from "./styles";

const DisconnectionModal = ({ type, tempTableId }) => {
  const closeGameOnConFHandler = () => {
    TableUtils.getInstance().removeTableObserver(tempTableId);
  };

  return (
    <ModalWrapper>
      {/* <A23Icon /> */}
      <DisconnectionLottie size={50} loop={true} />
      <ModalText>
        {
          type === RELOGIN_FAILURE
          ?
          'Relogin failure.'
          :
          <>
            You have been disconnected. <br></br> Trying to reconnect..
          </>
        }
      </ModalText>
      {type === RELOGIN_FAILURE ? (
        <CloseGameButton
          onTouchStart={(e) => {
            // e.preventDefault();
            e.stopPropagation();
            closeGameOnConFHandler();
          }}
        >
          {Object.keys(tempTableId).length > 1 ? "Close" : "Go to Lobby"}
        </CloseGameButton>
      ) : null}
    </ModalWrapper>
  );
};

export default DisconnectionModal;
