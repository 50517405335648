import Handler from "../Handler";

class LackHandler extends Handler {
  execute(message) {
    /** Lack protocol in lobby will be received as a response to lack# request from client -> given upon every successful login.
     * Intention of this protocol is to open already running game(s) of this user.
     * {
        "realActiveGames": {
          "playerId": "pro549595",
          "activeGames": [
            {
              "gameId": "100124-55471064tmyx",
              "tableId": "THR100124-5414194626zb",
              "gameDefId": "502",
              "awsALBCookie": "LITCYoqSz8xEaP5sMUY/dEdo0sy4NfcK5zbW8LqrwoL66ZztDYARj/mmaUxUNBSSaUZPUMVIBldDW15YmkPKEyzEeYFsrDIpSBRuYbiNWHge62qESV3n8AuB30p9",
              "nodeIp": "wss://pokergame.qaa23rmg.com/websocket",
              "serverType": null,
              "isPlay": null,
              "isReal": true,
              "isTourney": null,
              "smallBlind": 1,
              "bigBlind": 2
            }
          ]
        },
        "tourneyActiveGames": null,
        "realWaitingGames": {
          "playerName": "pro549595",
          "waitingGamesList": [
            {
              "tableId": "THR100124-533327266x1f",
              "isWaitingForBB": false,
              "gameId": "100124-5333272zcj62",
              "gameDefId": 504,
              "awsALBCookie": "IQperNs8837le3Gr+Ee05j8NIlzFOJP8JFDNpbItSjfk/A66Rn1hRXcRJQftXwHiCwvHCkUYNLmIAyqaNQIANJQ6GqYys/wJH5SiPljlfGN0mUP2/OhPi/rSJkSw",
              "nodeIp": "wss://pokergame.qaa23rmg.com/websocket",
              "serverType": null,
              "isPlay": false,
              "isReal": true,
              "isTourney": false,
              "smallBlind": 10,
              "bigBlind": 20
            }
          ]
        }
      }
     *  */
    let receivedData = JSON.parse(message);
    if (receivedData) {
      this.service.processLackGames(receivedData);
    }
  }
}
export default LackHandler;
