import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableSettingsContainer,
  TableSettingsHeader,
  TableSettingsBody,
  TableSetting,
  Toggle,
  DisabledToggle,
  SettingText,
  TableSettingTypes,
  TableSettingType,
  AccordionSetting,
  AccordionSettingHeader,
  AccordionCheckboxContainer,
  Checkbox,
  AccordionSubOptionSection,
  AccordionInputContainer,
  AccordionInputTop,
  AccordionInputText,
  AccordionInputValue,
  AccordionInputRangeContainer,
  MinMaxContainer,
  HeaderText,
  AutoTopupCriteriaText,
  AccordianInputValueDescription,
  BettingOptionsContainer,
  BettingOptionColumn,
  BettingOptionHeader,
  BettingOptionText,
  DefaultBettingOptionDropdown,
  SliderContainer,
  BettingOptionPresetsWrapper,
  BettingOptionPreset,
  RaiseSliderWrapper,
  RaiseSliderRow,
  MarkersWrappers,
  Marker,
  MarkerDash,
  MarkerValue,
} from "./TableSettingsStyle";
import toggleOff from "../../../../assets/lobby/NewToggleOff.png";
import toggleOn from "../../../../assets/lobby/NewToggleOn.png";
import emptyCheckbox from "../../../../assets/lobby/emptyCheckbox.svg";
import checkbox from "../../../../assets/lobby/Checkbox.svg";
import {
  handleAutoTopupFallsBelowSliderChange,
  handleAutoTopupSliderChange,
  setDefaultPostflopPresetValue,
  setDefaultPreflopPresetValue,
  setPostflopPresetValues,
  setPreflopPresetValues,
  toggleAutoMuck,
  toggleAutoRebuy,
  toggleAutoTableSwitch,
  toggleAutoTopup,
  toggleDisplayInBB,
  toggleHandStrength,
  toggleHaptics,
  toggleSmartCheck,
  toggleSmartFold,
  toggleSmartGestures,
  toggleSound,
} from "../../../../Poker/redux/slices/gameTableSlice";
import * as gameAssets from "../../../../Poker/routes/Game/gameAssets";
import { RangeSlider } from "../../Inputs/RangeSlider/RangeSlider";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../../../Poker/redux/slices/sliderSlice";
import { RaiseSlider } from "../../Raise/RaiseActionStyles";
import raiseThumb from "../../../../assets/gameTable/RaiseThumb.svg";
import { getCurrentTimeStamp, getISTDateTimeFormat, parseDecimalValuesOfNumber } from "../../../utils/GameUtils";
import CleverTapUtil from "../../../utils/CleverTapUtil";
import { PR_Click_Betting_Options } from "../../../../Poker/data/ClevertapConstants";

const TableSettings = ({ sliderDirection }) => {
  const dispatch = useDispatch();
  const [activeSettingType, setActiveSettingType] = useState("Basic");
  const {
    showHandStrength,
    autoTableSwitch,
    autoMuck,
    smartGestures,
    BBDisplay,
    autoRebuy,
    autoTopupConfig,
    bettingOptions: {
      preflopPresetValues,
      postflopPresetValues,
      defaultPreflopPresetValue,
      defaultPostflopPresetValue,
    },
  } = useSelector((state) => state.gameTable.tableSettings);
  const soundSetting = useSelector(
    (state) => state.gameTable.tableSettings.sound
  );
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const channel = useSelector((store) => store.lobby.channel);
  const player = useSelector((store) => store.lobby.player);
  const hapticsSetting = useSelector(
    (state) => state.gameTable.tableSettings.haptics
  );
  const isTourneygame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isTourneyGame
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isFunGame
  );
  const settingTypes =
    isFunGame || isTourneygame
      ? ["Basic", "Betting Options"]
      : ["Basic", "Buy-in", "Betting Options"];

  const { smartFold, smartCheck } = useSelector(
    (state) => state.gameTable.tableSettings.smartGestureOptions
  );
  const topUpAmountRef = useRef(0);
  const topUpThresholdRef = useRef(0);
  const orientation = useSelector((state) => state.gameTable.tableOrientation);

  const [selectedPreflopPreset, setSelectedPreflopPreset] = useState(0);
  const [selectedPostflopPreset, setSelectedPostflopPreset] = useState(0);

  const [sliderPreflopValue, setSliderPreflopValue] = useState(
    defaultPreflopPresetValue
  );
  const [sliderPostflopValue, setSliderPostflopValue] = useState(
    defaultPostflopPresetValue
  );

  const defaultPreflopValues = [2, 2.5, 3, 3.5, 4];
  const defaultPostflopValues = [2, 2.5, 3, 3.5, 4];

  const renderBasicSettings = () => {
    return (
      <>
        <TableSetting>
          <SettingText>Show Hand Strength</SettingText>
          <Toggle
            onClick={() => dispatch(toggleHandStrength())}
            src={showHandStrength ? toggleOn : toggleOff}
          />
        </TableSetting>
        <TableSetting>
          {/*NOTE: currently disabled auto muck editing from client as per product comments https://hdworks.atlassian.net/browse/IM-2132 */}
          <SettingText disabled={true}>Auto Muck</SettingText>
          <DisabledToggle
            disabled={true}
            // onClick={() => dispatch(toggleAutoMuck())}
            src={autoMuck ? toggleOn : toggleOff}
          />
        </TableSetting>
        <TableSetting>
          <SettingText>Auto Table Switching</SettingText>
          <Toggle
            onClick={() => dispatch(toggleAutoTableSwitch())}
            src={autoTableSwitch ? toggleOn : toggleOff}
          />
        </TableSetting>
        {/* <TableSetting>
          <SettingText>Smart Gestures</SettingText>
          <Toggle
            onClick={() => dispatch(toggleSmartGestures())}
            src={smartGestures ? toggleOn : toggleOff}
            // src={toggleOff}
          />
        </TableSetting> */}
        <TableSetting>
          <SettingText>Display Chips In BB</SettingText>
          <Toggle
            onClick={() => dispatch(toggleDisplayInBB())}
            src={BBDisplay ? toggleOn : toggleOff}
          />
        </TableSetting>
        {renderHapticSettings()}
      </>
    );
  };

  function renderHapticSettings() {
    return (
      <>
        <TableSetting>
          <SettingText>Vibrations</SettingText>
          <Toggle
            onClick={() => dispatch(toggleHaptics())}
            src={hapticsSetting ? toggleOn : toggleOff}
          />
        </TableSetting>
        <TableSetting>
          <SettingText>Sound</SettingText>
          <Toggle
            onClick={() => dispatch(toggleSound())}
            src={soundSetting ? toggleOn : toggleOff}
          />
        </TableSetting>
        {/* <TableSetting>
          <SettingText>Gestures</SettingText>
          <Toggle
            onClick={() => dispatch(toggleSmartGestures())}
            src={smartGestures ? toggleOn : toggleOff}
          />
        </TableSetting> */}
        {renderGesturesAccordionSetting()}
      </>
    );
  }

  const renderBuyInSettings = () => {
    return (
      <>
        <TableSetting>
          <SettingText>Auto Rebuy</SettingText>
          <Toggle
            disabled={false}
            onClick={() => {
              dispatch(toggleAutoRebuy());
            }}
            src={autoRebuy ? toggleOn : toggleOff}
          />
        </TableSetting>
        {renderAutoTopUpAccordionSettings()}
      </>
    );
  };

  useEffect(() => {
    let preflopSettings = { ...preflopPresetValues };
    preflopSettings[selectedPreflopPreset] = sliderPreflopValue;
    dispatch(setPreflopPresetValues(preflopSettings));
  }, [sliderPreflopValue]);

  useEffect(() => {
    setSliderPreflopValue(preflopPresetValues[selectedPreflopPreset]);
  }, [selectedPreflopPreset]);

  useEffect(() => {
    let postflopSettings = { ...postflopPresetValues };
    postflopSettings[selectedPostflopPreset] = sliderPostflopValue;
    dispatch(setPostflopPresetValues(postflopSettings));
  }, [sliderPostflopValue]);

  useEffect(() => {
    setSliderPostflopValue(postflopPresetValues[selectedPostflopPreset]);
  }, [selectedPostflopPreset]);

  const renderBettingOptions = () => {
    return (
      <BettingOptionsContainer>
        <BettingOptionColumn>
          {/* <BettingOptionHeader>Pre Flop Raise</BettingOptionHeader> */}
          <BettingOptionHeader>Pre Flop Default Raise</BettingOptionHeader>
          <DefaultBettingOptionDropdown
            value={defaultPreflopPresetValue}
            onChange={(event) =>
              dispatch(setDefaultPreflopPresetValue(event.target.value))
            }
            type="select"
          >
            {defaultPreflopValues.map((item, index) => (
              <option key={"default-preflop-values-" + index} value={item}>
                {item} BB
              </option>
            ))}
          </DefaultBettingOptionDropdown>
          <BettingOptionHeader>Pre Flop Betting Presets</BettingOptionHeader>
          <SliderContainer>
            <BettingOptionPresetsWrapper
              style={{ flexDirection: "column-reverse" }}
            >
              {Object.keys(preflopPresetValues).map((key, index) => (
                <BettingOptionPreset
                  onClick={() => setSelectedPreflopPreset(index)}
                  selected={index === selectedPreflopPreset}
                >
                  {preflopPresetValues[key]} BB
                </BettingOptionPreset>
              ))}
            </BettingOptionPresetsWrapper>
            <RaiseSliderRow>
              <RaiseSliderWrapper
                sliderValue={((sliderPreflopValue - 2) / 6) * 100}
                raiseThumb={raiseThumb}
              >
                <RaiseSlider
                  type={"range"}
                  step={0.1}
                  onChange={(event) =>
                    setSliderPreflopValue(event.target.value)
                  }
                  value={sliderPreflopValue}
                  // value={parseDecimalValuesOfNumber(0, 2)}
                  min={2}
                  max={8}
                  // onChange={handleSliderChange}
                />
              </RaiseSliderWrapper>
              <MarkersWrappers>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>8BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>7BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>6BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>5BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>4BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>3BB</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>2BB</MarkerValue>
                </Marker>
              </MarkersWrappers>
            </RaiseSliderRow>
          </SliderContainer>
        </BettingOptionColumn>
        <BettingOptionColumn>
          {/* <BettingOptionHeader>Post Flop Raise</BettingOptionHeader> */}
          <BettingOptionHeader>Post Flop Default Raise</BettingOptionHeader>
          <DefaultBettingOptionDropdown
            value={defaultPostflopPresetValue}
            onChange={(event) =>
              dispatch(setDefaultPostflopPresetValue(event.target.value))
            }
            type="select"
          >
            {defaultPostflopValues.map((item, index) => (
              <option key={"default-post-flop-values-" + index} value={item}>
                {item} BB
              </option>
            ))}
          </DefaultBettingOptionDropdown>
          <BettingOptionHeader>Post Flop Betting Presets</BettingOptionHeader>
          <SliderContainer>
            <BettingOptionPresetsWrapper
              style={{ flexDirection: "column-reverse" }}
            >
              {Object.keys(postflopPresetValues).map((key, index) => (
                <BettingOptionPreset
                  onClick={() => setSelectedPostflopPreset(index)}
                  selected={index === selectedPostflopPreset}
                >
                  {postflopPresetValues[key]}x
                </BettingOptionPreset>
              ))}
            </BettingOptionPresetsWrapper>
            <RaiseSliderRow>
              <RaiseSliderWrapper
                sliderValue={((sliderPostflopValue - 0.25) / 1.75) * 100}
                raiseThumb={raiseThumb}
              >
                <RaiseSlider
                  type={"range"}
                  step={0.05}
                  onChange={(event) =>
                    setSliderPostflopValue(event.target.value)
                  }
                  value={sliderPostflopValue}
                  // value={parseDecimalValuesOfNumber(0, 2)}
                  min={0.25}
                  max={2}
                  // onChange={handleSliderChange}
                />
              </RaiseSliderWrapper>
              <MarkersWrappers>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>2x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>1.75x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>1.5x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>1.25x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>1x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>0.75x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>0.5x</MarkerValue>
                </Marker>
                <Marker>
                  <MarkerDash></MarkerDash>
                  <MarkerValue>0.25x</MarkerValue>
                </Marker>
              </MarkersWrappers>
            </RaiseSliderRow>
          </SliderContainer>
        </BettingOptionColumn>
      </BettingOptionsContainer>
    );
  };

  const renderGesturesAccordionSetting = () => {
    return (
      <AccordionSetting background={smartGestures}>
        <AccordionSettingHeader>
          <SettingText>Gestures</SettingText>
          <Toggle
            onClick={() => dispatch(toggleSmartGestures())}
            src={smartGestures ? toggleOn : toggleOff}
          />
        </AccordionSettingHeader>
        {smartGestures && (
          <AccordionSubOptionSection>
            <AccordionCheckboxContainer>
              <SettingText>Double Tap On Check</SettingText>
              <Checkbox
                onClick={() => dispatch(toggleSmartCheck())}
                src={smartCheck ? checkbox : emptyCheckbox}
              />
            </AccordionCheckboxContainer>
            <AccordionCheckboxContainer>
              <SettingText>Drag to Fold</SettingText>
              <Checkbox
                onClick={() => dispatch(toggleSmartFold())}
                src={smartFold ? checkbox : emptyCheckbox}
              />
            </AccordionCheckboxContainer>
          </AccordionSubOptionSection>
        )}
      </AccordionSetting>
    );
  };

  const handleAutoTopupToggle = () => {
    dispatch(toggleAutoTopup());
  };

  const handleFallsBelowSlider = (value) => {
    dispatch(handleAutoTopupFallsBelowSliderChange({ value: value }));
  };

  const handleAutoTopupSlider = (value) => {
    dispatch(handleAutoTopupSliderChange({ value: value }));
  };

  const renderAutoTopUpAccordionSettings = () => {
    const {
      enabled,
      fallsBelowAmount,
      topUpAmount,
      fallBelowMinAmount,
      fallsBelowMaxAmount,
      topUpMinAmount,
      topUpMaxAmount,
    } = autoTopupConfig;
    return (
      <AccordionSetting disabled={false} background={enabled}>
        <AccordionSettingHeader>
          <SettingText>Auto Topup</SettingText>
          <Toggle
            id="buyin-auto-topup"
            disabled={false}
            onClick={handleAutoTopupToggle}
            src={enabled ? toggleOn : toggleOff}
          />
        </AccordionSettingHeader>
        {enabled && (
          <AccordionSubOptionSection>
            <AutoTopupCriteriaText>
              You can only top-up if your total balance meets the minimum buy-in
              criteria
            </AutoTopupCriteriaText>
            <div id="auto-topup-sliders">
              <AccordionInputContainer>
                <AccordionInputTop>
                  <AccordionInputText>
                    If my stack falls below
                  </AccordionInputText>
                  <AccordionInputValue>
                    {`${fallsBelowAmount} %`}
                    <AccordianInputValueDescription>
                      of max buy-in
                    </AccordianInputValueDescription>
                  </AccordionInputValue>
                </AccordionInputTop>
                <AccordionInputRangeContainer>
                  <RangeSlider
                    min={1}
                    max={100}
                    inputRefs={topUpAmountRef}
                    passInputValueToParentCallBack={handleFallsBelowSlider}
                    initialValue={fallsBelowAmount === 0 ? 1 : fallsBelowAmount}
                    type={"table-settings"}
                  />
                  <MinMaxContainer>
                    <AccordionInputText>Min</AccordionInputText>
                    <AccordionInputText>Max</AccordionInputText>
                  </MinMaxContainer>
                </AccordionInputRangeContainer>
              </AccordionInputContainer>

              <AccordionInputContainer>
                <AccordionInputTop>
                  <AccordionInputText>
                    Auto top-up my stack up to
                  </AccordionInputText>
                  <AccordionInputValue>
                    {`${topUpAmount} %`}
                    <AccordianInputValueDescription>
                      of max buy-in
                    </AccordianInputValueDescription>
                  </AccordionInputValue>
                </AccordionInputTop>
                <AccordionInputRangeContainer>
                  <RangeSlider
                    min={Math.max(10, fallsBelowAmount)}
                    max={100}
                    inputRefs={topUpThresholdRef}
                    passInputValueToParentCallBack={handleAutoTopupSlider}
                    initialValue={topUpAmount}
                    type={"table-settings"}
                  />
                  <MinMaxContainer>
                    <AccordionInputText>Min</AccordionInputText>
                    <AccordionInputText>Max</AccordionInputText>
                  </MinMaxContainer>
                </AccordionInputRangeContainer>
              </AccordionInputContainer>
            </div>
          </AccordionSubOptionSection>
        )}
      </AccordionSetting>
    );
  };

  const handleSettingChange = (type) => {
    if (type === "Betting Options")
      CleverTapUtil.getInstance().logEvent(PR_Click_Betting_Options, {
        Username: player.userName,
        channel: channel,
        Timestamp: getISTDateTimeFormat(),
      });
    setActiveSettingType(type);
  };

  return (
    <TableSettingsContainer
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
      orientation={orientation}
      id="TableSettingsContainer"
    >
      <TableSettingsHeader id="TableSettingsHeader">
        <img style={{ marginRight: "10px" }} src={gameAssets.setting}></img>
        <HeaderText>Table Settings</HeaderText>
      </TableSettingsHeader>
      <TableSettingTypes>
        {settingTypes.map((type, index) => (
          <TableSettingType
            key={"settingTypes-" + type}
            id={"setting-" + type}
            onClick={() => handleSettingChange(type)}
            active={type === activeSettingType}
          >
            {type}
          </TableSettingType>
        ))}
      </TableSettingTypes>
      <TableSettingsBody id="TableSettingsBody">
        {activeSettingType === "Basic"
          ? renderBasicSettings()
          : activeSettingType === "Haptics"
          ? renderHapticSettings()
          : activeSettingType === "Buy-in"
          ? renderBuyInSettings()
          : activeSettingType === "Betting Options"
          ? renderBettingOptions()
          : null}
      </TableSettingsBody>
    </TableSettingsContainer>
  );
};

export default TableSettings;
