import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const ModalContainer = styled.div`
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    z-index: 5;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${colors.secondaryBlue};
    animation: springIn 0.4s forwards;
`

export const ModalHeader = styled.div`
    background-color: ${colors.secondaryBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20%;
    padding: 0.5rem;
`

export const ModalTitle = styled.span`
    color: white;
    font-size: 16px;
`

export const ModalBody = styled.div`
    height: 80%;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.primaryBlue};
`

export const ModalText = styled.span`
    color: white;
    font-size: 14px;
    font-weight: ${(props) => props.bold ? 'bold' : null};
`

export const ModalButton = styled.button`
    background: ${(props) => props.type === 'secondary' ? 'transparent' : colors.primaryGreen};
    width: 50%;
    color: ${(props) => props.type === 'secondary' ? colors.primaryGold : 'white'};
    border:  ${(props) => props.type === 'secondary' ? `1px solid ${colors.primaryGold}` : 'none'};
    margin: 0rem 0rem 1rem 0rem;
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-radius: 5px;
    font-weight: ${(props) => props.bold ? 'bold' : null};
    font-family: 'Conto';
`

export const TextContainer = styled.div`
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
`