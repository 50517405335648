//NOTE: we will get this protocol for both lobby and game sockets.
// If loggedin user is winner he will receive in the game socket. if he is the busted out player and he is the part of winning hand we will receive in the lobby socket

import Handler from "../Handler";

class TourneyPrizeHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPrizeData(receivedData);
  }
}
export default TourneyPrizeHandler;

// TourneyPrize#
// String tourneyId;
// String playerName;
// double playerWinning;
