import styled from "styled-components";
import {
  Typo10WhiteContoRegularText,
  Typo12WhiteContoRegularText,
  Typo14WhiteContoBoldText,
  Typo14WhiteContoRegularText,
} from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  /* background-color: ${colors.black80}; */
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  transform: ${(props) => props.orientation === 'LANDSCAPE' ? 'scale(0.75)' : 'translateY(-10%)'};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  min-height: 140px;
  height: auto;
  border-radius: 8px;
  background: ${colors.darkGreen};
  animation: springIn 0.4s forwards;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  justify-content: center;
  align-items: center;

  background-color: ${colors.maastrichtblue};
  border-radius: 8px 8px 0px 0px;
`;

export const HeaderTitleText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
  padding-left: 24px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px 20px 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const IconWrapper = styled.img`
  width: 60px;
  height: 60px;
`;

export const MessageText = styled(Typo14WhiteContoRegularText)`
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const PrimaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.dragonGreen};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmPrimaryCTAbackground};
  border-radius: 5px;
  &:disabled {
    background: gray;
    opacity: 0.6;
  }
`;

export const ButtonText = styled(Typo14WhiteContoRegularText)`
  line-height: normal;
`;

export const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 24px;
`;

export const IssueTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 14px;
  justify-content: flex-start;
  align-items: center;
`;

export const RadioElement = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 15px;
`;

export const IssueTypeText = styled(Typo12WhiteContoRegularText)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 14px;
  border-radius: 10px;
  background: ${colors.maastrichtblue};
  padding: 8px;
  font-size: 12px;
  color: ${colors.white};
  border: 0.5px solid ${colors.blueSapphire};
  box-sizing: border-box;
  &:focus {
    outline: 2px solid ${colors.deepTeal};
  }
`;

export const FraudReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const SelectUsersContainer = styled.div``;
export const SelectUsersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 87px;
  height: 24px;
  border: 1px solid ${colors.blueSapphire};
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 87px;
  height: auto;
  border: 1px solid ${colors.blueSapphire};
  border-radius: 4px;
  position: absolute;
  background-color: ${colors.maastrichtblue};
  z-index: 1;
  margin-top: 2px;
  padding: 2px;
  box-sizing: border-box;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 6px;
`;

export const CheckboxIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 6px;
`;

export const UserNameText = styled(Typo10WhiteContoRegularText)``;

export const SelectPlaceholderText = styled(Typo12WhiteContoRegularText)`
  padding-left: 4px;
  padding-right: 4px;
  min-width: 60px;
`;

export const AccordianIconWrapper = styled.img`
  height: 13px;
  width: 12px;
`;
