import Handler from "../Handler";

class TourneyBreakEndHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyBreakEnd(receivedData);
  }
}

export default TourneyBreakEndHandler;
// TourneyBreakEnd#{"tableId":"TOTGXG020424-0122582231221071fk","message":"Addon break is ended, games will start now"}
