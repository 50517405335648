import { setPlayerRemainingTime } from "../../Poker/redux/slices/gameTableSlice";

class TimerObserver {
  constructor(dispatchFunction, games) {
    this.dispatchFunction = dispatchFunction;
    this.games = games;
    this.interval = {};
  }

  cleanup() {
    this.dispatchFunction = null;
    this.games = null;
    for (let tempTableId of Object.keys(this.interval)) {
      clearInterval(this.interval[tempTableId]);
    }
    this.interval = {};
  }

  timeoutHandler() {
    for (let tempTableId in this.games) {
      let gameObject = this.games[tempTableId];
      // Commented the below out because we want to count down remaining time for player turn regardless of whether it is logged in player or not
      // let playerSeat = gameObject.playerSeat;
      let playerSeat = gameObject.playerTurn;
      let playerDetails = gameObject.playerData.find(
        (player) => player.position === playerSeat
      );
      if (playerDetails?.remainingTime) {
        let time = playerDetails.remainingTime;
        let newTime = time;
        this.interval[tempTableId] = setInterval(() => {
          newTime = newTime - 1;
          if (newTime >= 0) {
            this.dispatchFunction(
              setPlayerRemainingTime({
                tempTableId: tempTableId,
                remainingTime: newTime,
                playerPosition: playerSeat,
              })
            );
          } else {
            this.dispatchFunction(
              setPlayerRemainingTime({
                tempTableId: tempTableId,
                remainingTime: false,
                playerPosition: playerSeat,
              })
            );
            clearInterval(this.interval[tempTableId]);
            this.interval = null;
          }
        }, 1000);
      } else {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
    return;
  }
}

export default TimerObserver;
