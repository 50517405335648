import React from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { CardImages } from "../../../assets/cards";
import rabbitCard from '../../../assets/cards/rabbitCard.png'
import { cardThemes } from "../../../assets/cards";
import { useSelector } from "react-redux";
import "./FlipCard.css";

const FlipCard = ({
  cardWidth,
  source,
  isRabbitCardFlipped,
  flipCard,
  horizontalExitAmount,
  doCommunityCardsExist,
  darkenCard,
  key
}) => {
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );

  const handleTap = () => {
    flipCard();
  };

  return (
    <AnimatePresence key={key}>
      {doCommunityCardsExist && (
        <motion.div
          className="framer-flip-card-container"
          style={{ width: cardWidth + "px" }}
          exit={{
            y: -50,
            x: horizontalExitAmount,
            scale: 0.25,
            opacity: 0,
            // transition: {
            //   delay: 2.5,
            // },
          }}
        >
          <motion.div
            className="framer-flip-card"
            animate={{
              opacity: 1,
              scale: 1,
            }}
            initial={{
              opacity: 0,
              scale: 0,
            }}
            onClick={handleTap}
            style={{
              width: cardWidth + "px",
              height: "auto",
              perspective: 1000,
              zIndex: 4,
            }}
          >
            <div style={{ width: "100%" }} className="framer-flip-card-inner">
              <motion.div
                animate={{
                  rotateY: isRabbitCardFlipped ? 180 : 0,
                }}
                style={{ width: "100%" }}
                className="framer-flip-card-front"
              >
                <img
                  style={{ width: "100%" }}
                  src={rabbitCard}
                ></img>
              </motion.div>
              <motion.div
                animate={{
                  rotateY: isRabbitCardFlipped ? 0 : 180,
                  filter: darkenCard ? 'brightness(0.6)' : null,
                }}
                style={{ width: "100%" }}
                className="framer-flip-card-back"
              >
                <img
                  style={{ width: "100%" }}
                  src={cardThemes[cardDeckTheme][source]}
                ></img>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FlipCard;
