import React from "react";
import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  IconWrapper,
  MessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  CloseIcon,
  SecondaryCTAButton,
} from "./tourneyCompleteModalStyles";
import { useDispatch, useSelector } from "react-redux";

import GreatIcon from "../../../assets/gameTable/GreatIcon.svg";
import closeIcon from "../../../assets/common/close.svg";

import {
  closeLocalGameModal,
  exitGameTable,
  hideGameTable,
  resetTableData,
} from "../../redux/slices/gameTableSlice";
import { closeTourneyInfoPage } from "../../redux/slices/lobbySlice";
import TableUtils from "../../utils/TableUtils";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import GameConnectionsN from "../../io/GameConnectionsN";

function TourneyCompleteModal(props) {
  const dispatch = useDispatch();
  const playerName = useSelector((state) => state.lobby.player.userName);
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );

  const handleModalClose = () => {
    let gameConnection = GameConnectionsN.getInstance().getGameConnection(
      props.tempTableId
    );
    if (gameConnection) {
      gameConnection.listener.close();
    }
    dispatch(closeTourneyInfoPage());
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
    TableUtils.getInstance().removeTableObserver(props.tempTableId);
  };

  const handleSecondaryCTAClick = () => {
    handleModalClose();
  };

  const handlePrimaryCTAClick = () => {
    if (gameOrientation === "LANDSCAPE") {
      messageToNativeClient({
        type: "orientation",
        viwe: "portrait",
        currentScreen: "table",
      });
    }
    messageToNativeClient({
      type: "openPokerLeaderboard",
      data: {
        Location: "openPokerLeaderboard",
        Tab: "Tourney",
        SubTab: "Completed",
      },
    });
    handleModalClose();
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Thanks for Watching!</HeaderTitleText>
          {/* <CloseIcon src={closeIcon} onClick={handleModalClose} /> */}
        </ModalHeader>

        <ModalBody>
          <IconWrapper src={GreatIcon} />
          <MessageText>
            It was a good game. See the winning positions in leaderboard!
          </MessageText>
          <CTAContainer>
            {/* <SecondaryCTAButton onClick={handlePrimaryCTAClick}>
              <ButtonText>Go to Lobby</ButtonText>
            </SecondaryCTAButton> */}
            <PrimaryCTAButton onClick={handleSecondaryCTAClick}>
              <ButtonText>Go to Lobby</ButtonText>
            </PrimaryCTAButton>
          </CTAContainer>
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default TourneyCompleteModal;
