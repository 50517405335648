import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import {
  Typo12GrayContoRegularText,
  Typo12WhiteContoRegularText,
} from "../../../styleGuide/Typos";

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 5%;
  &:first-child {
    padding-top: 0;
  }
`;

export const InfoTitle = styled(Typo12GrayContoRegularText)`
  width: 45%;
  text-align: left;
`;
export const InfoDivider = styled.div`
  width: 10%;
  color: ${colors.white};
`;

export const InfoDetail = styled(Typo12WhiteContoRegularText)`
  width: 45%;
  text-align: right;
`;
