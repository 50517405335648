import Handler from "../Handler";

class LeaveBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processLeaveBroadcast(receivedData);
  }
}

export default LeaveBroadcastHandler;
