import Handler from "../Handler";

class AntiBankingHandler extends Handler {
  // AB#{
  //     playerName = bcdhc,
  //     tableId = cvdcvh,
  //     antiBankingAmount = 1000,
  //     startTime = 4753443434368,
  //     endtime= 36643343434}
  
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAntiBankingDetails(receivedData);
  }
}

export default AntiBankingHandler;
