class ScrollUtils {
    static scrollUtilInstance;

    constructor() {
        if (!ScrollUtils.scrollUtilInstance) {
            this.lobbyComponentsScrollPositions = {
                gameTilesListScrollTop: 0
            }
            this.init();
            ScrollUtils.scrollUtilInstance = this;
        }
        return ScrollUtils.scrollUtilInstance;
    }

    init = () => {
        //can be removed later, adding for now just in case
    };

    updateLobbyScrollPositions = (scrollValueObject) => {
        if (scrollValueObject.type = "gameTileList") {
            this.lobbyComponentsScrollPositions.gameTilesListScrollTop = scrollValueObject.scrollTop;
        }
    }

    static getInstance() {
        if (!ScrollUtils.scrollUtilInstance) {
            return new ScrollUtils();
        } else {
            return ScrollUtils.scrollUtilInstance;
        }
    }
}

export default ScrollUtils;