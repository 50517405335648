
import Handler from "../Handler";

class TourneyPlayerStatusHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPlayerStatusChange(receivedData);
  }
}
export default TourneyPlayerStatusHandler;

// tps#{"playerStatusObjList":[{"tourneyId":"Z3GY8","tourneyPlayerStatus":"Registered","reminder":false}]}
