const colors = {
  pureWhite: "#ffffff",
  white: "#FFFDEA",
  whiteTwo: "#FFFDEA",
  gray: "#BABABA",
  lightYellow: "#FFFDEA",
  primaryGold: "#EABF69",
  navy2: "#3A4C71",
  tempNavy2: "rgb(51,96,112,0.5)",
  tourneySecondaryGreen: "#42BE40",
  offWhite: "#FFFDEA",
  primaryBlue: "#04303b",
  oxfordBlue: "#37535B",
  shamRock: "#36CF86",
  pebbleGray: "#9C9D9F",
  dainTree: "#03252D",
  tundora: "#474646",
  doveGray: "#707070",
  cloudy: "#A8A7A6",
  deepBlue: "#032028",
  deepBlue95: "rgba(8, 13, 26, 0.95)",
  secondaryBlue: "#336070", //
  tuftsBlue: "#4A75BC",
  tuftsBlue24: "rgb(74, 117, 188,0.24)",
  ballBlue: "#36AACF",
  brandyPunch: "#CD7F32",
  yellowOrange: "#FFB834",
  brandyPunch24: "rgb(205, 127, 50,0.24)",
  carnation: "#F15757",
  pastelGreen: "#5AE190",
  silverChalice: "#ADADAD",
  lightCornflowerBlue: "#91CFDE",
  leafyGreen: "#42BE40",
  leafyGreen24: "rgb(66, 190, 64,0.24)",
  green: "rgba(66, 190, 64, 0.50)",
  black60: "rgb(8, 13, 26, 0.6)",
  black40: "rgb(8, 13, 26, 0.4)",
  black80: "rgb(8, 13, 26, 0.8)",
  primaryBlue60: "rgb(3, 33, 70,0.6)",
  deepTeal60: "rgba(7, 80, 99, 0.60)",
  tealBlue: "#1085A4",
  casal40: "rgba(51, 96, 112, 0.4)",
  vampireGrey: "#4F5153",
  wedgeWood: "#4B889D",
  maastrichtblue: "#042A34",
  blueSapphire: "#0A5F75",
  darkGreen: "#032028",
  dragonGreen: "#65F48E",
  transparent: "rgba(255, 255, 255, 0)",
  winnerCardHighLight: "#00dd47",
  // winnerCardHighLight: "rgba(234, 191, 105, 1)",
  highlightNavy2: "#3A4C71",
  highlightNavy3: "#075063",
  highlightNavy3With60: "rgb(7, 80, 99,0.6)",
  timerGreen: "#005e14bd",
  timerBorderGreen: "#1C5A2D",
  timerRed: "#8c0000e0",
  primaryRed: "#C62828",
  secondaryRed: "#F0745C",
  secondaryRed24: "rgb(240, 116, 92, 0.24)",
  timerOrange: "#ab4612e0",
  firefly: "#022933",
  eastrenBlue59: "rgba(36, 158, 190, 0.59)",
  blueGreen: "#1597B8",
  blackPeral: "#06161B",
  deepSeaGreen: "#0F5365",
  blueWhale: "#04333F",
  petrol: "#06556A",
  saffronMango: "#ECC051",
  deepTeal: "#075063",
  nileBlue: "#13404C",
  putty: "#E6C683",
  downy: "#77BDD6",
  // timerGreen: "rgba(61, 190, 62, 0.8)",
  // timerBorderGreen: "#1C5A2D",
  // timerRed: "#af1a1ade",
  // timerOrange: "#d97720de",
  ctaPrimaryColor: "linear-gradient(180deg, #19B25F 0%, #065E3E 100%)",
  ctaPrimaryColor1: "linear-gradient(180deg, #42be40 0%, #196a29 100%)",

  // Ripple

  ripplePrimaryGold: "rgba(234, 191, 105, 0.85)",
  rippleSecondaryGold: "rgba(234, 191, 105, 0.55)",

  ripplePrimaryGreen: "rgba(66, 190, 64, 0.85)",
  rippleSecondaryGreen: "rgba(66, 190, 64, 0.55)",

  ripplePrimaryRed: "rgba(175, 26, 26, 0.85)",
  rippleSecondaryRed: "rgba(175, 26, 26, 0.55)",

  ripplePrimaryOrange: "rgba(217, 119, 32, 0.85)",
  rippleSecondaryOrange: "rgba(217, 119, 32, 0.55)",
  toastBackgroundColor:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), rgba(7, 80, 99, 0.50)",
  // game table
  gameTableBg: " #1d1610",

  //Linear gradients
  primaryDeepBlue: "linear-gradient(180deg, #032028 0%, #04303b 100%)",
  secondaryDeepBlue: "linear-gradient(180deg, #04303b 0%, #032028 100%)",
  primaryGray: "linear-gradient(180deg, #336070 0%, #075063 100%)",
  primaryGreen:
    "linear-gradient(180deg, #42B546 0%, rgba(66, 181, 70, 0.65) 100%)",

  secondaryGreen: "linear-gradient(180deg, #42BE40 0%, #196A29 100%)",

  // Button
  redBtn: "linear-gradient(180deg, #F0745C 0%, #C62828 100%);",
  greenBtn: "linear-gradient(180deg, #35A753 0%, #276E3A 100%);",
  blueBtn:
    "linear-gradient(180deg, #58B6C6 0%, rgba(88, 182, 198, 0.4) 100%), #000000;",

  lightGrey:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #4F5153",

  hoverLightGrey:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.8)), #262627",

  darkGrey: "linear-gradient(180deg, #4A4C53 0%, #1F2022 100%)",

  hoverDarkGrey: "linear-gradient(180deg, #3A3C41 0%, #171819 100%)",

  // Action label
  callAction: "linear-gradient(180deg, #198136 0%, #125C2E 100%)",
  checkAction: "linear-gradient(180deg, #2D448F 0%, #25376E 100%)",
  raiseAction: "linear-gradient(180deg, #2C7493 0%, #225474 100%)",
  foldAction: "linear-gradient(180deg, #840A0A 0%, #6C0707 100%)",
  allInAction: "linear-gradient(180deg, #A23D0E 0%, #731B08 100%)",
  // Action Borders
  callBorder: "rgba(108, 255, 154, 1)",
  checkBorder: "rgba(161, 183, 255, 1)",
  raiseBorder: "rgba(165, 227, 255, 1)",
  foldBorder: "rgba(255, 114, 114, 1)",
  allInBorder: "rgba(255, 138, 88, 1)",
  popupBgColor:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),linear-gradient(0deg, #075063, #075063)",
  // Raise action
  raiseSliderDefaultBgColor: " linear-gradient(3deg, #0D2D5C 0%, #3463AF 100%)",
  cornFlowerBlue: "#22365566",
  eastBay: "#3a4c71",
  raiseSliderFilled: `linear-gradient(270deg, #42BE40 3.12%, #42BE40 19.79%, #094A5A 70.31%)`,
  raiseSliderThumbColor: `  linear-gradient(84deg, #054454 3.64%, #075063 95.45%)`,
  black: "#000000ad",
  pitchBlack: "#000",
  cashFunToggleActiveColor: `linear-gradient( 0deg,rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), linear-gradient(3deg, #075063 -7.51%, #336070 86.57%)`,
  sideMenuHeader: ` linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0.4) 100%),#075063`,
  sideMenuSeperator: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(51, 96, 112, 0.20) 0%, rgba(51, 96, 112, 0.80) 33.85%, rgba(51, 96, 112, 0.80) 66.67%, rgba(51, 96, 112, 0.20) 100%)`,
  tourneyTileSeperator: ` linear-gradient(90deg,rgba(51, 96, 112, 0.1) 0%,rgba(51, 96, 112, 0.4) 33.85%,rgba(51, 96, 112, 0.4) 66.67%,rgba(51, 96, 112, 0.1) 100%)`,
  tourneyDetailSeperator: "rgba(51, 96, 112, 0.5)",
  tourneyBonusBarPrimary: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(270deg, #000 0%, #075063 49.3%, #000 100%)`,
  rangeSliderBackground: `linear-gradient(0deg,rgba(7, 80, 99, 0.14) 0%,rgba(7, 80, 99, 0.14) 100%),linear-gradient(0deg, #01171d 57.8%, rgba(34, 54, 85, 0) 108.3%)`,
  tableSettingsUnfilledSlider: "rgba(1, 23, 29, 0.85)",
  buyInRangeSliderUnfilled: "rgba(7, 80, 99, 0.14)",
  rangeSliderUnfilled:
    "linear-gradient(90deg, rgba(0, 0, 0, 0.20) 50.84%, rgba(54, 55, 56, 0.20) 97.5%), #000C0F",
  tourneyBonusBarFilled: "#42BE40",
  confirmPopupBackground: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), #075063`,
  confirmSecondaryCTAbackground:
    " linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%), #336070",
  confirmPrimaryCTAbackground:
    "linear-gradient(181deg, rgba(66, 190, 64, 0.80) 0.47%, #196A29 97.86%)",
  tourneyInfoBackground:
    "linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#075063",
  tourneyBannerBackground:
    "linear-gradient(90deg,rgba(5, 44, 55, 0.41) 0%,rgba(12, 17, 18, 0.8) 31.25%,rgba(12, 17, 18, 0.8) 65.1%,rgba(5, 44, 55, 0.41) 100%)",
  tourneyBannerBorder: "rgba(234, 191, 105, 0.6)",
};

export default colors;

// 032146 --->  04303b;
// 080D1A ---> 032028;
//FFFFFF ---> FFFDEA
//FAFAFA ---> FFFDEA
// BABABA --->
//223655 ---> 336070
//
