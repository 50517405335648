import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Poker/redux/store";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const disableLogs = () => {
  try {
    let console = {};
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};

    window.console = console;
  } catch (error) {
    console.log("Error while disabling logs:", error);
  }
};

if (process.env.REACT_APP_DISABLE_CONSOLE_STATEMENTS === "true") {
  disableLogs();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

const registerServiceWorker = async () => {
  if (navigator.serviceWorker) {
    try {
      const registration = await navigator.serviceWorker.register(
        `./${process.env.REACT_APP_ACTUAL_SERVICE_WORKER_NAME}.js`,
        { scope: process.env.REACT_APP_CLIENT_URL_PATH_NAME }
      );      
      if (registration.installing) {
        console.log("service worker installing");
      } else if (registration.waiting) {
        //not sure about this yet
        console.log("service worker waiting");
      } else if (registration.activating) {
        //not sure about this yet
        console.log("service worker activating");
      } else if (registration.active) {
        console.log("service worker active");
      } else {
        console.log(
          "final else case in service worker register scenario v4 test"
        );
      }
    }
    catch (err) {
      console.log('Error in registering the service worker');
    }
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker();
reportWebVitals();
