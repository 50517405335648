import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const ModalWrapper = styled.div`
    width: 80%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: ${colors.deepBlue}; */
    background-color: transparent;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0px;
    /* border: 1px solid ${colors.primaryBlue}; */
    z-index: 1;
`

export const ModalText = styled.span`
    color: white;
    font-family: 'Conto';
    margin-top: 5px;
    z-index: 10;
`