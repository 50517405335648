import React from "react";
import {
  ModalBody,
  ModalButton,
  ModalContainer,
  ModalText,
  TextContainer,
} from "../NewGame/NewTableStyles";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../styleGuide/Colors";
import { closeModal } from "../../../../Poker/redux/slices/modalSlice";
import { closeLocalGameModal } from "../../../../Poker/redux/slices/gameTableSlice";

const MaxFunTablesLimit = ({ tempTableId }) => {
  const dispatch = useDispatch();
  const activeGame = useSelector((state) => state.gameTable.activeGame);

  return (
    <ModalContainer>
      <ModalBody>
        <TextContainer>
          <ModalText
            bold={false}
            style={{
              marginTop: "0.5rem",
              color: colors.primaryGold,
              fontSize: "12px",
            }}
          >
            You can play only one fun game at a time.
          </ModalText>
        </TextContainer>
        <ModalButton
          bold={true}
          onClick={() =>
            tempTableId
              ? dispatch(closeLocalGameModal({ tempTableId: tempTableId }))
              : dispatch(closeModal())
          }
        >
          Close
        </ModalButton>
      </ModalBody>
    </ModalContainer>
  );
};

export default MaxFunTablesLimit;
