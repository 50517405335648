import Handler from "../Handler";

class RebuyInBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRebuyInSuccessBroadcast(receivedData);
  }
}

export default RebuyInBroadcastHandler;

// RebuyInB#{"status":"SUCCESS","playerName":"testuser","Amount":600.0}
