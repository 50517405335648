// ReloginFailureHandler.js

import Handler from "../Handler";

class ReloginFailureHandler extends Handler {
  execute(message) {
    console.log("check me rf ", message);
    // let receivedData = JSON.parse(message);
    this.service.processReloginFailure();
  }
}

export default ReloginFailureHandler;
