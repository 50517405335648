import Handler from "../Handler";

class TourneyPlayerAutoRegisteredHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyPlayerAutoRegister(receivedData);
  }
}
export default TourneyPlayerAutoRegisteredHandler;

// PlayerAutoRegistered#{"event":"auto-register-ack","playerName":"testuser","tourneyId":"faDmG","registerAck":{"status":200,"tourneyId":"faDmG","messageCode":600,"tourneyPlayerCount":4}}
