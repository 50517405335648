import React, { useEffect } from "react";
import colors from "../../../styleGuide/Colors";
import { InputRange } from "./RangeSliderStyles";

export const RangeSlider = (props) => {
  const {
    max,
    min,
    inputRefs,
    initialValue,
    passInputValueToParentCallBack,
    stepValue,
    type,
  } = props;


  useEffect(() => {
    inputRefs.current.value = initialValue;
    let value = Number(((initialValue - min) * 100) / (max - min));
    if (type === "table-settings") {
      inputRefs.current.style.background = `linear-gradient(to right, ${colors.tourneyBonusBarFilled} 0px, ${colors.tourneyBonusBarFilled} ${value}%, ${colors.tableSettingsUnfilledSlider} ${value}%, ${colors.tableSettingsUnfilledSlider} 0%)`;
    } else {
      inputRefs.current.style.background = `linear-gradient(to right, ${colors.green} 0px, ${colors.green} ${value}%, ${colors.buyInRangeSliderUnfilled} ${value}%, ${colors.buyInRangeSliderUnfilled} 0%)`;
    }
  }, [stepValue]);

  useEffect(() => {
    if (type === 'table-settings') {
      onRangeInputChange()
    }
  }, [initialValue])

  const onRangeInputChange = () => {
    let value = Number(((inputRefs.current.value - min) * 100) / (max - min));
    if (type === "table-settings") {
      inputRefs.current.style.background = `linear-gradient(to right, ${colors.tourneyBonusBarFilled} 0px, ${colors.tourneyBonusBarFilled} ${value}%, ${colors.tableSettingsUnfilledSlider} ${value}%, ${colors.tableSettingsUnfilledSlider} 0%)`;
    } else {
      inputRefs.current.style.background = `linear-gradient(to right, ${colors.green} 0px, ${colors.green} ${value}%, ${colors.buyInRangeSliderUnfilled} ${value}%, ${colors.buyInRangeSliderUnfilled} 0%)`;
    }
    passInputValueToParentCallBack(inputRefs.current.value);
  };

  return (
    <InputRange
      id="inputRange"
      type="range"
      min={min}
      max={max}
      value={initialValue}
      usageType={type}
      onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRangeInputChange();
      }}
      step={stepValue ?? "1"}
      ref={inputRefs}
    />
  );
};
