import React from "react";
import { ModalWrapper, ModalHeader, ModalBody } from "../modalStyle";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import PokerHand from "./PokerHand";
import { useSelector } from "react-redux";

const HandRanking = ({ tempTableId }) => {
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const cardTypes = {
    suits: ["Ace ", "Clubs ", "Heart ", "Diamond "],
    ranks: ["A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"],
  };

  const handRanks = [
    "Royal Flush",
    "Straight Flush",
    "Four Of A Kind",
    "Full House",
    "Flush",
    "Straight",
    "Three Of A Kind",
    "Two Pairs",
    "One Pair",
    "High Card",
  ];

  return (
    <ModalWrapper orientation={gameOrientation}>
      <ModalHeader>
        <Typo16WhiteContoBoldText>Hand Rankings</Typo16WhiteContoBoldText>
        <CloseButton tempTableId={tempTableId}></CloseButton>
      </ModalHeader>
      <ModalBody orientation={gameOrientation}>
        {handRanks.map((hand, index) => {
          return (
            <PokerHand
              orientation={gameOrientation}
              key={"PokerHandRankingKey-" + hand}
              handName={hand}
              handStrength={index + 1}
            />
          );
        })}
      </ModalBody>
    </ModalWrapper>
  );
};

export default HandRanking;
