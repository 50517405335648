import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import { Typo10PureWhiteContoRegularText } from "../../../styleGuide/Typos";

export const TableSettingsContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "table-settings-move-up forwards 0.25s"
      : "table-settings-move-down forwards 0.5s"};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #064656 0%, #053642 100%);
  height: ${(props) => (props.orientation === "PORTRAIT" ? "50%" : "75%")};
  min-height: ${(props) =>
    props.orientation === "PORTRAIT" ? "500px" : "250px"};
  overflow: scroll;
  @keyframes table-settings-move-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes table-settings-move-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const TableSettingsHeader = styled.div`
  /* background: ${colors.secondaryDeepBlue}; */
  background: ${colors.deepBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
`;

export const SettingText = styled.span`
  color: white;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  font-size: 12px;
`;

export const HeaderText = styled.span`
  color: ${colors.primaryGold};
  text-shadow: 0px 2px 2px black;
  background: ${colors.deepBlue};
  font-size: 14px;
  font-weight: bold;
`;

export const TableSettingsBody = styled.div`
  /* padding: 0rem 1rem 0.5rem 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 80%;
`;

export const TableSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
  width: 85%;
  margin: 1rem 0rem;
  /* height: 100%; */
`;

export const AccordionSetting = styled.div`
  width: 96%;
  margin: 0rem 0rem;
  height: 100%;
  background: ${(props) =>
    props.background
      ? "linear-gradient(to bottom, #0c4150, transparent)"
      : null};
  border-radius: 5px 5px 0px 0px;
  min-height: 150px;
  padding-top: 10px;
  border-width: 1px 1px 0px 1px;
  border-top: ${(props) => (props.background ? "1px solid #248ba9" : null)};
  transition: 0.25s ease;
  filter: ${(props) => (props.disabled ? "opacity(0.5)" : "opacity(1)")};
`;

export const AccordionSettingHeader = styled.div`
  margin: auto;
  width: 88%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
`;

export const AccordionSubOptionSection = styled.div``;

export const AccordionCheckboxContainer = styled.div`
  margin: 1rem auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
`;

export const AccordionInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 88%;
  margin: 1rem auto;
`;

export const AccordionInputTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AccordionInputText = styled.span`
  color: white;
  font-size: 10px;
`;

export const AccordionInputValue = styled.div`
  color: white;
  min-width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  background-color: #083643;
  border: 1px solid rgba(12, 101, 124, 0.45);
  border-radius: 5px;
  padding: 2.5px 5px;
  padding-left: 10px;
  box-shadow: inset 0px 2px 2px black;
  font-size: 12px;
  box-sizing: border-box;
`;

export const AccordianInputValueDescription = styled(
  Typo10PureWhiteContoRegularText
)`
  font-size: 8px;
  margin-left: 6px;
  margin-top: 2px;
`;

export const AccordionInputRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;

  #inputRange {
    background: #042e3a;
    border: none;
    border-radius: 3px;
  }
`;

export const MinMaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// export const AccordionInputRange = styled.input`
//   -webkit-appearance: none;
//   appearance: none;
//   background: ${colors.rangeSliderBackground};
//   background-blend-mode: normal, screen;
//   cursor: pointer;
//   ::-webkit-slider-runnable-track {
//     /* background: rgba(4, 46, 58, 1); */
//     height: 7px;
//     border-radius: 5px;
//     box-shadow: inset 0px 2px 2px black;
//   }

//   ::-moz-range-track {
//     background: rgba(4, 46, 58, 1);
//     height: 7px;
//     border-radius: 5px;
//   }

//   ::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     margin-top: -6px;
//     height: 20px;
//     width: 20px;
//     background: linear-gradient(174.64deg, #e7e7e7 4.29%, #49646b 96.52%),
//       linear-gradient(0deg, #ffffff, #ffffff);
//     border-top: 1px solid white;
//     border-radius: 50%;
//   }

//   ::-moz-range-thumb {
//     border: none; /*Removes extra border that FF applies*/
//     border-radius: 0; /*Removes default border-radius that FF applies*/
//     background: linear-gradient(174.64deg, #e7e7e7 4.29%, #49646b 96.52%),
//       linear-gradient(0deg, #ffffff, #ffffff);
//     height: 25px;
//     width: 25px;
//     border-top: 1px solid white;
//   }

//   :focus {
//     outline: none;
//   }
// `;

export const Toggle = styled.img`
  width: 50px;
  filter: ${(props) => (props.disabled ? "opacity(0.50)" : "opacity(1)")};
`;

export const Checkbox = styled.img`
  width: 15px;
`;

export const DisabledToggle = styled.img`
  width: 50px;
  filter: ${(props) => (props.disabled ? "opacity(0.50)" : "opacity(1)")};
`;

export const TableSettingTypes = styled.div`
  display: flex;
  flex-direction: row;
  height: 10%;
`;

export const TableSettingType = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#075063" : colors.deepBlue)};
  color: white;
  font-size: 12px;
  font-weight: ${(props) => (props.active ? "bold" : null)};
  height: 40px;
`;

export const AutoTopupCriteriaText = styled(Typo10PureWhiteContoRegularText)`
  margin-left: 22px;
`;

export const BettingOptionsContainer = styled.div`
  flex: 1;
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 6px;
`;

export const BettingOptionColumn = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: yellow; */
  padding: 6px;
  gap: 6px;
  color: white;
`;

export const BettingOptionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
export const BettingOptionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  font-size: 10px;
`;

export const DefaultBettingOptionDropdown = styled.select`
  background-color: rgba(42, 42, 42, 1);
  color: white;
  width: 100%;
  text-align: center;
  max-width: 72px;
  height: 22px;
  font-family: "Conto";
  border-radius: 2px;
  font-size: 10px;
  border: 1px solid rgba(77, 77, 77, 1);
  margin-bottom: 6px;

  &:focus {
    outline: none;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 280px;
  /* background-color: red; */
  padding: 6px 6px 6px 16px;
  background-color: rgb(2, 27, 30);
  box-shadow: -2px 1px 5px black;
  border-radius: 5px;
  gap: 16px;
  border: 1px solid rgba(36, 158, 190, 0.59);
`;

export const RaiseSliderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  flex: 1;
`;

export const MarkersWrappers = styled.div`
  height: 230px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: red; */
`;

export const Marker = styled.div`
  width: 100%;
  /* background-color: green; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`;

export const MarkerDash = styled.div`
  height: 1px;
  width: 12px;
  background-color: rgba(51, 96, 112, 1);
  border-radius: 0px 1.5px 1.5px 0px;
`;

export const MarkerValue = styled.div`
  font-size: 10px;
  color: white;
  font-weight: 500;
  line-height: 14px;
`;

export const BettingOptionPresetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-around;
  flex: 0.75;
`;

export const BettingOptionPreset = styled.div`
  flex: 1;
  background-color: ${(props) =>
    props.selected ? "rgba(47, 89, 104, 1)" : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
  max-width: 56px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  border: 1px solid rgba(14, 119, 146, 1);
`;

export const RaiseSliderWrapper = styled.div`
  display: flex;
  width: 45%;
  height: 95%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 12px;
  overflow: none;
  align-items: center;
  position: relative;
  input[type="range"] {
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(270deg);
    -webkit-appearance: none;
    appearance: none;
    background: ${colors.rangeSliderUnfilled};
    border: 1px solid ${colors.eastBay};
    width: 250px;
    height: 16px;
    border-radius: 12.5px;
    background-image: ${colors.raiseSliderFilled};

    background-size: ${(props) =>
      props.sliderValue ? `${props.sliderValue}%  100%` : "0% 100%"};
    background-repeat: no-repeat;
    border: 0.8px solid ${colors.petrol};
    box-shadow: 0px 0px 0px 3px #000 inset;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 38px;
    width: 38px;
    background-color: transparent;
    background-image: ${(props) =>
      props.raiseThumb
        ? `url(${props.raiseThumb})`
        : colors.raiseSliderThumbColor};
    background-repeat: no-repeat;
    box-shadow: none !important;
    /* margin-top: 6px; */

    /* box-shadow: 0px 3px 3px 0px ${colors.black};
    transition: background 0.3s ease-in-out; */
  }
`;
