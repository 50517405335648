import Handler from "../Handler";

class PotWinnerHandler extends Handler {
  execute(message) {
    //PotWinner#{"tableId":"THR180823-5713861gg2mg","gameId":"180823-5713861tkk0a","potWinnersList":[{"potAmount":60.0,"winnerNames":["lachi3"]}]}
    let receivedData = JSON.parse(message);
    this.service.processPotWinner(receivedData);
  }
}

export default PotWinnerHandler;
