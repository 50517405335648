class CleverTapUtil {
  static cleverTapInstance;
  constructor() {
    if (!CleverTapUtil.cleverTapInstance) {
      this.init();
      CleverTapUtil.cleverTapInstance = this;
    }
    return CleverTapUtil.cleverTapInstance;
  }

  init() {
    this.initialize(process.env.REACT_APP_CLEVERTAP_ID);
  }

  initialize(cleverTapAccountId) {
    if (!cleverTapAccountId) {
      throw new Error("Please provide Clever Tap Account id ");
    }
    if (!window.clevertap) {
      window.clevertap = {
        event: [],
        profile: [],
        account: [],
        onUserLogin: [],
        region: "in1",
        notifications: [],
        privacy: [],
      };
      window.clevertap.account.push({ id: cleverTapAccountId });
      window.clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
      window.clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
      (function () {
        let wzrk = window.document.createElement("script");
        wzrk.type = "text/javascript";
        wzrk.async = true;
        wzrk.src =
          ("https:" == document.location.protocol
            ? "https://d2r1yp2w7bby2u.cloudfront.net"
            : "http://static.clevertap.com") + "/js/clevertap.min.js";
        let s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wzrk, s);
      })();
    }
  }

  loginUser(payload) {
    window.clevertap.onUserLogin.push(payload);
    console.log("Called clevertap onUserLogin API with payload: ", payload);
  }

  logEvent(eventName, paramsObject) {
    if (paramsObject) {
        window.clevertap.event.push(eventName, paramsObject);
        console.log("Clevertap event with Params: ", eventName, paramsObject);
    } else {
      window.clevertap.event.push(eventName);
      console.log("Clevertap event only: ", eventName);
    }
  }

  static getInstance() {
    if (!CleverTapUtil.cleverTapInstance) {
      CleverTapUtil.cleverTapInstance = new CleverTapUtil();
    }
    return CleverTapUtil.cleverTapInstance;
  }
}

export default CleverTapUtil;
