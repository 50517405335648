class GameConnectionsN {
  static gameConnectionsInstance;

  constructor() {
    if (!GameConnectionsN.gameConnectionsInstance) {
      this.gameConnections = [];
      GameConnectionsN.gameConnectionsInstance = this;
    }
    return GameConnectionsN.gameConnectionsInstance;
  }

  addGameConnection(connectionObj) {
    this.gameConnections.push(connectionObj);
  }

  getGameConnection(tempTableId) {
    for (let index = 0; index < this.gameConnections.length; index++) {
      let connection = this.gameConnections[index];
      if (connection.tempTableId === tempTableId) {
        return connection;
      }
    }
    return undefined;
  }

  removeGameConnection(tempTableId, isMaintenanceMode) {
    let gameConnectionRemovalStatusObject = {
      isConnectionObjFound: false,
      isRemoved: false,
    };

    for (let index = this.gameConnections.length - 1; index >= 0; index--) {
      let connection = this.gameConnections[index];
      if (connection.tempTableId === tempTableId) {
        gameConnectionRemovalStatusObject.isConnectionObjFound = true;
        try {
          let listener = connection.listener;
          if (listener) {
            listener.cleanUpGameListener(true, isMaintenanceMode);
          }
          this.gameConnections.splice(index, 1);
          gameConnectionRemovalStatusObject.isRemoved = true;
        } catch (e) {
          console.log(
            "Error at removeGameConnection with tempTableId " +
              tempTableId +
              ":",
            e
          );
        } finally {
          let logStatement = "Game socket removal status -- ";
          if (gameConnectionRemovalStatusObject.isConnectionObjFound) {
            logStatement = logStatement + "connection object found -- ";
            if (gameConnectionRemovalStatusObject.isRemoved) {
              logStatement = logStatement + "removed";
            } else {
              logStatement = logStatement + "not removed";
            }
          } else {
            logStatement = logStatement + " connection object not found";
          }
          console.log(logStatement + " -- tempTableId " + tempTableId);

          return gameConnectionRemovalStatusObject;
        }
      }
    }
    return gameConnectionRemovalStatusObject;
  }

  static getInstance() {
    if (!GameConnectionsN.gameConnectionsInstance) {
      return new GameConnectionsN();
    } else {
      return GameConnectionsN.gameConnectionsInstance;
    }
  }
}

export default GameConnectionsN;
