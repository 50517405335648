import Handler from "../Handler";

class StartGameHandler extends Handler {
  execute(message) {
    // Start#{ position(int), playerName(String) , atTableAmount(Double), profilePic(String) , aceLevel(String) , playerAction(String) , playerStatus(int) , gamePlayerStatus(int) , isDealer(boolean) , isSmallBlind(boolean) , isBigBlind(boolean), userCards(List<String>) , rankDetails( HashMap <String,String>) }
    let receivedData = JSON.parse(message);
    this.service.processStartGame(receivedData);
  }
}

export default StartGameHandler;

// Protocol response for seated players -

// Start # {
//     "userCards": ["s12", "d2"],
//     "tableId": "THR040823-41512700u69l",
//     "gameId": "040823-41512707gsrj",
//     "potAmount": 7.0,
//     "smallBlindAmount": 1.0,
//     "bigBlindAmount": 2.0,
//     "rankDetails": {
//         "longDescription": "High Card (Queen,Two)",
//         "winningHand": "s12,d2",
//         "rank": "0",
//         "description": "High Card (Queen)",
//         "rankCards": "",
//         "handType": "Nothing",
//         "points": "194"
//     },
//     "playersSeatData": [{
//         "position": 3,
//         "playerName": "gg03",
//         "totalScore": 0.0,
//         "playerStatus": 303,
//         "profilePic": "profilePic",
//         "aceLevel": "aceLevel",
//         "atTableAmount": 58.0,
//         "gamePlayerStatus": 303,
//         "isSmallBlind": false,
//         "isBigBlind": true,
//         "isDealer": false
//     }, {
//         "position": 4,
//         "playerName": "gg01",
//         "totalScore": 0.0,
//         "playerStatus": 303,
//         "profilePic": "profilePic",
//         "aceLevel": "aceLevel",
//         "atTableAmount": 59.0,
//         "gamePlayerStatus": 303,
//         "isSmallBlind": true,
//         "isBigBlind": false,
//         "isDealer": false
//     }]
// }
// protocol response for watch players -
// Start # {
//     "userCards": [],
//     "tableId": "THR040823-41512700u69l",
//     "gameId": "040823-41512707gsrj",
//     "potAmount": 7,
//     "smallBlindAmount": 1,
//     "bigBlindAmount": 2,
//     "rankDetails": {},
//     "playersSeatData": [{
//             "position": 3,
//             "playerName": "gg03",
//             "totalScore": 0,
//             "playerStatus": 303,
//             "profilePic": "profilePic",
//             "aceLevel": "aceLevel",
//             "atTableAmount": 58,
//             "gamePlayerStatus": 303,
//             "isSmallBlind": false,
//             "isBigBlind": true,
//             "isDealer": false
//         },
//         {
//             "position": 4,
//             "playerName": "gg01",
//             "totalScore": 0,
//             "playerStatus": 303,
//             "profilePic": "profilePic",
//             "aceLevel": "aceLevel",
//             "atTableAmount": 59,
//             "gamePlayerStatus": 303,
//             "isSmallBlind": true,
//             "isBigBlind": false,
//             "isDealer": false
//         }
//     ]
// }
