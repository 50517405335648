import Handler from "../Handler";

class StartGameCancel extends Handler {
  execute(message) {
    let data = {
      status: null
    }
    this.service.processNextGameCancel(data, false);
  }
}

export default StartGameCancel;
