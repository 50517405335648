import Handler from "../Handler";

class DReserveAckHandler extends Handler {
  execute(message) {
    /** DReserveA#{playerName: "lachi3", gameId: "020823-0241361ocn4d", tableId: "THD020823-02413610rtzo", position: 0} */

    let receivedData = JSON.parse(message);
    this.service.processRemoveReserveSeat(receivedData);
  }
}

export default DReserveAckHandler;
