import React, { useEffect, useState, useRef } from "react";
import ChipIcon from "../../../assets/gameTable/chips.png";
import TimerIcon from "../../../assets/gameTable/TimerIcon.svg";
import TourneyLeaderboardIcon from "../../../assets/gameTable/TourneyLeaderboard.svg";
import ChevronIcon from "../../../assets/gameTable/chevron.svg";
import TrophyIcon from "../../../assets/gameTable/TrophyIcon.svg";
import {
  TourneyCommonButtonsWrapper,
  NextUpContainer,
  BlindsDetailsWrapper,
  ChipIconWrapper,
  SmallBlindAndBigBlindDetailsText,
  NextUpTimerWrapper,
  TimerIconWrapper,
  NextUpTimerText,
  NextUpText,
  TourneyLeaderboard,
  TourneyCurrentLevelWrapper,
  NextRoundTimerContainer,
  ChevronIconWrapper,
  CurrentLevelText,
  TrophyIconWrapper,
  TourneyRankingLeaderboardWrapper,
} from "./tourneyCommonButtonStyles";
import { useDispatch, useSelector } from "react-redux";
import { TOURNEY_RANKING_LEADERBOARD } from "../../data/Constants";
import { openSlider } from "../../redux/slices/sliderSlice";
import { Leaderboard } from "../../../Common/components/Buttons/Leaderboard/Leaderboard";
import { handleRequestTourneyRankingLeaderboardDetails } from "../../redux/slices/gameTableSlice";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

function TourneyCommonButtons(props) {
  const { tempTableId } = props;
  const playerData = useSelector((state) => state.lobby.player);
  const nextRoundTimerIntervalRef = useRef(null);
  const [levelUpTimerSeconds, setLevelUpTimerSeconds] = useState(0)
  const dispatch = useDispatch();

  const currentGame = useSelector(
    (state) => state.gameTable.games[tempTableId]
  );

  const tourneyNextLevelUpDetails = currentGame?.tourneyNextLevelUpDetails;
  const openTourneyRankingLeaderboard = () => {
    dispatch(
      handleRequestTourneyRankingLeaderboardDetails({
        tempTableId: tempTableId,
        playerName: playerData.userName,
      })
    );
    dispatch(
      openSlider({
        type: TOURNEY_RANKING_LEADERBOARD,
        config: { tempTableId: tempTableId },
      })
    );
  };


  useEffect(() => {
    handleTimerNewRound()
    return () => {
      clearInterval(nextRoundTimerIntervalRef.current)
    }
  }, [tourneyNextLevelUpDetails?.minutesLeft])

  const handleTimerNewRound = () => {//show timer combilned.
    let nextRoundTimerSeconds = ((tourneyNextLevelUpDetails?.minutesLeft) * 60)
    setLevelUpTimerSeconds(nextRoundTimerSeconds)
    nextRoundTimerIntervalRef.current = setInterval(() => {
      setLevelUpTimerSeconds((prev) =>  prev > 0 ? prev-1 : 0 );
    }, 1000);
  }


  const handleNextRoundTimer = (timer) => {
    let timerInSeconds = Math.round(levelUpTimerSeconds % 60);
    let timerInMin =  Math.floor(levelUpTimerSeconds / 60);
    return <NextRoundTimerContainer timerColorFlag={timerInMin < 1 }>
      <> {`${timerInMin === 0
        ? "00"
        : "0" + (timerInMin)
        }`}</>{" : "}{timerInSeconds ? timerInSeconds < 10 ? "0" + timerInSeconds.toString() : timerInSeconds : "00"}{" mins"}</NextRoundTimerContainer>;
  }


  return (
    <TourneyCommonButtonsWrapper>
      {/* Tourney next level wrapper */}
      {tourneyNextLevelUpDetails &&
        tourneyNextLevelUpDetails.minutesLeft > -1 && (
          <NextUpContainer>
            {/* <NextupLabelWrapper> */}
            {/* <NextUpText>Next up</NextUpText> */}
            <NextUpText>Next up</NextUpText>
            {/* <NextUpIconWrapper src={NextUpIcon} /> */}
            {/* </NextupLabelWrapper> */}
            <BlindsDetailsWrapper>
              <ChipIconWrapper src={ChipIcon} />
              <SmallBlindAndBigBlindDetailsText>
                {`${parseDecimalValuesOfNumber(
                  tourneyNextLevelUpDetails.smallBlind,
                  2,
                  checkToDisplayValueInK(tourneyNextLevelUpDetails.smallBlind),
                  checkToDisplayValueInL(tourneyNextLevelUpDetails.smallBlind)
                )} 
                / ${parseDecimalValuesOfNumber(
                  tourneyNextLevelUpDetails.bigBlind,
                  2,
                  checkToDisplayValueInK(tourneyNextLevelUpDetails.bigBlind),
                  checkToDisplayValueInL(tourneyNextLevelUpDetails.bigBlind)
                )}
                 (${parseDecimalValuesOfNumber(
                  tourneyNextLevelUpDetails.ante,
                  2,
                  checkToDisplayValueInK(tourneyNextLevelUpDetails.ante),
                  checkToDisplayValueInL(tourneyNextLevelUpDetails.ante)
                )})`}
              </SmallBlindAndBigBlindDetailsText>
            </BlindsDetailsWrapper>
            <NextUpTimerWrapper>
              <TimerIconWrapper src={TimerIcon} />
              <NextUpTimerText>{handleNextRoundTimer(tourneyNextLevelUpDetails.minutesLeft)}</NextUpTimerText>

            </NextUpTimerWrapper>
          </NextUpContainer>
        )}

      {/* Tourney leaderboard icon wrapper */}
      {/* <TourneyLeaderboard src={TourneyLeaderboardIcon} /> */}
      <Leaderboard
        orientation={"portrait"}
        gameDefinition={props.gameDefinition}
        isTourneyGame={true}
      />
      {/* Tourney current level wrapper */}
      {currentGame?.tourneyPlayerRankDetails && !currentGame?.watchPlayer && (
        <TourneyCurrentLevelWrapper>
          <ChevronIconWrapper src={ChevronIcon} />
          <CurrentLevelText>{`${currentGame?.tourneyPlayerRankDetails?.playerRank} / ${currentGame?.tourneyPlayerRankDetails?.activePlayerCount}`}</CurrentLevelText>
        </TourneyCurrentLevelWrapper>
      )}

      {/* Tourney ranking leaderboard wrapper */}
      <TourneyRankingLeaderboardWrapper onClick={openTourneyRankingLeaderboard}>
        <TrophyIconWrapper src={TrophyIcon} />
      </TourneyRankingLeaderboardWrapper>
    </TourneyCommonButtonsWrapper>
  );
}

export default TourneyCommonButtons;
