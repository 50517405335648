import Handler from "../Handler";

class BreakBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processBreakBroadcast(receivedData);
  }
}

export default BreakBroadcastHandler;

// BreakBroadcast#
// {
//     "tableId": "TOTUS5030124-511213718371qbqsr",
//     "message": "Break started",
//     "endTime": 1704276128670
// }
// message when break has started and we have an end time epoch value
// BreakBroadcast#
// {
//     "tableId": "TOTUS5030124-511213718371qbqsr",
//     "message": "Waiting for games to end on other tables",
//     "endTime":0
// }
// When waiting for other tables to end in that same tourney
