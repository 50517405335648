import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  ProfileImage,
  ProfileImagesContainer,
  DealerImg,
  WinnerLabel,
  WinningAmount,
  SitOutLabelWrapper,
  LottieWrapper,
  RelativeWrapper,
  PlayerComponentContainer,
  DetailsBlock,
  DetailsText,
  ProfileDetails,
  SitHereComponentContainer,
  SitHerePlusIcon,
  SitHereText,
  SeatComponentContainer,
  SeatStatusText,
  PureRelativeWrapper,
  BlindIconContainer,
  RippleWrapper,
  PlayerTableIcon,
  PlayerTableValue,
  MyBlindIconContainer,
  InfinityText,
  ChipImg,
  TourneyChipsWrapper,
  TimerTypeLabel,
  PlayerNotesIconWrapper,
  PlayerSitHereIconWrapper,
  ExtraTimeLeftWrapper,
  ExtraTimeLeftIcon,
  ExtraTimeLeftText,
} from "./playerStyles";
import dealerIcon from "../../../assets/player/Dealer.png";
import smallBlind from "../../../assets/player/SB.png";
import bigBlind from "../../../assets/player/BB.png";
import checkIcon from "../../../assets/player/Check.png";
import foldIcon from "../../../assets/player/Fold.png";
import yellowChip from "../../../assets/gameTable/chip-yellow.png";
import sitHereIcon from "../../../assets/gameTable/SitHereIcon.png";

import { useDispatch, useSelector } from "react-redux";
import {
  openLocalGameModal,
  requestReserveSeat,
  submitUpdatedTableSettingsConfig,
  toggleDisplayInBB,
  updateAllInAnimationFlag,
} from "../../redux/slices/gameTableSlice";
import colors from "../../../Common/styleGuide/Colors";
import {
  CALL_ACTION,
  CHECK_ACTION,
  FOLD_ACTION,
  GAME_STAGE_PRE_FLOP,
  INSUFFICIENT_BALANCE,
  MAX_USERNAME_LENGTH,
  OMAHA5,
  RAISE_ACTION,
  TEXAS_HOLDEM,
  TIMER_TYPE_PLAYER,
  OMAHA,
  PLAYER_EXTRA_TIMER,
  DISCONNECTION_TIMER,
  EXTRA_TIME,
  DISCONNECTED,
  INSUFFICIENT_FUN_CHIPS_BALANCE,
  RUPEE_SYMBOL,
  LANDSCAPE,
  GAME_STAGE_SHOWDOWN,
  CONVERT_CHIPS_TO_BB_IN_LONG_PRESS_TIME_IN_MS,
  PLAYER_STATS,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
} from "../../data/Constants";
import Lottie from "lottie-react";
import winnerGlow from "../../../assets/player/winner.json";
import redGlow from "../../../assets/player/RedWinner.json";
import blueGlow from "../../../assets/player/BlueWinner.json";
import greenGlow from "../../../assets/player/GreenWinner.json";
import grayGlow from "../../../assets/player/GrayWinner.json";
import yellowGlow from "../../../assets/player/GoldWinner.json";
import {
  checkIfIsLeftPlayer,
  checkIfIsRightPlayer,
  checkIfPlayerPositionIsOnTop,
  checkIfThePlayerPositionIsBottom,
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getNumberOfCardsBasedOnGameType,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { CardImages } from "../../../assets/cards/CardBacks/index";
import chip from "../../../assets/gameTable/chips.png";
import playerNotesIcon from "../../../assets/gameTable/PlayerNotesIcon.png";
import { Loader } from "../Loader/Loader";
import GameConnectionsN from "../../io/GameConnectionsN";
import { getGameSlice, getPlayerObject } from "../../utils/ReduxUtils";
import { TimerSVG } from "../../../Common/components/Timer/TimerSVG";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { PR_Client_TakeSeat_Click } from "../../data/ClevertapConstants";
import { AnimatePresence, motion } from "framer-motion";
import MyCards from "./MyCards";
import { cardThemes } from "../../../assets/cards";
import { returnGameTypeText } from "../../../Common/utils/PokerUtils";
import { extraTimeLeftClock } from "../../routes/Game/gameAssets";

const PlayerComponent = (props) => {
  const { top, left, scale, bottom, right, id, position, tempTableId } = props;
  const playerTurn = useSelector(
    (state) => state.gameTable.games[tempTableId].playerTurn
  );
  const gameStage = useSelector(
    (state) => state.gameTable.games[tempTableId].gameStage
  );
  const profileRef = useRef();
  const dispatch = useDispatch();
  let playerData = useSelector((state) =>
    state.gameTable.games[tempTableId].playerData.find(
      (player) => player.position === position
    )
  );
  const tourneyAutoPlay = useSelector(
    (state) => state.gameTable.games[tempTableId]?.tourneyAutoPlay
  );
  // THIS HAS TO BE REMOVED. WE ARE SIMPLY CALLING PLAYER DATA AGAIN BECAUSE THE WINNING HAND OPERATION IS NOT OCCURING IN THE STORE.
  // THE WINNING HAND CALCULATION HAS TO BE DONE IN THE STORE / SERVICE / WHATEVER BUT NOT IN THE UI COMPONENTS. REDUNDANCY 10000%
  const allPlayersData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );
  const {
    userName,
    isReservedSeat,
    tableTimer,
    allIn,
    startAllInAnimation,
    position: playerComponentPosition,
    isDealer,
    startTableTimer,
    lastAction,
    isTakenSeat,
    isSmallBlind,
    isBigBlind,
    isForcedBlind,
    showBlindIcon,
    betAmount,
    possibleWinningHandType,
    isFolded,
    foldAnimationCompleted,
    isActive,
    atTableAmount,
    colorCode,
    activeTableTimerType,
    totalTime,
    sitOutOption,
    isWinner,
    avatar,
    winningHand,
    winningAmount,
    autoMuck,
    // playerIsWaitingForBigBlind,
    isWaitingForBigBlind: playerIsWaitingForBigBlind,
    // winningAmount,
    muckCards,
    playerNotes,
  } = playerData ? playerData : {};
  const revealCard = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === position
      )?.revealCard
  );
  const playerComponentCards = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === position
      )?.cards
  );
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  // const userName = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.userName
  // );
  // const isReservedSeat = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isReservedSeat
  // );
  // const tableTimer = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.tableTimer
  // );
  // const allIn = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.allIn
  // );
  // const startAllInAnimation = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.startAllInAnimation
  // );
  // const playerComponentPosition = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.position
  // );
  // const isDealer = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isDealer
  // );
  // const startTableTimer = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.startTableTimer
  // );
  // const lastAction = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.lastAction
  // );
  // const isTakenSeat = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isTakenSeat
  // );
  // const isSmallBlind = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isSmallBlind
  // );
  // const isBigBlind = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isBigBlind
  // );
  // const isForcedBlind = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isForcedBlind
  // );
  // const showBlindIcon = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.showBlindIcon
  // );
  // const betAmount = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.betAmount
  // );
  // const revealCard = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.revealCard
  // );
  // const possibleWinningHandType = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.possibleWinningHandType
  // );
  // const playerComponentCards = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.cards
  // );
  // const isFolded = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isFolded
  // );
  // const foldAnimationCompleted = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.foldAnimationCompleted
  // );
  // const isActive = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isActive
  // );
  // const atTableAmount = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.atTableAmount
  // );
  // const colorCode = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.colorCode
  // );
  // const activeTableTimerType = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.activeTableTimerType
  // );
  // const totalTime = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.totalTime
  // );
  // const sitOutOption = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.sitOutOption
  // );
  // const isWinner = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isWinner
  // );
  // const avatar = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.avatar
  // );
  // const autoMuck = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.autoMuck
  // );
  // const playerIsWaitingForBigBlind = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.isWaitingForBigBlind
  // );
  // const winningAmount = useSelector(
  //   (state) =>
  //     state.gameTable.games[tempTableId].playerData.find(
  //       (player) => player.position === position
  //     )?.winningAmount
  // );
  const playerSeat = useSelector(
    (state) => state.gameTable.games[tempTableId].playerSeat
  );
  const userBalance = useSelector((state) => state.lobby.balance);
  const channel = useSelector((state) => state.lobby.channel);
  const playerSeatBeforeLeaveSeat = useSelector(
    (state) => state.gameTable.games[tempTableId].playerSeatBeforeLeaveSeat
  );
  const totalPlayersOnTable = useSelector(
    (state) => state.gameTable.games[tempTableId]?.totalPlayersConfig
  );
  const positionsArranged = useSelector(
    (state) => state.gameTable.games[tempTableId].positionsArranged
  );
  const playerSitOutTimer = useSelector(
    (state) => state.gameTable.games[tempTableId].playerSitOutTimer
  );
  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isWinnerDeclared
  );
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );
  const startWinnerPotAnimation = useSelector(
    (state) => state.gameTable.games[tempTableId]?.startWinnerPotAnimation
  );
  const { showHandStrength, BBDisplay } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const showRankDetails = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showRankDetails
  );
  const playerComponentSize = useSelector(
    (state) => state.gameTable.playerComponentSize
  );
  const lottieAnimationRef = useRef(null);
  const playerTableActionItem = useRef(null);

  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.bigBlind
  );

  const isGameStarted = useSelector(
    (state) => state.gameTable.games[tempTableId].isGameStarted
  );
  const showDefaultActions = useSelector(
    (state) => state.gameTable.games[tempTableId].showDefaultActions
  );
  const isCardDistributionAnimationCompleted = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].isCardDistributionAnimationCompleted
  );
  // const cardBackTheme = useSelector(
  //   (state) => state.gameTable.games[tempTableId].themeData.cardBack
  // );
  const cardBackTheme = useSelector(
    (state) => state.gameTable.themeData.cardBack
  );
  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );

  const isAntiBankingEnabled = useSelector(
    (state) => state.gameTable.games[tempTableId].isAntiBankingEnabled
  );

  const reserveLoading = useSelector(
    (state) => state.gameTable.games[tempTableId].processingReserve
  );
  const playerMovedToCenter = useSelector(
    (state) => state.gameTable.games[tempTableId].playerMovedToCenter
  );
  const isWaitingForBigBlind = useSelector(
    (state) => state.gameTable.games[tempTableId].isWaitingForBigBlind
  );

  const flopCardsAnimationCompleted = useSelector(
    (state) => state.gameTable.games[tempTableId].flopCardsAnimationCompleted
  );

  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);

  const [mySeat, setMySeat] = useState(playerSeat);

  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isTourneyGame
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isFunGame
  );
  const gameType = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.gameType
  );

  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameConnectionStatus
  );

  const [winningHandArray, setWinningHandArray] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getWinningHandCards();
    } else {
      setWinningHandArray([]);
      clearTimeout(intervalRef.current);
    }

    return () => {
      clearTimeout(intervalRef.current);
    };
  }, [isWinnerDeclared, winningPotData]);

  const getWinningHandCards = () => {
    if (winningPotData) {
      if (winningPotData?.winnerNames.includes(userName)) {
        setWinningHandArray(winningHand ? winningHand : []);
      } else {
        setWinningHandArray([]);
      }
    }
  };

  let frame;

  const longPressTimeout = useRef(null);

  useEffect(() => {
    return () => {
      if (longPressTimeout.current) {
        clearTimeout(longPressTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      playerSeatBeforeLeaveSeat !== undefined &&
      playerSeatBeforeLeaveSeat !== null
    ) {
      setMySeat(playerSeatBeforeLeaveSeat);
    } else if (playerSeat !== undefined && playerSeat !== null) {
      setMySeat(playerSeat);
    }
  }, [playerSeatBeforeLeaveSeat, playerSeat]);

  useEffect(() => {
    return () => {
      playerData = null;
    };
  }, []);

  const onSitHereClick = () => {
    //to be worked on @yashwanth
    // let loggedInPlayerObject = getPlayerObject();
    // console.log("Logged in ")
    // if (
    //   loggedInPlayerObject.playerStatus === DELETED_PLAYER ||
    //   loggedInPlayerObject.playerStatus === TO_BE_DELETED_PLAYER
    // ) {
    //   dispatch(openModal({ type: DELETED_PLAYER_LOGOUT }));
    //   return;
    // }

    let tableData = getGameSlice().games[tempTableId];
    let gameDefinition = tableData.gameDefinition;
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_TakeSeat_Click",
      eventProperties: {
        userName: getPlayerObject().userName,
        channel,
        currentCashBalance: userBalance.totalBalance,
        timeStamp,
        bigBlind: gameDefinition.bigBlind,
        smallBlind: gameDefinition.smallBlind,
        noOfPlayers: gameDefinition.maxPlayers,
        gameType: gameDefinition.gameType,
      },
    });

    CleverTapUtil.getInstance().logEvent(PR_Client_TakeSeat_Click, {
      Username: getPlayerObject().userName,
      Channel: channel,
      "Wallet balance": userBalance?.totalBalance,
      Timestamp: timeStamp,
      "Big blind": gameDefinition?.bigBlind,
      "No. of players": gameDefinition?.maxPlayers,
      "Game type": gameDefinition?.gameType,
      "Table name": gameDefinition?.tableName,
      "Game mode": gameDefinition?.gameMode,
    });

    if (!reserveLoading.status) {
      // if (buttonsEnabled) {
      // console.log('Game Table Data is ', tableData);
      // console.log('User balance >>> ', userBalance, tableData.gameDefinition.minBuyInAmount);
      if (isFunGame) {
        if (
          userBalance?.playChips < tableData?.gameDefinition?.minBuyInAmount
        ) {
          dispatch(
            openLocalGameModal({
              type: INSUFFICIENT_FUN_CHIPS_BALANCE,
              tempTableId: tempTableId,
            })
          );
          return;
        }
      } else {
        if (
          userBalance.totalBalance < tableData?.gameDefinition?.minBuyInAmount
        ) {
          dispatch(
            openLocalGameModal({
              type: INSUFFICIENT_BALANCE,
              tempTableId: tempTableId,
              data: {
                amountToAdd:
                  tableData.gameDefinition.minBuyInAmount -
                  userBalance.totalBalance,
                blindsText: `(${returnGameTypeText(
                  tableData.gameDefinition.gameType
                )} ${tableData.gameDefinition.smallBlind}/${
                  tableData.gameDefinition.bigBlind
                })`,
              },
            })
          );
          return;
        }
      }
      //hitting protocol directly @yashwanth
      dispatch(
        requestReserveSeat({
          position: position,
          tempTableId: tempTableId,
        })
      );
      let gameConnection =
        GameConnectionsN.getInstance().getGameConnection(tempTableId);
      gameConnection.listener.requestReserveSeat({
        playerName: getPlayerObject().userName,
        position: position,
        tableId: tableData.tableId,
      });
    }
    return;
  };

  // const playRippleAnimation = () => {
  //   frame = playAnimation({
  //     duration: 1000,
  //     timing: easeInOut(linear),
  //     draw: (progress) => {
  //       playRippleAnimationUsingFrameAnimation(progress);
  //     },
  //   });
  // };

  // const playRippleAnimationUsingFrameAnimation = (progress) => {
  //   let profile = profileRef.current;
  //   if (profile) {
  //     if (progress < 0.5) {
  //       profile.style.boxShadow = `
  //       0 0 0 0  ${colors.ripplePrimaryGold},
  //       0 0 0 ${5 * progress}px  ${colors.rippleSecondaryGold},
  //       0 0 0 ${10 * progress}px  ${colors.rippleSecondaryGold},
  //       0 0 0 ${7 * progress}px  ${colors.ripplePrimaryGold}
  //       `;
  //     } else {
  //       profile.style.boxShadow = `
  //       0 0 0 0  ${colors.rippleSecondaryGold},
  //       0 0 0 ${5 * progress}px  ${colors.ripplePrimaryGold},
  //       0 0 0 ${17 * progress}px  ${colors.transparent},
  //       0 0 0 ${10 * progress}px  ${colors.transparent}
  //       `;
  //     }

  //     profile.style.transition = `box-shadow 0.28s ease`;
  //     if (progress === 1) {
  //       profile.style.boxShadow = "unset";
  //       profile.style.transition = `box-shadow 0.28s ease`;
  //       if (
  //         playerSeatBeforeLeaveSeat !== undefined ||
  //         playerSeat === undefined ||
  //         tableTimer
  //       ) {
  //         playRippleAnimation();
  //       } else {
  //         return;
  //       }
  //     }
  //   }
  // };

  // const showAllInIcon = () => {
  //   if (playerData) {
  //     let isRightPlayer = checkIfIsRightPlayer(
  //       totalPlayersOnTable,
  //       mySeat,
  //       playerComponentPosition
  //     );

  //     let isLeftPlayer = checkIfIsLeftPlayer(
  //       totalPlayersOnTable,
  //       mySeat,
  //       playerComponentPosition
  //     );

  //     let isTopPlayer = checkIfPlayerPositionIsOnTop(
  //       mySeat,
  //       playerComponentPosition
  //     );

  //     let playerPositionsConfig = {};

  //     if (isTopPlayer) {
  //       playerPositionsConfig =
  //         tableOrientation === "PORTRAIT"
  //           ? {
  //               top: 150,
  //               left: -40,
  //             }
  //           : {
  //               top: 100,
  //               left: -55,
  //             };
  //     }

  //     if (isLeftPlayer) {
  //       playerPositionsConfig =
  //         tableOrientation === "PORTRAIT"
  //           ? {
  //               bottom: -15,
  //               left: 140,
  //             }
  //           : {
  //               bottom: 50,
  //               left: 275,
  //             };
  //     }

  //     if (isRightPlayer) {
  //       playerPositionsConfig =
  //         tableOrientation === "PORTRAIT"
  //           ? {
  //               bottom: -15,
  //               right: 140,
  //             }
  //           : {
  //               bottom: 50,
  //               right: 275,
  //             };
  //     }

  //     // Bottom player
  //     if (!isRightPlayer && !isLeftPlayer && !isTopPlayer) {
  //       playerPositionsConfig =
  //         tableOrientation === "PORTRAIT"
  //           ? {
  //               bottom: 160,
  //               left: 30,
  //             }
  //           : {
  //               bottom: 80,
  //               left: -50,
  //             };
  //     }

  //     return allIn &&
  //       atTableAmount === 0 &&
  //       isActive &&
  //       !playerSitOutTimer ? (
  //       <AllInIcon
  //         id={`all-in-${playerComponentPosition}"-"${tempTableId}`}
  //         src={allInIcon}
  //         left={playerPositionsConfig.left}
  //         right={playerPositionsConfig.right}
  //         bottom={playerPositionsConfig.bottom}
  //         top={playerPositionsConfig.top}
  //       ></AllInIcon>
  //     ) : null;
  //   }
  // };

  const showDealerIcon = () => {
    if (userName && isTakenSeat) {
      let playerDirection;
      let isRightPlayer = checkIfIsRightPlayer(
        totalPlayersOnTable,
        mySeat,
        playerComponentPosition
      );

      let isLeftPlayer = checkIfIsLeftPlayer(
        totalPlayersOnTable,
        mySeat,
        playerComponentPosition
      );

      let isTopPlayer =
        mySeat !== undefined && mySeat !== null
          ? checkIfPlayerPositionIsOnTop(
              mySeat,
              playerComponentPosition,
              totalPlayersOnTable
            )
          : totalPlayersOnTable === 2
          ? position === 1
            ? true
            : false
          : totalPlayersOnTable === 4
          ? position === 3
            ? true
            : false
          : false;

      let playerPositionsConfig = {};

      if (isTopPlayer) {
        playerDirection = "left";
        playerPositionsConfig = {
          top: 120,
          left: -45,
        };
      }

      if (isLeftPlayer) {
        playerDirection = "left";
        playerPositionsConfig = {
          bottom: 95,
          left: 90,
        };
      }

      if (isRightPlayer) {
        playerDirection = "right";
        playerPositionsConfig = {
          bottom: 95,
          right: 90,
        };
      }

      // Bottom player
      if (!isRightPlayer && !isLeftPlayer && !isTopPlayer) {
        playerPositionsConfig = {
          bottom: 90,
          left: -30,
        };
      }

      return (
        isDealer && (
          <DealerImg
            initial={{
              opacity: 0,
              scale: 0,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            src={dealerIcon}
            playerDirection={playerDirection}
            orientation={tableOrientation}
            left={playerPositionsConfig.left}
            right={playerPositionsConfig.right}
            bottom={playerPositionsConfig.bottom}
            top={playerPositionsConfig.top}
          />
        )
      );
    }
  };

  // const createChipsOnTable = () => {
  //   return (
  //     userName &&
  //     isTakenSeat &&
  //     (lastAction === CALL_ACTION || lastAction === RAISE_ACTION) && (
  //       <Chips
  //         tempTableId={tempTableId}
  //         playerComponentPosition={playerComponentPosition}
  //         mySeat={mySeat}
  //       ></Chips>
  //     )
  //   );
  // };

  const createPlayerComponentLogic = () => {
    if (userName) {
      if (isTakenSeat) {
        return createPlayerComponent();
      }
      if (isReservedSeat && !isTakenSeat) {
        return createReservedSeat();
      }
    } else {
      if ((playerSeat === null || playerSeat === undefined) && !isTourneyGame) {
        return createSitHereComponent();
      } else {
        return createEmptySeat();
      }
    }
  };

  const handleLongTouchStart = () => {
    if (playerComponentPosition === playerSeat && position === mySeat) {
      longPressTimeout.current = setTimeout(() => {
        dispatch(toggleDisplayInBB({ playVibration: true }));
        dispatch(submitUpdatedTableSettingsConfig());
      }, CONVERT_CHIPS_TO_BB_IN_LONG_PRESS_TIME_IN_MS);
    }
  };

  const handleLongTouchEnd = () => {
    if (playerComponentPosition === playerSeat && position === mySeat) {
      if (longPressTimeout.current) {
        clearTimeout(longPressTimeout.current);
      }
    }
  };

  const createOtherPlayerActionComponent = (isOppositePlayer) => {
    let playerTablePosition;

    let source = lastAction
      ? lastAction === CALL_ACTION
        ? chip
        : lastAction === CHECK_ACTION
        ? checkIcon
        : lastAction === RAISE_ACTION
        ? chip
        : lastAction === FOLD_ACTION
        ? foldIcon
        : null
      : isSmallBlind && gameStage === GAME_STAGE_PRE_FLOP
      ? smallBlind
      : isBigBlind && gameStage === GAME_STAGE_PRE_FLOP
      ? bigBlind
      : isForcedBlind && gameStage === GAME_STAGE_PRE_FLOP
      ? chip
      : null;

    if (totalPlayersOnTable === 2 && mySeat !== undefined && mySeat !== null) {
      playerTablePosition = "top";
    } else {
      if (
        checkIfIsLeftPlayer(
          totalPlayersOnTable,
          mySeat,
          playerComponentPosition
        )
      ) {
        playerTablePosition = "left";
      } else if (
        checkIfIsRightPlayer(
          totalPlayersOnTable,
          mySeat,
          playerComponentPosition
        )
      ) {
        playerTablePosition = "right";
      } else {
        if (checkIfThePlayerPositionIsBottom(playerComponentPosition, mySeat)) {
          playerTablePosition = "bottom";
        } else if (
          checkIfPlayerPositionIsOnTop(
            playerComponentPosition,
            mySeat,
            totalPlayersOnTable
          )
        ) {
          playerTablePosition = "top";
        }
      }
    }

    return lastAction ||
      (showBlindIcon &&
        (isSmallBlind ||
          isBigBlind ||
          (isForcedBlind && !isWinnerDeclared))) ? (
      <PureRelativeWrapper isOppositePlayer={isOppositePlayer}>
        <BlindIconContainer
          lastAction={lastAction || allIn}
          isOppositePlayer={isOppositePlayer}
          ref={playerTableActionItem}
          id={`${lastAction + "-" + playerComponentPosition + tempTableId}`}
          className={"otherPlayerComponent-" + playerComponentPosition}
          display={source}
          position={playerTablePosition}
          isPlainIcon={
            (source === checkIcon && !betAmount) ||
            (source === foldIcon && !betAmount)
          }
        >
          <PlayerTableIcon
            isBlind={source === smallBlind || source === bigBlind}
            src={source}
            isLandscape={tableOrientation === LANDSCAPE}
            id={`${
              "icon-" + lastAction + playerComponentPosition + "-" + tempTableId
            }`}
          />
          {betAmount ? (
            <PlayerTableValue position={playerTablePosition}>
              {BBDisplay
                ? parseDecimalValuesOfNumber(betAmount / bigBlindValue, 1) +
                  " BB"
                : parseDecimalValuesOfNumber(
                    betAmount,
                    2,
                    checkToDisplayValueInK(betAmount),
                    checkToDisplayValueInL(betAmount)
                  )}
            </PlayerTableValue>
          ) : null}
        </BlindIconContainer>
      </PureRelativeWrapper>
    ) : null;
  };

  const returnPlayerActionComponent = () => {
    let source = lastAction
      ? lastAction === CALL_ACTION
        ? chip
        : lastAction === CHECK_ACTION
        ? checkIcon
        : lastAction === RAISE_ACTION
        ? chip
        : lastAction === FOLD_ACTION
        ? foldIcon
        : null
      : showBlindIcon
      ? isSmallBlind && gameStage === GAME_STAGE_PRE_FLOP
        ? smallBlind
        : isBigBlind && gameStage === GAME_STAGE_PRE_FLOP
        ? bigBlind
        : isForcedBlind && gameStage === GAME_STAGE_PRE_FLOP
        ? chip
        : null
      : null;

    return source ? (
      <MyBlindIconContainer
        initial={{
          opacity: 0,
          scale: 0,
          translateX: "-50%",
        }}
        animate={{
          opacity: 1,
          scale: 1,
          translateX: "-50%",
        }}
        ref={playerTableActionItem}
        id={`${lastAction + "-" + playerComponentPosition + tempTableId}`}
        display={source}
        mySeat={playerSeat !== undefined && playerSeat !== null}
        isPlainIcon={
          (source === checkIcon && !betAmount) ||
          (source === foldIcon && !betAmount)
        }
      >
        <PlayerTableIcon
          isBlind={source === smallBlind || source === bigBlind}
          src={source}
          isLandscape={tableOrientation === LANDSCAPE}
          id={`${
            "icon-" + lastAction + playerComponentPosition + "-" + tempTableId
          }`}
        />
        {betAmount ? (
          <PlayerTableValue>
            {BBDisplay
              ? parseDecimalValuesOfNumber(betAmount / bigBlindValue, 1) + " BB"
              : parseDecimalValuesOfNumber(
                  betAmount,
                  2,
                  checkToDisplayValueInK(betAmount),
                  checkToDisplayValueInL(betAmount)
                )}
          </PlayerTableValue>
        ) : null}
      </MyBlindIconContainer>
    ) : null;
  };

  // const returnPossibleWinningHandType = () => {
  //   if (position === playerSeat) {
  //     if (
  //       playerMovedToCenter &&
  //       gameStage !== GAME_STAGE_PRE_FLOP &&
  //       flopCardsAnimationCompleted &&
  //       // !startWinnerPotAnimation &&
  //       !revealCard &&
  //       showHandStrength &&
  //       showRankDetails &&
  //       possibleWinningHandType
  //     ) {
  //       return (
  //         <PlayerHandRankWrapper
  //           orientation={tableOrientation}
  //           left={
  //             tableOrientation === PORTRAIT
  //               ? playerComponentSize * 1.35
  //               : // : playerComponentSize * 1.8
  //                 playerComponentSize * 1.6
  //           }
  //         >
  //           <PlayerHandRankText>{possibleWinningHandType}</PlayerHandRankText>
  //         </PlayerHandRankWrapper>
  //       );
  //     }
  //   }
  // };

  const returnCardRotateValueDependingOnIndexAndGameType = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      switch (index) {
        case 0:
          return -15;
        case 1:
          return 15;
      }
    }

    if (gameType === OMAHA) {
      switch (index) {
        case 0:
          return -15;
        case 1:
          return -5;
        case 2:
          return 5;
        case 3:
          return 15;
      }
    }

    if (gameType === OMAHA5) {
      switch (index) {
        case 0:
          return -15;
        case 1:
          return -5;
        case 2:
          return 0;
        case 3:
          return 5;
        case 4:
          return 15;
      }
    }
  };

  const returnCardHorizontalValueDependingOnIndexAndGameType = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      switch (index) {
        case 0:
          return 5;
        case 1:
          return -5;
      }
    }

    if (gameType === OMAHA) {
      switch (index) {
        case 0:
          return 7.5;
        case 1:
          return 2.5;
        case 2:
          return -2.5;
        case 3:
          return -7.5;
      }
    }

    if (gameType === OMAHA5) {
      switch (index) {
        case 0:
          return 5;
        case 1:
          return 2.5;
        case 2:
          return 0;
        case 3:
          return -2.5;
        case 4:
          return -5;
      }
    }
  };

  const returnCardVerticalValueDependingOnIndexAndGameType = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      return null;
    }

    if (gameType === OMAHA) {
      switch (index) {
        case 0:
          return 1.5;
        case 1:
          return -1;
        case 2:
          return -1;
        case 3:
          return 1.5;
      }
    }

    if (gameType === OMAHA5) {
      switch (index) {
        case 0:
          return 5;
        case 1:
          return 2.5;
        case 2:
          return 2;
        case 3:
          return 2.5;
        case 4:
          return 5;
      }
    }
  };

  const returnCardWidthBasedOnGameType = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      return 20;
    }
    if (gameType === OMAHA) {
      return 15;
    }

    if (gameType === OMAHA5) {
      return 10;
    }
  };

  const createOtherPlayerCards = () => {
    let isRightPlayer = checkIfIsRightPlayer(
      totalPlayersOnTable,
      mySeat,
      position
    );

    let isLeftPlayer = checkIfIsLeftPlayer(
      totalPlayersOnTable,
      mySeat,
      position
    );

    let isOppositePlayer = checkIfPlayerPositionIsOnTop(
      position,
      mySeat,
      totalPlayersOnTable
    );

    let isBottomPlayer = checkIfThePlayerPositionIsBottom(position, mySeat);

    const returnFoldHorizontalTranslationValue = () => {
      if (gameType === OMAHA || gameType === OMAHA5) {
        return;
      }

      if (isLeftPlayer) {
        return 100;
      }

      if (isRightPlayer) {
        return -100;
      }

      if (isOppositePlayer) {
        return 0;
      }

      return 0;
    };

    const returnFoldVerticalTranslationValue = () => {
      if (gameType === OMAHA || gameType === OMAHA5) {
        return;
      }

      if (isLeftPlayer || isRightPlayer) {
        return -50;
      }

      if (isOppositePlayer) {
        return 100;
      }

      if (isBottomPlayer) {
        return -100;
      }

      return 0;
    };

    return (
      <AnimatePresence
        key={"OtherPlayerCards-" + tempTableId + "playerPosition-" + position}
      >
        {!revealCard && playerComponentCards ? (
          <motion.div
            key={
              "otherPlayerCard-" + tempTableId + "playerPosition-" + position
            }
            initial={{
              y: 0,
              scale: 0,
              opacity: 0,
              // opacity: isFolded ? 0 : 1,
            }}
            animate={{
              y:
                isFolded || muckCards
                  ? returnFoldVerticalTranslationValue()
                  : -12.5,
              scale:
                (isFolded || muckCards) && gameType !== TEXAS_HOLDEM ? 0 : 1,
              x:
                isFolded || muckCards
                  ? returnFoldHorizontalTranslationValue()
                  : 0,
              opacity: isFolded || muckCards ? 0 : 1,
              rotate:
                (isFolded || muckCards) && gameType === TEXAS_HOLDEM
                  ? 360
                  : null, // Rotates 3 times
            }}
            exit={{
              y: isWinner || gameStage === GAME_STAGE_SHOWDOWN ? -20 : 0,
              scale: 0,
            }}
            transition={{
              duration: isFolded || muckCards ? 1 : 0.5,
            }}
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
            }}
          >
            {Array(getNumberOfCardsBasedOnGameType(gameType))
              .fill("cardBack")
              .map((card, index) => {
                return (
                  <motion.img
                    key={
                      "otherPlayerCardImage-" +
                      index +
                      "-tempTableId-" +
                      tempTableId +
                      "-card-" +
                      position
                    }
                    animate={{
                      rotateZ:
                        returnCardRotateValueDependingOnIndexAndGameType(index),
                      translateX:
                        returnCardHorizontalValueDependingOnIndexAndGameType(
                          index
                        ),
                      translateY:
                        returnCardVerticalValueDependingOnIndexAndGameType(
                          index
                        ),
                    }}
                    style={{
                      width: returnCardWidthBasedOnGameType() + "px",
                    }}
                    src={CardImages[cardBackTheme]}
                  ></motion.img>
                );
              })}
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  };

  // const createOtherPlayerCards = () => {
  //   if (
  //     isCardDistributionAnimationCompleted &&
  //     !revealCard &&
  //     playerComponentCards
  //   ) {
  //     if (playerComponentPosition === playerSeat) {
  //       return null;
  //     } else {
  //       let playerDirection = checkIfIsRightPlayer(
  //         totalPlayersOnTable,
  //         mySeat,
  //         playerComponentPosition
  //       );
  //       let numberOfCards = getNumberOfCardsBasedOnGameType(gameType);
  //       let otherPlayerCards = [];
  //       for (let i = 0; i < numberOfCards; i++) {
  //         otherPlayerCards.push(
  //           <OtherPlayerCard
  //             index={i}
  //             src={CardImages[cardBackTheme]}
  //             gameType={gameType}
  //             key={`otherPlayerCard-${i}-playerName-${userName}-${tempTableId}`}
  //           />
  //         );
  //       }
  //       return (
  //         <OtherPlayerCardsContainer
  //           isFolded={isFolded}
  //           isLeftPlayer={!playerDirection}
  //           isTexas={gameType === TEXAS_HOLDEM}
  //           id={`other-player-card-container-${tempTableId}-${playerComponentPosition}`}
  //         >
  //           {otherPlayerCards.map((card) => {
  //             return card;
  //           })}
  //         </OtherPlayerCardsContainer>
  //       );
  //     }
  //   }
  // };

  const createPlayerActionLabel = () => {
    const borderColor = allIn
      ? colors.allInBorder
      : lastAction === CALL_ACTION
      ? colors.callBorder
      : lastAction === CHECK_ACTION
      ? colors.checkBorder
      : lastAction === RAISE_ACTION
      ? colors.raiseBorder
      : lastAction === FOLD_ACTION
      ? colors.foldBorder
      : null;

    const actionBgColor = allIn
      ? colors.allInAction
      : lastAction === CALL_ACTION
      ? colors.callAction
      : lastAction === CHECK_ACTION
      ? colors.checkAction
      : lastAction === RAISE_ACTION
      ? colors.raiseAction
      : lastAction === FOLD_ACTION
      ? colors.foldAction
      : null;

    const myActionLabelVariant = {
      y: "-100%",
    };

    const otherActionLabelVariant = {
      y: "100%",
    };

    return isFolded || !isWinnerDeclared ? (
      <div
        style={{
          position: "absolute",
          left: "50%",
          bottom: position !== mySeat ? 0 : null,
          transform: "translateX(-50%)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnimatePresence
          key={
            "lastAction-" +
            lastAction +
            "playerActionLabelContainer-" +
            tempTableId +
            "-position-" +
            position
          }
        >
          {(!startTableTimer && lastAction) ||
          (allIn && atTableAmount === 0) ? (
            <motion.div
              key={
                "ActionLabel-" +
                lastAction +
                "-tempTableId-" +
                tempTableId +
                "-position-" +
                position
              }
              animate={
                position === mySeat
                  ? myActionLabelVariant
                  : otherActionLabelVariant
              }
              exit={{
                y: 0,
              }}
              // transition={{
              //   bounce: 0.5,
              // }}
              style={{
                background: actionBgColor,
                fontSize: "10px",
                borderRadius:
                  position === mySeat ? "3px 3px 0px 0px" : "0px 0px 3px 3px",
                // width: allIn || position !== mySeat ? "100%" : "40px",
                width: "100%",
                textAlign: "center",
                borderTop:
                  position === mySeat ? `1px solid ${borderColor}` : null,
                borderBottom:
                  position !== mySeat ? `1px solid ${borderColor}` : null,
                height: "14px",
                textTransform: "capitalize",
                fontWeight: "bold",
                zIndex: 1,
              }}
            >
              {" "}
              {allIn ? "All-In" : lastAction}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    ) : null;
  };

  const createPlayerComponent = () => {
    let isRightPlayer;
    if (playerSeat !== position) {
      isRightPlayer = checkIfIsRightPlayer(
        totalPlayersOnTable,
        mySeat,
        playerComponentPosition
      );
    }

    let isPlayerOpposite;
    if (playerSeat !== position) {
      isPlayerOpposite = checkIfPlayerPositionIsOnTop(
        playerComponentPosition,
        mySeat,
        totalPlayersOnTable
      );
    }

    return (
      <PlayerComponentContainer
        position={position}
        mySeat={mySeat}
        orientation={tableOrientation}
        isPlayerTurn={startTableTimer && tableTimer >= 0}
        // playAnimation={!playerMovedToCenter}
        id={"player-component-container-" + position}
        onClick={() => handlePlayerClick()}
      >
        {/* {mySeat !== playerComponentPosition ? createOtherPlayerCards() : null} */}
        {/* Components relative to the profile image only to be placed within ProfileImagesContainer */}
        <ProfileImagesContainer
          status={returnPlayerStatus()}
          orientation={tableOrientation}
          colorCode={colorCode ?? null}
          mySeat={mySeat}
          position={position}
          isActivePlayer={startTableTimer && tableTimer >= 0}
          id={"profile-images-container-" + position}
        >
          {/* TIMER Component */}
          {returnPlayerActiveTurnTimer()}
          {/* PLAYER AVATAR */}
          {returnPlayerAvatar()}
          {/* LABELS FOR ALL IN, SIT OUT, and WAIT FOR BB */}
          {returnPlayerStatusLabels()}
          {createMuckLabel()}
        </ProfileImagesContainer>
        {/* Components relative to player details position to be put within ProfileDetails */}
        <ProfileDetails
          position={position}
          mySeat={mySeat}
          isRightPlayer={isRightPlayer}
          orientation={tableOrientation}
          id={"profile-details-container-" + position}
          colorCode={colorCode ?? null}
        >
          {/* Creating Other Player Cards */}
          {mySeat !== playerComponentPosition && isActive
            ? createOtherPlayerCards(playerComponentPosition)
            : null}
          {lastAction || allIn ? createPlayerActionLabel() : null}
          <RelativeWrapper>
            {/* Returns Name and Table Amount */}
            {returnPlayerTableData()}
            {/* Winning Label */}
            {/* {returnWinnerLabel()} */}
            {/* This is what creates Dealer, SB, BB, and bet amount component for other players */}
            {(mySeat !== playerComponentPosition &&
              playerComponentPosition !== 0) ||
            (mySeat !== undefined &&
              mySeat !== playerComponentPosition &&
              tableOrientation === "PORTRAIT") ||
            (tableOrientation === "LANDSCAPE" &&
              mySeat !== playerComponentPosition &&
              !isPlayerOpposite)
              ? createOtherPlayerActionComponent()
              : null}
            {/* Top Up Component */}
            {/* {returnTopUpComponent()} */}
            {/* Player Hand Type */}
            {/* {!isFolded ? returnPossibleWinningHandType() : null} */}
            {/* Winning Amount Display */}
            {returnPlayerWinningAmount()}
            {/* Reveal cards at showdown */}
            {/* {isWinnerDeclared && revealCardsOnShowDown()} */}
            {/* {tableOrientation === "LANDSCAPE" && mySeat !== position
              ? showActionLabelOnProfile()
              : null} */}
            {tableOrientation === "LANDSCAPE" && mySeat !== position
              ? showDealerIcon()
              : null}
            {playerNotes && <PlayerNotesIconWrapper src={playerNotesIcon} />}
          </RelativeWrapper>
        </ProfileDetails>
        {/* Components relative to entire player component to be placed here */}
        {/* This is what creates Dealer, SB, BB, and bet amount component for ACTIVE/bottom player */}
        {mySeat === playerComponentPosition ||
        (playerComponentPosition === 0 && mySeat === undefined)
          ? returnPlayerActionComponent()
          : null}
        {returnPlayerRippleOnActiveTurn()}
        {returnPlayerWinnerLottieAnimation()}
        {/* {createChipsOnTable()} */}
        {tableOrientation === "PORTRAIT" || mySeat === position
          ? showDealerIcon()
          : null}
        {/* {showAllInIcon()} */}
        {returnPlayerTimerTypeLabel()}
        {/* {tableOrientation === "PORTRAIT" || mySeat === position
          ? showActionLabelOnProfile()
          : null} */}
        {mySeat !== playerComponentPosition &&
        tableOrientation === "LANDSCAPE" &&
        isPlayerOpposite
          ? createOtherPlayerActionComponent(true)
          : null}
        {revealCard && revealCardsOnShowDown()}
      </PlayerComponentContainer>
    );
  };

  // const revealCardsOnShowDown = () => {
  //   const { cardWidth: cardWidth, cardHeight: cardHeight } =
  //     getCardWidthAndHeight();
  //   let cards = [];
  //   let newCardWidth = cardWidth * 0.7,
  //     newCardHeight = cardHeight * 0.7;

  //   let isRightPlayer = checkIfIsRightPlayer(
  //     totalPlayersOnTable,
  //     mySeat,
  //     playerComponentPosition
  //   );

  //   let isLeftPlayer = checkIfIsLeftPlayer(
  //     totalPlayersOnTable,
  //     mySeat,
  //     playerComponentPosition
  //   );

  //   let offset =
  //     gameType === TEXAS_HOLDEM
  //       ? playerComponentSize / 10
  //       : gameType === OMAHA
  //       ? isLeftPlayer
  //         ? playerComponentSize * 0.05
  //         : isRightPlayer
  //         ? -playerComponentSize * 0.35
  //         : tableOrientation === PORTRAIT
  //         ? -playerComponentSize / 5
  //         : -playerComponentSize / 2.5
  //       : gameType === OMAHA5
  //       ? isLeftPlayer
  //         ? playerComponentSize * 0.03
  //         : isRightPlayer
  //         ? -playerComponentSize / 1.5
  //         : tableOrientation === PORTRAIT
  //         ? -playerComponentSize / 3
  //         : -playerComponentSize / 1.5
  //       : playerComponentSize / 10;

  //   if (playerComponentPosition !== playerSeat && !isFolded) {
  //     if (playerComponentCards && playerComponentCards.length > 0) {
  //       playerComponentCards.map((eachCard, index) => {
  //         let offsetBetweenCards = 15;
  //         cards.push(
  //           <Card
  //             tempTableId={tempTableId}
  //             width={newCardWidth}
  //             height={newCardHeight}
  //             source={eachCard}
  //             top={playerComponentSize * 0.05}
  //             left={offset + offsetBetweenCards * index}
  //             id={`card-${tempTableId}-${eachCard}`}
  //             key={
  //               "card-" +
  //               index +
  //               "playerName-" +
  //               userName +
  //               "table-chip" +
  //               tempTableId
  //             }
  //           ></Card>
  //         );
  //       });
  //     }
  //   }
  //   return cards;
  // };

  const revealCardsOnShowDown = () => {
    const isPlayerOpposite = checkIfPlayerPositionIsOnTop(
      playerComponentPosition,
      mySeat,
      totalPlayersOnTable
    );

    return (
      <AnimatePresence
        key={"revealCardsContainer-" + tempTableId + "-position-" + position}
      >
        {playerComponentPosition !== mySeat &&
        playerComponentCards?.length > 0 &&
        !isFolded ? (
          <motion.div
            key={"revealCardsDiv-" + tempTableId + "-position-" + position}
            initial={{
              opacity: 0,
              scale: 0,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              // Need to find a better way to center the reveal cards.
              translateX: "-50%",
            }}
            exit={{
              opacity: 0,
              scale: 0,
            }}
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {playerComponentCards.map((card, index) => {
              return (
                <motion.img
                  key={"revealCardsImage-" + tempTableId + "-card-" + card}
                  // animate={shouldCardTranslate(card)}
                  initial={{
                    y: 0,
                    x: 0,
                    scale: 0,
                    filter: null,
                  }}
                  animate={{
                    scale: 1,
                    y:
                      winningHandArray.includes(card) && !isPlayerOpposite
                        ? -10
                        : returnCardVerticalValueDependingOnIndexAndGameType(
                            index
                          ),
                    filter:
                      winningHandArray.includes(card) && isWinnerDeclared
                        ? "brightness(1)"
                        : !winningHandArray.includes(card) && isWinnerDeclared
                        ? "brightness(0.6)"
                        : null,
                    x: returnCardHorizontalValueDependingOnIndexAndGameType(
                      index
                    ),
                    rotateZ:
                      returnCardRotateValueDependingOnIndexAndGameType(index),
                    transformOrigin: "center",
                  }}
                  style={{
                    width:
                      gameType === TEXAS_HOLDEM
                        ? "30px"
                        : gameType === OMAHA
                        ? "20px"
                        : "15px",
                    boxShadow:
                      index > 0 ? "-1px 0px 8px 0px rgba(0,0,0,0.8)" : null,
                    zIndex: 6,
                    borderRadius: "5%",
                    // border: "1px solid rgba(0,0,0,0.5)",
                  }}
                  src={cardThemes[cardDeckTheme][card]}
                ></motion.img>
              );
            })}
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  };

  const returnPlayerActiveTurnTimer = () => {
    return startTableTimer && tableTimer >= 0 ? (
      <TimerSVG
        tempTableId={tempTableId}
        playerPosition={position}
        timer={tableTimer}
        type={TIMER_TYPE_PLAYER}
        playerTimerType={activeTableTimerType}
        playerTotalTime={totalTime}
      />
    ) : null;
  };

  const returnPlayerTimerTypeLabel = () => {
    if (
      activeTableTimerType === DISCONNECTION_TIMER &&
      playerComponentPosition === playerSeat
    )
      return;

    return !sitOutOption &&
      (activeTableTimerType === PLAYER_EXTRA_TIMER ||
        activeTableTimerType === DISCONNECTION_TIMER) ? (
      <TimerTypeLabel
        type={activeTableTimerType}
        isLoggedInPlayer={playerComponentPosition === playerSeat}
      >
        {activeTableTimerType === PLAYER_EXTRA_TIMER
          ? EXTRA_TIME
          : activeTableTimerType === DISCONNECTION_TIMER
          ? DISCONNECTED
          : null}
      </TimerTypeLabel>
    ) : null;
  };

  const returnPlayerRippleOnActiveTurn = () => {
    return tableTimer ? (
      <RippleWrapper id='ripple' ref={profileRef}></RippleWrapper>
    ) : null;
  };

  const returnPlayerWinnerLottieAnimation = () => {
    let winningColor;

    let animationArray = [redGlow, blueGlow, yellowGlow, greenGlow, grayGlow];

    return (
      isWinner &&
      isWinnerDeclared &&
      winningPotData && (
        // startWinnerPotAnimation &&
        <LottieWrapper>
          <Lottie
            style={{ width: "100%", height: "100%" }}
            animationData={animationArray[winningPotData?.potIndex]}
            ref={lottieAnimationRef.current}
            autoPlay={true}
            loop={true}
          />
        </LottieWrapper>
      )
    );
  };

  const returnIsPlayerActive = () => {
    let isActivePlayer =
      (playerSeat === position && playerSitOutTimer) || sitOutOption
        ? false
        : userName && isTakenSeat
        ? isFolded
          ? false
          : isActive
          ? true
          : false
        : true;

    return isActivePlayer;
  };

  const returnPlayerTableData = () => {
    return (
      <>
        <DetailsBlock
          orientation={tableOrientation}
          softEdges={"top"}
          isActive={returnIsPlayerActive()}
          style={{ borderRadius: "2px 2px 0px 0px" }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              // backgroundColor: colorCode ? `#${colorCode}` : "transparent",
              width: "0px",
              height: "0px",
            }}
          ></div>
          <DetailsText>
            {userName.length > MAX_USERNAME_LENGTH
              ? userName.substr(0, MAX_USERNAME_LENGTH) + "..."
              : userName}
          </DetailsText>
        </DetailsBlock>
        <DetailsBlock
          orientation={tableOrientation}
          softEdges={"bottom"}
          isActive={returnIsPlayerActive()}
          style={{ borderRadius: "0px 0px 2px 2px" }}
          // colorCode={colorCode ?? null}
        >
          {!isActive &&
          !playerSitOutTimer &&
          isGameStarted &&
          playerComponentPosition !== null &&
          playerComponentPosition !== undefined &&
          isWaitingForBigBlind &&
          playerComponentPosition === playerSeat ? (
            <DetailsText gold={true}>
              <InfinityText>Waiting for big blind</InfinityText>
            </DetailsText>
          ) : (
            <DetailsText
              style={{ fontWeight: "bold", fontSize: "0.75rem" }}
              gold={true}
            >
              {BBDisplay ? (
                parseDecimalValuesOfNumber(atTableAmount / bigBlindValue, 1) +
                " BB"
              ) : isFunGame || isTourneyGame ? (
                <TourneyChipsWrapper>
                  <ChipImg src={yellowChip} />
                  {parseDecimalValuesOfNumber(
                    atTableAmount,
                    2,
                    checkToDisplayValueInK(atTableAmount),
                    checkToDisplayValueInL(atTableAmount)
                  )}
                </TourneyChipsWrapper>
              ) : (
                RUPEE_SYMBOL +
                parseDecimalValuesOfNumber(
                  atTableAmount,
                  2,
                  checkToDisplayValueInK(atTableAmount),
                  checkToDisplayValueInL(atTableAmount)
                )
              )}
            </DetailsText>
          )}
        </DetailsBlock>
      </>
    );
  };

  const returnPlayerAvatar = () => {
    // let newPhoto =
    //   avatar && avatar !== "https://images.fanfight.com/avatars/avatar1.png"
    //     ? avatar
    //     : playerAvatar;
    let oldPhoto = avatar
      ? avatar
      : "https://images.fanfight.com/avatars/avatar1.png";
    return (
      <ProfileImage
        src={oldPhoto}
        isActive={returnIsPlayerActive()}
        onTouchStart={handleLongTouchStart}
        onTouchEnd={handleLongTouchEnd}
      ></ProfileImage>
    );
  };

  const createMuckLabel = () => {
    return muckCards ? (
      <AnimatePresence>
        <motion.div
          style={{
            position: "absolute",
            // top: '50%',
            // transform: 'translate(-50%)',
            // left: '50%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: colors.primaryDeepBlue,
            width: "120%",
            height: "18px",
            fontSize: "10px",
            fontWeight: "bold",
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
        >
          Muck
        </motion.div>
      </AnimatePresence>
    ) : null;
  };

  const returnPlayerStatusLabels = () => {
    // if (userName && isTakenSeat && autoMuck) {
    //   return <MuckLabelWrapper>Muck</MuckLabelWrapper>;
    // }
    if (isTourneyGame && tourneyAutoPlay && playerSeat === position) {
      return (
        <SitOutLabelWrapper type={"sit-out"}>AUTO PLAY</SitOutLabelWrapper>
      );
    }

    return atTableAmount === 0 && allIn ? null : !isWinnerDeclared && // <AllInLabelWrapper>ALL IN</AllInLabelWrapper>
      ((playerSeat === position && playerSitOutTimer) || sitOutOption) ? (
      <SitOutLabelWrapper type={"sit-out"}>SIT OUT</SitOutLabelWrapper>
    ) : // playerIsWaitingForBigBlind ? (
    // <SitOutLabelWrapper type={"wait-for-bb"}>WAIT FOR BB</SitOutLabelWrapper>
    // ) :
    null;
  };

  // const handleTopupClick = () => {
  //   if (atTableAmount < gameDefinition.maxbuyInAmount) {
  //     dispatch(
  //       updateTopupModalOpenStatus({ tempTableId: tempTableId, isOpen: true })
  //     );
  //   } else if (atTableAmount >= gameDefinition.maxBuyInAmount) {
  //     dispatch(
  //       setToastMessage({
  //         tempTableId: tempTableId,
  //         type: TOAST_INFO,
  //         message: "You have already reached Max buyin Amount",
  //       })
  //     );
  //   }
  // };

  const returnPlayerStatus = () => {
    let status = false;
    if (atTableAmount === 0 && allIn) {
      status = "all-in";
    } else if (sitOutOption) {
      status = "sit-out";
    } else if (playerIsWaitingForBigBlind) {
      status = "wait-for-bb";
    }
    return status;
  };

  const returnWinnerLabel = () => {
    return isWinner ? <WinnerLabel>Winner</WinnerLabel> : null;
  };

  // const returnTopUpComponent = () => {
  // console.log(
  //   "RUPEE SYMBOL",
  //   gameDefinition.minBuyInAmount,
  //   atTableAmount <= gameDefinition.minBuyInAmount
  // );
  // return
  // mySeat === position && isGameStarted ? (
  //   <div style={{zIndex: 10}} onClick={handleTopupClick}>
  //   <AddAmount src={cashRefresh} />
  //   </div>
  // ) :
  // null;
  // };

  const returnPlayerWinningAmount = () => {
    // let winningAmount;
    // if (winningPotData && winningPotData?.winnerNames.includes(userName)) {
    //   winningAmount = winningPotData?.winners[userName]?.winningAmount;
    // }
    return userName && isTakenSeat && winningAmount ? (
      <WinningAmount
        orientation={tableOrientation}
        mySeat={mySeat}
        position={position}
        id={"winning-" + id}
      >
        + {BBDisplay ? null : isFunGame ? null : RUPEE_SYMBOL}
        {BBDisplay
          ? parseDecimalValuesOfNumber(winningAmount / bigBlindValue, 1) + " BB"
          : parseDecimalValuesOfNumber(
              winningAmount,
              2,
              checkToDisplayValueInK(winningAmount),
              checkToDisplayValueInL(winningAmount)
            )}
      </WinningAmount>
    ) : null;
  };

  const createSitHereComponent = () => {
    return (
      <SitHereComponentContainer frame={frame} ref={profileRef}>
        {reserveLoading.status && reserveLoading.position === position ? (
          <RelativeWrapper>
            <Loader size={25} />
          </RelativeWrapper>
        ) : playerSeat === undefined || playerSeat === null ? (
          <RelativeWrapper
            onClick={() => {
              onSitHereClick();
            }}
          >
            <PlayerSitHereIconWrapper src={sitHereIcon} />
          </RelativeWrapper>
        ) : null}

        {/* <SitHereGlowContainer>
        </SitHereGlowContainer> */}
      </SitHereComponentContainer>
    );
  };

  const createEmptySeat = () => {
    return (
      <SeatComponentContainer type={"empty"}>
        <RelativeWrapper>
          <SeatStatusText type={"empty"}>Empty</SeatStatusText>
        </RelativeWrapper>
      </SeatComponentContainer>
    );
  };

  const createReservedSeat = () => {
    return (
      <SeatComponentContainer type={"reserved"}>
        <RelativeWrapper>
          <SeatStatusText type={"reserved"}>Reserved</SeatStatusText>
        </RelativeWrapper>
      </SeatComponentContainer>
    );
  };

  const handlePlayerClick = (e) => {
    if (isFunGame && playerComponentPosition === mySeat) {
      return;
    }
    if (
      // position !== playerSeat &&
      // playerSeat !== undefined &&
      // playerSeat !== null &&
      !isReservedSeat &&
      userName &&
      isTakenSeat
    ) {
      // Request player stats notes and colors here
      let gameConnection =
        GameConnectionsN.getInstance().getGameConnection(tempTableId);
      gameConnection.listener.requestPlayerStats({
        playerName: getPlayerObject().userName,
        opponetName: userName,
        // enabledPlayerStats: null,
        // playerNotes: null,
        // colorCode: null,
      });
      if (isTourneyGame) {
        //NOTE: We dont have player stats in tourneys earlier,
        //we are adding now because of IM-3678 ticket. if we integrate player notes and color tagging as well this below modal opening script is tehre in ps# response method in slice so this will be duplicate we need to remove it.
        window.sessionStorage.setItem(
          "player-profile-data",
          JSON.stringify(playerData)
        );
        dispatch(
          openLocalGameModal({ tempTableId: tempTableId, type: PLAYER_STATS })
        );
      }
      return;
    } else {
      return null;
    }
  };

  const isPlayerSeated = () => {
    if (
      playerSeat !== undefined &&
      playerSeat !== null &&
      playerSeat === position
    ) {
      return true;
    }

    if (playerComponentPosition === position && !isReservedSeat) {
      return true;
    }

    return false;
  };

  const returnPlayerExtraTimeLeftIcon = () => {
    // showing extra time only if player is connected and active
    if (
      position === mySeat &&
      playerSeat !== null &&
      playerSeat !== undefined &&
      isActive &&
      (isTourneyGame || isGameStarted) &&
      gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS &&
      playerData?.extraTimeLeft >= 0
    ) {
      return (
        <ExtraTimeLeftWrapper>
          <ExtraTimeLeftIcon src={extraTimeLeftClock} />
          <ExtraTimeLeftText>{playerData.extraTimeLeft}</ExtraTimeLeftText>
        </ExtraTimeLeftWrapper>
      );
    }
  };

  return (
    positionsArranged && (
      <Container
        size={playerComponentSize}
        // ref={refs}
        top={top}
        left={left}
        bottom={bottom}
        right={right}
        scale={scale}
        id={`player-${id}`}
        isSeated={isPlayerSeated(position)}
        position={position}
        mySeat={playerSeat}
        orientation={tableOrientation}
        key={`playerComponentRevamp-${userName}-table-${tempTableId}`}
        isOmaha5={gameType === OMAHA5}
      >
        <RelativeWrapper>
          {/* Player Component */}
          {createPlayerComponentLogic()}
          {position === mySeat ? (
            <MyCards position={position} tempTableId={tempTableId} />
          ) : null}
          {returnPlayerExtraTimeLeftIcon()}
        </RelativeWrapper>
      </Container>
    )
  );
};

export default PlayerComponent;
