import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import { motion } from "framer-motion";

export const MultiPotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 90%;
  /* background-color: red; */
`;

export const PotContainer = styled(motion.div)`
  background-color: black;
  color: white;
  border-radius: 24px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 16px;
  position: relative;
`;

export const PotAmountChip = styled(motion.img)`
  height: 16px;
  width: auto;
  position: absolute;
  left: 0;
`;

export const PotAmountChipsContainer = styled.div`
    height: 16px;
    width: 16px;
    position: relative;
`

export const PotAmountText = styled(motion.div)`
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Conto";
  margin: 0px 6px;
`;
