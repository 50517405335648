import Handler from "../Handler";

class JoinFailureHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.handleJoinFailure(receivedData);
  }
}

export default JoinFailureHandler;
