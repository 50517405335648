import {
  GAME_STAGE_FLOP,
  GAME_STAGE_RIVER,
  GAME_STAGE_SHOWDOWN,
  GAME_STAGE_TURN,
} from "../../data/Constants";
import Handler from "../Handler";

class RevealCardsHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    const { revealCard } = receivedData;
    let cards = [...revealCard];
    receivedData.revealCard = [...cards];
    this.service.processRevealCards(receivedData);
  }
}

export default RevealCardsHandler;
