import Handler from "../Handler";

class TourneyLobbydataHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyLobbyData(receivedData);
  }
}
export default TourneyLobbydataHandler;

// {
//   "upcomingTourneys": [
//     {
//       "tourneyName": "testing",
//       "tourneyType": "GTD",
//       "tourneyEntryChips": 0,
//       "maxAllowedPlayers": 0,
//       "totalPrize": "",
//       "currentLevel": 0,
//       "isTourneyStart": false,
//       "isTourneyEnd": false,
//       "tourneyStatus": "started",
//       "tourneyDescType": "",
//       "tourneyStartTime": "1697700385",
//       "levelDuration": 0,
//       "nextLevel": 0,
//       "isAnnouncedTourneyDisplayedInLobby": false,
//       "tourneyPrizeList": [],
//       "levelStopFlag": {},
//       "tourneyTables": {},
//       "addedTables": {},
//       "removedTables": [],
//       "loggedInPlayers": [],
//       "nonLoggedInPlayers": [],
//       "nonLoggedNextGamePlayers": [],
//       "lateRegisteredPlayers": [],
//       "tableCountFixed": false,
//       "fixedTablesCount": 0,
//       "sliderTimings": [],
//       "levelPlayers": {},
//       "loggedNextGamePlayers": [],
//       "tableIDSequence": 1,
//       "breakTime": 0,
//       "tourneyCompleted": false,
//       "tourneyEndTimeBuffer": false,
//       "registeredPlayerCount": 0,
//       "tourneyBuyin": 0,
//       "allowedAceLevels": [],
//       "parentTourney": "NA",
//       "perEachWinningAmount": 0,
//       "entryDesc": "NA",
//       "winners": [],
//       "childTourneys": [],
//       "tourneyRebuyTimerCreated": false,
//       "totalWinners": 0,
//       "prizeDistributionCompleted": false,
//       "lateRegistrationAllowed": false,
//       "lateRegisterEntryAmount": 0,
//       "lateRegisterStartingStack": 0,
//       "lateRegistrationsCount": 0,
//       "sitoutCount": 0,
//       "maxRebuyCount": -1,
//       "smallBlind": 0,
//       "bigBlind": 0,
//       "isWatchPlayerEnable": false,
//       "tableTime": 0,
//       "disconnectionExtraTime": 0,
//       "nextGameTimer": 0,
//       "minBuyInAmount": 0,
//       "maxbuyInAmount": 0,
//       "isRITEnabled": false,
//       "maxPlayers": 10,
//       "maxJoinAndWatchPlayers": 0,
//       "maxWatchPlayersCount": 0,
//       "maxJoinWaitListCount": 0,
//       "isWaitListEnabled": false,
//       "isSplitEnabled": false,
//       "minPlayersToStartGame": 0,
//       "isEnabled": false,
//       "maxTables": 0,
//       "onlineCount": 0,
//       "isStraddleEnabled": false,
//       "isSNG": false,
//       "sgtExtraTime": 0,
//       "sgtETCount": 0,
//       "rake": 0,
//       "headsUpRake": 0,
//       "sitOutTimeInMins": 0,
//       "reserveTime": 0,
//       "rakeCapForStartPlayersLETo3": 0,
//       "rakeCapForStartPlayersGT3": 0,
//       "buyInTimer": 0,
//       "rebuyInTimer": 0,
//       "timeBank": 0,
//       "isRankSuggestionEnabled": false,
//       "rabbitHunting": true,
//       "anonymous": false,
//       "leaderboard": false,
//       "turbo": false,
//       "startGameTime": 0,
//       "antiBankingTime": 0,
//       "featured": false,
//       "tourneyId": "OxYNO",
//       "tourneyStarted": true,
//       "tablesCount": 2,
//       "completedTablesCount": 0,
//       "levelCount": 1,
//       "lastLevel": false,
//       "playerTourneyStatus": "",
//       "tourneyEnded": false,
//       "tourneyCancelled": false,
//       "tourneyRake": 10,
//       "registrationStartTime": 1697700385,
//       "registrationEndTime": 1697700385,
//       "announcementStartDate": 1697700385,
//       "minPlayers": 3,
//       "entryFee": 5,
//       "cashType": "cashType",
//       "bonusPercentage": 10,
//       "prizeStructure": "GTD",
//       "prizePayoutType": "fixed",
//       "payoutStructure": {
//         "payoutDataList": [
//           {
//             "minPlayers": 0,
//             "maxPlayers": 0,
//             "winningDistribution": [
//               {
//                 "rankFrom": 1,
//                 "rankTo": 1,
//                 "prize": 500
//               },
//               {
//                 "rankFrom": 2,
//                 "rankTo": 2,
//                 "prize": 300
//               },
//               {
//                 "rankFrom": 3,
//                 "rankTo": 5,
//                 "prize": 100
//               },
//               {
//                 "rankFrom": 6,
//                 "rankTo": 15,
//                 "prize": 70
//               },
//               {
//                 "rankFrom": 16,
//                 "rankTo": 45,
//                 "prize": 50
//               }
//             ]
//           }
//         ]
//       },
//       "prizePool": 3300,
//       "tourneyRules": "just do it",
//       "isFeatured": false,
//       "totalNumberOfRounds": 10,
//       "playersPerGame": 0,
//       "roundBreakTime": 10,
//       "tourneyBreakTime": 10,
//       "breakInterval": 10,
//       "skips": 0,
//       "turnTimer": 0,
//       "bidTime": 0,
//       "extraTimer": 0
//     }
//   ],
//   "completedTourneys": [],
//   "myTourneys": [],
//   "tourneyTiles": [
//     "All",
//     " GTD",
//     " Free Roll",
//     " Sit And Go",
//     " Satellite",
//     " Timer"
//   ]
// }
