import styled, { css } from "styled-components";
import {
  Typo10GrayContoMediumText,
  Typo10WhiteContoMediumText,
  Typo12PrimaryGoldContoMediumText,
  Typo12SecondaryRedContoMediumText,
  Typo12WhiteContoMediumText,
  Typo14OffWhiteContoMediumText,
  Typo14PrimaryGoldContoBoldText,
  Typo14WhiteContoBoldText,
  Typo14WhiteContoMediumText,
  Typo16WhiteContoMediumText,
} from "../../../styleGuide/Typos";
import colors from "../../../styleGuide/Colors";

export const InsufficientFundsText = styled(Typo14OffWhiteContoMediumText)`
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: normal;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
`;

export const BalanceDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
  justify-content: center;
`;

export const BalanceDetailsWrapper = styled(Typo14OffWhiteContoMediumText)`
  line-height: normal;
`;

export const BalanceAmountText = styled(Typo14PrimaryGoldContoBoldText)`
  margin-left: 4px;
  text-align: center;
`;

export const InsufficientFundsBucketDeductionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${(props) =>
    props.isOpen ? `1px solid ${colors.deepTeal}` : `1px solid transparent}`};
  height: ${(props) => (props.isOpen ? "120px" : "0px")};

  margin-top: 12px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: ${(props) =>
    props.isOpen
      ? "height 0.4s, visibility 0s linear 600ms"
      : "height 0.4s, visibility 0s linear 0ms"};
`;

export const TotalBalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${colors.deepTeal};
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
`;

export const TotalBalanceText = styled(Typo12WhiteContoMediumText)``;

export const EachDeductionDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 3px 14px;
  box-sizing: border-box;
`;

export const DeductionLableText = styled(Typo12WhiteContoMediumText)``;

export const BonusCappingText = styled(Typo12SecondaryRedContoMediumText)``;

export const BalanceAmountToAdd = styled(Typo12PrimaryGoldContoMediumText)`
  text-align: center;
`;

export const ArrowIconWrapper = styled.img`
  width: 8px;
  height: 6px;
  margin-left: 4px;
  transform: ${(props) => (props.isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const AddIconWrapper = styled.img`
  width: 8px;
  height: 8px;
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ChipIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const ReloadIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const ButtonText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 15px;
`;

export const TourneyAddOnDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const TourneyDetailsText = styled(Typo14WhiteContoMediumText)`
  text-transform: uppercase;
`;

export const TourneyDescriptionText = styled(Typo14WhiteContoMediumText)`
  margin-top: 8px;
`;

export const TourneyAddOnDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
`;

export const TourneyDetailsLabelTextWrapper = styled(
  Typo12WhiteContoMediumText
)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
`;

export const EachAddOnDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
`;
export const TourneyDetailsValueWrapper = styled(Typo12WhiteContoMediumText)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin-left: 15%;
`;

export const ColumnText = styled(Typo12WhiteContoMediumText)`
  width: auto;
`;

export const ChipsToBeGivenText = styled(Typo10GrayContoMediumText)`
  margin-left: 4px;
`;

export const TicketDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const TicketIcon = styled.img`
  width: 109.61px;
  height: 73.5px;
`;

export const TicketDescriptionText = styled(Typo16WhiteContoMediumText)`
  line-height: 21.68px;
  margin-top: 16px;
`;

export const TourneyNameText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 16.26px;
  margin-top: 8px;
`;

export const tourneyTicketReceivedPopupCustomStyles = css`
  font-size: 16px !important;
  font-weight: 700 !important;
`;
