import React from "react";
import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  IconWrapper,
  MessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  CloseIcon,
  MessageWrapper,
  MessageHighLightText,
  SecondaryCTAButton,
} from "./gameLooserModalStyles";
import { useDispatch, useSelector } from "react-redux";

import GreatIcon from "../../../assets/gameTable/GreatIcon.svg";
import closeIcon from "../../../assets/common/close.svg";

import {
  closeLocalGameModal,
  deleteGameTable,
  exitGameTable,
  hideGameTable,
  resetTableData,
} from "../../redux/slices/gameTableSlice";
import {
  closeTourneyInfoPage,
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  requestTourneyTablesDynamicData,
} from "../../redux/slices/lobbySlice";
import TableUtils from "../../utils/TableUtils";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import { TOURNEY_INFO_TABLES_TAB } from "../../data/Constants";

function GameLooserModal(props) {
  const dispatch = useDispatch();
  const { modalConfig } = props;
  const playerName = useSelector((state) => state.lobby.player.userName);
  const tourneyGameCompleted = useSelector(
    (state) => state.gameTable.games[props.tempTableId].tourneyGameCompleted
  );
  const tourneyId = useSelector(
    (state) => state.gameTable.games[props.tempTableId].gameDefinition.tourneyId
  );

  const upcomingTourneys = useSelector(
    (state) => state.lobby.tourneys.upcomingTourneys
  );
  const completedTourneys = useSelector(
    (state) => state.lobby.tourneys.completedTourneys
  );
  const myTourneys = useSelector((state) => state.lobby.tourneys.myTourneys);

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const masterTourneysDefinitions = [
      ...myTourneys,
      ...upcomingTourneys,
      ...completedTourneys,
    ];
    let tourney = masterTourneysDefinitions.find(
      (tourney) => tourney.tourneyId === tourneyId
    );
    return tourney ? tourney : false;
  };

  const handleModalClose = () => {
    TableUtils.getInstance().overrideRemovedTableObserver(props.tempTableId);
    dispatch(
      resetTableData({ tempTableId: props.tempTableId, isTourneyGame: true })
    );
    sendFaroLog({
      type: "Initiate Watch player channel connection at tourney looser",
      tempTableId: props?.tempTableId,
    });
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  };

  const handlePrimaryCTAClick = () => {
    // handleModalClose();
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
    dispatch(closeTourneyInfoPage());
    dispatch(hideGameTable());
    dispatch(deleteGameTable({ tempTableId: props.tempTableId }));
    sendFaroLog({
      type: "Exit Table from tourney Looser popup",
      tempTableId: props?.tempTableId,
    });
    // dispatch(
    //   exitGameTable({ tempTableId: props.tempTableId, playerName: playerName })
    // );
  };

  const handleSpectateClick = () => {
    let gameDefinition = getTourneyDefinitionByTourneyId(tourneyId);
    if (gameDefinition) {
      dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
      TableUtils.getInstance().removeTableObserver(props.tempTableId, true);
      dispatch(hideGameTable());
      dispatch(
        requestTourneyInfoData({
          tourneyDetails: {
            ...gameDefinition,
            defaultSelectedTab: TOURNEY_INFO_TABLES_TAB,
          },
        })
      );
      dispatch(
        requestTourneyEntriesDynamicData({
          tourneyId: tourneyId,
        })
      );
      dispatch(requestTourneyTablesDynamicData({ tourneyId: tourneyId }));
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Thanks for Playing!</HeaderTitleText>
          {/* {!tourneyGameCompleted && (
            <CloseIcon src={closeIcon} onClick={handleModalClose} />
          )} */}
        </ModalHeader>

        <ModalBody>
          <IconWrapper src={GreatIcon} />
          <MessageWrapper>
            <MessageText>{`You have finished the tournament`}</MessageText>
            {/* <MessageText>
              {`in place`}
              <MessageHighLightText>{`#${modalConfig?.rank}`}</MessageHighLightText>
            </MessageText> */}
            <MessageText>
              {`It was a good game. See you again at the tables! `}
            </MessageText>
          </MessageWrapper>
          {tourneyGameCompleted ? (
            <CTAContainer>
              <PrimaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Go to Lobby</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          ) : (
            <CTAContainer>
              <SecondaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Go to Lobby</ButtonText>
              </SecondaryCTAButton>
              <PrimaryCTAButton onClick={handleSpectateClick}>
                <ButtonText>Spectate</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          )}
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default GameLooserModal;
