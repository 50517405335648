import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import animation from "./maintenance-lottie.json";
import maintenanceImage from "./maintenance.svg";

export const MaintenanceAnimation = ({ size, margin }) => {
  const lottieAnimationRef = useRef(null);
  const maintenanceAnimationTimeout = useRef(null);

  useEffect(() => {
    maintenanceAnimationTimeout.current = setTimeout(() => {
      lottieAnimationRef?.current?.play();
    }, 100);

    return () => clearTimeout(maintenanceAnimationTimeout.current);
  }, []);

  return (
    <div style={{ position: "relative", width: "80%", height: "fit-content" }}>
      <img
        style={{
          position: "relative",
          width: '100%',
        }}
        src={maintenanceImage}
      ></img>
      <Lottie
        style={{
          position: "absolute",
          bottom: '2%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: "25%",
        }}
        animationData={animation}
        ref={lottieAnimationRef.current}
        autoPlay={true}
        loop={true}
      />
    </div>
  );
};
