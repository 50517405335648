import Handler from "../Handler";

class RemoveFromWaitListBroadcastHandler extends Handler {
  execute(message) {
    // UnWaitB#
    // {playerName:”abc”,tableId:”fvd”,status:100,totWaitListCount:integer}

    let receivedData = JSON.parse(message);
    this.service.processRemoveFromWaitListBroadcast(receivedData);
  }
}

export default RemoveFromWaitListBroadcastHandler;
