import React from "react";
import {
  GlobalModal,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  RelativeWrapper,
} from "../../Modals/modalStyle.js";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos.js";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil.js";
import { ModalText, OKButton } from "./ForceLogoutStyles.js";

const DeletedPlayerForceLogout = (props) => {
  return (
    <GlobalModal>
      <ModalContainer>
        <RelativeWrapper>
          <ModalWrapper>
            <ModalHeader>
              <Typo16WhiteContoBoldText>Note</Typo16WhiteContoBoldText>
            </ModalHeader>
            <ModalBody>
              <ModalText bold={true}>
                Your account has been suspended. Please reach out to
                info@a23.com
              </ModalText>
              <OKButton
                onClick={() => {
                  messageToNativeClient({ type: "forceLogout" });
                }}
              >
                Log out
              </OKButton>
            </ModalBody>
          </ModalWrapper>
        </RelativeWrapper>
      </ModalContainer>
    </GlobalModal>
  );
};

export default DeletedPlayerForceLogout;
