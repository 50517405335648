// Lobby Events
export const IP_Client_LobbyView = "PR_Client_LobbyView"; //Done
export const IP_Client_CashFunToggle_Click = "PR_Client_CashFunToggle_Click"; //Done
export const IP_Client_TourneyTile_Click = "PR_Client_TourneyTile_Click"; // Done
export const IP_Client_TourneyInfoPage_View = "PR_Client_TourneyInfoPage_View"; // done
export const IP_Client_TourneyLobby_View = "PR_Client_TourneyLobby_View"; // Done
export const PR_HomeLobby_View = "PR_HomeLobbyView"; // done
export const PR_Lobby_referNearn = "PR_Lobby_refer&earn"; // done
export const PR_Click_Maintile = "PR_Click_Maintile"; //done
export const PR_Click_Recomndtile = "PR_Click_Recomndtile"; //done
export const PR_Lobby_Banner = "PR_Lobby_Banner";
export const PR_Lobby_IPromotions = "PR_Lobby_IPromotions"; // done

// Game Table Events
export const IP_Client_GameTable_View = "PR_Client_GameTable_View"; //Done
export const IP_Client_GameTable_Buyin_Click =
  "PR_Client_GameTable_Buyin_Click"; //Done
export const IP_Client_GameTable_AddTable_Click =
  "PR_Client_GameTable_AddTable_Click"; //Done
export const IP_Client_LeaveSeat_Click = "PR_Client_LeaveSeat_Click"; //Done
export const IP_Client_ExitTable_Click = "PR_Client_ExitTable_Click"; //Done
export const IP_Client_BannerClick = "PR_Client_BannerClick"; //Waiting for home page
export const IP_Client_LBicon_Click = "PR_Client_LBicon_Click"; //Done
export const PR_Client_TakeSeat_Click = "PR_Client_TakeSeat_Click";
export const PR_Client_Autotopup_enable = "PR_Client_Autotopup_enable";
export const PR_Client_Autotopup_disable = "PR_Client_Autotopup_disable";

// Old Events
export const IP_Client_Poker_launch = "PR_Client_Poker_launch"; //Done
export const IP_TourneyTab_Click = "PR_TourneyTab_Click"; //Done
export const IP_Client_Play_now = "PR_Client_Play_now"; //Done
export const IP_Client_Join = "PR_Client_Join"; //done
export const IP_Client_Game_chat_Click = "PR_Client_Game_chat_Click"; //NA
export const IP_Client_Topup = "PR_Client_Topup"; //Done
export const Poker_Client_Tourney_regclick = "Poker_Client_Tourney_regclick"; //Done
export const IP_Client_Tourney_unregister_Click =
  "PR_Client_Tourney_unregister_Click"; // Done
// export const IP_IPomotiontile_Click = "IP_IPomotiontile_Click"; // not needed
export const PR_learnpoker = "PR_learnpoker";
export const PR_ContactUs_view = "PR_ContactUs_view";
export const PR_Hamburger_view = "PR_Hamburger_view";

export const Poker_Redirection_Fail = "Poker_Redirection_Fail"; //done

// Betting Options
export const PR_Click_Betting_Options = "PR_Click_Betting_Options"
export const PR_Update_Bet_Presets = "PR_Update_Bet_Presets"

