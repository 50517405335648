import React from "react";
import { useDispatch } from "react-redux";
import restrictedIcon from "../../../../assets/lobby/restricted_location.svg";
import { closeModal } from "../../../../Poker/redux/slices/modalSlice";
import colors from "../../../styleGuide/Colors";
import { PrimaryCTAButton } from "../ConfirmationPopupUI/confirmationPopupStyles";
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
} from "../modalStyle";

const RestrictedLocation = () => {
  const dispatch = useDispatch();
  return (
    <ModalWrapper
      style={{
        background: colors.popupBgColor,
        border: `1px solid ${colors.primaryBlue}`,
        color: "white",
        fontFamily: "conto",
        textAlign: "center",
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <img src={restrictedIcon}></img>
      <ModalBody>
        Poker isn't accessible from your location. Check out fun games!
      </ModalBody>
      <PrimaryCTAButton
        onClick={() => dispatch(closeModal())}
        style={{ color: "white", fontFamily: "conto" }}
      >
        Okay
      </PrimaryCTAButton>
    </ModalWrapper>
  );
};

export default RestrictedLocation;
