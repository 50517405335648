import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConsolidatedMenu from "../../../Poker/components/ConsolidatedMenu/ConsolidatedMenu";
import { HandHistory } from "../../../Poker/components/HandHistory/HandHistory";
import TableStatistics from "../../../Poker/components/TableStatistics/TableStatistics";
import TourneyRankingLeaderboard from "../../../Poker/components/TourneyRankingLeaderboard/TourneyRankingLeaderboard";
import {
  CONSOLIDATED_MENU,
  GAME_INFO,
  HAND_HISTORY,
  TABLE_SETTINGS,
  TABLE_STATS,
  THEMES,
  TOURNEY_RANKING_LEADERBOARD,
} from "../../../Poker/data/Constants";
import { closeSlider } from "../../../Poker/redux/slices/sliderSlice";
import { GlobalSlider, DarkScreen, RelativeWrapper } from "./SliderStyle";
import TableSettings from "./TableSettings/TableSettings";
import TableThemeSelect from "./TableThemeSelect/TableThemeSelect";
import { submitUpdatedTableSettingsConfig } from "../../../Poker/redux/slices/gameTableSlice";
import GameConnectionsN from "../../../Poker/io/GameConnectionsN";
import CleverTapUtil from "../../utils/CleverTapUtil";
import {
  PR_Client_Autotopup_disable,
  PR_Client_Autotopup_enable,
  PR_Update_Bet_Presets,
} from "../../../Poker/data/ClevertapConstants";
import { getPlayerObject } from "../../../Poker/utils/ReduxUtils";
import { getISTDateTimeFormat } from "../../utils/GameUtils";
import GameInfoSlider from "../../../Poker/components/GameInfo/GameInfoSlider";

const Slider = () => {
  const dispatch = useDispatch();
  const sliderStatus = useSelector((state) => state.slider.visible);
  const sliderAnimating = useSelector((state) => state.slider.animating);
  const sliderType = useSelector((state) => state.slider.type);
  const config = useSelector((state) => state.slider.config);
  const [sliderDirection, setSliderDirection] = useState("open");
  const {
    bettingOptions: {
      preflopPresetValues,
      postflopPresetValues,
      defaultPreflopPresetValue,
      defaultPostflopPresetValue,
    },
  } = useSelector((state) => state.gameTable.tableSettings);

  const channel = useSelector((state) => state.lobby.channel);
  const userBalance = useSelector((state) => state.lobby.balance);

  const isAutoTopUpEnabled = useSelector(
    (state) => state.gameTable.tableSettings.autoTopupConfig.enabled
  );

  const closeSlider = () => {
    setSliderDirection("close");
  };

  const createSlider = () => {
    switch (sliderType) {
      case THEMES:
        return (
          <TableThemeSelect
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case TABLE_SETTINGS:
        return (
          <TableSettings
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case HAND_HISTORY:
        return (
          <HandHistory
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case TABLE_STATS:
        return (
          <TableStatistics
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case GAME_INFO:
        return (
          <GameInfoSlider
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case CONSOLIDATED_MENU:
        return (
          <ConsolidatedMenu
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
          />
        );
      case TOURNEY_RANKING_LEADERBOARD:
        return (
          <TourneyRankingLeaderboard
            sliderDirection={sliderDirection}
            closeSliderProp={closeSlider}
            config={config}
          />
        );
      default:
        return;
    }
  };

  const handleTouchStart = () => {
    if (sliderAnimating) return;

    if (sliderType === TABLE_SETTINGS) {
      CleverTapUtil.getInstance().logEvent(
        isAutoTopUpEnabled
          ? PR_Client_Autotopup_enable
          : PR_Client_Autotopup_disable,
        {
          Username: getPlayerObject().userName,
          Channel: channel,
          "Wallet balance": userBalance?.totalBalance,
          Timestamp: getISTDateTimeFormat(),
        }
      );

      CleverTapUtil.getInstance().logEvent(PR_Update_Bet_Presets, {
        Username: getPlayerObject().userName,
        Channel: channel,
        Timestamp: getISTDateTimeFormat(),
        PreflopPresetValues: Object.keys(preflopPresetValues).map((index) => preflopPresetValues[index]).join(', '),
        PreflopDefaultRaise: defaultPreflopPresetValue,
        PostflopPresetValues: Object.keys(postflopPresetValues).map((index) => postflopPresetValues[index]).join(', '),
        PostflopDefaultRaise: defaultPostflopPresetValue,
      });

      dispatch(submitUpdatedTableSettingsConfig());
    }
    setSliderDirection("close");
  };

  return (
    <>
      <GlobalSlider id="global-slider">
        <RelativeWrapper id="relative-wrapper">
          <DarkScreen
            sliderType={sliderType}
            id="darkscreen"
            onClick={handleTouchStart}
          />
          {createSlider()}
        </RelativeWrapper>
      </GlobalSlider>
    </>
  );
};

export default Slider;
