import Handler from "../Handler";

class WaitListBroadcastHandler extends Handler {
  execute(message) {
    // WaitB#
    // {playerName:”abc”,tableId:”fvd”,status:100,totWaitListCount:1}

    let receivedData = JSON.parse(message);
    this.service.processAddToWaitListBroadCast(receivedData);
  }
}

export default WaitListBroadcastHandler;