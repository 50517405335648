import Handler from "../Handler";

class UpdateDepositorTourneyHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processUpdateDepositorTourneyDetails(receivedData);
  }
}
export default UpdateDepositorTourneyHandler;

// updateDepositorTourney#{
// tourneyId: "abcd" ,
// addCashTicketRegistration: true
// }
