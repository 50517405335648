import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { parseDecimalValuesOfNumber } from "../../../Common/utils/GameUtils";
import {
  ListViewcontainer,
  GameTypeIconElement,
  AnnouncementInfoWrapper,
  AnnouncementInfoContainer,
  AnnouncementIconWrapper,
  AnnouncementTitleText,
  AnnouncementDescriptionText,
  GameTileSectionContainer,
  GameTileValueContainer,
  GameTileValue,
  GameTileName,
  GameTileSectionImage,
  GreenCircle,
  GameTileSectionDivider,
  GameTileTopRow,
  GameTileBottomRow,
  GameType,
  RupeePrefix,
  GameTileSectionDividerContainer,
  GameTileTopRowContent,
  GameTileBottomRowContent,
  GameTileBottomRightSection,
  GameTileBottomLeftText,
  GameTileBottomContent,
  IconsWrapper,
  TopRowIcon,
  TopRowIconWrapper,
  HappyHoursContainer,
  GreenDot,
  HappyHoursGlassImage,
} from "./styledComponents";

import incognitoIcon from "../../../assets/lobby/incognito.png";
import leaderboardSmallIcon from "../../../assets/lobby/LeaderboardSmallIcon.png";
import sLetterIcon from "../../../assets/lobby/straddle.png";
import turboIcon from "../../../assets/lobby/turbo.png";
import ritIcon from "../../../assets/lobby/RIT.png";
import multiPlayersIcon from "../../../assets/lobby/MultiplePlayersIcon.svg";
import happyHoursGlass from "../../../assets/lobby/happy-hours-glass.png";
import lobbyChip from "../../../assets/lobby/lobby-chip.png";
import lobbyStack from "../../../assets/lobby/lobby-stack.png";
import rakebackIcon from "../../../assets/lobby/rakebackIcon.png";
import tilearrow from "../../../assets/lobby/tile-arrow.svg";
import BaseGameTileCard from "../BaseGameTileCard/BaseGameTileCard";
import { LB_REAL, OMAHA, OMAHA5, TEXAS_HOLDEM } from "../../data/Constants";

const LobbyGameTile = ({
  gameDefId,
  applicableLeaderboards,
  handleGameTileClick,
  rakebackDefinitions,
  tab,
}) => {
  const gameDefinition = useSelector(
    (state) => state.lobby.masterGameDefinitions[gameDefId]
  );
  const {
    anonymous,
    smallBlind,
    bigBlind,
    maxPlayers,
    gameType,
    minBuyInAmount,
    onlineCount,
    flavourType,
    isStraddleEnabled,
    isRITEnabled,
    turbo,
    status,
    happyHoursEnabled,
    happyHoursEndTime,
    happyHoursStartTime,
  } = gameDefinition;
  const currentTimeStamp = useSelector((store) => store.lobby.currentTimeStamp);
  const rakeback = isRakebackGame(gameDefinition);
  const leaderboard = isLeaderboardPresent(gameDefId, flavourType);

  useEffect(() => {
    console.log("Mounting Game Tile!");
  }, []);

  const GameTypeText = ({ gameType }) => {
    return (
      <span>
        {gameType === "OMAHA5" ? "PLO5" : gameType === "OMAHA" ? "PLO4" : "NLH"}
      </span>
    );
  };

  function isLeaderboardPresent(gameDefId, flavourType) {
    if (flavourType === "real") {
      for (let i = 0; i < applicableLeaderboards.real.length; i++) {
        let current = applicableLeaderboards.real[i];
        for (let j = 0; j < current.gameDefIds.length; j++) {
          let currentDefId = Number(current.gameDefIds[j].split("_")[2]);
          if (
            currentDefId === gameDefId &&
            currentTimeStamp >= current.startTime &&
            currentTimeStamp <= current.endTime
          ) {
            return true;
          }
        }
      }
    } else if (flavourType === "play") {
      for (let i = 0; i < applicableLeaderboards.play.length; i++) {
        let current = applicableLeaderboards.play[i];
        for (let j = 0; j < current.gameDefIds.length; j++) {
          let currentDefId = Number(current.gameDefIds[j].split("_")[2]);
          if (
            currentDefId === gameDefId &&
            currentTimeStamp >= current.startTime &&
            currentTimeStamp <= current.endTime
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function isRakebackGame(gameDefinition) {
    let { gameDefId } = gameDefinition;
    if (gameDefinition.gameType === TEXAS_HOLDEM) {
      return rakebackDefinitions.texasHoldem.includes(gameDefId);
    } else if (gameDefinition.gameType === OMAHA) {
      return rakebackDefinitions.omaha.includes(gameDefId);
    } else if (gameDefinition.gameType === OMAHA5) {
      return rakebackDefinitions.omaha5.includes(gameDefId);
    }
    return false;
  }

  return status?.toLowerCase() === "active" ? (
    <BaseGameTileCard
      key={`tab-${tab}-gdid-${gameDefId}-gameTileCard`}
      gameDefId={gameDefId}
      flavourType={flavourType}
      maxPlayers={maxPlayers}
      gameType={gameType}
      smallBlind={smallBlind}
      bigBlind={bigBlind}
    >
      <GameTileTopRow onClick={() => handleGameTileClick(gameDefinition)}>
        <GameTileTopRowContent>
          <GameTileSectionContainer left={true}>
            <GameTileValueContainer>
              <GameTileSectionImage src={lobbyChip} />
              <GameTileValue>
                {gameDefinition.flavourType === LB_REAL ? (
                  <>
                    {/* <RupeePrefix>₹</RupeePrefix> */}
                    {parseDecimalValuesOfNumber(smallBlind, 1, true)}
                    {/* &nbsp; */}/{/* &nbsp; */}
                    {/* <RupeePrefix>₹</RupeePrefix> */}
                    {parseDecimalValuesOfNumber(bigBlind, 1, true)}
                  </>
                ) : (
                  <>
                    {parseDecimalValuesOfNumber(smallBlind, 1, true)}/
                    {parseDecimalValuesOfNumber(bigBlind, 1, true)}
                  </>
                )}
              </GameTileValue>
            </GameTileValueContainer>
            <GameTileValueContainer>
              <GameTileName>Blinds</GameTileName>
            </GameTileValueContainer>
          </GameTileSectionContainer>
          <GameTileSectionDividerContainer>
            <GameTileSectionDivider
              color={"rgba(9, 81, 99, 1)"}
            ></GameTileSectionDivider>
            <GameTileSectionDivider
              color={"rgba(7, 51, 62, 1)"}
            ></GameTileSectionDivider>
          </GameTileSectionDividerContainer>
          <GameTileSectionContainer>
            <GameTileValueContainer>
              <GameTileSectionImage src={lobbyStack} />
              <GameTileValue>
                {gameDefinition.flavourType === LB_REAL ? (
                  <>
                    <RupeePrefix>₹</RupeePrefix>
                    {parseDecimalValuesOfNumber(minBuyInAmount, 2, true)}
                  </>
                ) : (
                  parseDecimalValuesOfNumber(minBuyInAmount, 2, true)
                )}
              </GameTileValue>
            </GameTileValueContainer>
            <GameTileValueContainer>
              <GameTileName>Min Buy-In</GameTileName>
            </GameTileValueContainer>
          </GameTileSectionContainer>
          {(isRITEnabled || turbo || isStraddleEnabled || anonymous) && (
            <GameTileSectionDividerContainer>
              <GameTileSectionDivider
                color={"rgba(9, 81, 99, 1)"}
              ></GameTileSectionDivider>
              <GameTileSectionDivider
                color={"rgba(7, 51, 62, 1)"}
              ></GameTileSectionDivider>
            </GameTileSectionDividerContainer>
          )}
          <GameTileSectionContainer
            // style={{minWidth: '40%'}}
            right={false}
            style={{ gap: "6px" }}
          >
            <TopRowIconWrapper>
              {isRITEnabled && <TopRowIcon src={ritIcon} />}
              {turbo && <TopRowIcon src={turboIcon} />}
              {isStraddleEnabled && <TopRowIcon src={sLetterIcon} />}
              {anonymous && <TopRowIcon src={incognitoIcon} />}
            </TopRowIconWrapper>
            {currentTimeStamp >= happyHoursStartTime &&
            currentTimeStamp < happyHoursEndTime &&
            happyHoursEnabled ? (
              <TopRowIconWrapper>
                <HappyHoursContainer>
                  <GreenDot />
                  Happy Hour
                  <HappyHoursGlassImage src={happyHoursGlass} />
                </HappyHoursContainer>
              </TopRowIconWrapper>
            ) : null}
          </GameTileSectionContainer>
          <GameTileSectionContainer minWidth={true} right={true}>
            <GameTypeIconElement src={tilearrow} />
          </GameTileSectionContainer>
        </GameTileTopRowContent>
      </GameTileTopRow>
      {/* 
        Tooltip for lb icon on tile
        {isLeaderboardPresent(gameDefinition.gameDefId, gameDefinition.flavourType) && <LbTooltip gameDefId = {selectedGameDef} gameId = {gameDefinition.gameDefId} setSelectedGameDef={setSelectedGameDef} /> } */}
      <GameTileBottomRow>
        <GameTileBottomRowContent>
          <GameTileBottomContent>
            <GreenCircle></GreenCircle>
            <GameTileBottomLeftText>
              {onlineCount} Playing
            </GameTileBottomLeftText>
          </GameTileBottomContent>
          <GameTileBottomContent>
            <GameTypeIconElement src={multiPlayersIcon} />
            <GameTileBottomLeftText>{maxPlayers}</GameTileBottomLeftText>
          </GameTileBottomContent>
          {/* Icons for Leaderboard / Rakeback */}
          <IconsWrapper>
            {leaderboard && <GameTypeIconElement src={leaderboardSmallIcon} />}
            {rakeback && <GameTypeIconElement src={rakebackIcon} />}
          </IconsWrapper>
          <GameType gameType={gameType}>
            <GameTypeText gameType={gameType} />
          </GameType>
        </GameTileBottomRowContent>
      </GameTileBottomRow>
    </BaseGameTileCard>
  ) : null;
};

export default LobbyGameTile;
