import Handler from "../Handler";

class SitInHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processSitIn(receivedData);
  }
}

export default SitInHandler;
