import {
  LEAVE_SEAT_ACK_FAILURE,
  LEAVE_SEAT_ACK_PROCESSING,
  LEAVE_SEAT_ACK_REQUEST_ALREADY_SENT,
  LEAVE_SEAT_ACK_SUCCESS,
} from "../../data/Constants";
import Handler from "../Handler";

class LeaveSeatAckHandler extends Handler {
  execute(message) {
    //   leaveA#{
    //     "playerName": "amber633333",
    //     "tableId": "THR271223-0625139aqt5g",
    //     "status": 100 || 104 || 103,
    //     "isSitOutExpire": false
    // }

    // 100 -- leave seat req processed
    // 104 -- leave seat req sent and will be processed at game end
    // 103 -- leave seat req has already been sent and will be processed at game end 

    let receivedData = JSON.parse(message);
    const { playerName, tableId, isSitOutExpire, status } = receivedData;
    // let ackStatus = status
      // status === 100
      //   ? LEAVE_SEAT_ACK_SUCCESS
      //   : status === 104
      //   ? LEAVE_SEAT_ACK_PROCESSING
      //   : status === 103
      //   ? LEAVE_SEAT_ACK_REQUEST_ALREADY_SENT
      //   : status === false
      //   ? LEAVE_SEAT_ACK_FAILURE
      //   : LEAVE_SEAT_ACK_SUCCESS;
    if (isSitOutExpire) {
      this.service.leaveTableOnSitOutTimerEnd({
        ackStatus: status,
        playerName: playerName,
        tableId: tableId,
        isSitOutExpire: isSitOutExpire,
      });
    } else {
      this.service.leaveTableAck({
        ackStatus: status,
        playerName: playerName,
        tableId: tableId,
        isSitOutExpire: isSitOutExpire,
      });
    }
  }
}

export default LeaveSeatAckHandler;
