import React, { useState } from "react";
import { CloseButtonWrapper, CloseIcon } from "./closeButtonStyles";
import close from "../../../../assets/common/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { BUY_IN, REBUY_IN } from "../../../../Poker/data/Constants";
import { closeLocalGameModal } from "../../../../Poker/redux/slices/gameTableSlice";
import { Loader } from "../../../../Poker/components/Loader/Loader";

export const CloseButton = ({ top, right, onCloseHandler, tempTableId }) => {
  const modalType = useSelector((state) => state.gameTable.games[tempTableId].gameModal.type);
  const [closingStatus, setClosingStatus] = useState(false);

  const dispatch = useDispatch();

  const handleCloseButton = () => {
    setClosingStatus(true);
    if (modalType === BUY_IN || modalType === REBUY_IN) {
      onCloseHandler && onCloseHandler();
    } else {
      dispatch(closeLocalGameModal({ tempTableId: tempTableId}));
    }
  };

  return (
    <CloseButtonWrapper
      className={"close-icon"}
      onClick={handleCloseButton}
      top={top}
      right={right}
    >
      {
        closingStatus ?
        <Loader size={20}/>
        :
        <CloseIcon src={close}></CloseIcon>
      }
    </CloseButtonWrapper>
  );
};
