import Handler from "../Handler";

class DRebuyInHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processDRebuyIn(receivedData);
  }
}

export default DRebuyInHandler;

//DRebuyInA#{"tableId":"THR260923-20504247nl7g","gameId":"260923-2139280r0e2h","playerName":"testuser","position":0}
