import Handler from "../Handler";

class PlayerConfigHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processPlayerConfig(receivedData);
  }
}
export default PlayerConfigHandler;

//PlayerConfig#{"playerName":"guru","playChips":10000.0,"playerBasicSettings":{"handStrength":false,"autoTableSwitching":false,"displayChipsInBB":false,"autoMuck":false},"playerHaptics":{"vibrations":false,"sound":false,"smartGestures":false},"playerAutoRebuyConfig":{"autoRebuy":false,"playerAutoTopUpConfig":{"enabled":false,"fallsBelowAmount":0.0,"topUpAmount":0.0}}}
