//TourneyNewChildTourneyHandler

import Handler from "../Handler";

class TourneyNewChildTourneyHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processNewChildTourneyData(receivedData);
  }
}
export default TourneyNewChildTourneyHandler;

// newChildTourney#{
//     "tourneyId": "GG3C9",
//     "childTourneys": [
//         "fYTT0",
//         "aSAZS"
//     ]
// }
