import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import winnerTrophyLottie from "./winner-trophy.json";

import trophyIcon from "../../../assets/gameTable/TourneyTrophy.svg";

import {
  closeLocalGameModal,
  deleteGameTable,
  hideGameTable,
  resetTableData,
} from "../../redux/slices/gameTableSlice";
import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  IconWrapper,
  MessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  LottieWrapper,
  NoteText,
  MessageWrapper,
  MessageHighLightText,
  SecondaryCTAButton,
} from "./tourneyWinnerModalStyles";
import {
  closeTourneyInfoPage,
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  requestTourneyTablesDynamicData,
} from "../../redux/slices/lobbySlice";
import TableUtils from "../../utils/TableUtils";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import { TOURNEY_INFO_TABLES_TAB } from "../../data/Constants";

function TourneyWinnerModal(props) {
  const dispatch = useDispatch();

  const tourneyPrizeDetails = useSelector(
    (state) => state.gameTable.games[props.tempTableId].tourneyPrizeDetails
  );
  const tourneyGameCompleted = useSelector(
    (state) => state.gameTable.games[props.tempTableId].tourneyGameCompleted
  );
  // const playerName = useSelector((state) => state.lobby.player.userName);
  const tourneyId = useSelector(
    (state) => state.gameTable.games[props.tempTableId].gameDefinition.tourneyId
  );

  const upcomingTourneys = useSelector(
    (state) => state.lobby.tourneys.upcomingTourneys
  );
  const completedTourneys = useSelector(
    (state) => state.lobby.tourneys.completedTourneys
  );
  const myTourneys = useSelector((state) => state.lobby.tourneys.myTourneys);

  const lottieAnimationRef = useRef(null);

  // const handleModalClose = () => {
  //   TableUtils.getInstance().overrideRemovedTableObserver(props.tempTableId);
  //   dispatch(
  //     resetTableData({ tempTableId: props.tempTableId, isTourneyGame: true })
  //   );
  //   sendFaroLog({
  //     type: "Initiate Watch player channel connection at tourney winner",
  //     tempTableId: props?.tempTableId,
  //   });
  //   dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  // };

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const masterTourneysDefinitions = [
      ...myTourneys,
      ...upcomingTourneys,
      ...completedTourneys,
    ];
    let tourney = masterTourneysDefinitions.find(
      (tourney) => tourney.tourneyId === tourneyId
    );
    return tourney ? tourney : false;
  };

  const handlePrimaryCTAClick = () => {
    // handleModalClose();
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
    dispatch(closeTourneyInfoPage());
    dispatch(hideGameTable());
    dispatch(deleteGameTable({ tempTableId: props.tempTableId }));
    sendFaroLog({
      type: "Exit Table from tourney winner popup",
      tempTableId: props?.tempTableId,
    });
    // dispatch(
    //   exitGameTable({ tempTableId: props.tempTableId, playerName: playerName })
    // );
  };

  const handleSpectateClick = () => {
    let gameDefinition = getTourneyDefinitionByTourneyId(tourneyId);
    if (gameDefinition) {
      dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
      TableUtils.getInstance().removeTableObserver(props.tempTableId, true);
      dispatch(hideGameTable());
      dispatch(
        requestTourneyInfoData({
          tourneyDetails: {
            ...gameDefinition,
            defaultSelectedTab: TOURNEY_INFO_TABLES_TAB,
          },
        })
      );
      dispatch(
        requestTourneyEntriesDynamicData({
          tourneyId: tourneyId,
        })
      );
      dispatch(requestTourneyTablesDynamicData({ tourneyId: tourneyId }));
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Congratulations</HeaderTitleText>
          {/* {!tourneyGameCompleted && (
            <CloseIcon src={closeIcon} onClick={handleModalClose} />
          )} */}
        </ModalHeader>

        <ModalBody>
          <IconWrapper src={trophyIcon} />
          <LottieWrapper
            style={{
              width: "150px",
              height: "150px",
            }}
            animationData={winnerTrophyLottie}
            ref={lottieAnimationRef.current}
            autoPlay={true}
            loop={true}
          />

          <MessageWrapper>
            <MessageText>You have finished the tournament in place</MessageText>
            <MessageHighLightText>
              {`#${tourneyPrizeDetails?.rank}`}
            </MessageHighLightText>
            <MessageText>with</MessageText>
            {tourneyPrizeDetails?.playerWinning > 0 && (
              <MessageHighLightText>
                ₹{tourneyPrizeDetails?.playerWinning}
              </MessageHighLightText>
            )}

            {Object.keys(tourneyPrizeDetails?.ticketWonDetails).length > 0 && (
              <>
                {tourneyPrizeDetails?.playerWinning > 0 && (
                  <MessageText>and</MessageText>
                )}
                <MessageHighLightText>Ticket</MessageHighLightText>
                <MessageText>to the</MessageText>
                <MessageHighLightText>
                  {tourneyPrizeDetails?.ticketWonDetails?.tourneyName}
                </MessageHighLightText>
                <MessageText>Tourney</MessageText>
              </>
            )}
          </MessageWrapper>

          <NoteText>
            Note: Winnings undergo fraud checks and may take up to 24 hours to
            credit.
          </NoteText>
          {tourneyGameCompleted ? (
            <CTAContainer>
              <PrimaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Exit Table</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          ) : (
            <CTAContainer>
              <SecondaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Exit Table</ButtonText>
              </SecondaryCTAButton>

              <PrimaryCTAButton onClick={handleSpectateClick}>
                <ButtonText>Spectate</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          )}
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default TourneyWinnerModal;
