import { DISCONNECTION } from "../data/Constants";
import { closeModal, openModal } from "../redux/slices/modalSlice";
import { ClientListener } from "./ClientListener";
import {
  engineVersion,
  mobileModel,
  mobileVendor,
  osVersion,
} from "react-device-detect";
import { getDeviceAndConnectionDetails } from "../utils/BrowserUtils";
import { closeTourneyInfoPageOnlyIfLoading } from "../redux/slices/lobbySlice";

class LobbyListener extends ClientListener {
  constructor(store, dispatchFunction, clientType) {
    super(store);

    this.clientType = clientType;
    this.store = store;
    this.dispatchFunction = dispatchFunction;
    this.isConnectionEstablishedOnce = false;
  }

  onClientConnect() {
    super.onClientConnect();

    this.isConnectionEstablishedOnce = true; //not actively used as of now @yashwanth
    this.dispatchFunction(closeModal()); //Closing disconnection modal in case of connection as well as reconnection

    const pokerStore = require("../redux/store");
    const store = pokerStore.default.getState().lobby;
    try {
      let payload = {
        a23Token: store.a23Token,
        channel: store.channel,
        playerId: store.player.userId,
        playerName: store.player.userName,
        playerType: store.player.subscriptionType,
        profilePic: store.player.avatar,
        authToken: store.accessToken,
        deviceId: store.player.deviceId,
        deviceType: store.player.deviceType,
        deviceModel: store.player.deviceModel,
        deviceOS: store.player.deviceOS,
        IP: store.player.IP,
        clientVersion: process.env.REACT_APP_CLIENT_VERSION,
        aceLevel: store.player.level,
        playerA23Status: store.player.playerStatus,
      };
      payload = { ...payload, ...getDeviceAndConnectionDetails() };

      let IPManConnectProtocol = "Con#" + JSON.stringify(payload);
      this.getCommunicationChannel().sendMessage(IPManConnectProtocol);
    } catch (error) {
      throw new Error("Error at onClientConnect Lobby Server: ", error);
    }
  }

  onClientDisconnect() {
    super.onClientDisconnect();

    this.dispatchFunction(openModal({ type: DISCONNECTION }));
    this.dispatchFunction(closeTourneyInfoPageOnlyIfLoading());
    return true;
  }

  onError(event) {
    super.onError(event);

    //if needed, we can show some specific modal instead of plain disconnection
    this.dispatchFunction(openModal({ type: DISCONNECTION }));
    this.dispatchFunction(closeTourneyInfoPageOnlyIfLoading());
  }

  onClientReconnect() {
    super.onClientReconnect();

    this.dispatchFunction(closeModal());

    const pokerStore = require("../redux/store");
    const store = pokerStore.default.getState().lobby;
    try {
      let payload = {
        a23Token: store.a23Token,
        channel: store.channel,
        userId: store.player.userId,
        playerName: store.player.userName,
        userType: store.player.subscriptionType,
        profilePic: store.player.avatar,
        authToken: store.accessToken,
        deviceId: store.player.deviceId,
        deviceType: store.player.deviceType,
        deviceModel: store.player.deviceModel,
        deviceOS: store.player.deviceOS,
        IP: store.player.IP,
        aceLevel: store.player.level,
        playerA23Status: store.player.playerStatus,
        clientVersion: process.env.REACT_APP_CLIENT_VERSION,
      };
      payload = { ...payload, ...getDeviceAndConnectionDetails() };

      let IPManReconnectProtocol = "RCon#" + JSON.stringify(payload);
      this.getCommunicationChannel().sendMessage(IPManReconnectProtocol);
    } catch (error) {
      throw new Error("Error at onClientConnect Lobby Server: ", error);
    }
  }
}

export default LobbyListener;
