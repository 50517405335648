import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const ButtonWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  position: ${(props) => (props.position ? props.position : "absolute")};
  top: ${(props) => (props.top ? props.top + "rem" : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom + "rem" : "unset")};
  left: ${(props) => (props.left ? props.left + "rem" : "unset")};
  right: ${(props) => (props.right ? props.right + "rem" : "unset")};
  border: 1px solid ${colors.deepTeal60};
  border-radius: 5px;
`;

export const Button = styled.button`
  width: 2rem;
  height: 2rem;
  background: ${colors.primaryDeepBlue};
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 6px;
  position: relative;
  transform: scale(1);
  &:active {
    background: ${(props) =>
      props.backgroundColor ? colors.hoverDarkGrey : colors.hoverLightGrey};
    transform: scale(0.96);
    transition: transform 0.2s ease;
  }
  &:disabled {
    background: linear-gradient(0deg, rgb(0 0 0 / 28%), rgb(0 0 0 / 41%)),
      rgb(79 81 83);
  }
`;

export const ButtonIcon = styled.img`
  width: 18px;
  height: 18px;
`;
