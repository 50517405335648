import { useEffect, useRef } from "react";
import store from "../redux/store";

export function getPlayerObject() {
  let lobbySlice = getLobbySlice();
  return lobbySlice.player;
}

export function getLobbySlice() {
  return getReduxState()["lobby"];
}

export function getGameSlice() {
  return getReduxState()["gameTable"];
}

export function getModalSlice() {
  //being used in lobby modals
  return getReduxState()["modal"];
}

function getReduxState() {
  return store.getState();
}

export function useTraceUpdate(props, component) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(`Changed props in component: ${component} -->`, changedProps);
    }
    prev.current = props;
  });
}
