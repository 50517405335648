import Handler from "../Handler";

class NodeMismatchHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processNodeMismatch(receivedData);
  }
}

export default NodeMismatchHandler;
