import Handler from "../Handler";

class TourneyInfoAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyInfoData(receivedData);
  }
}
export default TourneyInfoAckHandler;

// TourneyInfoA#{
//     "buiInAmount": 0,
//     "registeredPlayers": 0,
//     "maxPlayers": 10,
//     "winners": 0,
//     "totalAddOns": 0,
//     "addOnAmount": 0,
//     "totalReBuyIns": 0,
//     "reBuyInAmount": 0,
//     "startingStack": 0,
//     "playersPerTable": 0,
//     "tourneyRegisteredPlayersStatusList": [
//       {
//         "playerName": "IPMAN1",
//         "position": 5,
//         "prize": 3
//       },
//       {
//         "playerName": "IPMAN2",
//         "position": 123,
//         "prize": 32
//       }
//     ],
//     "tourneyTablesStatusList": [
//       {
//         "tableId": "demmoTableId11",
//         "players": 10,
//         "largest": 30,
//         "smallest": 19
//       },
//       {
//         "tableId": "demmoTableId22",
//         "players": 40,
//         "largest": 100,
//         "smallest": 89
//       }
//     ]
//   }
