import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import wifi from "./wifi.json"
import error from "./error.json"

export const DisconnectionLottie = ({size, margin, loop}) => {
  const lottieAnimationRef = useRef(null);
  const disconnectionTimeout = useRef(null);

  useEffect(() => {
    disconnectionTimeout.current = setTimeout(() => {
      lottieAnimationRef?.current?.play();
    }, 100);

    return () => clearTimeout(disconnectionTimeout.current);
  }, []);

  return (
      <Lottie
        style={{
          width: size ? size + 'px' : "100%",
          height: size ? size + 'px' : "100%",
          margin: margin ? margin + 'px 0px' : 0,
        }}
        animationData={error}
        ref={lottieAnimationRef.current}
        autoPlay={true}
        loop={loop ? loop : false}
      />
  );
};
