import React, { memo, useState } from "react";
import {
  CardThemeTypes,
  CardType,
  TableThemeWrapper,
  Theme,
  ThemeHeader,
  ThemeOptions,
  ThemeType,
  ThemeTypes,
} from "./TableThemeStyle";
// import gameTableOne from '../../../../assets/gameTable/gameTable.svg';
import * as gameAssets from "../../../../Poker/routes/Game/gameAssets";
import { cardThemes as newCards } from "../../../../assets/cards";

import { useDispatch, useSelector } from "react-redux";
import {
  setCardBack,
  setCardDeck,
  setCardSize,
  setTableBackground,
  setTableTheme,
} from "../../../../Poker/redux/slices/gameTableSlice";
import {
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_SMALL,
  OMAHA,
  OMAHA5,
  TEXAS_HOLDEM,
} from "../../../../Poker/data/Constants";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../../../Poker/redux/slices/sliderSlice";
import { cardThemes } from "../../../../assets/cards";

const TableThemeSelect = ({ sliderDirection }) => {
  const dispatch = useDispatch();
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  // const tableThemeData = useSelector(
  //   (state) => state.gameTable.games[state.gameTable.activeGame].themeData
  // );
  const tableThemeData = useSelector((state) => state.gameTable.themeData);
  const [activeThemeType, setActiveThemeType] = useState("Table");
  const [activeCardType, setActiveCardType] = useState("Card Back");

  // const cardSize = useSelector(
  //   (state) =>
  //     state.gameTable.games[state.gameTable.activeGame].themeData.cardSize
  // );
  const cardSize = useSelector((state) => state.gameTable.themeData.cardSize);

  const gameType = useSelector(
    (state) =>
      state.gameTable.games[state.gameTable.activeGame].gameDefinition.gameType
  );

  const handleThemeSelect = (type) => {
    // Active Theme Type is Table, Background, Card Back, Card Back
    // Type is the theme itself within the above
    switch (activeThemeType) {
      case "Table":
        localStorage.setItem("tableTheme", type.name);
        dispatch(setTableTheme(type.name));
        return;
      case "Background":
        localStorage.setItem("tableBackground", type.name);
        dispatch(setTableBackground(type.name));
        // setBgTheme(type.name);
        return;
      case "Card":
        switch (activeCardType) {
          case "Card Back":
            localStorage.setItem("cardBack", type.name);
            dispatch(setCardBack(type.name));
            return;
          case "Card Front":
            localStorage.setItem("cardDeck", type.name);
            dispatch(setCardDeck(type.name));
            return;
          case "Card Size":
            localStorage.setItem("cardSize", type.name);
            dispatch(setCardSize(type.name));
            return;
        }
    }
  };

  const renderThemeTypes = () => {
    // All theme types that can be picked
    // const themeTypes = ["Table", "Background", "Card Back", "Card Back"]; //temp spruha
    const themeTypes = ["Table", "Background", "Card"];
    return themeTypes.map((type, index) => {
      return (
        <ThemeType
          id='theme-type'
          key={`renderThemeTypes-function-${type}-` + index}
          active={activeThemeType === type}
          onClick={() => setActiveThemeType(type)}
        >
          <span>{type}</span>
        </ThemeType>
      );
    });
  };

  const renderCardThemeTypes = () => {
    // All theme types that can be picked
    // const themeTypes = ["Table", "Background", "Card Back", "Card Back"]; //temp spruha
    const cardTypes = ["Card Back", "Card Front", "Card Size"];
    return cardTypes.map((type, index) => {
      if ((gameType === OMAHA5 || gameType === OMAHA) && type === "Card Size")
        return;
      return (
        <CardType
          id='theme-type'
          key={`renderCardThemeTypes-function-${type}-` + index}
          active={activeCardType === type}
          onClick={() => setActiveCardType(type)}
        >
          <span>{type}</span>
        </CardType>
      );
    });
  };

  const renderThemes = () => {
    // Source of all theme files in arrays
    const themes = {
      Table: [
        {
          name: "Table1",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.gameTableOne
              : gameAssets.LandscapeTableOne,
        },
        {
          name: "Table2",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.gameTableTwo
              : gameAssets.LandscapeTableTwo,
        },
        {
          name: "Table3",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.gameTableThree
              : gameAssets.LandscapeTableThree,
        },
        {
          name: "Table4",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.gameTableFour
              : gameAssets.LandscapeTableFour,
        },
        {
          name: "Table5",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.gameTableFive
              : gameAssets.LandscapeTableFive,
        },
        // { name: 'Table6', src: gameOrientation === 'PORTRAIT' ? gameAssets.gameTableSix : gameAssets.LandscapeTableFour },
      ],
      Background: [
        {
          name: "BG1",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.BG1
              : gameAssets.LandscapeBG1,
        },
        {
          name: "BG2",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.BG2
              : gameAssets.LandscapeBG2,
        },
        {
          name: "BG3",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.BG3
              : gameAssets.LandscapeBG3,
        },
        {
          name: "BG4",
          src:
            gameOrientation === "PORTRAIT"
              ? gameAssets.BG4
              : gameAssets.LandscapeBG4,
        },
      ],
      Card:
        activeCardType === "Card Back"
          ? [
              { name: "cardBack", src: gameAssets.mainCardBack },
              { name: "CB1", src: gameAssets.cardBack1 },
              { name: "CB2", src: gameAssets.cardBack2 },
              { name: "CB3", src: gameAssets.cardBack3 },
              { name: "CB4", src: gameAssets.cardBack4 },
            ]
          : activeCardType === "Card Front"
          ? [
              { name: "Default", src: cardThemes.Default.d1 },
              { name: "Gold", src: cardThemes.Gold.d1 },
              { name: "Colored", src: cardThemes.Colored.d1 },
              { name: "Multicolor", src: cardThemes.Multicolor.d1 },
              // { name: "Deck4", src: cardThemes.Deck4.s1 },
            ]
          : activeCardType === "Card Size"
          ? [
              {
                name: CARD_SIZE_SMALL,
                src: newCards[tableThemeData.cardDeck].s1,
              },
              {
                name: CARD_SIZE_MEDIUM,
                src: newCards[tableThemeData.cardDeck].s1,
              },
              {
                name: CARD_SIZE_LARGE,
                src: newCards[tableThemeData.cardDeck].s1,
              },
            ]
          : [],
    };

    // const ImageComponent = memo(({ source, active, name }) => {
    //   return (
    //     <img
    //       style={{
    //         transition: "0.5s ease",
    //         transform:
    //           active && activeCardType !== "Card Size" ? "scale(1.1)" : null,
    //         filter: active ? "brightness(1)" : "brightness(0.6)",
    //         height:
    //           activeThemeType === "Card"
    //             ? activeCardType === "Card Size"
    //               ? name === "Small"
    //                 ? "30%"
    //                 : name === "Medium"
    //                 ? "40%"
    //                 : "50%"
    //               : "50%"
    //             : "70%",
    //         // position: 'relative',
    //         // bottom: activeThemeType === 'Card' && activeCardType === 'Card Size' && '25%'
    //         // width: "80%",
    //         // maxWidth: gameOrientation === "PORTRAIT" ? "80px" : "null",
    //       }}
    //       src={source}
    //     ></img>
    //   );
    // });

    const activeThemeChecker = (name) => {
      if (activeThemeType === "Table" && tableThemeData.table === name) {
        return true;
      }
      if (
        activeThemeType === "Background" &&
        tableThemeData.background === name
      ) {
        return true;
      }
      if (activeThemeType === "Card") {
        if (activeCardType === "Card Back") {
          if (tableThemeData.cardBack === name) {
            return true;
          }
        }
        if (activeCardType === "Card Front") {
          if (tableThemeData.cardDeck === name) {
            return true;
          }
        }
        if (activeCardType === "Card Size") {
          if (name === cardSize) {
            return true;
          }
        }
      }
      return false;
    };

    return themes[activeThemeType].map((type, index) => {
      return (
        <Theme
          disabled={
            gameType !== TEXAS_HOLDEM &&
            (type.name == CARD_SIZE_MEDIUM || type.name == CARD_SIZE_LARGE)
          }
          id='theme'
          key={`rendering-theme-component-${type}-` + index}
          onClick={() => handleThemeSelect(type)}
          active={activeThemeChecker(type.name)}
          type={activeThemeType}
          activeCardType={activeCardType}
        >
          <img
            style={{
              transition: "0.5s ease",
              objectFit: "cover",
              transform:
                activeThemeChecker(type.name) && activeCardType !== "Card Size"
                  ? "scale(1.1)"
                  : null,
              filter: activeThemeChecker(type.name)
                ? "brightness(1)"
                : "brightness(0.6)",
              height:
                activeThemeType === "Card"
                  ? activeCardType === "Card Size"
                    ? type.name === "Small"
                      ? "30%"
                      : type.name === "Medium"
                      ? "40%"
                      : "50%"
                    : "50%"
                  : (activeThemeType === "Table" ||
                      activeThemeType === "Background") &&
                    gameOrientation === "LANDSCAPE"
                  ? "30%"
                  : "70%",
            }}
            src={type.src}
          ></img>
        </Theme>
      );
    });
  };

  return (
    <TableThemeWrapper
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
      orientation={gameOrientation}
      id='theme-wrapper'
    >
      <ThemeHeader>
        <img src={gameAssets.themes} style={{ marginRight: "10px" }}></img>
        Table Themes
      </ThemeHeader>
      <ThemeTypes id='theme-types'>{renderThemeTypes()}</ThemeTypes>
      {activeThemeType === "Card" ? (
        <CardThemeTypes id='card-theme-types'>
          {renderCardThemeTypes()}
        </CardThemeTypes>
      ) : null}
      <ThemeOptions id='theme-options'>{renderThemes()}</ThemeOptions>
    </TableThemeWrapper>
  );
};

export default TableThemeSelect;
