import React, { useCallback, useEffect, useRef, useState } from "react";
import incognitoIcon from "../../../assets/lobby/IncognitoIcon.svg";
import leaderboardSmallIcon from "../../../assets/lobby/LeaderboardSmallIcon.svg";
import sLetterIcon from "../../../assets/lobby/SIcon.svg";
import turboIcon from "../../../assets/lobby/TurboIcon.svg";
import ritIcon from "../../../assets/lobby/RITIcon.svg";
import multiPlayersIcon from "../../../assets/lobby/MultiplePlayersIcon.svg";
import emptyIcon from "../../../assets/lobby/empty-box.png";
import lobbyChip from "../../../assets/lobby/lobby-chip.png";
import lobbyStack from "../../../assets/lobby/lobby-stack.png";
import rakebackIcon from "../../../assets/lobby/rakeback.svg";
import tilearrow from "../../../assets/lobby/tile-arrow.svg";
import BaseGameTileCard from "../BaseGameTileCard/BaseGameTileCard";

import {
  ListViewcontainer,
  GameTypeIconElement,
  AnnouncementInfoWrapper,
  AnnouncementInfoContainer,
  AnnouncementIconWrapper,
  AnnouncementTitleText,
  AnnouncementDescriptionText,
  GameTileSectionContainer,
  GameTileValueContainer,
  GameTileValue,
  GameTileName,
  GameTileSectionImage,
  GreenCircle,
  GameTileSectionDivider,
  GameTileTopRow,
  GameTileBottomRow,
  GameType,
  GameIconsWrapper,
  GameTileBottomLeftSection,
  RupeePrefix,
  GameTileSectionDividerContainer,
  GameTileTopRowContent,
  GameTileBottomRowContent,
  GameTileBottomRightSection,
  GameTileBottomLeftText,
  GameTileBottomContent,
  IconsWrapper,
  TopRowIcon,
  TopRowIconWrapper,
} from "./styledComponents";
// import colors from "../../../Common/styleGuide/Colors";
import { useSelector } from "react-redux";
import {
  ALL,
  LB_REAL,
  OMAHA,
  OMAHA5,
  TEXAS_HOLDEM,
} from "../../data/Constants";
import { parseDecimalValuesOfNumber } from "../../../Common/utils/GameUtils";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import LobbyGameTile from "./LobbyGameTile";
import { useTraceUpdate } from "../../utils/ReduxUtils";
import ScrollUtils from "../../utils/ScrollUtils";

function LobbyGameTilesList(props) {
  const {
    gameTileClickHandler,
    tab,
    isCashGameToggled,
    currentScrollTopValue,
  } = props;
  const activeGamesExist = useSelector(
    (state) => state.lobby?.activeGamesExist
  );
  const scrollRef = useRef(null);
  const playerDetails = useSelector((state) => state.lobby.player);

  const handleScroll = () => {
    if (scrollRef.current) {
      //not using scrollLeft for now
      const { scrollTop, scrollLeft } = scrollRef.current;
      ScrollUtils.getInstance().updateLobbyScrollPositions({
        type: "gameTileList",
        scrollTop: scrollTop,
      });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop =
        ScrollUtils.getInstance().lobbyComponentsScrollPositions.gameTilesListScrollTop;
    }
  }, []);

  const gameTilesList = useSelector((state) => {
    switch (tab) {
      case ALL:
        if (isCashGameToggled) {
          return state.lobby.realGames;
        } else {
          return state.lobby.funGames;
        }
      case TEXAS_HOLDEM:
        if (isCashGameToggled) {
          return state.lobby.realTexasHoldem;
        } else {
          return state.lobby.funTexasHoldem;
        }
      case OMAHA:
        if (isCashGameToggled) {
          return state.lobby.realOmaha;
        } else {
          return state.lobby.funOmaha;
        }
      case OMAHA5:
        if (isCashGameToggled) {
          return state.lobby.realOmaha5;
        } else {
          return state.lobby.funOmaha5;
        }
    }
  });
  // const [selectedGameDef, setSelectedGameDef] = useState(undefined);
  const applicableLeaderboards = useSelector(
    (store) => store.lobby.applicableLeaderboards
  );
  const rakebackDefinitions = useSelector(
    (store) => store.lobby.rakebackDefinitions
  );
  // const swiper = useSwiper();
  // const swiperSlide = useSwiperSlide();
  const currentTimeStamp = useSelector((store) => store.lobby.currentTimeStamp);
  const playerData = useSelector((state) => state.lobby.player);
  const channel = useSelector((state) => state.lobby.channel);
  const userBalance = useSelector((state) => state.lobby.balance);

  // useTraceUpdate(props, 'LobbyGameTilesList');

  const renderEmptyAnnouncement = () => {
    return (
      <AnnouncementInfoWrapper>
        <AnnouncementInfoContainer>
          <AnnouncementIconWrapper src={emptyIcon} />
          {/* <AnnouncementTitleText>{``}</AnnouncementTitleText> */}
          <AnnouncementDescriptionText>{`Hold tight! New tables on the way.`}</AnnouncementDescriptionText>
        </AnnouncementInfoContainer>
      </AnnouncementInfoWrapper>
    );
  };

  const handleGameTileClick = (gameDefinition) => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_GameTile_click",
      eventProperties: {
        userName: playerData.userName,
        channel,
        gameMode: props.flavourType === "real" ? "Cash" : "Fun",
        currentCashBalance: userBalance.totalBalance,
        noOfPlayers: props.maxPlayers,
        gameId: props.gameDefId,
        gameFormat: props.gameType,
        blinds: gameDefinition.smallBlind + "/" + gameDefinition.bigBlind,
        timeStamp,
      },
    });
    return gameTileClickHandler(gameDefinition);
    // useCallback caches a function between re-renders until its dependencies change.
    // added userBlanace as dependency here , so that after first add cash new updated dependencies values are used.
  };

  return (
    <ListViewcontainer
      id={"gametiles-list-view-container"}
      containerWidth={window.innerWidth}
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {gameTilesList?.length === 0 || !activeGamesExist
        ? renderEmptyAnnouncement()
        : gameTilesList.map((gameDefId, index) => {
            return (
              <LobbyGameTile
                key={`tab-${tab}-gameTilesList-${gameDefId}`}
                tab={tab}
                gameDefId={gameDefId}
                applicableLeaderboards={applicableLeaderboards}
                rakebackDefinitions={rakebackDefinitions}
                handleGameTileClick={handleGameTileClick}
              />
            );
          })}
    </ListViewcontainer>
  );
}

export default LobbyGameTilesList;
