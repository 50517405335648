import Handler from "../Handler";

class SitOutHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processSitOut(receivedData);
  }
}

export default SitOutHandler;
