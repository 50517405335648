import Handler from "../Handler";

class TurnHandler extends Handler {
  execute(message) {
    /**stime#{"gameId":"03102022-55485928brdd","startTime":"5"} */

    let receivedData = JSON.parse(message);
    this.service.processTurnChange(receivedData);
  }
}

export default TurnHandler;

// Turn# {
//     "playerName": "hammer241661",
//     "position": 4,
//     "fold": true,
//     "check": false,
//     "call": true,
//     "raise": true,
//     "allInForCall": false,
//     "callAmount": 50,
//     "time": 17,
//     "maxTime": 17
//   }
