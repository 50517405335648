import React from "react";

import { ButtonWrapper, TextWrapper, IconWrapper } from "./styledComponents";

function Button(props) {
  const { children, location } = props;

  return (
    <ButtonWrapper
      location={location}
      bgColor={props.bgColor}
      onClick={() => props.clickHandler ? props.clickHandler() : null}
      {...props}
    >
      {props.showIcon && <IconWrapper src={props.iconUrl} />}
      {props.buttonText && <TextWrapper location={location}>{props.buttonText}</TextWrapper>}
      {children && children}
    </ButtonWrapper>
  );
}

export default Button;
