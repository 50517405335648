import Handler from "../Handler";

class HeartBeatLobbyHandler extends Handler {
  // g --- {"onlinePlayerCount":5,"currentTime":"30-Oct-2023 10:47:05"}
  execute(data) {
    data = JSON.parse(data);
    this.service.processLobbyHeartBeat(data);
  }
}

export default HeartBeatLobbyHandler;
