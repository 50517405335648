import Handler from "../Handler";

class PlayerConfigAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);

    this.service.processPlayerConfigAck(receivedData);
  }
}

export default PlayerConfigAckHandler;
