import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BuyIn } from "./BuyIn/BuyIn";
import HandRanking from "./HandRanking/HandRanking";
import { Information } from "./Information/Information";
import { DarkScreen, GlobalModal, RelativeWrapper } from "./modalStyle";
import PlayerStats from "./PlayerStats/PlayerStats";
import { requestRemoveReserveSeat } from "../../../Poker/redux/slices/gameTableSlice";
import DisconnectionModal from "./Disconnection/DisconnectionModal";
import {
  ASSET_ERROR,
  BUY_IN,
  DELETED_PLAYER_LOGOUT,
  DISCONNECTION,
  FOLD_CONFIRM,
  FORCE_LOGOUT,
  HAND_RANKING,
  INFORMATION,
  INSUFFICIENT_BALANCE,
  INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
  INSUFFICIENT_FUN_CHIPS_BALANCE,
  LEAVE_SEAT,
  LEAVE_TABLE,
  LOGOUT_WARNING,
  MAINTENANCE,
  MAX_FUN_GAMES_LIMIT,
  MAX_TABLE_LIMIT,
  NEW_TABLE,
  PLAYER_STATS,
  RAKEBACK_LEVEL_UPGRADE,
  REBUY_IN,
  RELOGIN_FAILURE,
  RESTRICTED_ACCESS,
  RESTRICTED_LOCATION,
  RESTRICT_FUN_CHIPS_RELOAD,
  TOURNEY_FAIRPLAY_POLICY_RESTRICTION,
  TOURNEY_LATE_REGISTRATION_SUCCESS,
  TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH,
  UNSUPPORTED_DEVICE,
  UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE,
} from "../../../Poker/data/Constants";
import SelectNewTable from "./NewGame/NewTable";
import Confirm from "./Confirm/Confirm";
import { closeModal } from "../../../Poker/redux/slices/modalSlice";
import MaxTableLimit from "./MaxTableLimit/MaxTableLimit";
import MaxFunTablesLimit from "./MaxTableLimit/MaxFunTablesLimit";
import Maintenance from "./MaintenancePopup/Maintenance";
import { RakebackLevelUpgrade } from "./RakebackLevelUpgrade/RakebackLevelUpgrade";
import RestrictedLocation from "./RestrictedLocation/RestrictedLocation";
import ForceLogoutModal from "./ForceLogout/ForceLogoutModal";
import DeletedPlayerForceLogout from "./ForceLogout/DeletedPlayerForceLogout";
import {
  updateShowLateRegisterSuccessPopup,
  updateShowTourneyFairPlayPolicyRestruction,
} from "../../../Poker/redux/slices/lobbySlice";
import UnsupportedDevice from "./UnsupportedDevice/UnsupportedDevice";
import AssetError from "./AssetError/AssetError";
import UnSupportedFoldTabletModeDevice from "./UnsupportedDevice/UnSupportedFoldTabletModeDevice";

const LobbyModalComponent = () => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.type);
  const modalStatus = useSelector((state) => state.modal.visible);
  const modalConfig = useSelector((state) => state.modal.config);
  let tempTableId = null;

  const createModal = () => {
    switch (modalType) {
      case BUY_IN:
        // console.log('Triggering buy in flow', tempTableId)
        return (
          <BuyIn
            tempTableId={tempTableId}
            handleModalClose={handleModalClose}
          />
        );
      case REBUY_IN:
        return (
          <BuyIn
            tempTableId={tempTableId}
            handleModalClose={handleModalClose}
          />
        );
      case INSUFFICIENT_BALANCE:
        return (
          <Confirm
            type={INSUFFICIENT_BALANCE}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
            modalType={"global"}
          />
        );
      case INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT:
        return (
          <Confirm
            type={INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT}
            handleModalClose={handleModalClose}
          />
        );
      case INFORMATION:
        return <Information />;
      case HAND_RANKING:
        return <HandRanking />;
      case PLAYER_STATS:
        return <PlayerStats />;
      case LEAVE_TABLE:
        return (
          <Confirm
            type={LEAVE_TABLE}
            handleModalClose={handleModalClose}
          />
        );
      case "join-failure":
      // return <Confirm type="leave-table" />;
      case LEAVE_SEAT:
        return (
          <Confirm
            type={LEAVE_SEAT}
            handleModalClose={handleModalClose}
          />
        );
      case FOLD_CONFIRM:
        return (
          <Confirm
            type={FOLD_CONFIRM}
            handleModalClose={handleModalClose}
          />
        );
      case DISCONNECTION:
        return <DisconnectionModal type={DISCONNECTION} />;
      case RELOGIN_FAILURE:
        return <DisconnectionModal type={RELOGIN_FAILURE} />;
      case NEW_TABLE:
        return <SelectNewTable />;
      case MAX_TABLE_LIMIT:
        return <MaxTableLimit />;
      case MAX_FUN_GAMES_LIMIT:
        return <MaxFunTablesLimit />;
      case MAINTENANCE:
        return <Maintenance />;
      case INSUFFICIENT_FUN_CHIPS_BALANCE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={INSUFFICIENT_FUN_CHIPS_BALANCE}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case RESTRICT_FUN_CHIPS_RELOAD:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RESTRICT_FUN_CHIPS_RELOAD}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case RAKEBACK_LEVEL_UPGRADE:
        return (
          <RakebackLevelUpgrade
            type={RAKEBACK_LEVEL_UPGRADE}
            handleModalClose={handleModalClose}
          />
        );
      case LOGOUT_WARNING:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={LOGOUT_WARNING}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case RESTRICTED_ACCESS:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RESTRICTED_ACCESS}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case FORCE_LOGOUT:
        return <ForceLogoutModal />;
      case UNSUPPORTED_DEVICE:
        return <UnsupportedDevice />;
      case UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE:
        return <UnSupportedFoldTabletModeDevice />;
      case ASSET_ERROR:
        return <AssetError />;
      case DELETED_PLAYER_LOGOUT:
        return <DeletedPlayerForceLogout />;
      case RESTRICTED_LOCATION:
        return <RestrictedLocation />;
      case TOURNEY_FAIRPLAY_POLICY_RESTRICTION:
        return (
          <Confirm
            type={TOURNEY_FAIRPLAY_POLICY_RESTRICTION}
            handleModalClose={handleModalClose}
          />
        );
      case TOURNEY_LATE_REGISTRATION_SUCCESS:
        return (
          <Confirm
            type={TOURNEY_LATE_REGISTRATION_SUCCESS}
            handleModalClose={handleModalClose}
          />
        );
      case TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      default:
        return;
    }
  };

  const handleModalClose = () => {
    if (modalType === TOURNEY_FAIRPLAY_POLICY_RESTRICTION) {
      dispatch(updateShowTourneyFairPlayPolicyRestruction({ isOpen: false }));
    }
    if (modalType === TOURNEY_LATE_REGISTRATION_SUCCESS) {
      dispatch(updateShowLateRegisterSuccessPopup({ isOpen: false }));
    }
    if (modalType === BUY_IN) {
      // console.log('Triggering buy in modal close function')
      dispatch(requestRemoveReserveSeat({ tempTableId: tempTableId }));
      return;
    } else if (
      modalType === "join-failure" ||
      modalType === "Disconnection" ||
      modalType === DELETED_PLAYER_LOGOUT ||
      modalType === FORCE_LOGOUT ||
      modalType === UNSUPPORTED_DEVICE ||
      modalType === ASSET_ERROR
    ) {
      return;
    } else {
      dispatch(closeModal());
      return;
    }
  };

  const modalBackgroundActions = () => {
    if (modalType === BUY_IN) {
      // dispatch(requestRemoveReserveSeat({}));
      return;
    } else if (
      modalType === "join-failure" ||
      modalType === "Disconnection" ||
      modalType === RELOGIN_FAILURE ||
      modalType === DISCONNECTION ||
      modalType === RESTRICTED_LOCATION ||
      modalType === DELETED_PLAYER_LOGOUT ||
      modalType === FORCE_LOGOUT ||
      modalType === UNSUPPORTED_DEVICE ||
      modalType === ASSET_ERROR
    ) {
      return;
    } else {
      dispatch(closeModal());
      return;
    }
  };

  return (
    <>
      {modalStatus && modalType ? (
        <GlobalModal id='global-modal'>
          <RelativeWrapper id='relative-wrapper'>
            <DarkScreen
              id='darkscreen'
              onClick={() => modalBackgroundActions()}
            />
            {createModal()}
          </RelativeWrapper>
        </GlobalModal>
      ) : null}
    </>
  );
};

export default LobbyModalComponent;
