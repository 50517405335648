import React from "react";
import { MaintenanceAnimation } from "../../../../Poker/components/Maintenance/MaintenanceAnimation";
import {
  MaintenanceMainText,
  MaintenanceModalWrapper,
  MaintenanceSubText,
  MaintenanceWrapper,
} from "./MaintenanceStyle";

const Maintenance = ({ betLevel }) => {
  return (
    <MaintenanceModalWrapper>
      <MaintenanceWrapper betLevel={betLevel}>
        <MaintenanceAnimation size={75} />
        <MaintenanceMainText>We're shuffling things up!</MaintenanceMainText>
        <MaintenanceSubText>
          {betLevel
            ? "This bet is currently under maintenance."
            : "The game is currently under maintenance to bring you an even better poker experience."}
        </MaintenanceSubText>
      </MaintenanceWrapper>
    </MaintenanceModalWrapper>
  );
};

export default Maintenance;
