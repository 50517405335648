import Handler from "../Handler";

class TourneyWaitingForMergingHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyWaitingForMergingDetails(receivedData);
  }
}

export default TourneyWaitingForMergingHandler;
