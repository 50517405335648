import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import {
  Typo12GrayContoRegularText,
  Typo12WhiteContoRegularText,
} from "../../../styleGuide/Typos";

export const CardShowcase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Card = styled.img`
  width: 25px;
  height: auto;
  margin: 0px 1px;
`;

export const DullCard = styled.img`
  width: 25px;
  height: auto;
  opacity: 0.5;
  margin: 0px 1px;
`;

export const PokerHandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0.25rem 1rem; */
`;
export const HandInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PokerHandNumber = styled.div`
  background-color: ${colors.primaryGold};
  color: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;

export const PokerHandName = styled.div`
  white-space: nowrap;
  margin: ${(props) =>
    props.orientation === "LANDSCAPE" ? "0px 15px" : "0.75rem 0.5rem"};
  color: white;
  font-size: 10px;
`;
