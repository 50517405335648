import Handler from "../Handler";

class RSeatsBroadcastHandler extends Handler {
  execute(message) {
    // RSeatsB#{"tableId":"THR300823-3728053tv12m","gameId":"300823-3728053of7ds","playerList":[{"playerName":"testuser","position":1}]}

    let receivedData = JSON.parse(message);
    this.service.processRSeatsBroadcast(receivedData);
  }
}

export default RSeatsBroadcastHandler;
