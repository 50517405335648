import Handler from "../Handler";

class TopupAckHandler extends Handler {
  // topupa#{"playerName":"jade938986","status":"PENDING","statusCode":700,"message":"MANUAL TOP-UP REQUEST RECEIVED"}
  // topupa#{"playerName":"jade938986","status":"SUCCESS","statusCode":701,"message":"TOP-UP SUCCESSFULLY WITH AN AMOUNT OF 114.75"}
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTopupSuccessAcknowledgement(receivedData);
  }
}

export default TopupAckHandler;

// TopUpA#{playerName:"abc", atTableAmount:"123.33"}
