import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Typo12GrayContoRegularText } from "../../../styleGuide/Typos";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { RangeSlider } from "../../Inputs/RangeSlider/RangeSlider";
import { BuyInWrapper } from "../modalStyle";
import {
  InfoDetail,
  InfoDivider,
  InfoTitle,
  InfoWrapper,
  Line,
  EntryAmount,
  RangeDetails,
  BuyInTimerWrapper,
  BuyInWrapperContainer,
  AntiBankingNote,
  HeaderTitleText,
  HeaderWrapper,
  BuyInBody,
} from "./BuyInStyles";

import Button from "../../Button/Button";
import colors from "../../../styleGuide/Colors";
import {
  disableMultitableSwipe,
  enableMultitableSwipe,
  updateTopupModalOpenStatus,
} from "../../../../Poker/redux/slices/gameTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { BuyInTimer } from "../../Timer/BuyInTimer";
import {
  BUY_IN,
  LB_REAL,
  RUPEE_SYMBOL,
  TIMER_TYPE_BUY_IN,
} from "../../../../Poker/data/Constants";
import { Loader } from "../../../../Poker/components/Loader/Loader";
import { ToolTip } from "../../../../Poker/components/ToolTip/ToolTip";
import {
  getAntiBankingNote,
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../utils/GameUtils";
import GameConnectionsN from "../../../../Poker/io/GameConnectionsN";
import {
  getGameSlice,
  getPlayerObject,
} from "../../../../Poker/utils/ReduxUtils";
import {
  sendRebuyInProtocol,
  sendTopUpProtocol,
} from "../../../../Poker/utils/ProtocolUtils";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil";
import CleverTapUtil from "../../../utils/CleverTapUtil";
import {
  IP_Client_GameTable_Buyin_Click,
  IP_Client_Topup,
} from "../../../../Poker/data/ClevertapConstants";

export const BuyIn = ({ tempTableId, handleModalClose }) => {
  const gameDefinition = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[tempTableId].isFunGame
  );
  const orientation = useSelector((state) => state.gameTable.tableOrientation);
  const buyInTimeInSeconds = useSelector(
    (state) => state.gameTable.games[tempTableId].buyInTimeInSeconds
  );
  const [inputRangeValue, setInputRangeValue] = useState(
    gameDefinition.minBuyInAmount
  );
  const inputRef = useRef();
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.lobby.balance);
  const [buyInLoader, setBuyInLoader] = useState(false);

  const openTopupModal = useSelector(
    (state) => state.gameTable.games[tempTableId].openTopupModal
  );
  const openRebuyInModal = useSelector(
    (state) => state.gameTable.games[tempTableId].openRebuyInModal
  );

  const antiBankingDetails = useSelector(
    (state) => state.gameTable.games[tempTableId]?.antiBanking
  );

  const setBuyInValueForPlayerPosition = useSelector(
    (state) => state.gameTable.games[tempTableId].setBuyInValueForPlayerPosition
  );

  const playerData = useSelector((state) =>
    state.gameTable.games[tempTableId]?.playerData.find(
      (player) =>
        player.position === state.gameTable.games[tempTableId].playerSeat
    )
  );
  const channel = useSelector((state) => state.lobby.channel);

  const [showToolTip, setShowToolTip] = useState(false);
  const [antiBankingAmount, setAntiBankingAmount] = useState(0);
  const [minRangeValue, setMinRangeValue] = useState(
    gameDefinition?.minBuyInAmount
  );
  const [maxRangeValue, setMaxRangeValue] = useState(
    isFunGame
      ? userBalance.playChips >= gameDefinition.maxbuyInAmount
        ? gameDefinition.maxbuyInAmount
        : userBalance.playChips
      : userBalance.totalBalance >= gameDefinition.maxbuyInAmount
      ? gameDefinition.maxbuyInAmount
      : userBalance.totalBalance
  );
  const processingDReserve = useSelector(
    (state) => state.gameTable.games[tempTableId].processingDReserve
  );

  useEffect(() => {
    if (antiBankingDetails?.isAntiBank) {
      if (antiBankingDetails?.antiBankAmount > gameDefinition.minBuyInAmount) {
        setMinRangeValue(antiBankingDetails?.antiBankAmount);
        setInputRangeValue(antiBankingDetails?.antiBankAmount);
      }
      if (antiBankingDetails?.antiBankAmount > gameDefinition.maxbuyInAmount) {
        setMaxRangeValue(antiBankingDetails?.antiBankAmount);
        setInputRangeValue(antiBankingDetails?.antiBankAmount);
      }
    }
  }, [antiBankingDetails?.isAntiBank]);

  useEffect(() => {
    let atTableAmount = Number(playerData?.atTableAmount);
    let playerBalance = Number(userBalance.totalBalance);
    let minBuyInAmount = Number(gameDefinition?.minBuyInAmount);
    let maxBuyInAmount = Number(gameDefinition?.maxbuyInAmount);
    let bigBlind = Number(gameDefinition?.bigBlind);
    if (openTopupModal) {
      // Setting the minimum slider amount for top up.
      if (atTableAmount + bigBlind > maxBuyInAmount) {
        setMinRangeValue(maxBuyInAmount);
        setInputRangeValue(maxBuyInAmount);
      } else {
        setMinRangeValue(Math.max(atTableAmount + bigBlind, minBuyInAmount));
        setInputRangeValue(
          Math.max(atTableAmount + bigBlind, minBuyInAmount, inputRangeValue)
        );
      }
      // Setting the maximum slider amount for top up
      setMaxRangeValue(Math.min(playerBalance + atTableAmount, maxBuyInAmount));
    }
  }, [playerData?.atTableAmount]);

  const createBlindDetails = () => {
    let details = [
      {
        key: "Total Balance",
        value: `${
          isFunGame ? userBalance.playChips : userBalance.totalBalance
        }`,
      },
      {
        key: "Blinds",
        value: `${gameDefinition.smallBlind}/${gameDefinition.bigBlind}`,
      },
      {
        key: "Max Buy-In",
        value: parseDecimalValuesOfNumber(Number(maxRangeValue)),
      },
    ];

    return details.map((item, i) => {
      return (
        <InfoWrapper key={i}>
          <InfoTitle> {item.key}</InfoTitle>
          <InfoDivider>:</InfoDivider>
          <InfoDetail>
            {i !== 1 ? (isFunGame ? "" : `${RUPEE_SYMBOL}`) : ""}
            {item.value}
          </InfoDetail>
        </InfoWrapper>
      );
    });
  };

  const inputRangeSliderValueHandler = (value) => {
    setInputRangeValue(parseDecimalValuesOfNumber(Number(value)));
  };

  const createEntryAmountDetails = () => {
    // console.log(
    //   "SPRUHA LOG MIN BUY IN TOP UP",
    //   playerData?.atTableAmount, gameDefinition.minBuyInAmount
    // );
    return (
      <>
        <Line></Line>
        <InfoWrapper marginBottom={6}>
          <InfoTitle>{openTopupModal ? "Top-up to" : "Entry amount"}</InfoTitle>
          <InfoDivider></InfoDivider>
          <EntryAmount>
            {isFunGame ? "" : RUPEE_SYMBOL}
            {parseDecimalValuesOfNumber(Number(inputRangeValue))}
          </EntryAmount>
        </InfoWrapper>

        <div
          onTouchStart={() => {
            dispatch(disableMultitableSwipe());
          }}
          onTouchEnd={() => {
            dispatch(enableMultitableSwipe());
          }}
        >
          {antiBankingDetails?.antiBankAmount !== maxRangeValue &&
          parseDecimalValuesOfNumber(minRangeValue, 0) !==
            parseDecimalValuesOfNumber(maxRangeValue, 0) ? (
            <>
              <RangeSlider
                min={parseDecimalValuesOfNumber(minRangeValue, 0)}
                max={parseDecimalValuesOfNumber(maxRangeValue, 0)}
                inputRefs={inputRef}
                stepValue={Math.min(gameDefinition.bigBlind, 1)}
                passInputValueToParentCallBack={inputRangeSliderValueHandler}
                initialValue={inputRangeValue}
              ></RangeSlider>

              <RangeDetails>
                <Typo12GrayContoRegularText>
                  {isFunGame ? "" : RUPEE_SYMBOL}
                  {openTopupModal
                    ? playerData?.atTableAmount < gameDefinition.minBuyInAmount
                      ? gameDefinition.minBuyInAmount
                      : playerData?.atTableAmount + gameDefinition.bigBlind
                    : minRangeValue}
                </Typo12GrayContoRegularText>
                <Typo12GrayContoRegularText>
                  {isFunGame ? "" : RUPEE_SYMBOL}
                  {parseDecimalValuesOfNumber(maxRangeValue, 0)}
                </Typo12GrayContoRegularText>
              </RangeDetails>
            </>
          ) : null}
        </div>

        {antiBankingDetails?.isAntiBank &&
        !openTopupModal &&
        !openRebuyInModal ? (
          <AntiBankingNote>
            {getAntiBankingNote(
              antiBankingDetails?.antiBankAmount,
              !isFunGame,
              gameDefinition?.antiBankingTime
            )}
          </AntiBankingNote>
        ) : null}
      </>
    );
  };

  const onBuyButtonClick = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_GameTable_Buyin_Click",
      eventProperties: {
        userName: getPlayerObject().userName,
        channel,
        currentCashBalance: userBalance.totalBalance,
        timeStamp,
        bigBlind: gameDefinition.bigBlind,
        smallBlind: gameDefinition.smallBlind,
        noOfPlayers: gameDefinition.maxPlayers,
        gameType: gameDefinition.gameType,
      },
    });
    CleverTapUtil.getInstance().logEvent(
      openTopupModal ? IP_Client_Topup : IP_Client_GameTable_Buyin_Click,
      {
        Username: getPlayerObject().userName,
        Channel: channel,
        "Wallet balance": userBalance?.totalBalance,
        Timestamp: getISTDateTimeFormat(),
        "Big blind": gameDefinition?.bigBlind,
        "No. of players": gameDefinition?.maxPlayers,
        "Game type": gameDefinition?.gameType,
        "Table name": gameDefinition?.tableName,
        "Cash / Fun": gameDefinition?.flavourType === LB_REAL ? "Cash" : "Fun",
        "Game mode": gameDefinition?.gameMode,
        "Buy-in amount": Number(inputRangeValue),
      }
    );
    setBuyInLoader(true);
    if (openRebuyInModal) {
      sendRebuyInProtocol({
        tempTableId: tempTableId,
        value: Number(inputRangeValue),
      });
    } else if (openTopupModal) {
      //hitting protocol directly @yashwanth
      // dispatch(
      //   initiateTopupRequest({
      //     tempTableId: tempTableId,
      //     topUpAmount: Number(inputRangeValue),
      //   })
      // );
      sendTopUpProtocol({
        tempTableId: tempTableId,
        topUpAmount: Number(inputRangeValue),
        playerName: getPlayerObject().userName,
      });
    } else {
      sendJoinRequest();
    }
  };

  const sendJoinRequest = () => {
    let tableData = getGameSlice().games[tempTableId];
    let playerSeat = tableData.setBuyInValueForPlayerPosition;

    let requestPayload = {
      tableId: tableData.tableId,
      gameDefId: tableData.gameDefinition.gameDefId,
      position: playerSeat,
      buyInAmount: Number(inputRangeValue),
      userId: getPlayerObject().userName,
      playerName: getPlayerObject().userName,
      isUserOptForAutoRebuy: tableData.isOptForAutoReBuy,
    };

    console.log("Request payload at join protocol:", requestPayload);

    let gameConnection =
      GameConnectionsN.getInstance().getGameConnection(tempTableId);
    gameConnection.listener.requestGameJoin(requestPayload);
  };

  const createBuyButton = () => {
    return (
      <BuyInWrapperContainer>
        {!buyInLoader ? (
          <Button
            location={BUY_IN}
            bgColor={colors.secondaryGreen}
            buttonText='Buy'
            showIcon={false}
            clickHandler={() => onBuyButtonClick()}
          ></Button>
        ) : (
          <Button location={BUY_IN}>
            <Loader size={20} />
          </Button>
        )}
        {!openTopupModal && (
          <BuyInTimerWrapper id='buy-in-timer-wrapper'>
            {!buyInLoader && (
              <BuyInTimer
                tempTableId={tempTableId}
                type={TIMER_TYPE_BUY_IN}
                timer={buyInTimeInSeconds}
                buyInCloseFunction={handleBuyInClose}
              />
            )}
          </BuyInTimerWrapper>
        )}
      </BuyInWrapperContainer>
    );
  };

  const createToolTip = () => {
    return (
      showToolTip && (
        <ToolTip autoCloseToolTipCallback={autoCloseToolTipCallback}></ToolTip>
      )
    );
  };

  const autoCloseToolTipCallback = (value) => {
    setShowToolTip(value);
  };

  const handleBuyInClose = () => {
    if (openRebuyInModal) {
      handleModalClose({ tempTableId: tempTableId });
      return;
    }
    if (openTopupModal) {
      dispatch(
        updateTopupModalOpenStatus({
          tempTableId: tempTableId,
          isOpen: false,
        })
      );
    } else {
      if (!processingDReserve) {
        handleModalClose({ tempTableId: tempTableId });
      }
    }
  };

  return (
    <>
      <BuyInWrapper orientation={orientation}>
        <HeaderWrapper>
          <HeaderTitleText>{`${
            openTopupModal ? `Top Up` : `Buy In`
          }`}</HeaderTitleText>
          {buyInLoader ? null : (
            <CloseButton
              tempTableId={tempTableId}
              onCloseHandler={handleBuyInClose}
              top={10}
            ></CloseButton>
          )}
        </HeaderWrapper>
        <BuyInBody orientation={orientation}>
          {createBlindDetails()}
          {createEntryAmountDetails()}
          {createBuyButton()}
        </BuyInBody>
      </BuyInWrapper>
      {createToolTip()}
    </>
  );
};
