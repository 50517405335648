import Handler from "../Handler";

class PlayerStatsOnDemandFailure extends Handler {
  execute() {
    // let receivedData = JSON.parse(message);
    this.service.processOnDemandPlayerStatsFailure();
  }
}

export default PlayerStatsOnDemandFailure;
