import React from "react";

import leftArrowIcon from "../../../assets/lobby/LeftArrowIcon.svg";

import {
  FullscreenWindowContainer,
  WindowHeader,
  WindowChildrenWrapper,
  BackArrowIconWrapper,
  HeaderTitleText,
} from "./styledComponents";

function FullScreenPageContainer(props) {
  return (
    <FullscreenWindowContainer>
      <WindowHeader>
        <BackArrowIconWrapper
          src={leftArrowIcon}
          onTouchStart={props.handleBackButton}
        />
        <HeaderTitleText>{props.title}</HeaderTitleText>
      </WindowHeader>
      <WindowChildrenWrapper type="text/html" data={props.url} />
    </FullscreenWindowContainer>
  );
}

export default FullScreenPageContainer;
