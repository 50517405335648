import Handler from "../Handler";

class TourneyTicketsHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyTicketsData(receivedData);
  }
}
export default TourneyTicketsHandler;

//tickets: [{tourneyId: "2mAF7", ticketClaimStatus: false}]
