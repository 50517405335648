import React, { useEffect, useState } from "react";
import {
  CardDetails,
  CardHeader,
  CollapseCardContainer,
  CollapseCardHeaderCardsContainer,
  CollapseCardTitle,
  OpenCloseButton,
  OpenCloseButtonIcon,
} from "./collapseCardStyle";
import arrowDown from "../../../assets/common/angleDown.svg";
import { CollapseCardPlayingCard } from "../../../Poker/components/HandHistory/handHistoryStyles";
import { cardThemes } from "../../../assets/cards";
import { useSelector } from "react-redux";

export const CollapseCard = ({ cardsProp, cardDetails, cardLabel }) => {
  const [isCardExpanded, setIsCardExpanded] = useState(
    cardLabel === "Summary" ? true : false
  );
  const [playInitialAnimation, setPlayInitialAnimation] = useState(false);
  const [cards, setCards] = useState([]);
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );

  const onExpandButtonClick = () => {
    console.log("IS EXPANDING");
    setPlayInitialAnimation(true);
    setIsCardExpanded(!isCardExpanded);
  };

  const handleCardsOnHeader = () => {
    console.log("Community Cards", cardsProp, cardLabel);
    if (cardLabel === "Flop") {
      setCards(cardsProp.slice(0, 3));
    } else if (cardLabel === "Turn") {
      setCards(cardsProp.slice(0, 4));
    } else if (cardLabel === "River") {
      setCards(cardsProp.slice(0, 5));
    }
  };

  useEffect(() => {
    handleCardsOnHeader();
  }, []);

  return cardDetails ? (
    <CollapseCardContainer
      playInitialAnimation={playInitialAnimation}
      isCardExpanded={isCardExpanded}
    >
      <CardHeader
        onClick={() => {
          onExpandButtonClick();
        }}
      >
        <OpenCloseButton>
          <OpenCloseButtonIcon
            playInitialAnimation={playInitialAnimation}
            isCardExpanded={isCardExpanded}
            src={arrowDown}
          ></OpenCloseButtonIcon>
        </OpenCloseButton>
		<div style={{minWidth: '30px'}}>
        <CollapseCardTitle>{cardLabel}</CollapseCardTitle>
		</div>
        <CollapseCardHeaderCardsContainer>
          {cards.map((card, index) => {
            return (
              <CollapseCardPlayingCard
                id="hand-history-card"
                cardNumber={index}
                src={cardThemes[cardDeckTheme][card]}
                key={
                  card + 'collapse-card-header-' + index + "handHistory-communityCards"
                }
              />
            );
          })}
        </CollapseCardHeaderCardsContainer>
      </CardHeader>
      {
        // isCardExpanded &&
        <CardDetails
          playInitialAnimation={playInitialAnimation}
          isCardExpanded={isCardExpanded}
        >
          {cardDetails}
        </CardDetails>
      }
    </CollapseCardContainer>
  ) : null;
};
