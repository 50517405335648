//TourneyDynamicCountsUpdateHandler.js

import Handler from "../Handler";

class TourneyDynamicCountsUpdateHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyDynamicCountsUpdate(receivedData);
  }
}
export default TourneyDynamicCountsUpdateHandler;

// tcd#{"event":"tourney-count-data","tourneyId":"mmrqg","reEntryCount":1,"reBuyCount":0,"addOnCount":0}
