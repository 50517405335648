import forge from "node-forge";
import { sendLobbyDebugLog } from "../../Poker/redux/slices/lobbySlice";

export const encryptAPIPayload = (data, encodeAuthData) => {
  try {
    if (data) {
      const key = `
    -----BEGIN PUBLIC KEY-----
    ${process.env.REACT_APP_RSA_PUBLIC_KEY}
    -----END PUBLIC KEY-----
`;

      const stringifiedData = JSON.stringify(data);

      // Convert PEM to forge public key
      const publicKey = forge.pki.publicKeyFromPem(key);
      const encryptedData = publicKey.encrypt(
        `${stringifiedData}`,
        "RSAES-PKCS1-V1_5"
      );
      const encryptedDataBase64 = forge.util.encode64(encryptedData);
      // Building RSA api payload using encryptedData.
      let apiRequestBody = {
        vendor_id: process.env.REACT_APP_RSA_VENDOR,
        authorization: encodeAuthData
          ? encodeURIComponent(encryptedDataBase64)
          : encryptedDataBase64,
      };
      return apiRequestBody;
    }
  } catch (e) {
    sendLobbyDebugLog({
      type: "ERROR_SOMETHING_WENT_WRONG_AT_FORGE_ENCRYPTION",
      error: JSON.stringify(e),
    });
  }
  return;
};
