import Handler from "../Handler";

class ReserveBroadcastHandler extends Handler {
  execute(message) {
    /** reserved --- {"playerName":"pradeep","position":1,"gender":"M"} */

    let receivedData = JSON.parse(message);
    this.service.processReserveSeatBroadcast(receivedData);
  }
}

export default ReserveBroadcastHandler;
