import Handler from "../Handler";

class WaitListAckHandler extends Handler {
  execute(message) {
    // WaitA#
    // {playerName:”abc”,tableId:”fvd”,status:100,toWaitListCount:1,s:1}
    // 100 - for success
    // 111 - insufficient funds

    let receivedData = JSON.parse(message);
    this.service.processJoinWaitListAck(receivedData);
  }
}

export default WaitListAckHandler;