
import Handler from "../Handler";

class TourneyRegisteredCountHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyRegisteredCount(receivedData);
  }
}
export default TourneyRegisteredCountHandler;

//trc#{"tourneyRegistrationCountMap":{"7Tf9N":0,"3QrnK":0,"oDKvy":0,"s6fJW":0}}
