export const SERVER_ADDRESS_FUNCTION = "getServerAddress";
export const SERVER_PORT_FUNCTION = "getServerPort";
export const SERVER_HEART_BEAT_MESSAGE_FUNCTION = "getHeartBeatMessage";
export const SERVER_HEART_BEAT_INTERVAL_FUNCTION = "getHeartBeatInterval";
export const SERVER_CONNECTION_TIME_OUT_FUNCTION = "getConnectionTimeout";
export const SERVER_READ_TIMEDOUT_FUNCTION = "getReadTimeout";
export const SERVER_HANDLERS_LIST_FUNCTION = "getHandlersList";
export const CLIENT_RECONNECTION_INTERVAL_FUNCTION = "getReconnectionTimeout";
export const CLIENT_PROTOCOL_DELIMITER_FUNCTION = "getProtocolDelimiter";
export const EVENT_ON_CLIENT_CONNECT = "onClientConnect";
export const EVENT_ON_CLIENT_DISCONNECT = "onClientDisconnect";
export const EVENT_ON_CLIENT_READ_TIMED_OUT = "onReadTimedout";
export const EVENT_ON_CLIENT_CONNECT_TIME_OUT = "onConnectTimeout";
export const EVENT_ON_CLIENT_RE_CONNECT = "onClientReConnect";
export const CLIENT_RECONNECTION_MODE = "getReconnectionMode";

class Configuration extends Object {
  constructor() {
    // connectionType,
    // serverAddress,
    // serverPort,
    // handlersList,
    // heartBeatMessage,
    // heartBeatInterval,
    // connectionTimeout,
    // readTimeout,
    // reconnectionAttemptInterval,
    // protocolDelimiter
    super();
    // this.connectionType = connectionType;
    // this.serverAddress = serverAddress;
    // this.serverPort = serverPort;
    // this.handlersList = handlersList;
    // this.heartBeatMessage = heartBeatMessage;
    // this.heartBeatInterval = heartBeatInterval;
    // this.connectionTimeout = connectionTimeout;
    // this.readTimeout = readTimeout;
    // this.reconnectionAttemptInterval = reconnectionAttemptInterval;
    // this.protocolDelimiter = protocolDelimiter;
  }

  setConnectionType(value) {
    this.connectionType = value;
  }

  getConnectionType() {
    return this.connectionType;
  }

  setServerAddress(value) {
    this.serverAddress = value;
  }

  getServerAddress() {
    return this.serverAddress;
  }

  setServerPort(value) {
    this.serverPort = value;
  }

  getServerPort() {
    return this.serverPort;
  }

  setHandlersList(value) {
    this.handlersList = value;
  }

  getHandlersList() {
    return this.handlersList;
  }

  setHeartBeatMessage(message) {
    this.heartBeatMessage = message;
  }

  getHeartBeatMessage() {
    return this.heartBeatMessage;
  }

  setHeartBeatInterval(value) {
    this.heartBeatInterval = value;
  }

  getHeartBeatInterval() {
    return this.heartBeatInterval;
  }

  setConnectionTimeout(value) {
    this.connectionTimeout = value;
  }

  getConnectionTimeout() {
    return this.connectionTimeout;
  }

  setReadTimeout(value) {
    this.readTimeout = value;
  }

  getReadTimeout() {
    return this.readTimeout;
  }

  setReconnectionTimeout(value) {
    this.reconnectionAttemptInterval = value;
  }

  getReconnectionTimeout() {
    return this.reconnectionAttemptInterval;
  }

  setProtocolDelimiter(value) {
    this.protocolDelimiter = value;
  }

  getProtocolDelimiter() {
    return this.protocolDelimiter;
  }

  getHandlersLength() {
    return Object.keys(this.handlersList).length;
  }

  setReconnectionMode(value) {
    this.reconnectionMode = value;
  }

  getReconnectionMode() {
    return this.reconnectionMode;
  }

  validateConfiguration() {
    let methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    methods = methods.filter((method) => method !== "constructor");
    for (let index = 0; index < methods.length; index++) {
      let key = methods[index];
      key = key.toString();
      if (key.indexOf("set") === -1 && key.indexOf("get") === 0) {
        if (key === SERVER_ADDRESS_FUNCTION) {
          let serverAddress = Configuration.prototype[key].call(this);
          if (serverAddress === undefined) {
            throw new Error("Server Address cannot be undefined");
          }
        } else if (key === SERVER_PORT_FUNCTION) {
          let serverPort = Configuration.prototype[key].call(this);
          if (serverPort === undefined) {
            throw new Error("Server Port cannot be undefined");
          }
        } else if (key === SERVER_HEART_BEAT_MESSAGE_FUNCTION) {
          let heartBeatMessage = Configuration.prototype[key].call(this);
          if (heartBeatMessage === undefined) {
            throw new Error("Heart beat message cannot be undefined");
          }
        } else if (key === SERVER_HEART_BEAT_INTERVAL_FUNCTION) {
          let heartBeatInterval = Configuration.prototype[key].call(this);
          if (heartBeatInterval === undefined) {
            throw new Error("Heart beat interval cannot be undefined");
          }
        } else if (key === SERVER_CONNECTION_TIME_OUT_FUNCTION) {
          let connectionTimeOut = Configuration.prototype[key].call(this);
          if (connectionTimeOut === undefined) {
            throw new Error("Connection timeout cannot be undefined");
          }
        } else if (key === SERVER_READ_TIMEDOUT_FUNCTION) {
          let readTimeOut = Configuration.prototype[key].call(this);
          if (readTimeOut === undefined) {
            throw new Error("Readtime out cannot be undefined");
          }
        } else if (key === CLIENT_RECONNECTION_INTERVAL_FUNCTION) {
          let reconnectionInterval = Configuration.prototype[key].call(this);
          if (reconnectionInterval === undefined) {
            throw new Error("Reconnection interval cannot be undefined");
          }
        } else if (key === CLIENT_PROTOCOL_DELIMITER_FUNCTION) {
          let protocoloDelimiter = Configuration.prototype[key].call(this);
          if (protocoloDelimiter === undefined) {
            throw new Error("Protocol delimiter cannot be undefined");
          }
        } else if (key === CLIENT_RECONNECTION_MODE) {
          let reconnecionMode = Configuration.prototype[key].call(this);
          if (reconnecionMode === undefined) {
            throw new Error("Reconnection mode cannot be undefined");
          }
        } else if (key === SERVER_HANDLERS_LIST_FUNCTION) {
          let handlersList = Configuration.prototype[key].call(this);
          if (handlersList === undefined) {
            throw new Error("Handlers list cannot be undefined ");
          } else {
            if (handlersList instanceof Object) {
              let length = this.getHandlersLength();
              if (length === 0) {
                throw new Error("Handlers list cannot be empty");
              }
            } else {
              throw new Error("Handlers list is not an object");
            }
          }
        }
      }
    }
    return true;
  }
}

export default Configuration;
