import React from "react";
import {
  ModalBody,
  ModalButton,
  ModalContainer,
  ModalText,
  TextContainer,
} from "./NewTableStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLocalGameModal,
  createTableData,
  hideGameTable,
  openLocalGameModal,
} from "../../../../Poker/redux/slices/gameTableSlice";
import colors from "../../../styleGuide/Colors";
import { MAX_TABLE_LIMIT } from "../../../../Poker/data/Constants";
import { requestCurrentFunChipsBalance } from "../../../../Poker/redux/slices/lobbySlice";
import { generateUniqueId } from "../../../utils/PokerUtils";

const SelectNewTable = ({ tempTableId }) => {
  const dispatch = useDispatch();
  const currentGameDefinition = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition
  );
  const games = useSelector((state) => Object.keys(state.gameTable.games));
  const isFunGame = useSelector(
    (state) => state.gameTable.games[tempTableId].isFunGame
  );

  const handleSelection = (type) => {
    if (type === "pick-new-blinds") {
      dispatch(hideGameTable());
      if (isFunGame) {
        dispatch(requestCurrentFunChipsBalance());
      }
    } else if (type === "similar") {
      // if (userBalance <= currentGameDefinition.minBuyInAmount) {
      // Creating a new game only if he is in less than 4 games.
      if (games.length < 4) {
        dispatch(
          createTableData({
            tempTableId: generateUniqueId(),
            gameDefinition: currentGameDefinition,
            watchPlayer: true,
          })
        );
      } else {
        return dispatch(
          openLocalGameModal({
            type: MAX_TABLE_LIMIT,
            tempTableId: tempTableId,
          })
        );
      }
    }
    return dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
  };

  return (
    <ModalContainer>
      <ModalBody>
        <TextContainer>
          <ModalText bold={true}>
            Would you like to join a similar table?
          </ModalText>
          <ModalText
            bold={true}
            style={{ marginTop: "0.5rem", color: colors.primaryGold }}
          >
            {currentGameDefinition.smallBlind} /{" "}
            {currentGameDefinition.bigBlind}
          </ModalText>
        </TextContainer>
        <ModalButton bold={true} onClick={() => handleSelection("similar")}>
          Yes
        </ModalButton>
        <ModalButton
          bold={false}
          type={"secondary"}
          onClick={() => handleSelection("pick-new-blinds")}
        >
          Pick New Blinds
        </ModalButton>
      </ModalBody>
    </ModalContainer>
  );
};

export default SelectNewTable;
