import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10SecondaryRedContoMediumText,
  Typo12OffWhiteContoBoldText,
  Typo10GrayContoRegularText,
  Typo12PrimaryGoldContoMediumText,
  Typo12OffWhiteContoMediumText,
  Typo11OffWhiteContoBoldText,
  Typo24GrayContoMediumText,
  Typo14GrayContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const ListViewcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 8px 0px 8px;
  box-sizing: border-box;

  // overflow-x: scroll;

  // to remove the tile movement on hold and press in IOS (16.5), modified the overflow-x to hidden
  /* overflow-x: hidden; */

  padding-bottom: 12px;
`;

export const TourneyGameTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 80px;
  box-sizing: border-box;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : ""};

  background-size: 100% 84px;
  background-repeat: no-repeat;
  animation: fade-in 0.5s forwards;

  margin: 8px 0px 0px 0px;
  padding: 12px;
`;

export const TourneyTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 10px 12px 10px 12px; */
  box-sizing: border-box;
  padding: 0px 6px;
`;

export const TourneyNameAndDeadlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TourneyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const TourneyTitleAndEndTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TourneyGameIconsAndPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const TourneyTitleText = styled(Typo12OffWhiteContoBoldText)`
  line-height: normal;
  text-transform: capitalize;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TourneyTimerText = styled(Typo10SecondaryRedContoMediumText)`
  line-height: normal;
  color: ${(props) =>
    props.isLateRegistrationTourney ? colors.secondaryRed : colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GameTypeIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-start;
  align-items: center;

  img:first-child {
    margin-left: 0px;
  }
  img:last-child {
    margin-right: 0px;
  }
`;

export const GameTypeIconElement = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px 4px 0px 4px;
`;

export const GroupPlayerIconElement = styled.img`
  height: 10px;
  padding-left: 8px;
  padding-right: 4px;
`;

export const PlayersCountText = styled(Typo10GrayContoRegularText)`
  line-height: normal;
  margin-right: 4px;
`;

export const BonusBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  height: 6px;
  border-radius: 6px;
  /* background: ${colors.tourneyBonusBarPrimary}; */
  background: #101e23;

  align-items: center;
`;

export const BonusBarFilled = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) =>
    props.fillPercentage ? `${props.fillPercentage}%` : "0%"};
  height: 4px;
  border-radius: 6px;
  /* background: ${colors.tourneyBonusBarFilled}; */
  background: #26923b;

  margin: 1px;
`;

export const SeperatorLine = styled.span`
  height: 1px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 5px;
  background: ${colors.tourneyTileSeperator};
`;

export const PrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-start;
  margin-right: 24px;
`;

export const TourneyDetailItemTitleText = styled(Typo10GrayContoRegularText)`
  line-height: normal;
`;

export const WinningAmountText = styled(Typo12PrimaryGoldContoMediumText)``;

export const BuyInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin: 0px 24px;
`;

export const WinnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin-left: 24px;
`;

export const CTAButtonWrapper = styled.div`
  margin-left: auto;
  span {
    text-transform: uppercase;
  }
`;

export const DetailsSeperatorLine = styled.span`
  width: 1px;
  height: 16px;
  background: ${colors.tourneyDetailSeperator};
`;

export const TourneyDetailInfoText = styled(Typo12OffWhiteContoMediumText)`
  line-height: normal;
`;
export const TourneyRunningStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 0px 0px 12px 4px;
  height: 15px;
  min-height: 15px;
`;

export const LiveIcon = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${colors.primaryRed};
  margin-right: 6px;
`;

export const TourneyRunningStatusText = styled(Typo11OffWhiteContoBoldText)`
  line-height: normal;
  text-transform: capitalize;
`;

export const GoldOpenIcon = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${colors.leafyGreen} !important;
  margin-right: 6px;
`;

export const AnnouncementInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
  margin-top: 12px;
  box-sizing: border-box;
`;

export const AnnouncementInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: inherit;
`;

export const AnnouncementIconWrapper = styled.img`
  height: 108px;
  width: 108px;
  opacity: 0.8;
`;

export const FeaturedStarIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 6px;
`;

export const AnnouncementTitleText = styled(Typo24GrayContoMediumText)`
  margin-top: 8px;
`;

export const AnnouncementDescriptionText = styled(Typo14GrayContoMediumText)`
  margin-top: 8px;
`;

export const StatusTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
`;

export const StatusTypeIconWrapper = styled.img`
  margin-right: 4px;
`;
