import Handler from "../Handler";

class AutoFoldActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoFoldActionAck(receivedData);
  }
}

export default AutoFoldActionHandler;
