import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  ToolTipArrow,
  ToolTipContainer,
  ToolTipText,
  ToolTipWrapper,
} from "./toolTipStyles";

export const ToolTip = (props) => {
  const { autoCloseToolTipCallback } = props;
  const [active, setActive] = useState(true);
  const [autoCloseDuration, setAutoCloseDuration] = useState(3);
  const timeout = useRef(null);

  useEffect(() => {
    showToolTip();

    return () => clearTimeout(timeout.current);
  }, []);

  const showToolTip = () => {
    timeout.current = setTimeout(() => {
      hideToolTip();
    }, autoCloseDuration * 1000);
  };

  const hideToolTip = () => {
    clearTimeout(timeout.current);
    setActive(false);
    autoCloseToolTipCallback(false);
  };

  return (
    active && (
      <ToolTipContainer>
        <ToolTipArrow></ToolTipArrow>
        <ToolTipWrapper>
          <ToolTipText>
            Since you left the table with 210 chips, you’ll have to buy-in with
            at least that amount
          </ToolTipText>
        </ToolTipWrapper>
      </ToolTipContainer>
    )
  );
};
