import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import { TEXAS_HOLDEM } from "../../data/Constants";
import { motion } from 'framer-motion';

export const MiniGameTableContainer = styled.div`
  width: 25%;
  margin-left: 2%;
  /* max-width: 60px; */
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: ${(props) =>
    props.orientation === "LANDSCAPE" ? "60px" : "80px"};
  border-radius: 25px;
`;

export const MiniGameTableImage = styled.img`
  width: 100%;
`;

export const PlusIcon = styled.span`
  color: ${colors.primaryGold};
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%); //translate(-50%, -50%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  filter: ${(props) => (props.isActiveGameTable ? null : "brightness(0.5)")};
  overflow: hidden;
  /* background-color: red; */
`;

export const Card = styled(motion.img)`
  /* width: ${(props) => (props.gameType === TEXAS_HOLDEM ? 1.05 : 0.85)}rem; */
  height: ${(props) => (props.gameType === TEXAS_HOLDEM ? 70 : 53)}%;
  // width: auto;
  // height: 80%;
  margin: 0px 0px;
`;

export const DimCard = styled.img`
  width: auto;
  height: 60%;
  opacity: 0.5;
`;

export const MiniGameInfoContainer = styled(motion.div)`
  width: 80%;
  height: 70%;
  display: flex;
  position: absolute;
  /* background-color: red; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: ${(props) => (props.isActiveGameTable ? "1" : "0.5")};
`;

export const TableName = styled.span`
  white-space: nowrap;
  font-size: 0.45rem;
  font-weight: ${(props) => (props.isActiveGameTable ? "bold" : "normal")};
  color: white;
`;

export const TableStakes = styled.span`
  white-space: nowrap;
  font-size: ${(props) => (props.smallFont ? 0.4 : 0.67)}rem;
  font-weight: ${(props) => (props.isActiveGameTable ? "bold" : "normal")};
  color: ${colors.primaryGold};
  margin-top: 1%;
`;

export const MiniGameTableTimerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 5%;
  transform: translate(40%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.playerTimer <= 5 ? '#c62828' : 'green'};
  width: 15px;
  height: 15px;
  animation: fade-in 0.5s forwards;
`;

export const MiniGameTableTimerValue = styled.span`
  color: white;
  font-family: "Conto";
  font-size: 8px;
  font-weight: bold;
`;

export const WinnerLabel = styled(motion.div)`
  position: absolute;
  top: 90%;
  width: 80%;
  /* transform: translateY(-50%); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    #c62828;
  border-radius: 2px;
  font-family: "Conto";
  font-weight: bold;
  color: white;
  font-size: 8px;
  letter-spacing: 1px;
`;

export const TotalPotAmountContainer = styled(motion.div)`
  position: absolute;
  top: 90%;
  /* left: 50%; */
  /* transform: translate(-50%); */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: fit-content;
  height: 16px;
  border-radius: 25px;
  /* padding: 1px; */
  border: 1px solid rgba(51, 51, 50);
`;

export const TotalPotChipIcon = styled.img`
  height: 100%;
  width: auto;
`;

export const TotalPotValue = styled.span`
  color: white;
  font-family: "Conto";
  font-weight: bold;
  white-space: nowrap;
  font-size: 10px;
  margin: 0px 4px;
`;
