import { createSlice, current } from "@reduxjs/toolkit";
// import { cloneDeep } from "lodash";
import { PLAYER_STATS } from "../../data/Constants";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    visible: false,
    type: null,
    config: null,
    data: null,
    lobbyModal: {
      type: null,
      visible: false,
    },
  },
  reducers: {
    openModal: (state, action) => {
      // let newState = cloneDeep(current(state));
      state.visible = true;
      let data = action.payload;
      state.type = data.type;
      if (data.config) {
        state.config = data.config;
      } else {
        state.config = undefined;
      }
      if (data.type === PLAYER_STATS) {
        state.playerData = action.payload.playerData;
      } else {
        state.playerData = null;
      }
      if (action.payload?.data) {
        state.data = action.payload?.data;
      }
      // return newState;
    },
    closeModal: (state, action) => {
      // let newState = cloneDeep(current(state));
      state.visible = false;
      state.type = null;
      state.config = null;
      state.data = null;
      // return newState;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
