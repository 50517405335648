import Handler from "../Handler";

class PlayerStatsOnPlayerJoin extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    if (receivedData?.length >= 1) {
      this.service.processPlayerStatsData(receivedData);
    }
  }
}

export default PlayerStatsOnPlayerJoin;
