import { configureStore } from "@reduxjs/toolkit";
import lobbyReducer from "./slices/lobbySlice";
import gameReducer from "./slices/gameTableSlice";
import sliderReducer from "./slices/sliderSlice";
import modalReducer from "./slices/modalSlice";
import buttonReducer from "./slices/buttonSlice";
import alertReducer from "./slices/alertSlice";
import alertSlice from "./slices/alertSlice";

const store = configureStore({
  reducer: {
    lobby: lobbyReducer,
    modal: modalReducer,
    gameTable: gameReducer,
    slider: sliderReducer,
    button: buttonReducer,
    alert: alertSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
