import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonStyles = styled.button`
  width: 98%;
  max-width: ${(props) => props.orientation === 'LANDSCAPE' ? '150px' : null};
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  border-radius: 5px;
  outline: none;
  border-top: 1px solid ${(props) => props.topBorder};
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  color: ${colors.white};
  font-family: "Conto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#0000"};
  &:disabled{
      opacity:0.7;
    }
`;

export const StandByButtonStyles = styled.button`
  position: relative;
  width: 98%;
  height: 32px;
  max-width: ${(props) => props.orientation === 'LANDSCAPE' ? '150px' : null};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  border-radius: 5px;
  outline: none;
  border: 0;
  color: ${(props) =>
    props.active ? "#08D421" : "#7A98A2"};
  border: 1px solid
    ${(props) =>
      props.active ? "#42BE40" : "#416B7A"};
  font-family: "Conto";
  font-style: normal;
  font-weight: ${(props) => props.active ? 700 : 500};
  font-size: ${(props) => props.active ? '13px' : '12px'};
  line-height: 16px;
  background-color: black;
`;

export const ButtonIcon = styled.img`
  width: ${(props) => (props.label === "Raise" ? "12px" : "16px")};
  height: auto;
  margin-right: 8px;
`;

export const StandbyIcon = styled.div`
  height: 40%;
  width: 4px;
  background: ${(props) => props.active ? '#42BE40' : 'transparent'};
  position: absolute;
  left: 8px;
  border: 1px solid ${(props) => props.active ? '#42BE40' : "#416B7A"};
  border-radius: 6px;
`;
