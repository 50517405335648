import styled from "styled-components";
import { Typo10LightYellowContoBoldText } from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 70px;
  min-height: 70px;
  // border-top: 1px solid ${colors.secondaryBlue};
  box-shadow: 3px -3px 8px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;

  @media (min-width: 768px) {
    /* height: 120px; */
  }
`;

export const FooterItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 70px;
  min-height: 70px;
  /* white-space: wrap; */
  /* min-width: 25px; */
  /* max-width: 50px; */
  z-index: 3;

  @media (min-width: 768px) {
    /* height: 120px; */
  }
`;

export const FooterItemIconWrapper = styled.img`
  width: 28px;
  height: 28px;
  min-height: 28px;
`;

export const FooterItemTextWrapper = styled(Typo10LightYellowContoBoldText)`
  line-height: 14px;
  margin-top: 4px;
  font-size: 11px;
  color: ${(props) =>
    props.isActive ? colors.primaryGold : colors.lightYellow};
`;

export const ActiveGamesIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  background: #ff0026;
  border-radius: 14px;
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  top: -6px;
  right: -2px;
  // border: 1px solid #032146;
`;

export const FooterGameTableWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${colors.deepBlue};
  border-top: 0.5px solid ${colors.navy2};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.25s ease;
  transform: ${(props) =>
    props.active ? "translateY(-100%)" : "translateY(0%)"};
  opacity: ${(props) => (props.active ? "1" : "0")};
  margin-bottom: -4px;
  @media (min-width: 768px) {
    /* height: 120px; */
    height: ${(props) => (props.active ? "120px" : "100%")};
    /* top: ${(props) => (props.active ? 0 : null)}; */
    top: 0;
  }
`;

export const NewSymbol = styled.span`
  position: absolute;
  color: white;
  font-size: 8px;
  font-weight: bold;
  background-color: #c62928;
  padding: 2px 8px;
  border-radius: 10px;
  top: 10%;
  left: 50%;
`;

export const NumberOfGames = styled.div`
  background-color: #c62828;
  position: absolute;
  top: 15%;
  right: 20%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 26px;
  min-height: 26px;
  width: 26px;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  border-radius: 50%;
  border: ${(props) =>
    props.isActive
      ? ` 2px solid ${colors.primaryGold}`
      : ` 2px solid ${colors.offWhite}`};
`;

export const UserAvatarIcon = styled.img`
  width: 26px;
  height: 26px;
  min-height: 26px;
  border-radius: 50%;
`;

export const HamburgerIconWrapper = styled.img`
  height: 14px;
  min-height: 14px;
  width: 16px;
  position: absolute;
  z-index: 2;
  bottom: 28px;
  margin-left: 24px;
`;
