import GameConnections from "../../io/GameConnections";

let gameConnections = GameConnections.getInstance();

// class MessageToServer {

   const MessageToServerJSONAction = (PROTOCOLJSON, tempTableId, doCleanUp) => {
        let connection = gameConnections.getGameConnection(tempTableId);
        if(connection && connection.messageHandler){
            connection.messageHandler.sendMessage(PROTOCOLJSON);
        }
        // if(doCleanUp === true) connection.messageHandler.doCleanUp();
    }

  const  MessageToServerCloseConnection = (tempTableId) => {
        // let connection = gameConnections.getGameConnection(tempTableId);
        // connection.messageHandler.doCleanUp();
        gameConnections.removeGameConnection(tempTableId);
    }
// }

const MessageToServer = {
    MessageToServerJSONAction,
    MessageToServerCloseConnection
}

export default MessageToServer;