import Handler from "../Handler";

// extraTimeLeft#{
//     "playerName": "wise59999",
//     "extraTimeLeft": 4
// }
class ExtraTimeLeftHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processPlayerExtraTimeLeft(receivedData);
  }
}

export default ExtraTimeLeftHandler;