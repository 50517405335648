import {
  GAME_STAGE_FLOP,
  GAME_STAGE_RIVER,
  GAME_STAGE_SHOWDOWN,
  GAME_STAGE_TURN,
} from "../../data/Constants";
import Handler from "../Handler";

class RefreshHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    const { seatsProtocol, prizePotProtocol, userCards } = receivedData;
    let seatsProtocolData, prizepotData;
    seatsProtocolData = JSON.parse(seatsProtocol);
    if (prizePotProtocol) {
      prizepotData = JSON.parse(prizePotProtocol);
    }
    this.service.cleanTableUp();
    if (seatsProtocolData.gameStarted) {
      const {
        tableId,
        gameId,
        runningTablechair,
        flopCards,
        turnCard,
        riverCard,
        totalPotAmt,
        gameStarted,
        gameStage,
      } = seatsProtocolData;
      let cards = [];
      if (gameStage === GAME_STAGE_FLOP) {
        cards = [...flopCards, "cardBack", "cardBack"];
      } else if (gameStage === GAME_STAGE_TURN) {
        cards = [...flopCards, turnCard, "cardBack"];
      } else if (gameStage === GAME_STAGE_RIVER) {
        cards = [...flopCards, turnCard, riverCard];
      } else if (gameStage === GAME_STAGE_SHOWDOWN) {
        cards = [...flopCards, turnCard, riverCard];
      }
      this.service.processRunningTableChairs({
        gameStarted: gameStarted,
        tableId: tableId,
        gameId: gameId,
        playersData: runningTablechair,
        gameStage: gameStage,
        communityCards: [...cards],
        userCards: userCards,
      });
    } else {
      const { gameId, gameStarted, tableId, yetToRunTableChairs } =
        seatsProtocolData;
      this.service.processSeatsOnTable({
        gameStarted: gameStarted,
        tableId: tableId,
        gameId: gameId,
        playersData: yetToRunTableChairs,
      });
    }
    if (prizepotData) {
      this.service.processPrizePot(prizepotData);
    }
    this.service.processRefreshProtocolData(receivedData);
  }
}
export default RefreshHandler;
