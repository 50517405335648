import Handler from "../Handler";

class SitOutFailureHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processSitOutFailure(receivedData);
  }
}

export default SitOutFailureHandler;
