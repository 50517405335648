import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const SitOutTimer = ({
  deadline,
  showTimer,
  // timerStartCallback,
  timerEndCallback,
}) => {
  // const [hours, setHours] = useState(0);
  const [timerEnd, setTimerEnd] = useState(false);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const [time, setTime] = useState(deadline - currentLobbyTime);
  const intervalRef = useRef(null);

  const countDown = () => {
    setTime((prev) => Math.floor(prev - 1000));
    return
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => countDown(), 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    setMinutes(Math.max(0, Math.floor((time / 1000 / 60) % 60)));
    setSeconds(Math.max(0, Math.floor((time / 1000) % 60)));
    if (time === 0) {
      performEndingCallback();
    }
  }, [time]);

  const performEndingCallback = () => {
    timerEndCallback();
  };

  return (
    showTimer && (
      <>
        <span>You have </span>
        <div
          style={{
            color: "rgb(234, 191, 105)",
            margin: "0px 0px",
            width: "40px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {/* <span id="hour">{hours < 10 ? "0" + hours : hours}:</span> */}
          <span id="minute">{minutes < 10 ? "0" + minutes : minutes}:</span>
          <span id="second">{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
        <span> to join the game.</span>
      </>
    )
  );
};
