import Handler from "../Handler";

class TourneyCancelHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyCancel(receivedData);
  }
}

export default TourneyCancelHandler;

// TourneyCancel#
// {
//     "tourneyId": "accuj",
//     "tableId": "TOTCUJ270524-12011701942007122g",
//     "playerName": "dev03"
//     "refundType":"CANCELLED_WO_REFUND"
// }

// refundType possible values are
// CANCELLED_WO_REFUND
// CANCELLED_WITH_REFUND
