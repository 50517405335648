import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DISCONNECTION_TIMER,
  EXTRA_TIMER_SOUND,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  PLAYER_DEFAULT_TIMER,
  PLAYER_EXTRA_TIMER,
  TIMER_TYPE_PLAYER,
  VIBRATE_LIGHT,
} from "../../../Poker/data/Constants";
import { setPlayerRemainingTime } from "../../../Poker/redux/slices/gameTableSlice";
import colors from "../../styleGuide/Colors";
import GameAudio from "../../../Poker/audio/GameAudio";

export const TimerSVG = (props) => {
  const {
    playerPosition,
    type,
    timer,
    tempTableId,
    playerTimerType,
    playerTotalTime,
  } = props;

  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );

  const playerRemainingTime = useSelector(
    (state) =>
      state.gameTable.games[tempTableId]?.playerData?.find(
        (player) => player.position === playerPosition
      )?.remainingTime
  );

  const currentActiveGame = useSelector(
    (state) => state.gameTable.games[tempTableId]
  );

  const isPlaySoundEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.sound
  );

  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );

  const isHapticsEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.haptics
  );

  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameConnectionStatus
  );

  const showGameTable = useSelector((state) => state.gameTable.showGameTable);

  const [totalTime, setTotalTime] = useState(timer);
  const [strokePercentage, setstrokePercentage] = useState(0);
  const [strokeColor, setStrokeColor] = useState(null);
  const [transitionStyleProperty, setTransitionStyleProperty] =
    useState("stroke-dashoffset");
  const timeOut = useRef(null);
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  const r = 26;
  const circ = 2 * Math.PI * r;

  const baseValue = playerTotalTime * 10;

  let interval;

  useEffect(() => {
    setTotalTime(timer);
  }, [timer]);

  useEffect(() => {
    timerCountDown();

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeOut.current);
    };
  }, []);

  useEffect(() => {
    if (playerTimerType === PLAYER_DEFAULT_TIMER) {
      setTransitionStyleProperty("stroke-dashoffset");
    }
    if (
      totalTime !== 0 &&
      (playerTimerType === PLAYER_EXTRA_TIMER ||
        playerTimerType === DISCONNECTION_TIMER)
    ) {
      timeOut.current = setTimeout(() => {
        setstrokePercentage(0);
        setTotalTime(timer - totalTime);
      }, totalTime * 1000);
    } else {
      setTotalTime(timer);
    }

    // Note:  to clear the old timeouts and start a fresh timer whenever there is a change in timer type
    if (
      playerTimerType === PLAYER_EXTRA_TIMER ||
      playerTimerType === DISCONNECTION_TIMER
    ) {
      clearInterval(intervalRef.current);
      clearTimeout(timeOut.current);

      timerCountDown();
      setTransitionStyleProperty(null);
      timeOut.current = setTimeout(() => {
        setTransitionStyleProperty("stroke-dashoffset");
      }, 350);
    }
  }, [playerTimerType]);

  useEffect(() => {
    if (
      applicationVisibility &&
      gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS
    ) {
      if (totalTime !== playerRemainingTime && playerRemainingTime) {
        setstrokePercentage(
          ((baseValue - playerRemainingTime * 10) * circ) / baseValue
        );
        setTotalTime(playerRemainingTime);
      } else {
        setstrokePercentage(((baseValue - totalTime * 10) * circ) / baseValue);
      }
    }

    if (!applicationVisibility) {
      GameAudio.getInstance().stopSound();
      setstrokePercentage(0);
      setTransitionStyleProperty(null);
    } else {
      setTransitionStyleProperty("stroke-dashoffset");
    }
  }, [applicationVisibility, gameConnectionStatus]);

  useEffect(() => {
    if (totalTime >= 0) {
      setstrokePercentage(((baseValue - totalTime * 10) * circ) / baseValue);
    }

    if (totalTime === 0) {
      setStrokeColor("transparent");
    }

    dispatch(
      setPlayerRemainingTime({
        tempTableId: tempTableId,
        remainingTime: Number(totalTime),
        playerPosition: playerPosition,
      })
    );

    if (
      type === TIMER_TYPE_PLAYER &&
      playerTimerType === PLAYER_DEFAULT_TIMER &&
      Number(totalTime) === 5 &&
      applicationVisibility
    ) {
      if (currentActiveGame.playerSeat === currentActiveGame.playerTurn) {
        if (isHapticsEnabled) {
          GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
        }

        GameAudio.getInstance().playAudio(
          !isPlaySoundEnabled,
          EXTRA_TIMER_SOUND,
          true,
          false
        );
      }
    }
  }, [totalTime]);

  useEffect(() => {
    if (applicationVisibility) {
      // using this property for smooth animation
      if (strokePercentage <= 70) {
        setStrokeColor(colors.timerGreen);
      } else if (strokePercentage > 70 && strokePercentage < 90) {
        setStrokeColor(colors.timerOrange);
      } else {
        setStrokeColor(colors.timerRed);
      }
    }
  }, [strokePercentage]);

  useEffect(() => {
    // Setting it to player remaining time - 1 because table rotation change takes about 750ms to take place and re-arrange positions.
    if (playerRemainingTime) {
      setTotalTime(playerRemainingTime - 1);
    }
  }, [tableOrientation]);

  useEffect(() => {
    setTransitionStyleProperty(null);
    timeOut.current = setTimeout(() => {
      setTransitionStyleProperty("stroke-dashoffset");
    }, 250);
  }, [showGameTable]);

  const timerCountDown = () => {
    if (timer) {
      intervalRef.current = setInterval(() => {
        setTotalTime((prev) => (prev !== 0 ? prev - 1 : prev));
      }, 1000);
    }
  };

  return gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS ? (
    <svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      style={{
        position: "absolute",
      }}
    >
      <g>
        <circle
          style={{
            transition: `${transitionStyleProperty} 1s linear, stroke 0.3s linear`,
          }}
          r={r}
          cx={40}
          cy={40}
          fill={strokeColor}
          stroke={"#000000ba"}
          strokeWidth={r * 2}
          strokeDasharray={circ}
          strokeDashoffset={circ - strokePercentage}
          transform={`rotate(-90 40 40)`}
        ></circle>
        <text
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
          fill="#fff"
          fontSize={"18px"}
          fontWeight={'bold'}
        >
          {totalTime}
        </text>
      </g>
    </svg>
  ) : null;
};
