//TourneyAddOnWatchPlayerHandler.js

import Handler from "../Handler";

class TourneyAddOnWatchPlayerHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyAddOnWatchPlayer(receivedData);
  }
}

export default TourneyAddOnWatchPlayerHandler;
