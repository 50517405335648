import styled from "styled-components";
import colors from "../../styleGuide/Colors";
import {
  Typo12WhiteContoRegularText,
  Typo13DeepTealContoBoldText,
  Typo13DeepTealContoRegularText,
  Typo18WhiteContoBoldText,
} from "../../styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  width: 170px;
  height: ${(props) =>
    props.isPortraitMode ? "355px" : "calc(var(--app-height) - 50px)"};
  border-radius: 4px;
  padding: 10px 0px 10px 0px;
  z-index: 10;
  box-sizing: border-box;
  right: 2%;
  bottom: ${(props) => (props.isPortraitMode ? "7%" : "45px")};
  background: ${colors.firefly};
  fill: ${colors.firefly};
  stroke-width: 0.4px;
  stroke: ${colors.eastrenBlue59};
  border: 1px solid ${colors.eastrenBlue59};
  filter: drop-shadow(-2px -1px 2px rgba(0, 0, 0, 0.4));
`;

export const SelectedRaiseAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  //   border: 1px solid yellow;
`;

export const RaiseManualInput = styled.input`
  width: 65px;
  height: 30px;
  text-align: center;
  background: linear-gradient(
      180deg,
      rgba(52, 67, 90, 0.9) 0%,
      rgba(41, 53, 70, 1) 10%
    ),
    linear-gradient(180deg, #8da1e4 100%, #566cb6 100%);
  border: 0.5px solid #566cb6;
  box-shadow: 0px 1px 3px 0px #000000b2 inset;
  border-radius: 4px;
  color: ${colors.white};
  font-weight: 500;
  font-family: Conto;
  font-size: 14px;
  padding-left: 4px;
  &:focus {
    outline: none;
  }
`;

export const RaisePresetsAndSliderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const RaisePresetsWrapper = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  margin-top: 12px;
  padding: 0px 16px 0px 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-right: 1px solid ${colors.nileBlue};
  box-sizing: border-box;
`;

export const PresetsSeperatorLine = styled.img`
  width: 2px;
  height: 100%;
`;

export const RaiseSliderWrapper = styled.div`
  display: flex;
  width: 45%;
  height: 95%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 12px;
  overflow: none;
  align-items: center;
  position: relative;
  input[type="range"] {
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(270deg);
    -webkit-appearance: none;
    appearance: none;
    background: ${colors.rangeSliderUnfilled};
    border: 1px solid ${colors.eastBay};
    width: 280px;
    height: 16px;
    border-radius: 12.5px;
    background-image: ${colors.raiseSliderFilled};

    background-size: ${(props) =>
      props.sliderValue ? `${props.sliderValue}%  100%` : "0% 100%"};
    background-repeat: no-repeat;
    border: 0.8px solid ${colors.petrol};
    box-shadow: 0px 0px 0px 3px #000 inset;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 38px;
    width: 38px;
    background-color: transparent;
    background-image: ${(props) =>
      props.raiseThumb
        ? `url(${props.raiseThumb})`
        : colors.raiseSliderThumbColor};
    background-repeat: no-repeat;
    box-shadow: none !important;
    /* margin-top: 6px; */

    /* box-shadow: 0px 3px 3px 0px ${colors.black};
    transition: background 0.3s ease-in-out; */
  }
`;

export const RaiseSliderAndSliderMarkingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  height: 100%;
  /* border-left: 1px solid ${colors.blackPeral}; */
`;
export const RaisePresetButton = styled.button`
  width: 56px;
  height: 35px;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${colors.blueGreen}`
      : `0.6px solid ${colors.tealBlue}`};
  background: transparent;
  background: ${(props) =>
    props.isSelected
      ? colors.tealBlue
      : "linear-gradient(0deg, rgba(0, 0, 0, 0.39) 0%, rgba(0, 0, 0, 0.39) 100%), linear-gradient(0deg, #0E7792 0%, #0E7792 100%), #2F5968"};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "all")};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  white-space: no-wrap;
`;

export const PresetButtonText = styled(Typo12WhiteContoRegularText)`
  overflow: hidden;
  white-space: no-wrap;
  line-height: normal;
`;

export const RaiseSlider = styled.input`
  // rotate: 270deg;
  box-shadow: 4px 3px 1px 0px #000 inset;
`;

export const RaiseIncrementOrDecrementButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  background: linear-gradient(101.89deg, #15396e 16.3%, #3463af 98.26%);
  width: 25px;
  height: 25px;
  border-radius: 16px;
  border: none;
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const RaisePresitionButtonText = styled(Typo18WhiteContoBoldText)``;

export const RaiseSliderMarkingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 21px;
  height: 100%;
  justify-content: space-evenly;
  margin-left: 10px;
`;

export const RaiseSliderMarking = styled.span`
  display: flex;
  width: 21px;
  height: 1px;
  flex-shrink: 0;
  border-radius: 0px 1.5px 1.5px 0px;
  border: 0.1px solid ${colors.deepSeaGreen};
  background: ${colors.blueWhale};
  box-shadow: -0.3px 0.54px 0.2px 0px #000 inset;
  opacity: 0.6;
`;

export const RaiseSliderFloatingSelectedValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: auto;
  min-width: 35px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.saffronMango};
  border-radius: 20px;
  position: absolute;
  z-index: 11;
  padding: 0px 8px;
  bottom: 100%;
`;

export const RaiseValueText = styled(Typo13DeepTealContoBoldText)`
  line-height: normal;
  white-space: nowrap;
`;

export const RaiseActionInputContainer = styled.div`
  display: flex;
  width: 136px;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(180deg, #032932 0%, #021b21 100%);
  border: 0.6px solid #135364;
  box-shadow: 2px 2px 2px 0px #000000 inset;
  padding: 0px 12px 0px 12px;
  input {
    &:invalid {
      animation: text-shake 0.3s;
      color: ${colors.secondaryRed};
      font-size: bold !important;
    }

    @keyframes text-shake {
      25% {
        transform: translateX(4px);
      }
      75% {
        transform: translateX(-4px);
      }
      100% {
        transform: translateX(4px);
      }
    }
  }
`;

export const RaiseCustomInput = styled.input`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.isKeyboardOpened ? "100%" : "0px")};
  overflow: hidden;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${colors.putty};
  font-size: 14px;
  font-weight: 400 !important;
  box-sizing: border-box;
`;

export const RaiseEditIcon = styled.img`
  height: 12px;
  width: 12px;
`;

export const RaiseInputTooltip = styled(Typo12WhiteContoRegularText)`
  display: flex;
  background-color: ${colors.vampireGrey};
  color: white;
  position: absolute;
  min-width: 80%;
  max-width: auto;
  top: -15px;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #032932 0%, #021b21 100%);
  border: 0.6px solid #135364;
  white-space: nowrap;
  overflow: hidden;
`;

export const RaiseDisplayValue = styled.span`
  display: ${(props) => (props.isKeyboardOpened ? "none" : "flex")};
  flex-direction: row;
  width: 100%;

  color: ${colors.putty};
  font-size: 14px;
  font-weight: 400;
  margin-right: 16px;
  padding-left: 4px;
  box-sizing: border-box;
`;
