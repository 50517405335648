import React, { useEffect, useRef, useState } from "react";

import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  RebuyTimerContainer,
  TimerCircleWrapper,
  TimeText,
  RebuyMessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  SecondaryCTAButton,
  RebuyStatsContainer,
  EachStatWrapper,
  StatsLabelText,
  AmountWrapper,
  ColomText,
  AmountText,
  ChipsToBeGrantedText,
  LoadingContainer,
} from "./tourneyRebuyModalStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelAnimation,
  linear,
  playAnimation,
} from "../../../Common/components/Animations/Animation";
import colors from "../../../Common/styleGuide/Colors";
import { Loader } from "../Loader/Loader";
import {
  closeLocalGameModal,
  processTourneyRebuyCancle,
  submitTourneyRebuyRequest,
} from "../../redux/slices/gameTableSlice";

function TourneyRebuyModal(props) {
  const { tempTableId } = props;
  const dispatch = useDispatch();
  const circularProgressRef = useRef();
  const [rebuyTime, setRebuyTime] = useState(10);

  let frame, interval;
  const tourneyRebuyInDetails = useSelector(
    (state) => state.gameTable.games[tempTableId].tourneyRebuyInDetails
  );
  const isTourneyRebuyRequestLoading = useSelector(
    (state) => state.gameTable.games[tempTableId].isTourneyRebuyRequestLoading
  );
  const playerData = useSelector((state) => state.lobby.player);
  const channel = useSelector((state) => state.lobby.channel);

  useEffect(() => {
    startTimerAnimation(0, 10);
    if (rebuyTime) {
      interval = setInterval(() => {
        setRebuyTime((prev) => (prev !== 0 ? prev - 1 : prev));
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      cancelAnimation(frame);
    };
  }, []);

  const handleModalClose = (autoClose) => {
    if (!isTourneyRebuyRequestLoading) {
      dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
      if (!autoClose) {
        dispatch(
          processTourneyRebuyCancle({
            tempTableId: tempTableId,
            channel: channel,
            playerName: playerData.userName,
          })
        );
      }
    }
  };

  const startTimerAnimation = (offset, endTime) => {
    frame = playAnimation({
      duration: endTime * 1000,
      timing: (timeFraction) => linear(timeFraction),
      draw: (progress) => timeProgress(progress, offset),
    });
  };

  const timeProgress = (progress, offset) => {
    try {
      if (circularProgressRef.current !== null) {
        circularProgressRef.current.style.background = `conic-gradient(
         ${colors.oxfordBlue}, ${(progress + offset) * 100 * 3.6}deg, ${
          colors.shamRock
        } ${(progress + offset) * 10 * 3.6}deg
      )`;
        if (progress === 1) {
          handleModalClose(true);

          cancelAnimation(frame);
        }
      }
    } catch (err) {
      console.log("ERROR IN TOURNEY REBUYIN TIME PROGRESS", err);
    }
  };

  const submitRebuyRequest = (isRebuy, isDoubleRebuy) => {
    const requestPayload = {
      playerName: playerData.userName,
      userId: playerData.userId,
      channel: channel,
      reBuyIn: isRebuy,
      doubleReBuyIn: isDoubleRebuy,
    };
    dispatch(
      submitTourneyRebuyRequest({
        tempTableId: tempTableId,
        rebuyRequestPayload: requestPayload,
      })
    );
  };

  return (
    tourneyRebuyInDetails && (
      <Container
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ModalWrapper>
          <ModalHeader>
            <HeaderTitleText>Re-buy</HeaderTitleText>
          </ModalHeader>
          <ModalBody>
            <RebuyTimerContainer>
              <TimerCircleWrapper ref={circularProgressRef}>
                <TimeText>{rebuyTime}</TimeText>
              </TimerCircleWrapper>
            </RebuyTimerContainer>
            <RebuyMessageText>Would you like to Re-buy ?</RebuyMessageText>
            <RebuyStatsContainer>
              <EachStatWrapper>
                <StatsLabelText>Total Balance</StatsLabelText>
                <AmountWrapper>
                  <ColomText>:</ColomText>
                  <AmountText>{`₹${tourneyRebuyInDetails.totalBalance}`}</AmountText>
                </AmountWrapper>
              </EachStatWrapper>
              <EachStatWrapper>
                <StatsLabelText>Re-buy Amount</StatsLabelText>
                <AmountWrapper>
                  <ColomText>:</ColomText>
                  <AmountText>{`₹${tourneyRebuyInDetails.reBuyAmount}`}</AmountText>
                  <ChipsToBeGrantedText>{`(${tourneyRebuyInDetails.chipsToBeGranted} chips)`}</ChipsToBeGrantedText>
                </AmountWrapper>
              </EachStatWrapper>
              {tourneyRebuyInDetails.doubleRebuy && (
                <EachStatWrapper>
                  <StatsLabelText>Double Re-buy Amount</StatsLabelText>
                  <AmountWrapper>
                    <ColomText>:</ColomText>
                    <AmountText>{`₹${tourneyRebuyInDetails.doubleReBuyAmount}`}</AmountText>
                    <ChipsToBeGrantedText>{`(${tourneyRebuyInDetails.doubleChipsToBeGranted} chips)`}</ChipsToBeGrantedText>
                  </AmountWrapper>
                </EachStatWrapper>
              )}
            </RebuyStatsContainer>
            {isTourneyRebuyRequestLoading ? (
              <LoadingContainer>
                <Loader size={20} />
              </LoadingContainer>
            ) : (
              <CTAContainer>
                <PrimaryCTAButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    submitRebuyRequest(true, false);
                  }}
                >
                  <ButtonText>Re-buy</ButtonText>
                </PrimaryCTAButton>
                {tourneyRebuyInDetails.doubleRebuy && (
                  <SecondaryCTAButton
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      submitRebuyRequest(false, true);
                    }}
                  >
                    <ButtonText>Double Re-buy</ButtonText>
                  </SecondaryCTAButton>
                )}
              </CTAContainer>
            )}
          </ModalBody>
        </ModalWrapper>
      </Container>
    )
  );
}

export default TourneyRebuyModal;
