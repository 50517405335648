import Handler from "./../Handler";
// import * as Constants from "./../../data/Constants";

class LobbyDataHandler extends Handler {
  // eslint-disable-next-line no-useless-constructor
  constructor(service) {
    super(service);
  }
  execute(data) {
    // {"gameDefinitions":[
    // {"gameDefId":"0","gameType":"play","gameName":"Beginner Contest","bet":10.0,"winnersCount":2,"winningAmount":32.0,"maxPlayers":4,"firstPlayerWinningAmount":22.4,"secondPlayerWinningAmount":9.6,"noOfRounds":5,"noOfSkips":15,"onlinePlayerCnt":826,"bidTime":10,"extraTime":0,"startTime":5,"tableTime":10,"nextGameStartTime":10,"status":"open"}
    // ]}
    data = JSON.parse(data).gamePools;
    // this.service.processGameStake(data);
    // Commented out the bottom services, updating master data updates all collections. Master, Real & Fun.
    this.service.processLobbyGameDefinitionsMasterData(data);
    // this.service.processPlayStake(data);
    // this.service.processRealStake(data);
  }
}

export default LobbyDataHandler;
