import styled from "styled-components";
import { BUY_IN } from "../../../Poker/data/Constants";
import colors from "../../styleGuide/Colors";
import { Typo12WhiteContoBoldText } from "../../styleGuide/Typos";

export const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 61px;
  width: ${(props) => (props.location === BUY_IN ? "30%" : "auto")};
  height: ${(props) => (props.location === BUY_IN ? "40px" : "28px")};
  outline: none;
  border: none;
  background: ${(props) =>
    props.bgColor ? props.bgColor : colors.primaryGreen};
  border-radius: 4px;
  padding: 6px 12px;
  ${(props) =>
    props.location === BUY_IN
      ? `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  `
      : null}
`;

export const IconWrapper = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 4px;
`;

export const TextWrapper = styled(Typo12WhiteContoBoldText)`
  line-height: 16px;
  font-size: ${(props) => (props.location === BUY_IN ? "16px" : "auto")};
  font-size: 11px;
`;
