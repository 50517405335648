import React, { memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GameInfoButton } from "../../../Common/components/Buttons/GameTable/GameInfoButton";
import Game from "./Game";
import { GameTablesMainContainer } from "./MultiGameTableWrapperStyle";
import * as gameAssets from "../../routes/Game/gameAssets";
import closeIcon from "../../../../src/assets/common/CloseLightIcon.svg";
import {
  HeaderActionButtonsWrapper,
  MiniGameTableWrapper,
  PureRelativeWrapper,
} from "../../routes/Game/gameTableStyles";
import MiniGameTable from "../../components/MiniGameTable.js/MiniGameTable";
import AddGameTable from "../../components/MiniGameTable.js/AddGameTable";
import {
  updateActiveGame,
} from "../../redux/slices/gameTableSlice";
import { openSlider } from "../../redux/slices/sliderSlice";
import {
  CONSOLIDATED_MENU,
  DELETED_PLAYER,
  LANDSCAPE,
  TO_BE_DELETED_PLAYER,
} from "../../data/Constants";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import Slider from "../../../Common/components/Slider/Slider";
import {
  CloseIconWrapper,
  IconWrapper,
  InfoToastMessageText,
  InfoToastWrapper,
  ToastContainer,
} from "../../../Common/components/Toast/toastStyle";
import infoIcon from "../../../../src/assets/common/InfoIcon.svg";
import { removeRakebackToast } from "../../redux/slices/lobbySlice";

const GameTables = ({
  gameToLobbyCallBack,
  accessToken,
  activeGamesLength,
  playerStatus,
}) => {
  const dispatch = useDispatch();
  const activeGames = useSelector((state) =>
    Object.keys(state.gameTable.games)
  );
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const activeGameData = useSelector(
    (state) => state.gameTable.games[activeGame]
  );
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const showRakebackToast = useSelector(
    (state) => state.lobby.showRakebackToast
  );
  const canUserSwipe = useSelector((state) => state.gameTable.canUserSwipe);
  const masterGameDefinitionKeys = useSelector((state) =>
    Object.keys(state.lobby.masterGameDefinitions)
  );
  const sliderRef = useRef(null);
  const sliderStatus = useSelector((state) => state.slider.visible);
  const sliderType = useSelector((state) => state.slider.type);

  const createCommonActionButtons = () => {
    return (
      !activeGameData?.showMaintenancePopup && (
        <>
          <HeaderActionButtonsWrapper id="HeaderActionButtonsWrapper">
            <PureRelativeWrapper>
              <GameInfoButton
                clickHandler={() => onHamburgerMenuClick()}
                iconSrc={gameAssets.hamburgerMenu}
                width="unset"
                position="unset"
                id="hamburgerMenu"
              ></GameInfoButton>
            </PureRelativeWrapper>
            {createMultiGameTables()}
          </HeaderActionButtonsWrapper>
        </>
      )
    );
  };

  const createMultiGameTables = () => {
    return (
      <MiniGameTableWrapper
        orientation={gameOrientation}
        id="mini-gametables-wrapper"
      >
        {activeGames.map((tempTableId, index) => {
          return (
            <MiniGameTable
              id={tempTableId}
              isLobby={false}
              tableNumber={tempTableId}
              activeGame={activeGame}
              key={index + "-miniGameTable-" + tempTableId}
            />
          );
        })}
        {/* Making sure there are game tiles in the lobby to show the plus button */}
        {playerStatus !== DELETED_PLAYER &&
        playerStatus !== TO_BE_DELETED_PLAYER &&
        activeGames.length < 4 &&
        masterGameDefinitionKeys.length > 0 ? (
          <AddGameTable gameToLobbyCallBack={gameToLobbyCallBack} />
        ) : null}
      </MiniGameTableWrapper>
    );
  };

  const onHamburgerMenuClick = () => {
    dispatch(openSlider({ type: CONSOLIDATED_MENU }));
  };

  const changeActiveGame = (realIndex) => {
    let tempTableId = activeGames[realIndex];
    dispatch(updateActiveGame(tempTableId));
    return;
  };

  return (
    activeGamesLength && (
      <GameTablesMainContainer>
        {showRakebackToast && (
          <ToastContainer
            isLandscapeOrientation={gameOrientation === LANDSCAPE}
          >
            <InfoToastWrapper>
              <IconWrapper src={infoIcon} />
              <InfoToastMessageText>
                Congrats! You can now claim rakeback winnings
              </InfoToastMessageText>
              <CloseIconWrapper
                src={closeIcon}
                onClick={() => {
                  dispatch(removeRakebackToast());
                }}
              />
            </InfoToastWrapper>
          </ToastContainer>
        )}
        {sliderStatus && sliderType ? <Slider /> : null}
        {createCommonActionButtons()}
        <Swiper
          initialSlide={
            activeGames.indexOf(activeGame) >= 0
              ? activeGames.indexOf(activeGame)
              : 0
          }
          loop={true}
          simulateTouch={activeGames.length > 1}
          slidesPerView={1}
          onRealIndexChange={(details) => changeActiveGame(details?.realIndex)}
          ref={sliderRef}
        >
          {activeGames.map((tempTableId, index) => {
            return (
              <SwiperSlide
                key={"GameRevamp-" + tempTableId}
              >
                <Game
                  token={accessToken}
                  gameToLobbyCallBack={gameToLobbyCallBack}
                  tempTableId={tempTableId}
                  activeGame={activeGame}
                  activeGames={activeGames}
                  canUserSwipe={canUserSwipe}
                  numberOfGames={activeGames.length}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </GameTablesMainContainer>
    )
  );
};

export default memo(GameTables);
