import Handler from "../Handler";

class TopupBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTopupBroadcast(receivedData);
  }
}

export default TopupBroadcastHandler;

// TopUpB#{playerName:"abc", atTableAmount:"123.33"}
