import Handler from "../Handler";

class TourneyStartHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyStart(receivedData);
  }
}
export default TourneyStartHandler;

//tourneyStart#{"tourneyRegistrationCountMap":{"7Tf9N":0,"3QrnK":0,"oDKvy":0,"s6fJW":0}}
