import Handler from "../Handler";

class RebuyInHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processRebuyIn(receivedData);
  }
}

export default RebuyInHandler;

// RebuyIn#{"playerName":"testuser","minBuyInAmount":900.0,"maxBuyInAmount":9000.0,"endTime":10}
