import Handler from "../Handler";

class TourneyRebuyBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyRebuyBroadcast(receivedData);
  }
}
export default TourneyRebuyBroadcastHandler;
// TRebuyBroadcast#{playerName;
// private String tableId;}
