import Handler from "../Handler";

class AutoCallAnyActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoCallAnyActionAck(receivedData);
  }
}

export default AutoCallAnyActionHandler;
