import React from "react";
import {
    GlobalModal,
    ModalBody,
    ModalContainer,
    ModalHeader,
    ModalWrapper,
    RelativeWrapper,
} from "../../Modals/modalStyle.js";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos.js";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil.js";
import { ModalText, OKButton } from "../ForceLogout/ForceLogoutStyles";

const AssetError = (props) => {
    return (
        <GlobalModal>
            <ModalContainer>
                <RelativeWrapper>
                    <ModalWrapper>
                        <ModalHeader>
                            <Typo16WhiteContoBoldText>Info</Typo16WhiteContoBoldText>
                        </ModalHeader>
                        <ModalBody>
                            <ModalText bold={true}>
                                Looks like a hiccup due to network issues. Please try again.
                            </ModalText>
                            <OKButton
                                onClick={
                                    () => window.navigator.onLine ? window.location.reload() : messageToNativeClient({ type: "forceLogout" })
                                }
                            >
                                Retry
                            </OKButton>
                        </ModalBody>
                    </ModalWrapper>
                </RelativeWrapper>
            </ModalContainer>
        </GlobalModal>
    );
};

export default AssetError;
