import Handler from "../Handler";

class AutoRebuyBroadcastHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoRebuyBroadcast(receivedData);
  }
}

export default AutoRebuyBroadcastHandler;
// AutoRebuyInB#{"status":"SUCCESS","playerName":"angel459889","Amount":900.0} 1695626627.2673745
