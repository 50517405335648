import Handler from "../Handler";

class TourneyStartNodeSwitchHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyStartNodeSwitch(receivedData);
  }
}
export default TourneyStartNodeSwitchHandler;

// TourneyStartNodeSwitch#
// private String tourneyId;
// private String playerName;
// private String tableId;
// private String gameId;
// private int levelCount;
// private String domainName;
// private String nodeIp;
