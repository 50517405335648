import Handler from "../Handler";

class TourneyRebuyEndedHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTourneyRebuyEnded(receivedData);
  }
}
export default TourneyRebuyEndedHandler;

// TourneyRebuyEnded#{"tourneyId":"qXOvQ","tableId":"TOTOVQ100424-181351919288fgh5k","message":"Rebuy timer ended because timer ended"}
