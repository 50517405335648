import Handler from "../Handler";

class ConnectAckHandler extends Handler {
  execute(message) {
    // ConA#{"gameId":"310723-3921353mfwp2","playerName":"hammer241661","profilePic":"https://images.fanfight.com/avatars/avatar1.png","tableId":"THD310723-3921353rojih"}

    // if (message && message.length > 0) {
    //   let details = JSON.parse(message);

    //   this.service.initGame(details);
    // }

    //for now, not using any data
    this.service.initGame();
  }
}

export default ConnectAckHandler;
