import Handler from "../Handler";

class AutoCheckFoldActionHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAutoCheckFoldActionAck(receivedData);
  }
}

export default AutoCheckFoldActionHandler;
