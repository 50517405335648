import React from "react";
import {
  GlobalModal,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  RelativeWrapper,
} from "../../Modals/modalStyle.js";
import { Typo16WhiteContoBoldText } from "../../../styleGuide/Typos.js";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil.js";
import { ModalText, OKButton } from "./ForceLogoutStyles.js";

const ForceLogoutModal = (props) => {
  return (
    <GlobalModal>
      <ModalContainer>
        <RelativeWrapper>
          <ModalWrapper>
            <ModalHeader>
              <Typo16WhiteContoBoldText>Error</Typo16WhiteContoBoldText>
            </ModalHeader>
            <ModalBody>
              <ModalText bold={true}>
                We're sorry, but it seems there was an issue with your session.
                Please log in again to continue.
              </ModalText>
              {/* <div style={{color:'white', textAlign:'center',marginBottom:'20px'}}>Sorry for inconvenience, please try again later</div> */}
              <OKButton
                onClick={() => {
                  messageToNativeClient({ type: "forceLogout" });
                }}
              >
                Log out
              </OKButton>
            </ModalBody>
          </ModalWrapper>
        </RelativeWrapper>
      </ModalContainer>
    </GlobalModal>
  );
};

export default ForceLogoutModal;
