//TourneyCompleteHandler.js

import Handler from "../Handler";

class TourneyGameCompleteHandler extends Handler {
  execute(message) {
    //added a check based on a crash scenario where tourney id key is not received in this game protocol
    let receivedData = message ? JSON.parse(message) : undefined;
    this.service.processTourneyGameComplete(receivedData);
  }
}
export default TourneyGameCompleteHandler;

// tourneycomplete#{"tourneyId":"fV8so"}
