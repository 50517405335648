import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo12GrayContoRegularText,
  Typo12WhiteContoRegularText,
  Typo14WhiteContoBoldText,
  Typo14WhiteContoMediumText,
  Typo14WhiteContoRegularText,
  Typo16WhiteContoBoldText,
  Typo16WhiteContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  background-color: ${colors.black40};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  min-height: 140px;
  height: auto;
  border-radius: 10px;
  background: ${colors.confirmPopupBackground};
  animation: springIn 0.4s forwards;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.deepTeal60};
`;

export const HeaderTitleText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px 20px 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const RebuyTimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${colors.oxfordBlue};
`;

export const TimerCircleWrapper = styled.div`
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 3px solid ${colors.shamRock}; */

  margin: 2px;
  box-sizing: border-box;
`;

export const TimeText = styled(Typo16WhiteContoMediumText)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  background-color: ${colors.oxfordBlue};
`;

export const RebuyMessageText = styled(Typo14WhiteContoMediumText)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const RebuyStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 36px;
`;
export const EachStatWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  justify-content: space-between;
`;

export const StatsLabelText = styled(Typo12WhiteContoRegularText)`
  width: 50%;
  font-size: 14px;
`;

export const AmountWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 6px;
  box-sizing: border-box;
`;

export const ColomText = styled(Typo12WhiteContoRegularText)`
  margin-right: 36px;
`;

export const AmountText = styled(Typo12WhiteContoRegularText)`
  margin-right: 2px;
  font-size: 14px;
`;

export const ChipsToBeGrantedText = styled(Typo12GrayContoRegularText)`
  margin-left: 2px;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const PrimaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.dragonGreen};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmPrimaryCTAbackground};
  border-radius: 5px;
`;

export const SecondaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: 2px solid ${colors.white};
  background: transparent;
  border-radius: 4px;
`;

export const ButtonText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33px;
`;
