import cardBack from "./card-back.png";
import cb1 from "./Carddeck1.png";
import cb2 from "./Carddeck2.png";
import cb3 from "./Carddeck3.png";
import cb4 from "./Carddeck4.png";

const CardImages = {
  CB1: cb1,
  CB2: cb2,
  CB3: cb3,
  CB4: cb4,
  cardBack: cardBack,
};

function preloadImage(src) {
  src.toString();
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export { CardImages, preloadImage }
