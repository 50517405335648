import Handler from "../Handler";

class ReloginConnectionAckHandler extends Handler {
  execute(message) {
    console.log("in relogin connection ack GAME:", message);
    let receivedData = message && message.length > 0 ? JSON.parse(message) : {};
    console.log("Hitting service method:", receivedData);
    this.service.processReloginConnectionAck(receivedData);
  }
}
export default ReloginConnectionAckHandler;
