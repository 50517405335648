import Handler from "../Handler";
class ResultHandler extends Handler {
  execute(data) {
    // Result#
    let message = JSON.parse(data);
    this.service.processResult(message);
  }
}

export default ResultHandler;

// Result#{
//   "resultBroadcasts": [
//       {
//           "playerName": "guru",
//           "rankDetails": {
//               "longDescription": "High Card (Ace,King,Queen,Nine,Six)",
//               "winningHand": "d1,c13,h12,d9,d6",
//               "rank": "0",
//               "description": "High Card (Ace)",
//               "rankCards": "",
//               "handType": "Nothing",
//               "points": "973974"
//           },
//           "userCards": [
//               "d1",
//               "d9"
//           ]
//       },
//       {
//           "playerName": "lord25697",
//           "rankDetails": {
//               "longDescription": "Pair of Kings (plus Queen,Jack,Six)",
//               "winningHand": "s13,c13,h12,d11,d6",
//               "rank": "1",
//               "description": "Pair of Kings",
//               "rankCards": "s13,c13",
//               "handType": "Pair",
//               "points": "1957046"
//           },
//           "userCards": [
//               "d11",
//               "s13"
//           ]
//       }
//   ]
// }