import React, { useEffect, useRef, useState } from "react";
import {
  Action,
  BottomText,
  GameTileBottomLeftText,
  GameTypeIconElement,
  GreenArrow,
  GridIcon,
  GridImage,
  Header,
  HeaderIcon,
  HomePageContainer,
  HomePageTitle,
  HorizontalBannersContainer,
  HorizontalCard,
  HorizontalImage,
  HowToPlayContainer,
  HowToPlayPokerBanner,
  IconsGridContainer,
  KnowMoreIcon,
  KnowMoreOption,
  KnowMoreOptionArrow,
  KnowMoreOptionName,
  KnowMoreSectionContainer,
  LeaderboardDetails,
  LeaderboardDetailsDivider,
  LeaderboardSubdetails,
  LeftSection,
  LoadingGridIcon,
  MissionDetailsText,
  MissionsDetails,
  NewRecommendedSwipeCard,
  NoScrollContainer,
  PlayerCornerCardSubtitle,
  PlayerCornerCardTitle,
  PlayersCornerCard,
  PlayersCornerCardCTA,
  PlayersCornerCardCTAContainer,
  PlayersCornerCardImage,
  PlayersCornerCardImageContainer,
  PlayersCornerCardInfo,
  PlayersCornerCardLeftConnector,
  PlayersCornerSection,
  PlayersCornerTrackerSection,
  PromotionalBannerContainer,
  PromotionalBannersContainer,
  PromotionalBannerSkeleton,
  PromotionsCard,
  PromotionsImage,
  RecommendedActionButton,
  RecommendedCardCTA,
  RecommendedCardLeft,
  RecommendedCardRight,
  RecommendedOnlineContainer,
  RecommendedSwipeCard,
  RecommendedText,
  RecommendedType,
  ReferActions,
  ReferBanner,
  ReferContainer,
  ReferContent,
  ReferCTA,
  ReferImage,
  ReferText,
  RightSection,
  RightSectionContentContainer,
  RightSectionProgressAmount,
  RightSectionProgressContainer,
  RightSectionProgressInnerContainer,
  RightSectionText,
  SectionContainer,
  SubtitleText,
  SwipableContainer,
  SwipeCard,
  TopBannerSkeleton,
  TopText,
  TrustSectionContainer,
  TrustText,
  TypeText,
} from "./HomeStyle";
// import homeIcon from "../../../assets/Home/dummyHomeIcon.png";
import progress from "../../../assets/Home/progressAsset.svg";
// import greenArrow from "../../../assets/Home/greenArrow.png";
// import missionTarget from "../../../assets/Home/missions.png";
// import lbHome from "../../../assets/Home/lbHome.png";
import recommended from "../../../assets/Home/RecommendedIcon.svg";
import promotions from "../../../assets/Home/promotions.svg";
import referAndEarn from "../../../assets/Home/referIcon.svg";
// import copyLogo from "../../../assets/Home/CopyLogo.svg";
// import shareLogo from "../../../assets/Home/ShareLogo.svg";
import knowMore from "../../../assets/Home/KnowMoreIcon.svg";
import rightArrow from "../../../assets/Home/rightArrow.svg";
import learnPoker from "../../../assets/Home/learnPoker.png";
import faqIcon from "../../../assets/Home/FAQs.svg";
import tandc from "../../../assets/Home/tandc.svg";
import trustImage from "../../../assets/Home/user-trust.svg";
// import successIcon from "../../../assets/common/SuccessIcon.svg";
import RakebackImage from "../../../assets/Home/RakebackImage.png";
import MissionsImage from "../../../assets/Home/MissionsImage.png";
import LeaderboardImage from "../../../assets/Home/LeaderboardImage.png";
import ReferAndEarn from "../../../assets/Home/ReferAndEarn.png";
import HowToPlayPoker from "../../../assets/Home/howToPlayPoker.jpeg";
import defaultH1 from "../../../assets/Home/defaultH1.png";
import defaultH2 from "../../../assets/Home/defaultH2.png";
// import defaultH3 from "../../../assets/Home/defaultH3.png";
import defaultP1 from "../../../assets/Home/defaultP1.png";
import defaultP2 from "../../../assets/Home/defaultP2New.png";
import defaultP3 from "../../../assets/Home/defaultP3.png";
// import multiPlayersIcon from "../../../assets/lobby/MultiplePlayersIcon.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import {
  FUN_CHANNELS,
  RESTRICTED_LOCATION,
  RSA_FAILURE_STATUS_ERROR_CODES,
  RUPEE_SYMBOL,
  VIBRATE_LIGHT,
} from "../../data/Constants";
import colors from "../../../Common/styleGuide/Colors";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import {
  clearDeeplink,
  handleDeeplink,
  sendLobbyDebugLog,
  updateBottomBanners,
  updateGridTiles,
  updateHomePageRecommendedData,
  updateTopBanners,
} from "../../redux/slices/lobbySlice";

import "swiper/css";
import "swiper/css/autoplay";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import {
  PR_Click_Maintile,
  PR_Click_Recomndtile,
  PR_Lobby_Banner,
  PR_Lobby_IPromotions,
  PR_Lobby_referNearn,
} from "../../data/ClevertapConstants";
import GameAudio from "../../audio/GameAudio";
import {
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import GridImageComponent from "./GridImage";
import ActiveGamesStickyButton from "./ActiveGamesStickyButton";
import { GreenCircle } from "../../components/LobbyGameTilesList/styledComponents";
import { openModal } from "../../redux/slices/modalSlice";
import { sendLobbyDebugProtocol } from "../../utils/ProtocolUtils";
import { encryptAPIPayload } from "../../../Common/utils/EncryptionUtil";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
SwiperCore.use([Autoplay]);

const Home = ({ gameTileClick, tileScrollStatus, toggleGameTable }) => {
  const dispatch = useDispatch();
  const {
    rakeback,
    leaderboard,
    missions,
    dataUpdated,
    horizontalBanners,
    CTPromotionalBanners,
    recommendedGames,
    topLoadedBanners,
    bottomLoadedBanners,
    gridTilesData,
  } = useSelector((state) => state.lobby.homeData);
  // const [loading, setLoading] = useState(true);
  const [homeData, setHomeData] = useState(null);
  const defaultHorizontalBanners = [
    {
      imageUrl: defaultH1,
      redirectionData: {
        Game: "Poker",
        Tab: "Active",
        SubTab: "All",
        Location: "TourneyLobby",
      },
      rank: 1,
    },
    {
      imageUrl: defaultH2,
      redirectionData: {
        Tab: "openRakeBack",
        Game: "Poker",
        Location: "openRakeBack",
      },
      rank: 2,
    },
    // NOTE: Removed defaultH3 Banner as per product requirement. confirmed by vineeth.
    // {
    //   imageUrl: defaultH3,
    //   redirectionData: {
    //     tourneyId: "",
    //     Game: "Poker",
    //     lbId: "",
    //     Tab: "Allwinning",
    //     SubTab: "",
    //     Location: "openPokerLeaderboard",
    //   },
    //   rank: 3,
    // },
  ];
  const defaultPromotionalBanners = [
    {
      imageUrl: defaultP1,
      redirectionData: {
        Game: "Poker",
        Tab: "Active",
        SubTab: "All",
        Location: "TourneyLobby",
      },
      rank: 1,
    },
    {
      imageUrl: defaultP2,
      redirectionData: {
        Game: "Poker",
        Tab: "Active",
        SubTab: "All",
        Location: "TourneyLobby",
      },
      rank: 2,
    },
    {
      imageUrl: defaultP3,
      redirectionData: {
        Tab: "openRakeBack",
        Game: "Poker",
        Location: "openRakeBack",
      },
      rank: 3,
    },
  ];
  const PlayersCornerArray = [
    // {
    //   type: "Rakeback",
    //   image: RakebackImage,
    // },
    {
      type: "Missions",
      image: MissionsImage,
    },
    {
      type: "Leaderboard",
      image: LeaderboardImage,
    },
  ];
  const [playersCornerArrayNew, setPlayersCornerArrayNew] =
    useState(PlayersCornerArray);
  const [topBanners, setTopBanners] = useState(
    topLoadedBanners.length > 0 ? topLoadedBanners : defaultHorizontalBanners
  );
  const [topBannersError, setTopBannersError] = useState(false);
  const [topAdminBanners, setTopAdminBanners] = useState([]);
  const [loadingTopBanners, setLoadingTopBanners] = useState(
    topLoadedBanners.length > 0 ? false : topBanners.length > 0 ? false : true
  );
  const [gridTiles, setGridTiles] = useState(
    gridTilesData.length > 0 ? gridTilesData : []
  );
  const [loadingGridTiles, setLoadingGridTiles] = useState(
    gridTilesData.length > 0 ? false : true
  );
  const [loadingRecommendedData, setLoadingRecommendedData] = useState(
    recommendedGames.length === 0 ? true : false
  );
  const [referCodeCopied, setReferCodeCopied] = useState(false);
  const [promotionalBanners, setPromotionalBanners] = useState(
    bottomLoadedBanners.length > 0
      ? bottomLoadedBanners.concat(defaultPromotionalBanners)
      : defaultPromotionalBanners
  );
  const [promotionalBannersError, setPromotionalBannersError] = useState(false);
  const [promotionalAdminBanners, setPromotionalAdminBanners] = useState([]);
  const [loadingPromotionalBanners, setLoadingPromotionalBanners] = useState(
    bottomLoadedBanners.length > 0 ? false : true
  );
  const [apiError, setApiError] = useState(false);
  const errorAPICount = useRef(0);
  const userId = useSelector((state) => state.lobby.player.userId);
  const hideRakeBack = useSelector((state) => state.lobby.hideRakeBack);
  const userName = useSelector((state) => state.lobby.player.userName);
  const isAllowLocation = useSelector(
    (state) => state.lobby.player.isAllowLocation
  );
  const channel = useSelector((state) => state.lobby.channel);
  const referCode = useSelector((state) => state.lobby.player.referCode);
  const receivedLobbyData = useSelector(
    (state) => state.lobby.receivedLobbyData
  );
  const isHapticsEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.haptics
  );
  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );
  const accessToken = useSelector((state) => state.lobby.accessToken);

  const dummy6 = [1, 2, 3, 4, 5, 6];
  const dummy3 = [1, 2, 3];
  const dummy2 = [1, 2];

  useEffect(() => {
    if (!hideRakeBack) {
      let rakBackObj = {
        type: "Rakeback",
        image: RakebackImage,
      };
      setPlayersCornerArrayNew([rakBackObj, ...PlayersCornerArray]);
    } else {
      setPlayersCornerArrayNew(PlayersCornerArray);
    }

    if (hideRakeBack) {
      if (topBanners.length > 0) {
        let filteredNonRakebackItems = topBanners.filter(
          (item) =>
            item.redirectionData?.Location?.toLowerCase() != "openrakeback"
        );
        setTopBanners(filteredNonRakebackItems);
      }
      if (promotionalBanners.length > 0) {
        let filteredPromotionalBanners = promotionalBanners.filter(
          (item) =>
            item.redirectionData?.Location?.toLowerCase() != "openrakeback"
        );
        setPromotionalBanners(filteredPromotionalBanners);
      }
    }
  }, [hideRakeBack]);

  const fetchHomePageAPI = async () => {
    let url = process.env.REACT_APP_HOME_PAGE_REQUEST;
    let headerVar = {
      headers: {
        Authorization: accessToken,
      },
    };

    let payload = {
      channel: channel,
    };
    try {
      let result = await axios.post(url, payload, headerVar);
      console.log("check_result_home ", result);
      let newLobbyData = result?.data?.message?.newLobbyData;
      if (newLobbyData) {
        // TILES DATA
        let tilesData = newLobbyData.find((item) => item.type === "tiles");

        if (tilesData) {
          // Sorting based on 'Type' key and limiting the number of items to 3 based on new Figma Design 16/05/24
          tilesData.data = tilesData.data
            // Removed the sorting by type as it is done by Rank from backend side before receiving it.
            // .sort((a, b) => a.type - b.type)
            .filter((item, index) => index < 3);
          dispatch(updateGridTiles({ gridTiles: tilesData?.data ?? [] }));
          setGridTiles(tilesData?.data ?? []);
        }
        setLoadingGridTiles(false);
        let recommendedData = newLobbyData.find(
          (item) => item.type === "recommended"
        );
        if (recommendedData) {
          dispatch(
            updateHomePageRecommendedData({
              lbGameDefIds: recommendedData.data,
            })
          );
        }
        setLoadingRecommendedData(false);
        // TOP BANNERS DATA
        let topBannersData = newLobbyData.find(
          (item) => item.type === "banners"
        );
        if (topBannersData) {
          setTopAdminBanners(topBannersData?.data.homeLobby);
        }
        // PROMOTIONAL BANNERS DATA
        let promotionalBannersData = newLobbyData.find(
          (item) => item.type === "promotions"
        );
        if (promotionalBannersData) {
          setPromotionalAdminBanners(promotionalBannersData?.data);
        }
        setHomeData(newLobbyData);
      }
      errorAPICount.current = 0;

      if (
        result?.data?.errorCode &&
        RSA_FAILURE_STATUS_ERROR_CODES.includes(result?.data?.errorCode)
      ) {
        sendLobbyDebugLog({
          type: `ENCRYPTION_FAILED_WITH_CODE - ${result?.data?.errorCode}`,
          responsePayload: result?.data,
        });
      }
    } catch (err) {
      console.log("Error in home page data ", err, url, payload);
      sendLobbyDebugProtocol({
        type: "fetchHomePageAPIError",
        error: err,
      });
      errorAPICount.current += 1;
      if (errorAPICount.current < 3) {
        fetchHomePageAPI();
      }
    }
    return;
  };

  const handleBannerClick = (item, index) => {
    CleverTapUtil.getInstance().logEvent(
      (item?.displayType?.includes("promo")
        ? PR_Lobby_IPromotions
        : PR_Lobby_Banner) +
        (index + 1),
      {
        Username: userName,
        Channel: channel,
        Timestamp: getISTDateTimeFormat(),
        RedirectionName: item?.redirectionData?.Location,
      }
    );
    if (item.Location) {
      dispatch(handleDeeplink({ deepLink: item }));
    } else if (item.redirectionData) {
      if (
        item?.redirectionData?.Location?.toLowerCase() ===
        "redirectioninsideapp"
      ) {
        let webUrl = item?.redirectionData?.webUrl;
        if (webUrl) {
          messageToNativeClient({
            type: "redirection",
            kind: "internal",
            url: webUrl,
          });
          return;
        }
      }
      let newRedirectionObject = {};
      let redirectionObjectKeys = Object.keys(item.redirectionData);
      for (let key of redirectionObjectKeys) {
        newRedirectionObject[key] = item.redirectionData[key].replace(
          /\s/g,
          ""
        );
        // console.log("New Item is ", key, newRedirectionObject[key]);
      }
      dispatch(handleDeeplink({ deepLink: newRedirectionObject }));
    }
  };

  useEffect(() => {
    messageToNativeClient({ type: "homepage" });
  }, []);

  useEffect(() => {
    errorAPICount.current = 0;
    if (userId && receivedLobbyData && applicationVisibility) {
      // Should fetch home page API only after userID exists and LD# is received to show recommended tiles.
      fetchHomePageAPI();
    }
  }, [userId, receivedLobbyData, applicationVisibility]);

  useEffect(() => {
    if (dataUpdated && homeData) {
      if (horizontalBanners.length > 0) {
        setTopBanners(horizontalBanners);
        dispatch(updateTopBanners({ topBanners: horizontalBanners }));
      } else {
        setTopBanners(
          topAdminBanners.length > 0
            ? topAdminBanners
            : defaultHorizontalBanners
        );
        dispatch(
          updateTopBanners({
            topBanners:
              topAdminBanners.length > 0
                ? topAdminBanners
                : defaultHorizontalBanners,
          })
        );
      }
      setLoadingTopBanners(false);
      if (CTPromotionalBanners.length > 0) {
        setPromotionalBanners(
          CTPromotionalBanners.concat(defaultPromotionalBanners)
        );
        dispatch(updateBottomBanners({ bottomBanners: CTPromotionalBanners }));
      } else {
        setPromotionalBanners(
          promotionalAdminBanners.concat(defaultPromotionalBanners)
        );
        dispatch(
          updateBottomBanners({
            bottomBanners:
              promotionalAdminBanners.length > 0
                ? promotionalAdminBanners
                : defaultPromotionalBanners,
          })
        );
      }
      setLoadingPromotionalBanners(false);
    }
    // Added all 3 parameters incase dataUpdated gets triggered before admin banners are fetched.
  }, [dataUpdated, homeData]);

  useEffect(() => {
    if (errorAPICount.current === 3) {
      setApiError(true);
    } else {
      setApiError(false);
    }
  }, [errorAPICount]);

  useEffect(() => {
    if (dataUpdated && homeData) {
      if (topBannersError) {
        setTopBanners(defaultHorizontalBanners);
      }

      if (promotionalBannersError) {
        setPromotionalBanners(defaultPromotionalBanners);
      }
    }
  }, [promotionalBannersError, topBannersError, dataUpdated, homeData]);

  const onGridIconClickHandler = (item, index) => {
    let eventProps = {
      Username: userName,
      Channel: channel,
      Timestamp: getISTDateTimeFormat(),
      Tilename: item?.name,
    };
    CleverTapUtil.getInstance().logEvent(
      PR_Click_Maintile + (index + 1),
      eventProps
    );
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "IP_Click_Maintile" + (index + 1),
      eventProperties: eventProps,
    });
    // tileRedirection(item?.redirectionData);
    if (item.redirectionData) {
      let newRedirectionObject = {};
      let redirectionObjectKeys = Object.keys(item.redirectionData);
      for (let key of redirectionObjectKeys) {
        newRedirectionObject[key] = item.redirectionData[key].replace(
          /\s/g,
          ""
        );
        // console.log("New Item is ", key, newRedirectionObject[key]);
      }
      dispatch(handleDeeplink({ deepLink: newRedirectionObject }));
    }
  };

  const onRecommendedTileClickHandler = (item, index) => {
    let eventProps = {
      Username: userName,
      Channel: channel,
      Timestamp: getISTDateTimeFormat(),
      Tiletype: item?.gameDefId ? "Cash Games" : "Tournament",
    };
    CleverTapUtil.getInstance().logEvent(
      PR_Click_Recomndtile + (index + 1),
      eventProps
    );
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "IP_Click_Recommendtile" + (index + 1),
      eventProperties: eventProps,
    });
    gameTileClick(item);
  };

  const returnGameTypeText = (gameType) => {
    return gameType === "OMAHA5"
      ? "PLO5"
      : gameType === "OMAHA"
      ? "PLO4"
      : "NLH";
  };

  const handleCopyReferText = () => {
    navigator.clipboard.writeText(referCode);
    setReferCodeCopied(true);
    if (isHapticsEnabled) {
      GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
    }
  };

  const handleLearnPokerClick = () => {
    messageToNativeClient({
      type: "redirection",
      kind: "internal",
      url: process.env.REACT_APP_HOW_TO_PLAY,
    });
  };

  const handleFAQClick = () => {
    messageToNativeClient({
      type: "redirection",
      kind: "internal",
      url: process.env.REACT_APP_FAQ,
    });
  };

  const handleTermsClick = () => {
    messageToNativeClient({
      type: "redirection",
      kind: "internal",
      url: process.env.REACT_APP_TERMS,
    });
  };

  const sendPlotlineEvent = (tracker) => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    let eventprops = {
      Username: userName,
      Channel: channel,
      Timestamp: getISTDateTimeFormat(),
      TrackerName: tracker,
    };

    CleverTapUtil.getInstance().logEvent(
      "PR_" + tracker + "_Tracker",
      eventprops
    );
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "IP_" + tracker + "_Tracker",
      eventProperties: eventprops,
    });
  };

  const onReferBannerClick = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    let eventProps = {
      Username: userName,
      Channel: channel,
      Timestamp: getISTDateTimeFormat(),
    };

    CleverTapUtil.getInstance().logEvent(PR_Lobby_referNearn, eventProps);
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "IP_Lobby_refer&earn",
      eventProperties: eventProps,
    });

    messageToNativeClient({ type: "openR&E" });
  };

  const returnTitle = (type) => {
    switch (type) {
      case "Rakeback":
        return rakeback?.rakeBackPercent
          ? `${parseDecimalValuesOfNumber(
              rakeback.rakeBackPercent,
              0
            )}% Rakeback`
          : "Rakeback";
      case "Missions":
        if (missions && missions?.missionDescription) {
          return missions.missionDescription;
        }
        return "Missions";
      case "Leaderboard":
        if (leaderboard && leaderboard?.lbName) {
          return leaderboard?.lbName;
        }
        return "Leaderboards";
      default:
        return null;
    }
  };

  const returnSubtitle = (type) => {
    switch (type) {
      case "Rakeback":
        if (rakeback) {
          if (rakeback?.isLastLevel) {
            return (
              <span style={{ color: "#BABABA" }}>
                Congrats! You are enjoying the highest rakeback.
              </span>
            );
          } else {
            return (
              <span style={{ color: "#BABABA" }}>
                <span style={{ color: colors.primaryGold, fontWeight: "bold" }}>
                  {parseDecimalValuesOfNumber(
                    rakeback?.pointsReqForLevelUp,
                    0,
                    true
                  )}
                </span>{" "}
                points for level up
              </span>
            );
          }
        }
        return;
      case "Missions":
        if (missions) {
          return `${missions?.gamesPlayed ?? "0"}/
        ${
          missions?.gamesConfigured ?? ""
        } played to get ₹${parseDecimalValuesOfNumber(
            missions?.prize,
            0,
            true
          )}`;
        }
        return "Watch this space for missions";
      case "Leaderboard":
        if (leaderboard && leaderboard?.points) {
          return (
            <span style={{ color: "#BABABA" }}>
              Points:{" "}
              <span style={{ color: colors.primaryGold, fontWeight: "bold" }}>
                {parseDecimalValuesOfNumber(leaderboard?.points, 0, true)}
                {/* 50 */}
              </span>
            </span>
          );
        } else {
          return !leaderboard?.lbName
            ? "Watch this space for Leaderboards"
            : null;
        }
      default:
        return null;
    }
  };

  const returnProgressPercentage = (type) => {
    switch (type) {
      case "Rakeback":
        // console.log('RAKEBACK DATA ', rakeback)
        if (rakeback) {
          return parseDecimalValuesOfNumber(
            (rakeback?.currentRBPoints / rakeback?.currentRBEnd) * 100,
            2
          );
        }
      case "Missions":
        if (
          !isNaN(missions?.gamesPlayed) &&
          !isNaN(missions?.gamesConfigured)
        ) {
          return (missions?.gamesPlayed / missions?.gamesConfigured) * 100;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  };

  const handlePlayersCornerClick = (type) => {
    // IM-2210
    if (!isAllowLocation) {
      return dispatch(openModal({ type: RESTRICTED_LOCATION }));
    }
    switch (type) {
      case "Rakeback":
        sendPlotlineEvent("Rakeback");
        messageToNativeClient({ type: "openRakeBack" });
        return;
      case "Missions":
        sendPlotlineEvent("Missions");
        messageToNativeClient({ type: "openMissions" });
        return;
      case "Leaderboard":
        sendPlotlineEvent("Leaderboard");
        messageToNativeClient({ type: "openPokerLeaderboard" });
        return;
    }
  };

  const returnPlayersCornerID = (type) => {
    switch (type) {
      case "Rakeback":
        return "rakeback-tracker";
      case "Missions":
        return "missions-tracker";
      case "Leaderboard":
        return "leaderboard-tracker";
    }
  };

  return (
    <HomePageContainer>
      <ActiveGamesStickyButton
        tileScrollStatus={tileScrollStatus}
        toggleGameTable={toggleGameTable}
      />
      <HorizontalBannersContainer>
        {/* {loadingTopBanners && topBanners.length !== 0 ? <TopBannerSkeleton></TopBannerSkeleton> : null} */}
        {topBanners.length !== 0 ? (
          <Swiper
            loop={true}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
          >
            {topBanners.map((item, index) => {
              if (item.imageUrl) {
                if (
                  item.redirectionData?.Location?.toLowerCase() ===
                    "openrakeback" &&
                  hideRakeBack
                ) {
                  return;
                } else {
                  return (
                    <SwiperSlide key={"horizontalHomeBanner-" + index}>
                      <HorizontalCard>
                        <HorizontalImage
                          onClick={() => handleBannerClick(item, index)}
                          src={item.imageUrl}
                          alt={""}
                          onError={() => setTopBannersError(true)}
                        />
                      </HorizontalCard>
                    </SwiperSlide>
                  );
                }
              }
            })}
          </Swiper>
        ) : null}
      </HorizontalBannersContainer>
      <SectionContainer id={"recommended-container"} rightGap={true}>
        {dataUpdated && recommendedGames.length > 0 ? (
          <Header>
            <HeaderIcon src={recommended} alt={""} />
            <HomePageTitle>Recommended</HomePageTitle>
          </Header>
        ) : null}
        <NoScrollContainer>
          {loadingRecommendedData
            ? dummy2.map((item, index) => (
                <NewRecommendedSwipeCard
                  key={"dummyRecommendedSwipeCard-" + index}
                  isLoading={true}
                  style={{ justifyContent: "flex-end" }}
                ></NewRecommendedSwipeCard>
              ))
            : recommendedGames.map((item, index) => (
                <NewRecommendedSwipeCard
                  key={"newRecommendedCard-" + index}
                  onClick={() => onRecommendedTileClickHandler(item, index)}
                  id={`recommended-tile-${item?.orderId}`}
                >
                  <RecommendedCardLeft>
                    <RecommendedText>
                      {parseDecimalValuesOfNumber(item.smallBlind, 2, true)}/
                      {parseDecimalValuesOfNumber(item.bigBlind, 2, true)} Blind
                    </RecommendedText>
                    <span style={{ fontWeight: 500, fontSize: "11px" }}>
                      Min. Buy{" "}
                      {RUPEE_SYMBOL +
                        parseDecimalValuesOfNumber(
                          item.minBuyInAmount,
                          2,
                          true
                        )}
                    </span>
                    <RecommendedOnlineContainer>
                      <GreenCircle></GreenCircle>
                      <GameTileBottomLeftText>
                        {item.onlineCount} Playing
                      </GameTileBottomLeftText>
                    </RecommendedOnlineContainer>
                  </RecommendedCardLeft>
                  <RecommendedCardRight>
                    <RecommendedCardCTA>
                      <img src={rightArrow} alt={""}></img>
                    </RecommendedCardCTA>
                    <RecommendedType type={item.gameType}>
                      {returnGameTypeText(item.gameType)}
                    </RecommendedType>
                  </RecommendedCardRight>
                </NewRecommendedSwipeCard>
              ))}
        </NoScrollContainer>
      </SectionContainer>
      <IconsGridContainer>
        {loadingGridTiles
          ? dummy3.map((item, index) => {
              return (
                <LoadingGridIcon key={`dummy-grid-item-${index}`}>
                  {/* <GridImage src={item.logoUrl} /> */}
                </LoadingGridIcon>
              );
            })
          : gridTiles.length > 0
          ? gridTiles.map((item, index) => {
              return (
                <GridImageComponent
                  key={"HomeGridItem-" + index}
                  action={() => onGridIconClickHandler(item, index)}
                  item={item}
                  id={`main-tile-${item?.name}`}
                />
              );
            })
          : null}
      </IconsGridContainer>
      {!FUN_CHANNELS.includes(channel) && (
        <PlayersCornerSection id={"players-corner-container"}>
          <Header style={{ paddingLeft: "12px" }}>
            <HeaderIcon src={progress} alt={""} />
            <HomePageTitle>Player's Corner</HomePageTitle>
          </Header>
          <PlayersCornerTrackerSection>
            {/* {!dataUpdated
            ?  */}
            {playersCornerArrayNew.map((item, index) => {
              return (
                <PlayersCornerCard
                  id={returnPlayersCornerID(item.type)}
                  index={index}
                  key={`${item.type}-${index}-playersCorner`}
                  onClick={() => handlePlayersCornerClick(item.type)}
                >
                  <PlayersCornerCardLeftConnector></PlayersCornerCardLeftConnector>
                  <PlayersCornerCardImageContainer>
                    <PlayersCornerCardImage
                      src={item.image}
                      alt={""}
                    ></PlayersCornerCardImage>
                  </PlayersCornerCardImageContainer>
                  <PlayersCornerCardInfo>
                    <PlayerCornerCardTitle>
                      {returnTitle(item.type)}
                    </PlayerCornerCardTitle>
                    {/* Added static checks for max rakeback level being 6. Might have to get a new indicator in the future if max level changes. */}
                    {(item.type === "Rakeback" &&
                      rakeback &&
                      !rakeback?.isLastLevel) ||
                    (item.type === "Missions" &&
                      !isNaN(missions?.gamesPlayed) &&
                      !isNaN(missions?.gamesConfigured)) ? (
                      <RightSectionProgressContainer>
                        <RightSectionProgressInnerContainer>
                          <RightSectionProgressAmount
                            percentage={returnProgressPercentage(item.type)}
                          />
                        </RightSectionProgressInnerContainer>
                      </RightSectionProgressContainer>
                    ) : item.type === "Leaderboard" && leaderboard ? (
                      <LeaderboardSubdetails>
                        Your Rank:
                        <span
                          style={{
                            color: colors.primaryGold,
                            fontWeight: "bold",
                          }}
                        >
                          {parseDecimalValuesOfNumber(leaderboard?.rank, 0)}
                        </span>
                      </LeaderboardSubdetails>
                    ) : null}
                    <PlayerCornerCardSubtitle>
                      {returnSubtitle(item.type)}
                    </PlayerCornerCardSubtitle>
                  </PlayersCornerCardInfo>
                  <PlayersCornerCardCTAContainer>
                    <PlayersCornerCardCTA>
                      <TypeText>{item.type}</TypeText>
                      <img
                        style={{ width: "4px" }}
                        src={rightArrow}
                        alt={""}
                      ></img>
                    </PlayersCornerCardCTA>
                  </PlayersCornerCardCTAContainer>
                </PlayersCornerCard>
              );
            })}
            {/* : null} */}
          </PlayersCornerTrackerSection>
        </PlayersCornerSection>
      )}
      {/* <SectionContainer> */}
      {promotionalBanners.length > 0 ? (
        <Header style={{ paddingLeft: "12px" }}>
          <HeaderIcon src={promotions} alt={""} />
          <HomePageTitle>Promotions</HomePageTitle>
        </Header>
      ) : null}
      <PromotionalBannersContainer>
        {promotionalBanners.length > 0
          ? promotionalBanners.slice(0, 3).map((item, index) => {
              if (item.imageUrl) {
                if (
                  item.redirectionData?.Location?.toLowerCase() ===
                    "openrakeback" &&
                  hideRakeBack
                ) {
                  return;
                } else {
                  return (
                    <PromotionsCard key={"homePromotionalBanner-" + index}>
                      <PromotionsImage
                        onClick={() => handleBannerClick(item)}
                        onError={() => setPromotionalBannersError(true)}
                        src={item.imageUrl}
                        alt={""}
                      />
                    </PromotionsCard>
                  );
                }
              }
            })
          : null}
      </PromotionalBannersContainer>
      {/* </SectionContainer> */}
      <SectionContainer rightGap={true}>
        <Header>
          <HeaderIcon alt={""} src={referAndEarn} />
          <HomePageTitle>Refer & Earn</HomePageTitle>
        </Header>
        <SubtitleText style={{ position: "relative", bottom: "6px" }}>
          {/* Refer a friend and get a bonus */}
        </SubtitleText>
        <ReferContainer>
          <ReferImage alt={""} src={ReferAndEarn} />
          <ReferContent>
            <ReferText>
              <span style={{ fontSize: "14px" }}>UP TO</span>
              <br></br>
              {/* Confirmed with Product that Rupees 10,0000 static value is okay for refer and earn */}
              {RUPEE_SYMBOL + "1 LAKH"}
            </ReferText>
            <ReferCTA onClick={() => onReferBannerClick()}>Refer Now</ReferCTA>
          </ReferContent>
        </ReferContainer>
        {/* <ReferBanner src={referBanner} onClick={onReferBannerClick} /> */}
        {/* <ReferActions> */}
        {/* {navigator.clipboard && referCode ? (
            <Action
              onClick={() => handleCopyReferText()}
              style={{
                borderRight: "1px solid rgba(88, 182, 198, 1)",
                color: referCodeCopied ? "#42BE40" : "rgba(88, 182, 198, 1)",
              }}
            >
              <img
                style={{ width: "15px" }}
                src={referCodeCopied ? successIcon : copyLogo}
              ></img>
              {referCodeCopied ? "Copied" : "Copy Code"}
            </Action>
          ) : null} */}
        {/* 
          <Action onClick={() => messageToNativeClient({ type: "openR&E" })}>
            <img src={shareLogo}></img>
            Refer Now
          </Action> */}
        {/* </ReferActions> */}
      </SectionContainer>
      <HowToPlayContainer>
        <Header>
          <HeaderIcon src={learnPoker} alt={""} />
          <HomePageTitle>Learn Poker</HomePageTitle>
        </Header>
        {/* <HowToPlayPokerBanner> */}
        <img
          onClick={() => handleLearnPokerClick()}
          style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          src={HowToPlayPoker}
          alt={""}
        ></img>
        {/* </HowToPlayPokerBanner> */}
      </HowToPlayContainer>
      <SectionContainer>
        <Header>
          <HeaderIcon src={knowMore} alt={""} />
          <HomePageTitle>Know More</HomePageTitle>
        </Header>
        <KnowMoreSectionContainer>
          {/* <KnowMoreOption
            onClick={() => handleLearnPokerClick()}
            style={{ borderBottom: "0.5px solid rgba(51, 96, 112, 1)" }}
          >
            <KnowMoreIcon>
              <img src={learnPoker}></img>
            </KnowMoreIcon>
            <KnowMoreOptionName>Learn Poker</KnowMoreOptionName>
            <KnowMoreOptionArrow>
              <img src={rightArrow}></img>
            </KnowMoreOptionArrow>
          </KnowMoreOption> */}
          <KnowMoreOption
            onClick={() => handleFAQClick()}
            style={{ borderBottom: "0.5px solid rgba(51, 96, 112, 1)" }}
          >
            <KnowMoreIcon>
              <img alt={""} src={faqIcon}></img>
            </KnowMoreIcon>
            <KnowMoreOptionName>FAQs</KnowMoreOptionName>
            <KnowMoreOptionArrow>
              <img alt={""} src={rightArrow}></img>
            </KnowMoreOptionArrow>
          </KnowMoreOption>
          <KnowMoreOption onClick={() => handleTermsClick()}>
            <KnowMoreIcon>
              <img alt={""} src={tandc}></img>
            </KnowMoreIcon>
            <KnowMoreOptionName>T&C</KnowMoreOptionName>
            <KnowMoreOptionArrow>
              <img alt={""} src={rightArrow}></img>
            </KnowMoreOptionArrow>
          </KnowMoreOption>
        </KnowMoreSectionContainer>
      </SectionContainer>
      <TrustSectionContainer>
        <img alt={""} src={trustImage}></img>
        <div>
          <TrustText>
            This game may be habit forming or financially risky. Play
            responsibly.
          </TrustText>
          <TrustText>
            By registering, you accept you are 18+ and agree to our T&C and
            privacy policy
          </TrustText>
        </div>
      </TrustSectionContainer>
    </HomePageContainer>
  );
};

export default Home;

// {/* LEADERBOARD CARD */}
{
  /* {leaderboard ? ( */
}
// <SwipeCard
//   id="leaderboard-tracker"
//   onClick={() => {
//     sendPlotlineEvent("Leaderboard");
//     messageToNativeClient({ type: "openPokerLeaderboard" });
//   }}
// >
//   <LeftSection>
//     <TopText>Win</TopText>
//     <BottomText>
//       {/* {RUPEE_SYMBOL + missions.prize} */}
//       {RUPEE_SYMBOL +
//         parseDecimalValuesOfNumber(
//           leaderboard?.prizeWorth,
//           0,
//           true
//         )}
//     </BottomText>
//   </LeftSection>
//   <RightSection style={{ marginLeft: "12px" }}>
//     <RightSectionContentContainer>
//       <RightSectionText
//         style={{ display: "flex", alignItems: "flex-start" }}
//       >
//         <img
//           style={{
//             width: "14px",
//             position: "relative",
//             // right: "5px",
//             marginRight: "5px",
//           }}
//           src={lbHome}
//         ></img>
//         {
//           leaderboard?.lbName
//           // "Poker Leaderboard"
//         }
//       </RightSectionText>
//       <LeaderboardDetails>
//         <LeaderboardSubdetails>
//           Points:{" "}
//           {parseDecimalValuesOfNumber(leaderboard?.points, 0)}
//         </LeaderboardSubdetails>
//         <LeaderboardDetailsDivider></LeaderboardDetailsDivider>
//         <LeaderboardSubdetails>
//           Rank:
//           <span
//             style={{
//               color: colors.primaryGold,
//               fontWeight: "bold",
//               fontSize: "10px",
//               position: "relative",
//               marginLeft: "4px",
//             }}
//           >
//             {parseDecimalValuesOfNumber(leaderboard?.rank, 0)}
//           </span>
//         </LeaderboardSubdetails>
//       </LeaderboardDetails>
//     </RightSectionContentContainer>
//     <GreenArrow src={greenArrow} />
//   </RightSection>
// </SwipeCard>
// ) : null}

// Rakeback card
// <SwipeCard
//   id="rakeback-tracker"
//   onClick={() => {
//     sendPlotlineEvent("Rakeback");
//     messageToNativeClient({ type: "openRakeBack" });
//   }}
// >
//   <LeftSection>
//     <TopText>RAKEBACK</TopText>
//     <BottomText>
//       {parseDecimalValuesOfNumber(rakeback.rakeBackPercent, 0)}%
//     </BottomText>
//   </LeftSection>
//   <RightSection>
//     <RightSectionContentContainer>
//       <RightSectionText>
//         <span style={{ color: "#EABF69" }}>
//           {parseDecimalValuesOfNumber(
//             rakeback.pointsReqForLevelUp,
//             0,
//             true
//           )}
//         </span>{" "}
//         points to level up
//       </RightSectionText>
//       <RightSectionProgressContainer>
//         <RightSectionProgressInnerContainer>
//           <RightSectionProgressAmount
//             percentage={
//               parseDecimalValuesOfNumber(
//                 (rakeback.currentRBPoints / rakeback.currentRBEnd) *
//                   100,
//                 2
//               )
//               // 9234234234
//             }
//           />
//         </RightSectionProgressInnerContainer>
//       </RightSectionProgressContainer>
//     </RightSectionContentContainer>
//   </RightSection>
//   <GreenArrow src={greenArrow} />
// </SwipeCard>

//           {missions ? (
//   <SwipeCard
//   id="missions-tracker"
//   style={{ minWidth: "280px" }}
//   onClick={() => {
//     sendPlotlineEvent("Missions");
//     messageToNativeClient({ type: "openMissions" });
//   }}
// >
//   <LeftSection>
//     <TopText>GET</TopText>
//     <BottomText>
//       {RUPEE_SYMBOL +
//         parseDecimalValuesOfNumber(missions?.prize, 0, true)}
//     </BottomText>
//   </LeftSection>
//   <RightSection>
//     <RightSectionContentContainer>
//       <RightSectionText
//         style={{
//           width: "100%",
//           display: "flex",
//           alignItems: "center",
//           textAlign: "left",
//         }}
//       >
//         <img
//           style={{
//             width: "16px",
//             position: "relative",
//             right: "5px",
//           }}
//           src={missionTarget}
//         ></img>
//         {
//           missions?.missionDescription
//           // "Play 5/10 blinds"
//         }
//       </RightSectionText>
//       {!missions?.progressBar ? (
//         <MissionsDetails>
//           <RightSectionProgressContainer>
//             <RightSectionProgressInnerContainer>
//               <RightSectionProgressAmount
//                 percentage={
//                   (missions?.gamesPlayed /
//                     missions?.gamesConfigured) *
//                   100
//                 }
//               />
//             </RightSectionProgressInnerContainer>
//           </RightSectionProgressContainer>
//           <MissionDetailsText>
//             {missions.gamesPlayed ?? "0"}/
//             {missions?.gamesConfigured} played
//           </MissionDetailsText>
//         </MissionsDetails>
//       ) : null}
//     </RightSectionContentContainer>
//   </RightSection>
//   <GreenArrow src={greenArrow} />
// </SwipeCard>
// ) : null}
