import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import {
  Typo12GoldContoRegularText,
  Typo12GrayContoRegularText,
  Typo16WhiteContoBoldText,
} from "../../../styleGuide/Typos";

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 0%;
  margin-bottom: 0px;
`;

export const InfoTitle = styled.span`
  width: 45%;
  text-align: left;
  text-transform: capitalize;
  font-size: 0.85rem;
  color: white;
  font-family: "Conto";
  font-weight: lighter;
`;

export const InfoDivider = styled.div`
  width: 10%;
  color: ${colors.white};
`;

export const InfoDetail = styled.span`
  width: 45%;
  text-align: right;
  color: ${colors.primaryGold};
  font-family: "Conto";
  font-size: 1rem;
  font-weight: bold;
`;

export const EntryAmount = styled(InfoDetail)`
  border-radius: 8px;
  /* border: 1px solid ${colors.navy2}; */
  padding: 2%;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  /* margin: 7% 0 3% 0; */
  background-color: ${colors.navy2};
`;

export const RangeDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 3%; */
`;

export const Note = styled.div`
  width: 95%;
  border-radius: 8px;
  background: rgba(242, 242, 242, 0.1);
  padding: 3%;
  display: flex;
  margin: 6% 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  /* margin: 7% 0 2% 0; */
`;

export const BuyInWrapperContainer = styled.div`
  width: 98%;
  height: 40px;
  display: flex;
  /* background-color: red; */
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 3% 0% 20px 3%;
`;

export const InfoImg = styled.img`
  margin: 2px 5px;
  width: 16px;
`;

export const BuyInTimerWrapper = styled.div`
  /* position: absolute; */
  bottom: 30px;
  left: 10px;
`;

export const AntiBankingNote = styled.div`
  border-radius: 4px;
  background: rgba(242, 242, 242, 0.1);
  padding: 3%;
  font-size: 0.75rem;
  color: rgb(186, 186, 186);
  letter-spacing: 0.4px;
`;

export const HeaderTitleText = styled(Typo16WhiteContoBoldText)`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${colors.deepTeal60};
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${colors.popupBgColor};
  border-radius: 8px 8px 0 0;
  position: relative;
  padding: 0.7rem;
  box-sizing: border-box;
  .close-icon {
    margin-top: -6px;
  }
`;

export const BuyInBody = styled.div`
  background: ${colors.popupBgColor};
  padding: ${(props) =>
    props.orientation === "LANDSCAPE" ? "0rem 1rem" : "1rem"};
  display: grid;
  gap: 0.5rem;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`;

export const ChipsStackIconWrapper = styled.img`
  margin-right: 4px;
  height: 10px;
`;
