import {
  FLUSH,
  FOUR_OF_A_KIND,
  FULL_HOUSE,
  GAME_SOCKET_REMOVED,
  HIGH_CARD,
  OMAHA,
  OMAHA5,
  ONE_PAIR,
  PLAYER_CARDS_IN_OMAHA,
  PLAYER_CARDS_IN_OMAHA5,
  PLAYER_CARDS_IN_TEXAS_HOLDEM,
  ROYAL_FLUSH,
  RUPEE_SYMBOL,
  SIX_PLAYERS_TABLE,
  STRAIGHT,
  STRAIGHT_FLUSH,
  TEXAS_HOLDEM,
  THREE_OF_A_KIND,
  TWO_PAIRS,
  TWO_PLAYERS_TABLE,
} from "../../Poker/data/Constants";
import { getMLTLogFormat } from "../../Poker/utils/ProtocolUtils";
import { getGameSlice } from "../../Poker/utils/ReduxUtils";
import { sendFaroLog } from "./FaroUtil";

const getCurrentTableData = (tempTableId, tables) => {
  // for (let index = 0; index < tables.length; index++) {
  //     if (tables[index].tempTableId === tempTableId) {
  //         return tables[index];
  //     }
  // }
  console.log("tables in method: ", tables);
  console.log("tables tempTableId: ", tempTableId);
  for (let key in tables) {
    console.log(tables[key]);
    console.log("2: ", typeof tables[key].tempTableId);
    console.log("3: ", typeof tempTableId);
    if (tables[key].tempTableId === tempTableId) {
      console.log("returning table: ", tables[tempTableId]);
      return tables[tempTableId];
    }
  }
  return null;
};

const getHandType = (type) => {
  if (type === "Nothing") return HIGH_CARD;
  if (type === "Pair") return ONE_PAIR;
  if (type === "Two pairs") return TWO_PAIRS;
  // if (type === "Triple") return THREE_OF_A_KIND; //commented - as changed at server side
  if (type === "Three of a kind") return THREE_OF_A_KIND;
  if (type === "Straight") return STRAIGHT;
  if (type === "Flush") return FLUSH;
  if (type === "Full House") return FULL_HOUSE;
  if (type === "Four of a kind") return FOUR_OF_A_KIND;
  if (type === "Straight Flush") return STRAIGHT_FLUSH;
  if (type === "Royal Flush") return ROYAL_FLUSH;
  if (type === "muck") return "Muck";
  return;
};

// const parseDecimalValuesOfNumber = (number, decimalPlaces = 2) => {
//   try {
//     if (number % 1 !== 0) {
//       let numberAsString = number.toString();
//       numberAsString = numberAsString.slice(
//         0,
//         numberAsString.indexOf(".") + decimalPlaces + 1
//       );
//       return Number(numberAsString);
//     } else {
//       return number;
//     }
//   } catch (e) {
//     return number;
//   }
// };

const checkToDisplayValueInK = (value) => {
  if (value) {
    return value >= 1000 && value < 100000;
  }
  return false;
};

const checkToDisplayValueInL = (value) => {
  if (value) {
    return value >= 100000;
  }
  return false;
};

const parseDecimalValuesOfNumber = (
  number,
  decimalPlaces = 2,
  useK = false,
  useL = false,
  printConsole = false
) => {
  try {
    if (isNaN(number)) {
      return number;
    }
    let numberAsString, parsedNumber; // Declare parsedNumber outside
    if (printConsole) {
      console.log(
        "Yashwanth logging parseDecimalValuesOfNumber received number: ",
        number
      );
      console.log(
        "Yashwanth logging parseDecimalValuesOfNumber received number type: ",
        typeof number
      );
    }

    if (number % 1 !== 0) {
      if (printConsole)
        console.log(
          "Yashwanth logging parseDecimalValuesOfNumber if case number: ",
          number
        );
      numberAsString = number.toString();
      const decimalIndex = numberAsString.indexOf(".");
      // Adjust slice based on decimalPlaces and potential rounding
      const sliceEnd = Math.min(
        decimalIndex + decimalPlaces + 1,
        numberAsString.length
      );
      numberAsString = numberAsString.slice(0, sliceEnd);
      parsedNumber = Number(numberAsString);
    } else {
      if (printConsole) {
        console.log(
          "Yashwanth logging parseDecimalValuesOfNumber else case number: ",
          number
        );
      }

      parsedNumber = typeof number !== "number" ? Number(number) : number;
      // parsedNumber = Number(number);
      if (printConsole) {
        console.log("YASHWANTH LOGGING AFTER PARSED:", parsedNumber);
        console.log(
          "YASHWANTH LOGGING AFTER PARSED typeof:",
          typeof parsedNumber
        );
      }
      // parsedNumber = number;
    }

    // Format with 'K' suffix if useK is true and value is >= 1000
    if (useK) {
      if (printConsole) {
        console.log(
          "Yashwanth logging parseDecimalValuesOfNumber useK parsedNumber: ",
          parsedNumber
        );
      }
      if (parsedNumber === 1000) {
        return "1K";
      } else if (parsedNumber >= 1000) {
        let [wholeNumber, decimals] = (parsedNumber / 1000)
          .toString()
          .split(".");
        if (decimals && decimalPlaces > 0) {
          let restrictToOnlyDecimalPlaces = decimals.slice(0, decimalPlaces);
          return `${wholeNumber}.${restrictToOnlyDecimalPlaces}K`;
        } else {
          return `${wholeNumber}K`;
        }
      } else {
        return parsedNumber;
      }
    } else if (useL) {
      if (printConsole) {
        console.log(
          "Yashwanth logging parseDecimalValuesOfNumber useL parsedNumber: ",
          parsedNumber
        );
      }
      if (parsedNumber === 100000) {
        return "1L";
      } else if (parsedNumber >= 100000) {
        let [wholeNumber, decimals] = (parsedNumber / 100000)
          .toString()
          .split(".");
        if (decimals && decimalPlaces > 0) {
          let restrictToOnlyDecimalPlaces = decimals.slice(0, decimalPlaces);
          return `${wholeNumber}.${restrictToOnlyDecimalPlaces}L`;
        } else {
          return `${wholeNumber}L`;
        }
      } else {
        return parsedNumber;
      }
    } else {
      // Handle 0 decimal places without decimal point
      if (printConsole) {
        console.log(
          "Yashwanth logging parseDecimalValuesOfNumber ELSE IF decimalPlaces: ",
          decimalPlaces,
          " parsedNumber:",
          parsedNumber
        );
      }
      if (decimalPlaces === 0) {
        if (printConsole) {
          console.log(
            "Yashwanth logging ELSE IF CONDITION ONE:",
            Number(parsedNumber)
          );
        }

        return Number(parsedNumber);
      } else {
        // Remove trailing zeros for non-zero decimal places
        if (printConsole) {
          console.log("YASHWANTH LOGGING parsedNumber: ", parsedNumber);
        }
        const formattedNumber = parsedNumber.toFixed(decimalPlaces);
        if (printConsole) {
          console.log(
            "Yashwanth logging ELSE IF CONDITION TWO STAGE ONE:",
            formattedNumber
          );
          console.log(
            "Yashwanth logging ELSE IF CONDITION TWO STAGE TWO PART A:",
            formattedNumber.replace(/\.0+$/, "")
          );
          console.log(
            "Yashwanth logging ELSE IF CONDITION TWO STAGE TWO PART B:",
            Number(formattedNumber.replace(/\.0+$/, ""))
          );
        }
        return Number(formattedNumber.replace(/\.0+$/, ""));
      }
    }
  } catch (e) {
    console.log("Yashwanth logging CATCH BLOCK:", e);
    console.log("Yashwanth logging CATCH BLOCK RETURNING PART A:", number);
    console.log(
      "Yashwanth logging CATCH BLOCK RETURNING PART B:",
      Number(number)
    );
    // return Number(number);
    return isNaN(number) ? number : Number(number);
  }
};

const getPlayerPosition = (position, playerPositions) => {
  let players = playerPositions.filter((player) => {
    return player.position === position;
  })[0];

  return players;
};

//  returns left player position
const checkIfPlayerIsLeftToMySeat = (totalPlayersOnTable, mySeat = 0) => {
  // default 0 value for mySeat in case of watch player
  let difference, seat, nextSeat;

  if (totalPlayersOnTable === SIX_PLAYERS_TABLE) {
    difference = totalPlayersOnTable - 1 - mySeat;
    if (difference === 0) {
      seat = 0;
    } else {
      seat = mySeat + 1;
    }

    if (seat + 1 === totalPlayersOnTable) {
      nextSeat = 0;
    } else {
      nextSeat = seat + 1;
    }
  }

  return [seat, nextSeat];
};

//  return right player position
const checkIfThePlayerIsRightToMySeat = (totalPlayersOnTable, mySeat = 0) => {
  let seat, previousSeat;
  if (totalPlayersOnTable === SIX_PLAYERS_TABLE) {
    if (mySeat === 0) {
      seat = totalPlayersOnTable - 1;
      previousSeat = seat - 1;
    } else if (mySeat === totalPlayersOnTable - 1) {
      seat = totalPlayersOnTable - 2;
      previousSeat = Math.abs(seat - 1);
    } else if (mySeat === 1) {
      seat = 0;
      previousSeat = totalPlayersOnTable - 1;
    } else {
      seat = mySeat - 1;
      previousSeat = seat - 1;
    }
  }

  return [seat, previousSeat];
};

// bottom player
const checkIfThePlayerPositionIsBottom = (position, mySeat = 0) => {
  return position === mySeat || mySeat === undefined;
};

//  top player
const checkIfPlayerPositionIsOnTop = (
  position,
  mySeat = 0,
  totalPlayersOnTable
) => {
  //  Need to add logic for 9 player table
  // default 0 value for mySeat in case of watch player
  if (totalPlayersOnTable === TWO_PLAYERS_TABLE) {
    return position !== mySeat;
  } else if (totalPlayersOnTable === SIX_PLAYERS_TABLE) {
    return Math.abs(position - mySeat) === 3;
  }
};

// left player
const checkIfIsLeftPlayer = (totalPlayersOnTable, mySeat = 0, position) => {
  let playerIconConfig = checkIfPlayerIsLeftToMySeat(
    totalPlayersOnTable,
    mySeat
  );

  let isLeftPlayer =
    totalPlayersOnTable && playerIconConfig?.includes(position);

  return isLeftPlayer;
};

// right player
const checkIfIsRightPlayer = (totalPlayersOnTable, mySeat = 0, position) => {
  let playerIconConfig = checkIfThePlayerIsRightToMySeat(
    totalPlayersOnTable,
    mySeat
  );

  let isRightPlayer =
    position !== mySeat && playerIconConfig.includes(position);

  return isRightPlayer;
};

export const getAntiBankingNote = (
  amount,
  isCashGame = true,
  antiBankingTime
) => {
  let note = `Note: As you left the table with ${
    isCashGame ? RUPEE_SYMBOL : ""
  }${isCashGame ? amount : amount + " chips"}${
    antiBankingTime ? " in the last " + antiBankingTime + " minutes" : ""
  }, you must buy in with at least that amount`;
  return note;
};

export const getCurrentTimeStamp = () => {
  let date = new Date();

  let dateString = `---->>---- ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
  return dateString;
};

export const getNumberOfCardsBasedOnGameType = (gameType) => {
  let numberOfCards =
    gameType === TEXAS_HOLDEM
      ? PLAYER_CARDS_IN_TEXAS_HOLDEM
      : gameType === OMAHA
      ? PLAYER_CARDS_IN_OMAHA
      : gameType === OMAHA5
      ? PLAYER_CARDS_IN_OMAHA5
      : null;

  return numberOfCards;
};

export const getWinningPlayersData = (playerData) => {
  let winningPlayers = [];
  if (playerData) {
    playerData.map((player) => {
      if (player.isWinner && player.handType) {
        winningPlayers?.push({ ...player });
      }
    });
  }

  let reversedPlayers = [...winningPlayers].reverse();
  // reverse bcz pot movement animation starts from last index, so using the same order to display the winning hand and highlighting cards
  return reversedPlayers;
};

export const getISTDateTimeFormatFromEpochFormat = (epochFormat) => {
  if (epochFormat) {
    let dateTime = new Date(epochFormat * 1000);
    return dateTime.toLocaleString();
  }
};

export const getISTDateTimeFormat = () => {
  let temp = new Date();
  let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
  timeStamp = changeToISOString(timeStamp);
  return timeStamp;
};

const changeToISOString = (timeStamp) => {
  let newTimeStamp;
  newTimeStamp = timeStamp.toISOString().replace("T", ", ");
  newTimeStamp = newTimeStamp.replace("Z", "");

  return newTimeStamp;
};

function preloadImage(src) {
  src.toString();
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      console.log("preloaded ", src);
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      console.log("preload Image didn't load successfully", src);
      reject(src);
    };
    img.src = src;
  });
}

function checkIfUnsupportedDevice(data) {
  // Tablet Check
  try {
    if (data?.deviceType) {
      if (data?.deviceType.toLowerCase() === "tablet") {
        return true;
      }
    }
    if (data?.deviceOS) {
      let deviceOSNumber = data.deviceOS.split(".")[0];
      const OS = Number(deviceOSNumber);
      console.log("OPERATING SYSTEM IS ", OS);
      let userAgent = window.navigator.userAgent.toLocaleLowerCase();
      if (userAgent.indexOf("android") === -1) {
        // iOS OS check
        if (OS < 12) {
          return true;
        }
      } else {
        // Android OS Check
        if (OS < 9) {
          return true;
        }
      }
    }
  } catch (err) {
    return false;
  }
  return false;
}

function checkIfFoldDeviceInTabletMode(data) {
  // Fold device (samsung in this case) in tablet mode - two screens
  try {
    if (data?.deviceType) {
      if (data?.deviceType.toLowerCase() === "tablet") {
        if (data?.deviceModel?.toLowerCase().search("samsung") >= 0) {
          // Samsung Galaxy Fold (2019) - Model Identifier: SM-F900
          // Samsung Galaxy Z Fold 2 - Model Identifier: SM-F916
          // Samsung Galaxy Z Fold 3 - Model Identifier: SM-F926
          if (data?.deviceModel?.toLowerCase().search("sm-f") >= 0) {
            return true;
          }
        }
        return false;
      }
    }
  } catch (err) {
    return false;
  }
  return false;
}

function checkIfFoldDeviceInMobileMode(data) {
  // Fold device (samsung in this case) in tablet mode - two screens
  try {
    if (data?.deviceType) {
      if (data?.deviceType.toLowerCase() === "mobile") {
        if (data?.deviceModel?.toLowerCase().search("samsung") >= 0) {
          // Samsung Galaxy Fold (2019) - Model Identifier: SM-F900
          // Samsung Galaxy Z Fold 2 - Model Identifier: SM-F916
          // Samsung Galaxy Z Fold 3 - Model Identifier: SM-F926
          if (data?.deviceModel?.toLowerCase().search("sm-f") >= 0) {
            return true;
          }
        }
        return false;
      }
    }
  } catch (err) {
    return false;
  }
  return false;
}

function checkExistingTourneyWatchTable(tourneyId, watchRequestedTableId) {
  let allGames = getGameSlice().games;
  console.log("tourneyId: ", tourneyId);
  let checkObject = {
    openedWatchTableTempTableId: undefined,
    openedWatchTableConnectionRemoved: false,
    requestedAlreadyOpenedTempTableId: undefined,
  };

  console.log("allGames: ", allGames);
  for (let eachGameTempTableId in allGames) {
    let eachGameTableObject = allGames[eachGameTempTableId];
    console.log("eachGameTableObject:", eachGameTableObject);

    if (
      eachGameTableObject.isTourneyGame &&
      eachGameTableObject.gameDefinition.tourneyId === tourneyId
    ) {
      console.log(
        "eachGameTableObject.watchPlayer:",
        eachGameTableObject.watchPlayer
      );
      if (
        eachGameTableObject.watchPlayer &&
        !checkObject.openedWatchTableTempTableId
      ) {
        checkObject.openedWatchTableTempTableId = eachGameTempTableId;
        checkObject.openedWatchTableConnectionRemoved =
          eachGameTableObject.gameConnectionStatus === GAME_SOCKET_REMOVED
            ? true
            : false;
      }

      if (eachGameTableObject.tableId === watchRequestedTableId) {
        checkObject.requestedAlreadyOpenedTempTableId = eachGameTempTableId;
      }
    }
  }
  console.log("checkObject: ", checkObject);
  sendFaroLog(
    getMLTLogFormat({
      type: "watchTableSwitchCheck",
      stage: "Game",
      logLocation: "checkExistingTourneyWatchTable",
      // tempTableId: props.tempTableId,
      comments: checkObject,
    })
  );
  return checkObject;
}

export {
  getCurrentTableData,
  getHandType,
  parseDecimalValuesOfNumber,
  getPlayerPosition,
  checkIfPlayerIsLeftToMySeat,
  checkIfPlayerPositionIsOnTop,
  checkIfIsLeftPlayer,
  checkIfThePlayerPositionIsBottom,
  checkIfIsRightPlayer,
  checkIfThePlayerIsRightToMySeat,
  preloadImage,
  checkIfUnsupportedDevice,
  checkIfFoldDeviceInTabletMode,
  checkIfFoldDeviceInMobileMode,
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  checkExistingTourneyWatchTable,
};
